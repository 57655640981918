<template>
    <v-container fluid fill-height v-if="!this.$store.state.LOADING">
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-row class="ma-2">
                        <v-col>
                            <v-row>
                                <v-col>
                                    <v-img :src="require('@/assets/image/logo_pttep.png')" style="width: 110px;" />
                                </v-col>
                                <v-col class="text-right">
                                    <v-img :src="require('@/assets/image/NewRS_PTTEPNAME.png')" style="width: 80%;" class="ml-auto" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8" lg="2" md="2" sm="3" style="display: flex; align-items: center;">
                                    <span class="mr-2" style="white-space: nowrap;">ที่ ปตท.สผ.</span>
                                    <v-text-field outlined dense hide-details v-model="docNo"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-center">
                                    {{ $store.state.dateNowThai }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <span>เรื่อง  ขอความอนุเคราะห์ออกหนังสือเดินทางราชการ</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <span>เรียน  ปลัดกระทรวงการต่างประเทศ</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <table aria-label="">
                                        <tr hidden>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <td style="width: 100px;">สิ่งที่ส่งมาด้วย</td>
                                            <td><v-text-field outlined dense hide-details v-model="$store.state.docPassport.enclosure"></v-text-field></td>
                                        </tr>
                                        <tr>
                                            <td style="width: 100px;"></td>
                                            <td>2. หนังสือรับรองการเป็นพนักงานของบริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน)</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 100px;"></td>
                                            <td>3. อนุมัติการเดินทาง {{ $store.state.docPassport.TRBudget }}</td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-10">
                                    <span>
                                        ด้วยบริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน) ได้อนุมัติให้พนักงาน จำนวน 1 ท่าน
                                        เดินทางไปปฏิบัติงานที่ประเทศ{{ $store.state.docPassport.country }} ระหว่างวันที่
                                        {{ $store.state.docPassport.dateStart }} - {{ $store.state.docPassport.dateEnd }} คือ
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2"></v-col>
                                <v-col>
                                    <table style="width: 100%" aria-label="">
                                        <tr hidden><th></th></tr>
                                        <tr>
                                            <td style="width: 50%"><u>ชื่อ นามสกุล</u></td>
                                            <td><u>ตำแหน่ง</u></td>
                                        </tr>
                                        <tr v-for="(item, index) in $store.state.docPassport.documentTraveller" :key="index">
                                            <td>
                                                <span>{{ item.NameTH }}</span><br>
                                                <span>({{ item.NameEN }})</span>
                                            </td>
                                            <td>
                                                <span>{{ item.PosiTH }}</span><br>
                                                <span>({{ item.PosiEN }})</span>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col cols="2"></v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-10">
                                    <span>
                                        จึงเรียนมาเพื่อโปรดอนุเคราะห์ออกหนังสือเดินทางราชการ ให้แก่พนักงานดังกล่าว ข้างต้นด้วย จักเป็นพระคุณยิ่ง
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-center">ขอแสดงความนับถือ</v-col>
                            </v-row>
                            <v-row align="center" justify="center" no-gutters class="mt-8">
                                <v-col cols="12" lg="2" md="3" sm="5" class="d-flex justify-center">
                                    <v-text-field outlined dense hide-details v-model="$store.state.docPassport.nameBudget"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" no-gutters class="mt-2">
                                <v-col cols="12" lg="3" md="4" sm="6" class="d-flex justify-center">
                                    <v-text-field outlined dense hide-details v-model="$store.state.docPassport.posiBudget"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" no-gutters class="mt-2">
                                <v-col cols="12" lg="3" md="4" sm="6" class="d-flex justify-center">
                                    <v-text-field outlined dense hide-details v-model="$store.state.docPassport.posiOther"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-5">
                                <v-col cols="12" lg="3" md="3" sm="6">
                                    <v-text-field outlined dense hide-details v-model="$store.state.docPassport.nameBTT"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col cols="12" lg="3" md="3" sm="6">
                                    <v-text-field outlined dense hide-details v-model="$store.state.docPassport.posiBTT"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-img :src="require('@/assets/image/NewWeb_footerPTTEP.png')" style="width: 70%;" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn color="primary" elevation="0" @click="printPDF()">Print To PDF</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import pdfFonts from '@/assets/custom-font.js'
export default {
    data(){
        return{
            TRNo: "",
            docNo: "",
            EMP_ID: "",
            Type: "",
        }
    },
    methods:{
        async printPDF(){
            pdfMake.vfs = pdfFonts.pdfMake.vfs
            pdfMake.fonts = {
                CordiaNew: {
                    normal: "CordiaNew.ttf"
                }
            }
            const doc = {
                content:[
                    {
                        columns: this.$store.state.docPassport.headerPassport
                    },
                    {
                        text: `ที่ ปตท.สผ. ${this.docNo}`,
                        style: "font16",
                        margin: [0, 10, 0, 0]
                    },
                    {
                        text: this.$store.state.dateNowThai,
                        style: "font16",
                        alignment: "center",
                        margin: [0, 25, 0, 0]
                    },
                    this.$store.state.docPassport.rptOffPss4Topic,
                    {
                        style: "font16",
                        margin: [0, 20, 0, 0],
                        columns: [
                            {
                                text: "",
                                width: 70
                            },
                            {
                                text: `ด้วยบริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน) ได้อนุมัติให้พนักงาน จำนวน 1 ท่าน เดินทาง`
                            }
                        ]
                    },
                    {
                        text: `ไปปฏิบัติงานที่ประเทศ${this.$store.state.docPassport.country}
                        ระหว่างวันที ${this.$store.state.docPassport.dateStart} - ${this.$store.state.docPassport.dateEnd}`,
                        style: "font16",
                    },
                    this.$store.state.docPassport.rptOffPssNameAndPosition,
                    this.$store.state.docPassport.newTravel,
                    this.$store.state.docPassport.rptOffPssSign
                ],
                footer: {
                    margin: [20, -10, 20, 10],
                    image: this.$store.state.NewWeb_footerPTTEP,
                    width: 555
                },
                pageSize: "A4",
                styles:{
                    font16: {
                        alignment: 'left',
                        fontSize: 16
                    },
                },
                defaultStyle: {
                    font: 'CordiaNew'
                }
            }
            pdfMake.createPdf(doc).download(`RptOffPss04_${this.$store.state.docPassport.nameTH}.pdf`)
        },
        getRptOffPss4(TRNo, EMP_ID, Type){
            this.$store.commit("docPassport/getDocPassport", {
                TRNo: TRNo,
                EMP_ID: EMP_ID,
                Type: Type,
            })
        }
    },
    mounted(){
        if(this.$route.query.TRNo && this.$route.query.EMP_ID && this.$route.query.Type){
            const q = this.$route.query
            this.TRNo = q.TRNo
            this.EMP_ID = q.EMP_ID
            this.Type = q.Type
            this.$store.state.docPassport.enclosure = "1. คำร้องขอออกหนังสือเดินทางและหนังสือนำวีซ่า"
            this.getRptOffPss4(q.TRNo, q.EMP_ID, q.Type)
        }
    }
}
</script>
