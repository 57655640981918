<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <span>
                    <span class="font-W700" style="font-size: 1.2em;">Travel Request</span>
                </span>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col class="d-flex flex-column">
                                Travel Request Number
                                <span class="font-W500" color="black">{{ TRNo }}</span>
                            </v-col>
                            <v-col class="d-flex flex-column">
                                Business Travel Type
                                <span class="font-W500" color="black">{{ BusinessTravelType }}</span>
                            </v-col>
                            <v-col class="d-flex flex-column">
                                Travel Request Status
                                <span class="font-W500" color="black">{{ TravelRequestStatus }}</span>
                            </v-col>
                            <v-col class="d-flex flex-column align-end align-self-center">
                                <span class="font-W700" size="4" color="red" v-if="RemainningDays <= 3">URGENT</span>
                                <span class="font-W700" size="4" color="#7AB800" v-if="RemainningDays > 3">NORMAL</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <v-row class="mb-3" no-gutters>
                            <v-col>
                                <span>
                                    <span class="font-W700" style="font-size: 1.1em;">Travel information</span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <v-card class="card-detail" elevation="0">
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <span class="font-W700" color="black">
                                                    Coporate Budget
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="mt-2">
                                            <v-col lg="6" sm="6" cols="12">
                                                Budget Holder
                                                <span style="color: #FF0000;">*</span>
                                                <v-text-field outlined dense
                                                v-model="BudgetHolder" background-color="white" disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col lg="6" sm="6" cols="12">
                                                Project
                                                <span style="color: #FF0000;">*</span>
                                                <v-text-field outlined dense
                                                v-model="Project" disabled></v-text-field>
                                            </v-col>
                                            <v-col lg="6" sm="6" cols="12" class="pl-lg-2 pl-sm-2">
                                                Company
                                                <span style="color: #FF0000;">*</span>
                                                <v-text-field outlined dense
                                                v-model="Company" background-color="#D2D2D2" disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col lg="6" sm="6" cols="12">
                                                Country
                                                <span style="color: #FF0000;">*</span>
                                                <v-text-field outlined dense
                                                v-model="Country" disabled></v-text-field>
                                            </v-col>
                                            <v-col lg="6" sm="6" cols="12" class="pl-lg-2 pl-sm-2">
                                                City or Province
                                                <span style="color: #FF0000;">*</span>
                                                <v-text-field outlined dense
                                                v-model="CityorProvince" disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col lg="6" sm="6" cols="12">
                                                Purpose of Travel
                                                <span style="color: #FF0000;">*</span>
                                                <v-text-field outlined dense
                                                v-model="PurposeofTravel" disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                Description for purpose of Travel
                                                <v-textarea outlined dense disabled></v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col lg="6" sm="6" cols="12">
                                                Attached Files
                                                <v-text-field outlined dense disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row><br>
                        <v-row class="mb-3" no-gutters>
                            <v-col>
                                <span>
                                    <span class="font-W700" style="font-size: 1.1em;">Traveler</span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <v-card class="card-detail" elevation="0">
                                    <v-card-text>
                                        <v-layout>
                                            <v-flex>
                                                <span class="font-W700" color="black">
                                                    Detail of Persons On Mission
                                                </span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout class="overflow-x-auto myTicket mt-2" style="height: 600px;">
                                            <table style="border-collapse: collapse; width: 100%;" aria-label="" id="customers" class="mb-3">
                                                <tr hidden><th></th></tr>
                                                <tr>
                                                    <td style="width: 100px;">
                                                        <v-layout class="ml-2 mr-3">
                                                            <v-flex class="d-flex flex-column">
                                                                <span style="color: #747678;">No.</span>
                                                                <span style="color: #747678;" size="1">Type/Dept/Div</span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </td>
                                                    <td>
                                                        <v-layout class="mr-3">
                                                            <v-flex class="d-flex flex-column">
                                                                <span style="color: #747678;">Full Name</span>
                                                                <span style="color: #747678;" size="1">Details</span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </td>
                                                    <td>
                                                        <v-layout class="mr-3">
                                                            <v-flex class="d-flex flex-column">
                                                                <span style="color: #747678;">Flight</span>
                                                                <span style="color: #747678;" size="1">Flight Details</span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </td>
                                                    <td>
                                                        <v-layout class="mr-3">
                                                            <v-flex class="d-flex flex-column">
                                                                <span style="color: #747678;">Hotel</span>
                                                                <span style="color: #747678;" size="1">Hotel Details</span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </td>
                                                    <td>
                                                        <v-layout class="mr-3">
                                                            <v-flex class="d-flex flex-column align-self-center">
                                                                <span style="color: #747678;">Status</span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr class="card-in-detail" v-for="(item, index) in Traveler" :key="index">
                                                    <td>
                                                        <v-layout class="ml-2 mt-2">
                                                            <v-flex class="d-flex align-center flex-column">
                                                                <span class="font-W500" color="black" size="3">{{ index + 1 }}</span>
                                                                <span class="font-W500" color="black" size="3">{{ item.TypeTraveler }}</span>
                                                                <span class="font-W500" color="black" size="3">{{ item.BudgetHolder }}</span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </td>
                                                    <td>
                                                        <v-layout class="mt-2">
                                                            <v-flex class="d-flex flex-column">
                                                                <span class="font-W500" color="black" size="3">{{ item.Name }}</span>
                                                                <span>Office: -</span>
                                                                <span>Mobile: {{ item.MOBILE_NO }}</span>
                                                                <span>{{ item.EMAIL_ID }}</span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </td>
                                                    <!-- Flight -->
                                                    <td>
                                                        <v-layout class="mt-2" v-for="(subItem, n) in item.Flight" :key="n">
                                                            <v-flex class="d-flex flex-column" v-if="subItem.IsReIssue === 0 || subItem.ReIssueSelected === 1">
                                                                <v-row>
                                                                    <v-col :cols="9">
                                                                        <v-card tile elevation="0" style="border: 1px solid #00AEEF; background: #EAF9FF;"
                                                                        class="pl-2 pr-2" width="170px">
                                                                            <v-layout>
                                                                                <v-flex>
                                                                                    <span style="color: #00AEEF;">PNR</span>
                                                                                </v-flex>
                                                                                <v-flex text-right>
                                                                                    <span style="color: #00AEEF;" v-if="subItem.Airline !== 'DD'" class="font-W700 text-right">
                                                                                        {{ subItem.PNR ? subItem.PNR : 'Pending'}}
                                                                                        <span style="color: red" class="f-text-18 font-W500">
                                                                                            {{ subItem.IsReIssue === 1 ? 'Re-Issue' : subItem.BookingType }}
                                                                                        </span>
                                                                                    </span>
                                                                                    <span v-else style="color: #00AEEF;" class="font-W700 text-right">
                                                                                        Pending
                                                                                    </span>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-card>
                                                                    </v-col>
                                                                    <v-col cols="3">
                                                                        <v-btn icon small elevation="0" @click="openTicketManagement(subItem)"
                                                                        style="background: #EBEEF4; border-radius: 5px; width: 36px !important;">
                                                                            <img class="shark" :src="require('@/assets/icons/EditDetail.svg')" width="20px" alt="" />
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                                <div class="f-text-18">
                                                                        Ticket Exp. {{subItem.BookingType === 'Online' ?
                                                                        formatDate(subItem.TKTL, 'DD-MMM-YYYY HH:mm') : '-'}}
                                                                    <span class="f-text-16" v-if="subItem.BookingType === 'Online'"
                                                                    :style="(sumDate(subItem.TKTL) < 72 ? 'color:red;' : '')">
                                                                        ({{sumDate(subItem.TKTL) < 0 ? 0 : sumDate(subItem.TKTL)}} Hours)
                                                                    </span>
                                                                </div>
                                                                <v-divider></v-divider>
                                                                <br />
                                                                <div v-for="(subItemDetail, n) in subItem.FlightDetailDisplay" :key="n" class="font-W500" color="black" size="3">
                                                                    <div class="bgcard">
                                                                        <v-row>
                                                                            <v-col cols="3">
                                                                                <v-img class="image-center" width="30" v-if="showAirlineLogo(subItemDetail.Airline) !== 'NoImg'"
                                                                                    :src="require(`@/assets/logoAirline/square/${showAirlineLogo(subItemDetail.Airline)}.png`)" />
                                                                                <div v-else class="text-center mt-2">
                                                                                    No Img
                                                                                </div>
                                                                            </v-col>
                                                                            <v-col cols="9">
                                                                                <div class="pt-2">
                                                                                    {{ subItemDetail.AirlineName }}
                                                                                    <span v-if="subItemDetail.FlightNumber">
                                                                                        {{ subItemDetail.Airline + subItemDetail.FlightNumber }}
                                                                                    </span>
                                                                                </div>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <br />
                                                                        <v-flex class="d-flex align-self-center text-center">
                                                                            <span class="font-W500 align-self-center" color="black" size="3">{{ subItemDetail.Origin }}</span>
                                                                            &nbsp;&nbsp;
                                                                            <img class="d-flex align-self-center"
                                                                            :src="require('@/assets/icons/arrow_left_right.svg')" height="12px" alt="" />
                                                                            &nbsp;&nbsp;
                                                                            <span class="font-W500 align-self-center" color="black" size="3">{{ subItemDetail.Destination }}</span>
                                                                        </v-flex>
                                                                        <v-row class="f-text-14">
                                                                            <v-col cols="6" class="text-center">
                                                                                {{ subItemDetail.DepartureDatetime ?
                                                                                formatDate(subItemDetail.DepartureDatetime, 'DD MMM YY') : '' }}
                                                                                <br />
                                                                                {{ subItemDetail.DepartureDatetime ?
                                                                                subItemDetail.TravelDepHour + ":" + subItemDetail.TravelDepMinute : ""}}
                                                                            </v-col>
                                                                            <v-col cols="6" class="text-center">
                                                                                {{ subItemDetail.ArrivalDatetime ?
                                                                                formatDate(subItemDetail.DepartureDatetime, 'DD MMM YY') : '' }}
                                                                                <br />
                                                                                {{ subItemDetail.ArrivalDatetime ?
                                                                                subItemDetail.TravelArrHour + ":" + subItemDetail.TravelArrMinute : ""}}
                                                                            </v-col>
                                                                        </v-row>
                                                                    </div>
                                                                    <br />
                                                                </div>
                                                                <v-layout no-gutters>
                                                                    <v-flex>
                                                                        <span class="font-W500" color="black" size="3">
                                                                            Ticket Status: <v-chip class="mr-2"
                                                                            :color="statusColor(subItem.BTTStatus)[0]"
                                                                            :text-color="statusColor(subItem.BTTStatus)[1]" x-small>
                                                                            {{ subItem.BTTStatus ? subItem.BTTStatus : 'Booked'}}</v-chip>
                                                                        </span>
                                                                        <br />
                                                                    </v-flex>
                                                                </v-layout>
                                                                <v-layout no-gutters class="mb-3">
                                                                    <v-flex>
                                                                        <span style="color: #FF5656;">
                                                                            THB {{ subItem.TotalFare.toLocaleString('th',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                                                        </span>
                                                                    </v-flex>
                                                                </v-layout>
                                                                <v-layout no-gutters>
                                                                    <v-flex style="color: white">
                                                                        Departure time <span class="font-W500">
                                                                            {{ formatDate(subItem.DepartureDateStart, 'DD MMM YYYY HH:mm:ss') }}
                                                                        </span>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                        </v-layout>
                                                    </td>
                                                    <!-- Hotel -->
                                                    <td>
                                                        <v-layout class="mt-2" v-for="(subItem, n) in item.Hotel" :key="n">
                                                            <v-flex class="d-flex flex-column">
                                                                <v-layout>
                                                                    <v-flex>
                                                                        <v-card tile elevation="0" style="border: 1px solid #00AEEF; background: #EAF9FF;"
                                                                        class="pl-2 pr-2" width="200px">
                                                                            <v-layout>
                                                                                <v-flex>
                                                                                    <span style="color: #00AEEF;">Booking Ref.</span>
                                                                                </v-flex>
                                                                                <v-flex text-right>
                                                                                    <span class="font-W700 text-right" style="color: #00AEEF;">
                                                                                        {{ subItem.BookingNo }}
                                                                                    </span>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-card>
                                                                    </v-flex>
                                                                    <v-flex xs="1" text-right>
                                                                        <v-btn icon small elevation="0" @click="showHotelManagement(item, subItem)"
                                                                        style="background: #EBEEF4; border-radius: 5px; width: 36px !important;">
                                                                            <img class="shark" :src="require('@/assets/icons/EditDetail.svg')" width="20px" alt="" />
                                                                        </v-btn>
                                                                    </v-flex>
                                                                </v-layout>
                                                                <v-layout no-gutters class="pt-2 pb-2">
                                                                    <v-flex class="d-flex align-self-center">
                                                                        <span class="font-W500" color="black" size="3">{{ subItem.HotelName }}</span>
                                                                    </v-flex>
                                                                </v-layout>
                                                                <v-layout no-gutters>
                                                                    <v-flex>
                                                                        <span>Check-In</span>
                                                                        <span class="font-W500" color="black"><strong> {{ subItem.CheckinDateNewFormat }}</strong></span>
                                                                    </v-flex>
                                                                </v-layout>
                                                                <v-layout no-gutters>
                                                                    <v-flex>
                                                                        <span class="font-W500">{{ subItem.RoomAndBed }}</span>
                                                                    </v-flex>
                                                                </v-layout>
                                                                <v-layout no-gutters>
                                                                    <v-flex>
                                                                        <span style="color: #FF5656;">
                                                                            THB {{ subItem.TotalHotel.toLocaleString('th',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                                                        </span>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                        </v-layout>
                                                    </td>
                                                    <td>
                                                        <v-layout class="mt-2">
                                                            <v-flex class="d-flex flex-column">
                                                                <span v-if="!item.BudgetHolderApprover" class="font-W500"
                                                                color="black" size="3">Pending Budget Holder</span>
                                                                <span v-if="!item.LineApprovalD && BudgetHolder.substring(0, 3) != 'PSL'"
                                                                class="font-W500" color="black" size="3">Pending Line Approver</span>
                                                                <span v-if="item.BudgetHolderApprover" class="font-W500 mt-2"
                                                                color="black" size="3">Budget Holder</span>
                                                                <span v-if="item.LineApprovalD && BudgetHolder.substring(0, 3) !== 'PSL'"
                                                                class="font-W500 mt-2" color="black" size="3">Line Approver</span>
                                                                <v-card tile elevation="0" v-if="(item.BudgetHolderApprover && item.LineApprovalD &&
                                                                BudgetHolder.substring(0, 3) !== 'PSL') ||
                                                                (item.BudgetHolderApprover && BudgetHolder.substring(0, 3) === 'PSL')"
                                                                style="background: #A7F3CE;" class="pl-2 pr-2 mt-1" width="150px">
                                                                    <v-layout>
                                                                        <v-flex>
                                                                            <span style="color: black;">Approved Final</span>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </v-card>
                                                                <v-card tile elevation="0" v-else style="background: #fff8a8;" class="pl-2 pr-2 mt-1" width="150px">
                                                                    <v-layout>
                                                                        <v-flex>
                                                                            <span style="color: black;">Pending</span>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </v-card>
                                                            </v-flex>
                                                        </v-layout>
                                                    </td>
                                                    <td>
                                                        <v-layout class="mt-2">
                                                            <v-flex>
                                                                <v-btn elevation="0" class="mr-2" @click="openDocPassportVisa('Passport', item)"
                                                                style="background: #EBEEF4; border-radius: 5px; width: 36px !important;">
                                                                    <v-img class="shrink" :src="require('@/assets/icons/passport1.svg')" width="20px"></v-img>
                                                                </v-btn>
                                                                <v-btn elevation="0" class="mr-2" @click="openDocPassportVisa('VISA', item)"
                                                                style="background: #EBEEF4; border-radius: 5px; width: 36px !important;">
                                                                    <v-img class="shrink" :src="require('@/assets/icons/visa_1.svg')" width="20px"></v-img>
                                                                </v-btn>
                                                                <v-btn elevation="0" class="mr-2" @click="getTravelRequest(item)"
                                                                style="background: #EBEEF4; border-radius: 5px; width: 36px !important;">
                                                                    <img class="shark" :src="require('@/assets/icons/search_doc.svg')" width="20px" alt="" />
                                                                </v-btn>
                                                                <v-btn elevation="0" style="background: #EBEEF4; border-radius: 5px; width: 36px !important;">
                                                                    <img class="shark" :src="require('@/assets/icons/printer.svg')" width="20px" alt="" />
                                                                </v-btn>
                                                            </v-flex>
                                                        </v-layout>
                                                    </td>
                                                </tr>
                                            </table>
                                        </v-layout>
                                        <v-layout class="mt-3">
                                            <v-flex>
                                                <span>Traveler  {{ Traveler.length }}  ( Internal {{ calInternal }}, External {{ calExternal }} )</span>
                                            </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row><br>
                        <v-row class="mb-3" no-gutters>
                            <v-col>
                                <span>
                                    <span class="font-W700" style="font-size: 1.1em;">Summary</span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <v-card class="card-detail" elevation="0">
                                    <v-card-text>
                                        <v-layout>
                                            <v-flex>
                                                <span class="font-W700" color="black">
                                                    Summary
                                                </span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout class="mt-2">
                                            <v-flex class="d-flex flex-column lg2 md3 sm4">
                                                <span class="font-W500">
                                                    Total Number of Persons
                                                </span>
                                                <span class="font-W500" color="black">
                                                    {{ Traveler.length }} Person
                                                </span>
                                            </v-flex>
                                            <v-flex class="d-flex flex-column">
                                                <span class="font-W500">
                                                    Total Advance Amount
                                                </span>
                                                <span class="font-W500" color="black">
                                                    {{ TotalAdvanceAmount.toLocaleString('th',{
                                                        minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} THB
                                                </span>
                                            </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card class="card-detail" elevation="0">
                                    <v-card-text>
                                        <v-layout class="mt-2" wrap>
                                            <v-flex class="text-center text-lg-left text-md-left text-sm-left lg6 md6 sm6 xs12">
                                                <v-btn elevation="0" class="pr-1" style="background: rgba(0, 159, 218, 0.1); border-radius: 5px; width: 135px !important;">
                                                    <img class="shark" :src="require('@/assets/icons/printer.svg')" width="20px" alt="" />
                                                    <span class="ml-2">Print</span>
                                                </v-btn>
                                                <v-btn elevation="0" class="ml-1"
                                                style="background: rgba(0, 159, 218, 0.1); border-radius: 5px; width: 135px !important;">
                                                    <img class="shark" :src="require('@/assets/icons/printer.svg')" width="20px" alt="" />
                                                    <span class="ml-2">Assign to</span>
                                                </v-btn>
                                            </v-flex>
                                            <v-flex class="text-center text-lg-right text-md-right text-sm-right lg6 md6 sm6 xs12 mt-1 mt-sm-0 mt-md-0 mt-lg-0">
                                                <v-btn elevation="0" class="pr-1"
                                                style="background: rgba(0, 159, 218, 0.1); border-radius: 5px; width: 135px !important;">
                                                    <img class="shark" :src="require('@/assets/icons/dollar_return.svg')" width="20px" alt="" />
                                                    <span class="ml-2">Refund</span>
                                                </v-btn>
                                                <v-btn :disabled="RemainningDays === 0 || BTTStatus !== 'Booked'" elevation="0" class="ml-1" @click="showDialogVerify()"
                                                style="background: rgba(121, 192, 70, 0.1); border-radius: 5px; width: 135px !important;">
                                                    <img class="shark" :src="require('@/assets/icons/check_doc_green.svg')" width="20px" alt="" />
                                                    <span class="ml-2">Verify</span>
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <v-row class="mb-3" no-gutters>
                            <v-col>
                                <span>
                                    <span class="font-W700" style="font-size: 1.1em;">Note</span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <v-card class="card-note" elevation="0">
                                    <v-row class="overflow-x-auto myTicket mt-2" style="margin: 1px; max-height: 252px;">
                                        <v-col>
                                            <table style="border-collapse: collapse; width: 100%" aria-label="" id="Note">
                                                <tr>
                                                    <th id="ActionDate1">Action Date</th>
                                                    <th id="TimeStamp1">Time Stamp</th>
                                                    <th id="Note1">Note</th>
                                                    <th id="Notedby1">Noted by</th>
                                                </tr>
                                                <tr v-for="(item, index) in TravelRequestNote" :key="index">
                                                    <td>
                                                        <span class="font-W500">{{ item.ActionDate }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="font-W500">{{ item.TimeStamp }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ item.Note }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="font-W500">{{ item.NoteBy }}</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3" no-gutters>
                            <v-col>
                                <v-textarea outlined dense v-model="Note"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="text-center text-lg-right text-md-right text-sm-right">
                                <v-btn elevation="0" color="primary" width="150" @click="addNote()">Add Note</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <v-row class="mb-3" no-gutters>
                            <v-col>
                                <span>
                                    <span class="font-W700" style="font-size: 1.1em;">Action History</span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <v-card class="card-note" elevation="0">
                                    <v-row class="overflow-x-auto myTicket mt-2" style="margin: 1px; max-height: 252px;">
                                        <v-col>
                                            <table style="border-collapse: collapse; width: 100%" aria-label="" id="Note">
                                                <tr>
                                                    <th id="ActionDate1">Action Date</th>
                                                    <th id="TimeStamp1">Time Stamp</th>
                                                    <th id="ActionName1">Action Name</th>
                                                    <th id="ActionResult1">Action Result</th>
                                                    <th id="Comment1">Comment</th>
                                                </tr>
                                                <tr v-for="(item, index) in TravelRequestLog" :key="index">
                                                    <td>
                                                        <span class="font-W500">{{ item.ActionDate }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="font-W500">{{ item.TimeStamp }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ item.ActionName }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="font-W500">{{ item.ActionResult }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="font-W500">{{ item.Comment }}</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3" no-gutters>
                            <v-col>
                                <span>
                                    <span class="font-W700" style="font-size: 1.1em;">Life Cycle</span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3" no-gutters>
                            <v-col>
                                <v-card class="card-detail" elevation="0">
                                    <v-card-text>
                                        <v-row class="overflow-x-auto">
                                            <v-col>
                                                <table style="white-space: nowrap; border-collapse: collapse;" aria-label="">
                                                    <tr hidden><th></th></tr>
                                                    <tr>
                                                        <td>
                                                            <table style="white-space: nowrap; border-collapse: collapse;" aria-label="">
                                                                <tr hidden><th></th></tr>
                                                                <tr>
                                                                    <td>
                                                                        <span class="font-W500" color="black">
                                                                            Budget Holder :
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <table style="white-space: nowrap; border-collapse: collapse;" aria-label="">
                                                                            <tr hidden><th></th></tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <v-img v-if="HaveBudgetHolder" class="ml-2"
                                                                                    :src="require('@/assets/icons/Green_check.png')" width="10" alt=""></v-img>
                                                                                </td>
                                                                                <td>
                                                                                    <span class="font-W500 ml-2" color="black">
                                                                                        {{ lifeCycleItem.length > 0 ? lifeCycleItem[0].BudgetHolderApprover : "" }}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <table style="white-space: nowrap; border-collapse: collapse;"
                                                v-for="(item, index) in lifeCycleItem" :key="index" aria-label="">
                                                    <tr hidden><th></th></tr>
                                                    <tr v-if="item.LINE_APPROVE">
                                                        <td>
                                                            <ul>
                                                                <table style="white-space: nowrap; border-collapse: collapse;" aria-label="">
                                                                    <tr hidden><th></th></tr>
                                                                    <tr>
                                                                        <td style="vertical-align: top;">
                                                                            <v-img v-if="item.HaveLineApprove" style="margin-top: 6px;"
                                                                            :src="require('@/assets/icons/Green_check.png')" width="10" alt=""></v-img>
                                                                        </td>
                                                                        <td>
                                                                            <span class="font-W500 ml-2" color="black">{{ item.LINE_APPROVE }}</span><br>
                                                                            <table style="white-space: nowrap; border-collapse: collapse;" aria-label="">
                                                                                <tr hidden><th></th></tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <v-img :src="require('@/assets/icons/Frame.svg')"
                                                                                        class="ml-2" width="15"></v-img>
                                                                                    </td>
                                                                                    <td>
                                                                                        <table style="white-space: nowrap; border-collapse: collapse;" aria-label="">
                                                                                            <tr hidden><th></th></tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="font-W500 ml-2" color="black">{{ item.Traveler }}</span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-3" no-gutters>
            <v-col>
                <ul>
                    <li>
                        <span style="color: #848484;" size="2">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pulvinar
                            facilisis maecenas mi sem sollicitudin orci, phasellus eros. Vel
                            posuere amet, amet, netus egestas nulla sem ultrices nullam.
                        </span>
                    </li>
                </ul>
                <ul>
                    <li>
                        <span style="color: #848484;" size="2">
                            Vel posuere amet, amet, netus egestas nulla sem ultrices nullam.
                        </span>
                    </li>
                </ul>
            </v-col>
        </v-row>
        <v-dialog v-model="itemFocus.dialog" v-if="itemFocus.dialog">
            <v-card>
                <v-card-title>
                    <v-row class="mt-5">
                        <v-col cols="4">
                            <v-text-field v-model="dialogTicketManagement.value.PNR" outlined background-color="white" dense></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-btn color="primary" @click="PNR_Retrieve(dialogTicketManagement.value.PNR)" :loading="TicketRevieve.loading">PNR Retrieve</v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text v-if="TicketRevieve.show">
                    <div class="bgcard">
                        PNR Retrieve
                        <div v-if="TicketRevieve.value === 'Not Found'">Not Found</div>
                        <template v-if="TicketRevieve.value && TicketRevieve.value.length > 0">
                            <v-col v-for="(flightItem, flightIndex) in TicketRevieve.value" :key="flightIndex">
                                <v-card class="card">
                                    <v-card-text class="">
                                        <v-row align="center">
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="1">
                                                        <template v-if="flightItem.Airline">
                                                                <v-img class="mt-2" v-if="showAirlineLogo(flightItem.Airline) !== 'NoImg'"
                                                                :src="require(`@/assets/logoAirline/square/${showAirlineLogo(flightItem.Airline)}.png`)"
                                                                width="60"></v-img>
                                                                 <div v-else class="text-center mt-6">
                                                                    No Image
                                                                </div>
                                                        </template>
                                                        <template v-else>
                                                                <v-img class="mt-5" :src="require(`@/assets/icons/Add.svg`)" width="30"></v-img>
                                                        </template>
                                                    </v-col>
                                                    <v-col cols="5">
                                                        Airline
                                                        <v-autocomplete v-model="flightItem.Airline" :items="Airlines" background-color="white"
                                                        item-value="Code" item-text="Name" @input.native="SearchAirlineCode($event.srcElement.value)"
                                                        hide-details dense outlined>
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col v-if="true" cols="6">
                                                        Trip Type
                                                        <v-select outlined background-color="white" dense :items="tripTypes"
                                                        item-text="text" item-value="code" hide-details v-model="flightItem.TripType"></v-select>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="6">
                                                Route From <span style="color: red">*</span>
                                                <v-autocomplete v-model="flightItem.OriginCode" placeholder="From" background-color="white"
                                                    @input.native="getCityCodes($event.srcElement.value, 'From')" hide-details
                                                    ref="RouteFrom" :items="citytotravelFrom" item-text="text"
                                                    item-value="Code" dense outlined>
                                                        <template v-slot:[`item`]="{item}">
                                                            <v-row @click="pushCity(item)">
                                                                <v-col cols="12">
                                                                    {{ item.text }}
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </v-autocomplete>
                                            </v-col>
                                            <v-col cols="6">
                                                Route To <span style="color: red">*</span>
                                                <v-autocomplete dense outlined placeholder="To" hide-details
                                                    @input.native="getCityCodes($event.srcElement.value, 'To')" background-color="white"
                                                    :items="citytotravelTo" item-text="text" item-value="Code"
                                                    v-model="flightItem.DestinationCode" ref="RouteTo">
                                                <template v-slot:[`item`]="{item}">
                                                        <v-row @click="pushCity(item)">
                                                            <v-col cols="12">
                                                                {{ item.text }}
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-row>
                                                    <v-col cols="8">
                                                        Departure Date <span style="color: red">*</span>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        Departure time <span style="color: red">*</span>
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <v-menu v-model="flightItem.travelDepDateMenu" :close-on-content-click="false"
                                                            :nudge-right="40" transition="scale-transition" offset-y
                                                            min-width="auto">
                                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                                <v-text-field :value="formatDate(flightItem.DepartureDatetime, 'DD-MM-YYYY')" background-color="white" hide-details
                                                                    outlined dense readonly v-bind="attrs" v-on="on">
                                                                    <v-icon slot="append" color="info">
                                                                        mdi-calendar
                                                                    </v-icon>
                                                                </v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="flightItem.DepartureDatetime"
                                                                @input="flightItem.travelDepDateMenu = false" :min="nowDate">
                                                            </v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="2" class="mb-6">
                                                        <v-text-field dense outlined placeholder="HH" background-color="white"
                                                            v-model="flightItem.TravelDepHour" hide-details></v-text-field>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-text-field dense outlined placeholder="MM" background-color="white"
                                                            v-model="flightItem.TravelDepMinute" hide-details></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-row>
                                                    <v-col cols="8">
                                                        Arrival Date <span style="color: red">*</span>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        Arrival time <span style="color: red">*</span>
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <v-menu v-model="flightItem.travelArrDateMenu" :close-on-content-click="false"
                                                            :nudge-right="40" transition="scale-transition" offset-y
                                                            min-width="auto">
                                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                                <v-text-field :value="formatDate(flightItem.ArrivalDatetime, 'DD-MM-YYYY')" background-color="white" hide-details
                                                                    outlined dense readonly v-bind="attrs" v-on="on">
                                                                    <v-icon slot="append" color="info">
                                                                        mdi-calendar
                                                                    </v-icon>
                                                                </v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="flightItem.ArrivalDatetime"
                                                                @input="flightItem.travelArrDateMenu = false" :min="nowDate">
                                                            </v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="2" class="mb-6">
                                                        <v-text-field dense outlined placeholder="HH" background-color="white"
                                                            v-model="flightItem.TravelArrHour" hide-details></v-text-field>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-text-field dense outlined placeholder="MM" background-color="white"
                                                            v-model="flightItem.TravelArrMinute" hide-details></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="3">
                                                Flight No. <span style="color: red">
                                                </span>
                                                <v-text-field dense outlined background-color="white" hide-details
                                                    v-model="flightItem.FlightNumber"></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                Seat Class <span style="color: red">*
                                                </span>
                                                <v-select dense outlined :items="svcClass" item-value="BookingCode" background-color="white" hide-details
                                                    v-model="flightItem.Svc_class" item-text="Description">
                                                </v-select>
                                            </v-col>
                                            <v-col cols="3">
                                                PNR No.
                                                <v-text-field dense outlined v-model="flightItem.PNR" background-color="white" hide-details>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </template>
                    </div>
                </v-card-text>
                <br />
                <v-card-text v-if="TicketRevieve.value && TicketRevieve.value.length === 0">
                    <div class="bgcard" >
                        <span v-if="TicketRevieve.show">Old Booking</span>
                        <v-col v-for="(flightItem, flightIndex) in itemFocus.TicketList" :key="flightIndex" :style="TicketRevieve.show ? 'pointer-events: none' : ''">
                            <v-card :class="TicketRevieve.show ? 'bgcard' : 'card'">
                                <v-card-title>
                                    Route {{flightItem.number}}
                                    <v-spacer></v-spacer>
                                    <v-btn class="btn-primary" @click="removeRoute(flightItem.orderFlight)">
                                        <v-img class="mr-2" width="15" :src="require(`@/assets/icons/CancelInfo.svg`)" /> Delete
                                    </v-btn>
                                </v-card-title>
                                <v-card-text class="">
                                    <v-row align="center">
                                        <v-col cols="12">
                                            <v-row>
                                                <v-col cols="1">
                                                    <template v-if="flightItem.Airline">
                                                            <v-img class="mt-2" v-if="showAirlineLogo(flightItem.Airline) !== 'NoImg'"
                                                            :src="require(`@/assets/logoAirline/square/${showAirlineLogo(flightItem.Airline)}.png`)"
                                                            width="60"></v-img>
                                                            <div v-else class="text-center mt-6">
                                                                No Image
                                                            </div>
                                                    </template>
                                                    <template v-else>
                                                            <v-img class="mt-5" :src="require(`@/assets/icons/Add.svg`)" width="30"></v-img>
                                                    </template>
                                                </v-col>
                                                <v-col cols="5">
                                                    Airline
                                                    <v-autocomplete v-model="flightItem.Airline" :items="Airlines" background-color="white"
                                                    item-value="Code" item-text="Name" @input.native="SearchAirlineCode($event.srcElement.value)"
                                                    hide-details dense outlined>
                                                        <template v-slot:[`item`]="{item}">
                                                            <v-row @click="pushAirline(item)">
                                                                <v-col cols="12">
                                                                    {{ item.Name }}
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col v-if="true" cols="6">
                                                    Trip Type
                                                    <v-select outlined background-color="white" dense :items="tripTypes"
                                                    item-text="text" item-value="code" hide-details v-model="flightItem.TripType"></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="6">
                                            Route From <span style="color: red">*</span>
                                            <v-autocomplete v-model="flightItem.OriginCode" placeholder="From" background-color="white"
                                                @input.native="getCityCodes($event.srcElement.value, 'From')" hide-details
                                                ref="RouteFrom" :items="citytotravelFrom" item-text="text"
                                                item-value="Code" dense outlined>
                                                    <template v-slot:[`item`]="{item}">
                                                        <v-row @click="pushCity(item)">
                                                            <v-col cols="12">
                                                                {{ item.text }}
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </v-autocomplete>
                                        </v-col>
                                        <v-col cols="6">
                                            Route To <span style="color: red">*</span>
                                            <v-autocomplete dense outlined placeholder="To" hide-details
                                                @input.native="getCityCodes($event.srcElement.value, 'To')" background-color="white"
                                                :items="citytotravelTo" item-text="text" item-value="Code"
                                                v-model="flightItem.DestinationCode" ref="RouteTo">
                                            <template v-slot:[`item`]="{item}">
                                                    <v-row @click="pushCity(item)">
                                                        <v-col cols="12">
                                                            {{ item.text }}
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-row>
                                                <v-col cols="8">
                                                    Departure Date <span style="color: red">*</span>
                                                </v-col>
                                                <v-col cols="4">
                                                    Departure time <span style="color: red">*</span>
                                                </v-col>
                                                <v-col cols="8">
                                                    <v-menu v-model="flightItem.travelDepDateMenu" :close-on-content-click="false"
                                                        :nudge-right="40" transition="scale-transition" offset-y
                                                        min-width="auto">
                                                        <template v-slot:[`activator`]="{ on, attrs }">
                                                            <v-text-field :value="formatDate(flightItem.DepartureDatetime, 'DD-MM-YYYY')" background-color="white" hide-details
                                                                outlined dense readonly v-bind="attrs" v-on="on">
                                                                <v-icon slot="append" color="info">
                                                                    mdi-calendar
                                                                </v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="flightItem.DepartureDatetime"
                                                            @input="flightItem.travelDepDateMenu = false" :min="nowDate">
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="2" class="mb-6">
                                                    <v-text-field dense outlined placeholder="HH" background-color="white"
                                                        v-model="flightItem.TravelDepHour" hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-text-field dense outlined placeholder="MM" background-color="white"
                                                        v-model="flightItem.TravelDepMinute" hide-details></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-row>
                                                <v-col cols="8">
                                                    Arrival Date <span style="color: red">*</span>
                                                </v-col>
                                                <v-col cols="4">
                                                    Arrival time <span style="color: red">*</span>
                                                </v-col>
                                                <v-col cols="8">
                                                    <v-menu v-model="flightItem.travelArrDateMenu" :close-on-content-click="false"
                                                        :nudge-right="40" transition="scale-transition" offset-y
                                                        min-width="auto">
                                                        <template v-slot:[`activator`]="{ on, attrs }">
                                                            <v-text-field :value="formatDate(flightItem.ArrivalDatetime, 'DD-MM-YYYY')" background-color="white" hide-details
                                                                outlined dense readonly v-bind="attrs" v-on="on">
                                                                <v-icon slot="append" color="info">
                                                                    mdi-calendar
                                                                </v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="flightItem.ArrivalDatetime"
                                                            @input="flightItem.travelArrDateMenu = false" :min="nowDate">
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="2" class="mb-6">
                                                    <v-text-field dense outlined placeholder="HH" background-color="white"
                                                        v-model="flightItem.TravelArrHour" hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-text-field dense outlined placeholder="MM" background-color="white"
                                                        v-model="flightItem.TravelArrMinute" hide-details></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3">
                                            Flight No. <span style="color: red">
                                            </span>
                                            <v-text-field dense outlined background-color="white" hide-details
                                                v-model="flightItem.FlightNumber"></v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            Seat Class <span style="color: red">*
                                            </span>
                                            <v-select dense outlined :items="svcClass" item-value="BookingCode" background-color="white" hide-details
                                                v-model="flightItem.Svc_class" item-text="Description">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="3">
                                            PNR No.
                                            <v-text-field dense outlined v-model="flightItem.PNR" background-color="white" hide-details>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <div class="h-space"></div>
                        <v-card elevation="0">
                            <v-card-actions>
                                <v-btn class="btn-primary" @click="addRoute()">
                                    <v-icon>mdi-plus</v-icon> Add Flight
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="primary" width="120" @click="itemFocus.dialog = false">Cancel</v-btn>
                    <template>
                        <v-btn color="primary" width="120" @click="itemFocus.value.BookingOID ? InsertFlightDetail() : null">
                            Save
                        </v-btn>
                    </template>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogTicketManagement.dialog" v-if="dialogTicketManagement.dialog" persistent >
            <v-card color="primary" class="pt-1 card">
                <v-card>
                    <v-card-text>
                        <br />
                        Ticket Information
                        <div class="h-space"></div>
                        <div class="bgcard">
                            <div class="h-space"></div>
                            <v-row>
                                <v-col cols="4">
                                    Agency Type
                                    <span style="color: red" v-if="dialogTicketManagement.TicketList.filter(x => x.Airline === 'TG').length ===
                                        dialogTicketManagement.TicketList.length && TGDirectTicket && TGDirectTicket.TGDirect === 1">
                                        *TG Direct
                                    </span>
                                    <v-select dense outlined hide-details background-color="white"
                                    v-model="dialogTicketManagement.value.AgentID"
                                    item-text="Name" item-value="AgentID" :items="agencyType">
                                        <template v-slot:[`item`]="{item}">
                                            <v-row @click="setAmount(item.AgentID)">
                                                <v-col cols="12">
                                                    {{item.Name}}
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="4">
                                    Booking Code
                                    <v-text-field dense outlined hide-details background-color="white" v-model="dialogTicketManagement.value.PNR"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    Ref Ticket No
                                    <v-text-field dense outlined hide-details background-color="white" v-model="dialogTicketManagement.value.RefTicketNo"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    Reuse
                                    <v-select dense outlined hide-details background-color="white"
                                    item-text="text" item-value="code" :items="reuse"
                                    v-model="dialogTicketManagement.value.Reuse"></v-select>
                                </v-col>
                                <v-col cols="4">
                                    Ticket No
                                    <v-text-field dense outlined hide-details background-color="white" v-model="dialogTicketManagement.value.TicketNo"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    Invoice No
                                    <v-text-field dense outlined hide-details background-color="white" v-model="dialogTicketManagement.value.InvoiceNo"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    Approval Code
                                    <v-text-field dense outlined hide-details background-color="white" v-model="dialogTicketManagement.value.ApprovalCode"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    Re-Issue Type
                                    <v-select dense outlined hide-details background-color="white"
                                    :items="reissueType" v-model="dialogTicketManagement.value.ReIssueType"></v-select>
                                </v-col>
                                <v-col cols="4">
                                    Amount
                                    <v-text-field dense outlined hide-details background-color="white" v-model="dialogTicketManagement.value.TotalFare"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    Service Fee
                                    <v-text-field dense outlined hide-details background-color="white" v-model="dialogTicketManagement.value.NFTFee"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    Vat
                                    <v-text-field dense outlined hide-details background-color="white" v-model="dialogTicketManagement.value.Vat"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    Issue Date
                                    <v-menu v-model="issueDateOffMenu" :close-on-content-click="false"
                                    :nudge-right="40" transition="scale-transition" offset-y
                                    min-width="auto">
                                        <template v-slot:[`activator`]="{ on, attrs }">
                                            <v-text-field :value="formatDate(dialogTicketManagement.value.IssueDate, 'DD-MMM-YYYY')"
                                            background-color="white" hide-details outlined dense readonly v-bind="attrs" v-on="on">
                                                <v-icon slot="append" color="info">
                                                    mdi-calendar
                                                </v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="dialogTicketManagement.value.IssueDate"
                                            @input="issueDateOffMenu = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="4">
                                    Total Amount
                                    <v-text-field dense outlined hide-details background-color="white" v-model="TotalAmount"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    Seat Class <span style="color: red">*
                                    </span>
                                    <v-select dense outlined :items="svcClass" item-value="BookingCode" background-color="white" hide-details
                                        v-model="dialogTicketManagement.value.Svc_class" item-text="Description">
                                    </v-select>
                                </v-col>
                                <v-col cols="4">
                                    Status
                                    <v-select dense outlined hide-details :items="status" v-model="dialogTicketManagement.value.BTTStatus" background-color="white"></v-select>
                                </v-col>
                                <template v-if="dialogTicketManagement.value.BTTStatus === 'Refund'">
                                    <v-col cols="12" lg="4" md="4" sm="4" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>Request to Refund Date</span>
                                        <v-menu v-model="RequestToRefundDateMenu" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field :value="formatDate(
                                                    dialogTicketManagement.value.RequestToRefundDate, 'DD-MMM-YYYY')"
                                                    background-color="white" hide-details
                                                    outlined dense readonly v-bind="attrs" v-on="on">
                                                    <v-icon slot="append" color="info">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="dialogTicketManagement.value.RequestToRefundDate"
                                                @input="RequestToRefundDateMenu = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="4" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>Receipt Slip Date</span>
                                        <v-menu v-model="ReceiptSlipDateMenu" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field :value="formatDate(dialogTicketManagement.value.ReceiptSlipDate, 'DD-MMM-YYYY')" background-color="white" hide-details
                                                    outlined dense readonly v-bind="attrs" v-on="on">
                                                    <v-icon slot="append" color="info">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="dialogTicketManagement.value.ReceiptSlipDate"
                                                @input="ReceiptSlipDateMenu = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="4" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>Refund Slip No</span>
                                        <v-text-field dense outlined hide-details v-model="dialogTicketManagement.value.RefundSlipNo" background-color="white"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="4" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>Received Date</span>
                                        <v-menu v-model="ReceivedDateMenu" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field :value="formatDate(dialogTicketManagement.value.ReceivedDate, 'DD-MMM-YYYY')" background-color="white" hide-details
                                                    outlined dense readonly v-bind="attrs" v-on="on">
                                                    <v-icon slot="append" color="info">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="dialogTicketManagement.value.ReceivedDate"
                                                @input="ReceivedDateMenu = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </template>
                            </v-row>
                        </div>
                        <div class="h-space"></div>
                        Agency Ticket Price
                        <div class="h-space"></div>
                        <div class="bgcard">
                            <v-row v-if="agencyTicketPrice && agencyTicketPrice.length > 0">
                                <template v-for="(item, index) in agencyTicketPrice">
                                    <template v-if="item.Name !== 'Nok Air' && item.Name !== 'Thai Airways'">
                                        <v-col cols="2" :key="`a${index}`" class="mt-2">
                                            Rank {{item.Rank}}. {{item.Name}}
                                        </v-col>
                                        <v-col cols="3" :key="`b${index}`">
                                            <v-text-field dense outlined hide-details background-color="white" v-model="item.FareCharge"></v-text-field>
                                        </v-col>
                                        <v-col cols="6" :key="`c${index}`" class="mt-2">THB</v-col>
                                    </template>
                                </template>
                            </v-row>
                            <span v-else>
                                No Data
                            </span>
                        </div>
                        <div class="h-space"></div>
                        Ticket List
                        <div class="h-space"></div>
                        <div class="bgcard">
                            <v-card-title>
                                <v-spacer></v-spacer>
                                <v-btn elevation="0" @click="openTicketList(dialogTicketManagement)" class="btn-primary" color="Secretary">
                                    <img class="shark" :src="require('@/assets/icons/EditDetail.svg')" width="25px" alt="" />
                                    Edit Flight
                                </v-btn>
                            </v-card-title>
                            <v-data-table v-if="dialogTicketManagement.TicketList" :key="render"
                            :headers="ticketHeader" :items="dialogTicketManagement.TicketList"
                            hide-default-footer>
                                <template v-slot:[`item.no`]="{index}">
                                    <td>
                                        {{ ++index }}
                                    </td>
                                </template>
                                <template v-slot:[`item.Traveler`]="{item}">
                                    <td>
                                        {{ item.Name }}
                                    </td>
                                </template>
                                <template v-slot:[`item.DepartureDatetime`]="{item}">
                                    <td>
                                        {{ formatDate(item.DepartureDatetime, 'DD-MMM-YYYY') }}
                                    </td>
                                </template>
                                <template v-slot:[`item.Route`]="{item}">
                                    <td>
                                        {{ item.OriginCode }} - {{ item.DestinationCode}}
                                    </td>
                                </template>
                                <template v-slot:[`item.Cdate`]="{item}">
                                    <td>
                                        {{ item.OriginalBookingOID ? formatDate(item.Cdate, 'DD-MMM-YYYY HH:mm') : ''}}
                                    </td>
                                </template>
                                <template v-slot:[`item.PNR`]="{item}">
                                    <td>
                                        {{ item.PNR }}
                                    </td>
                                </template>
                                <template v-slot:[`item.FlightNo`]="{item}">
                                    <td>
                                        {{ item.FlightNo }}
                                    </td>
                                </template>
                                <template v-slot:[`item.Time`]="{item}">
                                    <td>
                                        {{ item.TravelDepHour }}:{{item.TravelDepMinute}} - {{ item.TravelArrHour }}:{{item.TravelArrMinute}}
                                    </td>
                                </template>
                                <template v-slot:[`item.SeatNo`]="{item}">
                                    <td>
                                        {{ item.SeatNo }}
                                    </td>
                                </template>
                                <template  v-slot:[`item.Svc_class`]="{item}">
                                    <td>
                                        <span v-if="item.Svc_class">
                                            <span v-if="item.Svc_class === 'Y' || item.Svc_class === 'W' || item.Svc_class === 'M'">Economy</span>
                                            <span v-else-if="item.Svc_class === 'C'">Business</span>
                                            <span v-else-if="item.Svc_class === 'F'">First</span>
                                            Class
                                        </span>
                                    </td>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="dialogTicketManagement.dialog = false" text>Cancel</v-btn>
                        <v-btn color="primary" @click="SaveTicketInfomation()">Save</v-btn>
                        <v-btn class="btn-success" v-if="(dialogTicketManagement.value.BTTStatus ===  'Approve Final' ||
                            dialogTicketManagement.value.BTTStatus === 'Booked' ||
                            dialogTicketManagement.value.BTTStatus === 'Re-Issue')" @click="verify('Issuing')">Verify</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogVerify" persistent width="900px">
            <v-card elevation="0" title color="primary" class="pt-1 card">
                <v-card>
                    <v-card-text>
                        <v-card class="card-detail" title elevation="0">
                            <v-card-text>
                                <v-row>
                                    <v-col class="text-center">
                                        <span size="6" color="black">
                                            <strong>Verify</strong>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="text-center">
                                        <span size="3" color="#464646">You confirm that this item has been reviewed.</span>
                                    </v-col>
                                </v-row>
                                <v-row class="overflow-x-auto myTicket mt-2" style="margin: 1px; max-height: 252px;">
                                    <v-col>
                                        <table style="border-collapse: collapse; width: 100%;" aria-label="" id="Verify">
                                            <tr>
                                                <th id="Traveler1">Traveler</th>
                                                <th id="Traveler2">Flight</th>
                                                <th id="Traveler3" class="text-right">Price in TR</th>
                                                <th id="Traveler4" class="text-right">Current Price</th>
                                                <th id="Traveler5">Hotel</th>
                                            </tr>
                                            <tr v-for="(item, index) in Traveler" :key="index" style="border-top: 1px solid #EBEEF4;">
                                                <td>
                                                    <span class="font-W500">{{ item.Name }}</span>
                                                </td>
                                                <td>
                                                    <v-layout no-gutters class="mt-1 mb-1" v-for="(subItem, n) in item.Flight" :key="n">
                                                        <v-flex class="d-flex align-self-center">
                                                            <span class="font-W500" color="black" size="3">{{ subItem.Origin }}</span>
                                                            &nbsp;&nbsp;
                                                            <img class="d-flex align-self-center"
                                                            :src="require('@/assets/icons/arrow_left_right.svg')" height="12px" alt="" />
                                                            &nbsp;&nbsp;
                                                            <span class="font-W500" color="black" size="3">{{ subItem.Destination }}</span>
                                                        </v-flex>
                                                    </v-layout>
                                                </td>
                                                <td class="text-right">
                                                    <v-layout no-gutters>
                                                        <v-flex>
                                                            <span class="font-W500" color="black" size="3">
                                                                {{ calPriceTR(item.Flight).toLocaleString('th',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                                            </span>
                                                        </v-flex>
                                                    </v-layout>
                                                </td>
                                                <td class="text-right">
                                                    <v-layout no-gutters>
                                                        <v-flex>
                                                            <span class="font-W500" color="black" size="3">
                                                                {{ calCurPrice(item.Flight).toLocaleString('th',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                                            </span>
                                                        </v-flex>
                                                    </v-layout>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn outlined color="primary" class="mr-1" @click="closeVerify()">Close</v-btn>
                                <v-btn color="primary" class="ml-1" @click="confirmVerify()">Confirm</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogPassportVisa" persistent width="900px">
            <v-card elevation="0" title color="primary" class="pt-1 card">
                <v-card>
                    <v-card-text>
                        <v-card class="card-detail" title elevation="0">
                            <v-card-text>
                                <v-row>
                                    <v-col class="text-center">
                                        <span size="6" color="black">
                                            <strong>{{ objDoc.title }}</strong>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="text-center">
                                        <span size="3" color="#464646">{{ objDoc.subTitle }}</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select outlined dense :placeholder="objDoc.title + 'Document'"
                                        :items="objDoc.docItem" @change="getDocPassportVisa()"
                                        item-text="text" item-value="val" v-model="objDoc.doc"></v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn outlined color="primary" class="mr-1" @click="closeDocPassportVisa()">Close</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogHotelManagement" persistent width="900px">
            <v-card elevation="0" title color="primary" class="pt-1 card">
                <v-card>
                    <v-card-text>
                        <strong>Hotel Information</strong>
                        <v-card class="card-detail" title elevation="0">
                            <v-card-text>
                                <v-row>
                                    <v-col class="text-center">
                                        <span size="3" color="#464646">{{ objDoc.subTitle }}</span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" lg="5" md="5" sm="5">
                                        <span>Check In
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-menu v-model="menuCheckinStart"
                                            :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field outlined dense background-color="white"
                                                    :value="formatDateLL(hotelObj.CheckinDateStart)"
                                                    readonly v-bind="attrs" v-on="on"
                                                    :rules="ruleTraveler.checkinDateStartReq">
                                                    <template v-slot:append>
                                                        <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="hotelObj.CheckinDateStart"
                                                @input="menuCheckinStart = false"
                                                color="#00AEEF"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" lg="5" md="5" sm="5" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>Check Out
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-menu v-model="menuCheckinEnd"
                                            :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field outlined dense background-color="white"
                                                    :value="formatDateLL(hotelObj.CheckinDateEnd)" readonly
                                                    v-bind="attrs" v-on="on">
                                                    <template v-slot:append>
                                                        <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="hotelObj.CheckinDateEnd"
                                                @input="menuCheckinEnd = false" color="#00AEEF"
                                                :min="minDateBTT"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" lg="2" md="2" sm="2" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>Night</span>
                                        <v-text-field outlined dense v-model="sumNightBTT" type="number"
                                            background-color="white" disabled></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" lg="6" md="6" sm="6">
                                        <span>Country
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-autocomplete outlined dense :items="CountryItem" ref="Country"
                                            :rules="ruleTraveler.CountryReq" @change="getCityCodeHotel()"
                                            background-color="white" placeholder="Country"
                                            v-model="hotelObj.Country" item-text="Name"
                                            item-value="Code"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>City or Province
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-autocomplete outlined dense :items="CityorProvinceItem"
                                            ref="CityorProvince" background-color="white"
                                            :rules="ruleTraveler.CityorProvinceReq" item-text="text"
                                            item-value="Code" placeholder="City or Province"
                                            v-model="hotelObj.CityorProvince"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" lg="6" md="6" sm="6">
                                        <span>Hotel Name
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-text-field outlined dense v-model="hotelObj.HotelName"
                                            background-color="white" :rules="ruleTraveler.HotelNameReq"
                                            ref="HotelName" placeholder="Hotel Name"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>Hotel Description</span>
                                        <v-text-field outlined dense v-model="hotelObj.Description"
                                            background-color="white" ref="Description"
                                            placeholder="Hotel Description"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="6">
                                        <span>Room Type
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-text-field outlined dense v-model="hotelObj.RoomType"
                                            background-color="white" :rules="ruleTraveler.RoomTypeReq"
                                            ref="RoomType" placeholder="Room Type"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="2" md="2" sm="12" class="pl-lg-2 pl-md-2">
                                        <span>Number of Room
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-text-field outlined dense placeholder="Number of Room"
                                            background-color="white" type="number"
                                            :rules="ruleTraveler.NumberOfRoomReq"
                                            v-model="hotelObj.NumberOfRoom"
                                            ref="NumberOfRoom"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" lg="6" md="6" sm="6">
                                        <span>Occupancy
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-text-field outlined dense v-model="hotelObj.Occupancy"
                                            background-color="white" :rules="ruleTraveler.OccupancyReq"
                                            ref="Occupancy" placeholder="Occupancy"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>Bed Type
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-text-field outlined dense v-model="hotelObj.BedType"
                                            background-color="white" :rules="ruleTraveler.BedTypeReq"
                                            ref="BedType" placeholder="Bed Type"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" lg="6" md="6" sm="6">
                                        <span>Price per night
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-text-field outlined dense v-model="hotelObj.PricePerNight"
                                            background-color="white" type="number"
                                            :rules="ruleTraveler.PricePerNightReq" ref="PricePerNight"
                                            placeholder="Price per night"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>Amount
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-text-field outlined dense v-model="hotelObj.Amount" type="number"
                                            :rules="ruleTraveler.AmountReq" ref="Amount"
                                            background-color="white" placeholder="Amount"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="2" md="2" sm="12" class="pl-lg-2 pl-md-2">
                                        <span>Currency
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-select outlined dense :items="CurrencyItem" ref="Currency"
                                            background-color="white" :rules="ruleTraveler.CurrencyReq"
                                            v-model="hotelObj.Currency"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" lg="4" md="4" sm="4">
                                        <span>Booking No</span>
                                        <v-text-field outlined dense v-model="hotelObj.HTNo"
                                        background-color="white" placeholder="Booking No"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="4" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>Email
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-text-field outlined dense v-model="hotelObj.HTL_EMAIL"
                                        background-color="white" placeholder="Email"
                                        :rules="ruleTraveler.EmailReq" ref="Email"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="4" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>Tel</span>
                                        <v-text-field outlined dense v-model="hotelObj.HTL_TEL"
                                        background-color="white" placeholder="Tel"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" lg="4" md="4" sm="4">
                                        <span>Fax</span>
                                        <v-text-field outlined dense v-model="hotelObj.HTL_FAX"
                                        background-color="white" placeholder="Fax"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="4" class="pl-lg-2 pl-md-2 pl-sm-2">
                                        <span>Status</span>
                                        <v-select outlined dense :items="status" ref="status"
                                        background-color="white" v-model="hotelObj.BTTStatus"></v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn outlined color="primary" class="mr-1" @click="closeDialogHotelManagement()">Close</v-btn>
                                <v-btn color="primary" elevation="0" class="ml-1 mr-1" @click="actionHotel('Save')">Save</v-btn>
                                <v-btn v-if="hotelObj.BTTStatus === 'Booked' ||
                                hotelObj.BTTStatus === 'Re-Issue' || hotelObj.BTTStatus === 'Re-Issued'" color="btn-success"
                                elevation="0" class="ml-1" @click="actionHotel('Verify')">Verify</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRptTGTicket" persistent fullscreen>
            <v-card elevation="0" title color="primary" class="pt-1 card">
                <v-card>
                    <v-card-text>
                        <v-card title elevation="0">
                            <v-card-text>
                                <v-row class="ma-2">
                                    <v-col>
                                        <v-row>
                                            <v-col>
                                                <v-img :src="require('@/assets/image/logo_pttep.png')" style="width: 110px;" />
                                            </v-col>
                                            <v-col class="text-right">
                                                <v-img :src="require('@/assets/image/NewRS_PTTEPNAME.png')" style="width: 80%;" class="ml-auto" />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="8" lg="2" md="2" sm="3" style="display: flex; align-items: center;">
                                                <span class="mr-2" style="white-space: nowrap;">ที่ ปตท.สผ.</span>
                                                <v-text-field outlined dense hide-details v-model="RptTGTicket.Ref"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="text-center">
                                                {{ $store.state.dateNowThai }}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <span>เรื่อง ขอความอนุเคราะห์ออกบัตรโดยสารเครื่องบิน</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <span>เรียน ผู้จัดการกองขายภาคราชการ บริษัท การบินไทย จำกัด (มหาชน)</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="ml-10">
                                                <span>
                                                    ด้วย บริษัท ปตท. สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน) มีความประสงค์ให้พนักงาน
                                                    ใช้บริการเดินทางกับ บริษัท การบินไทยฯ โดยมีรายละเอียดดังต่อไปนี้
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="2"></v-col>
                                            <v-col>
                                                <table style="width: 100%; border-collapse: collapse;" aria-label="">
                                                    <tr hidden><th></th></tr>
                                                    <tr>
                                                        <td class="tbRptTG">ผู้เดินทาง: </td>
                                                        <td class="tbRptTG" colspan="3">{{ RptTGTicket.TravellerName }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="tbRptTG">ตำแหน่ง: </td>
                                                        <td class="tbRptTG" colspan="3">{{ RptTGTicket.TravellerPosition }}</td>
                                                    </tr>
                                                    <tbody v-for="(f, index) in RptTGTicket.Flight" :key="index">
                                                        <tr>
                                                            <td class="tbRptTG">วันที่เดินทาง: </td>
                                                            <td class="tbRptTG">{{ depDateThai(f.DepDate) }}</td>
                                                            <td class="tbRptTG">เส้นทาง:</td>
                                                            <td class="tbRptTG">{{ f.Route }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="tbRptTG">Flight: </td>
                                                            <td class="tbRptTG">{{ f.Flight }}</td>
                                                            <td class="tbRptTG">Class:</td>
                                                            <td class="tbRptTG">{{ f.Class }}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tr>
                                                        <td class="tbRptTG">TR Number: </td>
                                                        <td class="tbRptTG">{{ TRNo }}</td>
                                                        <td class="tbRptTG">PNR Code:</td>
                                                        <td class="tbRptTG">
                                                            {{ (dialogTicketManagement.value ?
                                                            dialogTicketManagement.value.PNR : "") }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="tbRptTG" colspan="4">การชำระค่าใช้จ่าย : </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="tbRptTG" colspan="4">
                                                            บัตรเครดิต {{ RptTGTicket.TGCreditCard.CardName }}
                                                            หมายเลข {{ RptTGTicket.TGCreditCard.CardNumber }}
                                                            วันที่หมดอายุ {{ RptTGTicket.TGCreditCard.CardExpDate }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="tbRptTG" colspan="2">
                                                            <span style="color: blue;">
                                                                กรุณาออกใบเสร็จรับเงินในนาม :
                                                            </span>
                                                        </td>
                                                        <td class="tbRptTG" colspan="2">
                                                            <span style="color: blue;">
                                                                บริษัท ปตท. สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน)
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </v-col>
                                            <v-col cols="2"></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-textarea outlined dense hide-details
                                                v-model="RptTGTicket.Paragraph1"></v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="text-center">ขอแสดงความนับถือ</v-col>
                                        </v-row>
                                        <v-row align="center" justify="center" no-gutters class="mt-8">
                                            <v-col cols="12" lg="2" md="3" sm="5" class="d-flex justify-center">
                                                <v-text-field outlined dense hide-details v-model="RptTGTicket.SincerelyName"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row align="center" justify="center" no-gutters class="mt-2">
                                            <v-col cols="12" lg="3" md="4" sm="6" class="d-flex justify-center">
                                                <v-text-field outlined dense hide-details v-model="RptTGTicket.SincerelyPosition"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="mt-5">
                                            <v-col>
                                                <span>ผู้ประสานงาน: {{ RptTGTicket.BTTSupport }}</span><br>
                                                <span>{{ RptTGTicket.BTTPosition }} โทร. 0-2537-{{ RptTGTicket.DIRECT_PHONE.replace("-", " ต่อ") }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-img :src="require('@/assets/image/NewWeb_footerPTTEP.png')" style="width: 70%;" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn outlined color="primary" class="mr-1" @click="closeDialogRptTGTicket()">Close</v-btn>
                                <v-btn color="primary" elevation="0" class="ml-1 mr-1" @click="saveRptTGTicket()">Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import SimpleCrypto from "simple-crypto-js"
import moment from 'moment';
import pdfFonts from '@/assets/custom-font.js'
import * as _ from 'lodash'
export default {
    data(){
        return{
            render: 0,
            formatStrDMY: "DD/MM/YYYY",
            ReceivedDateMenu: false,
            ReceiptSlipDateMenu: false,
            RequestToRefundDateMenu: false,
            BookingOID: "",
            issueDateOffMenu: false,
            reissueType: [
                'Not Specify',
                'Re-issue',
                'Re-route',
                'Refund',
                'Re-validated',
            ],
            dialogRptTGTicket: false,
            RptTGTicket:{
                Doc: "",
                Ref: "",
                TravellerName: "",
                TravellerPosition: "",
                Paragraph1: "",
                SincerelyName: "",
                SincerelyPosition: "",
                BTTId: "",
                BTTSupport: "",
                BTTPosition: "",
                DIRECT_PHONE: "",
                TGCreditCard: {
                    CardNumber: "",
                    CardName: "",
                    CardExpDate: ""
                },
                Flight: {
                    DepDate: "",
                    Route: "",
                    Flight: "",
                    Class: ""
                }
            },
            traveller: {
                EMPLOYEE_ID: "",
                BudgetHolder: "",
                Name: "",
                MOBILE_NO: "",
                DIRECT_PHONE: "",
                EMAIL_ID: "",
                BudgetHolderApprover: "",
                LineApprovalD: "",
                TypeTraveler: "",
            },
            TGDirectTicket: null,
            BTTSupport: "",
            hotelObj: {
                HTNo: "",
                ReqServ: "",
                ReqServOptnal: "",
                CheckinDateStart: "",
                CheckinDateEnd: "",
                Country: "",
                CityorProvince: "",
                SelectHotel: "",
                HotelName: "",
                Description: "",
                RoomType: "",
                NumberOfRoom: 1,
                Occupancy: 1,
                BedType: "",
                PricePerNight: 0,
                Amount: 0,
                Currency: "THB",
                Status: "",
                BookingType: "",
                Rating: 0,
                HTL_EMAIL: "",
                HTL_TEL: "",
                HTL_FAX: "",
                Mdate: ""
            },
            CurrencyItem: ["THB"],
            menuCheckinEnd: false,
            menuCheckinStart: false,
            tripTypes: [
                {text: 'Departure', code: 'depart'},
                {text: 'Return', code: 'return'}
            ],
            orderFlight: 0,
            PNRKey: null,
            dialogHotelManagement: false,
            agencyTicketPrice: [],
            TicketRevieve: {
                value: [],
                show: false,
                loading: false
            },
            selectedItem: { airline: [], city: [] },
            status: [
                'Booked',
                'Re-Issue',
                'Issuing',
                'Issued',
                'Refund',
                'Closed'
            ],
            objDoc:{
                title: "",
                subTitle: "",
                traveller: {},
                docItem: [],
                doc: "",
            },
            dialogPassportVisa: false,
            ticketInformation: [],
            ticketHeader: [
                { value: 'no', text: 'No'},
                { value: 'Traveler', text: 'Travelers'},
                { value: 'TicketService', text: 'Ticket Service'},
                { value: 'DepartureDatetime', text: 'Dates'},
                { value: 'Route', text: 'Route'},
                { value: 'Time', text: 'Time'},
                { value: 'AirlineName', text: 'Airline'},
                { value: 'FlightNumber', text: 'Flight No.'},
                { value: 'Svc_class', text: 'Class'},
                { value: 'SeatNo', text: 'Seat No.'},
                { value: 'PNR', text: 'Booking Code'},
            ],
            ticketItem: [],
            dialogTicketManagement: { value: null, dialog: false },
            specialMealItem: [],
            travelDepDateMenu: false,
            travelArrDateMenu: false,
            nowDate: new Date().toISOString().substr(0, 10),
            headerCity: "Top Search",
            pLanguage: 'en',
            citytotravelFrom: [],
            citytotravelTo: [],
            Airlines: [],
            itemFocus: { dialog: false, value: null, TicketList: [] },
            reuse: [ { text: 'Yes', code: 1 }, { text: 'No', code: 0 } ],
            agencyType: [],
            svcClass: [],
            HaveBudgetHolder: false,
            dialogVerify: false,
            lifeCycleItem: [],
            Note: "",
            TRNo: "",
            BusinessTravelType: "",
            TravelRequestStatus: "",
            RemainningDays: "",
            BudgetHolder: "",
            Project: "",
            Company: "",
            Country: "",
            CityorProvince: "",
            PurposeofTravel: "",
            Traveler: [],
            TravelRequestNote: [],
            TravelRequestLog: [],
            BTTStatus: "",
            TravelRequestOID: "",
            SimpleCrypto: new SimpleCrypto("aoc"),
            ruleTraveler: {
                ReqServReq: [v => !!v || 'Service requirement is required'],
                CountryReq: [v => !!v || 'Country is required'],
                checkinDateStartReq: [v => !!v || 'Check in is required'],
                CityorProvinceReq: [v => !!v || 'City or province is required'],
                SelectHotelReq: [v => !!v || 'Select hotel is required'],
                RoomTypeReq: [v => !!v || 'Room type is required'],
                HotelNameReq: [v => !!v || 'Hotel name is required'],
                OccupancyReq: [v => !!v || 'Occupancy is required'],
                NumberOfRoomReq: [v => !!v || 'Number of room is required'],
                BedTypeReq: [v => !!v || 'Bed type is required'],
                AmountReq: [v => !!v || 'Amount is required'],
                PricePerNightReq: [v => !!v || 'Price per night is required'],
                StatusReq: [v => !!v || 'Status is required'],
                CurrencyReq: [v => !!v || 'Currency is required'],
                DueDateTravelExpeReq: [v => !!v || 'Due Date is required'],
                accountNoReq: [v => !!v || 'Account No is required'],
                EmailReq: [v => !!v || 'Email is required']
            },
            CountryItem: [],
            CityorProvinceItem: [],
            oldStatusFlight: "",
        }
    },
    methods:{
        showAirlineLogo(val){
            let result
            try{
               if(require(`@/assets/logoAirline/square/${val}.png`)){
                   result = val
               }
            }catch (err){
                result = 'NoImg'
            }
            return result
        },
        sumDate(val){
            return moment(val).diff(moment(), 'hours')
        },
        statusColor(status){
            let res = ''
            let text = ''
            switch (status){
                case 'Issuing' :
                    res = '#fff8a8'
                    text = 'black'
                break;
                case 'Issued' :
                    text = 'white'
                    res = 'btn-success'
                break;
                case 'Re-Issue' :
                    res = '#FF5656'
                    text = 'white'
                break;
                default:
                    res = 'primary'
                    text = 'white'
            }

            return [res, text]
        },
        GetsvcClass(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}BookingClass/GetTableBookingClass`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    console.log(res.data.Result)
                    this.svcClass = await JSON.parse(JSON.stringify(res.data.Result))
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        getTGDirectTecketing(val){
            axios.post(this.$store.state.API_PATH + `TGDirectTicketing/GetTGDirectTicketingBySearch`, {CompanyName: val}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                if(res.data.Result && res.data.Result.length > 0){
                    this.TGDirectTicket = res.data.Result.filter(x => x.CompanyName === val)[0]
                }
            })
        },
        addRoute() {
            this.itemFocus.TicketList.push({
                orderFlight: this.orderFlight + 1,
                RouteFrom: "",
                TripType: "",
                RouteTo: "",
                Airline: null,
                departDate: null,
                arrivalDate: null,
                TravelDepHour: "",
                TravelDepMinute: "",
                TravelArrivalHour: "",
                TotalFare: null,
                TravelArrivalMinute: "",
                ReturnDepHour: "",
                ReturnDepMinute: "",
                ReturnArrivalHour: "",
                ReturnArrivalMinute: "",
                FlightNumber: "",
                seatNo: "",
                Reuse: "",
                specialMeal: "",
                travelDateOffMenu: false,
            })
        },
        removeRoute(ID){
            this.itemFocus.TicketList = this.itemFocus.TicketList.filter(x => x.orderFlight !== ID)
            this.orderFlight -= 1
            if(this.itemFocus.TicketList.length === 0){
                this.addRoute()
            }
        },
        verify(val){
            this.oldStatusFlight = JSON.parse(JSON.stringify(this.dialogTicketManagement.value.BTTStatus))
            const agentBySelected = this.agencyType.filter(x => this.dialogTicketManagement.value.AgentID === x.AgentID)[0]
            if(agentBySelected.OfficeID === 'Nokair'){
                this.sendMailToAgentByBidding()
            }else if(agentBySelected.OfficeID === 'ThaiAirways'){
                if(this.oldStatusFlight === "Booked" || this.oldStatusFlight === "Re-Issue" || this.oldStatusFlight === "Re-Issued"){
                    this.getRptTGTicket(this.dialogTicketManagement.value.BookingOID,
                    this.dialogTicketManagement.value.EMPLOYEE_ID, `RptTGTicket${this.oldStatusFlight}`)
                }
            }else if(agentBySelected.Name === 'Bidding'){
                this.sendMailToAgentByBidding()
            }
            else{
                const buildAgent = []
                buildAgent.push({...agentBySelected})
                this.sendMailToAgentBySelected(buildAgent)
            }
            this.dialogTicketManagement.value.BTTStatus = val
            this.SaveTicketInfomation()
        },
        setAmount(val){
            const findAmount = this.agencyTicketPrice.filter(x => x.AgentID === val)
            if(findAmount && findAmount.length > 0){
                this.dialogTicketManagement.value.Amount = findAmount[0].FareCharge
            }else{
                this.dialogTicketManagement.value.Amount = 0
            }
        },
        pushCity(val){
            this.selectedItem.city.push(val);
        },
        pushAirline(val){
            this.selectedItem.airline.push(val);
        },
        PNR_Retrieve(val){
            this.TicketRevieve.value = []
            this.TicketRevieve.loading = true
            this.TicketRevieve.show = false
            axios.post(this.$store.state.API_PATH + `FlightBooking/PNR_Retrieve`, {pnrNumber: val}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async (res) => {
                if(res.data.Status){
                    const result = JSON.parse(res.data.Result)
                    if(result.PNR_Reply && result.PNR_Reply !== 'undefined'){
                        if(Array.isArray(result.PNR_Reply.originDestinationDetails.itineraryInfo)){
                            let index = 0
                            for(const item of result.PNR_Reply.originDestinationDetails.itineraryInfo){
                                await new Promise(async resolve => {
                                    const getYears = new Date()
                                    await this.SearchAirlineByCode(item.travelProduct.companyDetail.identification).then(res2 => {
                                        this.Airlines.push(res2.data)
                                    })
                                    let dep = moment(item.travelProduct.product.depDate, 'YYMMDD').format('YY-MM-DD')
                                    let arr = moment(item.travelProduct.product.arrDate, 'YYMMDD').format('YY-MM-DD')
                                    dep = `${getYears.getFullYear()}-${dep.split('-')[1]}-${dep.split('-')[0]}`
                                    arr = `${getYears.getFullYear()}-${arr.split('-')[1]}-${arr.split('-')[0]}`
                                    const modify = {
                                        OriginalBookingOID: null,
                                        orderFlight: this.orderFlight + 1,
                                        BookingOID: null,
                                        EMPLOYEE_Log: null,
                                        number: item?.elementManagementItinerary?.reference?.number,
                                        TripType: item?.elementManagementItinerary?.reference?.number === "1" ? 'depart' : 'return',
                                        IsReIssue: 0,
                                        ReIssueSelected: 0,
                                        EquipmentType: item?.flightDetail?.productDetails?.equipment,
                                        BookingType: "Offline",
                                        SaveFlightBookingOID: null,
                                        EMPLOYEE_ID: null,
                                        PNR: item?.itineraryReservationInfo?.reservation?.controlNumber,
                                        FlightNumber: `${item?.travelProduct?.productDetails?.identification}`,
                                        Airline: item?.travelProduct?.companyDetail?.identification,
                                        AirlineName: null,
                                        OfficeID: result?.PNR_Reply?.securityInformation?.responsibilityInformation?.officeId,
                                        Origin: null,
                                        OriginCode: item?.travelProduct?.boardpointDetail?.cityCode,
                                        Destination: null,
                                        DestinationCode: item?.travelProduct?.offpointDetail?.cityCode,
                                        DepartureDateStart: null,
                                        DepartureDateEnd: null,
                                        Svc_class: item?.flightDetail?.facilities?.entertainement,
                                        SeatNo: null,
                                        TravelDepHour: item?.travelProduct?.product?.depTime?.slice(0, 2),
                                        TravelDepMinute: item?.travelProduct?.product?.depTime?.slice(-2),
                                        DepartureDatetime: dep,
                                        ArrivalDatetime: arr,
                                        FareType: result?.PNR_Reply?.securityInformation?.responsibilityInformation?.typeOfPnrElement,
                                        TravelArrHour: item?.travelProduct?.product?.arrTime?.slice(0, 2),
                                        TravelArrMinute: item?.travelProduct?.product?.arrTime?.slice(-2),
                                    }
                                    setTimeout(async () => {
                                        this.TicketRevieve.value.push(modify)
                                        index++
                                    }, 100);
                                    resolve()
                                })
                            }
                        }else{
                            const getYears = new Date()
                            await this.SearchAirlineByCode(result.PNR_Reply.originDestinationDetails.itineraryInfo.travelProduct.companyDetail.identification).then(resAirline => {
                                this.Airlines.push(resAirline.data)
                            })
                            let dep = moment(result.PNR_Reply.originDestinationDetails.itineraryInfo.travelProduct.product.depDate, 'YYMMDD').format('YY-MM-DD')
                            let arr = moment(result.PNR_Reply.originDestinationDetails.itineraryInfo.travelProduct.product.arrDate, 'YYMMDD').format('YY-MM-DD')
                            dep = `${getYears.getFullYear()}-${dep.split('-')[1]}-${dep.split('-')[0]}`
                            arr = `${getYears.getFullYear()}-${arr.split('-')[1]}-${arr.split('-')[0]}`
                            const modify = {
                                OriginalBookingOID: null,
                                BookingOID: null,
                                EMPLOYEE_Log: null,
                                number: result.PNR_Reply.originDestinationDetails.itineraryInfo.elementManagementItinerary.reference.number,
                                TripType: result.PNR_Reply.originDestinationDetails.itineraryInfo.elementManagementItinerary.reference.number === "1" ? 'depart' : 'return',
                                IsReIssue: 0,
                                ReIssueSelected: 0,
                                EquipmentType: result.PNR_Reply.originDestinationDetails.itineraryInfo.flightDetail.productDetails.equipment,
                                BookingType: "Offline",
                                SaveFlightBookingOID: null,
                                EMPLOYEE_ID: null,
                                PNR: result.PNR_Reply.originDestinationDetails.itineraryInfo.itineraryReservationInfo.reservation.controlNumber,
                                FlightNumber: `${result.PNR_Reply.originDestinationDetails.itineraryInfo.travelProduct.productDetails.identification}`,
                                Airline: result.PNR_Reply.originDestinationDetails.itineraryInfo.travelProduct.companyDetail.identification,
                                AirlineName: null,
                                OfficeID: result.PNR_Reply.securityInformation.responsibilityInformation.officeId,
                                Origin: null,
                                OriginCode: result.PNR_Reply.originDestinationDetails.itineraryInfo.travelProduct.boardpointDetail.cityCode,
                                Destination: null,
                                DestinationCode: result.PNR_Reply.originDestinationDetails.itineraryInfo.travelProduct.offpointDetail.cityCode,
                                DepartureDateStart: null,
                                DepartureDateEnd: null,
                                Svc_class: result.PNR_Reply.originDestinationDetails.itineraryInfo.flightDetail.facilities.entertainement,
                                SeatNo: null,
                                TravelDepHour: result.PNR_Reply.originDestinationDetails.itineraryInfo.travelProduct.product.depTime.slice(0, 2),
                                TravelDepMinute: result.PNR_Reply.originDestinationDetails.itineraryInfo.travelProduct.product.depTime.slice(-2),
                                DepartureDatetime: dep,
                                ArrivalDatetime: arr,
                                FareType: result.PNR_Reply.securityInformation.responsibilityInformation.typeOfPnrElement,
                                TravelArrHour: result.PNR_Reply.originDestinationDetails.itineraryInfo.travelProduct.product.arrTime.slice(0, 2),
                                TravelArrMinute: result.PNR_Reply.originDestinationDetails.itineraryInfo.travelProduct.product.arrTime.slice(-2),
                            }
                            this.TicketRevieve.value.push(modify)
                        }
                        this.TicketRevieve.show = true
                    }else{
                        this.TicketRevieve.value = 'Not Found'
                    }
                    this.TicketRevieve.loading = false
                }
            })
        },
        SaveTicketInfomation(){
            axios.post(this.$store.state.API_PATH + "Agent/setFareCharge", this.agencyTicketPrice, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            axios.post(this.$store.state.API_PATH + "TravelRequest/SaveTicketInfomation", this.dialogTicketManagement.value, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then((res) => {
                if(res.data.Status){
                    this.dialogTicketManagement = {
                        value: null,
                        dialog: false
                    }
                }
            })

            this.GetMyTravelRequestInformation()
        },
        getDocPassportVisa(){
            if(this.objDoc.title === "Passport"){
                if(this.objDoc.doc === "P001"){
                    window.open(`${window.location.origin}/doc/RptOffPss?TRNo=${this.TRNo}&EMP_ID=${this.objDoc.traveller.EMPLOYEE_ID}&Type=${this.objDoc.doc}`, "_blank")
                }
                else if(this.objDoc.doc === "P002"){
                    window.open(`${window.location.origin}/doc/RptOffPss2?TRNo=${this.TRNo}&EMP_ID=${this.objDoc.traveller.EMPLOYEE_ID}&Type=${this.objDoc.doc}`, "_blank")
                }
                else if(this.objDoc.doc === "P003"){
                    window.open(`${window.location.origin}/doc/RptOffPss3?TRNo=${this.TRNo}&EMP_ID=${this.objDoc.traveller.EMPLOYEE_ID}&Type=${this.objDoc.doc}`, "_blank")
                }
                else if(this.objDoc.doc === "P004"){
                    window.open(`${window.location.origin}/doc/RptOffPss4?TRNo=${this.TRNo}&EMP_ID=${this.objDoc.traveller.EMPLOYEE_ID}&Type=${this.objDoc.doc}`, "_blank")
                }
            }
            else if(this.objDoc.title === "VISA"){
                if(this.objDoc.doc === "V001"){
                    window.open(`${window.location.origin}/doc/RptSponLet?TRNo=${this.TRNo}&EMP_ID=${this.objDoc.traveller.EMPLOYEE_ID}&Type=${this.objDoc.doc}`, "_blank")
                }
                else if(this.objDoc.doc === "V002"){
                    window.open(`${window.location.origin}/doc/RptVisa?TRNo=${this.TRNo}&EMP_ID=${this.objDoc.traveller.EMPLOYEE_ID}&Type=${this.objDoc.doc}`, "_blank")
                }
                else if(this.objDoc.doc === "V003"){
                    window.open(`${window.location.origin}/doc/RptAutVisa?TRNo=${this.TRNo}&EMP_ID=${this.objDoc.traveller.EMPLOYEE_ID}&Type=${this.objDoc.doc}`, "_blank")
                }
            }
        },
        openDocPassportVisa(docType, item){
            this.objDoc.title = docType
            this.objDoc.subTitle = `${item.Name} (${item.TypeTraveler})`
            this.objDoc.traveller = item
            if(docType === "Passport"){
                this.objDoc.docItem = [
                    { text: "", val: "" },
                    { text: "จดหมายขอความอนุเคราะห์ออกหนังสือเดินทางราชการ", val: "P001" },
                    { text: "จดหมายขอความอนุเคราะห์ออกหนังสือเดินทางราชการ - หนังสือรับรอง", val: "P002" },
                    { text: "จดหมายขอความอนุเคราะห์ออกหนังสือเดินทางราชการ - งบประมาณค่าใช้จ่ายในการเดินทางประกอบการยื่นขอหนังสือเดินทางราชการ", val: "P003" },
                    { text: "จดหมายขอความอนุเคราะห์ออกหนังสือเดินทางราชการและหนังสือนำวีซ่า", val: "P004" },
                ]
            }
            else if(docType === "VISA"){
                this.objDoc.docItem = [
                    { text: "", val: "" },
                    { text: "จดหมาย Sponsor letter เพื่อขอ VISA", val: "V001" },
                    { text: "จดหมายขอความอนุเคราะห์ออกหนังสือนำ VISA", val: "V002" },
                    { text: "จดหมาย Authorized to Apply VISA form เพื่อมอบอำนาจให้ตัวแทนของ BTT ไปยื่นเอกสารแทนพนักงาน", val: "V003" }
                ]
            }
            this.dialogPassportVisa = true
        },
        closeDocPassportVisa(){
            this.objDoc = {
                title: "",
                subTitle: "",
                traveller: {},
                docItem: [],
                doc: "",
            }
            this.dialogPassportVisa = false
        },
        async openTicketManagement(val){
            this.$store.state.LOADING = true
            const setFlights = []
            this.dialogTicketManagement.TicketList = []
            this.GetTicketInformationDetail(val.BookingOID)
            .then(async res => {
                this.dialogTicketManagement.value = val
                await this.GetAgencyType(this.dialogTicketManagement.value.OriginalBookingOID ?
                this.dialogTicketManagement.value.OriginalBookingOID :
                this.dialogTicketManagement.value.BookingOID)

                this.setAmount(this.dialogTicketManagement.value.AgentID)
                this.dialogTicketManagement.value.BTTStatus = this.dialogTicketManagement.value.BTTStatus ? this.dialogTicketManagement.value.BTTStatus : 'Booked'
                this.dialogTicketManagement.value.IssueDate = this.formatDate(val.IssueDate, 'YYYY-MM-DD')
                if(!this.dialogTicketManagement.value.IssueDate || this.dialogTicketManagement.value.IssueDate === '1900-01-01'){
                    this.dialogTicketManagement.value.IssueDate = ''
                }
                this.ticketInformation = res.data.Result
                for(const y of this.ticketInformation.Traveler){
                    await new Promise((resolve) => {
                        if(y.Flight && y.Flight.length > 0){
                            y.Flight.forEach(async x => {
                                let modify = {}
                                if(x.BookingOID === val.BookingOID){
                                    if(!x.PNR){
                                        x.PNR = await this.dialogTicketManagement.value.PNR
                                    }
                                    if(!x.Svc_class){
                                        x.Svc_class = await this.dialogTicketManagement.value.Svc_class
                                    }
                                    x.orderFlight = this.orderFlight += 1
                                    modify = x
                                    await setFlights.push(modify)
                                }
                            })
                        }
                        resolve()
                    })
                }
                this.dialogTicketManagement.TicketList = await setFlights
                console.log(this.dialogTicketManagement.TicketList);
                if(this.dialogTicketManagement.TicketList.filter(x => x.Airline === 'TG').length === this.dialogTicketManagement.TicketList.length &&
                this.TGDirectTicket && this.TGDirectTicket.TGDirect === 1){
                    this.dialogTicketManagement.value.AgentID = this.agencyType.filter(x => x.Name === 'Thai Airways')[0]?.AgentID
                }else if(this.dialogTicketManagement.TicketList.filter(x => x.Airline === 'DD').length === this.dialogTicketManagement.TicketList.length){
                    this.dialogTicketManagement.value.AgentID = this.agencyType.filter(x => x.Name === 'Nok Air')[0]?.AgentID
                    console.log(this.dialogTicketManagement.TicketList);
                }else if(!this.dialogTicketManagement.value.AgentID || this.dialogTicketManagement.value.AgentID === ''){
                    this.dialogTicketManagement.value.AgentID = 'Bidding'
                }
                if(setFlights && setFlights.filter(x => x.OriginalBookingOID).length > 0){
                    this.dialogTicketManagement.TicketList = setFlights.filter(x => x.OriginalBookingOID)
                }
                this.render += 1
                this.dialogTicketManagement.dialog = true
                this.$store.state.LOADING = false
        }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        async InsertFlightDetail(val){
            if(val === 'Issued'){
                this.verify('Issued')
                this.dialogTicketManagement.value.BTTStatus = 'Issued'
            }
            let index = 0
            const modifyFlight = {
                PNR: this.itemFocus.value.PNR,
                Svc_class: this.itemFocus.value.Svc_class,
                TotalFare: this.itemFocus.value.TotalFare,
                BookingType: this.itemFocus.value.BookingType,
                BookingOID: null,
                BookingOfflineOID: null,
                RouteItem: [],
                Traveler: [],
                IsReIssue: this.itemFocus.value.IsReIssue,
                ReIssueSelected: this.itemFocus.value.ReIssueSelected,
                NotServiceRq: true
            }
            if(modifyFlight.BookingType === 'Online'){
                modifyFlight.BookingOID = this.itemFocus.value.BookingOID
            }else{
                modifyFlight.BookingOfflineOID = this.itemFocus.value.BookingOID
            }
            for (const item of this.TicketRevieve.value && this.TicketRevieve.value.length > 0 ? this.TicketRevieve.value : this.itemFocus.TicketList) {
                await new Promise(async (resolve, reject) => {
                    const getYears = new Date()
                    const ModifyFlightDetail = {
                        number: index + 1,
                        departDate: `${getYears.getFullYear()}-${item.DepartureDatetime.split('-')[1]}-${item.DepartureDatetime.split('-')[2]}`,
                        TravelDepHour : item.TravelDepHour,
                        TravelDepMinute : item.TravelDepMinute,
                        arrivalDate: `${getYears.getFullYear()}-${item.ArrivalDatetime.split('-')[1]}-${item.ArrivalDatetime.split('-')[2]}`,
                        TravelArrivalHour : item.TravelArrHour,
                        TravelArrivalMinute : item.TravelArrMinute,
                        EquipmentType: item.EquipmentType,
                        Airline: item.Airline,
                        IsActive: 1,
                        TripType: item.TripType,
                        FlightNumber: item.FlightNumber,
                        FareBasis: item.FareBasis,
                        FareType: item.FareType,
                        RouteFrom: item.OriginCode,
                        RouteTo: item.DestinationCode,
                        Svc_class: item.Svc_class,
                        PNR: item.PNR
                    }
                    index++
                    modifyFlight.RouteItem.push(ModifyFlightDetail)
                    resolve()
                })
            }
            axios.post(this.$store.state.API_PATH + 'FlightBooking/InsertFlightDetail', modifyFlight, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async res => {
                if(res.data.Status){
                    setTimeout(async () => {
                        await this.openTicketManagement(this.itemFocus.value)
                        this.TicketRevieve.loading = false
                        this.itemFocus.dialog = false
                    }, 200);
                }
            })
        },
        updateStatusTraveler(val){
            axios.post(`${this.$store.state.API_PATH}Traveler/UpdateStatusTraveler`, {
                EMPLOYEE_ID: val.emp_ID,
                TravelRequestOID: val.TravelRequestOID,
                Status: val.status === 'Approve Final' ? 'Re-Issue' : this.travel.status
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
        },
        UpdateTravelerOffline(){
            axios.post(this.$store.state.API_PATH + "Traveler/UpdateTravelerOffline", this.ModifyTraveler(), {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then((res) => {
                if(res.data.Status){
                   this.GetMyTravelRequestInformation()
                   this.itemFocus.dialog = false
                   this.itemFocus.val = null
                }
            })
        },
        getSpecialMeal() {
            axios.post(this.$store.state.API_PATH + "SpecialMeal/GetSpecialMeal", {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then((res) => {
                if (res.data.Status) {
                    this.specialMealItem = res.data.Result;
                    this.specialMealItem.unshift({ Description: "No Preferred", MealCode: "" })
                }
            })
                .catch((err) => err);
        },
        formatDate(date, format) {
            return date ? moment(date).format(format) : ""
        },
        getCityCodes(input, type) {
            if (input.length >= 3) {
                const checkItem = []
                if (type === "From") {
                    if (this.citytotravelFrom[0] === { header: this.headerCity }) {
                        this.citytotravelFrom.filter(el => el.Name.toLowerCase().substr(0, input.length) === input.toLowerCase())
                    }
                }
                else {
                    this.citytotravelTo.filter(el => el.Name.toLowerCase().substr(0, input.length) === input.toLowerCase())
                }
                if (checkItem.length === 0) {
                    this.GetCityCodeLine2(input, type)
                }
            }
        },
        GetCityCodeLine2(input, type) {
            axios.post(`${this.$store.state.API_PATH}CityCode/GetCityCodesAndAirportCodeBySearch?Name=${input}&pLanguage=${this.pLanguage}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                if (res.data.length > 0) {
                    if (type === "From") {
                        res.data.forEach(elm => {
                            this.citytotravelFrom.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                        })
                        this.selectedItem.city.forEach(elm => {
                            this.citytotravelFrom.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                        })
                    }
                    else if(type === "To") {
                        this.citytotravelTo = []
                        res.data.forEach(elm => {
                            this.citytotravelTo.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                        })
                        this.selectedItem.city.forEach(elm => {
                            this.citytotravelTo.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                        })
                    } else {
                        this.selectedItem.city.push(...res.data)
                        this.selectedItem.city.forEach(elm => {
                            this.citytotravelFrom.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                        })
                          this.selectedItem.city.forEach(elm => {
                            this.citytotravelTo.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                        })
                    }
                }
            })
                .catch(err => err)
        },
        SearchAirlineCode(val){
            const req = {
                Code: val,
                Language: 'en',
                Name: val
            }
            axios.post(this.$store.state.API_PATH + 'FlightSearch/SearchAirlineCode', req, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    this.Airlines = res.data
                    this.Airlines.push(...this.selectedItem.airline)
                })
        },
        async SearchAirlineByCode(val){
            const req = {
                Code: val,
                Language: 'en',
                Name: val
            }
            return axios.post(this.$store.state.API_PATH + 'FlightSearch/SearchAirlineByCode', req, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
        },
        async openTicketList(val){
            this.TicketRevieve = {
                value: [],
                show: false,
                loading: false
            }
            this.itemFocus.value = JSON.parse(JSON.stringify(val.value))
            this.itemFocus.TicketList = val.TicketList.filter(x => x.EMPLOYEE_ID === val.value.EMPLOYEE_ID)
            if(this.itemFocus.TicketList && this.itemFocus.TicketList.filter(x => x.OriginalBookingOID).length > 0){
                this.itemFocus.TicketList = this.itemFocus.TicketList.filter(x => x.OriginalBookingOID && x.EMPLOYEE_ID === val.value.EMPLOYEE_ID)
            }
            for(const x of this.itemFocus.TicketList){
                await new Promise(async (resolve) => {
                    if(!x.PNR){
                        x.PNR = this.itemFocus.value.PNR
                        resolve()
                    }
                    if(x.Airline){
                        await this.SearchAirlineByCode(x.Airline).then(res => {
                            if(res.data){
                                this.Airlines.push(res.data)
                                this.selectedItem.airline.push(res.data)
                            }
                        })
                        resolve()
                    }
                    if(x.Origin){
                        await this.GetCityCodeLine2(x.Origin, 'First')
                        resolve()
                    }
                    if(x.Destination){
                        await this.GetCityCodeLine2(x.Destination, 'First')
                        resolve()
                    }
                })
            }
            this.itemFocus.dialog = true
        },
        calPriceTR(flight){
            return flight ? flight.reduce((total, item)=>total+(item.TotalFare),0) : 0
        },
        calCurPrice(flight){
            return flight ? flight.reduce((total, item)=>total+(item.CurPrice),0) ?? flight.reduce((total, item)=>total+(item.TotalFare),0) : 0
        },
        getTravelRequest(item) {
            const formReqID = {
                TravelId: this.TravelRequestOID,
                EMPLOYEE_ID: null,
                Pending: 0,
                ItemPending: item
            }
            sessionStorage.formReqID = JSON.stringify(formReqID)
            setTimeout(() => {
                location.href = '/createTravelRq'
            }, 1);
        },
        closeVerify(){
            this.dialogVerify = false
        },
        confirmVerify(){
            this.dialogVerify = false
            this.$store.state.LOADING = true
            axios.post(this.$store.state.API_PATH + "TravelRequest/VerifyTravelRequest", {
                TRNo: this.TRNo,
                Traveler: this.Traveler
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                if(res.data.Status){
                    this.$store.state.LOADING = false
                    Swal.fire({
                        icon: 'success',
                        text: 'Success',
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                        window.location.href = "MyTaskBTT"
                    },()=>{})
                }
                else{
                    this.$store.state.LOADING = false
                    Swal.fire({
                        icon: 'error',
                        text: res.data.ErrorMessage,
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                        window.location.href = "MyTaskBTT"
                    },()=>{})
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        async showDialogVerify(){
            this.$store.state.LOADING = true
            const listFlight = []
            for(const item of this.Traveler){
                await new Promise(resolve=>{
                    for(const subItem of item.Flight){
                        listFlight.push({
                            AirFare: {
                                IsPricing: true,
                                SaveFlightBookingOID: subItem.SaveFlightBookingOID,
                                BookingOID: subItem.BookingOID,
                                // totalFare: subItem.TotalFare
                            }
                        })
                        resolve()
                    }
                })
            }
            axios.post(this.$store.state.API_PATH + "FlightBooking/ListBooking", listFlight, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async(res)=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    for(const item of this.Traveler){
                        await new Promise(resolve=>{
                            for(const subItem of item.Flight){
                                const i = res.data.Result.findIndex(x=>x.BookingOID === subItem.BookingOID)
                                if(i !== -1){
                                    subItem.CurPrice = res.data.Result[i].NewPrice
                                }
                                resolve()
                            }
                        })
                    }
                    this.dialogVerify = true
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        addNote(){
            if(this.Note){
                const PROFILE = JSON.parse(localStorage.PROFILE)
                this.$store.state.LOADING = true
                axios.post(this.$store.state.API_PATH + "TravelRequestNote/InsertTravelRequestNote", {
                    TRNo: this.TRNo,
                    Note: this.Note,
                    NoteBy: `${PROFILE.FIRSTNAME} ${PROFILE.LASTNAME}`
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res=>{
                    this.$store.state.LOADING = false
                    if(res.data.Status){
                        Swal.fire({
                            icon: 'success',
                            text: 'Success',
                            confirmButtonColor: '#00AEEF'
                        }).then(()=>{
                            this.Note = ""
                            axios.post(this.$store.state.API_PATH + "TravelRequestNote/GetTravelRequestNoteByTRNo", {
                                TRNo: this.TRNo
                            }, {
                                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                            }).then(res2=>{
                                this.$store.state.LOADING = false
                                if(res2.data.Status){
                                    this.TravelRequestNote = res2.data.Result
                                }
                            }).catch(err=>{
                                this.$store.state.LOADING = false
                                console.log(err)
                            })
                        },()=>{})
                    }
                }).catch(err=>{
                    this.$store.state.LOADING = false
                    console.log(err)
                })
            }
        },
        GetTicketInformationDetail(val){
            // this.$store.state.LOADING = true
            return axios.post(this.$store.state.API_PATH + "TravelRequest/GetTicketInformationDetail", {
                TRNo: this.TRNo,
                BookingOID: val
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
        },
        async GetAgencyType(val){
            await axios.post(this.$store.state.API_PATH + `Agent/GetAgencyType?val=${val}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.agencyTicketPrice = res.data
                this.agencyType.forEach(x => {
                    if(this.agencyTicketPrice.filter(y => y.OfficeID === x.OfficeID && x.IsActive === 1).length === 0){
                        if(x.Name !== 'Bidding' && x.Name !== 'Thai Airways' && x.Name !== 'Nok Air'){
                            this.agencyTicketPrice.push({
                                BookingOID: val,
                                Name: x.Name,
                                FareControlOID: "",
                                OfficeID: x.OfficeID,
                                TripType: "R",
                                Status: 0,
                                FareCharge: 0,
                                Rank: x.Rank
                            })
                        }
                    }
                });
            })
        },
        GetObjAgentService(){
            this.agencyType = []
            axios.post(this.$store.state.API_PATH + "Agent/GetObjAgentService", {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.agencyType.push({
                    AgentID: "Bidding",
                    OfficeID: "Bidding",
                    Name: "Bidding",
                    Email: "-",
                    FareControl: null,
                    Rank: 0,
                    QueueNumber: 0,
                    Category: 0,
                    IsActive: 0,
                    IsDelete: 0,
                    CREATED_BY: null
                })
                this.agencyType.push(...res.data)
            })
        },
        GetMyTravelRequestInformation(){
            this.$store.state.LOADING = true
            axios.post(this.$store.state.API_PATH + "TravelRequest/GetMyTravelRequestInformation", {
                TRNo: this.TRNo
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    const item = res.data.Result
                    this.BusinessTravelType = item.BusinessTravelType
                    this.RemainningDays = item.RemainningDays
                    this.BudgetHolder = item.BudgetHolder
                    this.Project = item.Project
                    this.Company = item.Company
                    this.Country = item.Country
                    this.TravelRequestStatus = item.TRStatus
                    this.CityorProvince = item.CityorProvince
                    this.PurposeofTravel = item.PurposeofTravel
                    this.Traveler = item.Traveler
                    this.TravelRequestNote = item.TravelRequestNote
                    this.TravelRequestLog = item.TravelRequestLog
                    this.lifeCycleItem = item.TravelRequestLifeCycle
                    this.BTTStatus = item.BTTStatus
                    this.TravelRequestOID = item.TravelRequestOID
                    this.BTTSupport = item.BTTSupport
                    this.HaveBudgetHolder = this.lifeCycleItem.filter(e=>e.HaveBudgetHolder).length > 0 ? true : false
                    this.getTGDirectTecketing(this.Company)
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        closeDialogHotelManagement(){
            this.traveller = {
                EMPLOYEE_ID: "",
                BudgetHolder: "",
                Name: "",
                MOBILE_NO: "",
                DIRECT_PHONE: "",
                EMAIL_ID: "",
                BudgetHolderApprover: "",
                LineApprovalD: "",
                TypeTraveler: "",
            }
            this.hotelObj = {
                HTNo: "",
                ReqServ: "",
                ReqServOptnal: "",
                CheckinDateStart: "",
                CheckinDateEnd: "",
                Country: "",
                CityorProvince: "",
                SelectHotel: "",
                HotelName: "",
                Description: "",
                RoomType: "",
                NumberOfRoom: 1,
                Occupancy: 1,
                BedType: "",
                PricePerNight: 0,
                Amount: 0,
                Currency: "THB",
                Status: "",
                BookingType: "",
                Rating: 0,
                HTL_EMAIL: "",
                HTL_TEL: "",
                HTL_FAX: "",
                Mdate: ""
            }
            this.dialogHotelManagement = false
        },
        showHotelManagement(item, obj){
            this.traveller = item
            this.hotelObj = obj
            this.hotelObj.HTNo = obj.BookingNo !== "Pending" ? obj.BookingNo : ""
            this.dialogHotelManagement = true
            this.getCountryCodeHotel()
        },
        formatDateLL(date) {
            return date ? moment(date).format("ll") : moment().format("ll")
        },
        getCountryCodeHotel() {
            axios.post(`${this.$store.state.API_PATH}CountryCode/GetCountryCode`, {
                Code: "",
                Language: this.$store.state.Language,
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (resCountrie) => {
                if (resCountrie.data.Status) {
                    this.CountryItem = []
                    resCountrie.data.Result.forEach((elm) => {
                        this.CountryItem.push({
                            ...elm,
                            text: `${elm.Name} (${elm.Code})`,
                        })
                    })
                    this.getCityCodeHotel()
                }
            })
            .catch((err) => err);
        },
        getCityCodeHotel() {
            this.CityorProvinceItem = []
            this.HotelNameItem = []
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}CityCode/${this.hotelObj.Country === 'TH' ? 'GetProvince' : 'GetCityCodeByCountryCode'}`, {
                CountryCode: this.hotelObj.Country,
                Language: this.$store.state.Language,
                Country: this.hotelObj.Country
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async(resCities) => {
                if (resCities.data.Status) {
                    for(const elm of resCities.data.Result){
                        await new Promise(resolve=>{
                            if (this.hotelObj.Country === 'TH') {
                                const findnameH = elm[`name_${this.$store.state.Language}`]
                                this.CityorProvinceItem.push({ ...elm, Code: elm.code, text: `${findnameH} (${elm.code})` })
                            } else {
                                this.CityorProvinceItem.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                            }
                            resolve()
                        })
                    }
                    this.$store.state.LOADING = false
                }
            }, ErrorMessage => {
                console.log(ErrorMessage)
                this.$store.state.LOADING = false
            })
        },
        actionHotel(type){
            if (!this.$refs.Country.validate() || !this.$refs.CityorProvince.validate() ||
            !this.$refs.HotelName.validate() || !this.$refs.RoomType.validate() ||
            !this.$refs.NumberOfRoom.validate() || !this.$refs.Occupancy.validate() ||
            !this.$refs.BedType.validate() || !this.$refs.PricePerNight.validate() ||
            !this.$refs.Amount.validate() || !this.$refs.Email.validate()) {
                if (!this.$refs.Country.validate()) {
                    this.$refs.Country.focus()
                }
                else if (!this.$refs.CityorProvince.validate()) {
                    this.$refs.CityorProvince.focus()
                }
                else if (!this.$refs.HotelName.validate()) {
                    this.$refs.HotelName.focus()
                }
                else if (!this.$refs.RoomType.validate()) {
                    this.$refs.RoomType.focus()
                }
                else if (!this.$refs.NumberOfRoom.validate()) {
                    this.$refs.NumberOfRoom.focus()
                }
                else if (!this.$refs.Occupancy.validate()) {
                    this.$refs.Occupancy.focus()
                }
                else if (!this.$refs.BedType.validate()) {
                    this.$refs.BedType.focus()
                }
                else if (!this.$refs.PricePerNight.validate()) {
                    this.$refs.PricePerNight.focus()
                }
                else if (!this.$refs.Amount.validate()) {
                    this.$refs.Amount.focus()
                }
                else {
                    this.$refs.Email.focus()
                }
            }
            else{
                if(this.hotelObj.BookingType !== "Offline"){
                    this.generatePDFForHotel(type)
                }
                else{
                    this.saveHotel(type, new FormData())
                }
            }
        },
        saveHotel(type, formData){
            const jsonReq = JSON.stringify({
                ...this.hotelObj,
                ...this.traveller,
                Type: type,
                TRNo: this.TRNo,
                BTTSupport: this.BTTSupport
            })
            formData.append('req', jsonReq)
            this.dialogHotelManagement = false
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}HotelBooking/UpdateHotelBooking`, formData, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    Swal.fire({
                        icon: 'success',
                        text: 'Success',
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                        location.reload()
                    },()=>{})
                }
                else{
                    Swal.fire({
                        icon: 'error',
                        text: res.ErrorMessage,
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                    },()=>{})
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        generatePDFForHotel(type){
            const PROFILE = JSON.parse(localStorage.PROFILE)
            pdfMake.vfs = pdfFonts.pdfMake.vfs
            pdfMake.fonts = {
                CordiaNew: {
                    normal: "CordiaNew.ttf",
                    bold: "CordiaNewBold.ttf",
                }
            }
            const doc = {
                content:[
                    {margin: [0, 0, 0, 30], columns: this.$store.state.headerReport},
                    {
                        table: {widths: [45, "*", 45, "*", 60, "*"], body: this.bodyRptH()}
                    },
                    {
                        margin: [0, 2, 0, 10],
                        table: {widths: [45, "*", 150, 80], body: this.bodyRptHHeader()}
                    },
                    {
                        text: "SUBJECT : Hotel Reservation",
                        bold: true
                    },
                    {
                        text: "Reservation Detail :",
                        bold: true
                    },
                    {
                        margin: [0, 2, 0, 10],
                        table: {widths: ["*", "*", "*", "*", "*"], body: this.bodyRptHCheckinout()}
                    },
                    {
                        columns: [
                            {
                                width: 35,
                                text: "Remark:",
                                bold: true
                            },
                            {
                                text: "*** Please reserve NON-SMOKING Room/ 1 Bed-room ONLY ***",
                            },
                        ]
                    },
                    {text: "Term of Payment:",bold: true},
                    {
                        margin: [0, 2, 0, 10],
                        table: {
                            widths: ["*"],
                            body: [
                                [
                                    {border: [true, true, true, false],
                                        columns: [
                                            {text: "Conditions:",bold: true},
                                        ]
                                    },
                                ],
                                [
                                    {
                                        border: [true, false, true, false],
                                        columns: [
                                            {margin: [5, 0, 0, 0], width: 20, text: "1."},
                                            {width: 112,text: "Issue an invoice in the name of "},
                                            {text: "PTT Exploration and Production Public Company Limited.",bold: true},
                                        ]
                                    },
                                ],
                                [
                                    {
                                        border: [true, false, true, false],
                                        columns: [
                                            {margin: [5, 0, 0, 0], width: 20, text: "2."},
                                            {width: 173, text: "The Company will be responsible for payment of "},
                                            {text: "room carge including ABF only.",bold: true},
                                        ]
                                    },
                                ],
                                [
                                    {
                                        border: [true, false, true, false],
                                        columns: [
                                            {
                                                margin: [5, 0, 0, 0],
                                                width: 20,
                                                text: "",
                                            },
                                            {
                                                text: "Other expenses will be settled by Guest."
                                            },
                                        ]
                                    },
                                ],
                                [
                                    {
                                        border: [true, false, true, true],
                                        columns: [
                                            {
                                                margin: [5, 0, 0, 0],
                                                width: 20,
                                                text: "3.",
                                            },
                                            {
                                                text: "Send an invoice to Corporate Accounting, PTTEP Head Office.",
                                            },
                                        ]
                                    },
                                ],
                            ]
                        }
                    },
                    {
                        columns: [
                            {
                                width: 230,
                                text: "Thank you and looking forward to receiving your confirmation by ",
                            },
                            {
                                width: 48,
                                text: "return fax to",
                                bold: true,
                                decoration: "underline"
                            },
                            {
                                width: 50,
                                text: "02 537 7683",
                                bold: true
                            },
                            {
                                text: "with the attention to the underdesigned.",
                                bold: true,
                                decoration: "underline"
                            },
                        ]
                    },
                    {
                        margin: [0, 20, 0, 0],
                        text: "Best regards,",
                    },
                    {
                        margin: [0, 50, 0, 0],
                        text: `${PROFILE.FIRSTNAME} ${PROFILE.LASTNAME}`,
                    },
                    {
                        text: PROFILE.POSITION,
                    },
                    {
                        text: (PROFILE.DIRECT_PHONE ? `0-2${PROFILE.DIRECT_PHONE}` : "")
                    },
                    {
                        margin: [0, 10, 0, 10],
                        table: {
                            widths: ["*"],
                            body: this.bodyRptHBody()
                        }
                    },
                    {
                        text: "Remark :",
                        bold: true,
                    },
                    {
                        text: "In case there is any booking done directly by our staffs, we suggest you to attach any related documents ( i.e. staff's ID, " +
                        "booking fax by their secretaries) to the invoices that you will send to PTTEP for further Payment.",
                    },
                ],
                pageSize: "A4",
                styles:{
                    boldTrue: {
                        bold: true
                    },
                    font16: {
                        alignment: 'left',
                        fontSize: 16
                    },
                },
                defaultStyle: {
                    font: 'CordiaNew'
                }
            }
            const app = this
            pdfMake.createPdf(doc).getBlob(blob=>{
                const formData = new FormData()
                formData.append('file', blob, 'hotel reservation.pdf')
                app.saveHotel(type, formData)
            })
        },
        bodyRptH(){
            const PROFILE = JSON.parse(localStorage.PROFILE)
            return [
                [
                    {
                        text: "FROM :",
                        bold: true
                    },
                    {
                        text: `${PROFILE.FIRSTNAME} ${PROFILE.LASTNAME}`
                    },
                    {
                        text: "TEL :",
                        bold: true
                    },
                    {
                        text: (PROFILE.DIRECT_PHONE ? `0-2${PROFILE.DIRECT_PHONE}` : "")
                    },
                    {
                        text: "DIRECT FAX :",
                        bold: true
                    },
                    {
                        text: "02 537 7683"
                    },
                ],
                [
                    {
                        text: "TOTAL NUMBER OF PAGES (INCLUDING THIS PAGE) :",
                        bold: true,
                        colSpan: 3
                    },
                    {},
                    {},
                    {
                        text: "1"
                    },
                    {
                        text: "DATE :",
                        bold: true
                    },
                    {
                        text: moment().format(this.formatStrDMY),
                        bold: true
                    }
                ]
            ]
        },
        bodyRptHBody(){
            const PROFILE = JSON.parse(localStorage.PROFILE)
            return [
                [
                    {
                        border: [true, true, true, false],
                        columns: [
                            {
                                width: 55,
                                table: {
                                    body: [
                                        [{border: [false, false, false, false],text: "Staff ID No. :"}]
                                    ]
                                }
                            },
                            {
                                width: 100,
                                table: {
                                    widths: [100],
                                    body: [
                                        [{border: [false, false, false, true],text: PROFILE.EMPLOYEE_ID,}]
                                    ]
                                }
                            },
                            {
                                width: 45,
                                margin: [5, 0, 0, 0],
                                table: {
                                    body: [
                                        [{border: [false, false, false, false],text: "TR No. :"}]
                                    ]
                                }
                            },
                            {
                                width: 100,
                                table: {
                                    widths: [100],
                                    body: [
                                        [{border: [false, false, false, true],text: this.TRNo,}]
                                    ]
                                }
                            },
                            {
                                width: 73,
                                margin: [5, 0, 0, 0],
                                table: {
                                    body: [
                                        [{border: [false, false, false, false],text: "Budget Holder :"}]
                                    ]
                                }
                            },
                            {
                                width: "*",
                                table: {
                                    widths: ["*"],
                                    body: [
                                        [{border: [false, false, false, true],text: this.BudgetHolder,}]
                                    ]
                                }
                            },
                        ]
                    },
                ],
                [
                    {
                        border: [true, false, true, false],
                        columns: [
                            {
                                width: 55,
                                table: {
                                    body: [
                                        [{border: [false, false, false, false],text: "Purpose :"}]
                                    ]
                                }
                            },
                            {
                                width: "*",
                                table: {
                                    widths: ["*"],
                                    body: [
                                        [{border: [false, false, false, true],text: this.PurposeofTravel,}]
                                    ]
                                }
                            },
                        ]
                    },
                ],
                [
                    {
                        border: [true, false, true, false],
                        columns: [
                            {
                                width: 55,
                                table: {
                                    body: [
                                        [{border: [false, false, false, false],text: "Project :"}]
                                    ]
                                }
                            },
                            {
                                width: "*",
                                table: {
                                    widths: ["*"],
                                    body: [
                                        [{border: [false, false, false, true],text: this.Project,}]
                                    ]
                                }
                            },
                            {
                                width: 73,
                                table: {
                                    body: [
                                        [{border: [false, false, false, false],text: "Approved Date :"}]
                                    ]
                                }
                            },
                            {
                                width: "*",
                                table: {
                                    widths: ["*"],
                                    body: [
                                        [{border: [false, false, false, true],text: (
                                            this.hotelObj.Mdate ? moment(
                                                this.hotelObj.Mdate).format(this.formatStrDMY) : "-"),}]
                                    ]
                                }
                            },
                        ]
                    },
                ],
                [
                    {
                        border: [true, false, true, true],
                        columns: [
                            {
                                width: 55,
                                table: {
                                    body: [
                                        [{border: [false, false, false, false],text: "Ref :"}]
                                    ]
                                }
                            },
                            {
                                width: "*",
                                table: {
                                    widths: ["*"],
                                    body: [
                                        [{border: [false, false, false, true],text: "-",}]
                                    ]
                                }
                            },
                            {
                                width: 73,
                                table: {
                                    body: [
                                        [{border: [false, false, false, false],text: "Confirmed Date :"}]
                                    ]
                                }
                            },
                            {
                                width: "*",
                                table: {
                                    widths: ["*"],
                                    body: [
                                        [{border: [false, false, false, true],text: "-",}]
                                    ]
                                }
                            },
                        ]
                    },
                ],
            ]
        },
        bodyRptHHeader(){
            return [
                [
                    {
                        text: "",
                        border: [true, true, false, false]
                    },
                    {
                        text: "COMPANY",
                        bold: true,
                        alignment: "center"
                    },
                    {
                        text: "ATTENTION",
                        bold: true,
                        alignment: "center"
                    },
                    {
                        text: "FAX NUMBER",
                        bold: true,
                        alignment: "center"
                    }
                ],
                [
                    {
                        text: "TO :",
                        bold: true,
                        alignment: "right",
                        border: [true, false, false, false]
                    },
                    {
                        text: this.hotelObj.HotelName
                    },
                    {
                        text: "",
                        bold: true
                    },
                    {
                        text: this.hotelObj.HTL_FAX
                    }
                ],
                [
                    {
                        text: "INTERNAL COPIES :",
                        bold: true,
                        colSpan: 2
                    },
                    {},
                    {
                        text: this.hotelObj.HTL_TEL,
                        colSpan: 2
                    },
                    {}
                ]
            ]
        },
        bodyRptHCheckinout(){
            return [
                [
                    {
                        text: "Guest Name",
                        bold: true,
                        alignment: "center"
                    },
                    {
                        text: "Check-in",
                        bold: true,
                        alignment: "center"
                    },
                    {
                        text: "Check-out",
                        bold: true,
                        alignment: "center"
                    },
                    {
                        text: "Room Type",
                        bold: true,
                        alignment: "center"
                    },
                    {
                        text: "No. of Night (s)",
                        bold: true,
                        alignment: "center"
                    }
                ],
                [
                    {
                        text: this.traveller.Name,
                    },
                    {
                        text: moment(this.hotelObj.CheckinDateStart).format(this.formatStrDMY),
                        alignment: "center"
                    },
                    {
                        text: moment(this.hotelObj.CheckinDateEnd).format(this.formatStrDMY),
                        alignment: "center"
                    },
                    {
                        text: this.hotelObj.RoomType,
                        alignment: "center"
                    },
                    {
                        text: this.hotelObj.Night,
                        alignment: "center"
                    },
                ],
            ]
        },
        getRptTGTicket(BookingOID, Traveller, Doc){
            this.dialogTicketManagement.dialog = false
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}RptTGTicket/GetRptTGTicket`, {
                BookingOID: BookingOID,
                Traveller: Traveller,
                Doc: Doc,
                TRNo: this.TRNo,
                BookingType: this.dialogTicketManagement.value.BookingType,
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(res=>{
                this.$store.state.LOADING = false
                const d = res.data.Result
                const BttU = d.BttAndBudgetHolder.filter(x=>x.Role === "BTTSupport")[0]
                if(res.data.Status){
                    this.RptTGTicket = {
                        BookingOID: BookingOID,
                        Doc: d.Doc,
                        Ref: d.Ref,
                        TravellerName: d.TravellerName,
                        TravellerPosition: d.TravellerPosition,
                        Paragraph1: d.Paragraph1,
                        SincerelyName: d.SincerelyName,
                        SincerelyPosition: d.SincerelyPosition,
                        BTTId: BttU.EMPLOYEE_ID,
                        BTTSupport: BttU.THAINAME,
                        BTTPosition: BttU.POSITIONTHAI,
                        DIRECT_PHONE: BttU.DIRECT_PHONE,
                        TGCreditCard: d.TGCreditCard,
                        Flight: d.Flight
                    }
                    this.dialogRptTGTicket = true
                }
                else{
                    this.RptTGTicket = {
                        BookingOID: BookingOID,
                        Doc: Doc,
                        Ref: "",
                        TravellerName: d.TravellerName,
                        TravellerPosition: d.TravellerPosition,
                        Paragraph1: "จึงเรียนมาเพื่อโปรดพิจารณาออกบัตรโดยสารเครื่องบิน ตามรายละเอียดดังกล่าวจักขอบพระคุณยิ่ง",
                        SincerelyName: "นางสาวอัจฉรา สันติวิริยะพิบูล",
                        SincerelyPosition: "ผู้จัดการ แผนกบริการเดินทางธุรกิจ",
                        BTTId: BttU.EMPLOYEE_ID,
                        BTTSupport: BttU.THAINAME,
                        BTTPosition: BttU.POSITIONTHAI,
                        DIRECT_PHONE: BttU.DIRECT_PHONE,
                        TGCreditCard: res.data.Result.TGCreditCard,
                        Flight: res.data.Result.Flight
                    }
                    this.dialogRptTGTicket = true
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        closeDialogRptTGTicket(){
            this.dialogRptTGTicket = false
            this.RptTGTicket = {
                Doc: "",
                Ref: "",
                TravellerName: "",
                TravellerPosition: "",
                Paragraph1: "",
                SincerelyName: "",
                SincerelyPosition: "",
                BTTId: "",
                BTTSupport: "",
                BTTPosition: "",
                DIRECT_PHONE: "",
                TGCreditCard: {
                    CardNumber: "",
                    CardName: "",
                    CardExpDate: ""
                },
                Flight: {
                    DepDate: "",
                    Route: "",
                    Flight: "",
                    Class: ""
                }
            }
            this.BookingOID = ""
        },
        async saveRptTGTicket(){
            const flightTG = [
                [
                    { text: "ผู้เดินทาง:", style: "font16", },
                    { text: this.RptTGTicket.TravellerName, style: "font16", colSpan: 3 },
                    {},
                    {},
                ],
                [
                    { text: "ตำแหน่ง:", style: "font16", },
                    { text: this.RptTGTicket.TravellerPosition, style: "font16", colSpan: 3 },
                    {},
                    {},
                ],
            ]
            await this.flightTGForm(flightTG)
            pdfMake.vfs = pdfFonts.pdfMake.vfs
            pdfMake.fonts = {
                CordiaNew: { normal: "CordiaNew.ttf", bold: "CordiaNewBold.ttf", }
            }
            const doc = {
                content:[
                    { margin: [0, 0, 0, 0], columns: this.$store.state.headerReport },
                    { text: `ที่ ปตท.สผ. ${this.RptTGTicket.Ref}`, style: "font16", margin: [0, 10, 0, 0] },
                    { text: this.$store.state.dateNowThai, style: "font16", alignment: "center", margin: [0, 15, 0, 0] },
                    {
                        text: "เรื่อง ขอความอนุเคราะห์ออกบัตรโดยสารเครื่องบิน",
                        style: "font16",
                        margin: [0, 15, 0, 0]
                    },
                    {
                        text: "เรียน ผู้จัดการกองขายภาคราชการ บริษัท การบินไทย จำกัด (มหาชน)",
                        style: "font16",
                        margin: [0, 8, 0, 0]
                    },
                    {
                        style: "font16",
                        margin: [0, 20, 0, 0],
                        columns: [
                            { text: "", width: 70 },
                            { text: `ด้วยบริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน) มีความประสงค์ให้พนักงาน` }
                        ]
                    },
                    {
                        text: "ใช้บริการเดินทางกับ บริษัท การบินไทยฯ โดยมีรายละเอียดดังต่อไปนี้",
                        style: "font16",
                    },
                    {
                        margin: [0, 8, 0, 0],
                        table: {
                            widths: [60, "*", 60, "*"],
                            body: flightTG
                        }
                    },
                    {
                        style: "font16",
                        margin: [0, 15, 0, 0],
                        columns: [
                            { text: "", width: 70 },
                            { text: "จึงเรียนมาเพื่อโปรดพิจารณาออกบัตรโดยสารเครื่องบิน ตามรายละเอียดดังกล่าว" }
                        ]
                    },
                    {
                        text: "จักขอบพระคุณยิ่ง",
                        style: "font16",
                    },
                    {
                        style: "font16",
                        margin: [0, 5, 0, 0],
                        alignment: "center",
                        columns: [
                            {
                                text: "",
                                width: 160
                            },
                            {
                                text: "ขอแสดงความนับถือ"
                            }
                        ]
                    },
                    {
                        style: "font16",
                        margin: [0, 35, 0, 0],
                        alignment: "center",
                        columns: [
                            {
                                text: "",
                                width: 160
                            },
                            {
                                text: `(${this.RptTGTicket.SincerelyName})`
                            }
                        ]
                    },
                    {
                        style: "font16",
                        margin: [0, 10, 0, 0],
                        alignment: "center",
                        columns: [
                            {
                                text: "",
                                width: 160
                            },
                            {
                                text: this.RptTGTicket.SincerelyPosition
                            }
                        ]
                    },
                    {
                        style: "font16",
                        margin: [0, 10, 0, 0],
                        text: `ผู้ประสานงาน: ${this.RptTGTicket.BTTSupport}`
                    },
                    {
                        style: "font16",
                        margin: [0, 5, 0, 0],
                        text: `${this.RptTGTicket.BTTPosition} โทร. 0-2537-${this.RptTGTicket.DIRECT_PHONE.replace("-", " ต่อ")}`
                    }
                ],
                footer: {
                    margin: [20, -10, 20, 10],
                    image: this.$store.state.NewWeb_footerPTTEP,
                    width: 555
                },
                pageSize: "A4",
                styles:{
                    boldTrue: {
                        bold: true
                    },
                    font16: {
                        alignment: 'left',
                        fontSize: 16
                    },
                },
                defaultStyle: {
                    font: 'CordiaNew'
                }
            }
            pdfMake.createPdf(doc).getBlob(blob=>{
                const formData = new FormData()
                const textDoc = this.RptTGTicket.Doc === "RptTGTicketBooked" ? "Booking" : ""
                formData.append('file', blob, `${textDoc} TG.pdf`)
                const jsonReq = JSON.stringify({
                    ...this.RptTGTicket,
                    TRNo: this.TRNo,
                })
                formData.append('req', jsonReq)
                this.dialogRptTGTicket = false
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}RptTGTicket/SaveRptTGTicket`, formData, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then(res=>{
                    this.$store.state.LOADING = false
                    if(res.data.Status){
                        this.closeDialogRptTGTicket()
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            text: res.data.ErrorMessage,
                            confirmButtonColor: '#00AEEF'
                        }).then(() => {
                            this.dialogRptTGTicket = true
                        }, () => {})
                    }
                }).catch(err=>{
                    this.$store.state.LOADING = false
                    console.log(err)
                })
            })
        },
        async flightTGForm(flightTG){
            if(this.RptTGTicket.Flight && this.RptTGTicket.Flight.length > 0){
                for(const d of this.RptTGTicket.Flight){
                    await new Promise(resolve=>{
                        flightTG.push(
                            [
                                {
                                    text: "วันที่เดินทาง:",
                                    style: "font16",
                                },
                                {
                                    text: this.depDateThai(d.DepDate),
                                    style: "font16",
                                },
                                {
                                    text: "เส้นทาง:",
                                    style: "font16",
                                },
                                {
                                    text: d.Route,
                                    style: "font16",
                                },
                            ],
                            [
                                {
                                    text: "Flight:",
                                    style: "font16",
                                },
                                {
                                    text: d.Flight,
                                    style: "font16",
                                },
                                {
                                    text: "Class:",
                                    style: "font16",
                                },
                                {
                                    text: d.Class,
                                    style: "font16",
                                },
                            ],
                        )
                        resolve()
                    })
                }
            }
            flightTG.push(
                [
                    {
                        text: "TR Number:",
                        style: "font16",
                    },
                    {
                        text: this.TRNo,
                        style: "font16",
                    },
                    {
                        text: "PNR Code:",
                        style: "font16",
                    },
                    {
                        text: (this.dialogTicketManagement.value ? this.dialogTicketManagement.value.PNR : ""),
                        style: "font16",
                    },
                ],
                [
                    {
                        text: "การชำระค่าใช้จ่าย :",
                        style: "font16",
                        colSpan: 4
                    },
                    {},
                    {},
                    {},
                ],
                [
                    {
                        text: `บัตรเครดิต ${this.RptTGTicket.TGCreditCard.CardName}
                        หมายเลข ${this.RptTGTicket.TGCreditCard.CardNumber} วันที่หมดอายุ ${this.RptTGTicket.TGCreditCard.CardExpDate}`,
                        style: "font16",
                        colSpan: 4
                    },
                    {},
                    {},
                    {},
                ],
                [
                    {
                        text: "กรุณาออกใบเสร็จรับเงินในนาม :",
                        style: "font16",
                        colSpan: 2,
                        color: "blue"
                    },
                    {},
                    {
                        text: "บริษัท ปตท. สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน)",
                        style: "font16",
                        colSpan: 2,
                        color: "blue"
                    },
                    {},
                ],
            )
        },
        sendMailToAgentBySelected(agentBySelected){
            this.dialogTicketManagement.dialog = false
            this.$store.state.LOADING = true
            const PROFILE = JSON.parse(localStorage.PROFILE)
            axios.post(`${this.$store.state.API_PATH}TravelRequest/SendMailToAgent`, {
                Agents: agentBySelected,
                TRNo: this.TRNo,
                PNR: this.dialogTicketManagement.value.PNR,
                BookingType: this.dialogTicketManagement.value.BookingType,
                BookingOID: this.dialogTicketManagement.value.BookingOID,
                BTTId: PROFILE.EMPLOYEE_ID,
                TravellerID: this.dialogTicketManagement.value.EMPLOYEE_ID,
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        text: res.data.ErrorMessage,
                        confirmButtonColor: '#00AEEF'
                    }).then(() => {
                    }, () => {})
                }
                this.$store.state.LOADING = false
            }).catch(err=>{
                console.log(err)
                this.$store.state.LOADING = false
            })
        },
        sendMailToAgentByBidding(){
            this.dialogTicketManagement.dialog = false
            this.$store.state.LOADING = true
            const PROFILE = JSON.parse(localStorage.PROFILE)
            axios.post(`${this.$store.state.API_PATH}TravelRequest/SendMailToBidding`, {
                TRNo: this.TRNo,
                PNR: this.dialogTicketManagement.value.PNR,
                BookingOID: this.dialogTicketManagement.value.BookingOID,
                TravellerID: this.dialogTicketManagement.value.EMPLOYEE_ID,
                BookingType: this.dialogTicketManagement.value.BookingType,
                BTTId: PROFILE.EMPLOYEE_ID
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(res=>{
                this.$store.state.LOADING = false
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        text: res.data.ErrorMessage,
                        confirmButtonColor: '#00AEEF'
                    }).then(() => {
                    }, () => {})
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        depDateThai(date){
            return `${moment(date).locale("th").format("Do MMMM")} ${Number(moment(date).format("YYYY")) + 543}`
        }
    },
    computed:{
        TotalAmount(){
            if(!this.dialogTicketManagement.value.TotalFare){
                this.dialogTicketManagement.value.TotalFare = 0
            }
            if(!this.dialogTicketManagement.value.NFTFee){
                this.dialogTicketManagement.value.NFTFee = 0
            }
            if(!this.dialogTicketManagement.value.Vat){
                this.dialogTicketManagement.value.Vat = 0
            }
            return parseInt(this.dialogTicketManagement.value.TotalFare) + parseInt(this.dialogTicketManagement.value.Vat) + parseInt(this.dialogTicketManagement.value.NFTFee)
        },
        TotalAdvanceAmount(){
            return _.sumBy(this.Traveler, ({ Flight })=> _.sumBy(Flight, "TotalFare")) +
            _.sumBy(this.Traveler, ({ Hotel })=> _.sumBy(Hotel, "TotalHotel"))
        },
        calInternal(){
            return this.Traveler.reduce((total, item)=>total+(item.TypeTraveler === "Internal" ? 1 : 0), 0)
        },
        calExternal(){
            return this.Traveler.reduce((total, item)=>total+(item.TypeTraveler === "External" ? 1 : 0), 0)
        },
        sumNightBTT() {
            return this.hotelObj.CheckinDateStart && this.hotelObj.CheckinDateEnd ? moment(this.hotelObj.CheckinDateEnd).diff(moment(this.hotelObj.CheckinDateStart), 'days') : ""
        },
        minDateBTT() {
            return this.hotelObj.CheckinDateStart ? new Date(new Date().setDate(new Date(this.hotelObj.CheckinDateStart).getDate() + 1)).toISOString().substring(0, 10) : ""
        }
    },
    mounted(){
        this.GetsvcClass();
        this.getSpecialMeal()
        if(sessionStorage.TRNo){
            this.TRNo = this.SimpleCrypto.decrypt(sessionStorage.TRNo).toString()
            this.GetObjAgentService()
            this.GetMyTravelRequestInformation()
        }
        sessionStorage.removeItem("isReIssue")
        sessionStorage.removeItem("HotelOID")
    }
}
</script>
<style scoped>
.font-W700{
    font-weight: 700;
}
.font-W500{
    font-weight: 500;
}
.card-detail{
    background: #F8F9FA;
    border: 1px solid #EBEEF4;
    border-radius: 10px;
}
.card-white{
    background: #F8F9FA;
}
.card-in-detail{
    background: #FFFFFF;
    /* Main bolder */

    border: 1px solid #EBEEF4;
    border-radius: 5px;
    vertical-align: top;
}
.row-card{
    background: #F8F9FA;
    border: 1px solid #EBEEF4;
    border-radius: 10px;
}
#customers td, #customers th {
  padding: 8px;
  white-space: nowrap;
}
.myTicket::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.myTicket::-webkit-scrollbar-track {
    background: #C8D6ED;
    border-radius: 5px;
}

.myTicket::-webkit-scrollbar-thumb {
    background: #00AEEF;
    border-radius: 5px;
}
.card-note{
    background: #FFFFFF;
    border: 1px solid #E6EBF4;
    border-radius: 5px;
}
#Note th {
    text-align: left;
    background: #F8F9FA;
    color: #747678;
    font-weight: 500;
    padding: 8px;
    white-space: nowrap;
}
#Note td {
  padding: 8px;
  color: #000000;
  white-space: nowrap;
}
/* #Verify tr {
    border-bottom: 1px solid #EBEEF4;
} */
#Verify th {
    text-align: left;
    background: #F8F9FA;
    background-color: white;
    color: #747678;
    font-weight: 500;
    padding: 8px;
    white-space: nowrap;
}
#Verify td {
  padding: 8px;
  color: #000000;
  white-space: nowrap;
  background-color: white;
}
.tbRptTG{
    border: 1px solid #dddddd;
    padding: 8px;
}
</style>
