<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <span>
                    <span class="font-W700" style="font-size: 1em;">My Task</span>
                </span>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <v-row align="center" no-gutters>
                            <v-col>
                                <span size="3" style="font-weight: 500; color: #000000;">Search</span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters align="center">
                            <v-col lg="4" sm="6" cols="12" class="pr-2">
                                Travel Request No.
                                <v-text-field outlined dense background-color="white" v-model="TRNo"
                                append-icon="mdi-close" @click:append="TRNo = ''"></v-text-field>
                            </v-col>
                            <v-col lg="4" sm="6" cols="12" class="pr-2">
                                Budget Holder
                                <v-autocomplete outlined dense placeholder="Select All" :items="budgetHolderMyTaskItem"
                                background-color="white" v-model="BudgetHolder" append-icon="mdi-close" @click:append="BudgetHolder = ''"
                                item-text="BudgetHolder" item-value="BudgetHolder"></v-autocomplete>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Status
                                <v-select outlined dense placeholder="Select All" :items="statusItem"
                                background-color="white" v-model="Status" append-icon="mdi-close" @click:append="Status = ''"></v-select>
                            </v-col>
                            <v-col lg="1" sm="6" cols="12" class="text-lg-left text-sm-left text-center">
                                <v-btn color="primary" elevation="0" @click="getMyTask()"
                                class="mb-lg-1 mb-md-1 mb-sm-1 mb-6">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <v-row align="center" no-gutters>
                            <v-col>
                                <span size="3" style="font-weight: 500; color: #000000;">Request list</span>
                            </v-col>
                            <v-col class="d-flex justify-end">
                                <span style="font-weight: 300; color: #747678;">(update {{ updatePageMyTask }})</span>
                            </v-col>
                        </v-row>
                        <v-card elevation="0">
                            <v-data-table :headers="headersMyTask" :items="itemRequestMyTask" class="elevation-1" sort-by="Pin" sort-desc
                            mobile-breakpoint style="white-space: nowrap" hide-default-footer height="30vh" @click:row="rowClicked"
                            @page-count="pageCount = $event" :page.sync="page" :items-per-page="itemPage" @load="onPagination($event)">
                                <template v-slot:[`item.Pin`]="{ item }">
                                    <v-img v-if="item.Pin" style="cursor: pointer;" :src="require('@/assets/icons/icon_pin.svg')"
                                    @click="addOrRemovePin(item)" width="20px"></v-img>
                                    <v-img v-else style="cursor: pointer;" :src="require('@/assets/icons/icon_unpin.svg')"
                                    @click="addOrRemovePin(item)" width="20px"></v-img>
                                </template>
                                <template v-slot:[`item.RemainningDays`]="{ item }">
                                    <span v-if="item.RemainningDays <= 5" color="#FF0000">{{ item.RemainningDays }}</span>
                                    <span v-else>{{ item.RemainningDays }}</span>
                                </template>
                            </v-data-table>
                        </v-card>
                        <v-row class="mt-3 hidden-xs-only" no-gutters>
                            <v-col class="d-flex" cols="12">
                                <span class="mr-3 mt-3">Show</span>
                                <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                v-model="itemPage" background-color="white" class="select-data-table shrink"></v-select>
                                <span class="ml-3 mt-3">items per page</span>
                                <v-pagination v-model="page" :length="pageCount" :total-visible="5"
                                class="pagination-custom" style="width: 60%;"></v-pagination>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                            <v-col class="d-flex" cols="12">
                                <span class="mr-3 mt-3">Show</span>
                                <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                v-model="itemPage" background-color="white" class="select-data-table shrink"></v-select>
                                <span class="ml-3 mt-3">items per page</span>
                            </v-col>
                            <v-col>
                                <v-pagination v-model="page" :length="pageCount" :total-visible="5"
                                class="pagination-custom"></v-pagination>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import axios from 'axios';
import * as moment from 'moment'
export default {
    data(){
        return{
            TRNo: "",
            BudgetHolder: "",
            Status: "",
            TravelerInput: "",
            Project: "",
            Company: "",
            Country: "",
            CityorProvince: "",
            dateFrom: "",
            dateTo: "",
            DepOn: "",
            ReturnOn: "",
            FlightNumber: "",
            BusinessTravelType: "",
            updatePageMyTask: moment().format("DD MMM YYYY hh:mm A"),
            itemsPerPage:[
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                // { text: "15", value: 15 },
                // { text: "25", value: 25 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemPage: 5,
            page: 1,
            pageCount: 2,
            headersMyTask: [
                { text: 'Budget Holder', value: 'BudgetHolder', sortable: false },
                { text: 'Travel Request Number', value: 'TRNo', sortable: false },
                { text: 'Travel Date From', value: 'BusinessStartDate', sortable: false },
                { text: 'Remainning Days', value: 'RemainningDays', sortable: false, align: 'center' },
                { text: 'Business Travel Type', value: 'BusinessTravelType', sortable: false },
                { text: 'Business Travel Case', value: 'BusinessTravelCase', sortable: false },
                { text: 'Trip Purpose', value: 'PurposeofTravel', sortable: false },
                { text: 'Status', value: 'BTTStatus', sortable: false, align: 'center' },
            ],
            itemRequestMyTask: [],
            budgetHolderMyTaskItem: [],
            statusItem: ["", "Booked", "Issued", "Sent"],
        }
    },
    methods:{
        getMyTask(){
            this.itemRequestMyTask = []
            this.$store.state.LOADING = true
            const PROFILE = JSON.parse(localStorage.PROFILE)
            axios.post(this.$store.state.API_PATH + "TravelRequest/GetMyTask", {
                EMPLOYEE_ID: PROFILE.EMPLOYEE_ID,
                TRNo: this.TRNo,
                BudgetHolder: this.BudgetHolder,
                TravelerInput: this.TravelerInput,
                Status: this.Status,
                Company: this.Company,
                Project: this.Project,
                CityorProvince: this.CityorProvince,
                Country: this.Country,
                BusinessStartDate: this.dateFrom,
                DepartureDateTime: this.DepOn,
                ArrivalDateTime: this.ReturnOn,
                BusinessEndDate: this.dateTo,
                FlightNumber: this.FlightNumber,
                BusinessTravelType: this.BusinessTravelType
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    this.itemRequestMyTask = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        getBudgetProjectByHolderMytask(){
            axios.post(this.$store.state.API_PATH + "BudgetProject/GetBudgetProjectByHolder", {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                if(res.data.Status){
                    this.budgetHolderMyTaskItem = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        rowClicked(item){
            const formReqID = {
                TravelId: item.TravelRequestOID,
                EMPLOYEE_ID: null
            }
            sessionStorage.formReqID = JSON.stringify(formReqID)
            setTimeout(() => {
                location.href = "createTravelRq"
            }, 1);
        },
    },
    mounted(){
        this.getBudgetProjectByHolderMytask()
        this.getMyTask()
    }
}
</script>
<style scoped>
.font-W700{
    font-weight: 700;
}
.row-card{
    background: #F8F9FA;
    border: 1px solid #EBEEF4;
    border-radius: 10px;
}
</style>
