<template>
    <v-container fluid fill-height v-if="!$store.state.LOADING">
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-row class="ma-2" no-gutters>
                        <v-col cols="12">
                            <v-img :src="require('@/assets/image/logo_pttep.png')" class="mx-auto" style="width: 110px;" />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="text-center">
                            <span>หนังสือรับรอง</span>
                        </v-col>
                    </v-row>
                    <v-row class="ml-4 mr-4">
                        <v-col cols="8" lg="2" md="2" sm="3" style="display: flex; align-items: center;">
                            <span class="mr-2" style="white-space: nowrap;">ที่ ปตท.สผ.</span>
                            <v-text-field outlined dense hide-details v-model="docNo"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="ml-4 mr-4">
                        <v-col cols="12" >
                            <span class="ml-12">
                                บริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน) ซึ่งเป็นบริษัทที่มีสถานะเป็น
                                รัฐวิสาหกิจตามพระราชบัญญัติงบประมาณปี พ.ศ. 2502 ภายใต้การกำกับดูแลของกระทรวงพลังงาน
                                ขอรับรองว่า {{ $store.state.docPassport.nameTH }} เป็นพนักงาน ของบริษัทฯ
                                ตั้งแต่วันที่ {{ $store.state.docPassport.joinDate }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="ml-4 mr-4">
                        <v-col cols="4" lg="7" md="7" sm="6"></v-col>
                        <v-col>
                            <span>ออกให้ ณ วันที่ {{ $store.state.dateNowThai }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="ml-4 mr-4 mt-10">
                        <v-col cols="4" lg="7" md="7" sm="6"></v-col>
                        <v-col>
                            <v-text-field outlined dense hide-details v-model="$store.state.docPassport.nameBudget" style="width: 250px;"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="ml-4 mr-4">
                        <v-col cols="4" lg="7" md="7" sm="6"></v-col>
                        <v-col>
                            <v-text-field outlined dense hide-details v-model="$store.state.docPassport.posiBudget" style="width: 350px;"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="ml-4 mr-4">
                        <v-col cols="4" lg="7" md="7" sm="6"></v-col>
                        <v-col>
                            <v-text-field outlined dense hide-details style="width: 350px;"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-5 ml-4 mr-4">
                        <v-col cols="12" lg="3" md="3" sm="6">
                            <v-text-field outlined dense hide-details v-model="$store.state.docPassport.nameBTT"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2 ml-4 mr-4">
                        <v-col cols="12" lg="3" md="3" sm="6">
                            <v-text-field outlined dense hide-details v-model="$store.state.docPassport.posiBTT"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn color="primary" elevation="0" @click="printPDF()">Print To PDF</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import pdfFonts from '@/assets/custom-font.js'
export default {
    data(){
        return{
            docNo: "",
            TRNo: "",
            EMP_ID: "",
            Type: "",
        }
    },
    methods:{
        async printPDF(){
            pdfMake.vfs = pdfFonts.pdfMake.vfs
            pdfMake.fonts = {
                CordiaNew: {
                    normal: "CordiaNew.ttf"
                }
            }
            const doc = {
                content:[
                    {
                        image: this.$store.state.logo_pttep,
                        width: 80,
                        alignment: 'center'
                    },
                    {
                        text: "หนังสือรับรอง",
                        style: "font16",
                        alignment: 'center',
                        margin: [0, 10, 0, 0]
                    },
                    {
                        text: `ที่ ปตท.สผ. ${this.docNo}`,
                        style: "font16",
                        margin: [0, 10, 0, 0]
                    },
                    {
                        style: "font16",
                        margin: [0, 20, 0, 0],
                        columns: [
                            {
                                text: "",
                                width: 70
                            },
                            {
                                text: `บริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน) ซึ่งเป็นบริษัทที่มีสถานะเป็น รัฐวิสาหกิจ `
                            }
                        ]
                    },
                    {
                        text: `ตามพระราชบัญญัติงบประมาณปี พ.ศ. 2502 ภายใต้การกำกับดูแลของกระทรวงพลังงาน \nขอรับรองว่า ${this.$store.state.docPassport.nameTH}`,
                        style: "font16",
                    },
                    {
                        style: "font16",
                        margin: [0, 30, 0, 0],
                        alignment: "center",
                        columns: [
                            {
                                text: "",
                                width: 160
                            },
                            {
                                text: `ออกให้ ณ วันที่ ${this.$store.state.dateNowThai}`
                            }
                        ]
                    },
                    this.$store.state.docPassport.rptOffpss02NameBtt
                ],
                pageSize: "A4",
                styles:{
                    font16: {
                        alignment: 'left',
                        fontSize: 16
                    },
                },
                defaultStyle: {
                    font: 'CordiaNew'
                }
            }
            pdfMake.createPdf(doc).download(`RptOffPss02_${this.$store.state.docPassport.nameTH}.pdf`)
        },
        getRtpOffPss2(TRNo, EMP_ID, Type){
            this.$store.commit("docPassport/getDocPassport", {
                TRNo: TRNo,
                EMP_ID: EMP_ID,
                Type: Type,
            })
        }
    },
    mounted(){
        if(this.$route.query.TRNo && this.$route.query.EMP_ID && this.$route.query.Type){
            const q = this.$route.query
            this.TRNo = q.TRNo
            this.EMP_ID = q.EMP_ID
            this.Type = q.Type
            this.getRtpOffPss2(q.TRNo, q.EMP_ID, q.Type)
        }
    }
}
</script>
