<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <span>
                    <span class="font-W700" style="font-size: 1em;">BTT</span>
                </span>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <v-row align="center" no-gutters>
                            <v-col>
                                <span size="3" style="font-weight: 500; color: #000000;">Search</span>
                            </v-col>
                            <v-col class="d-flex justify-end">
                                <v-switch inset label="Advanced Search" v-model="advanceSearch" @click="getAdvanceSearch()"></v-switch>
                            </v-col>
                        </v-row>
                        <v-row no-gutters align="center">
                            <v-col lg="4" sm="6" cols="12" class="pr-2">
                                TR No.
                                <v-text-field outlined dense placeholder="Travel Request Number"
                                v-model="TRNo" background-color="white"></v-text-field>
                            </v-col>
                            <v-col lg="4" sm="6" cols="12" class="pr-2">
                                Budget Holder
                                <v-autocomplete outlined dense placeholder="Budget Holder" :items="budgetHolderBttItem"
                                item-text="BudgetHolder" item-value="BudgetHolder" v-model="BudgetHolder"
                                background-color="white"></v-autocomplete>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Status
                                <v-select outlined dense placeholder="Status" :items="statusItem"
                                v-model="Status" background-color="white"></v-select>
                            </v-col>
                            <v-col lg="1" sm="6" cols="12" class="text-lg-left text-sm-left text-center">
                                <v-btn color="primary" elevation="0" class="mb-lg-1 mb-md-1 mb-sm-1 mb-6" @click="getBTT()">Search</v-btn>
                            </v-col>
                        </v-row>
                        <template v-if="advanceSearch">
                            <v-row no-gutters align="center">
                                <v-col lg="6" sm="6" cols="12" class="pr-2">
                                    Traveler ID, Name
                                    <v-autocomplete outlined dense placeholder="Traveler name" background-color="white"
                                    v-model="TravelerInput" :items="TravelerBttItem" item-text="txt" item-value="val">
                                        <template v-slot:append>
                                            <v-icon color="#00AEEF" @click="getUserProfileByInput()">mdi-magnify</v-icon>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col lg="6" sm="6" cols="12" class="pr-2">
                                    Project name
                                    <v-autocomplete outlined dense placeholder="Project name" :items="ProjectNameBttItem" item-text="ProjectName" item-value="ProjectName"
                                    v-model="Project" background-color="white"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row no-gutters align="center">
                                <v-col lg="6" sm="12" cols="12" class="pr-2">
                                    Company
                                    <v-text-field outlined dense placeholder="Company"
                                    v-model="Company" background-color="white"></v-text-field>
                                </v-col>
                                <v-col lg="3" sm="6" cols="12" class="pr-2">
                                    Country
                                    <v-autocomplete outlined dense placeholder="-- Select All --" :items="CountryItemBtt" item-text="Name" item-value="Code"
                                    v-model="Country" background-color="white"></v-autocomplete>
                                </v-col>
                                <v-col lg="3" sm="6" cols="12" class="pr-2">
                                    City/Province
                                    <v-autocomplete outlined dense placeholder="City/Province"
                                    v-model="CityorProvince" background-color="white" :items="CityItemBtt" item-text="Name" item-value="Code"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col lg="3" sm="6" cols="12" class="pr-2">
                                    Business Date From
                                    <v-menu v-model="menuDateFrom" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field outlined dense v-model="dateFrom" label="Business Date From" readonly v-bind="attrs" v-on="on">
                                                <template v-slot:append>
                                                    <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="dateFrom" @input="menuDateFrom = false" color="#00AEEF"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col lg="3" sm="6" cols="12" class="pr-2">
                                    Business Date To
                                    <v-menu v-model="menuDateTo" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field outlined dense v-model="dateTo" label="Business Date To" readonly v-bind="attrs" v-on="on">
                                                <template v-slot:append>
                                                    <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="dateTo" @input="menuDateTo = false" color="#00AEEF"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col lg="3" sm="6" cols="12" class="pr-2">
                                    Departure On
                                    <v-menu v-model="menuDepOn" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field outlined dense v-model="DepOn" label="Departure On" readonly v-bind="attrs" v-on="on">
                                                <template v-slot:append>
                                                    <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="DepOn" @input="menuDepOn = false" color="#00AEEF"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col lg="3" sm="6" cols="12" class="pr-2">
                                    Return On
                                    <v-menu v-model="menuReturnOn" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field outlined dense v-model="ReturnOn" label="Return On" readonly v-bind="attrs" v-on="on">
                                                <template v-slot:append>
                                                    <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="ReturnOn" @input="menuReturnOn = false" color="#00AEEF"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row no-gutters align="center">
                                <v-col lg="3" sm="6" cols="12" class="pr-2">
                                    Flight No.
                                    <v-text-field outlined dense placeholder="Flight Number"
                                    v-model="FlightNumber" background-color="white"></v-text-field>
                                </v-col>
                                <v-col lg="3" sm="6" cols="12" class="pr-2">
                                    Business Travel Type
                                    <v-select outlined dense placeholder="Business Travel Type"
                                    v-model="BusinessTravelType" background-color="white"></v-select>
                                </v-col>
                            </v-row>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <v-row align="center" no-gutters>
                            <v-col>
                                <span size="3" style="font-weight: 500; color: #000000;">Request list</span>
                            </v-col>
                            <v-col class="d-flex justify-end">
                                <span style="font-weight: 300; color: #747678;">(update {{ updatePageBtt }})</span>
                            </v-col>
                        </v-row>
                        <v-card elevation="0">
                            <v-data-table :headers="headers" :items="itemRequest" class="elevation-1"
                            mobile-breakpoint style="white-space: nowrap" hide-default-footer height="30vh"
                            @click:row="rowClicked" show-select v-model="selectedItem" item-key="TRNo" checkbox-color="primary"
                            @page-count="pageCount = $event" :page.sync="page" :items-per-page="itemPage" @load="onPagination($event)">
                            </v-data-table>
                        </v-card>
                        <v-row class="mt-3 hidden-xs-only" no-gutters>
                            <v-col class="d-flex" cols="12">
                                <span class="mr-3 mt-3">Show</span>
                                <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                v-model="itemPage" background-color="white" class="select-data-table shrink"></v-select>
                                <span class="ml-3 mt-3">items per page</span>
                                <v-pagination v-model="page" :length="pageCount" :total-visible="5"
                                class="pagination-custom" style="width: 60%;"></v-pagination>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                            <v-col class="d-flex" cols="12">
                                <span class="mr-3 mt-3">Show</span>
                                <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                v-model="itemPage" background-color="white" class="select-data-table shrink"></v-select>
                                <span class="ml-3 mt-3">items per page</span>
                            </v-col>
                            <v-col>
                                <v-pagination v-model="page" :length="pageCount" :total-visible="5"
                                class="pagination-custom"></v-pagination>
                            </v-col>
                        </v-row>
                        <v-row no-gutters align="center">
                            <v-col lg="4" sm="6" cols="12" class="pr-2">
                                Transfer to BTT User
                                <v-autocomplete outlined dense placeholder="Select" v-model="BTTUser"
                                background-color="white" :items="BTTUserItem" item-text="txt" item-value="val"></v-autocomplete>
                            </v-col>
                            <v-col lg="1" sm="6" cols="12" class="text-lg-left text-sm-left text-center">
                                <v-btn color="primary" elevation="0" class="mb-lg-1 mb-md-1 mb-sm-1 mb-6" @click="transferToBTTUser()">Transfer</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import * as moment from 'moment'
export default {
    data(){
        return{
            BTTUser: "",
            selectedItem: [],
            CityItemBtt: [],
            CountryItemBtt: [],
            ProjectNameBttItem: [],
            TravelerBttItem: [],
            TravelerInput: "",
            FlightNumber: "",
            BusinessTravelType: "",
            Project: "",
            Company: "",
            Country: "",
            CityorProvince: "",
            DepOn: "",
            ReturnOn: "",
            dateFrom: "",
            dateTo: "",
            menuDepOn: false,
            menuReturnOn: false,
            menuDateFrom: false,
            menuDateTo: false,
            advanceSearch: false,
            statusItem: ["", "Booked", "Issued", "Sent"],
            TRNo: "",
            BudgetHolder: "",
            Status: "",
            updatePageBtt: moment().format("DD MMM YYYY hh:mm A"),
            itemsPerPage:[
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                // { text: "15", value: 15 },
                // { text: "25", value: 25 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemPage: 5,
            page: 1,
            pageCount: 2,
            headers: [
                // { text: 'Pin', value: 'Pin', sortable: false },
                { text: 'Budget Holder Department', value: 'BudgetHolder', sortable: false },
                { text: 'Travel Request Number', value: 'TRNo', sortable: false },
                { text: 'Travel Date From', value: 'BusinessStartDate', sortable: false },
                { text: 'Remainning Days', value: 'RemainningDays', sortable: false, align: 'center' },
                { text: 'Business Travel Type', value: 'BusinessTravelType', sortable: false },
                { text: 'Business Travel Case', value: 'BusinessTravelCase', sortable: false },
                { text: 'Trip Purpose', value: 'PurposeofTravel', sortable: false },
                { text: 'BTT Status', value: 'BTTStatus', sortable: false },
                { text: 'Take Owner By', value: 'TakeOwnerBy', sortable: false },
            ],
            itemRequest: [],
            budgetHolderBttItem: [],
            BTTUserItem: [],
        }
    },
    methods:{
        rowClicked(item){
            if (this.selectedItem.includes(item)) {
                this.selectedItem = this.selectedItem.filter(selectedKeyID => selectedKeyID !== item)
            }
            else {
                this.selectedItem.push(item)
            }
        },
        getBTT(){
            this.itemRequest = []
            this.$store.state.LOADING = true
            const PROFILE = JSON.parse(localStorage.PROFILE)
            let PinItemBtt = []
            if(localStorage.PIN){
                PinItemBtt = JSON.parse(localStorage.PIN)
            }
            axios.post(this.$store.state.API_PATH + "TravelRequest/GetBTT", {
                EMPLOYEE_ID: PROFILE.EMPLOYEE_ID,
                TRNo: this.TRNo,
                BudgetHolder: this.BudgetHolder,
                TravelerInput: this.TravelerInput,
                Status: this.Status,
                Project: this.Project,
                Country: this.Country,
                Company: this.Company,
                CityorProvince: this.CityorProvince,
                BusinessStartDate: this.dateFrom,
                DepartureDateTime: this.DepOn,
                BusinessEndDate: this.dateTo,
                ArrivalDateTime: this.ReturnOn,
                FlightNumber: this.FlightNumber,
                BusinessTravelType: this.BusinessTravelType,
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    this.itemRequest = res.data.Result
                    if(PinItemBtt.length > 0){
                        let n = 1
                        for(let i = 0;i < PinItemBtt.length;i++){
                            const elm = PinItemBtt[i]
                            const item = this.itemRequest.filter(obj=>obj.TRNo === elm)
                            if(item.length > 0){
                                item[0].Pin = n
                                n++
                            }
                        }
                    }
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        getBudgetProjectByHolderBtt(){
            axios.post(this.$store.state.API_PATH + "BudgetProject/GetBudgetProjectByHolder", {
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                if(res.data.Status){
                    this.budgetHolderBttItem = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        getUserProfileByInput(){
            this.TravelerBttItem = []
            this.$store.state.LOADING = true
            axios.post(this.$store.state.API_PATH + "UserProfile/GetUserProfileByInput", {
                TravelerInput: this.TravelerInput
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    this.TravelerBttItem = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        getAdvanceSearch(){
            this.clearValBtt()
            if(this.advanceSearch){
                this.getBudgetProjectByProjectName()
                this.getCountryCodeALL()
                this.getCityCodeALL()
            }
        },
        getBudgetProjectByProjectName(){
            this.ProjectNameBttItem = []
            axios.post(this.$store.state.API_PATH + "BudgetProject/GetBudgetProjectByProjectName", {
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                if(res.data.Status){
                    this.ProjectNameBttItem = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        getCountryCodeALL(){
            this.CountryItemBtt = []
            axios.post(this.$store.state.API_PATH + "CountryCode/GetCountryCodeALL", {
                Language: "en"
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                if(res.data.Status){
                    this.CountryItemBtt = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        getCityCodeALL(){
            this.CityItemBtt = []
            axios.post(this.$store.state.API_PATH + "CityCode/GetCityCodeALL", {
                Language: "en"
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                if(res.data.Status){
                    this.CityItemBtt = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        clearValBtt(){
            this.TRNo = ""
            this.BudgetHolder = ""
            this.Status = ""
            this.TravelerInput = ""
            this.Project = ""
            this.Company = ""
            this.Country = ""
            this.CityorProvince = ""
            this.dateFrom = ""
            this.dateTo = ""
            this.DepOn = ""
            this.ReturnOn = ""
            this.FlightNumber = ""
            this.BusinessTravelType = ""
        },
        getBTTUser(){
            this.BTTUserItem = []
            axios.post(this.$store.state.API_PATH + "BudgetProject/GetBTTUser", {
                // Language: "en"
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                if(res.data.Status){
                    this.BTTUserItem = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        transferToBTTUser(){
            this.$store.state.LOADING = true
            axios.post(this.$store.state.API_PATH + "TravelRequest/UpdateTravelRequestBTTSupport", {
                BTTUser: this.BTTUser,
                BTTUserItem: this.selectedItem
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    Swal.fire({
                        icon: 'success',
                        text: 'Success',
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                        this.BTTUser = ""
                        this.selectedItem = []
                        this.getBTT()
                    },()=>{})
                }
                else{
                    Swal.fire({
                        icon: 'error',
                        text: res.data.ErrorMessage,
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                    },()=>{})
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        }
    },
    mounted(){
        this.getBudgetProjectByHolderBtt()
        this.getBTT()
        this.getBTTUser()
    }
}
</script>
<style scoped>
.font-W700{
    font-weight: 700;
}
.row-card{
    background: #F8F9FA;
    border: 1px solid #EBEEF4;
    border-radius: 10px;
}
</style>
