<template>
  <div>
    <v-app-bar app class="bg-image" :style="{ 'background-image': 'url(' + require('@/assets/banner.svg') + ')' }">
      <v-app-bar-nav-icon @click.native.stop="sideNav = !sideNav" class="hidden-lg-and-up"
        style="color: #00AEEF;"></v-app-bar-nav-icon>
      <v-img class="shrink hidden-md-and-down" :src="require('@/assets/pttep.svg')"></v-img>
      <v-col justify="center" align="center">
        <v-img class="shrink hidden-lg-and-up" :src="require('@/assets/pttep.svg')" width="180"></v-img>
      </v-col>
      <v-img class="shrink hidden-md-and-down" cli :src="require('@/assets/image/user_default.svg')" width="50"></v-img>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-img v-on="on" v-bind="attrs" class="shrink hidden-lg-and-up" cli
            :src="require('@/assets/image/user_default.svg')" width="50"></v-img>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <span style="font-weight: 500; color: #00338D;" size="3">{{ nameUser }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span style="font-weight: 400;" size="2">
                   {{ roleUser }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout()">
            <v-list-item-title class="logout-btn">
              <v-icon style="color: #00338D;">mdi-logout</v-icon>
              <span>LOGOUT</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <span class="hidden-md-and-down">
        <span style="font-weight: 500; color: white;">{{ nameUser }}</span><br>
        <span style="font-weight: 400; color: white; font-size: 13px;">
          {{ roleUser }}
        </span>
      </span>
      <v-icon large dark class="hidden-md-and-down ml-5 logout" @click="logout()">mdi-logout</v-icon>
    </v-app-bar>
    <v-navigation-drawer v-model="sideNav" app style="background: #00AEEF;" width="285">
      <v-list-item style="background: #00C7F6;">
        <v-list-item-content>
          <v-col justify="center" align="center">
            <v-img class="shrink" :src="require('@/assets/image/LOGO_ClickNGo.svg')" width="130"></v-img>
          </v-col>
          <v-col justify="center" align="center">
            <v-img class="shrink" :src="require('@/assets/image/txt_clickNgo.svg')" width="130"></v-img>
          </v-col>
          <v-col justify="center" align="center">
            <span class="f-card-header" style="color: white;">All your business travel need</span>
          </v-col>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mx-3" style="border: 0.5px solid rgba(255, 255, 255, 0.5);"></v-divider>
      <template v-for="(item, index) in listMenu">
        <v-list-item active-class="menu-active" v-if="(item.title || item.link) && item.link !== 'login' && item.subMenu.length === 0"
          :key="item.title" link dark router :to="item.link" @click="checkLogin(item)">
          <v-list-item-icon>
            <v-img :src="item.icon ? require('@/assets/icons/' + item.icon) : ''"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="f-text" style="font-weight: 500;">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="menu-active" v-if="(item.title || item.link) && item.link === 'login' && item.subMenu.length === 0"
          :key="item.title" link dark @click="checkLogin(item)">
          <v-list-item-icon>
            <v-img :src="item.icon ? require('@/assets/icons/' + item.icon) : ''"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="f-text" style="font-weight: 500;">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group :key="item.title" v-if="item.subMenu.length > 0" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-img :src="item.icon ? require('@/assets/icons/' + item.icon) : ''"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-weight: 500;">
                <span style="color: white;">{{ item.title }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item active-class="menu-active" v-for="child in item.subMenu"
          :key="child.title" link dark :to="child.link" @click="checkLogin(child)">
            <v-list-item-icon>
              <v-img :src="child.icon ? require('@/assets/icons/' + child.icon) : ''"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-weight: 500;">{{ child.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-divider :key="index" v-if="item.title && index < listMenu.length - 1" class="mx-3"
          style="border: 0.5px solid rgba(255, 255, 255, 0.5);"></v-divider>
      </template>
      <br>
      <br>
      <br>
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from 'axios'
import SimpleCrypto from "simple-crypto-js"
export default {
  data() {
    return {
      currentURL: '',
      path: '',
      path_: '',
      isBTT: 0,
      nameUser: "",
      roleUser: "",
      sideNav: null,
      menuBtt: [],
      listMenu: [],
      SimpleCrypto: new SimpleCrypto("aoc")
      // listMenu:[
      //   {
      //     menu:[
      //       { title: "Home", icon: require("@/assets/icons/Home.svg"), link: "/" },
      //     ]
      //   },
      //   {
      //     menu:[
      //       { title: "My Traveler Profile", icon: require("@/assets/icons/Traveler_profile.svg"), link: "MyTravelerProfile" },
      //       { title: "Internal Traveler Profile", icon: require("@/assets/icons/Internal_Traveler_profile.svg"), link: "InternalTravelerProfile" },
      //       { title: "External Traveler Profile", icon: require("@/assets/icons/External_Traveler_profile.svg"), link: "ExternalTravelerProfile" },
      //     ]
      //   },
      //   {
      //     menu:[
      //       { title: "Travel Request", icon: require("@/assets/icons/Travel_request_profile.svg"), link: "travelRequest" },
      //       { title: "Re-Issue Travel Request", icon: require("@/assets/icons/Re_Issue_Travel_request_profile.svg"), link: "" },
      //     ]
      //   },
      //   {
      //     menu:[
      //       { title: "My Tasks", icon: require("@/assets/icons/Worklist.svg"), link: "MyTask" },
      //       { title: "My Tasks (BTT)", icon: require("@/assets/icons/Worklist.svg"), link: "MyTaskBTT" },
      //       { title: "BTT", icon: require("@/assets/icons/BTT.svg"), link: "Btt" },
      //       { title: "Reconcile", icon: require("@/assets/icons/Reconcile.svg"), link: "Reconcile" },
      //       { title: "Reminder", icon: require("@/assets/icons/Reminder.svg"), link: "" },
      //       { title: "Search", icon: require("@/assets/icons/criminal_record.svg"), link: "Search" },
      //     ]
      //   },
      //   {
      //     menu:[
      //       { title: "User Management", icon: require("@/assets/icons/User_Managemennt.svg"), link: "" },
      //     ]
      //   },
      //   {
      //     menu:[
      //       { title: "Activitity Log", icon: require("@/assets/icons/Activity_log.svg"), link: "" },
      //       { title: "Report", icon: require("@/assets/icons/Report.svg"), link: "" },
      //     ]
      //   },
      //   {
      //     menu:[
      //       { title: "Contact", icon: require("@/assets/icons/Contact.svg"), link: "" },
      //     ]
      //   },
      // ],
    }
  },
  methods: {
    getMenuByRole() {
      const PROFILE = JSON.parse(localStorage.PROFILE)
      this.isBTT = PROFILE.IS_BTT
      this.nameUser = `${PROFILE.FIRSTNAME} ${PROFILE.LASTNAME}`
      axios.post(this.$store.state.API_PATH + "Menu/GetMenuByRole", {
        POSITION: PROFILE.POSITION,
        Language: this.$store.state.Language,
        IS_BTT: PROFILE.IS_BTT,
        EMPLOYEE_ID_BTT: PROFILE.EMPLOYEE_ID_BTT,
        EMPLOYEE_ID: PROFILE.EMPLOYEE_ID
      }, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(async (res) => {
        if (res.data.Status) {
          this.listMenu = res.data.Result
          this.callpath()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    logout() {
      localStorage.clear()
      sessionStorage.clear()
      window.location.href = "login"
    },
    callpath(){
      this.currentURL = window.location.href;
      this.path = this.currentURL.substring(this.currentURL.lastIndexOf("/") + 1);
      if(this.path.includes('?')){
        const url = this.path;
        const startIndex = url.lastIndexOf("/");
        const endIndex = url.indexOf("?");
        this.path = url.substring(startIndex + "/".length, endIndex);
      }
      this.path = (this.path === 'createTravelRq' ? "travelRequest" : this.path)
      const configHead = this.listMenu.filter(x=>x.link === this.path)
      if(configHead.length > 0){
        this.$store.commit("buttonPermit/setMenuConfig", configHead[0])
      }
      else{
        const configItem = this.listMenu.map(x=>x.subMenu.filter(y=>y.link === this.path)).filter(x=>x.length > 0)[0]
        this.$store.commit("buttonPermit/setMenuConfig", configItem[0])
      }
    },
    checkLogin(item) {
      if (!localStorage.PROFILE) {
        if (window.location.pathname !== "/login") {
          window.location.href = "login"
        }
      }
      else{
        if(item.link === "login"){
          const PROFILE = JSON.parse(localStorage.PROFILE)
          const paramID = `${PROFILE.EMPLOYEE_ID}|${PROFILE.FIRSTNAME} ${PROFILE.LASTNAME} (${PROFILE.POSITION})`
          const enCode = encodeURIComponent(this.SimpleCrypto.encrypt(paramID))
          setTimeout(()=>{
            window.location = `${window.location.origin}/${item.link}?id=${enCode}`
          },100)
        }
      }
    },
    areLocation(PathName) {
      return PathName === "createTravelRq" || PathName === "searchFlight" || PathName === "tripSummary" || PathName === "agentservice"
        || PathName === "CancelFlights" || PathName === "travelRequestInfo" || PathName === "searchHotel" || PathName === "" ? 1 : 0
    },
    selectedRole(){
      const PROFILE = JSON.parse(localStorage.PROFILE)
      axios.post(this.$store.state.API_PATH + `Menu/GetUserGroup?EMPLOYEE_ID=${PROFILE.EMPLOYEE_ID}`, {}, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(async (res) => {
        if (res.data.Status) {
          this.roleUser = res.data.Result.map((item) => `${item.Name}` ).toString();
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
mounted() {
    if (!localStorage.PROFILE) {
      if (window.location.pathname !== "/login/" && window.location.pathname !== "/login") {
        window.location.href = "login"
      }
    }
    else {
      this.getMenuByRole()
      this.selectedRole()
      if(sessionStorage.isReIssue && sessionStorage.HotelOID){
        this.$store.state.isReIssue = true
        this.$store.state.HotelOID = sessionStorage.HotelOID
      }
    }
  },

}
</script>
<style scoped>
.menu-active {
  background-color: #ABE1FA;
  /* background-color: #A6CE39; */
  color: #1B1464 !important;
  box-shadow: inset 5px 0px 0px #A6CE39;
}

.menu-active:before {
  z-index: -1;
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.logout {
  margin-left: 90px;
  cursor: pointer;
}

.logout-btn:hover {
  color: #00338D;
}
</style>
