<template>
        <v-row>
            <v-col cols="12">
                <searchFlight :flightRequest="flightRequest" :coperateData="coperateData" />
            </v-col>
            <v-col cols="12" md="8">
                <FlightDetail :airline="ticketPricing" :setSession="setSession"
                 :coperateData="coperateData" @checkboxDesMobile="checkboxDesMobile = $event"
                  @checkboxDescription="checkboxDescription = $event" :travelers="travelers"
                    :formContinue="continueBooking" />
            </v-col>
            <v-col cols="12" class="sticky" md="4">
                <v-dialog v-model="mobileDialogAlert" persistent>
                    <ProjectDetails @closeDialog="mobileDialogAlert = $event"
                     :airline="ticketPricing" :setSession="setSession" :coperateData="coperateData"
                      :checkboxDesMobile="checkboxDesMobile"
                       :checkboxDescription="checkboxDescription"
                    @callContinueBooking="continueBooking = $event"
                     :quantityofTr="this.travelers ? this.travelers.filter
                     (item => item.age === '' || item.age === 'Adult')
                     .length.toString() : '0'" />
                </v-dialog>
                <ProjectDetails class="hidden-sm-and-down" :airline="ticketPricing"
                 :setSession="setSession" :coperateData="coperateData"
                  :checkboxDesMobile="checkboxDesMobile"
                   :checkboxDescription="checkboxDescription"
                    @callContinueBooking="continueBooking = $event"
                     :quantityofTr=" this.travelers ? this.travelers
                     .filter(item => item.age === '' || item.age === 'Adult')
                     .length.toString() : '0'" />
            </v-col>
        </v-row>
</template>
<script>
import FlightDetail from '@/components/searchFlight/FlightDetail.vue'
import searchFlight from '@/components/searchFlight/SearchFlight.vue'
import ProjectDetails from '@/components/searchFlight/ProjectDetails.vue'
import axios from 'axios'
import moment from 'moment'
export default {
    data() {
        return {
            checkboxDesMobile: false,
            mobileDialogAlert: false,
            checkboxDescription:{
                chk1: false,
                chk2: false,
            },
            ticketPricing: null,
            setSession: false,
            continueBooking: false,
            travelers: null,
            coperateData: {},
            flightRequest: {}
        }
    },
    components: {
        FlightDetail,
        searchFlight,
        ProjectDetails
    },
    watch:{
        mobileDialogAlert(){
            if(this.mobileDialogAlert){
                this.checkboxDescription.chk1 = true
                this.checkboxDescription.chk2 = true
            }else{
                this.checkboxDescription.chk1 = false
                this.checkboxDescription.chk2 = false
            }
        },
        'checkboxDescription.chk1'(){
            if(this.checkboxDescription.chk1 && this.checkboxDescription.chk2 && this.checkboxDesMobile){
                this.mobileDialogAlert = true
            }
        },
        'checkboxDescription.chk2'(){

            if(this.checkboxDescription.chk1 && this.checkboxDescription.chk2 && this.checkboxDesMobile){
                this.mobileDialogAlert = true
            }
        },
    },
    methods:{
        async totalAge(val){
            const result = ''
            const nowdate = moment().format('YYYY-MM-DD')
            if(val){
                await parseInt(moment(nowdate).diff(moment(val), 'years'))
            }
            return result
        },
        getEarnmile(val){
            return axios.post(`${this.$store.state.API_PATH}EarnMiles/GetEarnMiles?val=${val}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
        },
        async getTravelers(){
            this.ticketPricing = JSON.parse(sessionStorage.ticketPricing)
            this.setSession = await true
            this.coperateData = JSON.parse(sessionStorage.coperateData)
            this.flightRequest = JSON.parse(sessionStorage.searchflightReq)
        }
    },
    async mounted() {
        this.travelers =  JSON.parse(sessionStorage.travelers)
        // sessionStorage.removeItem('ticketPricingModified')
        setTimeout(async () => {
            await this.getTravelers()
        }, 100);
    }
}
</script>
<style scoped>
*{
    scroll-behavior: smooth;
}
.font-W700 {
    font-weight: 700;
}
.card {
    box-shadow: 0px 2px 20px rgba(4, 49, 128, 0.1);
    border-radius: 10px;
}
.font-W400 {
    font-weight: 400;
}
.btn-table {
    background: rgba(0, 174, 239, 0.1) !important;
}
</style>
