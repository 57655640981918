<template>
    <div>
        <v-row class="mb-3">
            <v-col>
                <span>
                    <span class="font-W400" style="font-size: 1em;">Search</span><br>
                    <span class="font-W700" style="font-size: 2em;">Search</span>
                </span>
            </v-col>
        </v-row>
        <v-card elevation="0" title>
            <v-card-title class="font-W500 ">Search</v-card-title>
            <v-card-text>
                <v-card outlined style="background: #F8F9FA;">
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col lg="6" md="6" sm="6">
                                Traveler ID, Name
                                <v-text-field outlined dense placeholder="Traveler ID, Name" background-color="white">
                                    <template v-slot:append>
                                        <v-icon color="#00AEEF">mdi-magnify</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                TR No.
                                <v-text-field outlined dense placeholder="Travel Request Number" background-color="white"></v-text-field>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Budget Holder
                                <v-select outlined dense placeholder="Budget Holder " background-color="white"></v-select>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Status
                                <v-select outlined dense placeholder="Status " background-color="white"></v-select>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Project name
                                <v-select outlined dense placeholder="Project name " background-color="white"></v-select>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Company
                                <v-text-field outlined dense placeholder="Company" background-color="white"></v-text-field>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Dept/Div
                                <v-select outlined dense placeholder="Dept/Div " background-color="white"></v-select>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Country
                                <v-select outlined dense :items="countryItem" v-model="country" background-color="white"></v-select>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                City/Province
                                <v-text-field outlined dense placeholder="City/Province" background-color="white"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Business Date From
                                <v-menu v-model="menuDateFrom" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined dense v-model="dateFrom" label="Business Date From" readonly v-bind="attrs" v-on="on">
                                            <template v-slot:append>
                                                <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" @input="menuDateFrom = false" color="#00AEEF"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Business Date To
                                <v-menu v-model="menuDateTo" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined dense v-model="dateTo" label="Business Date To" readonly v-bind="attrs" v-on="on">
                                            <template v-slot:append>
                                                <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" @input="menuDateTo = false" color="#00AEEF"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Departure On
                                <v-menu v-model="menuDepOn" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined dense v-model="DepOn" label="Departure On" readonly v-bind="attrs" v-on="on">
                                            <template v-slot:append>
                                                <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="DepOn" @input="menuDepOn = false" color="#00AEEF"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Return On
                                <v-menu v-model="menuReturnOn" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined dense v-model="ReturnOn" label="Return On" readonly v-bind="attrs" v-on="on">
                                            <template v-slot:append>
                                                <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="ReturnOn" @input="menuReturnOn = false" color="#00AEEF"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Flight No.
                                <v-text-field outlined dense placeholder="Flight Number" background-color="white"></v-text-field>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Route
                                <v-text-field outlined dense placeholder="Route" background-color="white"></v-text-field>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-2">
                                Business Travel Type
                                <v-select outlined dense placeholder="Business Travel Type " background-color="white"></v-select>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="mt-6 text-lg-right text-sm-right text-center">
                                <v-btn color="primary" width="150" @click="ResultRequest">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>

export default {
    data(){
        return{
            DepOn: "",
            ReturnOn: "",
            dateFrom: "",
            dateTo: "",
            menuDepOn: false,
            menuReturnOn: false,
            menuDateFrom: false,
            menuDateTo: false,
            countryItem: [
                { text: "-- Select All --", value: "" },
            ],
            country: ""
        }
    },

    methods: {
        ResultRequest(){
            location.href= "#/SearchFlight";
        }
    }
}
</script>

<style scoped>
    .font-W700{
        font-weight: 700;
    }
    .font-W500{
        font-weight: 500;
    }
    .font-W400{
        font-weight: 400;
    }
</style>
