<template>
  <v-row>
    <v-col cols="12" id="search">
      <h3>
        <span style="font-size: 0.8rem"><a href="/travelRequest" style="color: grey">Travel Request</a> / Create
          Travel Request /
          <span style="text-decoration: underline;">
            Search Flights
          </span>
        </span>
        <br />
        <span>
            <span class="font-W700" style="font-size: 1em;">Search Flight</span>
        </span>
        <v-btn style="float: right" @click="backtopage">Back</v-btn>
      </h3>
    </v-col>
    <template v-if="!YourFlight">
      <v-col cols="12">
        <searchFlight :flightRequest="flightRequest" :coperateData="coperateData" />
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-row>
          <v-col cols="12">
            <yourFlight :depflight="depflight" class="card" :flightRequest="flightRequest" :returnflight="returnflight"
              @returnflight="returnflight = $event" :selectflight="selectflight" @changeval="selectflight = $event" />
          </v-col>
          <v-col cols="12">
            <v-card class="card overflow-x-auto hidden-sm-and-down">
                  <v-card-text >
                    Seat Class
                    <v-radio-group dense v-model="svcClass" hide-details :disabled="ailneDataloading">
                        <v-radio key="Economy" label="Economy" v-if="flightRequest && (
                          flightRequest.svcClass === 'Y' || flightRequest.svcClass === 'F' || flightRequest.svcClass === 'C')"
                           value="Y" class="theme-background"></v-radio>
                        <div style="height: 2px;"></div>
                        <v-radio key="Business" label="Business" v-if="flightRequest && (
                          flightRequest.svcClass === 'C' || flightRequest.svcClass === 'F')"
                           value="C" class="theme-background"></v-radio>
                        <div style="height: 2px;"></div>
                        <v-radio key="First" label="First" v-if="flightRequest && (
                          flightRequest.svcClass === 'F')" value="F" class="theme-background"></v-radio>
                      </v-radio-group>
                  </v-card-text>
            </v-card>
              <br />
              <v-card class="card overflow-x-auto hidden-sm-and-down" style="height: 800px;">
                <v-card-text>
                  <sfilter :carries="carries" @flightTime="flightTime = $event"
                   @selectedCarries="selectedCarries = $event" @Tramsit="Tramsit = $event"
                    @showYourFlight="YourFlight = $event" />
                </v-card-text>
              </v-card>
            <v-dialog v-model="filterDialog">
                <v-card>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="filterDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card>
              <sfilter :carries="carries" @flightTime="flightTime = $event" :flightRequest="flightRequest"
               @selectedCarries="selectedCarries = $event" @Tramsit="Tramsit = $event"
               @showYourFlight="YourFlight = $event" />
            </v-dialog>
            <v-card elevation="0" class="hidden-sm-and-up card">
              <v-card-title>
                Filter
                <v-spacer></v-spacer>
                <v-btn @click="showfilterDialog()" class="btn-table">
                  <span class="font-color">
                    Filter
                  </span>
                  <img class="ml-2" :src="require('@/assets/icons/filter-results-button.svg')" width="20" alt="" />
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="9" sm="12">
            <airline @carries="carries = $event" @ailneDataloading="ailneDataloading = $event"
              :flightTime="flightTime" :selectedCarries="selectedCarries"
              :Tramsit="Tramsit" :svcClass="svcClass !== null ? svcClass : ''" @svcClass="svcClass = $event"
              @depflight="depflight = $event" :flightRequest="flightRequest"
              @returnflight="returnflight = $event" @selectflight="selectflight = $event"
             :selectflight="selectflight" />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import yourFlight from '@/components/searchFlight/YourFlight'
import airline from '../components/searchFlight/Airline'
import searchFlight from '../components/searchFlight/SearchFlight'
import sfilter from '../components/searchFlight/Filter'
import flightDetail from '../components/searchFlight/FlightDetail'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'SearchFlight',
  data() {
    return {
      ailneDataloading: true,
      svcClass: null,
      formContinue: false,
      YourFlight: false,
      depflight: null,
      filterDialog: false,
      returnflight: null,
      selectflight: 1,
      flightRequest: null,
      coperateData: null,
      selectedCarries: [],
      Tramsit: null,
      flightTime: {},
      carries: {}
    }
  },
  components: {
    sfilter,
    airline,
    searchFlight,
    // projectDetail,
    flightDetail,
    yourFlight
  },
  methods: {
    showfilterDialog(){
      this.filterDialog = !this.filterDialog
    },
    backtopage() {
      location.href = 'createTravelRq'
    }
  },
  watch: {
    flightRequest() {
    }
  },
  computed: {

  },
  mounted() {
    this.flightRequest = JSON.parse(sessionStorage.searchflightReq)
    this.coperateData = JSON.parse(sessionStorage.coperateData)
    if(sessionStorage.svcClass !== undefined){
      this.svcClass = sessionStorage.svcClass
    }else{
      this.svcClass = this.flightRequest.svcClass
      sessionStorage.svcClass = this.svcClass
    }
  }
}
</script>
<style>
.btn-table {
    background: rgba(0, 174, 239, 0.1) !important;
}
.font-W700 {
    font-weight: 700;
}
.font-W400 {
    font-weight: 400;
}
.font-color{
  color: #00AEEF;
}
</style>
