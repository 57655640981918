import axios from "axios";
import store from "../index";

export default {
  namespaced: true,
  state: {
    menuConfig: {
      Add: 0,
      All: 0,
      Approve: 0,
      Delete: 0,
      ReIssue: 0,
      Revise: 0,
      Update: 0,
      View: 0,
    },
  },
  mutations: {
    setMenuConfig(state, param){
      state.menuConfig.Add = param.Add
      state.menuConfig.All = param.All
      state.menuConfig.Approve = param.Approve
      state.menuConfig.Delete = param.Delete
      state.menuConfig.ReIssue = param.ReIssue
      state.menuConfig.Revise = param.Revise
      state.menuConfig.Update = param.Update
      state.menuConfig.View = param.View
    },
    async getButtonPermit(state, param) {
      store.state.LOADING = true;
      setTimeout(() => {
        axios
          .post(
            `${store.state.API_PATH}ButtonPermit/GetButtonPermit`,
            {
              Path: param.Path,
            },
            {
              headers: { Authorization: `Basic ${store.state.authenApi}` },
            }
          )
          .then((res) => {
            store.state.LOADING = false;
            if (res.data.Status) {
              const [item] = res.data.Result;
              state.menuConfig.Add = item.Add;
              state.menuConfig.All = item.All;
              state.menuConfig.Approve = item.Approve;
              state.menuConfig.Delete = item.Delete;
              state.menuConfig.ReIssue = item.ReIssue;
              state.menuConfig.Revise = item.Revise;
              state.menuConfig.Update = item.Update;
              state.menuConfig.View = item.View;
              state.menuConfig.icon = item.icon;
              state.menuConfig.link = item.link;
              state.menuConfig.title = item.title;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    },
  },
};
