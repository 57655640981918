<template>
    <div>
        <v-row v-if="!$store.state.showHotelTripSum">
            <v-col>
                <img :src="require('@/assets/icons/hotel.svg')" width="30" alt="" />
                <div>
                    <span class="font-W700" style="font-size: 20px;">
                        Search Hotel
                    </span>
                    <v-btn style="float: right" v-if="$store.state.showHotelInfo" @click="$store.state.showHotelInfo = false">back</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="$store.state.showHotelTripSum">
            <v-col>
                <span class="font-W700" style="font-size: 20px;">
                    Trip Summary
                </span>
            </v-col>
        </v-row>
        <v-row v-if="!$store.state.showHotelTripSum">
            <v-col>
                <v-card elevation="0" class="card">
                    <v-card-text>
                        <v-row align="center">
                            <v-col cols="6" lg="5" md="5">
                                <v-row>
                                    <v-col>
                                        <span style="color: black;"><strong>{{ FilterHotel.Location }}</strong></span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <span>{{ FilterHotel.DescSearch }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" lg="4" md="5">
                                <v-row>
                                    <v-col>
                                        <span><strong>{{ FilterHotel.TRNoText }}</strong></span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <span>{{ FilterHotel.BusDateText }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" lg="3" md="2" class="text-right">
                                <v-btn small color="primary" @click="changeHotel()">Change Hotel</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="$store.state.showHotelTripSum">
            <v-col>
                <v-card elevation="0" class="card">
                    <v-card-text>
                        <v-row align="center">
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <span style="color: black;"><strong>{{ FilterHotel.Location }}</strong></span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <span>{{ FilterHotel.DescSearch }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="!$store.state.showHotelInfo && !$store.state.showHotelTripSum">
                <selecthotel :FilterHotel="FilterHotel" />
            </v-col>
            <v-col cols="12" v-if="$store.state.showHotelInfo">
                <selectroom />
            </v-col>
            <v-col cols="12" v-if="$store.state.showHotelTripSum">
                <hotelDetail  />
            </v-col>
        </v-row>
        <v-dialog v-model="showHotel" persistent>
            <v-card>
                <v-card-title>
                    Hotel Booking
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-tabs v-model="tabHotel">
                                <v-tab>
                                    <img :src="require('@/assets/icons/hotel_online.svg')" alt="" />
                                    <span class="ml-2">Search Hotel (Online Booking)</span>
                                </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tabHotel">
                                <!-- Online Start -->
                                <v-tab-item>
                                    <v-card elevation="0" class="bgcard mt-3">
                                        <v-card-text>
                                            <v-row>
                                                <v-col>
                                                    <span>
                                                        <strong>Search Hotel</strong>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-2">
                                                <v-col cols="12" lg="6" md="6" sm="6">
                                                    <span>Country
                                                        <span style="color: red;">*</span>
                                                    </span>
                                                    <v-autocomplete outlined dense :items="CountryItem"
                                                    ref="Country" :rules="rule.Country" @change="getCityCode()"
                                                    @input.native="getCountryCode($event.srcElement.value)"
                                                    placeholder="Country" v-model="hotelObj.Country" item-text="Name"
                                                    item-value="Code"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" lg="6" md="6" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                                    <span>City or Province
                                                        <span style="color: red;">*</span>
                                                    </span>
                                                    <v-autocomplete outlined dense :items="CityorProvinceItem"
                                                    ref="CityorProvince" :rules="rule.CityorProvince" item-text="text" item-value="Code"
                                                    placeholder="City or Province" v-model="hotelObj.CityorProvince"></v-autocomplete>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" lg="6" md="6" sm="6">
                                                    <span>Business Start Date</span>
                                                    <v-text-field outlined dense :value="formatDateLL(BusinessStartDate)" disabled></v-text-field>
                                                </v-col>
                                                <v-col cols="12" lg="6" md="6" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                                    <span>Business End Date</span>
                                                    <v-text-field outlined dense :value="formatDateLL(BusinessEndDate)" disabled></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" lg="6" md="6" sm="6">
                                                    <span>Check In
                                                        <span style="color: red;">*</span>
                                                    </span>
                                                    <v-menu v-model="menuCheckinDateStart" :close-on-content-click="false" :nudge-right="40"
                                                    transition="scale-transition" offset-y min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field outlined dense :value="formatDateLL(hotelObj.CheckinDateStart)"
                                                            readonly v-bind="attrs" v-on="on" :rules="rule.checkinDateStart">
                                                                <template v-slot:append>
                                                                    <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                                </template>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="hotelObj.CheckinDateStart" @input="menuCheckinDateStart = false" color="#00AEEF"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="12" lg="6" md="6" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                                    <span>Check Out
                                                        <span style="color: red;">*</span>
                                                    </span>
                                                    <v-menu v-model="menuCheckinDateEnd" :close-on-content-click="false"
                                                    :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field outlined dense :value="formatDateLL(hotelObj.CheckinDateEnd)" readonly v-bind="attrs" v-on="on">
                                                                <template v-slot:append>
                                                                    <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                                </template>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="hotelObj.CheckinDateEnd"
                                                         @input="menuCheckinDateEnd = false"
                                                          color="#00AEEF" :min="minDate"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" lg="4" md="4" sm="6">
                                                    <span>Night</span>
                                                    <v-text-field outlined dense disabled v-model="sumNight" type="number"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <span><strong>Traveler</strong></span>
                                                    <v-row align="center" justify="center">
                                                        <v-col cols="12">
                                                            <v-card elevation="0" class="card-radius5px">
                                                                <v-card-text>
                                                                    <v-data-table hide-default-footer :items="SelectedItem" checkbox-color="primary"
                                                                        item-key="fullname" v-model="Standbytable"
                                                                        :headers="header" mobile-breakpoint>
                                                                        <template v-slot:[`item.no`]="{ index }">
                                                                            {{ index + 1 }}
                                                                        </template>
                                                                    </v-data-table>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <!-- Online End -->
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-row class="mt-1 mb-1 text-center">
                        <v-col>
                            <v-btn outlined color="primary" width="120" class="mr-1" @click="closeSearchHotelInfo()">Cancel</v-btn>
                            <v-btn color="primary" width="120" class="ml-1"
                            @click="tabHotel === 0 ? gotoSearchHotelOnline() : saveHotelOffline()">Continue</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import selectroom from '../components/searchhotel/selectroom'
import selecthotel from '../components/searchhotel/selecthotel'
import hotelDetail from '../components/searchhotel/HotelDetail'
import UserProfile from '@/components/UserProfile.vue'
import SimpleCrypto from "simple-crypto-js"
import axios from 'axios'
import moment from 'moment'
export default {
    components: {
        selectroom,
        hotelDetail,
        // projectDetail,
        selecthotel,
        UserProfile
    },
    data() {
        return{
            BusinessStartDate: "",
            BusinessEndDate: "",
            rule: {
                ReqServ: [v => !!v || 'Service requirement is required'],
                checkinDateStart: [v => !!v || 'Check in is required'],
                Country: [v => !!v || 'Country is required'],
                CityorProvince: [v => !!v || 'City or province is required'],
                SelectHotel: [v => !!v || 'Select hotel is required'],
                HotelName: [v => !!v || 'Hotel name is required'],
                RoomType: [v => !!v || 'Room type is required'],
                NumberOfRoom: [v => !!v || 'Number of room is required'],
                Occupancy: [v => !!v || 'Occupancy is required'],
                BedType: [v => !!v || 'Bed type is required'],
                PricePerNight: [v => !!v || 'Price per night is required'],
                Amount: [v => !!v || 'Amount is required'],
                Currency: [v => !!v || 'Currency is required'],
                Status: [v => !!v || 'Status is required'],
            },
            hotelObj: {
                HTNo: "",
                ReqServ: "",
                ReqServOptnal: "",
                CheckinDateStart: this.BusinessStartDate,
                CheckinDateEnd: this.BusinessEndDate,
                Country: "",
                CityorProvince: "",
                SelectHotel: "",
                HotelName: "",
                RoomType: "",
                NumberOfRoom: 1,
                Occupancy: 1,
                BedType: "",
                PricePerNight: 0,
                Amount: 0,
                Currency: "THB",
                Status: "USED",
                BookingType: "",
                Rating: 0
            },
            header: [
                { text: 'No.', value: 'no', },
                { text: 'Traveler On Mission', value: 'fullname', },
                { text: 'Type', value: 'Type', }
            ],
            showHotel: false,
            tabHotel: 0,
            RoomTypeItem: [],
            SelectedItem: [],
            StandbyItem: [],
            Selectedtable: [],
            Standbytable: [],
            StatusItem: ["USED"],
            CurrencyItem: ["THB"],
            OccupancyItem: [],
            CountryItem: [],
            CityorProvinceItem: [],
            HotelNameItem: [],
            menuCheckinDateStart: false,
            menuCheckinDateEnd: false,
            FilterHotel: {},
            YourFlight: false,
            SimpleCrypto: new SimpleCrypto("aoc")
        }
    },
    methods:{
        changeHotel(){
            this.showHotel = true
            this.getCityCode()
        },
        async gotoSearchHotelOnline(){
            const requestInformation = JSON.parse(sessionStorage.coperateData)
            if(!this.$refs.Country.validate() || !this.$refs.CityorProvince.validate()){
                if(!this.$refs.Country.validate()){
                    this.$refs.Country.focus()
                }
                else{
                    this.$refs.CityorProvince.focus()
                }
            }
            else{
                const CountriesText =  this.CountryItem.filter(e=>e.Code === this.hotelObj.Country)[0].Name
                const CityorProvinceText =  this.$store.state.Language === "en" ?
                    this.CityorProvinceItem.filter(e=>e.Code === this.hotelObj.CityorProvince)[0].name_en :
                    this.CityorProvinceItem.filter(e=>e.Code === this.hotelObj.CityorProvince)[0].name_th
                const dateString = `${this.checkInDateFormat(this.hotelObj.CheckinDateStart)} - ${this.checkInDateFormat(this.hotelObj.CheckinDateEnd)}`
                const BusDate = `${this.formatDateLL(this.BusinessStartDate)} - ${this.formatDateLL(this.BusinessEndDate)}`
                const newArray = []
                for(const item of this.SelectedItem){
                    await new Promise((resolve)=>{
                        newArray.push({
                            TravelCardOID: item.TravelCardOID,
                            TravelRequestOID: item.TravelRequestOID,
                            TravelerOID: item.TravelerOID,
                            emp_ID: item.emp_ID,
                            fullname: item.fullname,
                            email: item.email,
                            firstname: item.firstname,
                            title: item.title,
                            mobile: item.mobile,
                            position: item.position,
                            dep: item.dep,
                            div: item.div,
                            lastname: item.lastname
                        })
                        resolve()
                    })
                }
                this.FilterHotel = {
                    Country: this.hotelObj.Country,
                    CityorProvince: this.hotelObj.CityorProvince,
                    CheckinDateStart: this.hotelObj.CheckinDateStart,
                    CheckinDateEnd: this.hotelObj.CheckinDateEnd,
                    Occupancy: this.SelectedItem.length,
                    Location: `${CountriesText}, ${CityorProvinceText}`,
                    DescSearch: `${dateString} | ${this.sumNight} nights | ${this.hotelObj.NumberOfRoom} Room, ${this.SelectedItem.length} Guest`,
                    TRNoText: `TR No. ${requestInformation.TRNo}`,
                    BusDateText: `(Business Start -End: ${BusDate})`,
                    Night: this.sumNight,
                    BusinessStartDate: this.BusinessStartDate,
                    BusinessEndDate: this.BusinessEndDate,
                    Traveler: newArray
                }
                this.$store.state.showHotelInfo = false
                this.showHotel = false
                window.location = `/searchHotel?ID=${encodeURIComponent(this.SimpleCrypto.encrypt(this.FilterHotel))}`
            }
        },
        getCityCode(){
            this.CityorProvinceItem = []
            this.HotelNameItem = []
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}CityCode/${this.hotelObj.Country === 'TH' ? 'GetProvince' : 'GetCityCodeByCountryCode'}`, {
                CountryCode: this.hotelObj.Country,
                Language: this.$store.state.Language,
                Country: this.hotelObj.Country
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then((res)=>{
                if (res.data.Status) {
                    res.data.Result.forEach((elm) => {
                        if (this.hotelObj.Country === 'TH') {
                            const name = elm[`name_${this.$store.state.Language}`]
                            this.CityorProvinceItem.push({ ...elm, Code: elm.code, text: `${name} (${elm.code})` })
                        } else {
                            this.CityorProvinceItem.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                        }
                    })
                    this.$store.state.LOADING = false
                }
            },err=>{
                this.$store.state.LOADING = false
            })
        },
        getCountryCode(input) {
            if (input) {
                const checkItem = this.CountryItem.filter((el) => el.text.toLowerCase().substr(0, input.length) === input.toLowerCase());
                if (checkItem.length === 0) {
                    axios.post(`${this.$store.state.API_PATH}CountryCode/GetCountryCode`, {
                        Code: input,
                        Language: this.$store.state.Language,
                    }, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    })
                    .then((res) => {
                        if (res.data.Status) {
                            this.CountryItem = []
                            res.data.Result.forEach((elm) => {
                                this.CountryItem.push({
                                    ...elm,
                                    text: `${elm.Name} (${elm.Code})`,
                                })
                            })
                        }
                    })
                    .catch((err) => err);
                }
            }
        },
        closeSearchHotelInfo(){
            this.showHotel = false
            this.hotelObj = {
                HTNo: "",
                ReqServ: "",
                ReqServOptnal: "",
                CheckinDateStart: this.BusinessStartDate,
                CheckinDateEnd: this.BusinessEndDate,
                Country: "",
                CityorProvince: "",
                SelectHotel: "",
                HotelName: "",
                RoomType: "",
                NumberOfRoom: 1,
                Occupancy: 1,
                BedType: "",
                PricePerNight: 0,
                Amount: 0,
                Currency: "THB",
                Status: "USED",
                BookingType: 0,
                Rating: 0
            }
        },
        formatDateLL(date){
            return moment(date).format("ll")
        },
        checkInDateFormat(date){
            return moment(date).format("ddd, DD MMM YYYY")
        },
        minDate(){
            return new Date(new Date().setDate(new Date(this.hotelObj.CheckinDateStart).getDate() + 1)).toISOString().substr(0, 10)
        },
    },
    computed:{
        sumNight(){
            return moment(this.hotelObj.CheckinDateEnd).diff(moment(this.hotelObj.CheckinDateStart), 'days')
        },
    },
    created(){
        if(this.$route.query.ID){
            const data = this.SimpleCrypto.decrypt(decodeURIComponent(this.$route.query.ID))
            this.FilterHotel = data
            this.BusinessStartDate = data.BusinessStartDate
            this.BusinessEndDate = data.BusinessEndDate
            this.hotelObj.CheckinDateStart = data.CheckinDateStart
            this.hotelObj.CheckinDateEnd = data.CheckinDateEnd
            this.SelectedItem = this.SimpleCrypto.decrypt(sessionStorage.SelectedItemHotel)
            if(sessionStorage.objHTL){
                this.$store.state.objHTL = JSON.parse(sessionStorage.objHTL)
                this.$store.state.showHotelInfo = true
            }
            if(sessionStorage.YourHotel){
                this.$store.state.showHotelInfo = false
                this.$store.state.showHotelTripSum = true
            }
        }
    }
}
</script>
