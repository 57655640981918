<template>
  <div>
    <v-row class="my-4 mx-4" no-gutters>
      <v-col>
        <span>
          <span class="font-W700" style="font-size: 1em">Internal Traveler Profile</span>
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="0" class="card" title>
          <v-card-text>
            <v-row no-gutters align="center">
              <v-col cols="12">
                <span style="color: #747678"> Data as of {{ dataUpdate }} </span>
              </v-col>
              <v-col cols="4" class="">
                <br />
                <v-text-field append-icon="mdi-magnify" @input.native="getDataInternalTR($event.srcElement.value)" outlined
                  dense placeholder="Type for Search" background-color="white">
                </v-text-field>
              </v-col>
              <v-col class="text-lg-right text-sm-right text-center hidden-xs-only hidden-sm-only">
                <v-btn class="btn-table mr-2" elevation="0">
                  <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1"></v-img>
                  Reset
                </v-btn>
              </v-col>
            </v-row>
            <v-card elevation="0">
              <v-data-table :headers="headersInternal" :items="dataInfo.itemInternal" class="elevation-1"
                item-key="No" @click:row="rowClicked" mobile-breakpoint style="white-space: nowrap" hide-default-footer
                height="40vh" @page-count="pageCount = $event" :page.sync="page" :items-per-page="itemPage"
                @load="onPagination($event)">
                <template v-slot:[`item.actions`]="{ item }">
                  <v-img style="cursor: pointer" :src="require('@/assets/icons/Source_File.svg')"
                    @click="getReturnDetail(item)"></v-img>
                </template>
                <template v-slot:[`item.No`]="{ index }">
                  {{ index + 1 }}
                </template>
              </v-data-table>
              <v-dialog v-model="dataInfo.showModal" v-if="dataInfo.showModal" width="1500px">
                <v-card>
                  <v-card-title>
                  </v-card-title>
                  <v-card-text>
                    <!-- Start Modal content goes here -->
                    <template>
                      <div>
                        <v-flex class="mb-3 font-W700"> Travel Profile </v-flex>
                        <v-card elevation="0" title class="card" no-gutters>
                          <v-card-text>
                            <v-card elevation="0" color="#F8F9FA">
                              <v-card-title>
                                <strong> Company Data </strong>
                              </v-card-title>
                              <v-card-text>
                                <v-row no-gutters>
                                  <v-col cols="12">
                                    <strong>Employee ID</strong>
                                    <br />
                                    <span class="text-profile">{{ dataInfo.EMPLOYEE_ID }}</span>
                                  </v-col>

                                  <div class="text-height"></div>

                                  <v-col cols="12" xs="6" md="2" sm="2">
                                    <strong>Thai Title</strong>
                                    <br />
                                    <span class="text-profile">{{ dataInfo.TITLE_TH }}</span>
                                  </v-col>

                                  <v-col cols="12" xs="6" md="3" sm="3">
                                    <strong>Thai Name</strong>
                                    <br />
                                    <span class="text-profile">{{ dataInfo.THAINAME }}</span>
                                  </v-col>

                                  <v-col cols="12" xs="6" md="7" sm="6">
                                    <strong>Thai Position</strong>
                                    <br />
                                    <span class="text-profile">{{ dataInfo.POSITIONTHAI }}</span>
                                  </v-col>

                                  <div class="text-height"></div>

                                  <v-col cols="12" xs="6" md="2" sm="2">
                                    <strong>English Title</strong>
                                    <br />
                                    <span class="text-profile">{{ dataInfo.TITLE }}</span>
                                  </v-col>

                                  <v-col cols="12" xs="6" md="3" sm="3">
                                    <strong>English Name</strong>
                                    <br />
                                    <span class="text-profile">{{ dataInfo.FIRSTNAME + " " + dataInfo.LASTNAME }}</span>
                                  </v-col>

                                  <v-col cols="12" xs="6" md="7" sm="6">
                                    <strong>English Position</strong>
                                    <br />
                                    <span class="text-profile">{{ dataInfo.POSITION }}</span>
                                  </v-col>

                                  <div class="text-height"></div>

                                  <v-col cols="12" xs="5" md="5" sm="5">
                                    <strong>Email</strong>
                                    <br />
                                    <span class="text-profile">{{ dataInfo.EMAIL_ID }}</span>
                                  </v-col>

                                  <v-col cols="12" xs="7" md="7" sm="7">
                                    <strong>Company</strong>
                                    <br />
                                    <span class="text-profile">PTT exploration and production Public Company
                                      Limited</span>
                                  </v-col>

                                  <div class="text-height"></div>

                                  <v-col cols="12" xs="5" md="5" sm="5">
                                    <strong>Department</strong>
                                    <br />
                                    <span class="text-profile">{{ dataInfo.DEPARTMENT_ID ? dataInfo.DEPARTMENT_ID : "-"
                                    }}</span>
                                  </v-col>

                                  <v-col cols="12" xs="7" md="7" sm="7">
                                    <strong>Division</strong>
                                    <br />
                                    <span class="text-profile">{{ DIVISION_IDByInternal ? DIVISION_IDByInternal : "-"
                                    }}</span>
                                  </v-col>

                                  <div class="text-height"></div>

                                  <v-col cols="12" xs="6" md="2" sm="2">
                                    <strong>Joined Date</strong>
                                    <br />
                                    <span class="text-profile">{{ dataInfo.JOIN_DATE }}</span>
                                  </v-col>

                                  <v-col cols="12" xs="6" md="3" sm="3">
                                    <strong>Residence</strong>
                                    <br />
                                    <span class="text-profile">-</span>
                                  </v-col>

                                  <v-col cols="12" xs="6" md="7" sm="6">
                                    <strong>Office Phone No.</strong>
                                    <br />
                                    <span class="text-profile">{{ dataInfo.DIRECT_PHONE ? dataInfo.DIRECT_PHONE : "-"
                                    }}</span>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                            <br />
                            <v-card elevation="0" color="#F8F9FA">
                              <v-card-title> Mobile Phone Number </v-card-title>

                              <v-card-text>
                                Country Code / Mobile No.
                                <div>
                                  <v-row>
                                    <v-col cols="12" md="2" sm="2" xs="2">
                                      <v-select :items="dataInfo.countryCode" v-model="MOBILE_CODE" dense outlined
                                        background-color="white"> </v-select>
                                    </v-col>
                                    <v-col cols="12" md="5" sm="5" xs="5">
                                      <input type="text" class="my-text-field phone-number" v-model="MOBILE_NO"
                                        @input="MOBILE_NO = validateNumberText(MOBILE_NO)" background-color="white" />
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-card-text>

                          <v-card-title> Personal Data </v-card-title>

                          <v-card-text>
                            <v-card elevation="0" color="#F8F9FA">
                              <v-card-title> Earn Miles </v-card-title>
                              <template v-if="dataInfo.EarnMiles != null">
                                <v-card-text>
                                  <v-row>
                                    <v-col cols="12">
                                      <span> R.O.P </span>
                                      <span style="float: right"> Thai Airways </span>
                                      <v-text-field outlined dense background-color="white"
                                        v-model="dataInfo.EarnMiles.filter((x) => x.Name === 'Thai Airways' && x.IsActive === 1)[0].MemberID">
                                      </v-text-field>
                                      <span> Nok Fanclub </span>
                                      <span style="float: right"> Nok Air </span>
                                      <v-text-field outlined dense background-color="white"
                                        v-model="dataInfo.EarnMiles.filter((x) => x.Name === 'Nok Air' && x.IsActive === 1)[0].MemberID">
                                      </v-text-field>
                                      <span> Big Membership </span>
                                      <span style="float: right"> Air Asia </span>
                                      <v-text-field outlined dense background-color="white"
                                        v-model="dataInfo.EarnMiles.filter((x) => x.Name === 'Air Asia' && x.IsActive === 1)[0].MemberID">
                                      </v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-card-title> Other Miles </v-card-title>
                                <v-card-text>
                                  <table style="width: 100%" aria-label="">
                                    <tr hidden>
                                      <th></th>
                                    </tr>
                                    <tr v-for="(itemOM, index) in dataInfo.EarnMiles.filter((x) => x.IsActive !== 1)"
                                      :key="index">
                                      <td style="width: 50px">No. {{ index + 1 }}</td>
                                      <td>
                                        Airline
                                        <v-autocomplete v-model="itemOM.Name" @change="SetAirlineByKey(itemOM.Name)"
                                          background-color="white"
                                          @input.native="GetAirlineByKey($event.srcElement.value)"
                                          :items="AirlineByInternal" item-text="Name" item-value="Name" dense
                                          outlined></v-autocomplete>
                                      </td>
                                      <td>
                                        Member ID
                                        <v-text-field outlined dense v-model="itemOM.MemberID" background-color="white">
                                        </v-text-field>
                                      </td>
                                      <td style="width: 30px">
                                        <v-btn color="red" x-small fab @click="RemoveOthmiles(itemOM)">
                                          <v-img :src="require('../assets/icons/Cancel.svg')" width="2" max-width="12">
                                          </v-img>
                                        </v-btn>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="4">
                                        <v-btn depressed @click="AddOthMiles()" v-if="$store.state.buttonPermit.menuConfig.Add">
                                          <v-img class="shrink mr-2" :src="require('../assets/icons/Add.svg')"
                                            width="22" />
                                          Add Mileage
                                        </v-btn>
                                      </td>
                                    </tr>
                                  </table>
                                </v-card-text>
                              </template>
                            </v-card>
                            <br />
                            <v-card elevation="0" color="#F8F9FA">
                              <v-card-title> Attached Files </v-card-title>
                              <v-card-text>
                                <v-btn class="btn-primary" @click="InputFile"> Upload File(s) </v-btn>
                                <div class="h-space"></div>
                                <input type="file" counter multiple hide-details="" ref="input_file" style="display: none"
                                  outlined show-size background-color="white"
                                  accept="image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, .pdf,
                                                                    application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  @change="AddAttachFile($event)" />
                                <br />
                                <v-row>
                                  <template v-if="filesing.length > 0">
                                    <v-col cols="3" v-for="(fileitem, index) in filesing" :key="'n' + index">
                                      <v-card class="card">
                                        <v-card-text class="bgcard">
                                          <v-row>
                                            <v-col cols="2">
                                              <v-icon v-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'xlsx' ||
                                                fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'xls'
                                                " class="mt-3" color="success">mdi-file-excel</v-icon>
                                              <v-icon
                                                v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'png'"
                                                class="mt-3" color="error">mdi-file-png-box</v-icon>
                                              <v-icon
                                                v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'pdf'"
                                                class="mt-3" color="error">mdi-file-pdf-box</v-icon>
                                              <v-icon
                                                v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'jpg'"
                                                class="mt-3" color="error">mdi-file-jpg-box</v-icon>
                                              <v-icon
                                                v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'zip'"
                                                class="mt-3" color="#EAC282">mdi-zip-box</v-icon>
                                              <v-icon
                                                v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'rar'"
                                                class="mt-3" color="#EAC282">mdi-bookmark-box-multiple</v-icon>
                                              <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'docx' ||
                                                fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'doc'
                                                " class="mt-3" color="primary">mdi-file-word</v-icon>
                                              <v-icon
                                                v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'xml'"
                                                class="mt-3" color="primary">mdi-xml</v-icon>
                                              <v-icon
                                                v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'txt'"
                                                class="mt-3" color="#E3751C">mdi-file-document</v-icon>
                                              <v-icon
                                                v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'svg'"
                                                class="mt-3" color="#E3751C">mdi-svg</v-icon>
                                              <v-icon v-else class="mt-3">mdi-file-outline</v-icon>
                                            </v-col>
                                            <v-col cols="10" style="height: 55px">
                                              <v-icon style="float: right; cursor: pointer"
                                                @click="removeFilesUserfile(fileitem, 'NotSave')">mdi-close</v-icon>
                                              <div class="mt-3">
                                                {{
                                                  fileitem.name.length > 15
                                                  ? fileitem.name.split(".")[0].slice(0, 10) + "..." +
                                                  fileitem.name.split(".")[fileitem.name.split(".").length - 1]
                                                  : fileitem.name
                                                }}
                                              </div>
                                            </v-col>
                                          </v-row>
                                        </v-card-text>
                                      </v-card>
                                    </v-col>
                                  </template>
                                  <template v-if="Getfilesing.length > 0">
                                    <v-col cols="3" v-for="(fileitem, index1) in Getfilesing" :key="'o' + index1">
                                      <v-card class="card">
                                        <v-card-text class="bgcard">
                                          <v-row>
                                            <v-col cols="2" @click="openFileUserfile(fileitem)" style="cursor: pointer">
                                              <v-icon
                                                v-if="fileitem.FileName.split('.')[1] === 'xlsx' || fileitem.FileName.split('.')[1] === 'xls'"
                                                class="mt-3" color="success">mdi-file-excel</v-icon>
                                              <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'png'" class="mt-3"
                                                color="error">mdi-file-png-box</v-icon>
                                              <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'pdf'" class="mt-3"
                                                color="error">mdi-file-pdf-box</v-icon>
                                              <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'jpg'" class="mt-3"
                                                color="error"> mdi-file-jpg-box</v-icon>
                                              <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'zip'" class="mt-3"
                                                color="#EAC282">mdi-zip-box</v-icon>
                                              <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'rar'" class="mt-3"
                                                color="#EAC282"> mdi-bookmark-box-multiple</v-icon>
                                              <v-icon
                                                v-else-if="fileitem.FileName.split('.')[1] === 'docx' || fileitem.FileName.split('.')[1] === 'doc'"
                                                class="mt-3" color="primary">
                                                mdi-file-word</v-icon>
                                              <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'xml'" class="mt-3"
                                                color="primary">mdi-xml</v-icon>
                                              <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'txt'" class="mt-3"
                                                color="#E3751C"> mdi-file-document</v-icon>
                                              <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'svg'" class="mt-3"
                                                color="#E3751C">mdi-svg</v-icon>
                                              <v-icon v-else class="mt-3">mdi-file-outline</v-icon>
                                            </v-col>
                                            <v-col cols="10" style="height: 55px">
                                              <v-icon style="float: right; cursor: pointer"
                                                @click="(DialogRemoveByInternal = true), remove('PassportFile', 'alert', fileitem)">mdi-close</v-icon>
                                              <div class="mt-3" @click="openFileUserfile(fileitem)"
                                                style="cursor: pointer">
                                                {{ fileitem.FileName.length > 15 ? fileitem.FileName.slice(0, 10) + "..."
                                                  + fileitem.FileName.split(".")[1] : fileitem.FileName }}
                                              </div>
                                            </v-col>
                                          </v-row>
                                        </v-card-text>
                                      </v-card>
                                    </v-col>
                                  </template>
                                </v-row>
                              </v-card-text>
                            </v-card>
                            <br />
                            <v-card elevation="0" color="#F8F9FA">
                              <v-card-title> Traveler Preference Information </v-card-title>
                              <v-card-text>
                                Preferred Seat

                                <br />
                                <v-row>
                                  <v-col cols="12" sm="12" xs="12" md="6">
                                    <v-select outlined dense v-model="SeatByInternal" item-value="Code"
                                      item-text="Description" background-color="white"
                                      :items="dataSeatByInternal"></v-select>
                                  </v-col>
                                </v-row>
                                Special Meal
                                <br />
                                <v-row>
                                  <v-col cols="12" sm="12" xs="12" md="6">
                                    <v-select outlined dense v-model="SpecialMealByInternal" item-value="MealCode"
                                      item-text="Description" background-color="white"
                                      :items="dataSpecialMealByInternal"></v-select>
                                  </v-col>
                                </v-row>
                                <p style="color: red">Note : The above are subject to available by the time and flight we
                                  make reservation.</p>
                              </v-card-text>
                            </v-card>
                            <br />
                            <v-card elevation="0" color="#F8F9FA">
                              <v-card-title>
                                Passport
                                <v-spacer></v-spacer>
                                <v-btn style="float: right" @click="ShowDialog('Passport', 'add', null)" v-if="$store.state.buttonPermit.menuConfig.Add">
                                  <v-img class="shrink mr-2" :src="require('../assets/icons/Add.svg')" width="22" />
                                  Add Passport
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <v-layout mt-3 class="overflow-x-auto scroll-custom"
                                  v-if="dataInfo.Passport != null && dataInfo.Passport.length > 0">
                                  <v-flex>
                                    <v-layout ma-2>
                                      <template v-for="(itemPassport, index) in dataInfo.Passport">
                                        <v-card :key="index" v-if="itemPassport.PassportType !== 'Required'"
                                          :class="index < dataInfo.Passport.length - 1 ? 'mr-5' : null" min-width="362">
                                          <v-container>
                                            <v-layout>
                                              <span style="font-size: 16px; white-space: nowrap">
                                                {{ itemPassport.PassportType }}
                                              </span>
                                              <v-spacer></v-spacer>
                                              <v-btn icon small @click="ShowDialog('Passport', 'edit', itemPassport)">
                                                <v-img :src="require('@/assets/icons/EditDetail.svg')"></v-img>
                                              </v-btn>
                                              &nbsp;
                                              <v-btn icon small
                                                @click="(DialogRemoveByInternal = true), remove('Passport', 'alert', itemPassport)">
                                                <v-img :src="require('@/assets/icons/Delete.svg')"></v-img>
                                              </v-btn>
                                            </v-layout>
                                          </v-container>
                                          <v-container>
                                            <v-divider></v-divider>
                                          </v-container>
                                          <v-container>
                                            <v-layout>
                                              <v-flex mr-5>
                                                <v-layout>
                                                  <v-flex>
                                                    <span style="white-space: nowrap"> Passport No. </span>
                                                    <br />
                                                    <span style="white-space: nowrap">
                                                      <strong>{{ itemPassport.PassportNo }}</strong>
                                                    </span>
                                                  </v-flex>
                                                </v-layout>
                                                <v-layout>
                                                  <v-flex>
                                                    <span style="white-space: nowrap"> Expiry Date </span>
                                                    <br />
                                                    <span style="white-space: nowrap">
                                                      <strong>{{ formatDate(itemPassport.ExpiryDate) }}</strong>
                                                    </span>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex>
                                                <v-layout>
                                                  <v-flex>
                                                    <span style="white-space: nowrap"> Request New Passport </span>
                                                    <br />
                                                    <span style="white-space: nowrap">
                                                      <strong>{{ itemPassport.RequestNewPassport }}</strong>
                                                    </span>
                                                  </v-flex>
                                                </v-layout>
                                                <v-layout>
                                                  <v-flex>
                                                    <span style="white-space: nowrap"> Country </span>
                                                    <br />
                                                    <span style="white-space: nowrap">
                                                      <strong>{{ itemPassport.Country }}</strong>
                                                    </span>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-container>
                                        </v-card>
                                      </template>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                                <v-layout v-else>
                                  <v-flex>
                                    <h3 style="text-align: center">Empty Data</h3>
                                  </v-flex>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                            <br />
                            <v-card elevation="0" color="#F8F9FA">
                              <v-card-title>
                                Visa
                                <v-spacer></v-spacer>
                                <v-btn @click="ShowDialog('Visa', 'add', null)" style="float: right" v-if="$store.state.buttonPermit.menuConfig.Add">
                                  <v-img class="shrink mr-2" :src="require('@/assets/icons/Add.svg')" width="22" />
                                  Add Visa
                                </v-btn>
                              </v-card-title>
                              <v-card-text>
                                <v-layout mt-3 class="overflow-x-auto scroll-custom"
                                  v-if="dataInfo.Visa != null && dataInfo.Visa.length > 0">
                                  <v-flex>
                                    <v-layout ma-2>
                                      <template v-for="(itemVisa, index) in dataInfo.Visa">
                                        <v-card :key="index" v-if="itemVisa.VisaType !== 'Required'"
                                          :class="index < dataInfo.Visa.length - 1 ? 'mr-5' : null" min-width="362">
                                          <v-container>
                                            <v-layout>
                                              <span style="font-size: 16px; white-space: nowrap">
                                                {{ itemVisa.VisaType }}
                                              </span>
                                              <v-spacer></v-spacer>
                                              <v-btn icon small @click="ShowDialog('Visa', 'edit', itemVisa)">
                                                <v-img :src="require('@/assets/icons/EditDetail.svg')"> </v-img>
                                              </v-btn>
                                              &nbsp;
                                              <v-btn icon small
                                                @click="(DialogRemoveByInternal = true), remove('Visa', 'alert', itemVisa)">
                                                <v-img :src="require('@/assets/icons/Delete.svg')"> </v-img>
                                              </v-btn>
                                            </v-layout>
                                          </v-container>
                                          <v-container>
                                            <v-divider></v-divider>
                                          </v-container>
                                          <v-container>
                                            <v-layout>
                                              <v-flex mr-5>
                                                <v-layout>
                                                  <v-flex>
                                                    <span style="white-space: nowrap"> Visa No. </span>
                                                    <br />
                                                    <span style="white-space: nowrap">
                                                      <strong>{{ itemVisa.VisaNo }}</strong>
                                                    </span>
                                                  </v-flex>
                                                </v-layout>
                                                <v-layout>
                                                  <v-flex>
                                                    <span style="white-space: nowrap"> Issue Date </span>
                                                    <br />
                                                    <span style="white-space: nowrap">
                                                      <strong>{{ formatDate(itemVisa.IssueDate) }}</strong>
                                                    </span>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex>
                                                <v-layout>
                                                  <v-flex>
                                                    <span style="white-space: nowrap"> Country </span>
                                                    <br />
                                                    <span style="white-space: nowrap">
                                                      <strong>{{ itemVisa.Country }}</strong>
                                                    </span>
                                                  </v-flex>
                                                </v-layout>
                                                <v-layout>
                                                  <v-flex>
                                                    <span style="white-space: nowrap"> Expiry Date </span>
                                                    <br />
                                                    <span style="white-space: nowrap">
                                                      <strong>{{ formatDate(itemVisa.ExpiryDate) }}</strong>
                                                    </span>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-container>
                                        </v-card>
                                      </template>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                                <v-layout v-else>
                                  <v-flex>
                                    <h3 style="text-align: center">Empty Data</h3>
                                  </v-flex>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                          </v-card-text>
                          <v-card-text>
                            <hr />
                            <br />
                            <v-row>
                              <v-col class="text-center">
                                <v-btn outlined color="#00AEEF" large width="130" @click="closeModal"> Cancel </v-btn>
                                &emsp;
                                <v-btn color="#00AEEF" large style="color: white" width="130" @click="saveProfile()"> Save
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-dialog v-model="DialogPassportByInternal" width="800">
                          <Passport :valPass="dataInfo.senddata" :key="dataInfo.componentKey" :_ID="dataInfo.EMPLOYEE_ID"
                            @PassportUpdate="dataInfo.Passport = $event"
                            @DialogPassport="DialogPassportByInternal = $event" />
                        </v-dialog>
                        <v-dialog v-model="DialogVisaByInternal" width="800">
                          <Visa @VisaUpdate="dataInfo.Visa = $event" @DialogVisa="DialogVisaByInternal = $event" :_ID="dataInfo.EMPLOYEE_ID"
                            :valVisa="dataInfo.VisaData" :key="dataInfo.componentKey"> </Visa>
                        </v-dialog>
                        <v-dialog v-model="DialogRemoveByInternal" width="500">
                          <v-card>
                            <v-card-title> Remove </v-card-title>
                            <v-card-text>
                              <template v-if="FileFocusing"> would you like to remove '{{ FileFocusing.FileName }}' ?
                              </template>
                              <template v-else> would you like to remove your passport account? </template>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn small text @click="DialogRemoveByInternal = false">Cancel</v-btn>
                              <v-btn small color="error" @click="remove(typeFocusing, 'remove', null)">Remove</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                    </template>
                    <!-- End Modal content goes here -->
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card>
            <v-row class="mt-3 hidden-xs-only" no-gutters>
              <v-col class="d-flex" cols="12">
                <span class="mr-3 mt-3">Show</span>
                <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value" v-model="itemPage"
                  background-color="white" class="select-data-table shrink"></v-select>
                <span class="ml-3 mt-3">items per page</span>
                <v-pagination v-model="page" :length="pageCount" :total-visible="5" class="pagination-custom"
                  style="width: 60%"></v-pagination>
              </v-col>
            </v-row>
            <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
              <v-col class="d-flex" cols="12">
                <span class="mr-3 mt-3">Show</span>
                <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value" v-model="itemPage"
                  background-color="white" class="select-data-table shrink"></v-select>
                <span class="ml-3 mt-3">items per page</span>
              </v-col>
              <v-col>
                <v-pagination v-model="page" :length="pageCount" :total-visible="5" class="pagination-custom">
                </v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import Passport from "@/components/Passport.vue";
import Visa from "@/components/Visa.vue";
export default {
  data() {
    return {
      dataUpdate: moment().format("DD MMM YYYY hh:mm A"),
      itemsPerPage: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "ALL", value: 200 },
      ],
      itemPage: 5,
      page: 1,
      pageCount: 2,
      headersInternal: [
        { text: "No.", value: "No", sortable: false },
        { text: "Internal ID", value: "EMPLOYEE_ID", sortable: false },
        { text: "Full Name", value: "FULLNAME", sortable: false },
        { text: "ABBREVIATION", value: "ABBREVIATION", sortable: false },
        { text: "E-mail", value: "EMAIL_ID", sortable: false },
        { text: "Office Phone No.", value: "DIRECT_PHONE", sortable: false },
      ],
      EX_PROFILE: {
        CBY: null,
        TITLE: "",
        EX_ID: "",
        EX_FNAME: "",
        CBYNAME: "",
        EX_LNAME: "",
        EX_PHONE_NO: "",
        EX_EMAIL: "",
        EX_OFFICE_PHONE: null,
        Company: null,
        Birthday: null,
        Visa: null,
        Passport: null,
        EarnMiles: [],
      },
      titleName: [
        { code: "mr", name: "Mr." },
        {code: 'Mrs', name: 'Mrs.'},
        { code: "miss", name: "Miss." },
      ],
      birthDate: {
        menu: false,
        value: null,
      },

      PassportFocusing: null,
      VisaFocusing: null,
      FileFocusing: null,
      typeFocusing: null,
      filesing: [],
      Getfilesing: [],
      file: null,
      DIVISION_IDByInternal: "",
      DialogPassportByInternal: false,
      DialogVisaByInternal: false,
      DialogRemoveByInternal: false,
      dataSeatByInternal: [
        { Code: "", Description: "No Preferred" },
        { Code: "A", Description: "Aisle seat" },
        { Code: "W", Description: "Window seat" },
      ],
      dataSpecialMealByInternal: [],
      AirlineByInternal: [],
      SeatByInternal: "",
      SpecialMealByInternal: "",
      LOCATIONByInternal: "",
      ORGUNITByInternal: "",
      MOBILE_CODE: "+66",
      MOBILE_NO: "",


      dataInfo: {
        DIRECT_PHONE: "",
        showModal: false,
        Internals: [],
        rules: {},
        reloadBtn: false,
        formatDateString: "YYYY-MM-DD",
        itemInternal: [],
        selectedItem: [],
        searchValue: "",
        dialogAddExternal: false,
        selectedRow: null,
        XLSX: null,
        sizes: ["Bytes", "KB", "MB"],
        EarnMiles: [],
        DEPARTMENT_ID: "",
        EMAIL_ID: "",
        POSITION: "",
        FIRSTNAME: "",
        LASTNAME: "",
        TITLE: "",
        POSITIONTHAI: "",
        SEX: "",
        EMPLOYEE_ID: "",
        TITLE_TH: "",
        THAINAME: "",
        JOIN_DATE: "",
        countryCode: ["+66", "22"],
        componentKey: 0,
        visaFile: [],
        pstFile: [],
        Passport: null,
        Visa: null,
        senddata: null,
        VisaData: null,
        filesize: 0,
        EMPLOYEE_ID_Login: "",
      },
    };
  },
  components: {
    Passport,
    Visa,
  },
  methods: {
    validateNumberText(val) {
      return val.replace(/\D/g, "");
    },
    InputFile() {
      this.$refs.input_file.click();
    },
    openFileUserfile(val) {
      window.open(val.FilePath);
    },
    closeModal() {
      this.dataInfo.showModal = false;
    },
    async rowClicked(item) {
      if(this.$store.state.buttonPermit.menuConfig.Update){
        var joinDate = new Date(item.JOIN_DATE);
        var formattedDate = joinDate.toLocaleDateString();
        this.dataInfo.EMPLOYEE_ID = item.EMPLOYEE_ID;
        this.dataInfo.TITLE_TH = item.TITLE_TH;
        this.dataInfo.THAINAME = item.THAINAME;
        this.dataInfo.POSITIONTHAI = item.POSITIONTHAI;
        this.dataInfo.TITLE = item.TITLE;
        this.dataInfo.FIRSTNAME = item.FIRSTNAME;
        this.dataInfo.LASTNAME = item.LASTNAME;
        this.dataInfo.POSITION = item.POSITION;
        this.dataInfo.EMAIL_ID = item.EMAIL_ID;
        this.dataInfo.DEPARTMENT_ID = item.DEPARTMENT_ID;
        this.DIVISION_IDByInternal = item.DIVISION_ID;
        this.dataInfo.JOIN_DATE = formattedDate;
        this.dataInfo.DIRECT_PHONE = item.DIRECT_PHONE;
        this.SeatByInternal = item.PREFERRED_SEAT
        this.SpecialMealByInternal = item.SPECIAL_MEAL
        if (item.MOBILE_NO && item.MOBILE_NO !== "-") {
          if (item.MOBILE_NO.split(" ")[1]) {
            this.MOBILE_CODE = item.MOBILE_NO.split(" ")[0];
            this.MOBILE_NO = item.MOBILE_NO.split(" ")[1];
          } else {
            this.MOBILE_NO = item.MOBILE_NO;
          }
        }
        this.dataInfo.EarnMiles = await this.getEarnMiles();
        this.GetFile("UserProfile");
        this.AirlineByInternal.push(...this.dataInfo.EarnMiles.filter((x) => x.IsActive !== 1));
        this.dataInfo.Passport = await this.getPassportByID();
        this.dataInfo.Visa = await this.getVisaByID();
        this.dataInfo.showModal = true;
      }
    },
    GetAirlineByKey(val) {
      axios
        .post(
          `${this.$store.state.API_PATH}EarnMiles/GetAirlineByKey?val=${val}&Language=en`,
          {},
          {
            headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
          }
        )
        .then((res) => {
          this.AirlineByInternal = res.data;
          this.AirlineByInternal.push(...this.dataInfo.EarnMiles.filter((x) => x.IsActive !== 1));
        });
    },
    SetAirlineByKey(val) {
      return this.AirlineByInternal.filter((x) => x.Name === val);
    },
    getDataInternalTR(val) {
      const PROFILE = JSON.parse(localStorage.PROFILE)
      axios
        .post(
          `${this.$store.state.API_PATH}UserProfile/GetInternalUserProfileBySearch`,
          {
            EMPLOYEE_ID: val,
            EMP_ID: PROFILE.EMPLOYEE_ID
          },
          {
            headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
          }
        )
        .then(async (res) => {
          if (res.data.Status) {
            this.dataInfo.itemInternal = res.data.Result;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.LOADING = false;
        });
    },
    openDialog() {
      this.dialogAddExternal = true;
    },
    closeAddExternal() {
      this.TITLE = "";
      this.birthDate.value = "";
      this.EX_FNAME = "";
      this.EX_LNAME = "";
      this.EX_PHONE_NO = "";
      this.EX_EMAIL = "";
      this.dialogAddExternal = false;
    },
    remove(type, processing, val) {
      this.typeFocusing = type;
      if (type === "Passport" || type === "Visa") {
        this.isPassportAndVisa(type, processing, val);
      } else if (type === "PassportFile") {
        if (processing === "alert") {
          this.FileFocusing = val;
        }
        if (processing === "remove") {
          this.removeFilesUserfile(this.FileFocusing, "Saved");
          this.DialogRemoveByInternal = false;
        }
      }
    },
    isPassportAndVisa(type, processing, val) {
      if (processing === "alert") {
        if (type === "Passport") {
          this.PassportFocusing = val;
        } else {
          this.VisaFocusing = val;
        }
      }
      if (processing === "remove") {
        if (type === "Passport") {
          this.ShowRemove("Passport", this.PassportFocusing);
        } else {
          this.ShowRemove("Visa", this.VisaFocusing);
        }
        this.DialogRemoveByInternal = false;
      }
    },
    removeFilesUserfile(val, keyByInternal) {
      if (keyByInternal === "NotSave") {
        this.filesing = this.filesing.filter((x) => x.name !== val.name);
        this.filesize -= val.size;
      } else if (keyByInternal === "Saved") {
        const formData = new FormData();

        for (let i = 0; i < this.filesing.length; i++) {
          formData.append("UserProfile", this.filesing[0]);
        }
        const files = formData
        axios
          .post(
            `${this.$store.state.API_PATH}FileUpload/RemoveFile?FileOID=${this.dataInfo.EMPLOYEE_ID}|${val.FilePath.split("?", "")}|${val.FileOID}|UserProfile|${val.FileName}`,
            files,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            if (res.data.Status) {
              this.Getfilesing = this.Getfilesing.filter((x) => x.FileOID !== val.FileOID);
            }
          });
      }
    },
    AddAttachFile(event) {
      this.filesing.push(...event.target.files);
    },
    AddOthMiles() {
      this.dataInfo.EarnMiles.push({
        no: this.dataInfo.EarnMiles.length + 1,
        EarnMileOID: null,
        EMPLOYEE_ID: this.dataInfo.EMPLOYEE_ID,
        Airline: null,
        MemberID: null,
      });
    },
    RemoveOthmiles(val) {
      if (this.dataInfo.EarnMiles.length > 1) {
        if (val.EarnMileOID !== null) {
          axios
            .post(
              `${this.$store.state.API_PATH}EarnMiles/RemoveEarnMiles?EarnMileOID=${val.EarnMileOID}&EMPLOYEE_ID=${this.dataInfo.EMPLOYEE_ID}`,
              {},
              {
                headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
              }
            )
            .then((res) => {
              this.dataInfo.EarnMiles = res.data.Result;
            });
        } else {
          this.dataInfo.EarnMiles = this.dataInfo.EarnMiles.filter((x) => x.no !== val.no);
        }
      }
    },
    ShowDialog(type, val, pItem) {
      this.forceRerender();
      if (type === "Passport" || type === "Visa") {
        if (type === "Passport") {
          this.DialogPassportByInternal = true;
          this.dataInfo.senddata = val === "edit" ? pItem : null;
        } else {
          this.DialogVisaByInternal = true;
          this.dataInfo.VisaData = val === "edit" ? pItem : null;
        }
      }
    },
    ShowRemove(type, val) {
      if (type === "Passport") {
        axios
          .post(`${this.$store.state.API_PATH}Passport/RemovePassport`, val, {
            headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
          })
          .then((res) => {
            if (res.data != null && res.data.Status) {
              this.dataInfo.Passport = res.data.Result;
            }
          });
      } else if (type === "Visa") {
        axios
          .post(`${this.$store.state.API_PATH}Visa/RemoveVisa`, val, {
            headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
          })
          .then((res) => {
            if (res.data != null && res.data.Status) {
              this.dataInfo.Visa = res.data.Result;
            }
          });
      }
    },
    forceRerender() {
      this.dataInfo.componentKey += 1;
    },

    totalAge(val) {
      const nowdate = moment().format(this.formatDateString);
      let result = null;
      if (val) {
        result = parseInt(moment(nowdate).diff(moment(val), "years"));
      }
      return result;
    },
    getSpecialMeal() {
      axios
        .post(
          `${this.$store.state.API_PATH}SpecialMeal/GetSpecialMeal`,
          {},
          {
            headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
          }
        )
        .then((res) => {
          if (res.data.Status) {
            this.dataSpecialMealByInternal = res.data.Result;
            this.dataSpecialMealByInternal.unshift({ Description: "No Preferred", MealCode: "" });
          }
        })
        .catch((err) => err);
    },
    formatDate(val) {
      let result = "";
      const formatdate = "DD MMM YYYY";
      if (val !== null && val !== "" && val !== "Invalid date") {
        result = moment(val).format(formatdate);
        this.EX_PROFILE.Birthday = moment(result).format(formatdate);
      } else {
        this.birthDate.value = moment().format(formatdate);
        result = moment().format(formatdate);
      }
      return result;
    },
    async getInternalID(val) {
      await axios
        .post(
          `${this.$store.state.API_PATH}UserProfile/GetUserProfileBySearch?val=${val}`,
          {},
          {
            headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
          }
        )
        .then(async (res) => {
          if (res.data.Status) {
            this.dataInfo.Internals = await res.data.Result;
          }
        })
        .catch(() => {
          this.$store.state.LOADING = false;
        });
      return this.dataInfo.Internals[0].FIRSTNAME;
    },

    async saveProfile() {
      const formData = new FormData();
      for (const i of this.filesing) {
        await new Promise(resolve => {
          formData.append("UserProfile", i)
          resolve()
        })
      }
      const data = JSON.parse(localStorage.PROFILE);
      let JOIN_DATE = null;
      if (data.JOIN_DATE) {
        JOIN_DATE = `'${data.JOIN_DATE}'`;
      } else if (data.HIREDATE) {
        JOIN_DATE = `'${data.HIREDATE}'`;
      }

      const filesing = formData;
      this.$store.state.LOADING = true;

      axios
        .post(
          `${this.$store.state.API_PATH}UserProfile/InsertUserProfile`,
          {
            EMPLOYEE_ID: this.dataInfo.EMPLOYEE_ID,
            TITLE_TH: this.dataInfo.TITLE_TH,
            THAINAME: this.dataInfo.THAINAME,
            POSITIONTHAI: this.dataInfo.POSITIONTHAI,
            TITLE: this.dataInfo.TITLE,
            FIRSTNAME: this.dataInfo.FIRSTNAME,
            LASTNAME: this.dataInfo.LASTNAME,
            SEX: this.dataInfo.SEX,
            LOCATION: this.dataInfo.LOCATIONByInternal,
            ORGUNIT: this.dataInfo.ORGUNITByInternal,
            POSITION: this.dataInfo.POSITION,
            EMAIL_ID: this.dataInfo.EMAIL_ID,
            MOBILE_NO: this.MOBILE_NO.indexOf("+") === -1 ? `${this.MOBILE_CODE} ${this.MOBILE_NO}` : `${this.MOBILE_NO}`,
            Groupdivision_ID: data.Groupdivision_ID,
            DIVISION_ID: data.DIVISION_ID,
            DEPARTMENT_ID: data.DEPARTMENT_ID,
            SECTION_ID: data.SECTION_ID,
            JOIN_DATE: JOIN_DATE,
            PREFERRED_SEAT: this.SeatByInternal,
            SPECIAL_MEAL: this.SpecialMealByInternal,
            STATUS: data.STATUS,
            EarnMiles: this.dataInfo.EarnMiles,
            formFiles: formData,
            DIRECT_PHONE: this.dataInfo.DIRECT_PHONE,
          },
          {
            headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
          }
        )
        .then((res) => {
          if (res.data.Status) {
            axios.post(`${this.$store.state.API_PATH}FileUpload/Upload?ID=` + this.dataInfo.EMPLOYEE_ID, filesing, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }).then(() => {
              this.$store.state.LOADING = false;
              setTimeout(() => {
                Swal.fire({
                  icon: "success",
                  text: "Success",
                }).then(() => {
                  axios
                    .post(
                      `${this.$store.state.API_PATH}Login/LoginByEmpCode`,
                      {
                        EMPLOYEE_ID: data.EMPLOYEE_ID,
                      },
                      {
                        headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
                      }
                    )
                    .then((res2) => {
                      if (res2.data.Status) {
                        localStorage.PROFILE = JSON.stringify(res2.data.Result);
                        setTimeout(() => {
                          window.location = window.location.href;
                        }, 100);
                      }
                    })
                    .catch((err) => {
                      this.$store.state.LOADING = false;
                      console.log(err);
                    });
                });
              }, 100)
            })
          }
          else {
            this.$store.state.LOADING = false;
          }
        })
        .catch((err) => {
          this.$store.state.LOADING = false;
          console.log(err);
        });
    },
    getEMPByID(val) {
      return axios.post(
        `${this.$store.state.API_PATH}UserProfile/GetUserProfileByID?val=${val}`,
        {},
        {
          headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
        }
      );
    },
    getEarnMiles() {
      return axios
        .post(
          `${this.$store.state.API_PATH}EarnMiles/GetEarnMiles?val=${this.dataInfo.EMPLOYEE_ID}`,
          {},
          {
            headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
          }
        )
        .then((res) => {
          return res.data.Result.length === 0 ? null : res.data.Result;
        });
    },
    GetFile(val) {
      axios.post(`${this.$store.state.API_PATH}FileUpload/GetFile?ID=${this.dataInfo.EMPLOYEE_ID}|${val}`).then((res) => {
        this.Getfilesing = res.data.Result;
      });
    },
    async getPassportByID() {
      let Result = null;
      await axios
        .post(
          `${this.$store.state.API_PATH}Passport/GetPassportByID?pEmployeeID=${this.dataInfo.EMPLOYEE_ID}`,
          {},
          {
            headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
          }
        )
        .then((res) => {
          Result = res.data.Result;
        });

      return Result;
    },
    async getVisaByID() {
      let Result = null
      await axios.post(`${this.$store.state.API_PATH}Visa/GetVisa?val=${this.dataInfo.EMPLOYEE_ID}`, {}, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(res => {
        Result = res.data.Result
      })
      return Result
    },
  },
  async mounted() {
    this.getSpecialMeal();
    this.getDataInternalTR();

    const textRequire = "This field is required";
    this.dataInfo.rules = {
      TITLE: [(val) => (val || "").length > 0 || textRequire],
      EX_FNAME: [(val) => (val || "").length > 0 || textRequire],
      EX_LNAME: [(val) => (val || "").length > 0 || textRequire],
      EX_PHONE_NO: [(val) => (val || "").length > 0 || textRequire],
      EX_EMAIL: [(val) => (val || "").length > 0 || textRequire],
      Company: [(val) => (val || "").length > 0 || textRequire],
      EX_OFFICE_PHONE: [(val) => (val || "").length > 0 || textRequire],
      CBY: [(val) => (val || "").length > 0 || textRequire],
      Birthday: [(val) => (val || "").length > 0 || textRequire],
      OthMiles: [(val) => (val || "").length > 0 || textRequire],
      Airline: [(val) => (val || "").length > 0 || textRequire],
    };
  },
};
</script>
<style scoped>
.font-W700 {
  font-weight: 700;
}

.font-W400 {
  font-weight: 400;
}

.btn-table {
  background: rgba(0, 174, 239, 0.1) !important;
}
</style>
