<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <span>
                    <span class="font-W700" size="4">Trip Summary</span>
                </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <v-row align="center" no-gutters>
                            <v-col class="text-right">
                                <v-badge content="1" color="#2D6FE3"></v-badge>
                            </v-col>
                            <v-col cols="12" class="pl-6">
                                <span style="font-weight: 500; color: #000000;">
                                    Bangkok (BKK)&nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img"
                                    width="14px" preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"><path fill="none" stroke="currentColor"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M21 6H3m18 0l-4 4m4-4l-4-4M3 18h18M3 18l4 4m-4-4l4-4"/></svg>
                                    &nbsp;Bangkok (BKK)
                                </span><br>
                                <span style="font-weight: 400; color: #747678;">
                                    Mon, 14 Feb 2025 - Sun, 20 Feb 2025  |  2 passenger  |  Business
                                </span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="8">
                <flightDetail :formContinue="true" />
            </v-col>
            <v-col cols="12" md="4">
                <projectDetail @callContinueBooking="formContinue = $event" :formContinue="true" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import flightDetail from '@/components/searchFlight/FlightDetail'
export default {
    components:{
        flightDetail,
    },
    data(){
        return{
            formContinue: []
        }
    }
}
</script>
