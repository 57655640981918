import Vue from "vue";
import Vuex from "vuex";
import environment from "@/environment";
import axios from "axios";
import imageCompression from "browser-image-compression";
import docPassport from "./modules/docPassport";
import docVisa from "./modules/docVisa";
import travelRequest from "./modules/travelRequest";
import buttonPermit from "./modules/buttonPermit";
import moment from "moment";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    docPassport,
    docVisa,
    travelRequest,
    buttonPermit,
  },
  state: {
    HotelOID: "",
    isReIssue: false,
    selectedRole: "",
    menuConfig: {
      Add: 0,
      All: 0,
      Approve: 0,
      Delete: 0,
      ReIssue: 0,
      Revise: 0,
      Update: 0,
      View: 0,
      icon: 0,
      link: 0,
      title: 0,
    },
    listMenu: [],
    path: "",
    ItemPending: [],
    dateNowThai: `${moment().locale("th").format("Do MMMM")} ${Number(moment().format("YYYY")) + 543}`,
    dateNowEN: moment().format("DD MMMM YYYY"),
    SHOW_MENU: true,
    showBtnRejectReturnApprove: false,
    LOADING: false,
    API_PATH: environment.API_PATH,
    API_HRWS: environment.API_HRWS,
    Language: "en",
    username: environment.uName,
    password: environment.pWord,
    x_apikey: environment.x_apikey,
    accessToken: "",
    showHotelInfo: false,
    objHTL: {},
    showHotelTripSum: false,
    authenApi: environment.authenApi,
    googleMap: environment.googleMap,
    logo_pttep: environment.logo_pttep,
    NewRS_PTTEPNAME: environment.NewRS_PTTEPNAME,
    NewWeb_footerPTTEP: environment.NewWeb_footerPTTEP,
    configTinyMce: {
      height: 500,
      nowrap: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | fontselect | fontsizeselect | forecolor backcolor | " +
        "bold italic underline strikethrough | link image media table mergetags | " +
        " addcomment showcomments | spellcheckdialog a11ycheck typography | " +
        "align lineheight | checklist numlist bullist indent outdent",
      fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
      automatic_uploads: true,
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.onchange = async function () {
          var file = await imageCompression(this.files[0], { maxSizeMB: 0.1, useWebWorker: true });
          var reader = new FileReader();
          reader.onload = function () {
            var id = "blobid" + new Date().getTime();
            var blobCache = tinymce.activeEditor.editorUpload.blobCache;
            var base64 = reader.result.split(",")[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };
        input.click();
      },
      image_class_list: [{ title: "Responsive", value: "img-responsive" }],
    },
    headerReport: [
      {
        image: environment.logo_pttep,
        width: 80
      },
      {
        text: "",
        width: 90
      },
      {
        image: environment.NewRS_PTTEPNAME,
        width: 350,
        alignment: 'right'
      }
    ]
  },
  getters: {},
  mutations: {
    getToken(state) {
      axios
        .post(
          state.API_HRWS + "oauth2/token?grant_type=client_credentials",
          {},
          {
            auth: {
              username: state.username,
              password: state.password,
            },
          }
        )
        .then((res) => {
          state.accessToken = res.data.access_token;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  actions: {},
});
