import axios from "axios";
import moment from "moment";
import store from "../index";

function dateThai(date) {
  return `${moment(date).locale("th").format("Do MMMM")} ${Number(moment(date).format("YYYY")) + 543}`;
}

export default {
  namespaced: true,
  state: {
    txtdoc2: "2. หนังสือรับรองการเป็นพนักงานของบริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน)",
    costFlight: "",
    costTotal: "",
    txtOther: "",
    posiOther: "",
    flight: "",
    country: "",
    joinDate: "",
    nameBudget: "",
    posiBudget: "",
    nameTH: "",
    posiThai: "",
    nameBTT: "",
    posiBTT: "",
    TRBudget: "",
    dateStart: "",
    dateEnd: "",
    documentTraveller: [],
    enclosure: "1. คำร้องขอออกหนังสือเดินทาง",
    passportNo: "",
    docNo: "1",
    TRNo: "",
    EMP_ID: "",
    Type: "",
    headerPassport: [],
    configFooterPassport: {},
    configStylePassport: {},
    rptOffPssTopic: [],
    rptOffPss4Topic: [],
    rptOffPssNameAndPosition: [
      {
        style: "font16",
        margin: [0, 20, 0, 0],
        alignment: "center",
        columns: [
          {
            text: "",
            width: 70,
          },
          {
            decoration: "underline",
            width: 150,
            text: "ชื่อ นามสกุล",
          },
          {
            decoration: "underline",
            text: "ตำแหน่ง",
          },
        ],
      },
    ],
    rptOffPssSign: [],
    rptOffPssDoc: [],
    rptOffpss02NameBtt: [],
    newTravel: [],
  },
  mutations: {
    getDocPassport(state, param) {
      state.configFooterPassport = {
        margin: [20, -10, 20, 10],
        image: store.state.NewWeb_footerPTTEP,
        width: 555,
      };
      state.configStylePassport = {
        font16: {
          alignment: "left",
          fontSize: 16,
        },
      };
      state.headerPassport = [
        {
          image: store.state.logo_pttep,
          width: 80,
        },
        {
          text: "",
          width: 90,
        },
        {
          image: store.state.NewRS_PTTEPNAME,
          width: 350,
          alignment: "right",
        },
      ];
      store.state.LOADING = true;
      axios
        .post(
          `${store.state.API_PATH}Document/GetDocPassport`,
          {
            TRNo: param.TRNo,
            EMP_ID: param.EMP_ID,
            Type: param.Type,
          },
          { headers: { Authorization: `Basic ${store.state.authenApi}` } }
        )
        .then(async (res) => {
          state.newTravel = [];
          store.state.LOADING = false;
          if (res.data.Status) {
            const item = res.data.Result[0];
            state.TRBudget = item.TRBudget;
            state.dateStart = dateThai(item.BusinessStartDate);
            state.dateEnd = dateThai(item.BusinessEndDate);
            state.documentTraveller = item.DocumentTraveller;
            for (const t of item.DocumentTraveller) {
              await new Promise((resolve) => {
                state.newTravel.push({
                  style: "font16",
                  margin: [0, 5, 0, 0],
                  alignment: "center",
                  columns: [
                    {
                      text: "",
                      width: 70,
                    },
                    {
                      width: 150,
                      text: t.NameTH,
                    },
                    {
                      text: t.PosiTH,
                    },
                  ],
                });
                state.newTravel.push({
                  style: "font16",
                  margin: [0, 5, 0, 0],
                  alignment: "center",
                  columns: [
                    {
                      text: "",
                      width: 70,
                    },
                    {
                      width: 150,
                      text: `(${t.NameEN})`,
                    },
                    {
                      text: `(${t.PosiEN})`,
                    },
                  ],
                });
                resolve();
              });
            }
            if (item.DocumentTraveller != null && item.DocumentTraveller.length > 0) {
              state.nameTH = item.DocumentTraveller[0].NameTH;
              state.posiThai = item.DocumentTraveller[0].PosiTH;
              state.joinDate = dateThai(item.DocumentTraveller[0].JoinDate);
            }
            state.nameBudget = item.NameBudget;
            state.posiBudget = item.PosiBudget;
            state.nameBTT = item.NameBTT;
            state.posiBTT = item.PosiBTT;
            state.country = item.Country;
            state.flight = item.Flight;
            state.rptOffPssTopic = [
              { text: "เรื่อง ขอความอนุเคราะห์ออกหนังสือเดินทางราชการ", style: "font16", margin: [0, 15, 0, 0] },
              { text: "เรียน ปลัดกระทรวงการต่างประเทศ", style: "font16", margin: [0, 8, 0, 0] },
              { style: "font16", margin: [0, 8, 0, 0], columns: [{ text: "สิ่งที่ส่งมาด้วย", width: 70 }, { text: state.enclosure }] },
              { style: "font16", margin: [0, 5, 0, 0], columns: [{ text: "", width: 70 }, { text: state.txtdoc2 }] },
              { style: "font16", margin: [0, 5, 0, 0], columns: [{ text: "", width: 70 }, { text: `3. อนุมัติการเดินทาง ${state.TRBudget}` }] },
              {
                style: "font16",
                margin: [0, 20, 0, 0],
                columns: [{ text: "", width: 70 }, { text: `ด้วยบริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน) ได้อนุมัติให้พนักงาน จำนวน 1 ท่าน เดินทาง` }],
              },
            ];
            state.rptOffPssDoc = [
              { style: "font16", margin: [0, 5, 0, 0], columns: [{ text: "", width: 70 }, { text: state.txtdoc2 }] },
              { style: "font16", margin: [0, 5, 0, 0], columns: [{ text: "", width: 70 }, { text: `3. อนุมัติการเดินทาง ${state.TRBudget}` }] },
              {
                style: "font16",
                margin: [0, 20, 0, 0],
                columns: [{ text: "", width: 70 }, { text: `ด้วยบริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน) ได้อนุมัติให้พนักงาน จำนวน 1 ท่าน เดินทาง` }],
              },
              {
                text: `ไปปฏิบัติงานที่ประเทศ${state.country}
              ระหว่างวันที ${state.dateStart} - ${state.dateEnd}`,
                style: "font16",
              },
            ];
            state.rptOffPssSign = [
              {
                style: "font16",
                margin: [0, 20, 0, 0],
                columns: [{ text: "", width: 70 }, { text: "จึงเรียนมาเพื่อโปรดอนุเคราะห์ออกหนังสือเดินทางราชการ ให้แก่พนักงานดังกล่าว ข้างต้นด้วย" }],
              },
              { text: "จักเป็นพระคุณยิ่ง", style: "font16" },
              { style: "font16", margin: [0, 30, 0, 0], alignment: "center", columns: [{ text: "", width: 160 }, { text: "ขอแสดงความนับถือ" }] },
              { style: "font16", margin: [0, 35, 0, 0], alignment: "center", columns: [{ text: "", width: 160 }, { text: state.nameBudget }] },
              { style: "font16", margin: [0, 10, 0, 0], alignment: "center", columns: [{ text: "", width: 160 }, { text: state.posiBudget }] },
              { style: "font16", margin: [0, 10, 0, 0], alignment: "center", columns: [{ text: "", width: 160 }, { text: state.posiOther }] },
              { style: "font16", margin: [0, 10, 0, 0], text: `ผู้ประสานงาน: ${state.nameBTT}` },
              { style: "font16", margin: [0, 5, 0, 0], text: state.posiBTT },
            ];
            state.rptOffPss4Topic = [
              { text: "เรื่อง ขอความอนุเคราะห์ออกหนังสือเดินทางราชการและหนังสือนำวีซ่า", style: "font16", margin: [0, 15, 0, 0] },
              { text: "เรียน ปลัดกระทรวงการต่างประเทศ", style: "font16", margin: [0, 8, 0, 0] },
              { style: "font16", margin: [0, 8, 0, 0], columns: [{ text: "สิ่งที่ส่งมาด้วย", width: 70 }, { text: state.enclosure }] },
              { style: "font16", margin: [0, 5, 0, 0], columns: [{ text: "", width: 70 }, { text: state.txtdoc2 }] },
              { style: "font16", margin: [0, 5, 0, 0], columns: [{ text: "", width: 70 }, { text: `3. อนุมัติการเดินทาง ${state.TRBudget}` }] },
            ];
            state.rptOffpss02NameBtt = [
              { style: "font16", margin: [0, 50, 0, 0], alignment: "center", columns: [{ text: "", width: 160 }, { text: [state.nameBudget, "\n", state.posiBudget, "\n"] }] },
              { style: "font16", margin: [0, 60, 0, 0], text: ["ผู้ประสานงาน: ", state.nameBTT, "\n", state.posiBTT, "\n"] },
            ];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
