<template>
    <v-card>
        <v-card-title>Add Traveler</v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-card elevation="0"
                        style="border-radius: 10px; background-color: #F8F9FA; border: 1px solid #EBEEF4;">
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <span style="font-weight: 500; color: #000000;">Search Traveler</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-3" v-if="typeTraveler === 'Internal'">
                                <v-col lg="3" md="3" sm="6" cols="12">
                                    <v-select outlined dense placeholder="Group" v-model="Groupdivision_ID"
                                        @change="getUserProfileByTraveler()" background-color="white"
                                        :items="GroupFilter" item-text="ABBREVIATION" item-value="NAME"></v-select>
                                </v-col>
                                <v-col lg="3" md="3" sm="6" cols="12">
                                    <v-select outlined dense placeholder="Division" v-model="DIVISION_ID"
                                        @change="getUserProfileByTraveler()" background-color="white"
                                        :items="DivisionFilter" item-text="ABBREVIATION" item-value="NAME"></v-select>
                                </v-col>
                                <v-col lg="3" md="3" sm="6" cols="12">
                                    <v-select outlined dense placeholder="Department" v-model="DEPARTMENT_ID"
                                        @change="getUserProfileByTraveler()" background-color="white"
                                        :items="DepartmentFilter" item-text="ABBREVIATION" item-value="NAME"></v-select>
                                </v-col>
                                <v-col lg="3" md="3" sm="6" cols="12">
                                    <v-select outlined dense placeholder="Section" v-model="SECTION_ID"
                                        @change="getUserProfileByTraveler()" background-color="white"
                                        :items="SectionFilter" item-text="ABBREVIATION" item-value="NAME"></v-select>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                    <v-text-field outlined dense placeholder="Traveler Name" v-model="travelName" @input.native="getUserProfileByTraveler($event.srcElement.value)"
                                        append-icon="mdi-magnify" @click:append="getUserProfileByTraveler()"
                                        background-color="white"></v-text-field>
                                </v-col>
                                <v-col cols="1">
                                    <v-btn fab x-small color="primary" class="ml-2 mt-1" @click="clearFilter()">
                                        <v-icon>mdi-autorenew</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                    <v-card elevation="0">
                                        <v-card-title>
                                            <v-spacer></v-spacer>
                                            Travelers <span :style="TravelerCount >= 9 ? 'color:red' : ''">
                                                {{TravelerCount}}/9
                                            </span>
                                        </v-card-title>
                                    </v-card>
                                    <br />
                                    <v-card elevation="0" class="overflow-x-auto" height="250px"
                                        style="border: 1px solid #E6EBF4; border-radius: 5px;">
                                        <v-card-text>
                                            <v-data-table :headers="headers" :items="itemData" mobile-breakpoint :items-per-page="itemPerpage" hide-default-footer item-key="emp_ID"
                                                checkbox-color="primary" @click:row="rowClicked" :show-select="true" :single-select="true"
                                                v-model="selectedTraveler">
                                                <template v-slot:[`item.data-table-select`]="{ isSelected, index, select }">
                                                    <div style="pointer-events: none;">
                                                        <v-checkbox :value="isSelected" :key="index"
                                                            :disabled="false" :ripple="false"
                                                            @input="select($event)"
                                                            color="primary">
                                                        </v-checkbox>
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.fullname`]="{ item }">
                                                    {{item.fullname}}
                                                    <br />
                                                    <span class="f-text-20" v-if="item.CreateBy && item.ParentsOfChild">
                                                        Travel With: {{item.CBYNAME}}
                                                    </span>
                                                </template>
                                            </v-data-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col class="text-center">
                    <v-btn outlined color="#00AEEF" class="mr-2" width="127" @click="close">Close</v-btn>
                    <v-btn elevation="0" color="#00AEEF" width="127" style="color: white" :disabled="items && items.length >= 9 ? true : false"
                        @click="addTraveler">Add Traveler</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
export default {
    props: {
        travelers: [],
        typeTraveler: "",
        items: [],
        GroupFilter: [],
        DivisionFilter: [],
        DepartmentFilter: [],
        SectionFilter: [],
    },
    data() {
        return {
            TravelerCount: this.travelers,
            Groupdivision_ID: "",
            DIVISION_ID: "",
            DEPARTMENT_ID: "",
            RequestOID: null,
            SECTION_ID: "",
            travelName: "",
            selectedTraveler: [],
            headers: [],
            itemData: [],
            itemPerpage: 20,
            dialogOpenOrClose: false,
            accessToken: "",
        }
    },
    methods: {
        async getUserProfileByTraveler() {
            if (this.typeTraveler === "Internal") {
                const PROFILE = JSON.parse(localStorage.PROFILE)
                await axios.post(this.$store.state.API_PATH + 'UserProfile/GetUserProfileByTraveler', {
                    Groupdivision_ID: this.Groupdivision_ID,
                    DIVISION_ID: this.DIVISION_ID,
                    DEPARTMENT_ID: this.DEPARTMENT_ID,
                    SECTION_ID: this.SECTION_ID,
                    FIRSTNAME: this.travelName,
                    // EMPLOYEE_ID: PROFILE.EMPLOYEE_ID,
                    STATUS: PROFILE.STATUS
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(async (res) => {
                    this.itemData = []
                    if (res.data.Status) {
                        this.itemPerpage = res.data.Result.length
                        await this.loopInternal(res.data.Result)
                    }
                }).catch(() => {

                })
            } else {
                const PROFILE = JSON.parse(localStorage.PROFILE)
                await axios.post(this.$store.state.API_PATH + 'ExternalTraveler/GetExternalTraveler', {
                    CBY: PROFILE.POSITION.indexOf('Secretary') !== -1 ? '' : PROFILE.EMPLOYEE_ID ,
                    EX_FNAME: this.travelName
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(async (res) => {
                    this.itemData = []
                    if (res.data.Status) {
                        this.itemPerpage = res.data.Result.length
                        await this.loopExternal(res.data.Result)
                    }
                })
                .catch(() => {

                })
            }
        },
        async loopInternal(data){
            let i = 0
            for (const item of data) {
                await new Promise(resolve => {
                    const haveData = this.itemData.filter(elm=>elm.EMPLOYEE_ID === item.EMPLOYEE_ID)
                    if(haveData.length === 0){
                        this.itemData.push({
                            travelerNo: moment().format("YYYYMMDD") + (i + 1),
                            PNR: null,
                            CreateBy: null,
                            emp_ID: item.EMPLOYEE_ID,
                            fullname: `${item.FIRSTNAME} ${item.LASTNAME}`,
                            firstname: item.FIRSTNAME,
                            lastname: item.LASTNAME,
                            email: item.EMAIL_ID,
                            title: item.TITLE,
                            mobile: item.MOBILE_NO,
                            position: item.POSITION,
                            group: item.Groupdivision_ID,
                            div: item.DIVISION_ID,
                            dept: item.DEPARTMENT_ID,
                            section: item.SECTION_ID,
                            flights: null,
                            hotels: [],
                            passport: [],
                            visa: [],
                            travelExpense: null,
                            TravelerOID: null,
                            TravelCard: {
                                IsDelete: 0,
                                TravelCardOID: null
                            },
                            isSelected: false,
                            DefaultSpecialRequest: {
                                PreferredSeat: item.PREFERRED_SEAT,
                                SpecialMeal: item.SPECIAL_MEAL
                            }
                        })
                        i++
                        resolve()
                    }
                    else{
                        resolve()
                    }
                })
            }
        },
        async totalAge(val){
            const nowdate = moment().format('YYYY-MM-DD')
            let result = ''
            if(val){
                result = await parseInt(moment(nowdate).diff(moment(val), 'years'))
            }
            return result
        },
        async loopExternal(data){
            let i = 0
            for (const item of data) {
                await new Promise(async resolve => {
                    const modifyItemData = {
                        travelerNo: moment().format("YYYYMMDD") + (i + 1),
                        PNR: null,
                        emp_ID: item.EX_ID,
                        fullname: `${item.EX_FNAME} ${item.EX_LNAME}`,
                        firstname: item.EX_FNAME,
                        lastname: item.EX_LNAME,
                        email: item.EX_EMAIL,
                        title: item.TITLE,
                        CreateBy: item.CBY,
                        mobile: item.EX_PHONE_NO,
                        birthdate: item.Birthday,
                        position: '',
                        group: '',
                        div: '',
                        age:  '',
                        dept: '',
                        section: '',
                        flights: null,
                        hotels: [],
                        passport: [],
                        visa: [],
                        travelExpense: null,
                        TravelerOID: null,
                        ParentsOfChild: item.ParentsOfChild === 1 ? true : false,
                        TravelCard: {
                            IsDelete: 0,
                            TravelCardOID: null
                        },
                        DefaultSpecialRequest: {
                            PreferredSeat: item.PREFERRED_SEAT,
                            SpecialMeal: item.SPECIAL_MEAL
                        },
                        CBYNAME: item.CBYNAME
                    }
                    if(await this.totalAge(item.Birthday) >= 12){
                        modifyItemData.age = 'Adult'
                    }else if(await this.totalAge(item.Birthday) < 12 && await this.totalAge(item.Birthday) >= 2){
                        modifyItemData.age = 'Child'
                    }else{
                        modifyItemData.age = 'Infant'
                    }
                    this.itemData.push(modifyItemData)
                    i++
                    resolve()
                })
            }
        },
        async getPassport(val) {
            await axios.post(`${this.$store.state.API_PATH}Passport/GetPassportByID?pEmployeeID=${val}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
        },
        addTraveler() {
            this.$store.state.LOADING = true
            if (this.typeTraveler === "Internal") {
                this.addInternal()
            } else {
                this.funcSelectedTraveler("getCreateTravelRequestExternal")
            }
        },
        async addInternal(){
            for(const item of this.selectedTraveler){
                await new Promise(async(resolve)=>{
                    if (item.CreateBy == null) {
                        await axios.post(`${this.$store.state.API_PATH}HRWS/LineApprovalCheck?val=${item.emp_ID}`, {}, {
                            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        }).then((la)=>{
                            if(la.data.Status){
                                const data = la.data.Result
                                item.lineApprover = data.Full_Name
                                resolve()
                            }
                            else{
                                resolve()
                            }
                        },()=>{
                            resolve()
                        })
                    }
                    else{
                        resolve()
                    }
                })
            }
            this.funcSelectedTraveler("getCreateTravelRequest")
        },
        async funcSelectedTraveler(funcName){
            const req = []
            const PROFILE = JSON.parse(localStorage.PROFILE)
            if (this.selectedTraveler.length > 0) {
                const FindParents = await this.selectedTraveler.filter(elm => elm.ParentsOfChild && elm.emp_ID[0] === 'E')
                if(FindParents && FindParents.length > 0){
                    let i = 0
                    for (const item of await FindParents) {
                        await new Promise((resolve) => {
                            if(this.items.filter(x => x.emp_ID === item.CreateBy).length === 0){
                                axios.post(this.$store.state.API_PATH + `UserProfile/GetParent?val=${item.CreateBy}`, {}, {
                                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                                }).then(async res => {
                                    if(res.data.Status && res.data.Result.length > 0){
                                        const modify = {
                                            travelerNo: moment().format("YYYYMMDD") + (i + 1),
                                            emp_ID: res.data.Result[0].EMPLOYEE_ID,
                                            fullname: `${res.data.Result[0].FIRSTNAME} ${res.data.Result[0].LASTNAME}`,
                                            firstname: res.data.Result[0].FIRSTNAME,
                                            lastname: res.data.Result[0].LASTNAME,
                                            email: res.data.Result[0].EMAIL_ID,
                                            title: res.data.Result[0].TITLE,
                                            mobile: res.data.Result[0].MOBILE_NO,
                                            position: res.data.Result[0].POSITION,
                                            group: res.data.Result[0].Groupdivision_ID,
                                            div: res.data.Result[0].DIVISION_ID,
                                            dept: res.data.Result[0].DEPARTMENT_ID,
                                            section: res.data.Result[0].SECTION_ID,
                                            TravelCard: {
                                                IsDelete: 0,
                                                TravelCardOID: null
                                            },
                                            isSelected: false,
                                            DefaultSpecialRequest: {
                                                PreferredSeat: item.PREFERRED_SEAT,
                                                SpecialMeal: item.SPECIAL_MEAL
                                            }
                                        }
                                        this.selectedTraveler.push(modify)
                                    }
                                })
                            }
                            i++
                            resolve()
                        })
                    }
                }
                await setTimeout(async () => {
                    const newTravelers = await this.selectedTraveler.filter(elm => !elm.TravelCard.TravelCardOID)
                    if (newTravelers != null && newTravelers.length > 0) {
                        await newTravelers.forEach(async elm => {
                            const modify = {
                                EMPLOYEE_ID: elm.emp_ID,
                                Status: 'Draft',
                                TravelRequestOID: this.RequestOID.TravelId,
                                PreferredSeat: elm.DefaultSpecialRequest.PreferredSeat,
                                SpecialMeal: elm.DefaultSpecialRequest.SpecialMeal,
                                TravelCard: elm.TravelCard,
                                CREATED_BY : PROFILE.EMPLOYEE_ID,
                                Passport: [{
                                    PassportOID: null,
                                }],
                                Visa: [{
                                    VisaOID: null
                                }],
                                SpecialRequest: await elm.DefaultSpecialRequest,
                            }
                            req.push(modify)
                        })
                        setTimeout(() => {
                            if(funcName === "getCreateTravelRequest"){
                                this.getCreateTravelRequest(req)
                            }
                            else{
                                this.getCreateTravelRequestExternal(req)
                            }
                        }, 500);
                    } else {
                        this.$emit("selectedTraveler", true)
                        this.$emit("dialogOpenOrClose", false)
                        this.$store.state.LOADING = false
                    }
                }, 500);
            }
        },
        getCreateTravelRequest(req){
            const app = this
            axios.post(app.$store.state.API_PATH + 'Traveler/CreateListTraveler', req, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async createRes => {
                const travelerReq = {
                    TravelCardOID: [],
                    TravelRequestOID: this.RequestOID.TravelId,
                }
                travelerReq.TravelCardOID = await createRes.data.Result.Result.map(data => data.travelCard)
                axios.post(app.$store.state.API_PATH + 'Traveler/GetAllTravelerByTravelRequestID', travelerReq, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(() => {
                    app.$emit("getTRLifeCycleByPermission")
                    app.$emit("selectedTraveler", true)
                    app.$emit("dialogOpenOrClose", false)
                    app.$store.state.LOADING = false
                })
            })
        },
        funcForSelectInternal(result, res){
            result.forEach(elm => {
                const filter = res.data.Result != null && res.data.Result.length > 0 ?
                res.data.Result.filter(data => data.emp_ID === elm.emp_ID)[0] : null
                elm.TravelCard.TravelCardOID = filter != null ? filter.travelCard : null
                elm.TravelerOID = filter.TravelerOID
                elm.emp_ID = filter.EMPLOYEE_ID
            })
        },
        getCreateTravelRequestExternal(req){
            const app = this
            axios.post(app.$store.state.API_PATH + 'Traveler/CreateListTraveler', req, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(() => {
                app.$emit("selectedTraveler", true)
                app.$emit("dialogOpenOrClose", false)
                app.$store.state.LOADING = false
            })
        },
        funcForSelectExternal(result, res){
            result.forEach(elm => {
                const filter = res.data.Result.Result != null && res.data.Result.Result.length > 0 ?
                res.data.Result.Result.filter(data => data.EMPLOYEE_ID === elm.emp_ID)[0] : null
                elm.TravelCard.TravelCardOID = filter != null ? filter.travelCard : null
                elm.TravelerOID = filter.TravelerOID
                elm.emp_ID = filter.EMPLOYEE_ID
            })
        },
        getToken() {
            axios.get(`${this.$store.state.API_PATH}Userprofile/getToken`, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.accessToken = res.data
            })
                .catch(err => {
                    this.$store.state.LOADING = false
                    console.log(err)
                })
        },
        rowClicked(item) {
            if (this.selectedTraveler.includes(item)) {
                this.selectedTraveler.splice(this.selectedTraveler.findIndex(e => e === item), 1)
                this.TravelerCount -= 1
            }
            else if(this.TravelerCount < 9 && this.selectedTraveler.filter(x => item.emp_ID === x.emp_ID).length === 0) {
                this.TravelerCount += 1
                this.selectedTraveler.push(item)
            }
        },
        close() {
            this.$emit("dialogOpenOrClose", false)
        },
        clearFilter() {
            this.Groupdivision_ID = ""
            this.DIVISION_ID = ""
            this.DEPARTMENT_ID = ""
            this.SECTION_ID = ""
            this.travelName = ""
            if (this.typeTraveler === "Internal") {
                this.getUserProfileByTraveler()
            }
        }
    },
    async mounted() {
        if(this.typeTraveler !== 'External'){
            this.headers.push(
                { text: 'Name', value: 'fullname', sortable: false },
                { text: 'Group', value: 'group', sortable: false },
                { text: 'Divition', value: 'div', sortable: false },
                { text: 'Department', value: 'dept', sortable: false },
                { text: 'Section', value: 'section', sortable: false })
            }else{
                this.headers.push(
                { text: 'Name', value: 'fullname', sortable: false },
                { text: 'Email', value: 'email', sortable: false },
                { text: 'Mobile', value: 'mobile', sortable: false },
                { text: 'Age', value: 'age', sortable: false },
                )
            }
        this.item = this.items.filter(x => this.typeTraveler === 'External' ? x.CreateBy != null : x.CreateBy == null)
        this.$store.state.LOADING = true
        await this.getUserProfileByTraveler()
        this.$store.state.LOADING = false

        this.getToken()
        if (sessionStorage.formReqID !== null && sessionStorage.formReqID !== undefined) {
            const parse = JSON.parse(sessionStorage.formReqID)
            this.RequestOID = parse
        }
        if (this.item !== undefined) {
                this.selectedTraveler = JSON.parse(JSON.stringify(this.item))
        }
    }
}
</script>
