<template>
    <v-container fluid fill-height v-if="!this.$store.state.LOADING">
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-row class="ma-2">
                        <v-col>
                            <v-row>
                                <v-col>
                                    <v-img :src="require('@/assets/image/logo_pttep.png')" style="width: 110px;" />
                                </v-col>
                                <v-col class="text-right">
                                    <v-img :src="require('@/assets/image/NewRS_PTTEPNAME.png')" style="width: 80%;" class="ml-auto" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8" lg="2" md="2" sm="3" style="display: flex; align-items: center;">
                                    <span class="mr-2" style="white-space: nowrap;">ที่ ปตท.สผ.</span>
                                    <v-text-field outlined dense hide-details v-model="docNo"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-center">
                                    {{ $store.state.dateNowThai }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <span>เรื่อง  ขอความอนุเคราะห์ออกหนังสือนำวีซ่า</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <span>เรียน  ปลัดกระทรวงการต่างประเทศ</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <table aria-label="">
                                        <tr hidden>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <td style="width: 100px;">สิ่งที่ส่งมาด้วย</td>
                                            <td>1. หนังสือรับรองการเป็นพนักงานของบริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน)</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 100px;"></td>
                                            <td>2. อนุมัติการเดินทาง {{ $store.state.docPassport.TRBudget }}</td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-10">
                                    <span>
                                        ด้วยบริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน) ได้อนุมัติให้พนักงาน จำนวน 1 ท่าน
                                        เดินทางไปปฏิบัติงานที่ประเทศ{{ $store.state.docPassport.country }} ระหว่างวันที่
                                        {{ $store.state.docPassport.dateStart }} - {{ $store.state.docPassport.dateEnd }} คือ
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2"></v-col>
                                <v-col>
                                    <table style="width: 100%" aria-label="">
                                        <tr hidden><th></th></tr>
                                        <tr>
                                            <td style="width: 50%"><u>ชื่อ นามสกุล</u></td>
                                            <td><u>ตำแหน่ง</u></td>
                                            <td><u>หนังสือเดินทางเลขที่</u></td>
                                        </tr>
                                        <tr v-for="(item, index) in $store.state.docPassport.documentTraveller" :key="index">
                                            <td>
                                                <span>{{ item.NameTH }}</span><br>
                                                <span>({{ item.NameEN }})</span>
                                            </td>
                                            <td>
                                                <span>{{ item.PosiTH }}</span><br>
                                                <span>({{ item.PosiEN }})</span>
                                            </td>
                                            <td>
                                                <v-text-field outlined dense hide-details v-model="$store.state.docPassport.passportNo"></v-text-field>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col cols="2"></v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-10">
                                    <span>
                                        จึงเรียนมาเพื่อโปรดอนุเคราะห์ออกหนังสือนำวีซ่า ให้แก่พนักงานดังกล่าว ข้างต้นด้วย จักเป็นพระคุณยิ่ง
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-center">ขอแสดงความนับถือ</v-col>
                            </v-row>
                            <v-row align="center" justify="center" no-gutters class="mt-8">
                                <v-col cols="12" lg="2" md="3" sm="5" class="d-flex justify-center">
                                    <v-text-field outlined dense hide-details v-model="$store.state.docPassport.nameBudget"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" no-gutters class="mt-2">
                                <v-col cols="12" lg="3" md="4" sm="6" class="d-flex justify-center">
                                    <v-text-field outlined dense hide-details v-model="$store.state.docPassport.posiBudget"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" no-gutters class="mt-2">
                                <v-col cols="12" lg="3" md="4" sm="6" class="d-flex justify-center">
                                    <v-text-field outlined dense hide-details v-model="$store.state.docPassport.posiOther"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-5">
                                <v-col cols="12" lg="3" md="3" sm="6">
                                    <v-text-field outlined dense hide-details v-model="$store.state.docPassport.nameBTT"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col cols="12" lg="3" md="3" sm="6">
                                    <v-text-field outlined dense hide-details v-model="$store.state.docPassport.posiBTT"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-img :src="require('@/assets/image/NewWeb_footerPTTEP.png')" style="width: 70%;" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn color="primary" elevation="0" @click="printPDF()">Print To PDF</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import pdfFonts from '@/assets/custom-font.js'
export default {
    data(){
        return{
            docNo: "1",
            TRNo: "",
            EMP_ID: "",
            Type: "",
        }
    },
    methods:{
        async loopNewTravel(newTravel){
            for(const item of this.$store.state.docPassport.documentTraveller){
                await new Promise((resolve)=>{
                    newTravel.push({
                        style: "font16",
                        margin: [0, 5, 0, 0],
                        alignment: "center",
                        columns: [
                            {
                                text: "",
                                width: 70
                            },
                            {
                                width: 150,
                                text: item.NameTH
                            },
                            {
                                text: item.PosiTH
                            },
                            {
                                text: this.$store.state.docPassport.passportNo
                            },
                        ]
                    })
                    newTravel.push({
                        style: "font16",
                        margin: [0, 5, 0, 0],
                        alignment: "center",
                        columns: [
                            {
                                text: "",
                                width: 70
                            },
                            {
                                width: 150,
                                text: `(${item.NameEN})`
                            },
                            {
                                text: `(${item.PosiEN})`
                            },
                            {
                                text: ""
                            },
                        ]
                    })
                    resolve()
                })
            }
        },
        async printPDF(){
            const newTravel = []
            await this.loopNewTravel(newTravel)
            pdfMake.vfs = pdfFonts.pdfMake.vfs
            pdfMake.fonts = { CordiaNew: { normal: "CordiaNew.ttf" } }
            const doc = {
                content:[
                    {
                        columns: [
                            {
                                image: this.$store.state.logo_pttep,
                                width: 80
                            },
                            {
                                text: "",
                                width: 90
                            },
                            {
                                image: this.$store.state.NewRS_PTTEPNAME,
                                width: 350,
                                alignment: 'right'
                            },
                        ]
                    },
                    {
                        text: `ที่ ปตท.สผ. ${this.docNo}`,
                        style: "font16",
                        margin: [0, 10, 0, 0]
                    },
                    {
                        text: this.$store.state.dateNowThai,
                        style: "font16",
                        alignment: "center",
                        margin: [0, 25, 0, 0]
                    },
                    {
                        text: "เรื่อง ขอความอนุเคราะห์ออกหนังสือนำวีซ่า",
                        style: "font16",
                        margin: [0, 15, 0, 0]
                    },
                    {
                        text: "เรียน ปลัดกระทรวงการต่างประเทศ",
                        style: "font16",
                        margin: [0, 8, 0, 0]
                    },
                    {
                        style: "font16",
                        margin: [0, 5, 0, 0],
                        columns: [
                            {
                                text: "สิ่งที่ส่งมาด้วย",
                                width: 70
                            },
                            {
                                text: "1. หนังสือรับรองการเป็นพนักงานของบริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน)"
                            }
                        ]
                    },
                    {
                        style: "font16",
                        margin: [0, 5, 0, 0],
                        columns: [
                            {
                                text: "",
                                width: 70
                            },
                            {
                                text: `2. อนุมัติการเดินทาง ${this.$store.state.docPassport.TRBudget}`
                            }
                        ]
                    },
                    {
                        style: "font16",
                        margin: [0, 20, 0, 0],
                        columns: [
                            {
                                text: "",
                                width: 70
                            },
                            {
                                text: `ด้วยบริษัท ปตท.สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน) ได้อนุมัติให้พนักงาน จำนวน 1 ท่าน เดินทาง`
                            }
                        ]
                    },
                    {
                        text: `ไปปฏิบัติงานที่ประเทศ${this.$store.state.docPassport.country}
                        ระหว่างวันที ${this.$store.state.docPassport.dateStart} - ${this.$store.state.docPassport.dateEnd}`,
                        style: "font16",
                    },
                    {
                        style: "font16",
                        margin: [0, 20, 0, 0],
                        alignment: "center",
                        columns: [
                            {
                                text: "",
                                width: 70
                            },
                            {
                                decoration: 'underline',
                                width: 150,
                                text: "ชื่อ นามสกุล"
                            },
                            {
                                decoration: 'underline',
                                text: "ตำแหน่ง"
                            },
                            {
                                decoration: 'underline',
                                text: "หนังสือเดินทางเลขที่"
                            },
                        ]
                    },
                    newTravel,
                    this.$store.state.docPassport.rptOffPssSign
                ],
                footer: { margin: [20, -10, 20, 10], image: this.$store.state.NewWeb_footerPTTEP, width: 555 },
                pageSize: "A4",
                styles: { font16: { alignment: 'left', fontSize: 16 } },
                defaultStyle: { font: 'CordiaNew' }
            }
            pdfMake.createPdf(doc).open()
            // pdfMake.createPdf(doc).download(`RptVisa_${this.$store.state.docPassport.nameTH}.pdf`)
        },
    },
    mounted(){
        if(this.$route.query.TRNo && this.$route.query.EMP_ID && this.$route.query.Type){
            this.TRNo = this.$route.query.TRNo
            this.EMP_ID = this.$route.query.EMP_ID
            this.Type = this.$route.query.Type
            this.$store.commit("docPassport/getDocPassport", {
                TRNo: this.TRNo,
                EMP_ID: this.EMP_ID,
                Type: this.Type,
            })
        }
    }
}
</script>
