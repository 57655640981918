<template>
    <div>
        <v-flex class="mb-3 font-W700">
            My Travel Profile
        </v-flex>
        <v-card elevation="0" title class="card" no-gutters>
            <v-card-title>
                My Profile
            </v-card-title>
            <v-card-text>
                <v-card elevation="0" color="#F8F9FA">
                    <v-card-title>
                        <strong>
                            Company Data
                        </strong>
                    </v-card-title>
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <strong>Employee ID</strong>
                                <br />
                                <span class="text-profile">{{ EMPLOYEE_ID }}</span>
                            </v-col>

                            <div class="text-height"></div>

                            <v-col cols="12" xs="6" md="2" sm="2">
                                <strong>Thai Title</strong>
                                <br />
                                <span class="text-profile">{{ TITLE_TH }}</span>
                            </v-col>

                            <v-col cols="12" xs="6" md="3" sm="3">
                                <strong>Thai Name</strong>
                                <br />
                                <span class="text-profile">{{ THAINAME }}</span>
                            </v-col>

                            <v-col cols="12" xs="6" md="7" sm="6">
                                <strong>Thai Position</strong>
                                <br />
                                <span class="text-profile">{{ POSITIONTHAI }}</span>
                            </v-col>

                            <div class="text-height"></div>

                            <v-col cols="12" xs="6" md="2" sm="2">
                                <strong>English Title</strong>
                                <br />
                                <span class="text-profile">{{ TITLE }}</span>
                            </v-col>

                            <v-col cols="12" xs="6" md="3" sm="3">
                                <strong>English Name</strong>
                                <br />
                                <span class="text-profile">{{ FIRSTNAME + " " + LASTNAME }}</span>
                            </v-col>

                            <v-col cols="12" xs="6" md="7" sm="6">
                                <strong>English Position</strong>
                                <br />
                                <span class="text-profile">{{ POSITION }}</span>
                            </v-col>

                            <div class="text-height"></div>

                            <v-col cols="12" xs="5" md="5" sm="5">
                                <strong>Email</strong>
                                <br />
                                <span class="text-profile">{{ EMAIL_ID }}</span>
                            </v-col>

                            <v-col cols="12" xs="7" md="7" sm="7">
                                <strong>Company</strong>
                                <br />
                                <span class="text-profile">PTT exploration and production Public Company Limited</span>
                            </v-col>

                            <div class="text-height"></div>

                            <v-col cols="12" xs="5" md="5" sm="5">
                                <strong>Department</strong>
                                <br />
                                <span class="text-profile">{{ (DEPARTMENT_ID ? DEPARTMENT_ID : "-") }}</span>
                            </v-col>

                            <v-col cols="12" xs="7" md="7" sm="7">
                                <strong>Division</strong>
                                <br />
                                <span class="text-profile">{{ (DIVISION_ID ? DIVISION_ID : "-") }}</span>
                            </v-col>

                            <div class="text-height"></div>

                            <v-col cols="12" xs="6" md="2" sm="2">
                                <strong>Joined Date</strong>
                                <br />
                                <span class="text-profile">{{ JOIN_DATE }}</span>
                            </v-col>

                            <v-col cols="12" xs="6" md="3" sm="3">
                                <strong>Residence</strong>
                                <br />
                                <span class="text-profile">-</span>
                            </v-col>

                            <v-col cols="12" xs="6" md="7" sm="6">
                                <strong>Office Phone No.</strong>
                                <br />
                                <span class="text-profile">-</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <br />
                <v-card elevation="0" color="#F8F9FA">
                    <v-card-title>
                        Mobile Phone Number
                    </v-card-title>

                    <v-card-text>
                        Country Code / Mobile No.
                        <div>
                            <v-row>
                                <v-col cols="12" md="2" sm="2" xs="2">
                                    <v-autocomplete :items="countryCode" v-model="MOBILE_CODE" dense outlined
                                        background-color="white" item-text="PhoneCode"
                                        item-value="PhoneCode">
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="5" sm="5" xs="5">
                                    <input type="text" class="my-text-field phone-number"
                                        v-model="MOBILE_NO"
                                        @input="(MOBILE_NO = validateNumberText(MOBILE_NO))"
                                        background-color="white" />
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-title>
                Personal Data
            </v-card-title>

            <v-card-text>
                <v-card elevation="0" color="#F8F9FA">
                    <v-card-title>
                        Earn Miles
                    </v-card-title>
                    <template v-if="EarnMiles != null">
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <span>
                                        R.O.P
                                    </span>
                                    <span style="float: right;">
                                        Thai Airways
                                    </span>
                                    <v-text-field outlined dense background-color="white"
                                        v-model="EarnMiles.filter(x => x.Name === 'Thai Airways' && x.IsActive === 1)[0].MemberID"></v-text-field>
                                    <span>
                                        Nok Fanclub
                                    </span>
                                    <span style="float: right;">
                                        Nok Air
                                    </span>
                                    <v-text-field outlined dense background-color="white"
                                        v-model="EarnMiles.filter(x => x.Name === 'Nok Air' && x.IsActive === 1)[0].MemberID"></v-text-field>
                                    <span>
                                        Big Membership
                                    </span>
                                    <span style="float: right;">
                                        Air Asia
                                    </span>
                                    <v-text-field outlined dense background-color="white"
                                        v-model="EarnMiles.filter(x => x.Name === 'Air Asia' && x.IsActive === 1)[0].MemberID"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-title>
                            Other Miles
                        </v-card-title>
                        <v-card-text>
                            <table style="width: 100%" aria-label="">
                                <tr hidden>
                                    <th></th>
                                </tr>
                                <tr v-for="(itemOM, index) in EarnMiles.filter(x => x.IsActive !== 1)" :key="index">
                                    <td style="width: 50px;">
                                        No. {{ (index + 1) }}
                                    </td>
                                    <td>
                                        Airline
                                        <v-autocomplete v-model="itemOM.Name" @change="SetAirlineByKey(itemOM.Name)"
                                            background-color="white"
                                            @input.native="GetAirlineByKey($event.srcElement.value)" :items="Airline"
                                            item-text="Name" item-value="Name" dense outlined></v-autocomplete>
                                    </td>
                                    <td>
                                        Member ID
                                        <v-text-field outlined dense v-model="itemOM.MemberID" background-color="white">
                                        </v-text-field>
                                    </td>
                                    <td style="width: 30px;">
                                        <v-btn color="red" x-small fab @click="RemoveOthmiles(itemOM)">
                                            <v-img :src="require('../assets/icons/Cancel.svg')" width="2" max-width="12">
                                            </v-img>
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <v-btn depressed @click="AddOthMiles()" v-if="$store.state.buttonPermit.menuConfig.Add">
                                            <v-img class="shrink mr-2" :src="require('../assets/icons/Add.svg')"
                                                width="22" />
                                            Add Mileage
                                        </v-btn>
                                    </td>
                                </tr>
                            </table>
                        </v-card-text>
                    </template>
                </v-card>
                <br />
                <v-card elevation="0" color="#F8F9FA">
                    <v-card-title>
                        Attached Files
                    </v-card-title>
                    <v-card-text>
                        <v-btn class="btn-primary"
                            @click="InputFile">
                            Upload File(s)
                        </v-btn>
                        <div class="h-space"></div>
                        <input type="file" counter multiple hide-details="" ref="input_file" style="display: none"
                        outlined show-size background-color="white"
                        accept="image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, .pdf,
                        application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        @change="AddAttachFile($event)" />
                        <br />
                        <v-row>
                            <template v-if="files.length > 0">
                                <v-col cols="3" v-for="(fileitem, index) in files" :key="'n' + index">
                                    <v-card class="card">
                                        <v-card-text class="bgcard">
                                            <v-row>
                                                <v-col cols="2">
                                                    <v-icon v-if="fileitem.name.split('.')
                                                    [fileitem.name.split('.').length - 1] == 'xlsx' || fileitem.name.split('.')
                                                    [fileitem.name.split('.').length - 1] == 'xls'" class="mt-3"
                                                        color="success">mdi-file-excel</v-icon>
                                                    <v-icon v-else-if="fileitem.name.split('.')
                                                    [fileitem.name.split('.').length - 1] == 'png'" class="mt-3"
                                                        color="error">mdi-file-png-box</v-icon>
                                                    <v-icon v-else-if="fileitem.name.split('.')
                                                    [fileitem.name.split('.').length - 1] == 'pdf'" class="mt-3"
                                                        color="error">mdi-file-pdf-box</v-icon>
                                                    <v-icon v-else-if="fileitem.name.split('.')
                                                    [fileitem.name.split('.').length - 1] == 'jpg'" class="mt-3"
                                                        color="error">mdi-file-jpg-box</v-icon>
                                                    <v-icon v-else-if="fileitem.name.split('.')
                                                    [fileitem.name.split('.').length - 1] == 'zip'" class="mt-3"
                                                        color="#EAC282">mdi-zip-box</v-icon>
                                                    <v-icon v-else-if="fileitem.name.split('.')
                                                    [fileitem.name.split('.').length - 1] == 'rar'" class="mt-3"
                                                        color="#EAC282">mdi-bookmark-box-multiple</v-icon>
                                                    <v-icon
                                                        v-else-if="fileitem.name.split('.')
                                                        [fileitem.name.split('.').length - 1] == 'docx' || fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'doc'"
                                                        class="mt-3" color="primary">mdi-file-word</v-icon>
                                                    <v-icon v-else-if="fileitem.name.split('.')
                                                    [fileitem.name.split('.').length - 1] == 'xml'" class="mt-3"
                                                        color="primary">mdi-xml</v-icon>
                                                    <v-icon v-else-if="fileitem.name.split('.')
                                                    [fileitem.name.split('.').length - 1] == 'txt'" class="mt-3"
                                                        color="#E3751C">mdi-file-document</v-icon>
                                                    <v-icon v-else-if="fileitem.name.split('.')
                                                    [fileitem.name.split('.').length - 1] == 'svg'" class="mt-3"
                                                        color="#E3751C">mdi-svg</v-icon>
                                                    <v-icon v-else class="mt-3">mdi-file-outline</v-icon>
                                                </v-col>
                                                <v-col cols="10" style="height: 55px;">
                                                    {{ fileitem.FileName }}
                                                    <v-icon style="float: right; cursor: pointer;"
                                                        @click="removeFilesUserfile(fileitem, 'NotSave')">mdi-close</v-icon>
                                                    <div class="mt-3">
                                                        {{ fileitem.name.length > 15 ? fileitem.name.split('.')[0].slice(0,
                                                            10) + '...' +
                                                            fileitem.name.split('.')[fileitem.name.split('.').length - 1] :
                                                            fileitem.name }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </template>
                            <template v-if="Getfiles.length > 0">
                                <v-col cols="3" v-for="(fileitem, index1) in Getfiles" :key="'o' + index1">
                                    <v-card class="card">
                                        <v-card-text class="bgcard">
                                            <v-row>
                                                <v-col cols="2" @click="openFileUserfile(fileitem)"
                                                    style="cursor: pointer;">
                                                    <v-icon
                                                        v-if="fileitem.FileName.split('.')[1] === 'xlsx' || fileitem.FileName.split('.')[1] === 'xls'"
                                                        class="mt-3" color="success">mdi-file-excel</v-icon>
                                                    <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'png'"
                                                        class="mt-3" color="error">mdi-file-png-box</v-icon>
                                                    <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'pdf'"
                                                        class="mt-3" color="error">mdi-file-pdf-box</v-icon>
                                                    <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'jpg'"
                                                        class="mt-3" color="error">
                                                        mdi-file-jpg-box</v-icon>
                                                    <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'zip'"
                                                        class="mt-3" color="#EAC282">mdi-zip-box</v-icon>
                                                    <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'rar'"
                                                        class="mt-3" color="#EAC282">
                                                        mdi-bookmark-box-multiple</v-icon>
                                                    <v-icon
                                                        v-else-if="fileitem.FileName.split('.')[1] === 'docx' || fileitem.FileName.split('.')[1] === 'doc'"
                                                        class="mt-3" color="primary">
                                                        mdi-file-word</v-icon>
                                                    <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'xml'"
                                                        class="mt-3" color="primary">mdi-xml</v-icon>
                                                    <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'txt'"
                                                        class="mt-3" color="#E3751C">
                                                        mdi-file-document</v-icon>
                                                    <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'svg'"
                                                        class="mt-3" color="#E3751C">mdi-svg</v-icon>
                                                    <v-icon v-else class="mt-3">mdi-file-outline</v-icon>
                                                </v-col>
                                                <v-col cols="10" style="height: 55px;">
                                                    <v-icon style="float: right; cursor: pointer;"
                                                        @click="DialogRemove = true, remove('PassportFile', 'alert', fileitem)">mdi-close</v-icon>
                                                    <div class="mt-3" @click="openFileUserfile(fileitem)"
                                                        style="cursor: pointer;">
                                                        {{
                                                            (fileitem.FileName.length > 15 ?
                                                            fileitem.FileName.slice(0, 10) + '...' +
                                                            fileitem.FileName.split('.')[1] : fileitem.FileName)
                                                        }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-card-text>
                </v-card>
                <br />
                <v-card elevation="0" color="#F8F9FA">
                    <v-card-title>
                        Traveler Preference Information
                    </v-card-title>
                    <v-card-text>
                        Preferred Seat
                        <br />
                        <v-row>
                            <v-col cols="12" sm="12" xs="12" md="6">
                                <v-select outlined dense v-model="Seat" item-value="Code" item-text="Description"
                                    background-color="white" :items="dataSeat"></v-select>
                            </v-col>
                        </v-row>
                        Special Meal
                        <br />
                        <v-row>
                            <v-col cols="12" sm="12" xs="12" md="6">
                                <v-select outlined dense v-model="SpecialMeal" item-value="MealCode" item-text="Description"
                                    background-color="white" :items="dataSpecialMeal"></v-select>
                            </v-col>
                        </v-row>
                        <p style="color:red">Note : The above are subject to available by the time and flight we make
                            reservation.</p>
                    </v-card-text>
                </v-card>
                 <br />
                <v-card elevation="0" color="#F8F9FA">
                    <v-card-title>
                        Request more baggage
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                            <span> Additional Baggage </span>
                            <br />
                                <v-select background-color="white" v-model="BAGGAGE" :items="Baggage"
                                    item-value="Code" item-text="Description" dense outlined></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <br />
                <v-card elevation="0" color="#F8F9FA">
                    <v-card-title>
                        Passport
                        <v-spacer></v-spacer>
                        <v-btn style="float: right" @click="ShowDialog('Passport', 'add', null)" v-if="$store.state.buttonPermit.menuConfig.Add">
                            <v-img class="shrink mr-2" :src="require('../assets/icons/Add.svg')" width="22" />
                            Add Passport
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-layout mt-3 class="overflow-x-auto scroll-custom" v-if="Passport != null && Passport.length > 0">
                            <v-flex>
                                <v-layout ma-2>
                                    <template v-for="(itemPassport, index) in Passport">
                                        <v-card :key="index" v-if="itemPassport.PassportType !== 'Required'"
                                            :class="index < (Passport.length - 1) ? 'mr-5' : null" min-width="362">
                                            <v-container>
                                                <v-layout>
                                                    <span style="font-size: 16px; white-space: nowrap;">
                                                        {{ itemPassport.PassportType }}
                                                    </span>
                                                    <v-spacer></v-spacer>
                                                    <v-btn icon small @click="ShowDialog('Passport', 'edit', itemPassport)">
                                                        <v-img :src="require('@/assets/icons/EditDetail.svg')"></v-img>
                                                    </v-btn>
                                                    &nbsp;
                                                    <v-btn icon small
                                                        @click="DialogRemove = true, remove('Passport', 'alert', itemPassport)">
                                                        <v-img :src="require('@/assets/icons/Delete.svg')"></v-img>
                                                    </v-btn>
                                                </v-layout>
                                            </v-container>
                                            <v-container>
                                                <v-divider></v-divider>
                                            </v-container>
                                            <v-container>
                                                <v-layout>
                                                    <v-flex mr-5>
                                                        <v-layout>
                                                            <v-flex>
                                                                <span style="white-space: nowrap;">
                                                                    Passport No.
                                                                </span>
                                                                <br>
                                                                <span style="white-space: nowrap;">
                                                                    <strong>{{ itemPassport.PassportNo }}</strong>
                                                                </span>
                                                            </v-flex>
                                                        </v-layout>
                                                        <v-layout>
                                                            <v-flex>
                                                                <span style="white-space: nowrap;">
                                                                    Expiry Date
                                                                </span>
                                                                <br>
                                                                <span style="white-space: nowrap;">
                                                                    <strong>{{ formatDate(itemPassport.ExpiryDate)
                                                                    }}</strong>
                                                                </span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                    <v-flex>
                                                        <v-layout>
                                                            <v-flex>
                                                                <span style="white-space: nowrap;">
                                                                    Request New Passport
                                                                </span>
                                                                <br>
                                                                <span style="white-space: nowrap;">
                                                                    <strong>{{ itemPassport.RequestNewPassport }}</strong>
                                                                </span>
                                                            </v-flex>
                                                        </v-layout>
                                                        <v-layout>
                                                            <v-flex>
                                                                <span style="white-space: nowrap;">
                                                                    Country
                                                                </span>
                                                                <br>
                                                                <span style="white-space: nowrap;">
                                                                    <strong>{{ itemPassport.Country }}</strong>
                                                                </span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </v-layout>
                                            </v-container>
                                        </v-card>
                                    </template>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-layout v-else>
                            <v-flex>
                                <h3 style="text-align:center;">Empty Data</h3>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
                <br />
                <v-card elevation="0" color="#F8F9FA">
                    <v-card-title>
                        Visa
                        <v-spacer></v-spacer>
                        <v-btn @click="ShowDialog('Visa', 'add', null)" style="float: right" v-if="$store.state.buttonPermit.menuConfig.Add">
                            <v-img class="shrink mr-2" :src="require('@/assets/icons/Add.svg')" width="22" />
                            Add Visa
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-layout mt-3 class="overflow-x-auto scroll-custom" v-if="Visa != null && Visa.length > 0">
                            <v-flex>
                                <v-layout ma-2>
                                    <template v-for="(itemVisa, index) in Visa">
                                        <v-card :key="index" v-if="itemVisa.VisaType !== 'Required'"
                                            :class="index < (Visa.length - 1) ? 'mr-5' : null" min-width="362">
                                            <v-container>
                                                <v-layout>
                                                    <span style="font-size: 16px; white-space: nowrap;">
                                                        {{ itemVisa.VisaType }}
                                                    </span>
                                                    <v-spacer></v-spacer>
                                                    <v-btn icon small @click="ShowDialog('Visa', 'edit', itemVisa)">
                                                        <v-img :src="require('@/assets/icons/EditDetail.svg')">
                                                        </v-img>
                                                    </v-btn>
                                                    &nbsp;
                                                    <v-btn icon small
                                                        @click="DialogRemove = true, remove('Visa', 'alert', itemVisa)">
                                                        <v-img :src="require('@/assets/icons/Delete.svg')">
                                                        </v-img>
                                                    </v-btn>
                                                </v-layout>
                                            </v-container>
                                            <v-container>
                                                <v-divider></v-divider>
                                            </v-container>
                                            <v-container>
                                                <v-layout>
                                                    <v-flex mr-5>
                                                        <v-layout>
                                                            <v-flex>
                                                                <span style="white-space: nowrap;">
                                                                    Visa No.
                                                                </span>
                                                                <br>
                                                                <span style="white-space: nowrap;">
                                                                    <strong>{{ itemVisa.VisaNo }}</strong>
                                                                </span>
                                                            </v-flex>
                                                        </v-layout>
                                                        <v-layout>
                                                            <v-flex>
                                                                <span style="white-space: nowrap;">
                                                                    Issue Date
                                                                </span>
                                                                <br>
                                                                <span style="white-space: nowrap;">
                                                                    <strong>{{ formatDate(itemVisa.IssueDate) }}</strong>
                                                                </span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                    <v-flex>
                                                        <v-layout>
                                                            <v-flex>
                                                                <span style="white-space: nowrap;">
                                                                    Country
                                                                </span>
                                                                <br>
                                                                <span style="white-space: nowrap;">
                                                                    <strong>{{ itemVisa.Country }}</strong>
                                                                </span>
                                                            </v-flex>
                                                        </v-layout>
                                                        <v-layout>
                                                            <v-flex>
                                                                <span style="white-space: nowrap;">
                                                                    Expiry Date
                                                                </span>
                                                                <br>
                                                                <span style="white-space: nowrap;">
                                                                    <strong>{{ formatDate(itemVisa.ExpiryDate) }}</strong>
                                                                </span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </v-layout>
                                            </v-container>
                                        </v-card>
                                    </template>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-layout v-else>
                            <v-flex>
                                <h3 style="text-align:center;">Empty Data</h3>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-text>
                <hr />
                <br />
                <v-row>
                    <v-col class="text-center">
                        <v-btn outlined color="#00AEEF" large width="130">
                            Cancel
                        </v-btn>
                        &emsp;
                        <v-btn color="#00AEEF" large style="color:white" width="130" @click="saveProfile()">
                            Save
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="DialogPassport" width="800">
            <Passport :valPass="senddata" :key="componentKey" @PassportUpdate="Passport = $event"
                @DialogPassport="DialogPassport = $event" />
        </v-dialog>
        <v-dialog v-model="DialogVisa" width="800">
            <Visa @VisaUpdate="Visa = $event" @DialogVisa="DialogVisa = $event" :valVisa="VisaData" :key="componentKey">
            </Visa>
        </v-dialog>
        <v-dialog v-model="DialogRemove" width="500">
            <v-card>
                <v-card-title>
                    Remove
                </v-card-title>
                <v-card-text>
                    <template v-if="FileFocus">
                        would you like to remove '{{ FileFocus.FileName }}' ?
                    </template>
                    <template v-else>
                        would you like to remove your passport account?
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small text @click="DialogRemove = false">Cancel</v-btn>
                    <v-btn small color="error" @click="remove(typeFocus, 'remove', null)">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import Passport from '@/components/Passport.vue'
import Swal from 'sweetalert2'
import moment from 'moment'
import Visa from './Visa.vue'
export default {
    data: () => ({
        noPreference: "No Preference",
        Baggage: [],
        XLSX: null,
        sizes: ['Bytes', 'KB', 'MB'],
        EarnMiles: null,
        PassportFocus: null,
        VisaFocus: null,
        FileFocus: null,
        typeFocus: null,
        files: [],
        Getfiles: [],
        file: null,
        DEPARTMENT_ID: "",
        DIVISION_ID: "",
        DialogPassport: false,
        DialogVisa: false,
        DialogRemove: false,
        dataSeat: [
            { Code: "", Description: "No Preference" },
            { Code: "A", Description: "Aisle seat" },
            { Code: "W", Description: "Window seat" },
        ],
        dataSpecialMeal: [],
        Airline: [],
        Seat: "",
        SpecialMeal: "",
        LOCATION: "",
        ORGUNIT: "",
        MOBILE_CODE: "",
        MOBILE_NO: "",
        EMAIL_ID: "",
        POSITION: "",
        FIRSTNAME: "",
        LASTNAME: "",
        TITLE: "",
        POSITIONTHAI: "",
        SEX: "",
        EMPLOYEE_ID: "",
        BAGGAGE: "",
        TITLE_TH: "",
        THAINAME: "",
        JOIN_DATE: "",
        countryCode: [],
        componentKey: 0,
        visaFile: [],
        pstFile: [],
        Passport: null,
        Visa: null,
        senddata: null,
        VisaData: null,
        filesize: 0
    }),
    components: {
        Passport,
        Visa
    },
    watch: {
    },
    methods: {
        GetPhoneCode(){
           this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}CountryCode/GetPhoneCode`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    console.log(res.data.Result);
                   this.countryCode = res.data.Result
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        validateNumberText(val) {
            return val.replace(/\D/g, '');
        },
        InputFile(){
            this.$refs.input_file.click()
        },
        openFileUserfile(val) {
            window.open(val.FilePath)
        },
        removeFilesUserfile(val, key) {
            if (key === 'NotSave') {
                this.files = this.files.filter(x => x.name !== val.name)
                this.filesize -= val.size
            } else if (key === 'Saved') {
                const formData = new FormData();
                for (let i = 0; i < this.files.length; i++) {
                    formData.append('UserProfile', this.files[0])
                }
                const files = formData
                axios.post(`${this.$store.state.API_PATH}FileUpload/RemoveFile?FileOID=`+
                `${this.EMPLOYEE_ID}|${val.FilePath.split('?')[0]}|${val.FileOID}|UserProfile|${val.FileName}`, files,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(res => {
                        this.Getfiles = this.Getfiles.filter(x => x.FileOID !== val.FileOID)
                    })
            }
        },
        SetAirlineByKey(val) {
            return this.Airline.filter(x => x.Name === val)
        },
        GetAirlineByKey(val) {
            axios.post(`${this.$store.state.API_PATH}EarnMiles/GetAirlineByKey?val=${val}&Language=en`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.Airline = res.data
                this.Airline.push(...this.EarnMiles.filter(x => x.IsActive !== 1))

            })
        },
        remove(type, processing, val) {
            this.typeFocus = type
            if (type === 'Passport' || type === 'Visa') {
                this.isPassportAndVisa(type, processing, val)
            } else if (type === 'PassportFile') {
                if (processing === 'alert') {
                    this.FileFocus = val
                }
                if (processing === 'remove') {
                    this.removeFilesUserfile(this.FileFocus, 'Saved')
                    this.DialogRemove = false
                }
            }
        },
        isPassportAndVisa(type, processing, val) {
            if (processing === 'alert') {
                if (type === 'Passport') {
                    this.PassportFocus = val
                }
                else {
                    this.VisaFocus = val
                }
            }
            if (processing === 'remove') {
                if (type === 'Passport') {
                    this.ShowRemove('Passport', this.PassportFocus)
                }
                else {
                    this.ShowRemove('Visa', this.VisaFocus)
                }
                this.DialogRemove = false
            }
        },
        forceRerender() {
            this.componentKey += 1;
        },
        AddAttachFile(event) {
            this.files.push(...event.target.files)
        },
        ShowDialog(type, val, pItem) {
            this.forceRerender()
            if (type === "Passport" || type === "Visa") {
                if (type === "Passport") {
                    this.DialogPassport = true
                    this.senddata = (val === "edit" ? pItem : null)
                }
                else {
                    this.DialogVisa = true
                    this.VisaData = (val === "edit" ? pItem : null)
                }
            }
        },
        ShowRemove(type, val) {
            if (type === 'Passport') {
                axios.post(`${this.$store.state.API_PATH}Passport/RemovePassport`, val, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    if (res.data != null && res.data.Status) {
                        this.Passport = res.data.Result
                    }
                })
            } else if (type === 'Visa') {
                axios.post(`${this.$store.state.API_PATH}Visa/RemoveVisa`, val, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    if (res.data != null && res.data.Status) {
                        this.Visa = res.data.Result
                    }
                })
            }
        },
        GetFile(val) {
            axios.post(`${this.$store.state.API_PATH}FileUpload/GetFile?ID=${this.EMPLOYEE_ID}|${val}`).then(res => {
                this.Getfiles = res.data.Result
            })
        },
        saveProfile() {
            const formData = new FormData()
            for (let i = 0; i < this.files.length; i++) {
                formData.append('UserProfile', this.files[i])
            }
            this.$store.state.LOADING = true
            const data = JSON.parse(localStorage.PROFILE)
            let JOIN_DATE = null
            if (data.JOIN_DATE) {
                JOIN_DATE = `'${data.JOIN_DATE}'`
            }
            else if (data.HIREDATE) {
                JOIN_DATE = `'${data.HIREDATE}'`
            }

            const files = formData
            axios.post(`${this.$store.state.API_PATH}FileUpload/Upload?ID=` + this.EMPLOYEE_ID, files,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            console.log(this.BAGGAGE);
            axios.post(`${this.$store.state.API_PATH}UserProfile/InsertUserProfile`, {
                EMPLOYEE_ID: this.EMPLOYEE_ID,
                TITLE_TH: this.TITLE_TH,
                THAINAME: this.THAINAME,
                POSITIONTHAI: this.POSITIONTHAI,
                TITLE: this.TITLE,
                FIRSTNAME: this.FIRSTNAME,
                LASTNAME: this.LASTNAME,
                SEX: this.SEX,
                LOCATION: this.LOCATION,
                ORGUNIT: this.ORGUNIT,
                BAGGAGE: this.BAGGAGE,
                POSITION: this.POSITION,
                EMAIL_ID: this.EMAIL_ID,
                // MOBILE_NO: this.MOBILE_NO.indexOf('+') === -1 ? `${this.MOBILE_CODE} ${this.MOBILE_NO}` : this.MOBILE_NO,
                MOBILE_CODE: this.MOBILE_CODE,
                MOBILE_NO: this.MOBILE_NO,
                Groupdivision_ID: data.Groupdivision_ID,
                DIVISION_ID: data.DIVISION_ID,
                DEPARTMENT_ID: data.DEPARTMENT_ID,
                SECTION_ID: data.SECTION_ID,
                JOIN_DATE: JOIN_DATE,
                PREFERRED_SEAT: this.Seat,
                SPECIAL_MEAL: this.SpecialMeal,
                STATUS: data.STATUS,
                EarnMiles: this.EarnMiles,
                formFiles: formData
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Success',
                    }).then(() => {
                        axios.post(`${this.$store.state.API_PATH}Login/LoginByEmpCode`, {
                            EMPLOYEE_ID: this.EMPLOYEE_ID
                        }, {
                            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        }).then(res2 => {
                            if (res2.data.Status) {
                                this.$store.state.LOADING = false
                                localStorage.PROFILE = JSON.stringify(res2.data.Result)
                                setTimeout(() => {
                                    window.location = window.location.href
                                }, 100)
                            }
                        }).catch(err => {
                            this.$store.state.LOADING = false
                            console.log(err)
                        })
                    })
                }
            }).catch(err => {
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        AddOthMiles() {
            this.EarnMiles.push(
                {
                    no: this.EarnMiles.length + 1,
                    EarnMileOID: null,
                    EMPLOYEE_ID: this.EMPLOYEE_ID,
                    Airline: null,
                    MemberID: null
                }
            );
        },
        RemoveOthmiles(val) {
            if (this.EarnMiles.length > 1) {
                if (val.EarnMileOID !== null) {
                    axios.post(`${this.$store.state.API_PATH}EarnMiles/RemoveEarnMiles?EarnMileOID=${val.EarnMileOID}&EMPLOYEE_ID=${this.EMPLOYEE_ID}`, {}, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    }).then(res => {
                        this.EarnMiles = res.data.Result
                    })
                } else {
                    this.EarnMiles = this.EarnMiles.filter(x => x.no !== val.no)
                }
            }
        },
        ShowVisa() {
            this.DialogVisa = true
        },
        Addpstfiles() {
            this.pstFile.push(this.$refs.filepst.files[0].name)
        },
        Addvisafiles() {
            this.visaFile.push(this.$refs.filevisa.files[0].name)
        },
        getSpecialMeal() {
            axios.post(`${this.$store.state.API_PATH}SpecialMeal/GetSpecialMeal`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
                .then(res => {
                    if (res.data.Status) {
                        this.dataSpecialMeal = res.data.Result
                        this.dataSpecialMeal.unshift({ Description: "No Preferred", MealCode: "" })
                    }
                })
                .catch(err => err)
        },
        getEarnMiles() {
            return axios.post(`${this.$store.state.API_PATH}EarnMiles/GetEarnMiles?val=${this.EMPLOYEE_ID}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                return res.data.Result.length === 0 ? null : res.data.Result
            })
        },
        async getPassportByID() {
            let Result = null
            await axios.post(`${this.$store.state.API_PATH}Passport/GetPassportByID?pEmployeeID=${this.EMPLOYEE_ID}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then((res) => {
                    Result = res.data.Result
                })

            return Result
        },
        async getVisaByID() {
            let Result = null
            await axios.post(`${this.$store.state.API_PATH}Visa/GetVisa?val=${this.EMPLOYEE_ID}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                Result = res.data.Result
            })
            return Result
        },
        formatDate(val) {
            return moment(val).format('DD MMMM YYYY')
        },
        getEMPByID(val) {
            return axios.post(`${this.$store.state.API_PATH}UserProfile/GetUserProfileByID?val=${val}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
        },
    },
    async mounted() {
        this.Baggage = [
            { Code: "", Description: this.noPreference },
            { Code: "10", Description: "10 KG" },
            { Code: "20", Description: "20 KG" }
        ]
        this.getSpecialMeal()
        this.GetPhoneCode()
        if (localStorage.PROFILE) {
            let PROFILE = JSON.parse(localStorage.PROFILE)
            await this.getEMPByID(PROFILE.EMPLOYEE_ID).then(async res => {
                PROFILE = res.data.Result
                this.EMPLOYEE_ID = PROFILE.EMPLOYEE_ID
                this.TITLE_TH = PROFILE.TITLE_TH
                this.THAINAME = PROFILE.THAINAME
                this.BAGGAGE = PROFILE.BAGGAGE
                this.SEX = PROFILE.SEX
                this.POSITIONTHAI = PROFILE.POSITIONTHAI
                this.TITLE = PROFILE.TITLE
                this.FIRSTNAME = PROFILE.FIRSTNAME
                this.LASTNAME = PROFILE.LASTNAME
                this.LOCATION = PROFILE.LOCATION
                this.ORGUNIT = PROFILE.ORGUNIT
                this.POSITION = PROFILE.POSITION
                this.EMAIL_ID = PROFILE.EMAIL_ID
                this.MOBILE_CODE = PROFILE.MOBILE_CODE
                this.MOBILE_NO = PROFILE.MOBILE_NO
                this.DEPARTMENT_ID = PROFILE.DEPARTMENT_ID
                this.DIVISION_ID = PROFILE.DIVISION_ID
                this.Seat = PROFILE.PREFERRED_SEAT === null ? '' : PROFILE.PREFERRED_SEAT
                this.SpecialMeal = PROFILE.SPECIAL_MEAL === null ? '' : PROFILE.SPECIAL_MEAL
                this.JOIN_DATE = PROFILE.JOIN_DATE ? moment(new Date(PROFILE.JOIN_DATE)).format("DD/MM/YYYY") : moment(PROFILE.HIREDATE).format("DD/MM/YYYY")
                this.EarnMiles = await this.getEarnMiles()
                this.GetFile('UserProfile');
                this.Airline.push(...this.EarnMiles.filter(x => x.IsActive !== 1))
                this.Passport = await this.getPassportByID()
                this.Visa = await this.getVisaByID()
            })
        }
    }
}
</script>

<style scoped>
.btnFile {
    border: 1px dashed gray;
    border-radius: 10px;
    padding: 0px;
    cursor: pointer;
    background-color: white;
}

.font-W700 {
    font-weight: 700;
}

.text-profile {
    color: black;
    font-weight: 500;
}

.text-height {
    height: 60px;
}

.icon {
    font-size: 50px !important;
}

.btn-table {
    background: rgba(0, 174, 239, 0.1) !important;
}

.scroll-custom::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.scroll-custom::-webkit-scrollbar-track {
    background: #C8D6ED;
    border-radius: 5px;
}

.scroll-custom::-webkit-scrollbar-thumb {
    background: #00AEEF;
    border-radius: 5px;
}</style>
