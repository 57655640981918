<template>
        <div>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <span>
                        <span class="font-W700" style="font-size: 1em;">Business Travel Case</span>
                    </span>
                </v-col>
            </v-row>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row gutters align="center" justify="center">
                                <v-col sm="4" lg="4" cols="8">
                                    BTT ID
                                    <v-text-field outlined dense background-color="white"
                                    v-model="BTTId" @keypress.enter="getBTTDataBySearch()"></v-text-field>
                                </v-col>
                                <v-col sm="4" lg="4" cols="8">
                                    BTT Name
                                    <v-text-field outlined dense background-color="white"
                                    v-model="BTTName" @keypress.enter="getBTTDataBySearch()"></v-text-field>
                                </v-col>
                                <v-col sm="4" lg="4" cols="8">
                                    BudgetHolder
                                    <v-text-field outlined dense background-color="white"
                                    v-model="BudgetHolder" @keypress.enter="getBTTDataBySearch()"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters align="center" justify="center">
                                <v-col md="auto" sm="auto" >
                                    <v-btn color="primary"
                                      @click="getBTTDataBySearch()" :loading="searchbtnLoading"
                                      elevation="0"
                                      class="mb-lg-0 mb-md-0 mb-sm-0 mb-6">Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row no-gutters align="center">
                                <v-col class="text-lg-right text-sm-right text-center">
                                    <v-btn class="btn-table mr-2" elevation="0" @click="deleteBusinessTravel()" v-if="$store.state.buttonPermit.menuConfig.Delete">
                                        <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1"></v-img>
                                        Delete
                                    </v-btn>
                                    <v-btn class="btn-table mr-2" elevation="0" @click="resetInfo()">
                                        <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1"></v-img>
                                        Reset
                                    </v-btn>
                                    <v-btn class="btn-table" elevation="0" @click="actionBusinessTravel('Add')" v-if="$store.state.buttonPermit.menuConfig.Add">
                                        <v-img :src="require('@/assets/icons/Add_trans.svg')" class="mr-1"></v-img>
                                        Add
                                    </v-btn>
                                    <v-dialog v-if="dialogAddOrEditBusinessTravelCase" v-model="dialogAddOrEditBusinessTravelCase" persistent
                                        width="600px">
                                        <v-card elevation="0" title color="primary" class="pt-1 card">
                                            <v-card>
                                                <v-card-text>
                                                    <v-card class="card-detail" title elevation="0">
                                                        <v-card-title>
                                                            <v-row no-gutters>
                                                                <v-col class="text-center">
                                                                    {{ AddOrEdit }} Business Travel Case
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    BTT ID
                                                                    <v-text-field outlined dense background-color="white" ref="BTTId" :rules="rules.BTTId"
                                                                    v-model="BTTId"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    BTT Name
                                                                    <v-text-field outlined dense background-color="white" ref="BTTName" :rules="rules.BTTName"
                                                                    v-model="BTTName"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Budget Holder
                                                                    <v-autocomplete  hide-details background-color="white"
                                                                                    v-model="BudgetHolder" :items="itemBudgetHolder" item-text="BudgetHolder"
                                                                                     item-value="BudgetHolder" ref="BudgetHolder"
                                                                                     :rules="rules.BudgetHolder" dense outlined @input.native="getBudgetHolder($event)">
                                                                    </v-autocomplete>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col class="text-center">
                                                            <v-btn outlined color="primary" class="mr-1"
                                                            @click="closeAddOrEditBusinessTra()">Close</v-btn>
                                                            <v-btn color="primary" class="ml-1" v-if="AddOrEdit === 'Add'"
                                                            @click="addBTTData()">Save</v-btn>
                                                            <v-btn color="primary" class="ml-1" v-else
                                                            @click="updateBTTData()">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <br>
                            <v-card elevation="0">
                                <v-data-table :headers="headerBusiness" :items="itemBTTData" class="elevation-1" item-key="ID"
                                @click:row="rowClicked" show-select v-model="selectedItemBusinessCase" checkbox-color="primary"
                                mobile-breakpoint style="white-space: nowrap" hide-default-footer height="45vh"
                                @page-count="paageCountBusiTra = $event" :page.sync="page" :items-per-page="itemPage" @load="onPagination($event)">
                                    <template v-slot:[`item.no`]="{ index }">
                                        {{ index + 1 }}
                                    </template>
                                </v-data-table>
                            </v-card>
                            <v-row class="mt-3 hidden-xs-only" no-gutters>
                                <v-col class="d-flex" cols="12">
                                    <span class="mr-3 mt-3">Show</span>
                                    <v-select outlined dense :items="itemsPerPageBusiness" item-text="text" item-value="value"
                                        v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                    <span class="ml-3 mt-3">items per page</span>
                                    <v-pagination v-model="page" :length="paageCountBusiTra" :total-visible="5" class="pagination-custom"
                                        style="width: 60%;"></v-pagination>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                                <v-col class="d-flex" cols="12">
                                    <span class="mr-3 mt-3">Show</span>
                                    <v-select outlined dense :items="itemsPerPageBusiness" item-text="text" item-value="value"
                                        v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                    <span class="ml-3 mt-3">items per page</span>
                                </v-col>
                                <v-col>
                                    <v-pagination v-model="page" :length="paageCountBusiTra" :total-visible="5" class="pagination-custom">
                                    </v-pagination>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="DialogRemove" width="500">
            <v-card>
                <v-card-title>
                    Remove
                </v-card-title>
                <v-card-text>
                    <template v-if="FileFocus">
                        would you like to remove '{{ FileFocus.FilePath.split('\\')[FileFocus.FilePath.split('\\').length - 1] }}' ?
                    </template>
                    <template v-else>
                        would you like to remove your passport account?
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small text @click="DialogRemove = false">Cancel</v-btn>
                    <v-btn small color="error" @click="remove(typeFocus, 'remove', null)">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </div>
</template>
<script>
import Swal from 'sweetalert2'
import axios from 'axios';
export default {
    data(){
        return{
            searchbtnLoading: false,
            FileFocus: null,
            typeFocus: null,
            DialogRemove: false,
            files: [],
            Bedfile: [],
            filesize: 0,
            BTTId: "",
            BTTName: "",
            BudgetHolder: "",
            AddOrEdit: "",
            dialogAddOrEditBusinessTravelCase: false,
            TEXT_SEARCH: "",
            itemPage: 10,
            page: 1,
            paageCountBusiTra: 2,
            headerBusiness: [
                { text: 'No.', value: 'no', sortable: false },
                { text: 'BTT Id', value: 'BTTId', sortable: false },
                { text: 'BTT Name', value: 'BTTName', sortable: false },
                { text: 'Budget Holder', value: 'BudgetHolder', sortable: false },
            ],
            itemsPerPageBusiness: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemBTTData: [],
            itemBudgetHolder: [],
            selectedItemBusinessCase: [],
            requireField: "This field is required",
            rules:{
                BTTId: [(val) => (val || "").length > 0 || this.requireField],
                BTTName: [(val) => (val || "").length > 0 || this.requireField],
                BudgetHolder: [(val) => (val || "").length > 0 || this.requireField],
            }
        }
    },
    methods:{
        remove(type, processing, val) {
            this.typeFocus = type
            if (type === 'BedTypeFile') {
                if (processing === 'alert') {
                    this.FileFocus = val
                }
                if (processing === 'remove') {
                    this.removeFiles(this.FileFocus, 'Saved')
                    this.DialogRemove = false
                }
            }
        },
        checkvalidate(){
            let result = false
            if (!this.$refs.BTTId.validate()) {
                this.$refs.BTTId.validate(true)
                this.$refs.BTTId.focus()
                result = true
            }
            if (!this.$refs.BTTName.validate()) {
                this.$refs.BTTName.validate(true)
                this.$refs.BTTName.focus()
                result = true
            }
            if (!this.$refs.BudgetHolder.validate()) {
                this.$refs.BudgetHolder.validate(true)
                this.$refs.BudgetHolder.focus()
                result = true
            }
            return result
        },
        updateBTTData(){
            if(!this.checkvalidate()){
                this.dialogAddOrEditBusinessTravelCase = false
                const PROFILE = JSON.parse(localStorage.PROFILE)
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}BusinessTravel/UpdateBusinessTravel`, {
                    ID: this.ID,
                    BTTId: this.BTTId,
                    BTTName: this.BTTName,
                    BudgetHolder: this.BudgetHolder,
                    Uby: PROFILE.EMPLOYEE_ID
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.SwalAlert(res)
                }).catch(() => {
                    this.$store.state.LOADING = false
                })
            }
        },
        SwalAlert(res){
            if(res.data.Status){
                Swal.fire({
                    icon: 'success',
                    text: 'Success',
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.closeAddOrEditBusinessTra()
                    this.getBTTData()
                }, () => {})
            }
            else{
                Swal.fire({
                    icon: 'error',
                    text: res.data.ErrorMessage,
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.dialogAddExternal = true
                }, () => { })
            }
        },
        closeAddOrEditBusinessTra(){
            this.dialogAddOrEditBusinessTravelCase = false
            this.BTTId = ""
            this.BTTName = ""
            this.BudgetHolder = ""
            this.AddOrEdit = ""
        },
        addBTTData(){
            if(!this.checkvalidate()){
                this.dialogAddOrEditBusinessTravelCase = false
                const PROFILE = JSON.parse(localStorage.PROFILE)
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}BusinessTravel/InsertBusinessTravel`, {
                    BTTId: this.BTTId,
                    BTTName: this.BTTName,
                    BudgetHolder: this.BudgetHolder,
                    Cby: PROFILE.EMPLOYEE_ID
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.SwalAlert(res)
                }).catch(() => {
                    this.$store.state.LOADING = false
                })
            }
        },
        actionBusinessTravel(AddOrEdit){
            this.AddOrEdit = AddOrEdit
            this.dialogAddOrEditBusinessTravelCase = true
        },
        resetInfo(){
            this.selectedItemBusinessCase = []
            this.TEXT_SEARCH = ""
            this.getBTTData()
        },
        deleteBusinessTravel(){
            if(this.selectedItemBusinessCase.length > 0){
                Swal.fire({
                    icon: 'warning',
                    text: 'would you like to to delete?',
                    confirmButtonText: 'Confirm',
                    showCancelButton: true,
                    confirmButtonColor: '#00AEEF'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.state.LOADING = true
                        axios.post(this.$store.state.API_PATH + 'BusinessTravel/DeleteBusinessTravel', {
                            selectedDelItem: this.selectedItemBusinessCase
                        }, {
                            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        })
                        .then(res => {
                            this.$store.state.LOADING = false
                            if (res.data.Status) {
                                Swal.fire({
                                    icon: 'success',
                                    confirmButtonColor: '#00AEEF',
                                    text: 'Success',
                                }).then(() => {
                                    this.selectedItemBusinessCase = []
                                    this.getBTTData()
                                },()=>{})
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    text: res.data.ErrorMessage,
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                },()=>{})
                            }
                        })
                        .catch(() => {
                            this.$store.state.LOADING = false
                        })
                    }
                })
            }
        },
        getBTTData(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}BusinessTravel/GetBusinessTravel`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    console.log(res.data.Result);
                    this.itemBTTData = res.data.Result
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        getBTTDataBySearch(){
                this.searchbtnLoading = true
                setTimeout(() => {
                    axios.post(this.$store.state.API_PATH + 'BusinessTravel/GetBusinessTravelBySearch', {
                        BTTId: this.BTTId,
                        BTTName: this.BTTName,
                        BudgetHolder: this.BudgetHolder,
                    }, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    }).then(res => {
                        this.$store.state.LOADING = false
                    if (res.data.Status) {
                        console.log(res.data.Result);
                        this.itemBTTData = res.data.Result
                        this.searchbtnLoading = false
                    } else {
                        this.searchbtnLoading = false
                    }
                    }).catch(() => {
                        this.$store.state.LOADING = false
                        this.searchbtnLoading = false
                    })
                }, 1000);
            },
        async getBudgetHolder(event){
            this.$store.state.LOADING = true
            await axios.get(`${this.$store.state.API_PATH}BusinessTravel/GetBudgetHolder?val=${event.target.value}`, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.itemBudgetHolder = res.data.Result
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        rowClicked(item){
            if(this.$store.state.buttonPermit.menuConfig.Update){
                this.AddOrEdit = "Edit";
                this.ID = item.ID;
                this.BTTId = item.BTTId;
                this.BTTName = item.BTTName;
                this.BudgetHolder = item.BudgetHolder;
                this.dialogAddOrEditBusinessTravelCase = true;
            }
        },
    },
    mounted(){
        this.getBTTData()
    }
}
</script>
<style scoped>
.font-W700{
    font-weight: 700;
}
.font-W400{
    font-weight: 400;
}
.btn-table{
    background: rgba(0, 174, 239, 0.1) !important;
}
</style>
