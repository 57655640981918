<template>
    <div>
        <v-row>
            <v-col cols="12" md="3">
                <sfilter @optionalFilterHotel="optionalFilterHotel" />
            </v-col>
            <v-col cols="12" md="9">
                <v-card class="card">
                    <v-card-title>
                        <h5>
                            Select Hotel
                        </h5>
                    </v-card-title>
                    <v-card-text>
                        <v-row v-if="Hotels.length > 0">
                            <v-col>
                                <v-card class="bgcard" elevation="0">
                                    <v-row v-for="(item, index) in Hotels" :key="index" no-gutters :class="index < (Hotels.length - 1) ? 'mb-2' : ''">
                                        <v-col>
                                            <v-card class="card-hotel" elevation="0">
                                                <v-row class="ma-1">
                                                    <v-col cols="12" lg="3" md="4">
                                                        <v-img width="120%" class="text-center" v-if="item.FilePath"
                                                            :src="item.FilePath" />
                                                            <div v-else class="text-center">
                                                                No Image
                                                            </div>
                                                    </v-col>
                                                    <v-col cols="12" lg="9" md="8" class="align-center">
                                                        <v-row>
                                                            <v-col cols="12" lg="6" md="6">
                                                                <v-row class="pointerLink" @click="getHotelInfo(item)">
                                                                    <v-col>
                                                                        <strong>
                                                                            {{ item.HTL_NAME }}
                                                                        </strong>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row no-gutters>
                                                                    <v-col class="d-flex align-center">
                                                                        <v-chip color="#004FBC" x-small dark>
                                                                            Hotel
                                                                        </v-chip>
                                                                        <v-rating hover dense small class="ml-3" color="#F9B50B"
                                                                        v-model="item.HTL_RATING" length="5" readonly></v-rating>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row no-gutters>
                                                                    <v-col>
                                                                        <img :src="require('@/assets/icons/map-pin.svg')" width="15" alt="" />
                                                                        <span class="ml-1">
                                                                            {{ item.HTL_CITY }}
                                                                        </span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col cols="12" lg="4" md="6">
                                                                <v-row>
                                                                    <v-col>
                                                                        <span>{{ item.HTL_DESC ? item.HTL_DESC.length > 165 ?
                                                                        `${item.HTL_DESC.substr(0,165)}...` : item.HTL_DESC : "" }}</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                <v-row>
                                                                    <v-col class="greentap ml-2" v-for="(itemRoom, index) in item.RoomType"
                                                                    :key="index">
                                                                        <strong>
                                                                            {{ itemRoom.RMT_NAME }}
                                                                        </strong>
                                                                        <br />
                                                                        <span>
                                                                            {{ itemRoom.RMT_DESC }}
                                                                        </span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col>
                                                                <v-row>
                                                                    <v-col class="text-right">
                                                                        <v-row>
                                                                            <v-col>
                                                                                <span style="color: #747678;">{{ dataFilter.Night }} nights, {{ dataFilter.Occupancy }} Guest</span>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row no-gutters>
                                                                            <v-col>
                                                                                <span class="font-W700" style="font-size: 16px;">
                                                                                    THB {{ (item.PRICE_MIN * dataFilter.Night).toLocaleString
                                                                                    ('th', { minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2 }) }} -
                                                                                    {{ (item.PRICE_MAX * dataFilter.Night).toLocaleString
                                                                                    ('th', { minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2 }) }}
                                                                                </span>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row no-gutters>
                                                                            <v-col>
                                                                                <v-expansion-panels flat>
                                                                                    <v-expansion-panel>
                                                                                        <v-expansion-panel-header>
                                                                                            <template v-slot:actions>
                                                                                                <v-icon color="primary">
                                                                                                $expand
                                                                                                </v-icon>
                                                                                            </template>
                                                                                            <span style="color: #009FDA;">Inclusive of Taxes</span>
                                                                                        </v-expansion-panel-header>
                                                                                        <v-expansion-panel-content class="text-left">
                                                                                            Some content
                                                                                        </v-expansion-panel-content>
                                                                                    </v-expansion-panel>
                                                                                </v-expansion-panels>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-if="!showProgressInfo && Hotels.length === 0">
                            <v-col>No data</v-col>
                        </v-row>
                        <v-row v-if="showProgressInfo">
                            <v-col>
                                <v-progress-linear indeterminate></v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import sfilter from '@/components/searchhotel/Filter'
import axios from 'axios';
export default {
    name: 'selectHotel',
    components:{
        sfilter
    },
    props: {
        FilterHotel: {},
    },
    data() {
        return {
            Hotels: [],
            dataFilter: {},
            showProgressInfo: false,
            haveInfo: true,
        }
    },
    methods:{
        getHotelInfo(item){
            this.$store.state.showHotelInfo = true
            sessionStorage.objHTL = JSON.stringify(item)
            this.$store.state.objHTL = item
        },
        getHotelByFilter(){
            if(this.Hotels.length === 0){
                this.showProgressInfo = true
            }
            axios.post(`${this.$store.state.API_PATH}GetHotelByFilter`,{
                HTL_ID: (this.Hotels.length > 0 ? this.Hotels.map(e=>`'${e.HTL_ID}'`).toString() : ""),
                COUNTRY_ID: this.dataFilter.Country,
                PRV_ID: this.dataFilter.CityorProvince,
                OptionalFilterHotel: this.FilterHotel.OptionalFilterHotel
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async(res) => {
                if (res.data.Status) {
                    for(const item of res.data.Result){
                        await new Promise(resolve=>{
                            this.Hotels.push(item)
                            resolve()
                        })
                    }
                    this.showProgressInfo = false
                    this.haveInfo = true
                }
                else{
                    this.showProgressInfo = false
                    this.haveInfo = false
                }
            })
            .catch((err) => {
                console.log(err)
                this.showProgressInfo = false
            })
        },
        optionalFilterHotel(val){
            this.Hotels = []
            this.FilterHotel.OptionalFilterHotel = val
            this.getHotelByFilter()
        }
    },
    mounted(){
        this.dataFilter = JSON.parse(JSON.stringify(this.FilterHotel))
        this.getHotelByFilter()
        window.onscroll = () => {
            const bottomOfWindow = Math.trunc(document.documentElement.scrollTop + window.innerHeight) === Math.trunc(document.documentElement.offsetHeight)
            if (bottomOfWindow && this.Hotels.length > 0) {
                this.getHotelByFilter()
            }
        }
    }
}
</script>

<style scoped>
.greentap {
    border-left: solid 3px #99e210;
    padding: 4px;
}
.card-hotel{
    border: #EBEEF4 0.5px solid !important;
    border-radius: 5px !important;
    /* padding: 2px 2px 2px 2px; */
}
.v-expansion-panel-header{
    padding: 0;
}
</style>
