<template>
  <div>
    <v-row class="mb-3" no-gutters>
      <v-col>
        <v-card elevation="0" title>
          <v-card-text>
            <v-row no-gutters align="center" justify="center">
              <v-col lg="4" cols="8">
                Subject
                <v-text-field outlined dense background-color="white" v-model="TEXT_SEARCH"
                  @keypress.enter="getHomeContent()"></v-text-field>
              </v-col>
              <v-col md="auto" sm="auto">
                <v-btn color="primary" elevation="0" class="ml-1 mb-2" @click="getHomeContent()">Search</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-3" no-gutters>
      <v-col>
        <v-card elevation="0" title>
          <v-card-text>
            <v-row no-gutters align="center">
              <v-col class="text-lg-right text-sm-right text-center">
                <v-btn class="btn-table mr-2" elevation="0" @click="deleteHomeContent()" v-if="$store.state.buttonPermit.menuConfig.Delete">
                  <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1"></v-img>
                  Delete
                </v-btn>
                <v-btn class="btn-table mr-2" elevation="0" @click="resetInfo()">
                  <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1"></v-img>
                  Reset
                </v-btn>
                <v-btn class="btn-table" elevation="0" @click="actionHomeContent('Add')" v-if="$store.state.buttonPermit.menuConfig.Add">
                  <v-img :src="require('@/assets/icons/Add_trans.svg')" class="mr-1"></v-img>
                  Add
                </v-btn>
                <v-dialog v-if="dialogAddOrEditHomeContent" v-model="dialogAddOrEditHomeContent" persistent width="1000px" :retain-focus="false">
                  <v-card elevation="0" title color="primary" class="pt-1 card">
                    <v-card>
                      <v-card-text>
                        <v-card class="card-detail" title elevation="0">
                          <v-card-title>
                            <v-row no-gutters>
                              <v-col class="text-center">
                                {{ AddOrEdit }} HomeContent
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-card-text>
                            <v-row no-gutters>
                              <v-col>
                                Order
                                <v-text-field outlined dense background-color="white" v-model="Order"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col>
                                Date<span style="color: red;">*</span>
                                <v-menu v-model="menuDateHomeContent" :close-on-content-click="false" :nudge-right="40"
                                  transition="scale-transition" offset-y min-width="auto">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined dense placeholder="Select Date"
                                      :value="DateHomeContent ? formatDateLL(DateHomeContent) : ''" readonly v-bind="attrs" v-on="on"
                                      :rules="rule.DateHomeContent" ref="DateHomeContent">
                                      <template v-slot:append>
                                        <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker v-model="DateHomeContent" @input="menuDateHomeContent = false" :rules="rule.DateHomeContent"
                                    color="#00AEEF"></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col>
                                Preview<span style="color: red;">*</span>
                                <v-textarea outlined dense background-color="white" v-model="Preview" ref="Preview"
                                  :rules="rule.Preview"></v-textarea>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col>
                                Subject<span style="color: red;">*</span>
                                <v-text-field outlined dense background-color="white" v-model="Subject" ref="Subject"
                                  :rules="rule.Subject"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col>
                                Image Cover
                                <v-file-input outlined dense accept="image/*" @change="attachImage()"
                                  v-model="fileImgHomeContent"></v-file-input>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col>
                                Description
                                <editor v-if="showMceHomeContent" v-model="Description"
                                  api-key="zme9n7btzvgd8wqufmthz6wgggu9n3z8e45y1ehvd2r3yf8a" :init="$store.state.configTinyMce" />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-row>
                          <v-col class="text-center">
                            <v-btn outlined color="primary" class="mr-1" @click="closeAddOrEditHomeContent()">Close</v-btn>
                            <v-btn color="primary" class="ml-1" v-if="AddOrEdit === 'Add'" @click="validAddOrEdit()">Save</v-btn>
                            <v-btn color="primary" class="ml-1" v-else @click="validAddOrEdit()">Save</v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row><br>
            <v-card elevation="0">
              <v-data-table :headers="headerHomeContent" :items="itemHomeContent" class="elevation-1" item-key="ID"
                @click:row="rowClicked" show-select v-model="selectedItemHomeContent" checkbox-color="primary" mobile-breakpoint
                style="white-space: nowrap" hide-default-footer height="45vh" @page-count="pageCountHomeContent = $event"
                :page.sync="pageHomeContent" :items-per-page="itemPageHomeContent">
                <template v-slot:[`item.no`]="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-img style="cursor: pointer;" :src="require('@/assets/icons/Source_File.svg')"
                    @click="getReturnDetail(item)"></v-img>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import imageCompression from 'browser-image-compression';
import * as moment from 'moment'
import Swal from 'sweetalert2';
import axios from "axios";
export default {
  components: {
    'editor': Editor
  },
  data() {
    return {
      showMceHomeContent: false,
      checkedIdHomeContent: [],
      fileImgHomeContent: null,
      selectedItemHomeContent: [],
      pageCountHomeContent: 2,
      pageHomeContent: 1,
      itemPageHomeContent: 10,
      itemPagePageHomeContent: [
        { text: "10", value: 10 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "ALL", value: 200 },
      ],
      itemHomeContent: [],
      headerHomeContent: [
        { text: 'No.', value: 'no', sortable: false },
        { text: 'Effective Date.', value: 'DateInTable', sortable: false },
        { text: 'Subject', value: 'Subject', sortable: false },
        { text: 'Preview', value: 'Preview', sortable: false },
      ],
      Order: "",
      Subject: "",
      Preview: "",
      AddOrEdit: "",
      DateHomeContent: "",
      ImgCover: "",
      menuDateHomeContent: false,
      dialogAddOrEditHomeContent: false,
      TEXT_SEARCH: "",
      rule: {
        DateHomeContent: [v => !!v || 'Date is required'],
        Subject: [v => !!v || 'Subject is required'],
        Preview: [v => !!v || 'Preview is required'],
      },
      Description: "",
      ID: "",
    }
  },
  methods: {
    async attachImage() {
      if (this.fileImgHomeContent) {
        const file = await imageCompression(this.fileImgHomeContent, { maxSizeMB: 0.1, useWebWorker: true })
        const reader = new FileReader()
        reader.onload = (event) => {
          this.ImgCover = event.target.result
        }
        reader.readAsDataURL(file)
      }
    },
    rowClicked(items) {
      if(this.$store.state.buttonPermit.menuConfig.Update){
        axios.post(this.$store.state.API_PATH + "HomeContent/GetHomeContentDescription", {
          ID: items.ID
        }, {
          headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        }).then(res => {
          this.$store.state.LOADING = false
          if (res.data.Status) {
            this.Description = res.data.Result[0].Description
          }
        }).catch(err => {
          this.$store.state.LOADING = false
          console.log(err)
        })
        this.AddOrEdit = "Edit"
        this.ID = items.ID
        this.Order = items.Order
        this.Subject = items.Subject
        this.Preview = items.Preview
        this.DateHomeContent = items.Date
        this.ImgCover = items.ImgCover
        this.Description = items.Description
        this.dialogAddOrEditHomeContent = true
        setTimeout(() => {
          this.showMceHomeContent = true
        }, 1)
      }
    },
    validAddOrEdit(){
      if (!this.$refs.DateHomeContent.validate() || !this.$refs.Subject.validate()) {
        if (!this.$refs.DateHomeContent.validate()) {
          this.$refs.DateHomeContent.focus()
        }
        else {
          this.$refs.Subject.focus()
        }
      }
      else{
        const PROFILE = JSON.parse(localStorage.PROFILE)
        this.$store.state.LOADING = true
        this.dialogAddOrEditHomeContent = false
        const req = {
          Order: this.Order,
          Date: this.DateHomeContent,
          Subject: this.Subject,
          ID: this.ID,
          Preview: this.Preview,
          Description: this.Description,
          PinPost: 0,
          ImgCover: this.ImgCover,
          Cby: PROFILE.EMPLOYEE_ID,
        }
        if(this.AddOrEdit === "Add"){
          this.addHomeContent(req)
        }
        else{
          this.updateHomeContent(req)
        }
      }
    },
    closeAddOrEditHomeContent() {
      this.dialogAddOrEditHomeContent = false
      this.Order = ""
      this.Subject = ""
      this.ID = ""
      this.Preview = ""
      this.AddOrEdit = ""
      this.DateHomeContent = ""
      this.Description = ""
    },
    addHomeContent(req) {
      axios.post(this.$store.state.API_PATH + "HomeContent/InsertHomeContent", req, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(res => {
        this.resApiAddOrEdit(res)
      }).catch(err => {
        this.$store.state.LOADING = false
        console.log(err)
      })
    },
    updateHomeContent(req) {
      axios.post(this.$store.state.API_PATH + "HomeContent/UpdateHomeContent", req, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(res => {
        this.resApiAddOrEdit(res)
      }).catch(err => {
        this.$store.state.LOADING = false
        console.log(err)
      })
    },
    resApiAddOrEdit(res){
      this.$store.state.LOADING = false
      if (res.data.Status) {
        Swal.fire({
          icon: 'success',
          text: 'Success',
          confirmButtonColor: '#00AEEF'
        }).then(() => {
          this.getHomeContent()
        })
      }
      else {
        Swal.fire({
          icon: 'error',
          text: res.data.ErrorMessage,
          confirmButtonColor: '#00AEEF'
        }).then(() => {
          window.location.reload()
        })
      }
    },
    deleteHomeContent() {
      if (this.selectedItemHomeContent.length > 0) {
        Swal.fire({
          icon: 'warning',
          text: 'would you like to to delete?',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          confirmButtonColor: '#00AEEF'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.state.LOADING = true
            const PROFILE = JSON.parse(localStorage.PROFILE)
            axios.post(this.$store.state.API_PATH + 'HomeContent/DeleteHomeContent', {
              ID: this.selectedItemHomeContent.map(val => `${val.ID}`).toString() ,
              Cby : PROFILE.EMPLOYEE_ID
            }, {
              headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
              .then(res => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                  Swal.fire({
                    icon: 'success',
                    text: 'Success',
                    confirmButtonColor: '#00AEEF'
                  }).then(() => {
                    this.selectedItemHomeContent = []
                    this.getHomeContent()
                  }, () => { })
                }
                else {
                  Swal.fire({
                    icon: 'error',
                    text: res.data.ErrorMessage,
                    confirmButtonColor: '#00AEEF'
                  }).then(() => {
                  }, () => { })
                }
              })
              .catch(() => {
                this.$store.state.LOADING = false
              })
          }
        })
      }
    },
    resetInfo() {
      this.getHomeContent()
    },
    actionHomeContent(AddOrEdit) {
      this.AddOrEdit = AddOrEdit
      this.dialogAddOrEditHomeContent = true
      setTimeout(() => {
        this.showMceHomeContent = true
      }, 1)
    },
    getHomeContent() {
      this.itemHomeContent = []
      this.$store.state.LOADING = true
      axios.post(this.$store.state.API_PATH + "HomeContent/GetHomeContent", {
        Subject: this.TEXT_SEARCH
      }, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then((res) => {
        this.$store.state.LOADING = false
        if (res.data.Status) {
          this.itemHomeContent = res.data.Result
          const menuConfigData = localStorage.getItem('menuConfig');
          this.$store.state.menuConfig = JSON.parse(menuConfigData);
        }
      }).catch(err => {
        this.$store.state.LOADING = false
        console.log(err)
      })
    },
    formatDateLL(date) {
      return date ? moment(date).format("ll") : moment().format("ll")
    },
  },
  mounted() {
    this.getHomeContent()
  },
}
</script>
