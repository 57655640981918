<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col class="h-space">
                <span class="font-W700" style="font-size: 1em;">Agent Service</span>
            </v-col>
        </v-row>
        <v-card class="card" elevation="0">
            <v-card-text>
                <v-row no-gutters align="center">
                    <v-col class="text-lg-right text-sm-right text-center hidden-xs-only hidden-sm-only">
                        <v-btn class="btn-table mr-2" @click="RemoveDialog.dialog = true" elevation="0" :disabled="selectAgents.length > 0 ? false : true">
                            <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1">
                            </v-img>
                            Delete
                        </v-btn>
                        <v-btn class="btn-table mr-2" elevation="0">
                            <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1">
                            </v-img>
                            Reset
                        </v-btn>
                        <v-btn class="btn-table" elevation="0" @click="AddAgent">
                            <v-img :src="require('@/assets/icons/Add_trans.svg')" width="20px" class="mr-1">
                            </v-img>
                            Add
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="hidden-md-and-up mt-3 flex-nowrap text-center" no-gutters>
                    <v-col>
                        <v-btn class="btn-table mr-2" @click="RemoveDialog.dialog = true" elevation="0">
                            <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1">
                            </v-img>
                            Delete
                        </v-btn>
                        <v-btn class="btn-table mr-2" elevation="0">
                            <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1">
                            </v-img>
                            Reset
                        </v-btn>
                        <v-btn class="btn-table" elevation="0" @click="AddAgent">
                            <v-img :src="require('@/assets/icons/Add_trans.svg')" width="20px" class="mr-1">
                            </v-img>
                            Add
                        </v-btn>
                    </v-col>
                </v-row>
                <br />
                <div class="bgcard">
                    <v-data-table hide-default-footer :headers="headers" :items="AgentItems" v-model="selectAgents" @click:row="focusAgent" item-key="AgentID" show-select>
                        <template v-slot:[`item.no`]="{index}">
                            {{ ++index }}
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
        </v-card>
        <v-dialog fullscreen v-if="openDialog.dialog" v-model="openDialog.dialog">
            <v-card class="card">
                <v-card-title>
                    <span v-if="openDialog.val && openDialog.val.Name">
                        {{ openDialog.val.Name }} Agent
                    </span>
                    <v-spacer></v-spacer>
                    <v-icon style="cursor: pointer;" @click="openDialog.dialog = false">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="bgcard">
                    <v-row v-if="openDialog.val">
                        <v-col cols="4">
                            <v-text-field outlined background-color="white" v-model="openDialog.val.Name" :rules="rule.AgentName" ref="AgentName"
                            dense label="Agent Name" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="!openDialog.val.AgentID">
                            <v-text-field outlined background-color="white" v-model="openDialog.val.OfficeID" :rules="rule.OfficeID" ref="OfficeID"
                            dense label="Office ID" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="4" v-else>
                            <v-text-field outlined background-color="white" :value="openDialog.val.OfficeID" :rules="rule.OfficeID" ref="OfficeID"
                            disabled dense label="Office ID" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field outlined background-color="white" v-model="openDialog.val.Rank" :rules="rule.Rank" ref="Rank"
                            dense label="Rank" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field outlined background-color="white" v-model="openDialog.val.Email" :rules="rule.Email" ref="Email"
                            dense label="Email" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field outlined background-color="white" v-model="openDialog.val.Category" :rules="rule.Category" ref="Category"
                            type="Number" dense label="Category" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field outlined background-color="white" v-model="openDialog.val.QueueNumber" :rules="rule.QueueNumber" ref="QueueNumber"
                            type="Number" dense label="Queue Number" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-radio-group v-model="openDialog.val.IsActive" ref="">
                                <v-row no-gutter>
                                    <v-col cols="6" lg="3" v-for="(itemsts, index) in statusAgent" :key="index">
                                        <v-radio :label="itemsts.text" :value="itemsts.code">
                                        </v-radio>
                                    </v-col>
                                </v-row>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <div v-if="openDialog.val && openDialog.val.FareControl.length > 0">
                        <div class="h-space"></div>
                        <v-card class="card text-center">
                            <div class="h-space"></div>
                            <span class="f-text-24">
                                {{ openDialog.sheetName }}
                            </span>
                            <span>
                                <v-icon style="cursor: pointer; float: right" @click="removeFarecon">mdi-close</v-icon>
                                &emsp;
                            </span>
                            <div class="h-space"></div>
                            <v-card-text class="bgcard">
                                <v-data-table :headers="FareControlHeaders" :items="openDialog.val.FareControl"></v-data-table>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div v-else class="text-center">
                    <div class="h-space"></div>
                        <v-btn color="primary" width="100%" large>
                            <label for="excel_file" style="width: 100%;">
                                Import File
                            </label>
                            <v-file-input type="file" style="display: none;" v-model="excel_file" id="excel_file" show-size>Import File</v-file-input>
                        </v-btn>
                    <div class="h-space"></div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <div class="h-space"></div>
                        <v-spacer></v-spacer>
                        <v-btn text @click="openDialog.dialog = false">Cancel</v-btn>
                        &emsp;
                        <v-btn :disabled="openDialog.val.OfficeID ? false : true" @click="saveAgent" color="primary">Save</v-btn>
                        <v-spacer></v-spacer>
                    <div class="h-space"></div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog width="500" v-model="RemoveDialog.dialog">
            <v-card>
                <v-card-title>
                    Remove Agent ?
                </v-card-title>
                <v-card-text></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small text @click="RemoveDialog.dialog = false">Cancel</v-btn>
                    <v-btn small color="primary" @click="openRemoveDialog()">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="FileCheck" width="500">
            <v-card>
                <v-card-title>
                    Invalid
                </v-card-title>
                <v-card-text>
                    only .xlsx file Supported.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="FileCheck = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    watch:{
        excel_file(){
            const reader = new FileReader()
            const findType = this.excel_file.name.split(".")
            if(this.excel_file && findType[findType.length - 1] === 'xlsx'){
                reader.readAsArrayBuffer(this.excel_file)
                reader.onload = (e) =>{
                    const arrayBuffer = reader.result
                    const rawData = new Uint8Array(arrayBuffer)
                    const workbook = this.XLSX.read(rawData, {type: 'array'})
                    const sheetName = workbook.SheetNames
                    this.openDialog.sheetName = this.excel_file.name
                    const sheetData = this.XLSX.utils.sheet_to_json(workbook.Sheets[sheetName[0]], {header: 1})
                    if(sheetData.length > 0){
                        sheetData[0].forEach(elm => {
                            this.newFileheader.push({text: elm, value: elm.replace(" ", "")})
                        })
                        sheetData.forEach(async (elm, index) => {
                            if(index > 0){
                                const row = []
                                await elm.forEach((data, index2) => {
                                    const mo = {}
                                    mo[this.newFileheader[index2].value] = data //Variable To Object
                                    row.push(mo)
                                })
                                const result = Object.assign({}, ...row)
                                this.newFileItem.push(result)
                            }
                        })
                    }
                    this.openDialog.val.FareControl = this.newFileItem
                }
            }else{
                this.FileCheck = true
                this.newFileheader = []
            }
        }
    },
    data(){
        return {
            selectAgents: [],
            FileCheck: false,
            excel_file: null,
            XLSX: null,
            newFileheader: [],
            newFileItem: [],
            headers: [
                {text: "No", value: "no"},
                {text: "Agent Name", value: "Name"},
                {text: "Office Id", value: "OfficeID"},
                {text: "Email", value: "Email"},
                {text: "Queue No", value: "QueueNumber"},
                {text: "Category", value: "Category"},
                {text: "Rank", value: "Rank"},
            ],
            FareControlHeaders: [
                {text: "TripType", value: "TripType"},
                {text: "Origin", value: "Origin"},
                {text: "Destination", value: "Destination"},
                {text: "AirlineCode", value: "AirlineCode"},
                {text: "FlightNumber", value: "FlightNumber"},
                {text: "RBD", value: "RBD"},
                {text: "FareBasis", value: "FareBasis"},
                {text: "FareType", value: "FareType"},
                {text: "AdultPax", value: "AdultPax"},
                {text: "ChildPax", value: "ChildPax"},
                {text: "InfantPax", value: "InfantPax"},
                {text: "DiscountAmount", value: "DiscountAmount"},
                {text: "DiscountIsPercent", value: "DiscountIsPercent"},
                {text: "MarkupAmount", value: "MarkupAmount"},
                {text: "MarkupIsPercent", value: "MarkupIsPercent"},
            ],
            AgentItems: [],
            openDialog:{
                dialog: false,
                val: {
                    AgentID: null,
                    Category: null,
                    Email: null,
                    FareControl: [],
                    IsActive: null,
                    IsDelete: null,
                    Name: null,
                    OfficeID: null,
                    QueueNumber: null,
                    Rank: null,
                },
                sheetName: null
            },
            RemoveDialog: {
                dialog: false,
                value: null
            },
            statusAgent: [
                { code: 1, text: 'Active' },
                { code: 0, text: 'Inactive' },
            ],
            requireField: "This field is required",
            rule: {
                AgentName: [(val) => (val || "").length > 0 || this.requireField],
                OfficeID: [(val) => (val || "").length > 0 || this.requireField],
                Rank: [(val) => (val || "").length > 0 || this.requireField],
                Email: [(val) => (val || "").length > 0 || this.requireField],
                Category: [(val) => (val || "").length > 0 || this.requireField],
                QueueNumber: [(val) => (val || "").length > 0 || this.requireField]
            },
        }
    },
    methods:{
        openRemoveDialog(){
            this.RemoveDialog.dialog = true
            this.RemoveDialog.value = this.selectAgents.map(x => x.AgentID)
            axios.post(`${this.$store.state.API_PATH}Agent/RemoveAgent`, this.RemoveDialog.value,
                {headers: { 'Authorization': `Basic ${this.$store.state.authenApi}`}}
            ).then(() => {
                this.AgentItems = this.AgentItems.filter(x => this.RemoveDialog.value.filter(y => y === x.AgentID).length === 0 )
                this.RemoveDialog.dialog = false
            })
        },
        removeFarecon(){
            this.openDialog.val.FareControl = []
        },
        checkvalidate(){
            let result = false
            if (!this.$refs.AgentName.validate()) {
                this.$refs.AgentName.validate(true)
                this.$refs.AgentName.focus()
                result = true
            }
            if (!this.$refs.OfficeID.validate()) {
                this.$refs.OfficeID.validate(true)
                this.$refs.OfficeID.focus()
                result = true
            }
            if (!this.$refs.Email.validate()) {
                this.$refs.Email.focus()
                this.$refs.Email.validate(true)
                result = true
            }
            return result
        },
        saveAgent(){
            if(!this.checkvalidate()){
                console.log('called')
                axios.post(`${this.$store.state.API_PATH}Agent/AddAgent`, this.openDialog.val,
                    {headers: { 'Authorization': `Basic ${this.$store.state.authenApi}`}}
                ).then(() => {
                    this.getAgent()
                    this.openDialog.dialog = false
                })
            }
        },
        AddAgent(){
            this.openDialog.dialog = true
            this.openDialog.val = {
                AgentID: null,
                Category: null,
                Email: null,
                FareControl: [],
                IsActive: 0,
                IsDelete: 0,
                Name: null,
                OfficeID: null,
                QueueNumber: null,
                Rank: null
            }
            this.newFileItem = []
            this.newFileheader = []
        },
        focusAgent(val){
            this.openDialog.val = val
            axios.post(`${this.$store.state.API_PATH}Agent/GetFareControl?val=${val.OfficeID}`, '',{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.openDialog.val.FareControl = res.data
                this.openDialog.dialog = true
            })
        },
        getAgent(){
            axios.post(`${this.$store.state.API_PATH}Agent/GetObjAgentService?val=`, '',{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.AgentItems = res.data
                const menuConfigData = localStorage.getItem('menuConfig');
                this.$store.state.menuConfig = JSON.parse(menuConfigData);
                })
        }
    },
    mounted(){
        this.XLSX = require("xlsx");
        this.getAgent()
    }
}
</script>
