import axios from "axios";
import moment from "moment";
import store from "../index";

function dateEn(date) {
  return `${moment(date).format("DD MMMM YYYY")}`;
}

export default {
  namespaced: true,
  state: {
    nameEN: "",
    posiEN: "",
    costFlight: "",
    costTotal: "",
    txtOther: "",
    posiOther: "",
    flight: "",
    country: "",
    joinDate: "",
    nameBudget: "",
    posiBudget: "",
    nameTH: "",
    posiThai: "",
    nameBTT: "",
    posiBTT: "",
    TRBudget: "",
    dateStart: "",
    dateEnd: "",
    documentTraveller: [],
    body: "",
    textOther: "",
    addressTo: "",
    countryEN: "",
    TRNo: "",
    EMP_ID: "",
    Type: "",
    headerVisa: [],
    footerVisa: [],
  },
  mutations: {
    getDocVisa(state, param) {
      state.headerVisa = [
        {
          image: store.state.logo_pttep,
          width: 80,
        },
        {
          text: "",
          width: 90,
        },
        {
          image: store.state.NewRS_PTTEPNAME,
          width: 350,
          alignment: "right",
        },
      ];
      store.state.LOADING = true;
      axios
        .post(
          `${store.state.API_PATH}Document/GetDocVisa`,
          {
            TRNo: param.TRNo,
            EMP_ID: param.EMP_ID,
            Type: param.Type,
          },
          {
            headers: { Authorization: `Basic ${store.state.authenApi}` },
          }
        )
        .then((res) => {
          store.state.LOADING = false;
          if (res.data.Status) {
            const item = res.data.Result[0];
            state.TRBudget = item.TRBudget;
            state.dateStart = dateEn(item.BusinessStartDate);
            state.dateEnd = dateEn(item.BusinessEndDate);
            state.documentTraveller = item.DocumentTraveller;
            if (item.DocumentTraveller != null && item.DocumentTraveller.length > 0) {
              state.nameEN = item.DocumentTraveller[0].NameEN;
              state.posiEN = item.DocumentTraveller[0].PosiEN;
              state.joinDate = dateEn(item.DocumentTraveller[0].JoinDate);
            }
            state.nameBudget = item.NameBudget;
            state.posiBudget = item.PosiBudget;
            state.nameBTT = item.NameBTT;
            state.posiBTT = item.PosiBTT;
            state.country = item.Country;
            state.flight = item.Flight;
            state.addressTo = item.AddressTo;
            state.countryEN = item.CountryEN;
            state.body = `PTT Exploration and Production Public Company Limited would like
               to seek your kind assistance in granting a visa
                for the following personnel External
                 Training on ${state.dateStart} - ${state.dateEnd}`;
            state.footerVisa = [
              {
                text: "Yours sincerely,",
                style: "font16",
                margin: [0, 25, 0, 0],
              },
              {
                text: `${state.nameBudget}\n${state.posiBudget}\n${state.posiOther}`,
                style: "font16",
                margin: [0, 25, 0, 0],
              },
              {
                style: "font16",
                margin: [0, 25, 0, 0],
                text: `Coordinator: ${state.nameBTT}`,
              },
              {
                style: "font16",
                text: state.posiBTT,
              },
              {
                style: "font16",
                text: `TR No.${state.TRBudget}`,
              },
            ];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
