<template>
        <div>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <span>
                        <span class="font-W700" style="font-size: 1em;">CountryCode</span>
                    </span>
                </v-col>
            </v-row>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row gutters align="center" justify="center">
                                <v-col sm="3" lg="3" cols="8">
                                    Code
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Code" @keypress.enter="getCountryCodeBySearch()"></v-text-field>
                                </v-col>
                                <v-col sm="3" lg="3" cols="8">
                                    PhoneCode
                                    <v-text-field outlined dense background-color="white"
                                    v-model="PhoneCode" @keypress.enter="getCountryCodeBySearch()"></v-text-field>
                                </v-col>
                                <v-col sm="3" lg="3" cols="8">
                                    Name
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Name" @keypress.enter="getCountryCodeBySearch()"></v-text-field>
                                </v-col>
                                 <v-col sm="3" lg="3" cols="8">
                                    Alpha3
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Alpha3" @keypress.enter="getCountryCodeBySearch()"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters align="center" justify="center">
                                <v-col md="auto" sm="auto" >
                                    <v-btn color="primary"
                                      @click="getCountryCodeBySearch()" :loading="searchbtnLoading"
                                      elevation="0"
                                      class="mb-lg-0 mb-md-0 mb-sm-0 mb-6">Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row no-gutters align="center">
                                <v-col class="text-lg-right text-sm-right text-center">
                                    <v-btn class="btn-table mr-2" elevation="0" @click="deleteCountryCode()" v-if="$store.state.buttonPermit.menuConfig.Delete">
                                        <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1"></v-img>
                                        Delete
                                    </v-btn>
                                    <v-btn class="btn-table mr-2" elevation="0" @click="resetInfo()">
                                        <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1"></v-img>
                                        Reset
                                    </v-btn>
                                    <v-btn class="btn-table" elevation="0" @click="actionCountryCode('Add')" v-if="$store.state.buttonPermit.menuConfig.Add">
                                        <v-img :src="require('@/assets/icons/Add_trans.svg')" class="mr-1"></v-img>
                                        Add
                                    </v-btn>
                                    <v-dialog v-if="dialogAddOrEditCountryCode" v-model="dialogAddOrEditCountryCode" persistent
                                        width="600px">
                                        <v-card elevation="0" title color="primary" class="pt-1 card">
                                            <v-card>
                                                <v-card-text>
                                                    <v-card class="card-detail" title elevation="0">
                                                        <v-card-title>
                                                            <v-row no-gutters>
                                                                <v-col class="text-center">
                                                                    {{ AddOrEdit }} Country Code
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Code
                                                                    <v-text-field  :disabled="AddOrEdit === 'Add' ? false : true"
                                                                     outlined dense background-color="white" ref="Code" :rules="rules.Code"
                                                                     v-model="Code"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Language
                                                                    <v-text-field :disabled="AddOrEdit === 'Add' ? true : true"
                                                                     outlined dense background-color="white" ref="Language" :rules="rules.Language"
                                                                     v-model="Language"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Name
                                                                     <v-text-field outlined dense background-color="white" ref="Name" :rules="rules.Name"
                                                                    v-model="Name"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Alpha3
                                                                     <v-text-field outlined dense background-color="white" ref="Alpha3" :rules="rules.Alpha3"
                                                                    v-model="Alpha3"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    PhoneCode
                                                                     <v-text-field outlined dense background-color="white" ref="PhoneCode" :rules="rules.PhoneCode"
                                                                    v-model="PhoneCode"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    <span>
                                                                        <v-checkbox label="Risk Country" :disabled="isSaved" hide-details
                                                                        v-model="RiskCountry" />
                                                                    </span>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col class="text-center">
                                                            <v-btn outlined color="primary" class="mr-1"
                                                            @click="closeAddOrEditCountryCode()">Close</v-btn>
                                                            <v-btn color="primary" class="ml-1" v-if="AddOrEdit === 'Add'"
                                                            @click="addCountryCodeData()">Save</v-btn>
                                                            <v-btn color="primary" class="ml-1" v-else
                                                            @click="updateCountryCodeData()">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <br>
                            <v-card elevation="0">
                                <v-data-table :headers="headerCountryCode" :items="itemCountryCodeData" class="elevation-1" item-key="no"
                                @click:row="rowClicked" show-select v-model="selectedItemCountryCode" checkbox-color="primary"
                                mobile-breakpoint style="white-space: nowrap" hide-default-footer height="45vh"
                                @page-count="pageCountCountryCode = $event" :page.sync="page" :items-per-page="itemPage" @load="onPagination($event)">
                                </v-data-table>
                            </v-card>
                            <v-row class="mt-3 hidden-xs-only" no-gutters>
                                <v-col class="d-flex" cols="12">
                                    <span class="mr-3 mt-3">Show</span>
                                    <v-select outlined dense :items="itemsPerPageCountryCode" item-text="text" item-value="value"
                                        v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                    <span class="ml-3 mt-3">items per page</span>
                                    <v-pagination v-model="page" :length="pageCountCountryCode" :total-visible="5" class="pagination-custom"
                                        style="width: 60%;"></v-pagination>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                                <v-col class="d-flex" cols="12">
                                    <span class="mr-3 mt-3">Show</span>
                                    <v-select outlined dense :items="itemsPerPageCountryCode" item-text="text" item-value="value"
                                        v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                    <span class="ml-3 mt-3">items per page</span>
                                </v-col>
                                <v-col>
                                    <v-pagination v-model="page" :length="pageCountCountryCode" :total-visible="5" class="pagination-custom">
                                    </v-pagination>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="DialogRemove" width="500">
            <v-card>
                <v-card-title>
                    Remove
                </v-card-title>
                <v-card-text>
                    <template v-if="FileFocus">
                        would you like to remove '{{ FileFocus.FilePath.split('\\')[FileFocus.FilePath.split('\\').length - 1] }}' ?
                    </template>
                    <template v-else>
                        would you like to remove your passport account?
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small text @click="DialogRemove = false">Cancel</v-btn>
                    <v-btn small color="error" @click="removeCountryCode(typeFocus, 'remove', null)">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </div>
</template>
<script>
import Swal from 'sweetalert2'
import axios from 'axios';
export default {
    data(){
        return{
            isSaved: false,
            RiskCountry: false,
            disable: false,
            searchbtnLoading: false,
            FileFocus: null,
            typeFocus: null,
            DialogRemove: false,
            files: [],
            Code: "",
            Language: "en",
            Name: "",
            Alpha3: "",
            PhoneCode: "",
            AddOrEdit: "",
            dialogAddOrEditCountryCode: false,
            TEXT_SEARCH: "",
            itemPage: 10,
            page: 1,
            pageCountCountryCode: 2,
            headerCountryCode: [
                { text: 'No.', value: 'no', sortable: false },
                { text: 'Code', value: 'Code', sortable: false },
                { text: 'Language', value: 'Language', sortable: false },
                { text: 'Name', value: 'Name', sortable: false },
                { text: 'Alpha3', value: 'Alpha3', sortable: false },
                { text: 'RiskCountry', value: 'RiskCountry', sortable: false },
                { text: 'PhoneCode', value: 'PhoneCode', sortable: false },
            ],
            itemCountryCodeData: [],
            selectedItemCountryCode: [],
            requireField: "This field is required",
            rules:{
                Code: [(val) => (val || "").length > 0 || this.requireField],
                Language: [(val) => (val || "").length > 0 || this.requireField],
                Name: [(val) => (val || "").length > 0 || this.requireField],
                Alpha3: [(val) => (val || "").length > 0 || this.requireField],
                RiskCountry: [(val) => (val || "").length > 0 || this.requireField],
                PhoneCode: [(val) => (val || "").length > 0 || this.requireField],
            },
            itemsPerPageCountryCode: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
        }
    },
    methods:{
        checkvalidateCountryCode(){
            let result = false
            if (!this.$refs.Code.validate()) {
                this.$refs.Code.validate(true)
                this.$refs.Code.focus()
                result = true
            }
            if (!this.$refs.Language.validate()) {
                this.$refs.Language.validate(true)
                this.$refs.Language.focus()
                result = true
            }
            if (!this.$refs.Name.validate()) {
                this.$refs.Name.validate(true)
                this.$refs.Name.focus()
                result = true
            }
            if (!this.$refs.Alpha3.validate()) {
                this.$refs.Alpha3.validate(true)
                this.$refs.Alpha3.focus()
                result = true
            }
            return result
        },
        removeCountryCode(type, processing, val) {
            this.typeFocus = type
            if (type === 'BedTypeFile') {
                if (processing === 'alert') {
                    this.FileFocus = val
                }
                if (processing === 'remove') {
                    this.DialogRemove = false
                    this.removeFiles(this.FileFocus, 'Saved')
                }
            }
        },
        updateCountryCodeData(){
            if(!this.checkvalidateCountryCode()){
                this.dialogAddOrEditCountryCode = false
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}CountryCode/UpdateCountryCode`, {
                    Code: this.Code,
                    Language: this.Language,
                    Name: this.Name,
                    Alpha3: this.Alpha3,
                    RiskCountry: this.RiskCountry ? 1 : 0,
                    PhoneCode: this.PhoneCode,
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.SwalAlert(res)
                })
            }
        },
        SwalAlert(res){
            if(res.data.Status){
                Swal.fire({
                    icon: 'success',
                    text: 'Success',
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.closeAddOrEditCountryCode()
                    this.getCountryCodeData()
                }, () => {})
            }
            else{
                Swal.fire({
                    icon: 'error',
                    text: res.data.ErrorMessage,
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.dialogAddExternal = true
                    this.$store.state.LOADING = false
                }, () => { })
            }
        },
        closeAddOrEditCountryCode(){
            this.dialogAddOrEditCountryCode = false
            this.Code = ""
            this.Name = ""
            this.Alpha3 = ""
            this.AddOrEdit = ""
            this.disable = false
            this.RiskCountry = false
            this.PhoneCode = ""
        },
        addCountryCodeData(){
            if(!this.checkvalidateCountryCode()){
                console.log("1");
                this.dialogAddOrEditCountryCode = false
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}CountryCode/InsertCountryCode`, {
                    Code: this.Code,
                    Language: 'en',
                    Name: this.Name,
                    Alpha3: this.Alpha3,
                    RiskCountry: this.RiskCountry ? "1" : "0",
                    PhoneCode: this.PhoneCode
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.SwalAlert(res)
                }).catch(() => {
                    this.$store.state.LOADING = false
                })
            }
        },
        actionCountryCode(AddOrEdit){
            this.AddOrEdit = AddOrEdit
            this.dialogAddOrEditCountryCode = true
        },
        resetInfo(){
            this.selectedItemCountryCode = []
            this.TEXT_SEARCH = ""
            this.getCountryCodeData()
        },
        deleteCountryCode(){
            if(this.selectedItemCountryCode.length > 0){
                Swal.fire({
                    icon: 'warning',
                    text: 'would you like to to delete?',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    confirmButtonColor: '#00AEEF'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.state.LOADING = true
                        axios.post(this.$store.state.API_PATH + 'CountryCode/DeleteCountryCode', {
                            selectedDelItem: this.selectedItemCountryCode
                        }, {
                            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        })
                        .then(res => {
                            this.$store.state.LOADING = false
                            if (res.data.Status) {
                                Swal.fire({
                                    icon: 'success',
                                    text: 'Success',
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                    this.selectedItemCountryCode = []
                                    this.getCountryCodeData()
                                },()=>{})
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    text: res.data.ErrorMessage,
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                },()=>{})
                            }
                        })
                        .catch(() => {
                            this.$store.state.LOADING = false
                        })
                    }
                })
            }
        },
        getCountryCodeData(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}CountryCode/GetTableCountryCode`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.itemCountryCodeData = res.data.Result
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        getCountryCodeBySearch(){
                this.searchbtnLoading = true
                setTimeout(() => {
                    axios.post(this.$store.state.API_PATH + 'CountryCode/GetCountryCodeBySearch', {
                        Code: this.Code,
                        Language: this.Language,
                        Name: this.Name,
                        Alpha3: this.Alpha3,
                        PhoneCode: this.PhoneCode
                    }, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    }).then(res => {
                        this.$store.state.LOADING = false
                    if (res.data.Status) {
                        console.log(res.data.Result);
                        this.itemCountryCodeData = res.data.Result
                        this.searchbtnLoading = false
                    } else {
                        this.searchbtnLoading = false
                    }
                    }).catch(() => {
                        this.$store.state.LOADING = false
                        this.searchbtnLoading = false
                    })
                }, 1000);
            },
        rowClicked(item){
            if( this.$store.state.buttonPermit.menuConfig.Update){
                this.AddOrEdit = "Edit"
                this.Code = item.Code
                this.Language = item.Language
                this.PhoneCode = item.PhoneCode
                this.Name = item.Name
                this.Alpha3 = item.Alpha3
                this.dialogAddOrEditCountryCode = true
                this.RiskCountry = item.RiskCountry ? true : false
            }
        },
    },
    mounted(){
        this.getCountryCodeData()
    }
}
</script>
<style scoped>
.font-W700{
    font-weight: 700;
}
.font-W400{
    font-weight: 400;
}
.btn-table{
    background: rgba(0, 174, 239, 0.1) !important;
}
</style>
