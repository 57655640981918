<template>
  <v-card>
    <v-card-title> Passport </v-card-title>
    <v-card-text ref="form">
      <br />
      <v-row class="bg-card">
        <v-col cols="12" md="12" xs="12">
          Passport Type
          <v-select outlined dense :disabled="disable" :items="passportType" ref="PassType" background-color="white"
            :rules="rule.PassType" placeholder="Select Passport Type" v-model="Passport.PassportType"></v-select>
        </v-col>
        <v-col cols="12" md="12" xs="12">
          Passport No. <span class="remark">*</span>
          <v-text-field outlined dense :disabled="disable" v-model="Passport.PassportNo" placeholder="Input Passport No."
            ref="PassNo" background-color="white" :rules="rule.PassNo"></v-text-field>
        </v-col>
        <v-col cols="12" md="12" xs="12">
          Expiry Date <span class="remark">*</span>
          <v-menu v-model="expiryDate.menu" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:[`activator`]="{ on, attrs }">
              <v-text-field :disabled="disable" :value="formatDate(expiryDate.date)" outlined dense readonly
                v-bind="attrs" v-on="on" ref="ExpiryDate" background-color="white">
                <v-icon slot="append" color="info"> mdi-calendar </v-icon>
              </v-text-field>
            </template>
            <v-date-picker :min="nowDate" v-model="expiryDate.date" @input="expiryDate.menu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="12" xs="12">
          Country
          <v-autocomplete v-model="Passport.Country" @input.native="getCountryCodePassport($event.srcElement.value)"
            :items="country" background-color="white" ref="Country" :rules="rule.Country" item-text="text"
            item-value="text" dense :disabled="disable" hide-details="true" outlined></v-autocomplete>
        </v-col>
        <v-col md="12">
          <template v-if="files.length > 0">
            <v-col cols="12" v-for="(fileitem, index) in files" :key="'n' + index">
              <v-card class="card">
                <v-card-text class="bgcard">
                  <v-row>
                    <v-col cols="2">
                      <v-icon v-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'xlsx' || fileitem.name.split('.')
                      [fileitem.name.split('.').length - 1] == 'xls'" class="mt-3"
                        color="success">mdi-file-excel</v-icon>
                      <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] ==
                        'png'" class="mt-3" color="error">
                        mdi-file-png-box</v-icon>
                      <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] ==
                        'pdf'" class="mt-3" color="error">
                        mdi-file-pdf-box</v-icon>
                      <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] ==
                        'jpg'" class="mt-3" color="error">
                        mdi-file-jpg-box</v-icon>
                      <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] ==
                        'zip'" class="mt-3" color="#EAC282">
                        mdi-zip-box</v-icon>
                      <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] ==
                        'rar'" class="mt-3" color="#EAC282">
                        mdi-bookmark-box-multiple</v-icon>
                      <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'docx' || fileitem.name.split('.')
                        [fileitem.name.split('.').length - 1] == 'doc'
                        " class="mt-3" color="primary">mdi-file-word</v-icon>
                      <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'xml'"
                        class="mt-3" color="primary">mdi-xml</v-icon>
                      <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'txt'"
                        class="mt-3" color="#E3751C"> mdi-file-document</v-icon>
                      <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'svg'"
                        class="mt-3" color="#E3751C">mdi-svg</v-icon>
                      <v-icon v-else class="mt-3">mdi-file-outline</v-icon>
                    </v-col>
                    <v-col cols="10" style="height: 55px">
                      <v-icon style="float: right; cursor: pointer"
                        @click="remove('alert', fileitem), (DialogRemove = true)">mdi-close</v-icon>
                      <div class="mt-3">
                        {{
                          fileitem.name.length > 15
                          ? fileitem.name.split(".")[0].slice(0, 10) + "..." +
                          fileitem.name.split(".")[fileitem.name.split(".").length - 1]
                          : fileitem.name
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
          <template v-else-if="PassportFile.length > 0">
            <v-col cols="12" v-for="(fileitem, index1) in PassportFile" :key="'o' + index1">
              <v-card class="card">
                <v-card-text class="bgcard">
                  <v-row>
                    <v-col cols="2" @click="openFile(fileitem)" style="cursor: pointer">
                      <v-icon v-if="fileitem.FileName.split('.')[1] === 'xlsx' ||
                        fileitem.FileName.split('.')[1] === 'xls'" class="mt-3"
                        color="success">mdi-file-excel</v-icon>
                      <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'png'" class="mt-3" color="error">
                        mdi-file-png-box</v-icon>
                      <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'pdf'
                        " class="mt-3" color="error">
                        mdi-file-pdf-box</v-icon>
                      <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'jpg'
                        " class="mt-3" color="error">
                        mdi-file-jpg-box</v-icon>
                      <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'zip'
                        " class="mt-3" color="#EAC282">
                        mdi-zip-box</v-icon>
                      <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'rar'
                        " class="mt-3" color="#EAC282">
                        mdi-bookmark-box-multiple</v-icon>
                      <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'docx' ||
                        fileitem.FileName.split('.')[1] === 'doc'
                        " class="mt-3" color="primary">mdi-file-word</v-icon>
                      <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'xml'
                        " class="mt-3" color="primary">
                        mdi-xml</v-icon>
                      <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'txt'
                        " class="mt-3" color="#E3751C">
                        mdi-file-document</v-icon>
                      <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'svg'
                        " class="mt-3" color="#E3751C">
                        mdi-svg</v-icon>
                      <v-icon v-else class="mt-3">mdi-file-outline</v-icon>
                    </v-col>
                    <v-col cols="10" style="height: 55px">
                      <v-icon style="float: right; cursor: pointer"
                        @click="remove('alert', fileitem), (DialogRemove = true)">mdi-close</v-icon>
                      <div class="mt-3" @click="openFile(fileitem)" style="cursor: pointer">
                        {{
                          fileitem.FileName.length > 15 ?
                          fileitem.FileName.slice(0, 10) + '...' +
                          fileitem.FileName.split('.')[1] : fileitem.FileName
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
          <div style="float: right;" v-if="PassportFile.length || files.length">(1/1)</div>
          <div style="float: right;" v-else>(0/1)</div>
          <v-dialog v-model="DialogRemove" width="500">
            <v-card>
              <v-card-title> Remove </v-card-title>
              <v-card-text>
                <template v-if="FileFocus && FileFocus.FilePath != undefined">
                  would you like to remove '{{ FileFocus.FileName.split("\\")[FileFocus.FileName.split("\\").length - 1]
                  }}' ?
                </template>
                <template v-else-if="FileFocus && FileFocus.name != undefined">
                  would you like to remove '{{ FileFocus.name.split("\\")[FileFocus.name.split("\\").length - 1] }}' ?
                </template>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small text @click="DialogRemove = false">Cancel</v-btn>
                <v-btn small color="error"
                  @click="removeFiles(FileFocus, FileFocus.FilePath == undefined ? 'NotSave' : 'Saved')">Remove</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
              depressed
              @click="InputFile">
              <v-img class="shrink mr-2" :src="require('../assets/icons/Add.svg')"
              width="22" />
              Upload File(s)
          </v-btn>
          <div style="float: right;">
            <input type="file" counter multiple hide-details="" ref="input_file" style="display: none;"
                width="22" outlined show-size background-color="white"
                accept="image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, .pdf,
                application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                @change="AddAttachFile($event)" />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="#00AEEF" @click="close()">Cancel</v-btn>
      &nbsp;
      <v-btn color="#00AEEF" style="color: white" @click="create(Passport.PassportOID != null ? 'Update' : 'Add')"
        :loading="loadingPassport">Save</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
import moment from "moment";
import axios from "axios";
export default {
  props: {
    valPass: Object,
    _ID: String,
  },
  data() {
    return {
      strYMD: "YYYY-MM-DD",
      nowDate: null,
      FileFocus: null,
      DialogRemove: false,
      file: null,
      PassportFile: [],
      files: [],
      country: [],
      disable: false,
      loadingPassport: false,
      passportType: ["Ordinary Passport", "Official Passport", "Diplomatic Passport", "Temporary Passport"],
      requireField: "This field is required",
      rule: {
        PassType: [(val) => (val || "").length > 0 || this.requireField],
        ReqNewPass: [(val) => (val || "").length > 0 || this.requireField],
        ExpiryDate: [(val) => (val || "").length > 0 || this.requireField],
        Country: [(val) => (val || "").length > 0 || this.requireField],
        PassNo: [(val) => (val || "").length > 0 || this.requireField],
      },
      requestNewPass: ["No Service Required", "Required"],
      expiryDate: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        menu: false,
        modal: false,
      },
      Passport: {
        PassportOID: null,
        PassportNo: null,
        PassportType: null,
        RequestNewPassport: "No Service Required",
        Country: null,
        ExpiryDate: null,
        EMPLOYEE_ID: null,
      },
      employee: null,
    };
  },
  watch: {
    expiryDate() { },
  },
  methods: {
    InputFile(){
        this.$refs.input_file.click()
    },
    openFile(val) {
      window.open(val.FilePath, '_blank');
    },
    GetFile(val) {
      axios.post(`${this.$store.state.API_PATH}FileUpload/GetFile?ID=${this.Passport.EMPLOYEE_ID}|${val}`).then((res) => {
        this.PassportFile = res.data.Result;
      });
    },
    AddAttachFile(event) {
      this.files = []
      if(event.target.files.length > 0){
        this.files.push(event.target.files[0])
      }
    },
    remove(processing, val) {
      if (processing === "alert") {
        this.FileFocus = val;
      }
      if (processing === "remove") {
        this.removeFiles(this.FileFocus, "Saved");
      }
    },
    removeFiles(val, key) {
      if (key === "NotSave") {
        this.files = this.files.filter((x) => x.name !== val.name);
      } else if (key === "Saved") {
        const formData = new FormData();
        for (let i = 0; i < this.files.length; i++) {
          formData.append("Passport", this.files[0]);
        }
        console.log("เข้า")
        const files = formData;
        axios.post(`${this.$store.state.API_PATH}FileUpload/RemoveFile?FileOID=`+
        `${this.employee.EMPLOYEE_ID}|${val.FilePath.split('?')[0]}|${val.FileOID}|Passport|${val.FileName}`, files, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data) {
            this.PassportFile = this.PassportFile.filter((x) => x.FileOID !== val.FileOID);
          }
        });
      }
      this.DialogRemove = false;
    },
    async alertkey(val) {
      if (val === "a") {
        this.Passport.PassportNo = "";
      }
      return this.Passport.PassportNo;
    },
    checkRequired() {
      if (this.Passport.RequestNewPassport === "Required") {
        this.disable = true;
      }
    },
    getCountryCodePassport(input) {
      if (input) {
        const checkItem = this.country.filter((el) => el.text.toLowerCase().substr(0, input.length) === input.toLowerCase());
        if (checkItem.length === 0) {
          axios
            .post(
              `${this.$store.state.API_PATH}CountryCode/GetCountryCode`,
              {
                Code: input,
                Language: this.$store.state.Language,
              },
              {
                headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
              }
            )
            .then((res) => {
              if (res.data.Status) {
                this.country = [];
                res.data.Result.forEach((elm) => {
                  this.country.push({
                    ...elm,
                    text: `${elm.Name} (${elm.Code})`,
                  });
                });
              }
            })
            .catch((err) => err);
        }
      }
    },
    formatDate(val) {
      let result = "";
      if (val) {
        result = moment(val).format("DD MMM YY");
      }
      return result;
    },
    close() {
      this.$emit("DialogPassport", false);
    },
    async create(type) {
      if (this.employee != null) {
        this.Passport.ExpiryDate = await moment(this.expiryDate.date).format(this.strYMD);
        this.Passport.EMPLOYEE_ID = (await this._ID) === undefined ? this.employee.EMPLOYEE_ID : this._ID;
        this.loadingPassport = true;
        let checkvalid = false;

        if (this.Passport.RequestNewPassport !== "Required") {
          checkvalid = this.requirePassport();
        }

        if (!checkvalid) {
          await this.passportAddOrUpdate(type);
        }
        this.loadingPassport = false;
      }
    },
    async passportAddOrUpdate(type) {
      const formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append("Passport", this.files[i]);
      }
      if (type === "Add") {
        await axios
          .post(`${this.$store.state.API_PATH}Passport/CreatePassport`, this.Passport, {
            headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
          })
          .then((res) => {
            this.$emit("PassportUpdate", res.data.Result);
            this.$emit("DialogPassport", false);
          });
      } else if (type === "Update") {
        await axios
          .post(`${this.$store.state.API_PATH}Passport/ModifyPassport`, this.Passport, {
            headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
          })
          .then((res) => {
            if (res.data != null && res.data.Status) {
              this.$emit("PassportUpdate", res.data.Result);
              this.$emit("DialogPassport", false);
            }
          });
      }

      const files = formData;
      axios.post(`${this.$store.state.API_PATH}FileUpload/Upload?ID=` + this.Passport.EMPLOYEE_ID, files, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    requirePassport() {
      let checkvalid = false;
      if (!this.$refs.PassType.validate()) {
        this.$refs.PassType.focus();
        this.$refs.PassType.validate(true);
        checkvalid = true;
      }
      if (!this.$refs.PassNo.validate()) {
        this.$refs.PassNo.focus();
        this.$refs.PassNo.validate(true);
        checkvalid = true;
      }
      if (!this.$refs.ExpiryDate.validate()) {
        this.$refs.ExpiryDate.focus();
        this.$refs.ExpiryDate.validate(true);
        checkvalid = true;
      }
      if (!this.$refs.Country.validate()) {
        this.$refs.Country.focus();
        this.$refs.Country.validate(true);
        checkvalid = true;
      }
      return checkvalid;
    },
  },
  mounted() {
    this.nowDate = moment().format(this.strYMD)
    this.employee = JSON.parse(localStorage.PROFILE)
    if (this.valPass != null && this.valPass !== undefined) {
      this.Passport.PassportNo = this.valPass.PassportNo;
      this.Passport.PassportType = this.valPass.PassportType;
      this.Passport.RequestNewPassport = this.valPass.RequestNewPassport;
      this.Passport.Country = this.valPass.Country;
      this.Passport.ExpiryDate = this.valPass.ExpiryDate;
      this.expiryDate.date = moment(this.valPass.ExpiryDate).format(this.strYMD);
      this.Passport.EMPLOYEE_ID = this._ID !== null && this._ID !== undefined ? this._ID : this.employee.EMPLOYEE_ID;
      this.Passport.PassportOID = this.valPass.PassportOID;
      this.country.push({
        text: this.valPass.Country,
        Code: "",
        Language: "",
        Name: "",
        Alpha3: null
      })
      this.GetFile("Passport");
    }
  },
};
</script>
<style scoped>
.bg-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 10px;
}

.remark {
  color: red;
}
</style>
