<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col class="h-space">
                <span class="font-W700" style="font-size: 1em;">Short Haul</span>
            </v-col>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <div style="float: right;">
                            <v-btn :disabled="shortHaulSelect.length === 0 ? true : false" @click="RemoveItemdialog.alert = true"
                            v-if="$store.state.buttonPermit.menuConfig.Delete" class="btn-table mr-2" elevation="0" >
                                <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1">
                                </v-img>
                                Delete
                            </v-btn>
                            <v-btn class="btn-table mr-2" elevation="0">
                                <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1">
                                </v-img>
                                Reset
                            </v-btn>
                            <v-btn class="btn-table" elevation="0" @click="addShortHaul()" v-if="$store.state.buttonPermit.menuConfig.Add">
                                <v-img :src="require('@/assets/icons/Add_trans.svg')" width="20px" class="mr-1">
                                </v-img>
                                Add
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="bgcard">
                            <v-data-table checkbox-color="primary" @page-count="pageCount = $event" :page.sync="page" :items-per-page="itemPage" @load="onPagination($event)"
                            :headers="ShortHaulHeader" item-key="ShortHaulOID" show-select v-model="shortHaulSelect"
                            @click:row="clickRow" :items="shortHaulList" hide-default-footer mobile-breakpoint>
                            </v-data-table>
                        </div>
                        <v-row class="mt-3 hidden-xs-only" no-gutters>
                            <v-col class="d-flex" cols="12">
                                <span class="mr-3 mt-1 f-text-18">Show</span>
                                <v-select outlined dense hide-details :items="itemsPerPage" item-text="text" item-value="value"
                                v-model="itemPage" background-color="white" class="select-data-table shrink"></v-select>
                                <span class="ml-3 mt-1 f-text-18">items per page</span>
                                <v-pagination v-model="page" :length="pageCount" :total-visible="5"
                                class="pagination-custom" style="width: 60%;"></v-pagination>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                            <v-col class="d-flex" cols="12">
                                <span class="mr-3 mt-1 f-text-18">Show</span>
                                <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                v-model="itemPage" background-color="white" class="select-data-table shrink"></v-select>
                                <span class="ml-3 mt-1 f-text-18">items per page</span>
                            </v-col>
                            <v-col>
                                <v-pagination v-model="page" :length="pageCount" :total-visible="5"
                                class="pagination-custom"></v-pagination>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog width="1000" v-if="shortHaulDialog.dialog" v-model="shortHaulDialog.dialog">
            <v-card class="card">
                <v-card-title>
                    {{shortHaulDialog.val.ShortHaulOID ? 'Edit' : 'Add'}} Short Haul
                </v-card-title>
                <v-card-text>
                    <br />
                    <div class="bgcard">
                        <v-row>
                            <v-col cols="6">
                                Origin Country
                                <v-autocomplete dense background-color="white"
                                item-value="Code" item-text="Name" @input.native="getCountryCodeShortHaul($event.srcElement.value)"
                                :items="countryList" outlined v-model="shortHaulDialog.val.OriginCountry"></v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                Origin City
                                <v-autocomplete dense background-color="white" chips small-chips color="primary" multiple deletable-chips
                                item-value="Code" item-text="text" chi
                                @input.native="GetCityCodeShortHaul($event.srcElement.value)"
                                :items="cityList" outlined v-model="shortHaulDialog.val.OriginCode">
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index < 2">
                                        <span>{{ item.text }}</span>
                                    </v-chip>
                                    <span v-if="index === 2" class="text-grey text-caption align-self-center">
                                    (+{{ shortHaulDialog.val.OriginCode.length - 2 }} others)
                                    </span>
                                </template>
                            </v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                Destination Country
                                <v-autocomplete dense background-color="white"
                                item-value="Code" item-text="Name" @input.native="getCountryCodeShortHaul($event.srcElement.value)"
                                :items="countryList" outlined v-model="shortHaulDialog.val.DestinationCountry"></v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                Destination City
                                <v-autocomplete dense background-color="white" chips small-chips color="primary" multiple deletable-chips
                                item-value="Code" item-text="text"
                                @input.native="GetCityCodeShortHaul($event.srcElement.value)"
                                :items="cityList" outlined v-model="shortHaulDialog.val.DestinationCode">
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index < 3">
                                        <span>{{ item.text }}</span>
                                    </v-chip>
                                    <span v-if="index === 3" class="text-grey text-caption align-self-center">
                                    (+{{ shortHaulDialog.val.DestinationCode.length - 3 }} others)
                                    </span>
                                </template>
                            </v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="shortHaulDialog.dialog = false">Cancel</v-btn>
                    <v-btn color="primary" @click="shortHaulSave">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="RemoveItemdialog.alert" width="500">
            <v-card class="card">
                <v-card-title>
                    Remove
                </v-card-title>
                <v-card-text>
                    would you like to delete Short Haul ?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small text>Cancel</v-btn>
                    <v-btn small color="primary" :loading="RemoveItemdialog.processing" @click="removeShortHaul()">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            itemsPerPage:[
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemPage: 5,
            page: 1,
            pageCount: 2,
            RemoveItemdialog: {
                alert: false,
                processing: false
            },
            ShortHaulHeader:  [
                { text: "Origin Country", value: 'OriginCountry'},
                { text: "Origin Code", value: 'OriginCode' },
                { text: "Destination Country", value: 'DestinationCountry' },
                { text: "Destination Code", value: 'DestinationCode' },
            ],
            countryList: [],
            cityList: [],
            shortHaulList: [],
            shortHaulSelect: [],
            shortHaulDialog: {
                val: null,
                dialog: false
            }
        }
    },
    watch: {
    },
    methods: {
        removeShortHaul(){
            this.RemoveItemdialog.processing = true
            console.log(this.shortHaulSelect.map(x => x.ShortHaulOID));
            if(this.shortHaulSelect.length > 0){
                axios.post(this.$store.state.API_PATH + 'ShortHaul/RemoveShortHaul', this.shortHaulSelect.map(x => x.ShortHaulOID.toString()), {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    this.getShortHaul()
                    this.RemoveItemdialog.processing = false
                    this.RemoveItemdialog.alert = false
                })
            }
        },
        clickRow(val){
            if(this.$store.state.buttonPermit.menuConfig.Update){
                this.shortHaulDialog.dialog = true
                this.shortHaulDialog.val = JSON.parse(JSON.stringify(val))
                this.shortHaulDialog.val.OriginCode = typeof(val.OriginCode) === 'string' &&
                 val.OriginCode.split(',').length > 0 ?
                  val.OriginCode.split(',') : val.OriginCode
                this.shortHaulDialog.val.DestinationCode = typeof(val.DestinationCode) === 'string' &&
                 val.DestinationCode.split(',').length > 0 ?
                  val.DestinationCode.split(',') : val.DestinationCode
                this.getCountryCodeShortHaul(val.OriginCountry)
                this.getCountryCodeShortHaul(val.DestinationCountry)
                this.cityList = [{text: `Select All`, Code: '*'}]
                this.shortHaulDialog.val.OriginCode.forEach(item => {
                    this.GetCityCodeShortHaul(item)
                })
                this.shortHaulDialog.val.DestinationCode.forEach(item => {
                    this.GetCityCodeShortHaul(item)
                })
            }
        },
        getShortHaul(){
            axios.post(`${this.$store.state.API_PATH}ShortHaul/GetShortHaul`, {}, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                if(res.data.Status){
                    this.shortHaulList = res.data.Result
                    const menuConfigData = localStorage.getItem('menuConfig');
                    this.$store.state.menuConfig = JSON.parse(menuConfigData);
                    console.log(this.$store.state.menuConfig);
                }
            }).catch(err =>{
            })
        },
        GetCityCodeShortHaul(input) {
            axios.post(`${this.$store.state.API_PATH}CityCode/GetCityCode?Name=${input}&pLanguage=en`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                if (res.data.length > 0) {
                    res.data.forEach(elm => {
                        this.cityList.push({ text: `${elm.Name} (${elm.Code})`, Code: elm.Code })
                    })
                }
            })
                .catch(err => err)
        },
        getCountryCodeShortHaul(input) {
        if (input) {
            const checkItem = this.countryList.filter((el) => el.text.toLowerCase().substr(0, input.length) === input.toLowerCase());
            if (checkItem.length === 0) {
                axios.post(`${this.$store.state.API_PATH}CountryCode/GetCountryCode`,
                    {
                        Code: input,
                        Language: this.$store.state.Language,
                    },
                    {
                        headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
                    })
                    .then((res) => {
                        if (res.data.Status) {
                            this.country = [];
                            res.data.Result.forEach((elm) => {
                                this.countryList.push({
                                    ...elm,
                                    text: `${elm.Name} (${elm.Code})`,
                                });
                            });
                        }
                    })
                    .catch((err) => err);
                }
            }
        },
        shortHaulSave(){
            this.shortHaulDialog.val.OriginCode = this.shortHaulDialog.val.OriginCode.toString()
            this.shortHaulDialog.val.DestinationCode = this.shortHaulDialog.val.DestinationCode.toString()
            if(this.shortHaulDialog.val.ShortHaulOID === 0){
                console.log(this.shortHaulDialog.val);
                axios.post(`${this.$store.state.API_PATH}ShortHaul/AddShortHaul`, this.shortHaulDialog.val, {
                    headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
                })
                .then((res) => {
                    this.getShortHaul()
                    this.shortHaulDialog = {
                        val: null,
                        dialog: false
                    }
                });
            }else{
                axios.post(`${this.$store.state.API_PATH}ShortHaul/ModifyShortHaul`, this.shortHaulDialog.val, {
                    headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
                })
                .then((res) => {
                    this.getShortHaul()
                    this.shortHaulDialog = {
                        val: null,
                        dialog: false
                    }
                });
            }
        },
        addShortHaul(){
            this.cityList = [{text: `Select All`, Code: '*'}]
            this.shortHaulDialog.dialog = true
            this.shortHaulDialog.val = {
               ShortHaulOID: 0,
               OriginCountry: null,
               OriginCode: null,
               DestinationCountry: null,
               DestinationCode: null
            }
        }
    },
    mounted(){
        this.getShortHaul()
    }
}
</script>
