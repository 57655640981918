<template>
        <div>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <span>
                        <span class="font-W700" style="font-size: 1em;">Embassy</span>
                    </span>
                </v-col>
            </v-row>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row gutters align="center" justify="center">
                                <v-col sm="4" lg="2" cols="8">
                                    EmbID
                                    <v-text-field outlined dense background-color="white"
                                    v-model="EmbID" @keypress.enter="getEmbassyBySearch()"></v-text-field>
                                </v-col>
                                <v-col sm="4" lg="2" cols="8">
                                    Code
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Code" @keypress.enter="getEmbassyBySearch()"></v-text-field>
                                </v-col>
                                <v-col sm="4" lg="2" cols="8">
                                    Name1
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Name1" @keypress.enter="getEmbassyBySearch()"></v-text-field>
                                </v-col>
                                 <v-col sm="4" lg="2" cols="8">
                                    Address1
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Address1" @keypress.enter="getEmbassyBySearch()"></v-text-field>
                                </v-col>
                                <v-col sm="4" lg="2" cols="8">
                                    Address2
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Address2" @keypress.enter="getEmbassyBySearch()"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters align="center" justify="center">
                                <v-col md="auto" sm="auto" >
                                    <v-btn color="primary"
                                      @click="getEmbassyBySearch()" :loading="searchbtnLoading"
                                      elevation="0"
                                      class="mb-lg-0 mb-md-0 mb-sm-0 mb-6">Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row no-gutters align="center">
                                <v-col class="text-lg-right text-sm-right text-center">
                                    <v-btn class="btn-table mr-2" elevation="0" @click="deleteEmbassy()" v-if="$store.state.buttonPermit.menuConfig.Delete">
                                        <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1"></v-img>
                                        Delete
                                    </v-btn>
                                    <v-btn class="btn-table mr-2" elevation="0" @click="resetInfo()">
                                        <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1"></v-img>
                                        Reset
                                    </v-btn>
                                    <v-btn class="btn-table" elevation="0" @click="actionEmbassy('Add')" v-if="$store.state.buttonPermit.menuConfig.Add">
                                        <v-img :src="require('@/assets/icons/Add_trans.svg')" class="mr-1"></v-img>
                                        Add
                                    </v-btn>
                                    <v-dialog v-if="dialogAddOrEditEmbassy" v-model="dialogAddOrEditEmbassy" persistent
                                        width="600px">
                                        <v-card elevation="0" title color="primary" class="pt-1 card">
                                            <v-card>
                                                <v-card-text>
                                                    <v-card class="card-detail" title elevation="0">
                                                        <v-card-title>
                                                            <v-row no-gutters>
                                                                <v-col class="text-center">
                                                                    {{ AddOrEdit }} Embassy
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row gutters>
                                                            <v-col>
                                                                    Code
                                                                    <v-text-field  outlined dense background-color="white" ref="Code"
                                                                    v-model="Code"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row gutters>
                                                                 <v-col>
                                                                    Name1
                                                                     <v-text-field outlined dense background-color="white" ref="Name1"
                                                                    v-model="Name1"></v-text-field>
                                                                </v-col>
                                                                <v-col>
                                                                    Name2
                                                                     <v-text-field outlined dense background-color="white" ref="Name1"
                                                                    v-model="Name2"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row gutters>
                                                                <v-col>
                                                                    Address1
                                                                     <v-text-field outlined dense background-color="white" ref="Address1"
                                                                    v-model="Address1"></v-text-field>
                                                                </v-col>
                                                                <v-col>
                                                                    Address2
                                                                     <v-text-field outlined dense background-color="white" ref="Address2"
                                                                    v-model="Address2"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row gutters>
                                                                <v-col>
                                                                    Address3
                                                                     <v-text-field outlined dense background-color="white" ref="Address3"
                                                                    v-model="Address3"></v-text-field>
                                                                </v-col>
                                                                <v-col>
                                                                    Address4
                                                                     <v-text-field outlined dense background-color="white" ref="Address4"
                                                                    v-model="Address4"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row gutters>
                                                                <v-col>
                                                                    EmbTel
                                                                     <v-text-field outlined dense background-color="white" ref="EmbTel"
                                                                    v-model="EmbTel"></v-text-field>
                                                                </v-col>
                                                                <v-col>
                                                                    EmbFax
                                                                     <v-text-field outlined dense background-color="white" ref="EmbFax"
                                                                    v-model="EmbFax"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row gutters>
                                                                <v-col>
                                                                    EmbEmail
                                                                     <v-text-field outlined dense background-color="white" ref="EmbEmail"
                                                                    v-model="EmbEmail"></v-text-field>
                                                                </v-col>
                                                                <v-col>
                                                                    Cby
                                                                     <v-text-field outlined dense background-color="white" ref="Cby"
                                                                    v-model="Cby"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row gutters>
                                                                <v-col>
                                                                    Uby
                                                                     <v-text-field outlined dense background-color="white" ref="Uby"
                                                                    v-model="Uby"></v-text-field>
                                                                </v-col>
                                                                <v-col>
                                                                    Status
                                                                     <v-radio-group dense row v-model="Status">
                                                                        <v-radio key="1" value="1" label="Active"></v-radio>
                                                                        <v-radio key="0" value="0" label="Inactive"></v-radio>
                                                                    </v-radio-group>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col class="text-center">
                                                            <v-btn outlined color="primary" class="mr-1"
                                                            @click="closeAddOrEditEmbassy()">Close</v-btn>
                                                            <v-btn color="primary" class="ml-1" v-if="AddOrEdit === 'Add'"
                                                            @click="addEmbassyData()">Save</v-btn>
                                                            <v-btn color="primary" class="ml-1" v-else
                                                            @click="updateEmbassyData()">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <br>
                            <v-card elevation="0">
                                <v-data-table :headers="headerEmbassy" :items="itemEmbassyData" class="elevation-1" item-key="no"
                                @click:row="rowClicked" show-select v-model="selectedItemEmbassy" checkbox-color="primary"
                                mobile-breakpoint style="white-space: nowrap" hide-default-footer height="45vh"
                                @page-count="pageCountEmbassy = $event" :page.sync="page" :items-per-page="itemPage" @load="onPagination($event)">
                                </v-data-table>
                            </v-card>
                            <v-row class="mt-3 hidden-xs-only" no-gutters>
                                <v-col class="d-flex" cols="12">
                                    <span class="mr-3 mt-3">Show</span>
                                    <v-select outlined dense :items="itemsPerPageEmbassy" item-text="text" item-value="value"
                                        v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                    <span class="ml-3 mt-3">items per page</span>
                                    <v-pagination v-model="page" :length="pageCountEmbassy" :total-visible="5" class="pagination-custom"
                                        style="width: 60%;"></v-pagination>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                                <v-col class="d-flex" cols="12">
                                    <span class="mr-3 mt-3">Show</span>
                                    <v-select outlined dense :items="itemsPerPageEmbassy" item-text="text" item-value="value"
                                        v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                    <span class="ml-3 mt-3">items per page</span>
                                </v-col>
                                <v-col>
                                    <v-pagination v-model="page" :length="pageCountEmbassy" :total-visible="5" class="pagination-custom">
                                    </v-pagination>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="DialogRemove" width="500">
            <v-card>
                <v-card-title>
                    Remove
                </v-card-title>
                <v-card-text>
                    <template v-if="FileFocus">
                        would you like to remove '{{ FileFocus.FilePath.split('\\')[FileFocus.FilePath.split('\\').length - 1] }}' ?
                    </template>
                    <template v-else>
                        would you like to remove your passport account?
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small text @click="DialogRemove = false">Cancel</v-btn>
                    <v-btn small color="error" @click="remove(typeFocus, 'remove', null)">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </div>
</template>
<script>
import Swal from 'sweetalert2'
import axios from 'axios';
export default {
    data(){
        return{
            disable: false,
            searchbtnLoading: false,
            FileFocus: null,
            typeFocus: null,
            DialogRemove: false,
            files: [],
            EmbID: "",
            Code: "",
            Name1: "",
            Name2: "",
            Address1: "",
            Address2:"",
            Address3:"",
            Address4:"",
            EmbTel:"",
            EmbFax:"",
            EmbEmail:"",
            Cby: "",
            Uby: "",
            Status: "",
            AddOrEdit: "",
            dialogAddOrEditEmbassy: false,
            TEXT_SEARCH: "",
            itemPage: 10,
            page: 1,
            pageCountEmbassy: 2,
            headerEmbassy: [
                { text: 'No.', value: 'no', sortable: false },
                { text: 'EmbID', value: 'EmbID', sortable: false },
                { text: 'Code', value: 'Code', sortable: false },
                { text: 'Name1', value: 'Name1', sortable: false },
                { text: 'Address1', value: 'Address1', sortable: false },
                { text: 'Address2', value: 'Address2', sortable: false },
            ],
            itemEmbassyData: [],
            selectedItemEmbassy: [],
            itemsPerPageEmbassy: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            requireField: "This field is required",
            rules:{
                EmbID: [(val) => (val || "").length > 0 || this.requireField],
                Code: [(val) => (val || "").length > 0 || this.requireField],
                Name1: [(val) => (val || "").length > 0 || this.requireField],
                Address1: [(val) => (val || "").length > 0 || this.requireField],
                Address2: [(val) => (val || "").length > 0 || this.requireField],
            }
        }
    },
    methods:{
        remove(type, processing, val) {
            this.typeFocus = type
            if (type === 'BedTypeFile') {
                if (processing === 'alert') {
                    this.FileFocus = val
                }
                if (processing === 'remove') {
                    this.removeFiles(this.FileFocus, 'Saved')
                    this.DialogRemove = false
                }
            }
        },
        checkvalidate(){
            let result = false
            if (!this.$refs.EmbID.validate()) {
                this.$refs.EmbID.validate(true)
                this.$refs.EmbID.focus()
                result = true
            }
            if (!this.$refs.Code.validate()) {
                this.$refs.Code.validate(true)
                this.$refs.Code.focus()
                result = true
            }
            if (!this.$refs.Name1.validate()) {
                this.$refs.Name1.validate(true)
                this.$refs.Name1.focus()
                result = true
            }
            if (!this.$refs.Address1.validate()) {
                this.$refs.Address1.validate(true)
                this.$refs.Address1.focus()
                result = true
            }
            if (!this.$refs.Address2.validate()) {
                this.$refs.Address2.validate(true)
                this.$refs.Address2.focus()
                result = true
            }
            return result
        },
        updateEmbassyData(){
                this.dialogAddOrEditEmbassy = false
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}Embassy/UpdateEmbassy`, {
                    EmbID: this.EmbID,
                    Code: this.Code,
                    Name1: this.Name1,
                    Name2: this.Name2,
                    Address1: this.Address1,
                    Address3: this.Address3,
                    Address2: this.Address2,
                    Address4: this.Address4,
                    EmbFax: this.EmbFax,
                    EmbTel: this.EmbTel,
                    Cby: this.Cby,
                    EmbEmail: this.EmbEmail,
                    Uby: this.Uby,
                    Status: this.Status,
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.SwalAlertEmbassy(res)
                })
        },
        SwalAlertEmbassy(res){
            if(res.data.Status){
                Swal.fire({
                    icon: 'success',
                    text: 'Success',
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.closeAddOrEditEmbassy()
                    this.getEmbassyData()
                }, () => {})
            }
            else{
                Swal.fire({
                    icon: 'error',
                    text: res.data.ErrorMessage,
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.dialogAddExternal = true
                }, () => { })
            }
        },
        closeAddOrEditEmbassy(){
            this.dialogAddOrEditEmbassy = false
            this.EmbID = ""
            this.Code = ""
            this.Name1 = ""
            this.Name2 = ""
            this.Address1 = ""
            this.Address2 = ""
            this.Address3 = ""
            this.Address4 = ""
            this.EmbTel = ""
            this.EmbFax = ""
            this.EmbEmail = ""
            this.Cby = ""
            this.Uby = ""
            this.Status = ""
            this.AddOrEdit = ""
            this.disable = false
        },
        addEmbassyData(){
                this.dialogAddOrEditEmbassy = false
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}Embassy/InsertEmbassy`, {
                    EmbID: this.EmbID,
                    Code: this.Code,
                    Name1: this.Name1,
                    Address1: this.Address1,
                    Name2: this.Name2,
                    Address2: this.Address2,
                    Address3: this.Address3,
                    Address4: this.Address4,
                    EmbTel: this.EmbTel,
                    EmbFax: this.EmbFax,
                    Cby: this.Cby,
                    EmbEmail: this.EmbEmail,
                    Uby: this.Uby,
                    Status: this.Status,
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.SwalAlertEmbassy(res)
                }).catch(() => {
                    this.$store.state.LOADING = false
                })
        },
        actionEmbassy(AddOrEdit){
            this.AddOrEdit = AddOrEdit
            this.dialogAddOrEditEmbassy = true
        },
        resetInfo(){
            this.selectedItemEmbassy = []
            this.TEXT_SEARCH = ""
            this.getEmbassyData()
        },
        deleteEmbassy(){
            if(this.selectedItemEmbassy.length > 0){
                Swal.fire({
                    icon: 'warning',
                    text: 'would you like to to delete?',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    confirmButtonColor: '#00AEEF'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.state.LOADING = true
                        axios.post(this.$store.state.API_PATH + 'Embassy/DeleteEmbassy', {
                            selectedDelItem: this.selectedItemEmbassy
                        }, {
                            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        })
                        .then(res => {
                            this.$store.state.LOADING = false
                            if (res.data.Status) {
                                Swal.fire({
                                    icon: 'success',
                                    text: 'Success',
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                    this.selectedItemEmbassy = []
                                    this.getEmbassyData()
                                },()=>{})
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    text: res.data.ErrorMessage,
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                },()=>{})
                            }
                        })
                        .catch(() => {
                            this.$store.state.LOADING = false
                        })
                    }
                })
            }
        },
        getEmbassyData(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}Embassy/GetEmbassy`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.itemEmbassyData = res.data.Result
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        getEmbassyBySearch(){
            console.log(this.EmbID);
                this.searchbtnLoading = true
                setTimeout(() => {
                    axios.post(this.$store.state.API_PATH + 'Embassy/GetEmbassyBySearch', {
                        EmbID: this.EmbID,
                        Code: this.Code,
                        Name1: this.Name1,
                        Address1: this.Address1,
                        Address2: this.Address2
                    }, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    }).then(res => {
                        this.$store.state.LOADING = false
                    if (res.data.Status) {
                        console.log(res.data.Result);
                        this.itemEmbassyData = res.data.Result
                        this.searchbtnLoading = false
                    } else {
                         this.searchbtnLoading = false
                    }
                    }).catch(() => {
                        this.$store.state.LOADING = false
                         this.searchbtnLoading = false
                    })
                }, 1000);
            },
        rowClicked(item){
            if(this.$store.state.buttonPermit.menuConfig.Update){
                this.AddOrEdit = "Edit";
                this.EmbID = item.EmbID;
                this.Code = item.Code;
                this.Name1 = item.Name1;
                this.Name2 = item.Name2;
                this.Address1 = item.Address1;
                this.Address2 = item.Address2;
                this.Address3 = item.Address3;
                this.Address4 = item.Address4;
                this.EmbTel = item.EmbTel;
                this.EmbFax = item.EmbFax;
                this.EmbEmail = item.EmbEmail;
                this.Cby = item.Cby;
                this.Uby = item.Uby;
                this.Status = item.Status;
                this.dialogAddOrEditEmbassy = true;
            }
        },
    },
    mounted(){
        this.getEmbassyData()
    }
}
</script>
<style scoped>
.font-W700{
    font-weight: 700;
}
.font-W400{
    font-weight: 400;
}
.btn-table{
    background: rgba(0, 174, 239, 0.1) !important;
}
</style>
