<template>
    <v-card class="card">
        <div style="padding: 8px 10px 0px 10px">
            <v-row v-if="flightRequest != null && coperateData != null">
                <v-col cols="12" md="6">
                    <div class="hidden-md-and-up text-center">
                        <strong>{{flightRequest.originName}} ({{flightRequest.originCode}})</strong>
                        &nbsp;
                        <img :src="require('../../assets/icons/tweenArrows.svg')" width="13" alt="" />
                        &nbsp;
                        <strong>{{flightRequest.destinationName}} ({{flightRequest.destinationCode}})</strong>
                        <br />
                        <span style="font-size: 0.8rem;">
                            {{formatDate(flightRequest.departDate)}} - {{formatDate(flightRequest.returnDate)}} |
                            {{(flightRequest.adult) + (flightRequest.child) + (flightRequest.infant) }}
                            <span v-if="parseInt((flightRequest.adult) + (flightRequest.child) + (flightRequest.infant)) > 1 ">
                                passengers
                            </span>
                            <span v-else>
                                passenger
                            </span>
                        </span>
                    </div>
                    <div class="hidden-sm-and-down">
                        <strong>{{flightRequest.originName}} ({{flightRequest.originCode}})</strong>
                        &nbsp;
                        <img :src="require('../../assets/icons/tweenArrows.svg')" width="13" alt="" />
                        &nbsp;
                        <strong>{{flightRequest.destinationName}} ({{flightRequest.destinationCode}})</strong>
                        <br />
                        <span style="font-size: 0.8rem;">
                            {{formatDate(flightRequest.departDate)}} - {{formatDate(flightRequest.returnDate)}} |
                            {{(flightRequest.adult) + (flightRequest.child) + (flightRequest.infant) }}
                            <span v-if="parseInt((flightRequest.adult) + (flightRequest.child) + (flightRequest.infant)) > 1 ">
                                Passengers
                            </span>
                            <span v-else>
                                Passenger
                            </span>
                        </span>
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <v-row v-if="pathname != '/tripSummary'">
                        <v-col cols="12" xs="12" md="8">
                            <v-text-field outlined dense disabled class="mt-1" :value="'TRNO.' + coperateData.TRNo" hide-details></v-text-field>
                            <!-- TR No.R13000001 -->
                        </v-col>
                        <v-col cols="12" xs="12" md="4" class="text-center">
                            <div style="height: 6px;" class="hidden-sm-and-down"></div>
                            <v-btn block color="primary" style="height: 37px; width: 100px;" @click="flightdialog()"><span
                                    style="font-size: .6rem;" >Change Flight</span></v-btn>
                        </v-col>
                    </v-row>
                    <div style="height: 8px"></div>
                </v-col>
            </v-row>
        </div>
        <div v-if="travelers != null">
            <v-dialog v-model="sflightdialog" width="1100">
                <FlightBooking :selectTraveler="travelers.filter(elm => elm.TravelCard != null && elm.TravelCard.IsDelete == 0)[0]"
                    :travelers="travelers.filter(elm => elm.TravelCard != null && elm.TravelCard.IsDelete == 0)"
                    :key="reloading" :coperateData="coperateData" @closeflightbook="sflightdialog = $event" />
            </v-dialog>
        </div>
    </v-card>
</template>

<script>
import moment from 'moment';
import FlightBooking from './FlightBooking.vue';

export default {
    props:{
        flightRequest: Object,
        coperateData: Object
    },
    data() {
        return {
            travel: null,
            reloading: 0,
            isActive: false,
            sflightdialog: false,
            travelers: null,
            pathname: ''
        }
    },
    methods: {
        pagereloading() {
            this.reloading += 1
        },
        formatDate(val){
            return moment(val).format('DD MMM YYYY')
        },
        ShowDetails() {
            this.isActive = !this.isActive;
        },
        flightdialog(){
            this.sflightdialog = !this.sflightdialog
            this.pagereloading()
        }
    },
    components:{
        FlightBooking,
    },
    mounted(){
        this.pathname = window.location.pathname
        this.travelers = JSON.parse(sessionStorage.travelers)
        // console.log(this.flightRequest)
    }
}
</script>

<style>
.timetoflight {
    position: absolute;
    top: 47%;
    right: 100%;
}

.lineplane {
    position: relative;
}
.font-W700 {
    font-weight: 700;
}
.hideDetails {
    display: none;
}

#pricing-airline {
    float: right;
}

@media screen and (max-width: 1000px) {
    #pricing-airline {
        float: left;

    }

    .show-details {
        display: none
    }
}

.text-profile {
    color: black;
    font-weight: 500;
}
</style>
