<template>
    <v-container fluid fill-height v-if="!$store.state.LOADING">
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-row>
                        <v-col class="text-center">
                            <span><strong>งบประมาณค่าใช้จ่ายในการเดินทาง</strong></span><br>
                            <span><strong>ประกอบการยื่นขอหนังสือเดินทางราชการ</strong></span>
                        </v-col>
                    </v-row>
                    <v-row class="ml-4 mr-4">
                        <v-col cols="12" >
                            <span class="ml-12">
                                ตามที่บริษัท ปตท. สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน) (ปตท.สผ.) ได้มอบหมายให้ {{ $store.state.docPassport.nameTH }}
                                ตำแหน่ง {{ $store.state.docPassport.posiThai }} ไปปฏิบัติงานที่{{$store.state.docPassport.country}}
                                ระหว่างวันที่ {{$store.state.docPassport.dateStart}} - {{$store.state.docPassport.dateEnd}}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="mt-4 ml-4 mr-4">
                        <v-col cols="12" >
                            <span class="ml-12">
                                งบประมาณค่าใช้จ่ายโดยเบิกจ่ายตามระเบียบ ปตท.สผ. ดังมีรายการต่อไปนี้
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2 ml-4 mr-4" align="center">
                        <v-col cols="12" lg="4" md="4" sm="6" class="d-flex d-column-flex align-center">
                            <span class="ml-12 mr-2">
                                ค่าตั๋วเครื่องบินไป-กลับ
                            </span>
                            <v-text-field outlined dense hide-details style="width: 250px;" class="shrink" v-model="$store.state.docPassport.flight"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="3" md="3" sm="6" class="d-flex d-column-flex align-center">
                            <span class="ml-12 mr-2">
                                =
                            </span>
                            <v-text-field outlined dense hide-details style="width: 150px;" class="shrink" v-model="$store.state.docPassport.costFlight"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2 ml-4 mr-4" align="center">
                        <v-col cols="12" lg="4" md="4" sm="6" class="d-flex d-column-flex align-center">
                            <span class="ml-12 mr-2 text-right">
                                รวมค่าใช้จ่ายทั้งสิ้นเป็นเงิน
                            </span>
                        </v-col>
                        <v-col cols="12" lg="3" md="3" sm="6" class="d-flex d-column-flex align-center">
                            <span class="ml-12 mr-2">
                                =
                            </span>
                            <v-text-field outlined dense hide-details style="width: 150px;" class="shrink" v-model="$store.state.docPassport.costTotal"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2 ml-4 mr-4" align="center">
                        <v-col cols="12" lg="4" md="4" sm="6" class="d-flex d-column-flex align-center">
                            <v-text-field outlined dense hide-details style="width: 350px;" class="shrink ml-12" v-model="$store.state.docPassport.txtOther"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="ml-4 mr-4 mt-10">
                        <v-col cols="4" lg="7" md="7" sm="6"></v-col>
                        <v-col>
                            <v-text-field outlined dense hide-details v-model="$store.state.docPassport.nameBudget" style="width: 250px;"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="ml-4 mr-4">
                        <v-col cols="4" lg="7" md="7" sm="6"></v-col>
                        <v-col>
                            <v-text-field outlined dense hide-details v-model="$store.state.docPassport.posiBudget" style="width: 350px;"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-5 ml-4 mr-4">
                        <v-col cols="12" lg="3" md="3" sm="6">
                            <v-text-field outlined dense hide-details v-model="$store.state.docPassport.nameBTT"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2 ml-4 mr-4">
                        <v-col cols="12" lg="3" md="3" sm="6">
                            <v-text-field outlined dense hide-details v-model="$store.state.docPassport.posiBTT"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn color="primary" elevation="0" @click="printPDF()">Print To PDF</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import pdfFonts from '@/assets/custom-font.js'
export default {
    data(){
        return{
            docNo: "",
            TRNo: "",
            EMP_ID: "",
            Type: "",
        }
    },
    methods:{
        async printPDF(){
            pdfMake.vfs = pdfFonts.pdfMake.vfs
            pdfMake.fonts = {
                CordiaNew: {
                    normal: "CordiaNew.ttf",
                    bold: "CordiaNewBold.ttf",
                }
            }
            const doc = {
                content:[
                    {
                        text: "งบประมาณค่าใช้จ่ายในการเดินทาง",
                        style: "font16Bold",
                        alignment: 'center',
                        margin: [0, 10, 0, 0]
                    },
                    {
                        text: "ประกอบการยื่นขอหนังสือเดินทางราชการ",
                        style: "font16Bold",
                        alignment: 'center',
                        margin: [0, 5, 0, 0]
                    },
                    {
                        style: "font16",
                        margin: [0, 20, 0, 0],
                        columns: [
                            {
                                text: "",
                                width: 70
                            },
                            {
                                text: `ตามที่บริษัท ปตท. สำรวจและผลิตปิโตรเลียม จำกัด (มหาชน) (ปตท.สผ.) ได้มอบหมายให้`
                            }
                        ]
                    },
                    {
                        text: `${this.$store.state.docPassport.nameTH} ตำแหน่ง ${this.$store.state.docPassport.posiThai}` +
                        ` ไปปฏิบัติงานที่${this.$store.state.docPassport.country}` +
                        ` ระหว่างวันที่ ${this.$store.state.docPassport.dateStart} - ${this.$store.state.docPassport.dateEnd} นั้น`,
                        style: "font16",
                    },
                    {
                        style: "font16",
                        margin: [0, 20, 0, 0],
                        columns: [
                            {
                                text: "",
                                width: 70
                            },
                            {
                                text: `งบประมาณค่าใช้จ่ายโดยเบิกจ่ายตามระเบียบ ปตท.สผ. ดังมีรายการต่อไปนี้`
                            }
                        ]
                    },
                    {
                        style: "font16",
                        margin: [50, 10, 50, 0],
                        alignment: "center",
                        columns: [
                            {
                                alignment: "left",
                                width: "60%",
                                text: `ค่าตั๋วเครื่องบินไป-กลับ ${this.$store.state.docPassport.flight}`,
                            },
                            {
                                alignment: "center",
                                width: 10,
                                text: "="
                            },
                            {
                                width: 150,
                                alignment: "right",
                                text: `${ Number(this.$store.state.docPassport.costFlight).toLocaleString('th', { maximumFractionDigits: 2 }) } บาท`
                            },
                        ]
                    },
                    {
                        style: "font16",
                        margin: [50, 10, 50, 0],
                        alignment: "center",
                        columns: [
                            {
                                alignment: "left",
                                width: "60%",
                                text: `รวมค่าใช้จ่ายทั้งสิ้นเป็นเงิน`,
                            },
                            {
                                alignment: "center",
                                width: 10,
                                text: "="
                            },
                            {
                                width: 150,
                                alignment: "right",
                                text: `${ Number(this.$store.state.docPassport.costTotal).toLocaleString('th', { maximumFractionDigits: 2 }) } บาท`
                            },
                        ]
                    },
                    {
                        style: "font16",
                        margin: [50, 10, 50, 0],
                        alignment: "center",
                        columns: [
                            {
                                alignment: "left",
                                width: "60%",
                                text: `${this.$store.state.docPassport.txtOther}`,
                            },
                            {
                                width: 10,
                                text: ""
                            },
                            {
                                width: 150,
                                text: ""
                            },
                        ]
                    },
                    {
                        style: "font16",
                        margin: [0, 100, 0, 0],
                        alignment: "center",
                        columns: [
                            {
                                text: "",
                                width: 160
                            },
                            {
                                text: [
                                    this.$store.state.docPassport.nameBudget, '\n',
                                    this.$store.state.docPassport.posiBudget, '\n',
                                ]
                            }
                        ]
                    },
                    {
                        style: "font16",
                        margin: [0, 60, 0, 0],
                        text: [
                            'ผู้ประสานงาน: ', this.$store.state.docPassport.nameBTT, '\n',
                            this.$store.state.docPassport.posiBTT, '\n',
                        ]
                    },
                ],
                pageSize: "A4",
                styles:{
                    font16: {
                        alignment: 'left',
                        fontSize: 16
                    },
                    font16Bold: {
                        fontSize: 16,
                        bold: true,
                    },
                },
                defaultStyle: {
                    font: 'CordiaNew'
                }
            }
            pdfMake.createPdf(doc).download(`RptOffPss03_${this.$store.state.docPassport.nameTH}.pdf`)
        }
    },
    mounted(){
        if(this.$route.query.TRNo && this.$route.query.EMP_ID && this.$route.query.Type){
            this.TRNo = this.$route.query.TRNo
            this.EMP_ID = this.$route.query.EMP_ID
            this.Type = this.$route.query.Type
            this.$store.commit("docPassport/getDocPassport", {
                TRNo: this.TRNo,
                EMP_ID: this.EMP_ID,
                Type: this.Type,
            })
        }
    }
}
</script>
