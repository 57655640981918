<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <span>
                    <div class="font-W700 f-page h-space">Group List</div>
                </span>
            </v-col>
        </v-row>
        <v-card class="card" elevation="0">
            <v-card-text>
                <div class="f-main-header h-space font-W700">Search</div>
                <div>
                    <v-row align="center" class="bgcard" no-gutters>
                        <v-col lg="6" sm="8" cols="12" class="pr-4">
                            Group Name
                            <v-text-field dense outlined background-color="white" hide-details></v-text-field>
                        </v-col>
                        <v-col lg="1" sm="4" cols="12" class="text-lg-left text-sm-left text-center">
                            <br />
                            <v-btn color="primary" elevation="0" class="mb-lg-0 mb-md-0 mb-sm-0 mb-6">Search
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <br />
            </v-card-text>
        </v-card>
        <br />
        <v-card class="card" elevation="0">
            <v-card-text>
                <v-row no-gutters align="center">
                    <v-col class="text-lg-right text-sm-right text-center hidden-xs-only hidden-sm-only">
                        <v-btn class="btn-table mr-2" elevation="0" @click="DialogRemove()" :disabled="selectGroup.length ? false : true">
                            <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1">
                            </v-img>
                            Delete
                        </v-btn>
                        <v-btn class="btn-table mr-2" elevation="0">
                            <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1">
                            </v-img>
                            Reset
                        </v-btn>
                        <v-btn class="btn-table" elevation="0" @click="addGroup()">
                            <v-img :src="require('@/assets/icons/Add_trans.svg')" width="20px" class="mr-1">
                            </v-img>
                            Add
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="hidden-md-and-up mt-3 flex-nowrap text-center" no-gutters>
                    <v-col>
                        <v-btn class="btn-table mr-2" elevation="0" @click="DialogRemove()" :disabled="selectGroup.length ? false : true"
                        v-if="$store.state.buttonPermit.menuConfig.Delete">
                            <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1">
                            </v-img>
                            Delete
                        </v-btn>
                        <v-btn class="btn-table mr-2" elevation="0">
                            <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1">
                            </v-img>
                            Reset
                        </v-btn>
                        <v-btn class="btn-table" elevation="0" @click="addGroup()" v-if="$store.state.buttonPermit.menuConfig.Add">
                            <v-img :src="require('@/assets/icons/Add_trans.svg')" width="20px" class="mr-1">
                            </v-img>
                            Add
                        </v-btn>
                    </v-col>
                </v-row>
                <br />
                <div class="bgcard">
                    <v-data-table class="card" :headers="Groupheaders" item-key="UserGrpID" show-select v-model="selectGroup" :items="GroupList" @click:row="getGroup">
                        <template v-slot:[`item.no`]="{index}">
                            {{ (++index) }}
                        </template>
                        <template v-slot:[`item.DefaultGroup`]="{item}">
                            <v-img v-if="item.DefaultGroup" class="ml-3" width="13" :src="require('@/assets/icons/Green_check.png')"></v-img>
                        </template>
                    </v-data-table>
                </div>
                <v-dialog v-if="GroupFocus.Dialog && GroupFocus.value" v-model="GroupFocus.Dialog" persistent>
                    <v-card>
                        <v-card-title>{{ GroupFocus.value.Name }}</v-card-title>
                        <v-card-text>
                            <div class="f-main-header h-space font-W700">Group Data</div>
                            <div>
                                <v-row align="center" class="bgcard" no-gutters>
                                    <v-col lg="6" sm="12" cols="12" class="pr-4">
                                        Order
                                        <v-text-field dense outlined background-color="white"></v-text-field>
                                    </v-col>
                                    <v-col lg="6" sm="12" cols="12" class="pr-4">
                                    </v-col>
                                    <v-col lg="6" sm="12" cols="12" class="pr-4">
                                        Group Name
                                        <v-text-field dense outlined v-model="GroupFocus.value.Name" background-color="white"></v-text-field>
                                    </v-col>
                                    <v-col lg="6" sm="12" cols="12" class="pr-4">
                                        Description
                                        <v-text-field dense outlined background-color="white" v-model="GroupFocus.value.Description"></v-text-field>
                                    </v-col>
                                    <v-col lg="6" sm="12" cols="12" class="pr-4">
                                        <v-checkbox v-model="GroupFocus.value.DefaultGroup" dense hide-details label="Default Role"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </div>
                            <br />
                            <div class="bgcard" v-if="GroupFocus.value && GroupFocus.value.UserPermission && GroupFocus.value.UserPermission.length > 0">
                                <v-data-table class="card" :headers="Detailheaders" :items="GroupFocus.value.UserPermission" @click:row="GroupRoleDialog = true">
                                    <template v-slot:[`item.no`]="{index}">
                                        {{ (++index) }}
                                    </template>
                                    <template v-slot:[`item.MenuName`]="{item}">
                                        <div v-if="item.MenuModels">{{item.MenuModels.SubTitleEN ? item.MenuModels.SubTitleEN : item.MenuModels.TitleEN }}</div>
                                    </template>
                                    <template v-slot:[`item.View`]="{item}">
                                        <v-checkbox v-if="item.MenuModels && item.MenuModels.View > 0" v-model="item.View" :false-value="0" :true-value="1"
                                        @click="checkedTrueFalse(item)" :disabled="item.Status === '0'"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.Add`]="{item}">
                                        <v-checkbox v-if="item.MenuModels && item.MenuModels.Add > 0" v-model="item.Add" :false-value="0" :true-value="1"
                                        @click="checkedTrueFalse(item)" :disabled="item.Status === '0'"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.Update`]="{item}">
                                        <v-checkbox v-if="item.MenuModels && item.MenuModels.Update > 0" v-model="item.Update" :false-value="0" :true-value="1"
                                        @click="checkedTrueFalse(item)" :disabled="item.Status === '0'"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.Delete`]="{item}">
                                        <v-checkbox v-if="item.MenuModels && item.MenuModels.Delete > 0" v-model="item.Delete" :false-value="0" :true-value="1"
                                        @click="checkedTrueFalse(item)" :disabled="item.Status === '0'"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.All`]="{item}">
                                        <v-checkbox v-if="item.MenuModels && item.MenuModels.All > 0" v-model="item.All" :false-value="0" :true-value="1"
                                        @click="checkAll(item)" :disabled="item.Status === '0'"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.Approve`]="{item}">
                                        <v-checkbox v-if="item.MenuModels && item.MenuModels.Approve > 0"
                                        v-model="item.Approve" :false-value="0" :true-value="1" :disabled="item.Status === '0'"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.Revise`]="{item}">
                                        <v-checkbox v-if="item.MenuModels && item.MenuModels.Revise > 0"
                                        v-model="item.Revise" :false-value="0" :true-value="1" :disabled="item.Status === '0'"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.ReIssue`]="{item}">
                                        <v-checkbox v-if="item.MenuModels && item.MenuModels.ReIssue > 0"
                                        v-model="item.ReIssue" :false-value="0" :true-value="1" :disabled="item.Status === '0'"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.Status`]="{item}">
                                        <v-checkbox v-model="item.Status" false-value="0" true-value="1"></v-checkbox>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                                <v-btn large text @click="(GroupFocus.Dialog = false)">Back</v-btn>
                                <v-btn large color="primary" @click="saveUserPermission()">Save</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="openDialogRemove" width="500">
                    <v-card>
                        <v-card-title>
                            Remove Alert
                        </v-card-title>
                        <v-card-text>
                            Would you like to Remove?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small text @click="openDialogRemove = false">Cancel</v-btn>
                            <v-btn small color="error" @click="removeGroup()">Remove</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
    name: "GroupList",
    data(){
        return{
            openDialogRemove: false,
            selectGroup: [],
            GroupFocus: {
                Dialog: false,
                value: null
            },
            Groupheaders: [
                { text: "No", value: 'no', width: '10'},
                { text: "Default Group", value: 'DefaultGroup', width: '10'},
                { text: "Group Name", width: 20, value: 'Name' },
                { text: "Description", value: 'Description' },
                { text: "FunctionList", value: 'FucntionList' },
            ],
            Detailheaders: [
                { text: "No", value: 'no', width: '5'},
                { text: "Menu Name", value: 'MenuName'},
                { text: "View", width: 20, value: 'View' },
                { text: "Add", value: 'Add', width: '10'},
                { text: "Update", value: 'Update', width: '10'},
                { text: "Delete", value: 'Delete', width: '10'},
                { text: "All", value: 'All', width: '10'},
                { text: "Approve", value: 'Approve', width: '10'},
                { text: "Revise", value: 'Revise', width: '10'},
                { text: "Re-Issue", value: 'ReIssue', width: '10'},
                { text: "Active", value: 'Status', width: '10'},
            ],
            GroupList: [],
            MenuList: []
        }
    },
    methods:{
        UpdateUserGroup(){
            console.log(this.GroupFocus.value);
            axios.post(this.$store.state.API_PATH + 'Menu/UpdateUserGroup', this.GroupFocus.value, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
        },
        saveUserPermission(){
            this.GroupFocus.Dialog = false
            this.$store.state.LOADING = true

            this.UpdateUserGroup()
            axios.post(this.$store.state.API_PATH + 'Menu/InsertUserPermission', {
                UserPermission: this.GroupFocus.value.UserPermission,
                GroupName: this.GroupFocus.value.Name
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.$store.state.LOADING = false
                if(res.data.Status){
                    this.GroupList = res.data.Result
                    Swal.fire({
                        icon: 'success',
                        text: 'Success',
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                        this.GroupFocus.Dialog = false
                    },()=>{})
                }
                else{
                    Swal.fire({
                        icon: 'error',
                        text: res.data.ErrorMessage,
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                        this.GroupFocus.Dialog = true
                    },()=>{})
                }
            }).catch((err)=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        checkedTrueFalse(item){
            if((item.MenuModels.View === 1 && item.View === 0) || (item.MenuModels.Add === 1 && item.Add === 0) ||
            (item.MenuModels.Update === 1 && item.Update === 0) || (item.MenuModels.Delete === 1 && item.Delete === 0)){
                item.All = 0
            }
            else{
                item.All = 1
            }
        },
        checkAll(item){
            if(item.All === 1){
                if(item.MenuModels.View === 1){
                    item.View = 1
                }
                if(item.MenuModels.Add === 1){
                    item.Add = 1
                }
                if(item.MenuModels.Update === 1){
                    item.Update = 1
                }
                if(item.MenuModels.Delete === 1){
                    item.Delete = 1
                }
            }
            else{
                if(item.MenuModels.View === 1){
                    item.View = 0
                }
                if(item.MenuModels.Add === 1){
                    item.Add = 0
                }
                if(item.MenuModels.Update === 1){
                    item.Update = 0
                }
                if(item.MenuModels.Delete === 1){
                    item.Delete = 0
                }
            }
        },
        addGroup(){
            this.GroupFocus.Dialog = true
            this.getGroup({
                UserPerID: null,
                UserPerIDName: null,
                Name: null,
                Uid: null,
                Status: null,
                Description: null,
                DefaultGroup: null,
                UserPermission: null,
            });
        },
        DialogRemove(){
            this.openDialogRemove = true
        },
        removeGroup(){
            const req = this.selectGroup.map(x => x.UserGrpID.toString())
            axios.post(this.$store.state.API_PATH + 'Menu/RemoveUserGroup', req, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                if(res.data.Status){
                    this.GroupList = res.data.Result
                    Swal.fire({
                        icon: 'success',
                        text: 'Success',
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                        this.openDialogRemove = false
                    },()=>{})
                }
                else{
                    Swal.fire({
                        icon: 'error',
                        text: res.data.ErrorMessage,
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                        this.GroupFocus.Dialog = true
                    },()=>{})
                }
            })
        },
        getGroup(val){
            if(this.$store.state.buttonPermit.menuConfig.Update){
                this.GroupFocus.Dialog = true
                console.log(this.GroupFocus.Dialog);
                console.log(this.GroupFocus);
                axios.post(this.$store.state.API_PATH + 'Menu/GetGroupByGroupType', val, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    console.log(res.data.Result);
                    this.GroupFocus.value = res.data.Result
                })
            }
        },
        getMenu(){
            axios.post(this.$store.state.API_PATH + 'Menu/GetMenu', '', {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
        }
    },
    mounted(){
        axios.post(this.$store.state.API_PATH + 'Menu/GetGroup', '', {
            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        }).then(res => {
            this.GroupList = res.data.Result
        })
    },
    computed:{
        selectGroupName:{
            get() {
                let name
                if(this.GroupList){
                    name = this.GroupList.map(x => x.Name)
                }
                return name
            }
        }
    }
}
</script>
