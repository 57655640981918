<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card elevation="0">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Login form
                  <span data-test="accessToken">{{ accessToken }}</span>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                  <v-text-field prepend-icon="mdi-lock" label="Employee ID" type="password"
                  v-model="empId" @keypress.enter="getEmpById()"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-layout>
                  <v-flex class="text-center">
                    <v-btn data-test="getEmpById" color="primary" @click="getEmpById()">Login</v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>
          </v-card>
        </v-flex>
    </v-layout>
  </v-container>
</template>



<script>
import axios from 'axios'
import SimpleCrypto from "simple-crypto-js"
import aad from "../services/aad";

export default {
  data(){
    return{
      loginAD: "",
      empId: "",
      accessToken: "",
      jsonText: "",
      haveJson: false,
      SecurityCode: "SecurityCode=1234",
      textEmpEmpty: "Employee id empty.",
      SimpleCrypto: new SimpleCrypto("aoc"),
      empIdBTT: "",
      ActionName: ""
    }
  },
  methods:{
    login() {
      aad.login().then((account) => {
        this.loginAD = account
        console.log(account);
      });
    },

    getEmpById(){
      this.$store.state.LOADING = true
      axios.post(`${this.$store.state.API_PATH}Hrws/getEmployeesByID?val=${this.empId}`, {}, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      })
      .then(async res=>{
        if(res.data.Status){
          if(res.data.Result.data.employees_MastersData != null){
            const data = res.data.Result.data.employees_MastersData
            const splName = data.THAINAME.split(" ")
            data.TITLE_TH = splName[0]
            data.THAINAME = `${splName[1]} ${splName[2]}`
            // await this.getLineApprove(data)
            await this.getGroupdivision(data)
            await this.getDivision(data)
            await this.getDepartment(data)
            await this.getSection(data)
            await this.InsertUserProfileFromHRServ(data)
          }
          else{
            this.UpdateStatusUserProfile()
          }
        }
        else{
          this.$store.state.LOADING = false
        }
      })
      .catch(err=>{
        this.$store.state.LOADING = false
        console.log(err)
      })
    },
    async InsertUserProfileFromHRServ(data){
      await axios.post(this.$store.state.API_PATH + "UserProfile/InsertUserProfileFromHRServ", {
        EMPLOYEE_ID: data.EMPLOYEE_ID,
        TITLE_TH: data.TITLE_TH,
        THAINAME: data.THAINAME,
        POSITIONTHAI: data.POSITIONTHAI,
        TITLE: data.TITLE,
        FIRSTNAME: data.FIRSTNAME,
        LASTNAME: data.LASTNAME,
        SEX: data.SEX,
        LOCATION: data.LOCATION,
        ORGUNIT: data.ORGUNIT,
        POSITION: data.POSITION,
        EMAIL_ID: data.EMAIL_ID,
        MOBILE_NO: data.MOBILE_NO,
        DIRECT_PHONE: data.DIRECT_PHONE,
        // MOBILE_NO: data.MOBILE_NO && data.MOBILE_NO.trim() !== "-" ? data.MOBILE_NO.trim() : "",
        Groupdivision_ID: data.Groupdivision_ID,
        DIVISION_ID: data.DIVISION_ID,
        DEPARTMENT_ID: data.DEPARTMENT_ID,
        SECTION_ID: data.SECTION_ID,
        JOIN_DATE: data.HIREDATE ? `'${data.HIREDATE}'` : null,
        LINE_APPROVE: data.LINE_APPROVE,
        ACCOUNT_NO: data.BANKACCOUNT,
        STATUS: data.STATUS
      }, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(resA=>{
        if(resA.data.Status){
          axios.post(this.$store.state.API_PATH + "Login/LoginByEmpCode", {
            EMPLOYEE_ID: data.EMPLOYEE_ID
          }, {
            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
          }).then(res2=>{
            this.$store.state.LOADING = false
            if(res2.data.Status){
              if(this.empIdBTT !== data.EMPLOYEE_ID){
                res2.data.Result.EMPLOYEE_ID_BTT = this.empIdBTT
                res2.data.Result.ActionByBTT = this.ActionName
              }
              setTimeout(()=>{
                localStorage.PROFILE = JSON.stringify(res2.data.Result)
                window.location.href = "/"
              }, 100)
            }
          })
          .catch(err=>{
            this.$store.state.LOADING = false
            console.log(err)
          })
        }
        else{
          this.$store.state.LOADING = false
        }
      }).catch(err=>{
        this.$store.state.LOADING = false
        console.log(err)
      })
    },
    UpdateStatusUserProfile(){
      axios.post(this.$store.state.API_PATH + "UserProfile/UpdateStatusUserProfile", {
        EMPLOYEE_ID: this.empId
      }, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(()=>{
        this.$store.state.LOADING = false
        alert(this.textEmpEmpty)
      })
      .catch(err=>{
        this.$store.state.LOADING = false
        alert(this.textEmpEmpty)
        console.log(err)
      })
    },
    async getLineApprove(data){
      await this.getOrganizations(data.ORGUNIT).then(res=>{
        if(res.data.Status){
          if(res.data.Result.Data.OrganizationsData != null){
            console.log(res.data.Result.Data)
            data.LINE_APPROVE = res.data.Result.Data.OrganizationsData.HeadActing.HEAD_ID
          }
          else{
            data.LINE_APPROVE = ""
          }
        }
      })
    },
    async getGroupdivision(data){
      await this.getOrganizations(data.Groupdivision_ID).then(res=>{
        if(res.data.success){
          if(res.data.Result.Data.OrganizationsData != null){
            data.Groupdivision_ID = res.data.Result.Data.OrganizationsData.NAME
          }
          else{
            data.Groupdivision_ID = ""
          }
        }
      })
    },
    async getDivision(data){
      await this.getOrganizations(data.DIVISION_ID).then(res=>{
        if(res.data.success){
          if(res.data.Result.Data.OrganizationsData != null){
            data.DIVISION_ID = res.data.Result.Data.OrganizationsData.NAME
          }
          else{
            data.DIVISION_ID = ""
          }
        }
      })
    },
    async getDepartment(data){
      await this.getOrganizations(data.DEPARTMENT_ID).then(res=>{
        if(res.data.success){
          if(res.data.Result.Data.OrganizationsData != null){
            data.DEPARTMENT_ID = res.data.Result.Data.OrganizationsData.NAME
          }
          else{
            data.DEPARTMENT_ID = ""
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async getSection(data){
      await this.getOrganizations(data.SECTION_ID).then(res=>{
        if(res.data.success){
          if(res.data.Result.Data.OrganizationsData != null){
            data.SECTION_ID = res.data.Result.Data.OrganizationsData.NAME
          }
          else{
            data.SECTION_ID = ""
          }
        }
      })
    },
    getOrganizations(ID){
      return axios.post(`${this.$store.state.API_PATH}Hrws/getOrganizationsByID?val=${ID}`, {}, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      })
    },
    async getToken(){
      console.log(this.loginAD);
      await axios.get(`${this.$store.state.API_PATH}Userprofile/getToken`, {
          headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        }).then(res=>{
        this.accessToken = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    },
  },
  mounted(){
    localStorage.clear()
    sessionStorage.clear()
    if(this.$route.query.id){
      this.getToken()
      const dataBTT = this.SimpleCrypto.decrypt(this.$route.query.id)
      const splDataBTT = dataBTT.split("|")
      this.empIdBTT = splDataBTT[0] ?? ""
      this.ActionName = splDataBTT[1]
    }
    else{
      this.getToken()
    }
  }
}
</script>
