<template>
    <div class="sticky">
        <v-card>
            <v-card-title>
                Project Detail
            </v-card-title>
            <v-card-text>
                <div style="border: solid .6px #c3c3c3; padding: 15px; border-radius: 5px;">
                    <span>
                        PNR No.
                    </span>
                    <span style="float: right">
                        <h2 style="color: #7ab800">{{pnr}}</h2>
                    </span>
                </div>
                <br />
                <div style="border: solid .6px #c3c3c3; padding: 15px; border-radius: 5px; font-size: .8rem;">
                    Please confirm before
                </div>
                <br />
                <div style="border: solid .6px #c3c3c3; padding: 15px; border-radius: 5px;">
                    <v-row v-if="coperateData != null">
                        <v-col cols="6">
                            <strong>
                                Budget Holder
                            </strong>
                        </v-col>
                        <v-col cols="6">
                            <strong style="float: right;">
                                {{coperateData.BudgetHolder}}
                            </strong>
                        </v-col>
                        <v-col cols="6">
                            <strong>
                                Project
                            </strong>
                        </v-col>
                        <v-col cols="6">
                            <span style="float: right;">
                                {{coperateData.ProjectName}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            <strong>
                                Company
                            </strong>
                        </v-col>
                        <v-col cols="6">
                            <span style="float: right; font-size: .8rem;">
                                {{coperateData.CompanyName}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            <strong>
                                Bussiness Start-End
                            </strong>
                        </v-col>
                        <v-col cols="6">
                            <span style="float: right; font-size: .8rem;">
                               {{formatDatepicker(coperateData.startDate) + " to " + formatDatepicker(coperateData.endDate)}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            <strong>
                                Purpose of Travel
                            </strong>
                        </v-col>
                        <v-col cols="6">
                            <span style="float: right;">
                                {{coperateData.PurId}}
                            </span>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn small color="info" text @click="cancel()">
                    Back to page
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn outlined small color="info" :loading="cancelLoading" @click="cancelFlight()">
                    Cancel Booking
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialogCancel" width="500">
            <div style="border: solid .6px #F8F9FA; background-color: #F8F9FA; padding: 15px; border-radius: 5px;">
                <v-card-title>
                    <v-spacer></v-spacer>
                    <h3>
                        Cancel Confirmation !
                    </h3>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-row class="text-center">
                        <v-col>
                            Your reservation (PNR) will be deleted.
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card>
                    <v-card-title>
                        <h5>
                            Traveler
                        </h5>
                        <v-spacer></v-spacer>
                        <h5>
                            PNR No.
                        </h5>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text v-if="airline != null">
                        <v-row v-for="(itemTravel, index) in airline.adtPaxs" :key="index">
                            <v-col cols="8">
                                {{itemTravel.firstname  + ' ' + itemTravel.lastname}}
                            </v-col>
                            <v-col cols="4" style="color: #7ab800;">
                                <strong style="float: right;">
                                    {{ airline.PNR }}
                                </strong>
                            </v-col>
                            <v-col cols="12" v-if="airline.chdPaxs">
                                <span v-for="(chdItem, chdindex) in airline.chdPaxs" :key="chdindex">
                                    <v-row>
                                        <v-col cols="8">
                                            <img :src="require('@/assets/icons/Frame.svg')" style="position: relative" alt="" />
                                            <span style="position: absolute;" class="mt-2 ml-2"> {{ chdItem.firstname  + ' ' + chdItem.lastname}} </span>
                                        </v-col>
                                        <v-col cols="4">
                                            <strong style="float: right; color: #7ab800;" class="mt-2 ml-2">
                                                {{ airline.PNR }}
                                            </strong>
                                        </v-col>
                                    </v-row>
                                </span>
                            </v-col>
                        </v-row>
                       <br />
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="info" @click="closed()" small>Back to Travel Request</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
    name: "ProjectDetails",
    props: {
        coperateData: Object,
        pnr: String,
        airline: Object,
        travelers : Array,
    },
    data() {
        return {
            tab: null,
            dialog: false,
            searchFlight: {},
            loading: false,
            messageAlert: '',
            dialogError: false,
            dialogCancel: false,
            cancelLoading: false
        }
    },
    methods: {
        cancel(){
            location.href = 'createTravelRq'
        },
        formatDatepicker(date){
            return moment(date).format('DD-MM-YYYY')
        },
        cancelFlight(){
            this.cancelLoading = true
            const formReqID = JSON.parse(sessionStorage.formReqID)
            const CancelBookingRequest = {
                GetFlightBookingModel: {
                    bookingKeyReference: this.airline.BookingOID,
                },
                AirFare: this.airline,
                FullName: `${this.travelers[0].fullname} (${this.travelers[0].fullname})`,
                TRNo: formReqID.TRNo,
                PNR: this.airline.PNR
            }
            if(CancelBookingRequest.AirFare.depFlight[0].airline.code === "DD"){
                axios.post(`${this.$store.state.API_PATH}CancelBooking/CancelNokairBooking`, CancelBookingRequest, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    console.log(res)
                    if(res != null && !res.data.isError){
                        this.dialogCancel = true
                        this.cancelLoading = false
                    }
                })
            }else{
                axios.post(`${this.$store.state.API_PATH}CancelBooking/CancelBooking`, CancelBookingRequest, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    if(res != null && !res.data.isError){
                        this.dialogCancel = true
                        this.cancelLoading = false
                    }
                })
            }
        },
        closed(){
            location.href = 'createTravelRq'
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
}
</style>
