<template>
        <div>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <span>
                        <span class="font-W700" style="font-size: 1em;">PreferredSeat</span>
                    </span>
                </v-col>
            </v-row>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row gutters align="center" justify="center">
                                <v-col sm="6" lg="6" cols="8">
                                    Code
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Code" @keypress.enter="getPreferredSeatBySearch()"></v-text-field>
                                </v-col>
                                <v-col sm="6" lg="6" cols="8">
                                    Desc
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Desc" @keypress.enter="getPreferredSeatBySearch()"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters align="center" justify="center">
                                <v-col md="auto" sm="auto" >
                                    <v-btn color="primary"
                                      @click="getPreferredSeatBySearch()" :loading="searchbtnLoading"
                                      elevation="0"
                                      class="mb-lg-0 mb-md-0 mb-sm-0 mb-6">Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row no-gutters align="center">
                                <v-col class="text-lg-right text-sm-right text-center">
                                    <v-btn class="btn-table mr-2" elevation="0" @click="deletePreferredSeat()" v-if="$store.state.buttonPermit.menuConfig.Delete">
                                        <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1"></v-img>
                                        Delete
                                    </v-btn>
                                    <v-btn class="btn-table mr-2" elevation="0" @click="resetInfo()">
                                        <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1"></v-img>
                                        Reset
                                    </v-btn>
                                    <v-btn class="btn-table" elevation="0" @click="actionPreferredSeat('Add')" v-if="$store.state.buttonPermit.menuConfig.Add">
                                        <v-img :src="require('@/assets/icons/Add_trans.svg')" class="mr-1"></v-img>
                                        Add
                                    </v-btn>
                                    <v-dialog v-if="dialogAddOrEditPreferredSeat" v-model="dialogAddOrEditPreferredSeat" persistent
                                        width="600px">
                                        <v-card elevation="0" title color="primary" class="pt-1 card">
                                            <v-card>
                                                <v-card-text>
                                                    <v-card class="card-detail" title elevation="0">
                                                        <v-card-title>
                                                            <v-row no-gutters>
                                                                <v-col class="text-center">
                                                                    {{ AddOrEdit }} Preferred Seat
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Code
                                                                    <v-text-field  :disabled="AddOrEdit === 'Add' ? false : true"
                                                                     outlined dense background-color="white" ref="Code" :rules="rules.Code"
                                                                     v-model="Code"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Desc
                                                                    <v-text-field outlined dense background-color="white" ref="Desc" :rules="rules.Desc"
                                                                    v-model="Desc"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col class="text-center">
                                                            <v-btn outlined color="primary" class="mr-1"
                                                            @click="closeAddOrEditPreferredSeat()">Close</v-btn>
                                                            <v-btn color="primary" class="ml-1" v-if="AddOrEdit === 'Add'"
                                                            @click="addPreferredSeatData()">Save</v-btn>
                                                            <v-btn color="primary" class="ml-1" v-else
                                                            @click="updatePreferredSeatData()">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <br>
                            <v-card elevation="0">
                                <v-data-table :headers="headerPreferredSeat" :items="itemPreferredSeatData" class="elevation-1" item-key="no"
                                @click:row="rowClicked" show-select v-model="selectedItemPreferredSeat" checkbox-color="primary"
                                mobile-breakpoint style="white-space: nowrap" hide-default-footer height="45vh"
                                @page-count="pageCountPreferredSeat = $event" :page.sync="page" :items-per-page="itemPage" @load="onPagination($event)">
                                </v-data-table>
                            </v-card>
                            <v-row class="mt-3 hidden-xs-only" no-gutters>
                                <v-col class="d-flex" cols="12">
                                    <span class="mr-3 mt-3">Show</span>
                                    <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                        v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                    <span class="ml-3 mt-3">items per page</span>
                                    <v-pagination v-model="page" :length="pageCountPreferredSeat" :total-visible="5" class="pagination-custom"
                                        style="width: 60%;"></v-pagination>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                                <v-col class="d-flex" cols="12">
                                    <span class="mr-3 mt-3">Show</span>
                                    <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                        v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                    <span class="ml-3 mt-3">items per page</span>
                                </v-col>
                                <v-col>
                                    <v-pagination v-model="page" :length="pageCountPreferredSeat" :total-visible="5" class="pagination-custom">
                                    </v-pagination>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
</template>
<script>
import Swal from 'sweetalert2'
import axios from 'axios';
export default {
    data(){
        return{
            disable: false,
            searchbtnLoading: false,
            files: [],
            Code: "",
            Desc: "",
            AddOrEdit: "",
            dialogAddOrEditPreferredSeat: false,
            itemPage: 10,
            page: 1,
            pageCountPreferredSeat: 2,
            headerPreferredSeat: [
                { text: 'No.', value: 'no', sortable: false },
                { text: 'Code', value: 'Code', sortable: false },
                { text: 'Desc', value: 'Desc', sortable: false },
            ],
            itemsPerPage: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemPreferredSeatData: [],
            selectedItemPreferredSeat: [],
            requireField: "This field is required",
            rules:{
                Code: [(val) => (val || "").length > 0 || this.requireField],
                Desc: [(val) => (val || "").length > 0 || this.requireField],
            }
        }
    },
    methods:{
        checkvalidate(){
            let result = false
            if (!this.$refs.Code.validate()) {
                this.$refs.Code.validate(true)
                this.$refs.Code.focus()
                result = true
            }
            if (!this.$refs.Desc.validate()) {
                this.$refs.Desc.validate(true)
                this.$refs.Desc.focus()
                result = true
            }
            return result
        },
        updatePreferredSeatData(){
            if(!this.checkvalidate()){
                this.dialogAddOrEditPreferredSeat = false
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}PreferredSeat/UpdatePreferredSeat`, {
                    Code: this.Code,
                    Desc: this.Desc,
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.SwalAlert(res)
                })
            }
        },
        SwalAlert(res){
            if(res.data.Status){
                Swal.fire({
                    icon: 'success',
                    text: 'Success',
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.closeAddOrEditPreferredSeat()
                    this.getPreferredSeatData()
                }, () => {})
            }
            else{
                Swal.fire({
                    icon: 'error',
                    text: res.data.ErrorMessage,
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.dialogAddExternal = true
                }, () => { })
            }
        },
        closeAddOrEditPreferredSeat(){
            this.dialogAddOrEditPreferredSeat = false
            this.Code = ""
            this.Desc = ""
            this.AddOrEdit = ""
            this.disable = false
        },
        addPreferredSeatData(){
            if(!this.checkvalidate()){
                this.dialogAddOrEditPreferredSeat = false
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}PreferredSeat/InsertPreferredSeat`, {
                    Code: this.Code,
                    Desc: this.Desc,
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.SwalAlert(res)
                }).catch(() => {
                    this.$store.state.LOADING = false
                })
            }
        },
        actionPreferredSeat(AddOrEdit){
            this.AddOrEdit = AddOrEdit
            this.dialogAddOrEditPreferredSeat = true
        },
        resetInfo(){
            this.selectedItemPreferredSeat = []
            this.getPreferredSeatData()
        },
        deletePreferredSeat(){
            if(this.selectedItemPreferredSeat.length > 0){
                Swal.fire({
                    icon: 'warning',
                    text: 'would you like to to delete?',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    confirmButtonColor: '#00AEEF'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.state.LOADING = true
                        axios.post(this.$store.state.API_PATH + 'PreferredSeat/DeletePreferredSeat', {
                            selectedDelItem: this.selectedItemPreferredSeat
                        }, {
                            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        })
                        .then(res => {
                            this.$store.state.LOADING = false
                            if (res.data.Status) {
                                Swal.fire({
                                    icon: 'success',
                                    text: 'Success',
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                    this.selectedItemPreferredSeat = []
                                    this.getPreferredSeatData()
                                },()=>{})
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    text: res.data.ErrorMessage,
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                },()=>{})
                            }
                        })
                        .catch(() => {
                            this.$store.state.LOADING = false
                        })
                    }
                })
            }
        },
        getPreferredSeatData(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}PreferredSeat/GetTablePreferredSeat`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.itemPreferredSeatData = res.data.Result
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        getPreferredSeatBySearch(){
                this.searchbtnLoading = true
                setTimeout(() => {
                    axios.post(this.$store.state.API_PATH + 'PreferredSeat/GetPreferredSeatBySearch', {
                        Code: this.Code,
                        Desc: this.Desc,
                    }, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    }).then(res => {
                        this.$store.state.LOADING = false
                    if (res.data.Status) {
                        this.itemPreferredSeatData = res.data.Result
                        this.searchbtnLoading = false
                    } else {
                        this.searchbtnLoading = false
                    }
                    }).catch(() => {
                        this.$store.state.LOADING = false
                        this.searchbtnLoading = false
                    })
                }, 1000);
            },
        rowClicked(item){
            if(this.$store.state.buttonPermit.menuConfig.Update){
                this.AddOrEdit = "Edit";
                this.Code = item.Code;
                this.Desc = item.Desc;
                this.dialogAddOrEditPreferredSeat = true;
            }
        },
    },
    mounted(){
        this.getPreferredSeatData()
    }
}
</script>
<style scoped>
.font-W700{
    font-weight: 700;
}
.font-W400{
    font-weight: 400;
}
.btn-table{
    background: rgba(0, 174, 239, 0.1) !important;
}
</style>
