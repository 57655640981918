<template>
    <div v-if="HotelDetail != null && Hotel">
        <v-row>
            <v-col>
                <v-card elevation="0" class="card">
                    <v-card-title>
                        {{ Hotel.HTL_NAME }}
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-card class="bgcard" elevation="0">
                                    <v-row>
                                        <v-col cols="12" lg="6" md="6">
                                            <div v-if="!Hotel.headerImage && Hotel.HotelFiles && Hotel.HotelFiles.filter(x => x.IsHeader === 1).length > 0">
                                                <v-img :src="Hotel.HotelFiles.filter(x => x.IsHeader === 1)[0].FilePath" alt="" />
                                            </div>
                                            <div v-else-if="Hotel.headerImage" class="h-space">
                                                <v-img :src="Hotel.headerImage" alt="" />
                                            </div>
                                            <div v-else>
                                                No Image
                                            </div>
                                        </v-col>
                                        <v-col cols="12" lg="6" md="6">
                                            <template>
                                                <v-row no-gutters>
                                                    <v-col cols="4" class="pa-1" v-for="(item, index) in Hotel.HotelFiles && Hotel.HotelFiles.length > 0
                                                    ? Hotel.HotelFiles : []" :key="index"
                                                    @click="ImageActive(item.FilePath, 'Hotel')" >
                                                        <v-img :src="item.FilePath" alt="" style="border-radius: 5px;" class="image-hover"/>
                                                    </v-col>
                                               </v-row>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col>
                                <strong>
                                    Location Info
                                </strong>
                            </v-col>
                            <v-col class="text-right">
                                <v-btn color="primary" @click="Mapblank" small>Location on Google Maps</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div class="bgcard">
                                    <GmapMap ref="mapRef" :center="mapCenterRoom" :zoom="17"
                                    style="width: 100%; height: 200px; border:0;">
                                        <GmapMarker :position="mapPositionRoom" :clickable="true" :draggable="true" />
                                    </GmapMap>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0" class="card">
                    <v-card-text>
                        <v-row align="center">
                            <v-col class="d-flex align-center">
                                <strong>{{ objHotel.HTL_NAME }}</strong>
                                <v-chip color="primary" class="ml-1" small>Hotel</v-chip>
                                <v-rating length="5" readonly v-model="Hotel.HTL_RATING" small :value="5" color="#F9B50B" dense></v-rating>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card class="bgcard" elevation="0">
                                    <v-row class="overflow-x-auto mt-1">
                                        <v-col>
                                            <v-card elevation="0" height="400px" style="background-color: #F8F9FA !important;">
                                                <v-row v-for="(item, index) in Hotel.HotelPrice"
                                                :key="index" no-gutters :class="index === 0 ? null : 'mt-2'">
                                                    <v-col>
                                                        <v-card elevation="0" class="card">
                                                            <v-row align="center">
                                                                <v-col cols="3" @click="ShowRoomDetail(item)">
                                                                    <div v-if="item.FilePath" class="image-container">
                                                                        <img :src="item.FilePath" alt="" />
                                                                        <div class="overlay">
                                                                            <p>Show More</p>
                                                                        </div>
                                                                    </div>
                                                                    <div v-else class="text-center">No Image</div>
                                                                </v-col>
                                                                <v-col cols="12" lg="6" md="6">
                                                                    <v-row>
                                                                        <v-col>
                                                                            <span>
                                                                                <strong>
                                                                                    {{
                                                                                        RoomType.filter(x => x.RMT_ID === item.RMT_ID).length > 0 ?
                                                                                        RoomType.filter(x => x.RMT_ID === item.RMT_ID)[0].RMT_NAME : null
                                                                                    }}
                                                                                    ({{
                                                                                        BedType.filter(x => x.BDT_ID === item.BDT_ID).length > 0 ?
                                                                                        BedType.filter(x => x.BDT_ID === item.BDT_ID)[0].BDT_NAME : null
                                                                                    }})
                                                                                </strong>
                                                                            </span>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row no-gutters align="center">
                                                                        <v-col cols="1">
                                                                            <v-img class="mr-1 shrink" max-width="25" :src="require(('@/assets/icons/Vector.svg'))" alt=""></v-img>
                                                                        </v-col>
                                                                        <v-col>
                                                                            <span>
                                                                                {{
                                                                                    RoomType.filter(x => x.RMT_ID === item.RMT_ID).length > 0 ?
                                                                                    RoomType.filter(x => x.RMT_ID === item.RMT_ID)[0].RMT_NAME : null
                                                                                }}
                                                                            </span>
                                                                        </v-col>
                                                                        <v-col cols="1" class="text-center">
                                                                            <v-img class="mr-1 shrink mx-auto" max-width="17"
                                                                             :src="require(('@/assets/icons/Guest.svg'))"
                                                                              alt=""></v-img>
                                                                        </v-col>
                                                                        <v-col>
                                                                            <span>{{ FilterHotel.Occupancy }} Guest</span>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col class="greentap ml-2">
                                                                            <span style="color: #00AEEF;">
                                                                                <strong>{{ item.BDT_DESC }}Free WiFi</strong>
                                                                            </span>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-divider vertical class="mt-4 mb-4" color="#004FBC"></v-divider>
                                                                <v-col class="text-right mr-4">
                                                                    <v-row class="mt-2">
                                                                        <v-col>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row no-gutters class="mt-2">
                                                                        <v-col>
                                                                            <span>
                                                                                <strong>
                                                                                    THB
                                                                                    {{ item.HOTEL_PRICE.toLocaleString('th', {
                                                                                    minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                                                                </strong>
                                                                            </span>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row no-gutters>
                                                                        <v-col>
                                                                            <span>/ room / night</span>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row align="center" no-gutters class="mt-2 mb-2">
                                                                        <v-col>
                                                                            <v-btn small color="primary" @click="gotoYourHotel(item)">Select Room</v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="showRoom.dialog" v-if="showRoom.value">
            <v-card class="card">
                <v-card-title>
                    <strong>
                    {{
                        RoomType.filter(x => x.RMT_ID === showRoom.value.RMT_ID).length > 0 ?
                        RoomType.filter(x => x.RMT_ID === showRoom.value.RMT_ID)[0].RMT_NAME : null
                    }}
                    ({{
                        BedType.filter(x => x.BDT_ID === showRoom.value.BDT_ID).length > 0 ?
                        BedType.filter(x => x.BDT_ID === showRoom.value.BDT_ID)[0].BDT_NAME : null
                    }})
                    </strong>
                </v-card-title>
                <v-card-text class="bgcard">
                    <v-row>
                        <v-col cols="4">
                            <v-row>
                                <v-col cols="12">
                                        <v-img v-if="!showRoom.headerImage && showRoom.value.RoomFiles &&
                                            showRoom.value.RoomFiles.filter(x => x.IsHeader === 1).length > 0"
                                            :src="showRoom.value.RoomFiles.filter(x => x.IsHeader === 1)[0].FilePath" />
                                        <div v-else-if="showRoom.headerImage" class="text-center">
                                            <v-img :src="showRoom.headerImage" />
                                         </div>
                                        <div v-else>
                                            No Image
                                        </div>
                                </v-col>
                                <v-col cols="12" style="position: relative;">
                                    <v-layout class="overflow-x-auto" >
                                        <span style="width: 100px !important; position: relative;" v-for="(item, index) in
                                            showRoom.value.RoomFiles" :key="index" class="pa-1 image-hover"
                                            @click="ImageActive(item.FilePath, 'RoomPrice')" >
                                            <v-img class="image-center" width="100%" :src="item.FilePath" />
                                        </span>
                                    </v-layout>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="8">
                            <v-card class="card">
                                <v-card-title>
                                    {{
                                        RoomType.filter(x => x.RMT_ID === showRoom.value.RMT_ID).length > 0 ?
                                        RoomType.filter(x => x.RMT_ID === showRoom.value.RMT_ID)[0].RMT_NAME : null
                                    }}
                                    ({{
                                        BedType.filter(x => x.BDT_ID === showRoom.value.BDT_ID).length > 0 ?
                                        BedType.filter(x => x.BDT_ID === showRoom.value.BDT_ID)[0].BDT_NAME : null
                                    }})
                                </v-card-title>
                                <v-card-text>
                                    <v-row align="center">
                                        <v-col cols="12" lg="8" md="8">
                                            <v-row no-gutters align="center">
                                                <v-col cols="1">
                                                    <v-img class="mr-1 shrink" max-width="25" :src="require(('@/assets/icons/Vector.svg'))" alt=""></v-img>
                                                </v-col>
                                                <v-col>
                                                    <span>
                                                        {{
                                                            RoomType.filter(x => x.RMT_ID === showRoom.value.RMT_ID).length > 0 ?
                                                            RoomType.filter(x => x.RMT_ID === showRoom.value.RMT_ID)[0].RMT_NAME : null
                                                        }}
                                                    </span>
                                                </v-col>
                                                <v-col cols="1" class="text-center">
                                                    <v-img class="mr-1 shrink mx-auto" max-width="17"
                                                        :src="require(('@/assets/icons/Guest.svg'))"
                                                        alt=""></v-img>
                                                </v-col>
                                                <v-col>
                                                    <span>{{ FilterHotel.Occupancy }} Guest</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="greentap ml-2">
                                                    <span style="color: #00AEEF;">
                                                        <strong>{{ showRoom.value.BDT_DESC }}Free WiFi</strong>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-divider vertical class="mt-4 mb-4" color="#004FBC"></v-divider>
                                        <v-col class="text-right mr-4">
                                            <v-row class="mt-2">
                                                <v-col>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-2">
                                                <v-col>
                                                    <span>
                                                        <strong>
                                                            THB
                                                            {{ showRoom.value.HOTEL_PRICE.toLocaleString('th', {
                                                            minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                                        </strong>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col>
                                                    <span>/ room / night</span>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters class="mt-2 mb-2">
                                                <v-col>
                                                    <v-btn small color="primary" @click="gotoYourHotel(showRoom.value)">Select Room</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="showRoom.dialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import SimpleCrypto from "simple-crypto-js"
export default {
    name: 'selectHotel',
    data() {
        return {
            mapCenterRoom: { lat: 0, lng: 0 },
            mapPositionRoom: { lat: 0, lng: 0 },
            showRoom: {
                dialog: false,
                value: null,
                headerImage: null
            },
            propID: "1",
            BedType: [],
            RoomType: [],
            HotelDetail: [],
            Hotel: null,
            RoomItem: [],
            FilterHotel: {},
            objHotel: this.$store.state.objHTL,
            SimpleCrypto: new SimpleCrypto("aoc")
        }
    },
    methods: {
        async getLocationInMapForRoom(param){
            await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?${param}&key=${this.$store.state.googleMap}`)
            .then(result=>{
                if(result.data.status === "OK"){
                    const item = result.data.results[0]
                    this.mapPositionRoom.lat = item.geometry.location.lat
                    this.mapPositionRoom.lng = item.geometry.location.lng
                    this.mapCenterRoom.lat = item.geometry.location.lat
                    this.mapCenterRoom.lng = item.geometry.location.lng
                }
            })
        },
        ImageInactive(){
            setTimeout(() => {
               this.showRoom.headerImage = null
            }, 1000);
        },
        ImageActive(val, key){
            if(key === 'RoomPrice'){
                this.showRoom.headerImage = val
            }else{
                this.Hotel.headerImage = val
            }
        },
        ShowRoomDetail(val){
            this.headerImage = null
            this.showRoom.dialog = true
            this.showRoom.value = val
            axios.post(`${this.$store.state.API_PATH}FileUpload/GetHotelPriceFiles?ID=${val.HTPRC_ID}`,{}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.showRoom.value.RoomFiles = res.data.Result
            })
        },
        Mapblank(){
            window.open(`https://www.google.com/maps/search/?api=1&query=${this.mapPositionRoom.lat},${this.mapPositionRoom.lng}`, "_blank")
        },
        gotoYourHotel(item){
            const hotelHeader = JSON.parse(sessionStorage.objHTL)
            this.$store.state.LOADING = true
            const coperateData = JSON.parse(sessionStorage.coperateData)
            axios.post(`${this.$store.state.API_PATH}UserProfile/GetUserProfileByID?val=${coperateData.EMPLOYEE_ID}`,{}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async(res) => {
                if (res.data.Status) {
                    this.$store.state.LOADING = false
                    const rec = res.data.Result
                    const RoomType = this.RoomType.filter(x => x.RMT_ID === item.RMT_ID)[0]?.RMT_NAME ?? ""
                    const BedType = this.BedType.filter(x => x.BDT_ID === item.BDT_ID)[0]?.BDT_NAME ?? ""
                    const data = {
                        HTL_RATING: hotelHeader.HTL_RATING,
                        ...this.FilterHotel,
                        HTL_ID: hotelHeader.HTL_ID,
                        HTL_NAME: hotelHeader.HTL_NAME,
                        HTL_DESC: hotelHeader.HTL_DESC,
                        ...item,
                        HotelFilePath: hotelHeader.FilePath,
                        HotelRoomFilePath: item.FilePath,
                        TITLE: rec.TITLE,
                        EMAIL_ID: rec.EMAIL_ID,
                        MOBILE_NO: rec.MOBILE_NO,
                        FIRSTNAME: rec.FIRSTNAME,
                        LASTNAME: rec.LASTNAME,
                        RoomType: RoomType,
                        BedType: BedType,
                    }
                    sessionStorage.YourHotel = JSON.stringify(data)
                    setTimeout(()=>{
                        this.$store.state.LOADING = false
                        // sessionStorage.removeItem("objHTL")
                        this.$store.state.showHotelInfo = false
                        this.$store.state.showHotelTripSum = true
                    }, 300)
                }
                else{
                    this.$store.state.LOADING = false
                }
            })
            .catch((err) => {
                console.log(err)
                this.$store.state.LOADING = false
            })
        },
        remove(item){
            item.QTY_BED = item.QTY_BED - 1
        },
        addBed(item){
            item.QTY_BED += 1
        },
        getHotel(){
            this.$store.state.LOADING = true

            axios.post(`${this.$store.state.API_PATH}GetHotelByID?val=` + this.objHotel.HTL_ID, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then((res) => {
                this.Hotel = res.data.Result
                if(this.Hotel.HTL_LOCATION){
                    setTimeout(async()=>{
                        await this.getLocationInMapForRoom(`latlng=${this.Hotel.HTL_LOCATION}`)
                    },500)
                }
                else{
                    setTimeout(async()=>{
                        await this.getLocationInMapForRoom(`address=${this.Hotel.HTL_NAME}`)
                    },500)
                }
                axios.post(`${this.$store.state.API_PATH}FileUpload/GetFile?ID=` + `${this.objHotel.HTL_ID}|Hotel`, {}, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(resHotel => {
                    this.Hotel.HotelFiles = resHotel.data.Result
                })
                this.$store.state.LOADING = false
            }).catch(err => {
                console.log(err);
                this.$store.state.LOADING = false

            })
        },
        getHotelInfo(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}GetHotelDetail`,{
                HTL_ID: this.objHotel.HTL_ID
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async(res) => {
                if (res.data.Status) {
                    this.$store.state.LOADING = false
                    this.RoomItem = res.data.Result
                }
                else{
                    this.$store.state.LOADING = false
                }
            })
            .catch((err) => {
                console.log(err)
                this.$store.state.LOADING = false
            })
        },
        GetBedType(){
            axios.post(`${this.$store.state.API_PATH}BedType/GetBedType`,{}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.BedType = res.data.Result
            })
        },
        GetRoomType(){
            axios.post(`${this.$store.state.API_PATH}RoomType/GetRoomType`,{}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.RoomType = res.data.Result
            })
        },
    },
    mounted() {
        this.GetBedType()
        this.GetRoomType()
        if(this.$route.query.ID){
            const data = this.SimpleCrypto.decrypt(decodeURIComponent(this.$route.query.ID))
            this.FilterHotel = data
            this.getHotel()
        }
    }

}
</script>

<style scoped>
.image-hover:hover {
    cursor: pointer;
    border: 1px solid #00AEEF;
    border-radius: 2px;
}
.greentap {
    border-left: solid 3px #99e210;
    padding: 4px;
}
</style>
