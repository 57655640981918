<template>
    <v-row>
        <v-col>
            <v-card elevation="0" class="card">
                <v-card-text>
                    <v-row>
                        <v-col>
                            Filter
                        </v-col>
                        <v-col class="text-right">
                            <v-btn small color="primary" @click="updateFilter()">Updates</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text>
                    <v-card class="card">
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    Rating
                                    <v-checkbox dense hide-details v-for="(x, i) in 5" :key="i"
                                    :value="x" v-model="filterRating">
                                        <template v-slot:label>
                                            <img v-for="(subItem, n) in x" :key="n"
                                            :src="require('@/assets/icons/Star.svg')" width="20" alt="" />
                                        </template>
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            filterRating: [],
            tab: null,
            tabTime: null,
            InResult: null,
            ChangSearch: false,
            YourFlight: false,
            Facilites: ['Wifi', 'Swimming Pool', 'Parking', 'Restaurant', 'Elevator', 'Airport Tranfer'],
            RoomFacilities: ['Kitchen/Kitchenette', 'Private Bathroom', 'Air Conditioning', 'Bathtub'],
        }
    },
    methods: {
        updateFilter(){
            const optionalFilterHotel = {
                Rating: this.filterRating.toString()
            }
            this.$emit("optionalFilterHotel", optionalFilterHotel)
        },
        ShowDetails() {
            this.isActive = !this.isActive;
        },
        chengeSearch() {
            this.ChangSearch = !this.ChangSearch;
        },
        showYourFlight() {
            this.YourFlight = true;
            this.$emit("showYourFlight", true)
        }
    }
}
</script>

<style scoped>
.whitecard {
    background-color: white;
    padding: 10px;
}

.hideCard {
    display: none;
}

.flight-select {
    border-left: solid 4px #99e210;
}

.theme-background {
    background-color: white;
    border-radius: 10px;
    padding: 8px 8px;
}
</style>
