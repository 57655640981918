<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <span>
                    <span class="font-W700" style="font-size: 1em;">Room Type</span>
                </span>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card elevation="0" title>
                    <v-card-text>
                        <v-row no-gutters align="center" justify="center">
                            <v-col lg="4" cols="8">
                                Room Type Name
                                <v-text-field outlined dense background-color="white"
                                v-model="TEXT_SEARCH" @keypress.enter="getRoomType()"></v-text-field>
                            </v-col>
                            <v-col md="auto" sm="auto">
                                <v-btn color="primary" elevation="0" class="ml-1" @click="getRoomType()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card elevation="0" title>
                    <v-card-text>
                        <v-row no-gutters align="center">
                            <v-col class="text-lg-right text-sm-right text-center">
                                <v-btn class="btn-table mr-2" elevation="0" @click="deleteRoomType()" v-if="$store.state.buttonPermit.menuConfig.Delete">
                                    <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1"></v-img>
                                    Delete
                                </v-btn>
                                <v-btn class="btn-table mr-2" elevation="0" @click="resetInfo()">
                                    <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1"></v-img>
                                    Reset
                                </v-btn>
                                <v-btn class="btn-table" elevation="0" @click="actionRoomType('Add')" v-if="$store.state.buttonPermit.menuConfig.Add">
                                    <v-img :src="require('@/assets/icons/Add_trans.svg')" class="mr-1"></v-img>
                                    Add
                                </v-btn>
                                <v-dialog v-if="dialogAddOrEditRoomType" v-model="dialogAddOrEditRoomType" persistent
                                    width="600px">
                                    <v-card elevation="0" title color="primary" class="pt-1 card">
                                        <v-card>
                                            <v-card-text>
                                                <v-card class="card-detail" title elevation="0">
                                                    <v-card-title>
                                                        <v-row no-gutters>
                                                            <v-col class="text-center">
                                                                {{ AddOrEdit }} Room Type
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                Order
                                                                <v-text-field outlined dense background-color="white" ref="Order" :rules="rules.Order"
                                                                v-model="RMT_ORDER"></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                Room Typed Name
                                                                <v-text-field outlined dense background-color="white" ref="RoomTypeName" :rules="rules.RoomTypeName"
                                                                v-model="RMT_NAME"></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                Description
                                                                <v-textarea outlined dense background-color="white" rows="3" auto-grow
                                                                v-model="RMT_DESC"></v-textarea>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                Status
                                                                <v-radio-group dense row v-model="STS_ID">
                                                                    <v-radio key="1" value="1" label="Active"></v-radio>
                                                                    <v-radio key="0" value="0" label="Inactive"></v-radio>
                                                                </v-radio-group>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-card-text>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col class="text-center">
                                                        <v-btn outlined color="primary" class="mr-1"
                                                        @click="closeAddOrEditRoomType()">Close</v-btn>
                                                        <v-btn color="primary" class="ml-1" v-if="AddOrEdit === 'Add'"
                                                        @click="addRoomType()">Save</v-btn>
                                                        <v-btn color="primary" class="ml-1" v-else
                                                        @click="updateRoomType()">Save</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                        <br>
                        <v-card elevation="0">
                            <v-data-table :headers="headerRoomType" :items="itemRoomType" class="elevation-1" item-key="RMT_ID"
                            @click:row="rowClicked" show-select v-model="selectedItem" checkbox-color="primary"
                            mobile-breakpoint style="white-space: nowrap" hide-default-footer height="45vh"
                            @page-count="pageCount = $event" :page.sync="page" :items-per-page="itemPage" @load="onPagination($event)">
                                <template v-slot:[`item.no`]="{ index }">
                                    {{ index + 1 }}
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-img style="cursor: pointer;" :src="require('@/assets/icons/Source_File.svg')" @click="getReturnDetail(item)"></v-img>
                                </template>
                            </v-data-table>
                        </v-card>
                        <v-row class="mt-3 hidden-xs-only" no-gutters>
                            <v-col class="d-flex" cols="12">
                                <span class="mr-3 mt-3">Show</span>
                                <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                    v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                <span class="ml-3 mt-3">items per page</span>
                                <v-pagination v-model="page" :length="pageCount" :total-visible="5" class="pagination-custom"
                                    style="width: 60%;"></v-pagination>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                            <v-col class="d-flex" cols="12">
                                <span class="mr-3 mt-3">Show</span>
                                <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                    v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                <span class="ml-3 mt-3">items per page</span>
                            </v-col>
                            <v-col>
                                <v-pagination v-model="page" :length="pageCount" :total-visible="5" class="pagination-custom">
                                </v-pagination>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Swal from 'sweetalert2'
import axios from 'axios';
export default {
    data(){
        return{
            RMT_ID: "",
            RMT_NAME: "",
            RMT_DESC: "",
            RMT_ORDER: "",
            STS_ID: "",
            TEXT_SEARCH: "",
            AddOrEdit: "",
            dialogAddOrEditRoomType: false,
            itemPage: 10,
            page: 1,
            pageCount: 2,
            headerRoomType: [
                { text: 'No.', value: 'no', sortable: false },
                { text: 'Room Type Name', value: 'RMT_NAME', sortable: false },
                { text: 'Description', value: 'RMT_DESC', sortable: false },
            ],
            itemsPerPage: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemRoomType: [],
            selectedItem: [],
            requireField: "This field is required",
            rules:{
                Order: [(val) => (val || "").length > 0 || this.requireField],
                RoomTypeName: [(val) => (val || "").length > 0 || this.requireField],
            }
        }
    },
    methods:{
        checkvalidate(){
            let result = false
            if (!this.$refs.Order.validate()) {
                this.$refs.Order.validate(true)
                this.$refs.Order.focus()
                result = true
            }
            if (!this.$refs.RoomTypeName.validate()) {
                this.$refs.RoomTypeName.validate(true)
                this.$refs.RoomTypeName.focus()
                result = true
            }
            return result
        },
        swalAlert(status){
            if(status){
                Swal.fire({
                    icon: 'success',
                    text: 'Success',
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.closeAddOrEditRoomType()
                    this.getRoomType()
                }, () => {})
            }
            else{
                Swal.fire({
                    icon: 'error',
                    text: res.data.ErrorMessage,
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.dialogAddExternal = true
                }, () => { })
            }
        },
        updateRoomType(){
            if(!this.checkvalidate()){
                this.dialogAddOrEditRoomType = false
                const PROFILE = JSON.parse(localStorage.PROFILE)
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}RoomType/UpdateRoomType`, {
                    RMT_ID: this.RMT_ID,
                    RMT_ORDER: this.RMT_ORDER,
                    RMT_NAME: this.RMT_NAME,
                    RMT_DESC: this.RMT_DESC,
                    STS_ID: this.STS_ID,
                    UPDATED_BY: PROFILE.EMPLOYEE_ID
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.swalAlert(res.data.Status)
                }).catch(() => {
                    this.$store.state.LOADING = false
                })
            }
        },
        closeAddOrEditRoomType(){
            this.dialogAddOrEditRoomType = false
            this.RMT_ORDER = ""
            this.RMT_NAME = ""
            this.RMT_DESC = ""
            this.STS_ID = ""
            this.AddOrEdit = ""
        },
        addRoomType(){
            if(!this.checkvalidate()){
                this.dialogAddOrEditRoomType = false
                const PROFILE = JSON.parse(localStorage.PROFILE)
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}RoomType/InsertRoomType`, {
                    RMT_ORDER: this.RMT_ORDER,
                    RMT_NAME: this.RMT_NAME,
                    RMT_DESC: this.RMT_DESC,
                    STS_ID: this.STS_ID,
                    CREATED_BY: PROFILE.EMPLOYEE_ID
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.$store.state.LOADING = false
                    this.swalAlert(res.data.Status)
                }).catch(() => {
                    this.$store.state.LOADING = false
                })
            }
        },
        actionRoomType(AddOrEdit){
            this.AddOrEdit = AddOrEdit
            this.dialogAddOrEditRoomType = true
        },
        resetInfo(){
            this.selectedItem = []
            this.TEXT_SEARCH = ""
            this.getRoomType()
        },
        deleteRoomType(){
            if(this.selectedItem.length > 0){
                Swal.fire({
                    icon: 'warning',
                    text: 'would you like to delete?',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    confirmButtonColor: '#00AEEF'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.state.LOADING = true
                        axios.post(this.$store.state.API_PATH + 'RoomType/DeleteRoomType', {
                            RoomTypeItem: this.selectedItem
                        }, {
                            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        })
                        .then(res => {
                            this.$store.state.LOADING = false
                            if (res.data.Status) {
                                Swal.fire({
                                    icon: 'success',
                                    text: 'Success',
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                    this.selectedItem = []
                                    this.getRoomType()
                                },()=>{})
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    text: res.data.ErrorMessage,
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                },()=>{})
                            }
                        })
                        .catch(() => {
                            this.$store.state.LOADING = false
                        })
                    }
                })
            }
        },
        getRoomType(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}RoomType/GetRoomType`, {
                RMT_NAME: this.TEXT_SEARCH
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.itemRoomType = res.data.Result
                    const menuConfigData = localStorage.getItem('menuConfig');
                    this.$store.state.menuConfig = JSON.parse(menuConfigData);
                }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        rowClicked(item){
            if(this.$store.state.buttonPermit.menuConfig.Update){
                this.AddOrEdit = "Edit"
                this.RMT_ID = item.RMT_ID
                this.STS_ID = item.STS_ID
                this.RMT_ORDER = item.RMT_ORDER
                this.RMT_NAME = item.RMT_NAME
                this.RMT_DESC = item.RMT_DESC
                this.dialogAddOrEditRoomType = true
            }
        },
    },
    mounted(){
        this.getRoomType()
    }
}
</script>
<style scoped>
.font-W700{
    font-weight: 700;
}
.font-W400{
    font-weight: 400;
}
.btn-table{
    background: rgba(0, 174, 239, 0.1) !important;
}
</style>
