<template>
  <div id="app">
    <v-dialog v-if="dialogOpen" v-model="dialogOpen" persistent width="1000px" :retain-focus="false">
      <v-card elevation="0" title color="primary" class="pt-1 card">
        <v-card>
          <v-card-text>
            <v-card class="card-detail" title elevation="0">
              <v-card-text>
                <v-card class="mb-3">
                  <v-col>
                    <v-text-field dense label="Subject" outlined v-model="Subject" disabled></v-text-field>
                  </v-col>
                </v-card>
                <v-spacer></v-spacer>
                <editor v-if="showMce" v-model="htlmString" api-key="zme9n7btzvgd8wqufmthz6wgggu9n3z8e45y1ehvd2r3yf8a"
                  :init="$store.state.configTinyMce" />
                <v-layout>
                  <v-flex class="text-right" style="margin-top: 10px;">
                    <v-btn style="margin-right: 10px;" data-test="getEmpById" color="primary"
                      @click="changeValueContent()">Save</v-btn>
                    <v-btn style="margin-right: 10px;" data-test="getEmpById" color="primary"
                      @click="closeDialog()">Cancel</v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-card>
    </v-dialog>

    <v-row class="mb-3" no-gutters>
      <v-col>
        <span>
          <span class="font-W700" style="font-size: 1em;">Search</span>
        </span>
      </v-col>
    </v-row>
    <v-row class="mb-3" no-gutters>
      <v-col>
        <v-card elevation="0" title>
          <v-card-text>
            <v-row no-gutters align="center" justify="center">
              <v-col lg="4" cols="8">
                Search
                <v-text-field outlined dense background-color="white" v-model="TEXT_SEARCH"
                  @keypress.enter="searchContent()"></v-text-field>
              </v-col>
              <v-col md="auto" sm="auto">
                <v-btn color="primary" elevation="0" class="ml-1" @click="searchContent()">Search</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-3" no-gutters>
      <v-col>
        <v-card elevation="0" title>
          <v-card-text>
            <v-card elevation="0">
              <template>
                <v-data-table :headers="headers" :key="render" :items="desserts" class="elevation-1" @click:row="getDataContent">
                  <template v-slot:[`item.no`]="{ index }">{{ index + 1 }}</template>
                </v-data-table>
              </template>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import axios from "axios"
import Swal from 'sweetalert2';
export default {
  name: 'app',
  components: {
    'editor': Editor
  },

  data() {
    return {
      render: 0,
      showMce: false,
      dialogOpen: false,
      TEXT_SEARCH: "",
      desserts: [],
      headers: [
        { text: 'No.', align: 'start', key: 'no', value: 'no' },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'Subject',
        },
      ],
      htlmString: "",
      Subject: "",
      BTMDocID: "",
    }
  },
  methods: {
    closeDialog() {
      this.htlmString = ""
      this.Subject = ""
      this.dialogOpen = false
    },
    getDataContent(value) {
      if(this.$store.state.buttonPermit.menuConfig.Update){
        axios.post(this.$store.state.API_PATH + "BTMDocument/GetBTMDocumentDescription", {
          "ID": `${value.ID}`,
        }, {
          headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        }).then(async (res) => {
          if (res.data.Status) {
            this.dialogOpen = true;
            this.BTMDocID = value.ID
            this.Subject = value.Subject
            this.htlmString = res.data.Result[0].Description
            setTimeout(() => {
              this.showMce = true
            }, 100)
          } else {
            console.log("No response");
          }
        }).catch(err => {
          this.$store.state.LOADING = false
          console.log(err)
        })
      }
    },
    changeValueContent() {
      this.$store.state.LOADING = true
      const PROFILE = JSON.parse(localStorage.PROFILE)
      axios.post(this.$store.state.API_PATH + "BTMDocument/UpdateBTMDocument", {
        "ID": `${this.BTMDocID}`,
        "Description": `${this.htlmString}`,
        CREATED_BY : PROFILE.EMPLOYEE_ID
      }, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(async (res) => {
        this.$store.state.LOADING = false
        if (res.data.Status) {
          Swal.fire({
            icon: 'success',
            text: 'Success',
          }).then(() => {
            this.dialogOpen = false
          })
        }
      }).catch(err => {
        this.$store.state.LOADING = false
        console.log(err)
      })
    },
    searchContent() {
      axios.post(this.$store.state.API_PATH + "BTMDocument/GetBTMDocument", {
        "Subject": this.TEXT_SEARCH
      }, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(async (res) => {
        if (res.data.Status) {
          this.desserts = res.data.Result
          this.render += 1
        } else {
          console.log("No response");
        }
      }).catch(err => {
        this.$store.state.LOADING = false
        console.log(err)
      })
    }
  },
  mounted() {
    this.searchContent()
  },
}
</script>


