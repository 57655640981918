<template>
    <div>
        <TravelRq />
    </div>
</template>
<script>
import TravelRq from '@/components/travelRequest/TravelRequest.vue'
export default {
    name: 'TravelRequest',
    components: {
        TravelRq,
    },
}
</script>
