<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <span>
                    <span class="font-W700" style="font-size: 1em;">Reconcile</span>
                </span>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <v-row align="center" no-gutters>
                            <v-col>
                                <span size="3" style="font-weight: 500; color: #000000;">Reconcile</span>
                            </v-col>
                        </v-row>
                        <v-row align="center" no-gutters>
                            <v-col>
                                <v-card class="row-card" elevation="0">
                                    <v-card-text>
                                        <v-row no-gutters align="center">
                                            <v-col cols="6" class="pr-2">
                                                Reconcile Type
                                                <v-select outlined dense background-color="white" item-value="Code" v-model="ReconcileSelected" :items="ReconcileType"></v-select>
                                            </v-col>
                                            <v-col cols="6" class="pr-2">
                                                Attached Files
                                                <v-file-input type="file" prepend-icon append-icon="mdi-paperclip"
                                                 @change="input_file()" outlined dense background-color="white"
                                                  v-model="excel_file" id="excel_file" show-size>Import File</v-file-input>
                                            </v-col>
                                            <v-col lg="12" sm="12" cols="12" class="text-center">
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                                <br />
                                <v-card class="row-card" elevation="0" v-if="showTableReconcile">
                                    <v-card-actions>
                                        {{reconcileData.name}}
                                        <v-spacer></v-spacer>
                                        <v-btn class="btn-table mr-2" elevation="0">Submit</v-btn>
                                        <v-btn class="btn-table mr-2" elevation="0" @click="onExport()">Export</v-btn>
                                    </v-card-actions>
                                    <v-card-text>
                                        <v-data-table :headers="reconcileData.headers" :items="reconcileData.files">
                                            <template v-slot:[`item.InvoiceNo`]="{item}">
                                                {{ item.ImportData.InvoiceNo }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.InvoiceNo === item.OriginalData.InvoiceNo">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.PNR`]="{item}">
                                                {{ item.ImportData.PNR }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.PNR && item.ImportData.PNR === item.OriginalData.PNR">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.TicketIssueDate`]="{item}">
                                                {{ item.ImportData.TicketIssueDate }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.TicketIssueDate &&
                                                 formatdate(item.ImportData.TicketIssueDate, this.strYMDline) === formatdate(
                                                    item.OriginalData.TicketIssueDate, this.strYMDline)">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.Passenger`]="{item}">
                                                {{ item.ImportData.Passenger }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.Passenger &&
                                                 item.ImportData.Passenger == item.OriginalData.Passenger">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.Route`]="{item}">
                                                {{ item.ImportData.Route }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.Route &&
                                                 item.ImportData.Route.replace('/', '-') === item.OriginalData.Route">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.TicketNo`]="{item}">
                                                {{ item.ImportData.TicketNo }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.TicketNo && item.ImportData.TicketNo === item.OriginalData.TicketNo">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.Amount`]="{item}">
                                                {{ parseFloat(item.ImportData.Amount) }}
                                                <br />
                                                <span class="success-text-color" v-if="parseFloat(
                                                    item.ImportData.Amount).toFixed(2) == parseFloat(
                                                        item.OriginalData.Amount).toFixed(2)">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.Project`]="{item}">
                                                {{ item.ImportData.Project }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.Project && item.ImportData.Project === item.OriginalData.Project">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.BudgetHolder`]="{item}">
                                                {{ item.ImportData.BudgetHolder }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.BudgetHolder &&
                                                 item.ImportData.BudgetHolder === item.OriginalData.BudgetHolder">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.PurposeofTravel`]="{item}">
                                                {{ item.ImportData.PurposeofTravel }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.PurposeofTravel &&
                                                 item.ImportData.PurposeofTravel === item.OriginalData.PurposeofTravel">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.TRNo`]="{item}">
                                                {{ item.ImportData.TRNo }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.TRNo &&
                                                 item.ImportData.TRNo === item.OriginalData.TRNo">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.Vat`]="{item}">
                                                {{ parseFloat(item.ImportData.Vat) }}
                                                <br />
                                                <span class="success-text-color" v-if="parseFloat(
                                                    item.ImportData.Vat).toFixed(2) == parseFloat(
                                                        item.OriginalData.Vat).toFixed(2)">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.Department`]="{item}">
                                                {{ item.ImportData.Department }}
                                                <br />
                                            </template>
                                            <template v-slot:[`item.Fee`]="{item}">
                                                {{ parseFloat(item.ImportData.Fee) }}
                                                <br />
                                                <span class="success-text-color" v-if="parseFloat(item.ImportData.Fee).toFixed(2) == parseFloat(item.OriginalData.Fee).toFixed(2)">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.Total`]="{item}">
                                                {{ parseFloat(item.ImportData.Total) }}
                                                <br />
                                                <span class="success-text-color" v-if="parseFloat(
                                                    item.ImportData.Total).toFixed(2) == parseFloat(
                                                        item.OriginalData.Total).toFixed(2)">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.BookingNo`]="{item}">
                                                {{ item.ImportData.BookingNo }}
                                                <br />
                                            </template>
                                            <template v-slot:[`item.Ext`]="{item}">
                                                {{ item.ImportData.Ext }}
                                                <br />
                                            </template>
                                            <template v-slot:[`item.Agency`]="{item}">
                                                {{ item.ImportData.Agency }}
                                                <br />
                                            </template>
                                            <template v-slot:[`item.Seat`]="{item}">
                                                {{ item.ImportData.Seat }}
                                            </template>
                                            <template v-slot:[`item.FlightNo`]="{item}">
                                                {{ item.ImportData.FlightNo }}
                                                <br />
                                             </template>
                                            <template v-slot:[`item.Depart`]="{item}">
                                                {{ item.ImportData.Depart }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.Depart && item.ImportData.Depart === item.OriginalData.Depart">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.Arrival`]="{item}">
                                                {{ item.ImportData.Arrival }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.Arrival && item.ImportData.Arrival === item.OriginalData.Arrival">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.Class`]="{item}">
                                                {{ item.ImportData.Class }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.Class && item.ImportData.Class === item.OriginalData.Class">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.Tax`]="{item}">
                                                {{ parseFloat(item.ImportData.Tax)}}
                                                <br />
                                                <span class="success-text-color" v-if="parseFloat(item.ImportData.Tax) == parseFloat(item.OriginalData.Tax)">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.TotalPerPax`]="{item}">
                                                {{ item.ImportData.TotalPerPax }}
                                                <br />
                                            </template>
                                            <template v-slot:[`item.Baggage`]="{item}">
                                                {{ item.ImportData.Baggage }}
                                                <br />
                                            </template>
                                            <template v-slot:[`item.BookingDate`]="{item}">
                                                {{ formatdate(item.ImportData.BookingDate, this.strYMDline) }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.BookingDate &&
                                                  formatdate(item.ImportData.BookingDate, this.strYMDline) === formatdate(
                                                    item.OriginalData.BookingDate, this.strYMDline)">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.Booker`]="{item}">
                                                {{ item.ImportData.Booker }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.Booker && item.ImportData.Booker == item.OriginalData.Booker">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.FlightDate`]="{item}">
                                                {{ item.ImportData.FlightDate }}
                                                <br />
                                                <span class="success-text-color" v-if="item.ImportData.FlightDate &&
                                                 formatdate(item.ImportData.FlightDate, this.strYMDline) === formatdate(
                                                    item.OriginalData.FlightDate, this.strYMDline)">
                                                    Match
                                                </span>
                                                <span v-else class="error-text-color">
                                                    Not match
                                                </span>
                                            </template>
                                            <template v-slot:[`item.No`]="{index}">
                                               {{++index}}
                                            </template>
                                            <template v-slot:[`item.Status`]="{item}">
                                                {{ item.ImportData.Status }}
                                            </template>
                                        </v-data-table>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import * as XLSX from 'xlsx'
import axios from 'axios'
import moment from 'moment'
export default {
    data(){
        return{
            strYMD: "YYYY/MM/DD",
            strYMDline: "YYYY-MM-DD",
            XLSX: null,
            reconcileData: {
                name: null,
                files: [],
                headers: []
            },
            excel_file: null,
            ReconcileSelected: '',
            dateFrom: "",
            dateTo: "",
            showTableReconcile: false,
            menuDateFrom: false,
            menuDateTo: false,
            ReconcileType: [
                { text: 'Air Ticket - TG', Code: 'TG'},
                { text: 'Air Ticket - Nok Air', Code: 'NOKAIR'},
                { text: 'Air Ticket - Siam Express', Code: 'SIAMEXPRESS'},
                { text: 'Air Ticket - PAN AIR', Code: 'PANAIR'},
                { text: 'Air Ticket - EXPRESS INTER', Code: 'EXPRESSINTER'},
                { text: 'Air Ticket - BCD', Code: 'BCD'},
            ],
            txtBudgetHolder: "Budget Holder",
            txtTripPurpose: "Trip Purpose",
            txtInvoiceNo: "Invoice No.",
            txtCWTServiceFee: "CWT Service Fee",
            txtBTRNumber: "BTR Number",
            ReconcileHeaders: []
        }
    },
    watch:{
        ReconcileSelected(){
            this.SelectReconcileType()
        }
    },
    methods: {
        formatdate(date, format){
            return moment(date).format(format)
        },
        async onExport() {
            let no = 1
            const wscols = [
                { wpx: 850 }
            ]
            let worksheet = {}
            const headerStyle = {
                border: {
                top: { style: 'thin', color: { rgb: '00000000' } }, // Black color
                bottom: { style: 'thin', color: { rgb: '00000000' } },
                left: { style: 'thin', color: { rgb: '00000000' } },
                right: { style: 'thin', color: { rgb: '00000000' } }
                }
            }
            if(this.reconcileData.files.filter(x => x.ImportData.Status === 'Complete').length > 0){
                    let sumAmount = 0.0
                    let sumFee = 0.0
                    let sumVat = 0.0
                    let sumTotal = 0.0
                    let sumTax = 0.0
                    let modifytoExcel = JSON.parse(JSON.stringify(this.reconcileData.files))
                    modifytoExcel = modifytoExcel.filter(x => x.ImportData.Status === 'Complete')
                    for(const item of modifytoExcel){
                        await new Promise(resolve=> {
                            delete item.ImportData.Status
                            // item.ImportData.Route = `${item.ImportData.Route}`
                            if(item.ImportData.Amount){
                                sumAmount += parseFloat(item.ImportData.Amount)
                            }
                            if(item.ImportData.Fee){
                                sumFee += parseFloat(item.ImportData.Fee)
                            }
                            if(item.ImportData.Tax){
                                sumTax += parseFloat(item.ImportData.Tax)
                            }
                            if(item.ImportData.Vat){
                                sumVat += parseFloat(item.ImportData.Vat)
                            }
                            if(item.ImportData.Total){
                                sumTotal += parseFloat(item.ImportData.Total)
                            }
                            item.no = no
                            no++
                            resolve()
                        })
                    }
                    modifytoExcel.push({ImportData: { Amount: sumAmount, Vat: sumVat, Fee: sumFee, Total: sumTotal, Tax: sumTax }, OriginalData: {}})
                    worksheet = XLSX.utils.aoa_to_sheet([[`รายงานการขายแบบรายสัปดาห์`]])
                    XLSX.utils.sheet_add_json(worksheet, [...modifytoExcel.map(x => x.ImportData)], { skipHeader: false, origin: 'A3' })
                    for (let col = 0; col <= this.reconcileData.headers.length; col++) {
                        if(this.reconcileData.headers[col]?.text !== 'Status'){
                            const cell = XLSX.utils.encode_cell({ r: 2, c: col });
                            const setHeader = await worksheet[cell]
                            worksheet[cell] = await {v: this.reconcileData.headers[col]?.text, t: setHeader?.t, s: headerStyle};
                        }
                    }
                const newBook = XLSX.utils.book_new()
                newBook['!cols'] = wscols;
                XLSX.utils.book_append_sheet(newBook, worksheet)
                XLSX.writeFile(newBook, `Report ${this.ReconcileSelected} (${moment().format('DD MMM YYYY HH-mm')}).xlsx`)
            }
        },
        SelectReconcileType(){
            this.reconcileData.headers = this.ReconcileHeaders.filter(x => x.Code === this.ReconcileSelected)[0].Headers
        },
        input_file(){
            if(this.excel_file){
                const reader = new FileReader()
                const findType = this.excel_file.name.split(".")
                if(this.excel_file && findType[findType.length - 1] === 'xlsx'){
                    reader.readAsArrayBuffer(this.excel_file)
                    reader.onload = async (e) =>{
                        const arrayBuffer = reader.result
                        const rawData = new Uint8Array(arrayBuffer)
                        const workbook = this.XLSX.read(rawData, {type: 'array'})
                        const sheetName = workbook.SheetNames
                        this.reconcileData.name = this.excel_file.name
                        const sheetData = this.XLSX.utils.sheet_to_json(workbook.Sheets[sheetName[0]], {header: 1})
                        if(sheetData.length > 0){
                            await sheetData.forEach(async (elm, index) => {
                                if(index > 0){
                                    const row = []
                                    await elm.forEach((data, index2) => {
                                        const mo = {}
                                        if(this.reconcileData.headers[index2].value === 'FlightDate' ||
                                         this.reconcileData.headers[index2].value === 'BookingDate' ||
                                          this.reconcileData.headers[index2].value === 'TicketIssueDate'){
                                            if(moment(data).format(this.strYMD) !== undefined &&
                                             moment(data).format(this.strYMD) !== '1970/01/01'){
                                                mo[this.reconcileData.headers[index2].value] = moment(
                                                    data).format(this.strYMD) //Variable To Object
                                            }else{
                                                mo[this.reconcileData.headers[index2].value] = moment(
                                                    new Date((data - 25569) * 86400 * 1000)).format(
                                                        this.strYMD) //Variable To Object
                                            }
                                        }else{
                                            mo[this.reconcileData.headers[index2].value] = data //Variable To Object
                                        }
                                        row.push(mo)
                                    })
                                    const result = {
                                        ImportData: Object.assign({}, ...row),
                                        OriginalData: {}
                                    }
                                    result.ImportData.Status = null
                                    await axios.post(`${this.$store.state.API_PATH}Reconcile/ReconcileCheck`, {
                                        // TR: result.TGInvoiceNumber,
                                        PNR: result.ImportData.PNR,
                                        Passenger: result.ImportData.Passenger
                                    }, {
                                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                                    }).then(async res => {
                                        if(res.data.Status && res.data.Result.length > 0){
                                            result.ImportData.Status = 'Complete'
                                            res.data.Result.forEach(x => {
                                                result.OriginalData = x
                                                if(result.ImportData.InvoiceNo !== undefined && x.InvoiceNo !== result.ImportData.InvoiceNo){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.Passenger !== undefined && x.Passenger !== result.ImportData.Passenger){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.Route !== undefined){
                                                    if(result.ImportData.Route.split('/').length > 0){
                                                        if(result.ImportData.Route === x.Route.replace('-', '/')){
                                                            result.ImportData.Status = 'Incomplete'
                                                        }
                                                    }else if(result.ImportData.Route.split('-').length > 0){
                                                        if(result.ImportData.Route === x.Route){
                                                            result.ImportData.Status = 'Incomplete'
                                                        }
                                                    }
                                                }
                                                if(result.ImportData.BookingDate !== undefined && this.formatdate(
                                                    x.FlightDate, this.strYMDline) !== this.formatdate(
                                                        result.ImportData.FlightDate, this.strYMDline)){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.BookingDate !== undefined && this.formatdate(
                                                    x.BookingDate, this.strYMDline) !== this.formatdate(
                                                        result.ImportData.BookingDate, this.strYMDline)){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.TicketNo !== undefined && x.TicketNo !== result.ImportData.TicketNo){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.Amount !== undefined && parseFloat(
                                                    x.Amount).toFixed(2) !== parseFloat(result.ImportData.Amount).toFixed(2)){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.Fee !== undefined && parseFloat(
                                                    x.Fee).toFixed(2) !== parseFloat(result.ImportData.Fee).toFixed(2)){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.Vat !== undefined && parseFloat(
                                                    x.Vat).toFixed(2) !== parseFloat(result.ImportData.Vat).toFixed(2)){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.Total !== undefined && parseFloat(
                                                    x.Total).toFixed(2) !== parseFloat(result.ImportData.Total).toFixed(2)){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.PNR !== undefined && x.PNR !== result.ImportData.PNR){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.TRNo !== undefined && x.TRNo !== result.ImportData.TRNo){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.BudgetHolder !== undefined &&
                                                 x.BudgetHolder !== result.ImportData.BudgetHolder){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.PurposeofTravel !== undefined &&
                                                 x.PurposeofTravel !== result.ImportData.PurposeofTravel){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                                if(result.ImportData.Project !== undefined &&
                                                 x.Project !== result.ImportData.Project){
                                                    result.ImportData.Status = 'Incomplete'
                                                }
                                            })
                                        }else{
                                            result.ImportData.Status = 'Data Not Found'
                                        }
                                    })
                                    this.reconcileData.files.push(result)
                                }
                            })
                            this.showTableReconcile = true
                        }
                    }
                }else{
                    this.FileCheck = true
                    this.newFileheader = []
                }
            }
        }
    },
    mounted(){
        const newHeader = [
            { value: 'InvoiceNo', text: this.txtInvoiceNo },
            { value: 'FlightDate', text: 'Date' },
            { value: 'Passenger', text: 'Passenger' },
            { value: 'Route', text: 'Itinerary' },
            { value: 'TicketNo', text: 'Ticket' },
            { value: 'Amount', text: 'Amount' },
            { value: 'Fee', text: this.txtCWTServiceFee },
            { value: 'Vat', text: 'VAT 7%' },
            { value: 'Total', text: 'Total' },
            { value: 'PNR', text: 'PNR NO.' },
            { value: 'TRNo', text: this.txtBTRNumber },
            { value: 'Department', text: 'Department' },
            { value: 'BudgetHolder', text: this.txtBudgetHolder },
            { value: 'PurposeofTravel', text: this.txtTripPurpose },
            { value: 'Project', text: 'Project' },
            { value: 'Status', text: 'Status' }
        ]
        this.ReconcileHeaders = [
            {
                Code: 'TG',
                Headers: [
                    { value: 'InvoiceNo', text: 'TG Invoice Number',    },
                    { value: 'TicketIssueDate', text: 'Ticket Issue Date',},
                    { value: 'Passenger', text: 'Traveler Name',},
                    { value: 'Route', text: 'Route',},
                    { value: 'TicketNo', text: 'Ticket Number',},
                    { value: 'Total', text: 'Total Amount (THB)',},
                    { value: 'PNR', text: 'PNR Code',},
                    { value: 'Status', text: 'Status'},
                ]
            },
            {
                Code: 'NOKAIR',
                Headers: [
                    { value: 'No', text: 'No.',},
                    { value: 'InvoiceNo', text: 'Tax invoice no.',},
                    { value: 'BookingNo', text: 'Booking No.',},
                    { value: 'PNR', text: 'PNR',},
                    { value: 'Ext', text: 'Ext No.',},
                    { value: 'Agency', text: 'Agency Code',},
                    // { value: 'ApprovalCode', text: 'Approval Code'},
                    { value: 'Passenger', text: 'Pax Name',},
                    { value: 'Seat', text: 'Seat' },
                    { value: 'BookingDate', text: 'Create Date',},
                    { value: 'FlightNo', text: 'Flight No.',},
                    { value: 'FlightDate', text: 'Flight Date',},
                    { value: 'Depart', text: 'Depart',},
                    { value: 'Arrival', text: 'Arrive',},
                    { value: 'Class', text: 'Class',},
                    { value: 'Tax', text: 'Airport Tax',},
                    { value: 'Baggage', text: 'Baggage',},
                    { value: 'Vat', text: 'Vat7%',},
                    { value: 'Total', text: 'Total Per Pax',},
                    { value: 'Amount', text: 'Payment Amount',},
                    { value: 'TRNo', text: 'TR Number',},
                    { value: 'Department', text: 'Department',},
                    { value: 'BudgetHolder', text: this.txtBudgetHolder,},
                    { value: 'Project', text: 'Project',},
                    { value: 'PurposeofTravel', text: this.txtTripPurpose,},
                    { value: 'Status', text: 'Status'},
                ]
            },
            {
                Code: 'PANAIR',
                Headers: [
                    { value: 'InvoiceNo', text: this.txtInvoiceNo, width: '100'},
                    { value: 'FlightDate', text: 'Date', width: '100'},
                    { value: 'Passenger', text: 'Passenger', width: '150'},
                    { value: 'Route', text: 'Itinerary', width: '100'},
                    { value: 'TicketNo', text: 'Ticket', width: '150'},
                    { value: 'Amount', text: 'Amount', width: '150'},
                    { value: 'Fee', text: this.txtCWTServiceFee, width: '150'},
                    { value: 'Vat', text: 'VAT 7%', width: '150'},
                    { value: 'Total', text: 'Total', width: '150'},
                    { value: 'PNR', text: 'PNR NO.', width: '150'},
                    { value: 'TRNo', text: this.txtBTRNumber, width: '150'},
                    { value: 'Department', text: 'Department', width: '150'},
                    { value: 'BudgetHolder', text: this.txtBudgetHolder, width: '150'},
                    { value: 'PurposeofTravel', text: this.txtTripPurpose, width: '150'},
                    { value: 'Project', text: 'Project', width: '150'},
                    { value: 'Status', text: 'Status',},
                ]
            },
            {
                Code: 'EXPRESSINTER',
                Headers: [
                    // { value: 'TicketInvoiceNo', text: 'Tkt Invoice No.',},
                    { value: 'InvoiceNo', text: 'Invoice/No.',},
                    { value: 'FlightDate', text: 'Flight Date',},
                    { value: 'Passenger', text: 'Passenger',},
                    { value: 'Route', text: 'Itinerary',},
                    { value: 'TicketNo', text: 'Ticket',},
                    { value: 'Amount', text: 'Amount',},
                    { value: 'Fee', text: this.txtCWTServiceFee,},
                    { value: 'Vat', text: 'VAT/7% Total',},
                    { value: 'Total', text: 'Total',},
                    { value: 'PNR', text: 'PNR/NO.',},
                    { value: 'TRNo', text: 'BTR/Number',},
                    { value: 'Department', text: 'Department',},
                    { value: 'BudgetHolder', text: 'Budget/Holder',},
                    { value: 'PurposeofTravel', text: 'Trip/Purpose',},
                    { value: 'Project', text: 'Project',},
                    { value: 'Booker', text: 'CreateBy',},
                    { value: 'Status', text: 'Status'},
                ]
            },
            {
                Code: 'SIAMEXPRESS',
                Headers: newHeader
            },
            {
                Code: 'BCD',
                Headers: newHeader
            }
        ]
        this.XLSX = require("xlsx");
    }
}
</script>
<style scoped>
.font-W700{
    font-weight: 700;
}
.row-card{
    background: #F8F9FA;
    border: 1px solid #EBEEF4;
    border-radius: 10px;
}
</style>
