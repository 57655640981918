<template>
  <v-row>
    <v-col cols="12">
      <UserProfile />
    </v-col>
  </v-row>
</template>

<script>
import UserProfile from '@/components/UserProfile.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'MyTravelerProfile',
  data() {
    return {
      formContinue: false,
      YourFlight: false,
    }
  },
  components: {
    UserProfile
  },
  methods: {

  },
  mounted(){
  }
}
</script>
