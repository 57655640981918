<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col class="h-space">
                <span class="font-W700" style="font-size: 1em;">Tariff Level</span>
            </v-col>
        </v-row>
        <v-card class="card" elevation="0">
            <v-card-text>
                <div class="bgcard">
                    <v-data-table @click:row="getTariffLevel" :items="tariff" :headers="tariffheaders" hide-default-footer>
                        <template v-slot:[`item.no`]="{index}">
                            {{ ++index }}
                        </template>
                        <template v-slot:[`item.EconomyClass`]="{item}">
                            <div class="text-center">
                                <v-icon v-if="item.EconomyClass === 1" color="success" x-large>mdi-check-circle-outline</v-icon>
                                <v-icon v-else color="error">
                                    mdi-close-circle-outline
                                </v-icon>
                            </div>
                        </template>
                        <template v-slot:[`item.BusinessClass`]="{item}">
                            <div class="text-center">
                                <v-icon v-if="item.BusinessClass === 1" color="success" x-large>mdi-check-circle-outline</v-icon>
                                <v-icon v-else color="error">
                                    mdi-close-circle-outline
                                </v-icon>
                            </div>
                        </template>
                        <template v-slot:[`item.FirstClass`]="{item}">
                            <div class="text-center">
                                <v-icon v-if="item.FirstClass === 1" color="success" x-large>mdi-check-circle-outline</v-icon>
                                <v-icon v-else color="error">
                                    mdi-close-circle-outline
                                </v-icon>
                            </div>
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
        </v-card>
        <v-dialog width="800" v-if="openDialog.dialog" v-model="openDialog.dialog" persistent>
            <v-card class="card">
                <v-card-title>{{ openDialog.value.TariffName }}</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col cols="4">
                            <div class="text-center">
                                Economy Class
                                <br />
                                <v-icon class="mt-2" v-if="openDialog.value.EconomyClass === 1" color="success" x-large>mdi-check-circle-outline</v-icon>
                                <v-icon class="mt-2" v-else color="error">
                                    mdi-close-circle-outline
                                </v-icon>
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <div class="text-center">
                                Business Class
                                <br />
                                <v-icon class="mt-2" v-if="openDialog.value.BusinessClass === 1" color="success" x-large>mdi-check-circle-outline</v-icon>
                                <v-icon class="mt-2" v-else color="error">
                                    mdi-close-circle-outline
                                </v-icon>
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <div class="text-center">
                                First Class
                                <br />
                                <v-icon class="mt-2" v-if="openDialog.value.FirstClass === 1" color="success" x-large>mdi-check-circle-outline</v-icon>
                                <v-icon class="mt-2" v-else color="error">
                                    mdi-close-circle-outline
                                </v-icon>
                            </div>
                        </v-col>
                    </v-row>
                    <div class="h-space"></div>
                    <v-card elevation="0">
                        <v-card-text class="bgcard">
                            <v-row>
                                <v-col cols="5">
                                    <strong>Stand By</strong>
                                    <div class="h-space"></div>
                                    <v-data-table v-model="tariffStandBy" :items="tariffLevels" show-select item-key="TariffLevel" @click:row="clickRow($event, 'StandBy')"
                                    checkbox-color="primary" mobile-breakpoint :headers="tariffLevelHeaders" hide-default-footer hide-default-header></v-data-table>
                                </v-col>
                                <v-col cols="2" style="position: relative">
                                    <v-row class="text-cente heightcenter">
                                        <v-col>
                                            <v-btn class="btn-table" @click="addTariffLevel()">
                                                <img class="hidden-sm-and-down"
                                                    :src="require('@/assets/icons/Arrowrightinfo.svg')"
                                                    alt="" />
                                                <img class="hidden-sm-and-up"
                                                    :src="require('@/assets/icons/ArrowPrimaryUP.svg')"
                                                    alt="" />
                                                <span class="ml-4" style="font-size: .8rem;">Add</span>
                                                <span class="ml-4"></span>
                                            </v-btn>
                                            <div style="height: 10px;"></div>
                                            <v-btn class="btn-table" @click="removeTariffLevel()">
                                                <img class="hidden-sm-and-down"
                                                    :src="require('@/assets/icons/ArrowLeftInfo.svg')" alt="" />
                                                <img class="hidden-sm-and-up"
                                                    :src="require('@/assets/icons/ArrowPrimaryDown.svg')"
                                                    alt="" />
                                                <span class="ml-1" style="font-size: .8rem;">Remove</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="5">
                                    <strong>Selected</strong>
                                    <div class="h-space"></div>
                                    <v-data-table v-model="tariffSelected" :items="openDialog.value.TariffLevel"
                                     show-select item-key="TariffLevel" @click:row="clickRow($event, 'Selected')"
                                     checkbox-color="primary" mobile-breakpoint :headers="tariffLevelHeaders"
                                     hide-default-footer hide-default-header></v-data-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="closeDialog()">Cancel</v-btn>
                    <v-btn color="primary" @click="saveTariff">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            tariffStandBy: [],
            tariffSelected: [],
            tariffLevels: [],
            openDialog: {
                value: null,
                dialog: false
            },
            tariff: [],
            tariffLevelHeaders: [
                { text: "Tariff Level Name", value: 'TariffLevel'},
            ],
            tariffheaders: [
                { text: "No", value: 'no'},
                { text: "Name", value: 'TariffName'},
                { text: "Economy Class", value: 'EconomyClass', align: 'center' },
                { text: "Business Class", value: 'BusinessClass', align: 'center' },
                { text: "First Class", value: 'FirstClass', align: 'center' },
            ]
        }
    },
    methods: {
        saveTariff(){
            axios.post(this.$store.state.API_PATH + "Tariff/ModifyTariff", this.openDialog.value, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.closeDialog()
            }).catch(err =>{
                console.log(err)
            })
        },
        closeDialog(){
            this.openDialog.dialog = false
            this.openDialog.value = null
        },
        addTariffLevel(){
            const standBy = this.tariffStandBy
            this.openDialog.value.TariffLevel.push(...standBy)
            this.tariffLevels = this.tariffLevels.filter(x => this.openDialog.value.TariffLevel.filter(y => x.TariffLevel === y.TariffLevel).length === 0)
            this.tariffStandBy = []
        },
        removeTariffLevel(){
            const selected = this.tariffSelected
            this.tariffLevels.push(...selected)
            this.openDialog.value.TariffLevel = this.openDialog.value.TariffLevel.filter(x => this.tariffSelected.filter(y => x.TariffLevel === y.TariffLevel).length === 0)
            this.tariffSelected = []
        },
        clickRow(item, key){
            if(key === 'StandBy'){
                if (this.tariffStandBy.includes(item)) {
                    this.tariffStandBy.splice(this.tariffStandBy.findIndex(e => e === item), 1)
                }
                else {
                    this.tariffStandBy.push(item)
                }
            }else if(key === 'Selected'){
                if (this.tariffSelected.includes(item)) {
                    this.tariffSelected.splice(this.tariffSelected.findIndex(e => e === item), 1)
                }
                else {
                    this.tariffSelected.push(item)
                }
            }
        },
        getTariffLevel(item){
            if(this.$store.state.buttonPermit.menuConfig.Update){
                this.tariffStandBy = []
                this.tariffSelected = []
                axios.post(this.$store.state.API_PATH + "Tariff/GetTariffLevel?val=", {}, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(async res => {
                    this.tariffLevels = res.data.Result
                }).catch(err =>{
                    console.log(err)
                })
                axios.post(`${this.$store.state.API_PATH}Tariff/GetTariffByID?val=${item.TariffOID}`, {}, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(async res => {
                    this.openDialog.value = res.data.Result
                    this.tariffLevels = this.tariffLevels.filter(x => this.openDialog.value.TariffLevel.filter(y => x.TariffLevel === y.TariffLevel).length === 0)
                    this.openDialog.dialog = true;
                }).catch(err =>{
                    console.log(err)
                })
            }
        },
        getTariff(){
            axios.post(this.$store.state.API_PATH + "Tariff/GetTariff", {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async res => {
                this.tariff = res.data.Result
                const menuConfigData = localStorage.getItem('menuConfig');
                this.$store.state.menuConfig = JSON.parse(menuConfigData);
            }).catch(err =>{
            })
        }
    },
    mounted(){
        this.getTariff()
    }
}
</script>
