<template>
    <div>
        <v-card elevation="0">
            <div class="fade">
                <v-img :src="slideShow.ImgCover" style="width:100%; max-height: 350px; border-radius: 5px;">
                    <v-row class="mb-2 mr-3 text-end">
                        <v-col cols="12">
                            <v-row>
                                <v-col>
                                    <h1>
                                        <span class="w700">{{ slideShow.Subject }}</span>
                                    </h1>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col></v-col>
                                <v-col lg="6" md="6" cols="12">
                                    <v-card elevation="0" color="transparent">
                                        <p>{{ slideShow.Preview.length > 220 ? `${slideShow.Preview.substring(0, 220)}.....` : slideShow.Preview }}</p>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row align="end">
                                <v-col>
                                    <v-btn outlined @click="gotoHomeContent()" v-if="slideShow.ID">Read more</v-btn>
                                </v-col>
                            </v-row>
                            <v-row align="end" v-if="imageSlide.length > 0">
                                <v-col>
                                    <span :class="item.no == slide ? 'dotSelect' : 'dot'" v-for="(item, index) in imageSlide" :key="index" @click="carousel(item.no)"></span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-img>
            </div>
        </v-card>
        <v-card elevation="0" class="mt-5">
            <v-card-text>
                <v-tabs v-model="tab1">
                    <v-tab key="tab1" @click="getPending()">
                        <span class="spanBlackFont">My Tasks</span>
                        <v-badge v-if="itemPending.length > 0" inline :content="itemPending.length" color="#FF0000"></v-badge>
                    </v-tab>
                    <v-tab key="tab2" @click="getRequest()">
                        <span class="spanBlackFont">My Travel Request</span>
                        <v-badge v-if="itemRequest.length > 0" inline :content="itemRequest.length" color="#FF0000"></v-badge>
                    </v-tab>
                    <v-row class="text-right ml-5">
                        <v-col>
                            <v-btn class="mt-2" color="primary" elevation="0" @click="createTra()">Create Travel Request</v-btn>
                        </v-col>
                    </v-row>
                </v-tabs>
                <v-tabs-items v-model="tab1" touchless>
                    <!-- My Tasks -->
                    <v-tab-item>
                        <v-container fluid>
                            <v-row class="mt-3">
                                <v-col>
                                    <v-btn-toggle color="white" dense>
                                        <v-btn ref="btnPending" :color="clickBtnToggle === 'Pending' ||
                                        clickBtnToggle === 'Submitted' ||
                                        clickBtnToggle === 'Submit' ? 'primary' : ''" @click="getPending()">
                                            Pending
                                            <v-badge v-if="itemPending.length > 0" inline :content="itemPending.length" color="#FF0000"></v-badge>
                                        </v-btn>
                                        <v-btn ref="btnReturn" :color="clickBtnToggle === 'Return' ? 'primary' : ''" @click="getReturn()">
                                            Return
                                        </v-btn>
                                        <v-btn ref="btnApproveReject" :color="clickBtnToggle === 'Approve/Reject' ? 'primary' : ''"
                                        @click="getApproveOrReject()">Approve/Reject</v-btn>
                                    </v-btn-toggle>
                                </v-col>
                            </v-row>
                            <!-- Pending -->
                            <v-card class="overflow-x-auto myTicket" elevation="0" v-if="clickBtnToggle == 'Pending' ||
                            clickBtnToggle == 'Submitted' || clickBtnToggle == 'Submit'">
                                <v-layout mt-5 v-if="itemPending.length == 0 && !LoadingMyTasks">
                                    <v-flex>
                                        <span>No Information</span>
                                    </v-flex>
                                </v-layout>
                                <v-layout mt-5 text-center v-if="LoadingMyTasks">
                                    <v-flex>
                                        <v-progress-linear indeterminate color="#00AEEF" height="8" rounded></v-progress-linear>
                                    </v-flex>
                                </v-layout>
                                <v-layout mb-4>
                                    <template v-for="(item, index) in itemPending">
                                        <v-card color="primary" :key="index"
                                        style="border: 1px solid #00AEEF;" width="350" height="100%" elevation="0" class="mt-3 mr-3">
                                            <v-card-text class="ma-0 pa-0 clickTr text-decoration">
                                                <v-row class="flex-row-reverse" no-gutters dense>
                                                    <v-badge content="NEW" color="#FF0000" overlap class="mb-5 mr-lg-11 mr-md-11 mr-sm-10 mr-11"></v-badge>
                                                </v-row>
                                                <v-row dense no-gutters @click="getTravelRequest(item)">
                                                    <v-col class="ml-4" style="text-decoration: underline #00AEEF;">
                                                        <span size="2" style="font-weight: 400; color: white;">Request Number</span>
                                                    </v-col>
                                                    <v-col class="text-right mr-4 mb-4">
                                                        <span size="4" style="font-weight: 700; color: white;">{{ item.TRNo }}</span>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-text style="background: white;">
                                                <v-row>
                                                    <v-col>
                                                        <v-tabs :color="colorStatus(item)">
                                                            <v-tab class="subtab-active" href="#subtab1" v-if="item.FlightDetail.length > 0">
                                                                <v-img :src="require('@/assets/icons/plane-ticket.svg')" width="30px"></v-img>
                                                                <span style="font-weight: 400; color: black;">Flight</span>
                                                            </v-tab>
                                                            <v-tab class="subtab-active ml-1" href="#subtab2" v-if="item.HotelDetail.length > 0">
                                                                <v-img :src="require('@/assets/icons/hotel.svg')" width="30px"></v-img>
                                                                <span style="font-weight: 400; color: black;">Hotel</span>
                                                            </v-tab>
                                                            <v-tab class="subtab-active ml-1" href="#subtab5" v-if="item.TravelExpense.length > 0">
                                                                <span style="font-weight: 400; color: black;">Travel Expense</span>
                                                            </v-tab>
                                                            <!-- Flight -->
                                                            <v-tab-item value="subtab1" v-if="item.FlightDetail.length > 0">
                                                                <v-container>
                                                                    <table style="width: 100%" aria-label="">
                                                                        <tr hidden><th></th></tr>
                                                                        <tbody v-for="(subItem, n) in item.FlightDetail[0].FlightLocation" :key="n">
                                                                            <tr>
                                                                                <td colspan="2">
                                                                                    <span class="hSub-w700">PNR No. {{ subItem.PNR }}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2">
                                                                                    <span class="hSub-w700">{{ subItem.Origin }} - {{ subItem.Destination }}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2">
                                                                                    <span class="hSub-w400">{{ subItem.DepartureDateTime }} - {{ subItem.ArrivalDateTime }}</span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table style="width: 100%" aria-label="">
                                                                        <tr hidden><th></th></tr>
                                                                        <br>
                                                                        <tr>
                                                                            <td colspan="2" class="border-req">
                                                                                <span class="hSub-w300">Passengers</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr v-for="(subItem, subIndex) in item.FlightDetail[0].Passengers" :key="subIndex">
                                                                            <td colspan="2" style="padding-left: 5px;">
                                                                                <span class="hSub-w400">{{ subItem.TITLE }} {{ subItem.FIRSTNAME }} {{ subItem.LASTNAME }}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <br>
                                                                        <tr>
                                                                            <td colspan="2" class="border-req">
                                                                                <span class="hSub-w300">Requester</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr v-for="(subItem, subIndex) in item.FlightDetail[0].Requester" :key="'FlightRe'+subIndex">
                                                                            <td colspan="1" style="padding-left: 5px;">
                                                                                <span class="hSub-w400">{{ subItem.TITLE }}
                                                                                     {{ subItem.FIRSTNAME }}
                                                                                      {{ subItem.LASTNAME }}</span><br>
                                                                            </td>
                                                                            <td colspan="1" v-if="false">
                                                                                <v-img style="float: right; margin-right: 5px;"
                                                                                 :src="require('@/assets/icons/Requester.svg')"></v-img>
                                                                            </td>
                                                                        </tr>
                                                                        <br>
                                                                    </table>
                                                                    <table style="width: 100%" aria-label="" class="border-budget">
                                                                        <tr hidden><th></th></tr>
                                                                        <tr>
                                                                            <td style="padding-left: 5px; white-space:nowrap;">
                                                                                <span class="hSub-w300">Budget Holder</span><br>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span class="hSub-w500">{{ item.FlightDetail[0].BudgetHolder }}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style="padding-left: 5px; white-space:nowrap;">
                                                                                <span class="hSub-w300">Project Name:</span><br>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span class="hSub-w500">{{ item.FlightDetail[0].ProjectName }}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style="padding-left: 5px; white-space:nowrap;">
                                                                                <span class="hSub-w300">BTT Staff</span><br>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span class="hSub-w500">{{ item.FlightDetail[0].BTTStaff }}</span>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <br>
                                                                    <table style="white-space: nowrap; width: 100%;" aria-label="">
                                                                        <tr hidden><th></th></tr>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="hSub-w300">Request date</span>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span class="hSub-w300">Total</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="hSub-w500">{{ item.FlightDetail[0].CreateDate }}</span>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span class="hSub-w500">
                                                                                    THB {{ item.FlightDetail[0].TotalFare.toLocaleString('th',
                                                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </v-container>
                                                            </v-tab-item>

                                                            <!-- Hotel -->
                                                            <v-tab-item value="subtab2" v-if="item.HotelDetail.length > 0">
                                                                <v-container>
                                                                    <table style="width: 100%;" aria-label="">
                                                                        <tr hidden><th></th></tr>
                                                                        <tbody v-for="(hotelList, i) in item.HotelDetail[0].HotelLocation" :key="'A'+i">
                                                                            <tr>
                                                                                <td colspan="2">
                                                                                    <span class="hSub-w700">Booking No. {{ hotelList.BookingNo }}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2">
                                                                                    <span class="hSub-w700">{{ hotelList.HotelName }}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="hSub-w400">{{ hotelList.CheckinoutDateNewFormat }}</span>
                                                                                </td>
                                                                                <td class="text-right" style="width: 50px;">
                                                                                    <span class="hSub-w400">{{ hotelList.Night }} night</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2">
                                                                                    <ul>
                                                                                        <li>
                                                                                            <span class="hSub-w400">Breakfast</span>
                                                                                        </li>
                                                                                        <li>
                                                                                            <span class="hSub-w400">House keeping</span>
                                                                                        </li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                        <br>
                                                                        <tr>
                                                                            <td colspan="2" class="border-req">
                                                                                <span class="hSub-w300">Passengers</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr v-for="(passenger, i) in item.HotelDetail[0].Passengers" :key="'B'+i">
                                                                            <td colspan="2" style="padding-left: 5px;">
                                                                                <span class="hSub-w400">
                                                                                    {{ passenger.TITLE }} {{ passenger.FIRSTNAME }} {{ passenger.LASTNAME }}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                        <br>
                                                                        <tr>
                                                                            <td colspan="2" class="border-req">
                                                                                <span class="hSub-w300">Requester</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr v-for="(requester, i) in item.HotelDetail[0].Requester" :key="'C'+i">
                                                                            <td colspan="1" style="padding-left: 5px;">
                                                                                <span class="hSub-w400">{{ requester.TITLE }}
                                                                                     {{ requester.FIRSTNAME }}
                                                                                      {{ requester.LASTNAME }}</span><br>
                                                                            </td>
                                                                            <td colspan="1">
                                                                                <v-img style="float: right; margin-right: 5px;"
                                                                                 :src="require('@/assets/icons/Requester.svg')"></v-img>
                                                                            </td>
                                                                        </tr>
                                                                        <br>
                                                                    </table>
                                                                    <table class="border-budget" style="white-space: nowrap; width: 100%;" aria-label="">
                                                                        <tr hidden><th></th></tr>
                                                                        <tr>
                                                                            <td style="padding-left: 5px;">
                                                                                <span class="hSub-w300">Budget Holder</span><br>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span class="hSub-w500">{{ item.HotelDetail[0].BudgetHolder }}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style="padding-left: 5px;">
                                                                                <span class="hSub-w300">Project Name:</span><br>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span class="hSub-w500">{{ item.HotelDetail[0].ProjectName }}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style="padding-left: 5px;">
                                                                                <span class="hSub-w300">BTT Staff</span><br>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span class="hSub-w500">{{ item.HotelDetail[0].BTTStaff }}</span>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <br>
                                                                    <table style="white-space: nowrap; width: 100%;" aria-label="">
                                                                        <tr hidden><th></th></tr>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="hSub-w300">Request date</span>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span class="hSub-w300">Total</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="hSub-w500">{{ item.HotelDetail[0].CreateDate }}</span>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span class="hSub-w500">
                                                                                    THB {{ item.HotelDetail[0].TotalHotel.toLocaleString('th',
                                                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </v-container>
                                                            </v-tab-item>

                                                            <!-- TravelExpense -->
                                                            <v-tab-item value="subtab5" v-if="item.TravelExpense.length > 0">
                                                                <v-container>
                                                                    <table style="width: 100%;" aria-label="">
                                                                        <tr hidden><th></th></tr>
                                                                        <tbody v-for="(te, i) in item.TravelExpense" :key="'TE'+i" class="mt-2">
                                                                            <tr>
                                                                                <td colspan="2">
                                                                                    <span class="hSub-w700">Travel Name : {{ te.Name }}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2">
                                                                                    <span class="hSub-w700">Due Date : {{ dmyLLL(te.DueDate) }}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2">
                                                                                    <span class="hSub-w700">Description : {{ te.Description }}</span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </v-container>
                                                            </v-tab-item>
                                                        </v-tabs>
                                                    </v-col>
                                                </v-row>
                                                <v-row justify="center" align="center" class="mb-1 hidden-sm-and-down">
                                                    <v-btn color="#B44545" elevation="0" outlined class="ma-1" @click="actionStatusMytask('Reject', item)">Reject</v-btn>
                                                    <v-btn color="#F0BC2D" elevation="0" class="ma-1" dark @click="actionStatusMytask('Return', item)">Return</v-btn>
                                                    <v-btn color="#7AB800" elevation="0" class="ma-1" dark @click="actionStatusMytask('Approve', item)">Approve</v-btn>
                                                </v-row>
                                                <v-row justify="center" align="center" class="mb-1 hidden-md-and-up">
                                                    <v-btn color="#B44545" elevation="0" outlined class="ma-1" @click="actionStatusMytask('Reject', item)">Reject</v-btn>
                                                    <v-btn color="#F0BC2D" elevation="0" class="ma-1" dark @click="actionStatusMytask('Return', item)">Return</v-btn>
                                                    <v-btn color="#7AB800" elevation="0" class="ma-1" dark @click="actionStatusMytask('Approve', item)">Approve</v-btn>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </v-layout>
                            </v-card>

                            <!-- Return -->
                            <v-card class="mt-3" elevation="0" v-if="clickBtnToggle == 'Return'">
                                <v-container fluid>
                                    <v-layout wrap>
                                        <v-flex>
                                            <span style="font-weight: 500; color: black;">Request list</span>
                                        </v-flex>
                                        <v-flex class="text-right">
                                            <span style="font-weight: 300; color: #747678;">(update {{ updatePageHome }})</span>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout wrap>
                                        <v-flex class="pr-lg-2 pr-md-2 pr-sm-2 pr-0">
                                            <span style="font-weight: 400; color: #747678;">TR No.</span>
                                            <v-text-field v-model="TRNo" outlined dense placeholder="Travel Request Number" background-color="white"
                                            append-icon="mdi-close" @click:append="TRNo = ''"></v-text-field>
                                        </v-flex>
                                        <v-flex class="pr-lg-2 pr-md-2 pr-sm-0 pr-0">
                                            <span style="font-weight: 400; color: #747678;">Project</span>
                                            <v-select outlined dense placeholder="Project name"
                                            append-icon="mdi-close" @click:append="Project = ''" v-model="Project"
                                            :items="ProjectItemReturn" item-text="txt" item-value="val"
                                            background-color="white"></v-select>
                                        </v-flex>
                                        <v-flex class="pr-lg-2 pr-md-2 pr-sm-2 pr-0">
                                            <span style="font-weight: 400; color: #747678;">Action Time</span>
                                            <v-select outlined dense placeholder="Action Time"
                                            append-icon="mdi-close" @click:append="ActionTime = ''" v-model="ActionTime"
                                            :items="ActionTimeItemReturn" item-text="txt" item-value="val"
                                            background-color="white"></v-select>
                                        </v-flex>
                                        <v-flex class="text-right">
                                            <v-btn class="mt-lg-6 mt-md-6 mt-sm-6" color="primary" elevation="0" width="150" @click="getMyTaskByReturn()">Search</v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout class="mt-lg-0 mt-md-0 mt-sm-5 mt-5">
                                        <v-flex>
                                            <v-data-table :headers="headersTable" :items="itemReturn" class="elevation-1"
                                            mobile-breakpoint style="white-space: nowrap" hide-default-footer
                                            @page-count="pageCountReturn = $event" :page.sync="pageReturn" :items-per-page="itemPageReturn" @load="onPagination($event)">
                                                <template v-slot:[`item.actions`]="{ item }">
                                                    <v-img style="cursor: pointer;" :src="require('@/assets/icons/Source_File.svg')" @click="getTravelRequest(item)"></v-img>
                                                </template>
                                            </v-data-table>
                                            <v-row class="mt-3 hidden-xs-only" no-gutters>
                                                <v-col class="d-flex" cols="12">
                                                    <span class="mr-3 mt-3">Show</span>
                                                    <v-select outlined dense :items="itemsPerPageReturn" item-text="text" item-value="value"
                                                    v-model="itemPageReturn" background-color="white" class="select-data-table shrink"></v-select>
                                                    <span class="ml-3 mt-3">items per page</span>
                                                    <v-pagination v-model="pageReturn" :length="pageCountReturn" :total-visible="5"
                                                    class="pagination-custom" style="width: 50%;"></v-pagination>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                                                <v-col class="d-flex" cols="12">
                                                    <span class="mr-3 mt-3">Show</span>
                                                    <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                                    v-model="itemPageReturn" background-color="white" class="select-data-table shrink"></v-select>
                                                    <span class="ml-3 mt-3">items per page</span>
                                                </v-col>
                                                <v-col>
                                                    <v-pagination v-model="pageReturn" :length="pageCountReturn" :total-visible="5"
                                                    class="pagination-custom"></v-pagination>
                                                </v-col>
                                            </v-row>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card>

                            <!-- Approve/Reject -->
                            <v-card class="mt-3" elevation="0" v-if="clickBtnToggle == 'Approve/Reject'">
                                <v-container fluid>
                                    <v-layout wrap>
                                        <v-flex>
                                            <span style="font-weight: 500; color: black;">Request list</span>
                                        </v-flex>
                                        <v-flex class="text-right">
                                            <span style="font-weight: 300; color: #747678;">(update {{ updatePageHome }})</span>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout wrap>
                                        <v-flex class="pr-lg-2 pr-md-2 pr-sm-2 pr-0">
                                            <span style="font-weight: 400; color: #747678;">TR No.</span>
                                            <v-text-field v-model="TRNo" outlined dense placeholder="Travel Request Number" background-color="white"
                                            append-icon="mdi-close" @click:append="TRNo = ''"></v-text-field>
                                        </v-flex>
                                        <v-flex class="pr-lg-2 pr-md-2 pr-sm-0 pr-0">
                                            <span style="font-weight: 400; color: #747678;">Project</span>
                                            <v-select outlined dense placeholder="Project name"
                                            append-icon="mdi-close" @click:append="Project = ''" v-model="Project"
                                            :items="ProjectItemApproveReject" item-text="txt" item-value="val"
                                            background-color="white"></v-select>
                                        </v-flex>
                                        <v-flex class="pr-lg-2 pr-md-2 pr-sm-2 pr-0">
                                            <span style="font-weight: 400; color: #747678;">Action Time</span>
                                            <v-select outlined dense placeholder="Action Time"
                                            append-icon="mdi-close" @click:append="ActionTime = ''" v-model="ActionTime"
                                            :items="ActionTimeItemApproveReject" item-text="txt" item-value="val"
                                            background-color="white"></v-select>
                                        </v-flex>
                                        <v-flex class="text-right">
                                            <v-btn class="mt-lg-6 mt-md-6 mt-sm-6" color="primary" elevation="0" width="150" @click="getMyTaskByApproveReject()">Search</v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout class="mt-lg-0 mt-md-0 mt-sm-5 mt-5">
                                        <v-flex>
                                            <v-data-table :headers="headersTable" :items="itemApproveReject" class="elevation-1"
                                            mobile-breakpoint style="white-space: nowrap" hide-default-footer
                                            @page-count="pageCountApproveReject = $event" :page.sync="pageApproveReject"
                                            :items-per-page="itemPageApproveReject" @load="onPagination($event)">
                                                <template v-slot:[`item.actions`]="{ item }">
                                                    <v-img style="cursor: pointer;" :src="require('@/assets/icons/Source_File.svg')" @click="getTravelRequest(item)"></v-img>
                                                </template>
                                            </v-data-table>
                                            <v-row class="mt-3 hidden-xs-only" no-gutters>
                                                <v-col class="d-flex" cols="12">
                                                    <span class="mr-3 mt-3">Show</span>
                                                    <v-select outlined dense :items="itemsPerPageApproveReject" item-text="text" item-value="value"
                                                    v-model="itemPageApproveReject" background-color="white" class="select-data-table shrink"></v-select>
                                                    <span class="ml-3 mt-3">items per page</span>
                                                    <v-pagination v-model="pageApproveReject" :length="pageCountApproveReject" :total-visible="5"
                                                    class="pagination-custom" style="width: 50%;"></v-pagination>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                                                <v-col class="d-flex" cols="12">
                                                    <span class="mr-3 mt-3">Show</span>
                                                    <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                                    v-model="itemPageApproveReject" background-color="white" class="select-data-table shrink"></v-select>
                                                    <span class="ml-3 mt-3">items per page</span>
                                                </v-col>
                                                <v-col>
                                                    <v-pagination v-model="pageApproveReject" :length="pageCountApproveReject" :total-visible="5"
                                                    class="pagination-custom"></v-pagination>
                                                </v-col>
                                            </v-row>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card>

                        </v-container>
                    </v-tab-item>

                    <!-- My Travel Request -->
                    <v-tab-item>
                        <v-container fluid>
                            <v-row class="mt-3">
                                <v-col>
                                    <v-btn-toggle color="white" dense>
                                        <v-btn :color="clickBtnToggleRec == 'Request' ? 'primary' : ''" @click="getRequest()">
                                            Request
                                            <v-badge v-if="itemRequest.length > 0" inline :content="itemRequest.length" color="#FF0000"></v-badge>
                                        </v-btn>
                                        <v-btn ref="btnHistory" :color="clickBtnToggleRec == 'History' ? 'primary' : ''" @click="getHistory()">
                                            History
                                        </v-btn>
                                    </v-btn-toggle>
                                </v-col>
                            </v-row>

                            <!-- Request -->
                            <v-card class="overflow-x-auto myTicket" elevation="0" v-if="clickBtnToggleRec == 'Request'">
                                <v-layout mt-5 v-if="itemRequest.length == 0">
                                    <v-flex>
                                        <span>No Information</span>
                                    </v-flex>
                                </v-layout>
                                <v-layout mb-4>
                                    <v-card :color="colorStatus(item)" v-for="(item, index) in itemRequest"
                                    :key="index" :style="'border: 1px solid ' + colorStatus(item) +';'"
                                    width="350" height="100%" elevation="0" class="mt-3 mr-3">
                                        <v-card-text class="ma-0 pa-0 clickTr text-decoration">
                                            <v-row class="flex-row-reverse" no-gutters dense>
                                                <v-badge content="NEW" color="#FF0000" overlap class="mb-5 mr-lg-11 mr-md-11 mr-sm-10 mr-11" v-if="item.Read === 0"></v-badge>
                                                <div class="mb-5 mr-lg-11 mr-md-11 mr-sm-10 mr-11" v-else></div>
                                            </v-row>
                                            <v-row dense no-gutters @click="getTravelRequestByMyTravel(item)">
                                                <v-col class="ml-4" :style="'text-decoration: underline' + colorStatus(item) + ';'">
                                                    <span size="2" style="font-weight: 400; color: white;">Request Number</span>
                                                </v-col>
                                                <v-col class="text-right mr-4 mb-4">
                                                    <span size="4" style="font-weight: 700; color: white;">{{ item.TRNo }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-text style="background: white;">
                                            <v-row>
                                                <v-col>
                                                    <v-tabs :color="colorStatus(item)">
                                                        <v-tab class="subtab-active" href="#subtab1" v-if="item.FlightDetail.length > 0">
                                                            <v-img :src="require('@/assets/icons/plane-ticket.svg')" width="30px"></v-img>
                                                            <span style="font-weight: 400; color: black;">Flight</span>
                                                        </v-tab>
                                                        <v-tab class="subtab-active ml-1" href="#subtab2" v-if="item.HotelDetail.length > 0">
                                                            <v-img :src="require('@/assets/icons/hotel.svg')" width="30px"></v-img>
                                                            <span style="font-weight: 400; color: black;">Hotel</span>
                                                        </v-tab>
                                                        <!-- Flight -->
                                                        <v-tab-item value="subtab1" v-if="item.FlightDetail.length > 0">
                                                            <v-container>
                                                                <table style="width: 100%" aria-label="">
                                                                    <tr hidden><th></th></tr>
                                                                    <tbody v-for="(subItem, n) in item.FlightDetail[0].FlightLocation" :key="n">
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <span class="hSub-w700">PNR No. {{ subItem.PNR }}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <span class="hSub-w700">{{ subItem.Origin }} - {{ subItem.Destination }}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <span class="hSub-w400">{{ subItem.DepartureDateTime }} - {{ subItem.ArrivalDateTime }}</span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <table style="width: 100%" aria-label="">
                                                                    <tr hidden><th></th></tr>
                                                                    <br>
                                                                    <tr>
                                                                        <td colspan="2" class="border-req">
                                                                            <span class="hSub-w300">Passengers</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-for="(subItem, subIndex) in item.FlightDetail[0].Passengers" :key="subIndex">
                                                                        <td colspan="2" style="padding-left: 5px;">
                                                                            <span class="hSub-w400">{{ subItem.TITLE }} {{ subItem.FIRSTNAME }} {{ subItem.LASTNAME }}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <br>
                                                                    <tr>
                                                                        <td colspan="2" class="border-req">
                                                                            <span class="hSub-w300">Requester</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-for="(subItem, subIndex) in item.FlightDetail[0].Requester" :key="'FlightRe'+subIndex">
                                                                        <td colspan="1" style="padding-left: 5px;">
                                                                            <span class="hSub-w400">{{ subItem.TITLE }} {{ subItem.FIRSTNAME }} {{ subItem.LASTNAME }}</span><br>
                                                                        </td>
                                                                    </tr>
                                                                    <br>
                                                                </table>
                                                                <table style="width: 100%" aria-label="" class="border-budget">
                                                                    <tr hidden><th></th></tr>
                                                                    <tr>
                                                                        <td style="padding-left: 5px; white-space:nowrap;">
                                                                            <span class="hSub-w300">Budget Holder</span><br>
                                                                        </td>
                                                                        <td class="text-right">
                                                                            <span class="hSub-w500">{{ item.FlightDetail[0].BudgetHolder }}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="padding-left: 5px; white-space:nowrap;">
                                                                            <span class="hSub-w300">Project Name:</span><br>
                                                                        </td>
                                                                        <td class="text-right">
                                                                            <span class="hSub-w500">{{ item.FlightDetail[0].ProjectName }}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="padding-left: 5px; white-space:nowrap;">
                                                                            <span class="hSub-w300">BTT Staff</span><br>
                                                                        </td>
                                                                        <td class="text-right">
                                                                            <span class="hSub-w500">{{ item.FlightDetail[0].BTTStaff }}</span>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <br>
                                                                <table style="white-space: nowrap; width: 100%;" aria-label="">
                                                                    <tr hidden><th></th></tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="hSub-w300">Request date</span>
                                                                        </td>
                                                                        <td class="text-right">
                                                                            <span class="hSub-w300">Total</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="hSub-w500">{{ item.FlightDetail[0].CreateDate }}</span>
                                                                        </td>
                                                                        <td class="text-right">
                                                                            <span class="hSub-w500">
                                                                                THB {{ item.FlightDetail[0].TotalFare.toLocaleString('th',
                                                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </v-container>
                                                        </v-tab-item>

                                                        <!-- Hotel -->
                                                        <v-tab-item value="subtab2" v-if="item.HotelDetail.length > 0">
                                                            <v-container>
                                                                <table style="width: 100%;" aria-label="">
                                                                    <tr hidden><th></th></tr>
                                                                    <tbody v-for="(hotelList, i) in item.HotelDetail[0].HotelLocation" :key="i">
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <span class="hSub-w700">Booking No. {{ hotelList.BookingNo }}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <span class="hSub-w700">{{ hotelList.HotelName }}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="hSub-w400">{{ hotelList.CheckinoutDateNewFormat }}</span>
                                                                            </td>
                                                                            <td class="text-right" style="width: 50px;">
                                                                                <span class="hSub-w400">{{ hotelList.Night }} night</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <ul>
                                                                                    <li>
                                                                                        <span class="hSub-w400">Breakfast</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="hSub-w400">House keeping</span>
                                                                                    </li>
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <br>
                                                                    <tr>
                                                                        <td colspan="2" class="border-req">
                                                                            <span class="hSub-w300">Passengers</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-for="(passenger, i) in item.HotelDetail[0].Passengers" :key="'B'+i">
                                                                        <td colspan="2" style="padding-left: 5px;">
                                                                            <span class="hSub-w400">
                                                                                {{ passenger.TITLE }} {{ passenger.FIRSTNAME }} {{ passenger.LASTNAME }}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <br>
                                                                    <tr>
                                                                        <td colspan="2" class="border-req">
                                                                            <span class="hSub-w300">Requester</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-for="(requester, i) in item.HotelDetail[0].Requester" :key="'C'+i">
                                                                        <td colspan="1" style="padding-left: 5px;">
                                                                            <span class="hSub-w400">{{ requester.TITLE }}
                                                                                 {{ requester.FIRSTNAME }}
                                                                                  {{ requester.LASTNAME }}</span><br>
                                                                        </td>
                                                                        <td colspan="1">
                                                                            <v-img style="float: right; margin-right: 5px;" :src="require('@/assets/icons/Requester.svg')"></v-img>
                                                                        </td>
                                                                    </tr>
                                                                    <br>
                                                                </table>
                                                                <table class="border-budget" style="white-space: nowrap; width: 100%;" aria-label="">
                                                                    <tr hidden><th></th></tr>
                                                                    <tr>
                                                                        <td style="padding-left: 5px;">
                                                                            <span class="hSub-w300">Budget Holder</span><br>
                                                                        </td>
                                                                        <td class="text-right">
                                                                            <span class="hSub-w500">{{ item.HotelDetail[0].BudgetHolder }}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="padding-left: 5px;">
                                                                            <span class="hSub-w300">Project Name:</span><br>
                                                                        </td>
                                                                        <td class="text-right">
                                                                            <span class="hSub-w500">{{ item.HotelDetail[0].ProjectName }}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="padding-left: 5px;">
                                                                            <span class="hSub-w300">BTT Staff</span><br>
                                                                        </td>
                                                                        <td class="text-right">
                                                                            <span class="hSub-w500">{{ item.HotelDetail[0].BTTStaff }}</span>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <br>
                                                                <table style="white-space: nowrap; width: 100%;" aria-label="">
                                                                    <tr hidden><th></th></tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="hSub-w300">Request date</span>
                                                                        </td>
                                                                        <td class="text-right">
                                                                            <span class="hSub-w300">Total</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="hSub-w500">{{ item.HotelDetail[0].CreateDate }}</span>
                                                                        </td>
                                                                        <td class="text-right">
                                                                            <span class="hSub-w500">
                                                                                THB {{ item.HotelDetail[0].TotalHotel.toLocaleString('th',
                                                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </v-container>
                                                        </v-tab-item>
                                                    </v-tabs>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions v-if="item.Status !== 'Draft'">
                                            <v-layout align-center v-if="item.Status !== 'Return'" pa-2>
                                                <v-flex>
                                                    <span size="2" style="font-weight: 400; color: white;">{{ item.UpdateDate }}</span>
                                                </v-flex>
                                                <v-flex text-right>
                                                    <span size="3" style="font-weight: 500; color: white;">{{ item.Status }}</span>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout align-center v-if="item.Status === 'Return'" pl-2 pr-2>
                                                <v-flex>
                                                    <span size="2" style="font-weight: 400; color: white;">{{ item.UpdateDate }}</span>
                                                </v-flex>
                                                <v-flex text-right>
                                                    <span size="3" style="font-weight: 500; color: white;">{{ item.Status }}</span>
                                                    <v-btn elevation="0" style="height: 39px;" class="ml-2"
                                                    @click="editDataReturn(item.TravelRequestOID, item.EMPLOYEE_ID)">Edit</v-btn>
                                                </v-flex>
                                            </v-layout>
                                        </v-card-actions>
                                        <v-card-actions v-if="item.Status === 'Draft'" style="background: rgba(242, 242, 242);">
                                            <v-layout align-center pl-2 pr-2>
                                                <v-flex>
                                                    <v-btn small outlined color="#A7A9AC" style="background: #FFFFFF; width: 39px; height: 39px;"
                                                    @click="editDataDraft(item.TravelRequestOID, item.EMPLOYEE_ID)" elevation="0">
                                                        <v-img class="shrink" :src="require('@/assets/icons/delete-outline.svg')" ></v-img>
                                                    </v-btn>
                                                </v-flex>
                                                <v-flex text-right>
                                                    <v-btn small color="primary" elevation="0" style="width: 39px; height: 39px;"
                                                    @click="editDataDraft(item.TravelRequestOID, item.EMPLOYEE_ID)">
                                                        <v-img class="shrink" :src="require('@/assets/icons/Edit Detail.svg')"></v-img>
                                                    </v-btn>
                                                </v-flex>
                                            </v-layout>
                                        </v-card-actions>
                                    </v-card>
                                </v-layout>
                            </v-card>

                            <!-- History -->
                            <v-card elevation="0" v-if="clickBtnToggleRec == 'History'">
                                <v-row no-gutters align="center" class="mt-4">
                                    <v-col cols="6">
                                        <span class="spanBlackFont">Request list</span>
                                    </v-col>
                                    <v-col cols="6" class="text-right">
                                        <span style="color: #747678;">
                                            (update {{ dataUpdate }} )
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters align="center">
                                    <v-col lg="3" sm="6" cols="12" class="pr-2">
                                        TR No.
                                        <v-text-field outlined dense placeholder="Show all"
                                        v-model="TRNo" background-color="white"
                                        append-icon="mdi-close" @click:append="TRNo = ''"></v-text-field>
                                    </v-col>
                                    <v-col lg="3" sm="6" cols="12" class="pr-2">
                                        Project
                                        <v-select outlined dense placeholder="All Project"
                                        append-icon="mdi-close" @click:append="Project = ''"
                                        :items="ProjectItem" item-text="txt" item-value="val"
                                        v-model="Project" background-color="white"></v-select>
                                    </v-col>
                                    <v-col lg="2" sm="6" cols="12" class="pr-2">
                                        Action Time
                                        <v-select outlined dense placeholder="Show All"
                                        append-icon="mdi-close" @click:append="ActionTime = ''"
                                        :items="ActionTimeItem" item-text="txt" item-value="val"
                                        v-model="ActionTime" background-color="white"></v-select>
                                    </v-col>
                                    <v-col lg="2" sm="6" cols="12" class="pr-2">
                                        Status
                                        <v-select outlined dense placeholder="All status"
                                        append-icon="mdi-close" @click:append="Status = ''"
                                        :items="StatusItem" item-text="txt" item-value="val"
                                        v-model="Status" background-color="white"></v-select>
                                    </v-col>
                                    <v-col lg="2" cols="12" class="text-lg-left text-center">
                                        <v-btn color="primary" elevation="0" @click="GetMyTravelRequestByHistory()"
                                        class="mb-lg-0 mb-md-6 mb-sm-6 mb-6" width="150px">Search</v-btn>
                                    </v-col>
                                </v-row>
                                <v-card elevation="0">
                                    <v-data-table :headers="headersTable" :items="itemHistory" class="elevation-1" item-key="No"
                                    mobile-breakpoint style="white-space: nowrap" hide-default-footer
                                    @page-count="pageCountHistory = $event" :page.sync="pageHistory"
                                    :items-per-page="itemPageHistory" @load="onPagination($event)">
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <v-img style="cursor: pointer;" :src="require('@/assets/icons/Source_File.svg')" @click="getTravelRequest(item)"></v-img>
                                        </template>
                                    </v-data-table>
                                </v-card>
                                <v-row class="mt-3 hidden-xs-only" no-gutters>
                                    <v-col class="d-flex" cols="12">
                                        <span class="mr-3 mt-3">Show</span>
                                        <v-select outlined dense :items="itemsPerPageHistory" item-text="text" item-value="value"
                                        v-model="itemPageHistory" background-color="white" class="select-data-table shrink"></v-select>
                                        <span class="ml-3 mt-3">items per page</span>
                                        <v-pagination v-model="pageHistory" :length="pageCountHistory" :total-visible="5"
                                        class="pagination-custom" style="width: 60%;"></v-pagination>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                                    <v-col class="d-flex" cols="12">
                                        <span class="mr-3 mt-3">Show</span>
                                        <v-select outlined dense :items="itemsPerPageHistory" item-text="text" item-value="value"
                                        v-model="itemPageHistory" background-color="white" class="select-data-table shrink"></v-select>
                                        <span class="ml-3 mt-3">items per page</span>
                                    </v-col>
                                    <v-col>
                                        <v-pagination v-model="pageHistory" :length="pageCountHistory" :total-visible="5"
                                        class="pagination-custom"></v-pagination>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
        <v-layout wrap>

            <!-- Reminder -->
            <v-flex lg9 md9 sm12 xs12>
                <v-layout>
                    <v-flex>
                        <v-card class="mt-5" elevation="0">
                            <v-container>
                                <v-layout>
                                    <v-flex align-self-center>
                                        <span class="hSub-w700">Reminder</span>
                                    </v-flex>
                                    <v-flex text-right>
                                        <v-btn icon @click="showReminder = !showReminder">
                                            <v-icon>{{ showReminder ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                                <v-expand-transition>
                                    <div v-show="showReminder">
                                        <v-layout mt-3>
                                            <v-flex>
                                                <div style="background-color:#E5EAF3; height: 2px; width:100%;"></div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout mt-3 v-if="ReminderItem.length == 0">
                                            <v-flex text-center>
                                                <span>No Information</span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout mt-3 class="overflow-x-auto myTicket" v-if="ReminderItem.length > 0">
                                            <v-flex>
                                                <v-layout mb-4>
                                                    <v-card class="bg-card-Reminder ml-3" elevation="0" width="292"
                                                    v-for="(item, index) in ReminderItem" :key="index">
                                                        <v-container>
                                                            <v-layout>
                                                                <v-flex>
                                                                    <span class="hSub-w300">TR No. {{ item.TRNo }}</span>
                                                                </v-flex>
                                                            </v-layout>
                                                            <v-layout>
                                                                <v-flex>
                                                                    <span class="hSub-w500">{{ item.DepCity }} - {{ item.ArrCity }}</span>
                                                                </v-flex>
                                                            </v-layout>
                                                            <v-layout>
                                                                <v-flex>
                                                                    <span class="hSub-w300" style="white-space: nowrap;">{{ item.DepartureDate }}</span>
                                                                </v-flex>
                                                                <v-flex text-right>
                                                                    <table style="width: 150px;" aria-label="">
                                                                        <tr hidden><th></th></tr>
                                                                        <tr>
                                                                            <td class="hSub-w300" style="font-size: 15px;">Flight {{ item.FlightNumber }}</td>
                                                                        </tr>
                                                                    </table>
                                                                </v-flex>
                                                            </v-layout>
                                                            <v-layout mt-2>
                                                                <v-flex xs2>
                                                                    <v-img :src="require('@/assets/icons/clock.svg')" width="25px"></v-img>
                                                                </v-flex>
                                                                <v-flex>
                                                                    <span class="hSub-w300">{{ item.DepartureDateTime }}</span>
                                                                </v-flex>
                                                            </v-layout>
                                                        </v-container>
                                                    </v-card>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </v-expand-transition>
                            </v-container>
                        </v-card>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex>
                        <v-card class="mt-5" elevation="0">
                            <v-container>
                                <v-layout>
                                    <v-flex align-self-center>
                                        <span class="hSub-w700">BTM Document</span>
                                    </v-flex>
                                    <v-flex text-right>
                                        <v-btn icon @click="showBtmDoc = !showBtmDoc">
                                            <v-icon>{{ showBtmDoc ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                                <v-expand-transition>
                                    <div v-show="showBtmDoc">
                                        <v-layout mt-3>
                                            <v-flex>
                                                <div style="background-color:#E5EAF3; height: 2px; width:100%;"></div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout mt-3 class="overflow-x-auto myTicket">
                                            <v-flex>
                                                <v-layout class="mb-4" v-for="item in btmImg" :key="item.index">
                                                    <v-hover v-slot="{ hover }" v-for="(subItem, index) in item.subBtmImg" :key="index">
                                                        <v-card class="ml-3" :style="hover ? 'opacity: 0.6;' : ''"
                                                        :width="subItem.width" :height="subItem.height"
                                                        @click="gotoBTMDoc(subItem)">
                                                            <v-img :src="subItem.img" height="100%" width="100%">
                                                                <v-card-title>
                                                                    <span style="font-weight: 700; color: white; white-space: pre-line">{{ subItem.title }}</span>
                                                                </v-card-title>
                                                            </v-img>
                                                        </v-card>
                                                    </v-hover>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </v-expand-transition>
                            </v-container>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-flex>

            <!-- BTM Travel Update -->
            <v-flex lg3 md3 sm12 xs12 class="pl-lg-3 pl-md-3">
                <v-card class="mt-5 overflow-x-auto myTicket" elevation="0">
                    <v-container>
                        <v-layout>
                            <v-flex align-self-center>
                                <span class="hSub-w700">BTM Travel Update</span>
                            </v-flex>
                            <v-flex text-right>
                                <v-btn icon @click="showBtmUpdate = !showBtmUpdate">
                                    <v-icon>{{ showBtmUpdate ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                        <v-expand-transition>
                            <v-layout mt-3 v-show="showBtmUpdate">
                                <v-flex>
                                    <div style="background-color:#E5EAF3; height: 2px; width:100%;"></div>
                                </v-flex>
                            </v-layout>
                        </v-expand-transition>
                        <v-card class="overflow-x-auto myTicket" elevation="0" :height="showBtmUpdate ? '970' : ''">
                            <v-expand-transition class="newsCard">
                                <v-container v-show="showBtmUpdate">
                                    <v-layout>
                                        <v-flex>
                                            <v-card class="bg-card-btm-travel-update mt-3" elevation="0"
                                            v-for="(item, index) in btmUpdate" :key="index">
                                                <v-img :src="item.ImgCover" height="100%" width="100%"></v-img>
                                                <v-card-subtitle>
                                                    <span style="font-weight: 700; white-space: pre-line background: #4D4D4D;">{{ item.Subject }}</span>
                                                </v-card-subtitle>
                                                <v-card-text>
                                                    <v-layout>
                                                        <v-flex>
                                                            <span style="font-weight: 700; white-space: pre-line background: #4D4D4D;">{{ item.Preview }}</span>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-layout>
                                                        <v-flex>
                                                            <v-btn outlined @click="gotoNewsDetail(item)">Read more</v-btn>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-card-actions>
                                            </v-card>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout class="mt-5" v-if="btmUpdate.length >= 5 && showMoreNews">
                                        <v-flex class="text-center">
                                            <v-btn outlined fab elevation="0" color="primary" @click="getNewsDoc()">
                                                <v-icon>mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-expand-transition>
                        </v-card>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>

        <!-- Dialog Action Status -->
        <v-dialog v-model="dialogActionStatus" persistent width="400px">
            <v-card elevation="0" title color="primary" class="pt-1 card">
                <v-card>
                    <v-card-text>
                        <v-card class="card-detail" title elevation="0">
                            <v-card-title>
                                <v-row>
                                    <v-col class="text-center">
                                        {{ titleActionStatus }}
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col class="text-center">
                                        <span>Travel Request : <strong>{{ TRNoText }}</strong></span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <table style="margin-left: auto; margin-right: auto;" aria-label="">
                                            <tr hidden><th></th></tr>
                                            <tr v-for="(name, index) in TravelerArrName" :key="index">
                                                <td style="align: right; padding-right: 15px;">
                                                    <span v-if="index === 0">Passenger</span>
                                                </td>
                                                <td><strong>{{ name }}</strong></td>
                                            </tr>
                                        </table>
                                    </v-col>
                                </v-row>
                                <v-row v-if="statusDialog !== 'Approve'">
                                    <v-col class="text-center">
                                        <v-textarea outlined dense background-color="white" auto-grow v-model="noteTR"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn outlined color="primary" class="mr-1" @click="closeDialogActionStatus()">Close</v-btn>
                                <v-btn color="primary" class="ml-1" @click="confirmDialogActionStatus()">Confirm</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import * as moment from 'moment'
import * as _ from 'lodash'
export default {
    data(){
        return{
            PROFILE: {},
            BudgetHolderTR: "",
            statusDialog: "",
            namePassenger: "",
            noteTR: "",
            RemainingDaysText: 'Remaining Days',
            showMoreNews: true,
            LoadingMyTasks: false,
            TravelRequestOID: "",
            ReminderItem: [],
            BudgetHolderApprover: "",
            LineApprovalD: "",
            statusMytask: "",
            TravelerOID: "",
            TRNoText: "",
            titleActionStatus: "",
            dialogActionStatus: false,
            ActionTimeItemApproveReject: [],
            ProjectItemApproveReject: [],
            ActionTimeItemReturn: [],
            ProjectItemReturn: [],
            ProjectItem: [],
            ActionTimeItem: [],
            StatusItem: [],
            TRNo: "",
            Project: "",
            ActionTime: "",
            Status: "",
            TravelerArrID: [],
            TravelerArrName: [],
            itemsPerPageHistory:[
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemPageHistory: 5,
            pageHistory: 1,
            pageCountHistory: 2,
            textActionTime: "Action Time",
            textBudgetHolder: "Budget Holder",
            textHeadTRNo: "Travel Request Number",
            textTravelDate: "Travel Date",
            textBusinessTravelType: "Business Travel Type",
            textBusinessTravelCase: "Business Travel Case",
            textTripPurpose: "Trip Purpose",
            headersRejAndHisTable: [
                { text: this.textActionTime, value: 'ActionTime', sortable: false, align: 'center' },
                { text: 'Status', value: 'Status', sortable: false, align: 'center' },
                { text: this.RemainingDaysText, value: 'RemainningDays', sortable: false, align: 'center' },
                { text: this.textHeadTRNo, value: 'TRNo', sortable: false, align: 'center' },
                { text: 'Type', value: 'TypeTravel', sortable: false, align: 'center' },
                { text: 'Pax', value: 'Pax', sortable: false, align: 'center' },
                { text: this.textBusinessTravelType, value: 'BusinessTravelType', sortable: false, align: 'center' },
                { text: this.textBusinessTravelCase, value: 'BusinessTravelCase', sortable: false, align: 'center' },
                { text: this.textTripPurpose, value: 'PurposeofTravel', sortable: false, align: 'center' },
                { text: '', value: 'actions', sortable: false, align: 'center' },
            ],
            headersTable: [
                { text: this.textActionTime, value: 'ActionTime', sortable: false, align: 'center' },
                { text: 'Status', value: 'Status', sortable: false, align: 'center' },
                { text: this.textBudgetHolder, value: 'BudgetHolder', sortable: false, align: 'center' },
                { text: this.textHeadTRNo, value: 'TRNo', sortable: false, align: 'center' },
                { text: 'Type', value: 'TypeTravel', sortable: false, align: 'center' },
                { text: this.textTravelDate, value: 'TravelDate', sortable: false, align: 'center' },
                { text: 'Pax', value: 'Pax', sortable: false, align: 'center' },
                { text: this.textBusinessTravelType, value: 'BusinessTravelType', sortable: false, align: 'center' },
                { text: this.textBusinessTravelCase, value: 'BusinessTravelCase', sortable: false, align: 'center' },
                { text: this.textTripPurpose, value: 'TripPurpose', sortable: false, align: 'center' },
                { text: '', value: 'actions', sortable: false, align: 'center' },
            ],
            itemsPerPageApproveReject:[
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemPageApproveReject: 5,
            pageApproveReject: 1,
            pageCountApproveReject: 2,
            dataUpdate: moment().format("DD MMM YYYY hh:mm A"),
            showReminder: true,
            showBtmUpdate: false,
            showBtmDoc: false,
            itemsPerPage:[
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemPage: 5,
            page: 1,
            pageCount: 2,
            itemHistory: [],
            itemPending: [],
            itemRequest: [],
            itemReturn: [],
            itemApproveReject: [],
            itemsPerPageReturn:[
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemPageReturn: 5,
            pageReturn: 1,
            pageCountReturn: 2,
            updatePageHome: moment().format("DD MMM YYYY hh:mm A"),
            tab1: null,
            subtab1: null,
            clickBtnToggleRec: "Request",
            clickBtnToggle: "Pending",
            slideShow: {
                ID: "",
                Subject: "",
                Preview: "",
                ImgCover: ""
            },
            slide: 1,
            imageSlide: [],
            txtContact: "Contact Us",
            btmImg: [],
            btmUpdate: [],
            hrefCreateTravelRequest: "/createTravelRq",
            textApproveFinal: "Approve Final"
        }
    },
    methods:{
        gotoHomeContent(){
            window.location.href = `${window.location.origin}/HomeContent?ID=${encodeURIComponent(this.slideShow.ID)}`
        },
        getHomeContentDisplayInHomepage(){
            axios.post(`${this.$store.state.API_PATH}HomeContent/GetHomeContentDisplayInHomepage`, {
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                if(res.data.Status){
                    this.imageSlide = res.data.Result
                    this.slideShow.ID = this.imageSlide[0].ID
                    this.slideShow.Subject = this.imageSlide[0].Subject
                    this.slideShow.Preview = this.imageSlide[0].Preview
                    this.slideShow.ImgCover = res.data.Result[0].ImgCover ? res.data.Result[0].ImgCover : require("@/assets/image/home1.png")
                    setInterval(()=>{
                        if(this.slide === this.imageSlide.length){
                            this.slideShow.ID = this.imageSlide[0].ID
                            this.slideShow.Subject = this.imageSlide[0].Subject
                            this.slideShow.Preview = this.imageSlide[0].Preview
                            this.slideShow.ImgCover = this.imageSlide[0].ImgCover
                            this.slide = 1
                        }
                        else{
                            this.slide++
                            const index = this.imageSlide.findIndex(e=>e.no === this.slide.toString())
                            this.slideShow.ID = this.imageSlide[index].ID
                            this.slideShow.Subject = this.imageSlide[index].Subject
                            this.slideShow.Preview = this.imageSlide[index].Preview
                            this.slideShow.ImgCover = this.imageSlide[index].ImgCover
                        }
                    },6000)
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        gotoNewsDetail(item){
            window.location.href = `${window.location.origin}/NewsDocument?ID=${item.ID}`
        },
        createTra() {
            this.TravelRqloading = true
            sessionStorage.removeItem('coperateData')
            sessionStorage.removeItem('formReqID')
            sessionStorage.removeItem('travelers')
            location.href = "createTravelRq"
        },
        getMyTravelRequestReminder(){
            this.ReminderItem = []
            const PROFILE = JSON.parse(localStorage.PROFILE)
            axios.post(this.$store.state.API_PATH + "TravelRequest/GetMyTravelRequestReminder", {
                EMPLOYEE_ID: PROFILE.EMPLOYEE_ID
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    this.ReminderItem = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        editDataDraft(TravelRequestOID, empId){
            sessionStorage.removeItem('formReqID')
            const formReqID = {
                TravelId: TravelRequestOID,
                EMPLOYEE_ID: empId,
                Pending: 0
            }
            sessionStorage.formReqID = JSON.stringify(formReqID)
            location.href = this.hrefCreateTravelRequest
        },
        editDataReturn(TravelRequestOID, empId){
            sessionStorage.removeItem('formReqID')
            const formReqID = {
                TravelId: TravelRequestOID,
                EMPLOYEE_ID: empId,
                Pending: 0
            }
            sessionStorage.formReqID = JSON.stringify(formReqID)
            location.href = this.hrefCreateTravelRequest
        },
        confirmDialogActionStatus(){
            let StatusAction = ""
            if(this.statusMytask !== this.textApproveFinal){
                StatusAction = this.statusMytask
            }
            else{
                if(this.BudgetHolderApprover === this.LineApprovalD || (this.BudgetHolderApprover && this.LineApprovalD)){
                    StatusAction = this.textApproveFinal
                }
                else if(this.BudgetHolderApprover && !this.LineApprovalD){
                    StatusAction = "Pending Line Approver"
                }
                else{
                    StatusAction = "Pending Budget Holder"
                }
            }
            const PROFILE = JSON.parse(localStorage.PROFILE)
            setTimeout(()=>{
                this.dialogActionStatus = false
                this.$store.state.LOADING = true
                axios.post(this.$store.state.API_PATH + "TravelRequest/UpdateStatusMytask", {
                    TRNo: this.TRNoText,
                    TravelerArrID: this.TravelerArrID,
                    BudgetHolderApprover: this.BudgetHolderApprover,
                    LineApprovalD: this.LineApprovalD,
                    TravelRequestOID: this.TravelRequestOID,
                    Status: StatusAction,
                    Note: this.noteTR,
                    ActionName: `${PROFILE.FIRSTNAME} ${PROFILE.LASTNAME}(${PROFILE.POSITION})`,
                    IsApproved: this.BudgetHolderTR === PROFILE.EMPLOYEE_ID ? "Budget Holder" : "",
                    EMPLOYEE_ID: PROFILE.EMPLOYEE_ID
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res=>{
                    this.$store.state.LOADING = false
                    if(res.data.Status){
                        Swal.fire({
                            icon: 'success',
                            text: 'Success',
                            confirmButtonColor: '#00AEEF'
                        }).then(()=>{
                            this.closeDialogActionStatus()
                            this.getMyTaskByPending()
                        },()=>{})
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            text: res.data.ErrorMessage,
                            confirmButtonColor: '#00AEEF'
                        }).then(()=>{
                            this.dialogActionStatus = true
                        },()=>{})
                    }
                }).catch(err=>{
                    this.$store.state.LOADING = false
                    window.location = window.location.href
                })
            },100)
        },
        async actionStatusMytask(type, item){
            const PROFILE = JSON.parse(localStorage.PROFILE)
            for(const gTraveller of item.TravelerArrID){
                await new Promise((resolve)=>{
                    if (PROFILE.EMPLOYEE_ID === item.TravelRequestApprover) {
                        gTraveller.BudgetHolder = PROFILE.EMPLOYEE_ID
                    }
                    else if (item.BudgetHolderApprover === item.TravelRequestApprover) {
                        gTraveller.BudgetHolder = item.BudgetHolderApprover
                    }
                    else {
                        gTraveller.BudgetHolder = ""
                    }
                    if(!gTraveller.LineApprover){
                        if (item.LineApprovedArr.filter(x=>x.Traveller === gTraveller.Traveller)[0].Approved.split(",")
                        .filter(x=>x === PROFILE.EMPLOYEE_ID).length > 0) {
                            gTraveller.LineApprover = PROFILE.EMPLOYEE_ID
                        }
                        else if (item.LineApprovalD === item.LINE_APPROVE) {
                            gTraveller.LineApprover = item.LineApprovalD
                        }
                        else {
                            gTraveller.LineApprover = ""
                        }
                    }
                    if(type === "Approve"){
                        if(gTraveller.BudgetHolder && gTraveller.LineApprover){
                            gTraveller.Status = "Approve Final"
                        }
                        else if(gTraveller.BudgetHolder){
                            gTraveller.Status = "Pending Line Approver"
                        }
                        else if(gTraveller.LineApprover){
                            gTraveller.Status = "Pending Budget Holder"
                        }
                    }
                    else{
                        gTraveller.Status = type
                    }
                    setTimeout(()=>{
                        resolve()
                    }, 1)
                })
            }
            this.statusMytask = type
            if(type === "Approve"){
                this.statusMytask = this.textApproveFinal
                this.titleActionStatus = "Approve Confirmation !"
            }
            else if(type === "Return"){
                this.titleActionStatus = "Return Confirmation !"
            }
            else{
                this.titleActionStatus = "Reject Confirmation !"
            }
            this.TravelerOID = item.TravelerOID
            this.TravelerArrID = item.TravelerArrID
            this.TravelerArrName = _.uniq(item.TravelerArrName)
            this.TRNoText = item.TRNo
            this.TravelRequestOID = item.TravelRequestOID
            this.namePassenger = item.Name
            this.BudgetHolderTR = item.TravelRequestApprover
            this.statusDialog = type
            this.dialogActionStatus = true
        },
        closeDialogActionStatus(){
            this.dialogActionStatus = false
            this.TravelerOID = ""
            this.titleActionStatus = ""
            this.TRNoText = ""
            this.statusMytask = ""
            this.BudgetHolderApprover = ""
            this.LineApprovalD = ""
            this.noteTR = ""
        },
        clearTravelRequestHistory(){
            this.TRNo = ""
            this.Project = ""
            this.ActionTime = ""
            this.Status = ""
        },
        onPagination(event){
            console.log(event)
        },
        gotoBTMDoc(item){
            if(item.Subject === this.txtContact){
                window.open('https://btm.pttep.com/btm_ict/Upload/ContactUs/ContactUs.pdf');
            } else {
                window.location.href = `${window.location.origin}/BTMDocument?Subject=${encodeURIComponent(item.Subject)}`
            }
        },
        carousel(no){
            this.slideShow = this.imageSlide[this.imageSlide.findIndex(e=>e.no === no)].img
            this.slide = Number(no)
        },
        getPending(){
            this.getMyTaskByPending()
            this.clickBtnToggle = "Pending"
            this.$refs.btnReturn.isActive = false
            this.$refs.btnApproveReject.isActive = false
        },
        getReturn(){
            this.clearTravelRequestHistory()
            this.getMyTaskByReturn()
            this.getMyTaskFilterByReturn()
            this.clickBtnToggle = "Return"
        },
        getApproveOrReject(){
            this.clearTravelRequestHistory()
            this.getMyTaskByApproveReject()
            this.getMyTaskFilterByApproveReject()
            this.clickBtnToggle = "Approve/Reject"
        },
        getRequest(){
            this.getMyTravelRequest()
            this.clickBtnToggleRec = "Request"
            this.clickBtnToggle = this.clickBtnToggleRec
            setTimeout(()=>{
                this.$refs.btnHistory.isActive = false
            },1)
        },
        getHistory(){
            this.clearTravelRequestHistory()
            this.GetMyTravelRequestByHistory()
            this.getMyTravelRequestFilterByHistory()
            this.clickBtnToggleRec = "History"
            this.clickBtnToggle = this.clickBtnToggleRec
        },
        getMyTaskByPending(){
            this.LoadingMyTasks = true
            this.itemPending = []
            const PROFILE = JSON.parse(localStorage.PROFILE)
            axios.post(this.$store.state.API_PATH + "TravelRequest/GetMyTaskByPending", {
                EMPLOYEE_ID: PROFILE.EMPLOYEE_ID
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.LoadingMyTasks = false
                if(res.data.Status){
                    this.itemPending = res.data.Result
                }
            }).catch(err=>{
                this.LoadingMyTasks = false
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        ModifyReq(){
            const PROFILE = JSON.parse(localStorage.PROFILE)
            return {
                EMPLOYEE_ID: PROFILE.EMPLOYEE_ID,
                TRNo: this.TRNo,
                Project: this.Project,
                ActionTime: this.ActionTime,
                Status: this.Status
            }
        },
        getMyTaskByReturn(){
            this.headersTable = [
                { text: this.textActionTime, value: 'ActionTime', sortable: false, align: 'center' },
                { text: this.RemainingDaysText, value: 'RemainningDays', sortable: false, align: 'center' },
                { text: this.textHeadTRNo, value: 'TRNo', sortable: false, align: 'center' },
                { text: 'Type', value: 'TypeTravel', sortable: false, align: 'center' },
                { text: 'Pax', value: 'Pax', sortable: false, align: 'center' },
                { text: this.textBusinessTravelType, value: 'BusinessTravelType', sortable: false, align: 'center' },
                { text: this.textBusinessTravelCase, value: 'BusinessTravelCase', sortable: false, align: 'center' },
                { text: this.textTripPurpose, value: 'PurposeofTravel', sortable: false, align: 'center' },
                { text: '', value: 'actions', sortable: false, align: 'center' },
            ]
            this.itemReturn = []
            this.$store.state.LOADING = true
            axios.post(this.$store.state.API_PATH + "TravelRequest/GetMyTaskByReturn",
               this.ModifyReq(), {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    this.itemReturn = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        getMyTaskFilterByReturn(){
            const PROFILE = JSON.parse(localStorage.PROFILE)
            axios.post(this.$store.state.API_PATH + "TravelRequest/GetMyTaskFilterByReturn", {
                EMPLOYEE_ID: PROFILE.EMPLOYEE_ID,
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    this.ActionTimeItemReturn = res.data.Result.ActionTime
                    this.ProjectItemReturn = res.data.Result.Project
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        getMyTaskByApproveReject(){
            this.headersTable = this.headersRejAndHisTable
            this.itemApproveReject = []
            this.$store.state.LOADING = true
            axios.post(this.$store.state.API_PATH + "TravelRequest/GetMyTaskByApproveReject",
                this.ModifyReq(), {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    this.itemApproveReject = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        getMyTaskFilterByApproveReject(){
            const PROFILE = JSON.parse(localStorage.PROFILE)
            axios.post(this.$store.state.API_PATH + "TravelRequest/GetMyTaskFilterByApproveReject", {
                EMPLOYEE_ID: PROFILE.EMPLOYEE_ID,
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    this.ActionTimeItemApproveReject = res.data.Result.ActionTime
                    this.ProjectItemApproveReject = res.data.Result.Project
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        getMyTravelRequest(){
            const PROFILE = JSON.parse(localStorage.PROFILE)
            axios.post(this.$store.state.API_PATH + "TravelRequest/GetMyTravelRequestByRequest", {
                EMPLOYEE_ID: PROFILE.EMPLOYEE_ID
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    this.itemRequest = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        GetMyTravelRequestByHistory(){
            this.headersTable = this.headersRejAndHisTable
            this.itemHistory = []
            this.$store.state.LOADING = true
            axios.post(this.$store.state.API_PATH + "TravelRequest/GetMyTravelRequestByHistory",
            this.ModifyReq(), {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    this.itemHistory = res.data.Result
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        getMyTravelRequestFilterByHistory(){
            const PROFILE = JSON.parse(localStorage.PROFILE)
            axios.post(this.$store.state.API_PATH + "TravelRequest/GetMyTravelRequestFilterByHistory", {
                EMPLOYEE_ID: PROFILE.EMPLOYEE_ID,
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if(res.data.Status){
                    this.ActionTimeItem = res.data.Result.ActionTime
                    this.ProjectItem = res.data.Result.Project
                    this.StatusItem = res.data.Result.Status
                }
            }).catch(err=>{
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        colorStatus(item){
            let color = ""
            if(item.Status === this.textApproveFinal){
                color = "#7AB800"
            }
            else if(item.Status === "Return"){
                color = "#F0BC2D"
            }
            else if(item.Status === "Reject"){
                color = "#B44545"
            }
            else{
                color = "#00AEEF"
            }
            return color
        },
        getTravelRequest(val) {
            location.href = `${this.hrefCreateTravelRequest}?TR=${val.TravelRequestOID.toString().toUpperCase()}|${val.TRNo}|${
                this.PROFILE.EMPLOYEE_ID}|A|${val.TravelerArrID.map(x=>x.Traveller).toString()}`
        },
        getTravelRequestByMyTravel(val) {
            if(val.Read === 0){
                axios.post(this.$store.state.API_PATH + "TravelRequest/ReadMyTravelRequestByRequest", {
                    TravelerOID: val.TravelerArrID.map(x => `'${x}'`).toString()
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res=>{
                    this.$store.state.LOADING = false
                    if(res.data.Status){
                        this.getTravelRequest(val)
                    }
                }).catch(err=>{
                    this.$store.state.LOADING = false
                    console.log(err)
                })
            }
            else{
                this.getTravelRequest(val)
            }
        },
        getNewsDoc(){
            axios.post(this.$store.state.API_PATH + "News/GetNewsDisplayInHomepage", {
                ID: this.btmUpdate.map(x=>`'${x.ID}'`).toString()
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                if(res.data.Status){
                    res.data.Result.forEach(x=>{
                        this.btmUpdate.push(x)
                    })
                    this.showMoreNews = true
                }
                else{
                    this.showMoreNews = false
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        dmyLLL(date){
            return moment(date).format("DD MMM YYYY")
        }
    },
    computed:{
        newMyTravelRequest(){
            return this.itemRequest.reduce((total,item)=>total+(item.Read),0)
        }
    },
    created(){
        this.btmImg = [
            { index: 1, subBtmImg: [
                { title: "Travel Product \n& Manual", Subject: "Travel Product & Manual", img: require("@/assets/image/Travel_Product_&_Manual.png"), width: 297, height: 300 },
                { title: "Airline & Ticket", Subject: "Airline & Ticket", img: require("@/assets/image/Airline_&_Ticket.png"), width: 297, height: 300 },
                { title: "Corporate Hotel", Subject: "Corporate Hotel", img: require("@/assets/image/Corporate_Hotel.png"), width: 200, height: 300 },
                { title: "Visa Information", Subject: "Visa Information", img: require("@/assets/image/Visa_Information.png"), width: 297, height: 300 },
            ] },
            { index: 2, subBtmImg: [
                { title: "Passport \n& Travel Document", Subject: "Passport & Travel Document",
                img: require("@/assets/image/Passport_&_Travel_Document.png"), width: 297, height: 300 },
                { title: "Tips & Trick Before Travel", Subject: "Passport & Travel Document",
                img: require("@/assets/image/Tips_&_Trick_Before_Travel.png"), width: 297, height: 300 },
                { title: "OHO!!! Promotions", Subject: "OHO!!! Promotions", img: require("@/assets/image/OHO_Promotions.png"), width: 297, height: 300 },
                { title: this.txtContact, Subject: this.txtContact, img: require("@/assets/image/Contact_Us.png"), width: 200, height: 300,},
            ] },
        ]
        if(localStorage.PROFILE){
            this.PROFILE = JSON.parse(localStorage.PROFILE)
            this.getHomeContentDisplayInHomepage()
            this.getMyTaskByPending()
            this.getMyTravelRequest()
            this.getMyTravelRequestReminder()
            this.getNewsDoc()
        }
    },
}
</script>
<style scoped>
.newsCard{
    position: absolute;
    width: 193.7px;
    height: 427.4px;
    left: 427.4px;
    bottom: -233.73px;

    background: linear-gradient(270deg, #FFFFFF 19.43%, rgba(255, 255, 255, 0) 94.27%);
    border-radius: 5px;
    transform: rotate(90deg);
}
.img-responsive {
  max-width: 100%;
  height: auto;
}
.btn-icon-radius5{
    width: 36px;
    height: 36px;
    border-radius: 5px;
}
.bg-card-btm-travel-update{
    /* background: rgba(198, 231, 244, 0.2); */
    border: 1px solid #009FDA;
    border-radius: 5px;
}
.bg-card-Reminder{
    background: rgba(198, 231, 244, 0.2);
    border: 1px solid #009FDA;
    border-radius: 5px;
}
.border-pnr{
    padding: 5px;
    border-radius: 4.35018px;
    border: 1px solid #009FDA;
}
.border-req{
    padding: 5px;
    background: #F2F2F2;
    border-radius: 4.35018px;
}
.border-budget{
    padding: 5px;
    background: #E8F3FF;
    border-radius: 4.35018px;
}
.subtab-active {
    background: #EEF6FF;
    border-radius: 5px 5px 0px 0px;
    /* border-bottom: 2px solid #00AEEF; */
}
.hSub-w700{
    font-weight: 700;
    color: #383838;
}
.hSub-w500{
    font-weight: 500;
    color: #383838;
}
.hSub-w400{
    font-weight: 400;
    color: #383838;
}
.hSub-w300{
    font-weight: 300;
    color: #383838;
}
.w700{
    font-size: 2.5vw !important;;
    font-weight: 700;
}
.w400{
    font-size: 1.3vw;
    font-weight: 400;
}
.w300{
    font-size: 1vw;
    font-weight: 300;
}
.v-tab {
    text-transform: none !important;
}
.v-btn {
    text-transform: none !important;
    /* background-color: transparent !important; */
}
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}
.dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: #00AEEF;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.dotSelect {
  cursor: pointer;
  height: 8px;
  width: 23px;
  margin: 0 2px;
  background-color: #00AEEF;
  border-radius: 20px;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.active, .dot:hover, .dotSelect:hover {
  background-color: #00afefa6;
}
.active, .btn-tab{
    background-color: #00AEEF;
}
.v-card{
    border-radius: 5px;
}
@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

.myTicket::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.myTicket::-webkit-scrollbar-track {
    background: #C8D6ED;
    border-radius: 5px;
}

.myTicket::-webkit-scrollbar-thumb {
    background: #00AEEF;
    border-radius: 5px;
}
.spanBlackFont{
    color: black;
    font-weight: 700;
}
.clickTr{
    cursor: pointer;
}
.text-decoration:hover{
    text-decoration: underline #FFFFFF;
    text-underline-offset: 5px;
}
</style>
