<template>
    <div>
        <v-row class="my-4 mx-4" no-gutters>
            <v-col>
                <span>
                    <span class="font-W700" style="font-size: 1em;">External Traveler Profile</span>
                </span>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row gutters align="center" justify="center">
                                <v-col sm="3" lg="3" cols="8">
                                    External ID
                                    <v-text-field outlined dense background-color="white"
                                    v-model="EX_IDs" @keypress.enter="searchExternal()"></v-text-field>
                                </v-col>
                                <v-col sm="3" lg="3" cols="8">
                                    FirstName
                                    <v-text-field outlined dense background-color="white"
                                    v-model="EX_FNAMEs" @keypress.enter="searchExternal()"></v-text-field>
                                </v-col>
                                <v-col sm="3" lg="3" cols="8">
                                    LastName
                                    <v-text-field outlined dense background-color="white"
                                    v-model="EX_LNAMEs" @keypress.enter="searchExternal()"></v-text-field>
                                </v-col>
                                 <v-col sm="3" lg="3" cols="8">
                                    Email
                                    <v-text-field outlined dense background-color="white"
                                    v-model="EX_EMAILs" @keypress.enter="searchExternal()"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters align="center" justify="center">
                                <v-col md="auto" sm="auto" >
                                    <v-btn color="primary"
                                      @click="searchExternal()" :loading="searchbtnLoading"
                                      elevation="0"
                                      class="mb-lg-0 mb-md-0 mb-sm-0 mb-6">Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0" title>
                    <v-card-text>
                        <v-row no-gutters align="center">
                            <v-col cols="12">
                                <span style="color: #747678;">
                                    Data as of {{ dataUpdate }}
                                </span>
                            </v-col>
                            <v-col class="text-lg-right text-sm-right text-center hidden-xs-only hidden-sm-only">
                                <v-btn class="btn-table mr-2" elevation="0" @click="deleteExternal()"
                                    :disabled="selectedItem.length > 0 ? false : true"
                                    v-if="$store.state.buttonPermit.menuConfig.Delete">
                                    <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1"></v-img>
                                    Delete
                                </v-btn>
                                <v-btn class="btn-table mr-2" elevation="0" @click="reload()" >
                                    <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1"></v-img>
                                    Reset
                                </v-btn>
                                <v-btn class="btn-table" elevation="0" @click="openDialog()" v-if="$store.state.buttonPermit.menuConfig.Add">
                                    <v-img :src="require('@/assets/icons/Add_trans.svg')" width="20px" class="mr-1"></v-img>
                                    Add
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="hidden-md-and-up mt-3 flex-nowrap" no-gutters>
                            <v-col>
                                <v-btn class="btn-table mr-2" elevation="0" @click="deleteExternal()"
                                    :disabled="selectedItem.length > 0 ? false : true"
                                    v-if="$store.state.buttonPermit.menuConfig.Delete">
                                    <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1"></v-img>
                                    Delete
                                </v-btn>
                                <v-btn class="btn-table mr-2" elevation="0">
                                    <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1"></v-img>
                                    Reset
                                </v-btn>
                                <v-btn class="btn-table" elevation="0" @click="openDialog()" v-if="$store.state.buttonPermit.menuConfig.Add">
                                    <v-img :src="require('@/assets/icons/Add_trans.svg')" width="20px" class="mr-1"></v-img>
                                    Add
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-dialog v-if="dialogAddExternal" v-model="dialogAddExternal" :key="componentKey" persistent
                            width="1000px">
                            <v-card elevation="0" title color="primary" class="pt-1 card">
                                <v-card>
                                    <v-card-title>
                                        <v-spacer></v-spacer>
                                        <v-icon @click="closeAddExternal()">mdi-close</v-icon>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-card class="card-detail" title elevation="0">
                                            <v-card-title>
                                                <v-row>
                                                    <v-col class="text-center">
                                                        {{ !EX_PROFILE.EX_ID ? 'Add' : 'Edit' }} External Traveler
                                                    </v-col>
                                                </v-row>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-row no-gutters>
                                                    <v-col cols="3" class="pr-2">
                                                        Title
                                                        <v-select outlined dense background-color="white" ref="TITLE"
                                                            v-model="EX_PROFILE.TITLE" item-text="name" item-value="code"
                                                            :items="titleName" :rules="rules.TITLE" hide-details>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col lg="3" sm="6" cols="12" class="pr-2">
                                                        Firstname
                                                        <v-text-field outlined dense background-color="white" ref="EX_FNAME"
                                                            v-model="EX_PROFILE.EX_FNAME" :rules="rules.EX_FNAME" hide-details>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col lg="3" sm="6" cols="12" class="pr-2">
                                                        Lastname
                                                        <v-text-field outlined dense background-color="white" ref="EX_LNAME"
                                                            v-model="EX_PROFILE.EX_LNAME" :rules="rules.EX_LNAME" hide-details>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col lg="3" sm="6" cols="12" class="pr-2">
                                                        Birth Date
                                                        <v-menu v-model="birthDate.menu" :close-on-content-click="false"
                                                            :nudge-right="40" transition="scale-transition" offset-y
                                                            min-width="auto">
                                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                                <v-text-field
                                                                    :value="birthDate.value ? formatDate(birthDate.value) : ''"
                                                                    ref="Birthday" background-color="white"
                                                                    :rules="rules.Birthday" outlined dense readonly
                                                                    v-bind="attrs" v-on="on" hide-details>
                                                                    <v-icon slot="append" color="info">
                                                                        mdi-calendar
                                                                    </v-icon>
                                                                </v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="birthDate.value" :max="minDate"
                                                                @input="birthDate.menu = false"></v-date-picker>
                                                        </v-menu>
                                                        <div style="float: right;" v-if="birthDate.value">
                                                            {{ totalAge(birthDate.value) >= 144 ? parseInt(totalAge(birthDate.value)/12) +
                                                                ' Year old (Adult)' :
                                                                totalAge(birthDate.value) < 144 && (totalAge(birthDate.value)) >= 24 ?
                                                                 parseInt(totalAge(birthDate.value)/12) + ' Year old (Child)' :
                                                                totalAge(birthDate.value) < 24 ? totalAge(birthDate.value) < 12 ?
                                                                 `${totalAge(birthDate.value)} Month (Infant)`:
                                                                `${parseInt(totalAge(birthDate.value)/12)} year (Infant) ${Math.round(
                                                                    (((totalAge(birthDate.value) / 12)-1)*12))} months` : ''
                                                            }}
                                                        </div>
                                                    </v-col>
                                                     <v-col lg="3" sm="6" cols="12" class="pr-2"
                                                        v-if="birthDate.value && totalAge(birthDate.value) > 144">
                                                        Phone No.
                                                        <v-text-field outlined dense background-color="white" ref="EX_PHONE_NO"
                                                            v-model="EX_PROFILE.EX_PHONE_NO" :rules="rules.EX_PHONE_NO" hide-details>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col lg="3" sm="6" cols="12" class="pr-2"
                                                        v-if="birthDate.value && totalAge(birthDate.value) > 144">
                                                        E-mail
                                                        <v-text-field outlined dense background-color="white"
                                                            v-model="EX_PROFILE.EX_EMAIL">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col lg="3" sm="6" cols="12" class="pr-2"
                                                        v-if="birthDate.value && totalAge(birthDate.value) > 144">
                                                        Company
                                                        <v-text-field outlined dense background-color="white"
                                                            v-model="EX_PROFILE.Company"></v-text-field>
                                                    </v-col>
                                                    <v-col lg="3" sm="6" cols="12" class="pr-2"
                                                        v-if="birthDate.value && totalAge(birthDate.value) > 144">
                                                        Office Phone
                                                        <v-text-field outlined dense background-color="white"
                                                           v-model="EX_PROFILE.EX_OFFICE_PHONE"></v-text-field>
                                                    </v-col>
                                                   <br />
                                                    <v-col cols="12" class="pr-2" v-if="totalAge(birthDate.value) < 144">
                                                        <v-card elevation="0">
                                                            <v-card-text>
                                                            Internal ID
                                                                <v-autocomplete v-model="EX_PROFILE.CBY" :rules="rules.CBY"
                                                                    ref="CBY" background-color="white"
                                                                    @input.native="getInternalID($event.srcElement.value)"
                                                                    :items="Internals" item-value="FULLNAME" item-text="FULLNAME"
                                                                    dense outlined hide-details>
                                                                </v-autocomplete>
                                                                <v-checkbox v-if="false"
                                                                    v-model="EX_PROFILE.ParentsOfChild" label="Parents Of Child"
                                                                    hide-details></v-checkbox>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                     <v-col cols="12">
                                                        <div style="background-color: white;">
                                                            <v-checkbox label="Board Management" v-model="isBoard" hide-details="" v-if="totalAge(birthDate.value) >= 144" />
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <v-row cols="12">
                                                    <!-- Traveler Preference Information -->
                                                </v-row>
                                                <v-row id="ProfileDetail">
                                                    <v-col cols="12"
                                                        v-if="EX_PROFILE.EarnMiles != null && EX_PROFILE.EarnMiles.length > 0">
                                                        <span>
                                                            R.O.P
                                                        </span>
                                                        <span style="float: right;">
                                                            Thai Airways
                                                        </span>
                                                        <v-text-field outlined background-color="white" dense
                                                            v-model="EX_PROFILE.EarnMiles.filter(x => x.Name === 'Thai Airways' && x.IsActive === 1)[0].MemberID">
                                                        </v-text-field>
                                                        <span>
                                                            Nok Fanclub
                                                        </span>
                                                        <span style="float: right;">
                                                            Nok Air
                                                        </span>
                                                        <v-text-field outlined dense background-color="white"
                                                            v-model="EX_PROFILE.EarnMiles.filter(x => x.Name === 'Nok Air' && x.IsActive === 1)[0].MemberID">
                                                        </v-text-field>
                                                        <span>
                                                            Big Membership
                                                        </span>
                                                        <span style="float: right;">
                                                            Air Asia
                                                        </span>
                                                        <v-text-field outlined dense background-color="white"
                                                            v-model="EX_PROFILE.EarnMiles.filter(x => x.Name === 'Air Asia' && x.IsActive === 1)[0].MemberID">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12"
                                                        v-if="EX_PROFILE.EarnMiles != null && EX_PROFILE.EarnMiles.length > 0">
                                                        <v-card-title>
                                                            Other Miles
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <table style="width: 100%" aria-label="">
                                                                <tr hidden>
                                                                    <th></th>
                                                                </tr>
                                                                <tr v-for="(itemOM, index) in EX_PROFILE.EarnMiles.filter(x => x.IsActive !== 1)"
                                                                    :key="index">
                                                                    <td style="width: 50px;">
                                                                        No. {{ (index + 1) }}
                                                                    </td>
                                                                    <td>
                                                                        Airline
                                                                        <v-autocomplete v-model="itemOM.Name"
                                                                            @change="SetAirlineByKey(itemOM.Name)"
                                                                            @input.native="GetAirlineByKey($event.srcElement.value)"
                                                                            :items="Airline" background-color="white"
                                                                            ref="Airline" :rules="rules.Airline"
                                                                            item-text="Name" item-value="Name" dense
                                                                            outlined></v-autocomplete>
                                                                    </td>
                                                                    <td>
                                                                        Member ID
                                                                        <v-text-field outlined dense
                                                                            v-model="itemOM.MemberID" ref="OthMiles"
                                                                            :rules="rules.OthMiles"
                                                                            background-color="white">
                                                                        </v-text-field>
                                                                    </td>
                                                                    <td style="width: 30px;">
                                                                        <v-btn color="red" x-small fab
                                                                            @click="RemoveOthmiles(itemOM)">
                                                                            <v-img
                                                                                :src="require('../assets/icons/Cancel.svg')"
                                                                                width="2" max-width="12">
                                                                            </v-img>
                                                                        </v-btn>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="4">
                                                                        <div class="h-space"></div>
                                                                        <v-btn depressed @click="AddOthMiles()">
                                                                            <v-img class="shrink mr-2"
                                                                                :src="require('../assets/icons/Add.svg')"
                                                                                width="22" />
                                                                            Add Mileage
                                                                        </v-btn>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </v-card-text>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-card class="card">
                                                            <v-card-text>
                                                                <v-btn depressed @click="InputFile">
                                                                    <v-img class="shrink mr-2"
                                                                        :src="require('../assets/icons/Add.svg')"
                                                                        width="22" />
                                                                    Upload File(s)
                                                                </v-btn>
                                                            </v-card-text>
                                                        </v-card>
                                                        <div class="h-space"></div>
                                                        <input type="file" counter multiple hide-details="" ref="input_file"
                                                            style="display: none" width="22" outlined show-size
                                                            background-color="white"
                                                            accept="image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, .pdf,
                                                            application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            @change="AddAttachFile($event)" />
                                                        <br />
                                                        <v-row>
                                                            <template v-if="files.length > 0">
                                                                <v-col cols="4" v-for="(fileitem, index) in files"
                                                                    :key="'n' + index">
                                                                    <v-card class="card">
                                                                        <v-card-text class="bgcard">
                                                                            <v-row>
                                                                                <v-col cols="2">
                                                                                    <v-icon
                                                                                        v-if="fileitem.name.split('.')
                                                                                        [fileitem.name.split('.').length - 1] == 'xlsx' || fileitem.name.split('.')
                                                                                        [fileitem.name.split('.').length - 1] == 'xls'"
                                                                                        class="mt-3"
                                                                                        color="success">mdi-file-excel</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.name.split('.')
                                                                                        [fileitem.name.split('.').length - 1] == 'png'"
                                                                                        class="mt-3"
                                                                                        color="error">mdi-file-png-box</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.name.split('.')
                                                                                        [fileitem.name.split('.').length - 1] == 'pdf'"
                                                                                        class="mt-3"
                                                                                        color="error">mdi-file-pdf-box</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.name.split('.')
                                                                                        [fileitem.name.split('.').length - 1] == 'jpg'"
                                                                                        class="mt-3"
                                                                                        color="error">mdi-file-jpg-box</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.name.split('.')
                                                                                        [fileitem.name.split('.').length - 1] == 'zip'"
                                                                                        class="mt-3"
                                                                                        color="#EAC282">mdi-zip-box</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.name.split('.')
                                                                                        [fileitem.name.split('.').length - 1] == 'rar'"
                                                                                        class="mt-3"
                                                                                        color="#EAC282">mdi-bookmark-box-multiple</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.name.split('.')
                                                                                        [fileitem.name.split('.').length - 1] == 'docx' ||
                                                                                            fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'doc'"
                                                                                        class="mt-3"
                                                                                        color="primary">mdi-file-word</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.name.split('.')
                                                                                        [fileitem.name.split('.').length - 1] == 'xml'"
                                                                                        class="mt-3"
                                                                                        color="primary">mdi-xml</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.name.split('.')
                                                                                        [fileitem.name.split('.').length - 1] == 'txt'"
                                                                                        class="mt-3"
                                                                                        color="#E3751C">mdi-file-document</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.name.split('.')
                                                                                        [fileitem.name.split('.').length - 1] == 'svg'"
                                                                                        class="mt-3"
                                                                                        color="#E3751C">mdi-svg</v-icon>
                                                                                    <v-icon v-else
                                                                                        class="mt-3">mdi-file-outline</v-icon>
                                                                                </v-col>
                                                                                <v-col cols="10" style="height: 55px;">
                                                                                    <v-icon
                                                                                        style="float: right; cursor: pointer;"
                                                                                        @click="removeFiles(fileitem, 'NotSave')">mdi-close</v-icon>
                                                                                    <div class="mt-3">
                                                                                        {{ fileitem.name.length > 15 ?
                                                                                            fileitem.name.split('.')[0].slice(0,
                                                                                                10) + '...' +
                                                                                            fileitem.name.split('.')[fileitem.name.split('.').length
                                                                                            - 1] :
                                                                                            fileitem.name }}
                                                                                    </div>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                            </template>
                                                            <template v-if="Getfiles.length > 0">
                                                                <v-col cols="4" v-for="(fileitem, index1) in Getfiles"
                                                                    :key="'o' + index1">
                                                                    <v-card class="card">
                                                                        <v-card-text class="bgcard">
                                                                            <v-row>
                                                                                <v-col cols="2" @click="openFile(fileitem)"
                                                                                    style="cursor: pointer;">
                                                                                    <v-icon
                                                                                        v-if="fileitem.FileName.split('.')
                                                                                        [fileitem.FileName.split('.').length - 1] === 'xlsx' ||
                                                                                            fileitem.FileName.split('.')
                                                                                            [fileitem.FileName.split('.').length - 1] === 'xls'"
                                                                                        class="mt-3"
                                                                                        color="success">mdi-file-excel</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.FileName.split('.')
                                                                                        [fileitem.FileName.split('.').length - 1] === 'png'"
                                                                                        class="mt-3"
                                                                                        color="error">mdi-file-png-box</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.FileName.split('.')
                                                                                        [fileitem.FileName.split('.').length - 1] === 'pdf'"
                                                                                        class="mt-3"
                                                                                        color="error">mdi-file-pdf-box</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.FileName.split('.')
                                                                                        [fileitem.FileName.split('.').length - 1] === 'jpg'"
                                                                                        class="mt-3" color="error">
                                                                                        mdi-file-jpg-box</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.FileName.split('.')
                                                                                        [fileitem.FileName.split('.').length - 1] === 'zip'"
                                                                                        class="mt-3"
                                                                                        color="#EAC282">mdi-zip-box</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.FileName.split('.')
                                                                                        [fileitem.FileName.split('.').length - 1] === 'rar'"
                                                                                        class="mt-3" color="#EAC282">
                                                                                        mdi-bookmark-box-multiple</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.FileName.split('.')
                                                                                        [fileitem.FileName.split('.').length - 1] === 'docx' ||
                                                                                            fileitem.FileName.split('.')
                                                                                            [fileitem.FileName.split('.').length - 1] === 'doc'"
                                                                                        class="mt-3" color="primary">
                                                                                        mdi-file-word</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.FileName.split('.')
                                                                                        [fileitem.FileName.split('.').length - 1] === 'xml'"
                                                                                        class="mt-3"
                                                                                        color="primary">mdi-xml</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.FileName.split('.')
                                                                                        [fileitem.FileName.split('.').length - 1] === 'txt'"
                                                                                        class="mt-3" color="#E3751C">
                                                                                        mdi-file-document</v-icon>
                                                                                    <v-icon
                                                                                        v-else-if="fileitem.FileName.split('.')
                                                                                        [fileitem.FileName.split('.').length - 1] === 'svg'"
                                                                                        class="mt-3"
                                                                                        color="#E3751C">mdi-svg</v-icon>
                                                                                    <v-icon v-else
                                                                                        class="mt-3">mdi-file-outline</v-icon>
                                                                                </v-col>
                                                                                <v-col cols="10" style="height: 55px;">
                                                                                    <v-icon
                                                                                        style="float: right; cursor: pointer;"
                                                                                        @click="DialogRemove = true,
                                                                                            remove('ExternalProfile', 'alert', fileitem)">mdi-close</v-icon>
                                                                                    <div class="mt-3"
                                                                                        @click="openFile(fileitem)"
                                                                                        style="cursor: pointer;">
                                                                                        {{
                                                                                            fileitem.FilePath.length > 15 ?
                                                                                            fileitem.FilePath.split('/')
                                                                                            [fileitem.FilePath.split('/').length
                                                                                                - 1]
                                                                                                .slice(0, 10) + '...' +
                                                                                            fileitem.FileName.split('.')[1] :
                                                                                            fileitem.FileName
                                                                                        }}
                                                                                    </div>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                            </template>
                                                        </v-row>
                                                    </v-col>
                                                    <template v-if="EX_PROFILE.EX_ID">
                                                        <v-col md="6">
                                                            Preferred Seat
                                                            <br />
                                                            <v-select background-color="white" outlined dense
                                                                v-model="EX_PROFILE.PREFERRED_SEAT" item-value="Code"
                                                                item-text="Description" :items="dataSeat"></v-select>
                                                        </v-col>
                                                        <v-col md="6">
                                                            Special Meal
                                                            <br />
                                                            <v-select background-color="white" outlined dense
                                                                v-model="EX_PROFILE.SPECIAL_MEAL" item-value="MealCode"
                                                                item-text="Description" :items="dataSpecialMeal"></v-select>
                                                        </v-col>
                                                    </template>
                                                </v-row>
                                            </v-card-text>
                                            <v-row v-if="EX_PROFILE.EX_ID">
                                                <v-col cols="6">
                                                    <v-card elevation="0" color="#F8F9FA">
                                                        <v-card-title>
                                                            Passport
                                                            <v-spacer></v-spacer>
                                                            <v-btn style="float: right"
                                                                @click="ShowDialog('Passport', 'add', null)">
                                                                <v-img class="shrink mr-2"
                                                                    :src="require('../assets/icons/Add.svg')" width="22" />
                                                                Add Passport
                                                            </v-btn>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-card elevation="0">
                                                                <v-card-text>
                                                                    <template
                                                                        v-if="EX_PROFILE.Passport != null && EX_PROFILE.Passport.length > 0">
                                                                        <template
                                                                            v-if="EX_PROFILE.Passport.length === 1 && EX_PROFILE.Passport[0].PassportType === 'Required'">
                                                                            <h3 style="text-align: center;">Empty Data</h3>
                                                                        </template>
                                                                        <v-row v-else>
                                                                            <template
                                                                                v-for="(itemPassport, index) in EX_PROFILE.Passport">
                                                                                <v-col md="12" sm="12"
                                                                                    v-if="itemPassport.PassportType !== 'Required'"
                                                                                    :key="index">
                                                                                    <v-card>
                                                                                        <v-card-title>
                                                                                            <span style="font-size: 16px">
                                                                                                {{ itemPassport.PassportType
                                                                                                }}
                                                                                            </span>
                                                                                            <v-spacer></v-spacer>
                                                                                            <v-btn icon small
                                                                                                @click="ShowDialog('Passport', 'edit', itemPassport)">
                                                                                                <v-img
                                                                                                    :src="require('@/assets/icons/EditDetail.svg')">
                                                                                                </v-img>
                                                                                            </v-btn>
                                                                                            &nbsp;
                                                                                            <v-btn icon small
                                                                                                @click="DialogRemove = true, remove('Passport', 'alert', itemPassport)">
                                                                                                <v-img
                                                                                                    :src="require('@/assets/icons/Delete.svg')">
                                                                                                </v-img>
                                                                                            </v-btn>
                                                                                        </v-card-title>
                                                                                        <v-card-text>
                                                                                            <v-divider></v-divider>
                                                                                        </v-card-text>
                                                                                        <v-card-text>
                                                                                            <v-row>
                                                                                                <v-col sm="6" md="6">
                                                                                                    Passport No.
                                                                                                    <br />
                                                                                                    <strong>
                                                                                                        {{
                                                                                                            itemPassport.PassportNo
                                                                                                        }}
                                                                                                    </strong>
                                                                                                </v-col>
                                                                                                <v-col sm="6" md="6">
                                                                                                    Request New Passport
                                                                                                    <br />
                                                                                                    <strong>
                                                                                                        {{
                                                                                                            itemPassport.RequestNewPassport
                                                                                                        }}
                                                                                                    </strong>
                                                                                                </v-col>
                                                                                                <v-col sm="6" md="6">
                                                                                                    Expiry Date
                                                                                                    <br />
                                                                                                    <strong>
                                                                                                        {{
                                                                                                            formatDate(itemPassport.ExpiryDate)
                                                                                                        }}
                                                                                                    </strong>
                                                                                                </v-col>
                                                                                                <v-col sm="6" md="6">
                                                                                                    Country
                                                                                                    <br />
                                                                                                    <strong>
                                                                                                        {{
                                                                                                            itemPassport.Country
                                                                                                        }}
                                                                                                    </strong>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </v-card-text>
                                                                                    </v-card>
                                                                                </v-col>
                                                                            </template>
                                                                        </v-row>
                                                                    </template>
                                                                    <template v-else>
                                                                        <h3 style="text-align: center;">Empty Data</h3>
                                                                    </template>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-card elevation="0" color="#F8F9FA">
                                                        <v-card-title>
                                                            Visa
                                                            <v-spacer></v-spacer>
                                                            <v-btn @click="ShowDialog('Visa', 'add', null)"
                                                                style="float: right">
                                                                <v-img class="shrink mr-2"
                                                                    :src="require('@/assets/icons/Add.svg')" width="22" />
                                                                Add Visa
                                                            </v-btn>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-card elevation="0">
                                                                <v-card-text>
                                                                    <template
                                                                        v-if="EX_PROFILE.Visa != null && EX_PROFILE.Visa.length > 0">
                                                                        <template
                                                                            v-if="EX_PROFILE.Visa.length === 1 && EX_PROFILE.Visa[0].VisaType === 'Required'">
                                                                            <h3 style="text-align: center;">Empty Data</h3>
                                                                        </template>
                                                                        <v-row v-else>
                                                                            <template
                                                                                v-for="(itemVisa, index) in EX_PROFILE.Visa">
                                                                                <v-col md="12" sm="12"
                                                                                    v-if="itemVisa.VisaType !== 'Required'"
                                                                                    :key="index">
                                                                                    <v-card>
                                                                                        <v-card-title>
                                                                                            <span style="font-size: 16px">
                                                                                                {{ itemVisa.VisaType }}
                                                                                            </span>
                                                                                            <v-spacer></v-spacer>
                                                                                            <v-btn icon small
                                                                                                @click="ShowDialog('Visa', 'edit', itemVisa)">
                                                                                                <v-img
                                                                                                    :src="require('@/assets/icons/EditDetail.svg')">
                                                                                                </v-img>
                                                                                            </v-btn>
                                                                                            &nbsp;
                                                                                            <v-btn icon small
                                                                                                @click="DialogRemove = true, remove('Visa', 'alert', itemVisa)">
                                                                                                <v-img
                                                                                                    :src="require('@/assets/icons/Delete.svg')">
                                                                                                </v-img>
                                                                                            </v-btn>
                                                                                        </v-card-title>
                                                                                        <v-card-text>
                                                                                            <v-divider></v-divider>
                                                                                        </v-card-text>
                                                                                        <v-card-text>
                                                                                            <v-row>
                                                                                                <v-col sm="6" md="6">
                                                                                                    Passport No.
                                                                                                    <br />
                                                                                                    <strong>
                                                                                                        {{
                                                                                                            itemVisa.VisaNo
                                                                                                        }}
                                                                                                    </strong>
                                                                                                </v-col>
                                                                                                <v-col sm="6" md="6">
                                                                                                    Passport Type
                                                                                                    <br />
                                                                                                    <strong>
                                                                                                        {{
                                                                                                            itemVisa.Country
                                                                                                        }}
                                                                                                    </strong>
                                                                                                </v-col>
                                                                                                <v-col sm="6" md="6">
                                                                                                    Issue Date
                                                                                                    <br />
                                                                                                    <strong>
                                                                                                        {{
                                                                                                            formatDate(itemVisa.IssueDate)
                                                                                                        }}
                                                                                                    </strong>
                                                                                                </v-col>
                                                                                                <v-col sm="6" md="6">
                                                                                                    Expiry Date
                                                                                                    <br />
                                                                                                    <strong>
                                                                                                        {{
                                                                                                            formatDate(itemVisa.ExpiryDate)
                                                                                                        }}
                                                                                                    </strong>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </v-card-text>
                                                                                    </v-card>
                                                                                </v-col>
                                                                            </template>
                                                                        </v-row>
                                                                    </template>
                                                                    <template v-else>
                                                                        <h3 style="text-align: center;">Empty Data</h3>
                                                                    </template>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row>
                                            <v-col class="text-center">
                                                <v-btn outlined color="primary" class="mr-1"
                                                    @click="closeAddExternal()">Close</v-btn>
                                                <v-btn color="primary" :loading="reloadBtn" class="ml-1"
                                                    @click="addExternal()">{{ EX_PROFILE.EX_ID ? 'Save' :
                                                        'Continue' }}</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-card>
                        </v-dialog>
                        <br>
                        <v-card elevation="0">
                            <v-data-table :headers="headersExternal" :items="itemExternal" class="elevation-1" item-key="No"
                                @click:row="rowClicked" show-select v-model="selectedItem" checkbox-color="primary"
                                mobile-breakpoint style="white-space: nowrap" hide-default-footer height="40vh"
                                @page-count="pageCount = $event" :page.sync="page" :items-per-page="itemPage"
                                @load="onPagination($event)">
                            </v-data-table>
                        </v-card>
                        <v-row class="mt-3 hidden-xs-only" no-gutters>
                            <v-col class="d-flex" cols="12">
                                <span class="mr-3 mt-3">Show</span>
                                <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                    v-model="itemPage" background-color="white" class="select-data-table shrink"></v-select>
                                <span class="ml-3 mt-3">items per page</span>
                                <v-pagination v-model="page" :length="pageCount" :total-visible="5"
                                    class="pagination-custom" style="width: 60%;"></v-pagination>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                            <v-col class="d-flex" cols="12">
                                <span class="mr-3 mt-3">Show</span>
                                <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                    v-model="itemPage" background-color="white" class="select-data-table shrink"></v-select>
                                <span class="ml-3 mt-3">items per page</span>
                            </v-col>
                            <v-col>
                                <v-pagination v-model="page" :length="pageCount" :total-visible="5"
                                    class="pagination-custom">
                                </v-pagination>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="DialogPassport" width="800">
            <Passport @PassportUpdate="EX_PROFILE.Passport = $event" :valPass="senddata" :_ID="EX_PROFILE.EX_ID"
                :key="componentKey" @DialogPassport="DialogPassport = $event" />
        </v-dialog>
        <v-dialog v-model="DialogVisa" width="800">
            <Visa @VisaUpdate="EX_PROFILE.Visa = $event" :_ID="EX_PROFILE.EX_ID" @DialogVisa="DialogVisa = $event"
                :valVisa="VisaData" :key="componentKey"></Visa>
        </v-dialog>
        <v-dialog v-model="DialogRemove" width="500">
            <v-card>
                <v-card-title>
                    Remove
                </v-card-title>
                <v-card-text>
                    would you like to remove your {{ typeFocus }} ?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small text @click="DialogRemove = false">Cancel</v-btn>
                    <v-btn small color="error" @click="remove(typeFocus, 'remove', null)">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import axios from 'axios';
import Visa from '@/components/Visa.vue'
import Passport from '@/components/Passport.vue'
import moment from 'moment'
export default {
    components: {
        Visa,
        Passport
    },
    data() {
        return {
            EX_IDs: "",
            EX_FNAMEs: "",
            EX_LNAMEs: "",
            EX_EMAILs: "",
            searchbtnLoading : false,
            minDate: moment().format('YYYY-MM-DD'),
            isBoard: false,
            MOBILE_CODE: null,
            filesize: null,
            Airline: [],
            sizes: ['Bytes', 'KB', 'MB'],
            files: [],
            Getfiles: [],
            file: null,
            dataSpecialMeal: [],
            Internals: [],
            rules: {},
            countryCode: ["+66", "22"],
            reloadBtn: false,
            formatDateString: 'YYYY-MM-DD',
            EX_PROFILE: {
                CBY: null,
                TITLE: "",
                EX_ID: "",
                EX_FNAME: "",
                CBYNAME: "",
                EX_LNAME: "",
                EX_PHONE_NO: "",
                EX_EMAIL: "",
                EX_OFFICE_PHONE: null,
                PREFERRED_SEAT: '',
                SPECIAL_MEAL: '',
                Company: null,
                Birthday: null,
                Visa: null,
                Passport: null,
                EarnMiles: [],
            },
            titleName:[
                {code: 'mr', name: 'Mr.'},
                {code: 'Mrs', name: 'Mrs.'},
                {code: 'miss', name: 'Miss.'}
            ],
            dataSeat: [
                { Code: "", Description: "No Preferred" },
                { Code: "A", Description: "Aisle seat" },
                { Code: "W", Description: "Window seat" },
            ],
            SpecialMeal: "",
            Seat: "",
            DialogPassport: false,
            DialogVisa: false,
            senddata: null,
            VisaData: null,
            dialogAddExternal: false,
            dataUpdate: moment().format("DD MMM YYYY hh:mm A"),
            itemsPerPage: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                // { text: "15", value: 15 },
                // { text: "25", value: 25 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemPage: 5,
            page: 1,
            pageCount: 2,
            headersExternal: [
                { text: 'No.', value: 'No', sortable: false },
                { text: 'External ID', value: 'EX_ID', sortable: false },
                { text: 'First Name', value: 'EX_FNAME', sortable: false },
                { text: 'Last Name', value: 'EX_LNAME', sortable: false },
                { text: 'Phone No.', value: 'EX_PHONE_NO', sortable: false },
                { text: 'E-mail', value: 'EX_EMAIL', sortable: false },
                { text: 'Internal Name', value: 'CBYNAME', sortable: false }
            ],
            itemExternal: [],
            selectedItem: [],
            componentKey: 0,
            typeFocus: null,
            PassportFocus: null,
            VisaFocus: null,
            DialogRemove: false,
            birthDate: {
                menu: false,
                value: null
            }
        }
    },
    watch: {
        // async 'EX_PROFILE.CBY'() {
        //     if (this.EX_PROFILE.CBY !== null) {
        //         this.EX_PROFILE.CBYNAME = await this.getInternalID(this.EX_PROFILE.CBY)
        //     }
        // },

        async 'birthDate.value'() {
            if (this.formatDate.value) {
                this.formatDate(this.formatDate.value)
            }
        }
    },
    methods: {
        selectCountryCode() {
        },
        reload() {
            location.reload()
        },
        validateNumberText(val) {
            return val.replace(/\D/g, '');
        },
        InputFile() {
            this.$refs.input_file.click()
        },
        GetFile(val) {
            this.Getfiles = []
            axios.post(`${this.$store.state.API_PATH}FileUpload/GetFile?ID=${this.EX_PROFILE.EX_ID}|${val}`).then((res) => {
                this.Getfiles = res.data.Result;
            });
        },
        AddAttachFile(event) {
            this.files.push(...event.target.files)
        },
        openFile(val) {
            window.open(val.FilePath)
        },
        calSize(bytes) {
            const k = 1024
            const sum = Math.floor(Math.log(bytes) / Math.log(k))
            this.filesize += parseFloat((bytes / Math.pow(k, sum)).toFixed(2))
        },
        getEarnMiles() {
            return axios.post(`${this.$store.state.API_PATH}EarnMiles/GetEarnMiles?val=${this.EX_PROFILE.EX_ID}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                return res.data.Result.length === 0 ? null : res.data.Result
            })
        },
        AddOthMiles() {
            this.EX_PROFILE.EarnMiles.push(
                {
                    no: this.EX_PROFILE.EarnMiles.length + 1,
                    EarnMileOID: null,
                    EMPLOYEE_ID: this.EX_PROFILE.EX_ID,
                    Airline: null,
                    MemberID: null
                }
            );
        },
        RemoveOthmiles(val) {
            if (this.EX_PROFILE.EarnMiles.length > 1) {
                if (val.EarnMileOID !== null) {
                    axios.post(`${this.$store.state.API_PATH}EarnMiles/RemoveEarnMiles?EarnMileOID=${val.EarnMileOID}&EMPLOYEE_ID=${this.EX_PROFILE.EX_ID}`, {}, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    }).then(res => {
                        if (res.data.Status) {
                            this.EX_PROFILE.EarnMiles = res.data.Result
                        }
                    })
                } else {
                    this.EX_PROFILE.EarnMiles = this.EX_PROFILE.EarnMiles.filter(x => x.no !== val.no)
                }
            }
        },
        SetAirlineByKey(val) {
            return this.Airline.filter(x => x.Name === val)
        },
        GetAirlineByKey(val) {
            axios.post(`${this.$store.state.API_PATH}EarnMiles/GetAirlineByKey?val=${val}&Language=en`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.Airline = res.data
                this.Airline.push(...this.EX_PROFILE.EarnMiles.filter(x => x.IsActive !== 1))

            })
        },
        totalAge(val) {
            const nowdate = moment().format(this.formatDateString);
            let monthsDiff = 0;
            if (val) {
                monthsDiff = Number(moment(nowdate).diff(moment(val), 'months'));
            }
            return monthsDiff;
        },
        searchExternal() {
            this.searchbtnLoading = true;
            axios.post(`${this.$store.state.API_PATH}ExternalTraveler/searchExternal`, {
                EX_ID: this.EX_IDs,
                EX_FNAME: this.EX_FNAMEs,
                EX_LNAME: this.EX_LNAMEs,
                EX_EMAIL: this.EX_EMAILs,
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}`}
            }).then(async (res) => {
                if (res.data.Status) {
                    console.log(res.data.Result);
                    this.itemExternal = res.data.Result
                    this.searchbtnLoading = false;
                } else {
                    this.searchbtnLoading = false;
                }
            }).catch(() => {
                this.searchbtnLoading = false;
                this.$store.state.LOADING = false
            })
        },
        async getInternalID(val) {
            await axios.post(`${this.$store.state.API_PATH}UserProfile/GetUserProfileBySearch?val=${val}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async (res) => {
                if (res.data.Status) {
                    this.Internals = await res.data.Result
                }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
            return this.Internals[0].FIRSTNAME

        },
        openDialog() {
            this.files = []
            this.Getfiles = []
            this.EX_PROFILE = {
                CBY: null,
                EX_ID: "",
                EX_FNAME: "",
                EX_LNAME: "",
                EX_PHONE_NO: "",
                EX_EMAIL: "",
                EX_OFFICE_PHONE: null,
                PREFERRED_SEAT: '',
                SPECIAL_MEAL: '',
                Company: null,
                Birthday: '',
                Visa: null,
                Passport: null,
                EarnMiles: null,
            }
            this.forceRerender()
            this.dialogAddExternal = true
        },
        getSpecialMeal() {
            axios.post(`${this.$store.state.API_PATH}SpecialMeal/GetSpecialMeal`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
                .then(res => {
                    if (res.data.Status) {
                        this.dataSpecialMeal = res.data.Result
                        this.dataSpecialMeal.unshift({ Description: "No Preferred", MealCode: "" })
                    }
                })
                .catch(err => err)
        },
        remove(type, processing, val) {
            console.log(type, processing, val)
            this.typeFocus = type
            if (type === 'Passport') {
                if (processing === 'alert') {
                    this.PassportFocus = val
                } else if (processing === 'remove') {
                    this.RemoveSave('Passport', this.PassportFocus)
                    this.DialogRemove = false
                }
            } else if (type === 'Visa') {
                if (processing === 'alert') {
                    this.VisaFocus = val
                } else if (processing === 'remove') {
                    this.RemoveSave('Visa', this.VisaFocus)
                    this.DialogRemove = false
                }
            } else if (type === 'ExternalProfile') {
                if (processing === 'alert') {
                    this.FileFocus = val
                } else if (processing === 'remove') {
                    this.removeFiles(this.FileFocus, 'Saved')
                    this.DialogRemove = false
                }
            }
        },
        RemoveSave(type, val) {
            if (type === 'Passport') {
                axios.post(`${this.$store.state.API_PATH}Passport/RemovePassport`, val, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    if (res.data != null && res.data.Status) {
                        this.EX_PROFILE.Passport = res.data.Result
                    }
                })
            } else if (type === 'Visa') {
                axios.post(`${this.$store.state.API_PATH}Visa/RemoveVisa`, val, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    if (res.data != null && res.data.Status) {
                        this.EX_PROFILE.Visa = res.data.Result
                    }
                })
            }
        },
        removeFiles(exVal, exKey) {
            console.log(exVal, exKey);
            if (exKey === 'NotSave') {
                this.files = this.files.filter(x => x.name !== exVal.name)
                this.filesize -= exVal.size
            } else if (exKey === 'Saved') {
                const formData = new FormData();
                for (let i = 0; i < this.files.length; i++) {
                    formData.append('ExternalTravelerProfile', this.files[0])
                }

                const files = formData
                axios.post(`${this.$store.state.API_PATH}FileUpload/RemoveFile?FileOID=` +
                    `${this.EX_PROFILE.EX_ID}|${exVal.FilePath.split('?')[0]}|${exVal.FileOID}|ExternalTravelerProfile|${exVal.FileName}`, files,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(res => {
                        if (res.data) {
                            this.Getfiles = this.Getfiles.filter(x => x.FileOID !== exVal.FileOID)
                        }
                    })
            }
        },
        formatDate(val) {
            let result = ''
            const formatdate = 'DD MMM YYYY'
            if (val !== null && val !== '' && val !== 'Invalid date') {
                result = moment(val).format(formatdate)
                this.EX_PROFILE.Birthday = moment(result).format(formatdate)
            } else {
                this.birthDate.value = moment().format(formatdate)
                result = moment().format(formatdate)
            }
            return result
        },
        forceRerender() {
            this.componentKey += 1;
        },
        ShowDialog(type, val, pItem) {
            this.forceRerender()
            if (type === "Passport") {
                if (val === 'add') {
                    this.DialogPassport = true
                    this.senddata = null
                } else if (val === 'edit') {
                    this.DialogPassport = true
                    this.senddata = pItem
                }
            } else if (type === "Visa") {
                if (val === 'add') {
                    this.DialogVisa = true
                    this.VisaData = null
                } else if (val === 'edit') {
                    this.DialogVisa = true
                    this.VisaData = pItem
                }
            }
        },
        async checkvalidate() {
            let result = false
            const age = this.totalAge(this.birthDate.value)
            if (!this.$refs.TITLE.validate()) {
                this.$refs.TITLE.validate(true)
                this.$refs.TITLE.focus()
                result = true
            }
            if (!this.$refs.EX_FNAME.validate()) {
                this.$refs.EX_FNAME.validate(true)
                this.$refs.EX_FNAME.focus()
                result = true
            }
            if (!this.$refs.EX_LNAME.validate()) {
                this.$refs.EX_LNAME.validate(true)
                this.$refs.EX_LNAME.focus()
                result = true
            }
            if (age > 144) {
                if (!this.$refs.EX_PHONE_NO.validate()) {
                    this.$refs.EX_PHONE_NO.focus()
                    this.$refs.EX_PHONE_NO.validate(true)
                    result = true
                }
            }

            if (!this.$refs.Birthday.validate()) {
                this.$refs.Birthday.focus()
                this.$refs.Birthday.validate(true)
                result = true
            }
            if(age < 144){
                if (!this.$refs.CBY.validate()) {
                    this.$refs.CBY.focus()
                    this.$refs.CBY.validate(true)
                    result = true
                }
            }
            if (this.$refs.OthMiles) {
                await this.$refs.OthMiles.forEach(async OthMile => {
                    if (await !OthMile.validate()) {
                        OthMile.validate(true)
                        OthMile.focus()
                        result = true
                    }
                })
            }

            if (this.$refs.Airline) {
                await this.$refs.Airline.forEach(async airline => {
                    if (await !airline.validate()) {
                        airline.validate(true)
                        airline.focus()
                        result = true
                    }
                })
            }

            return result
        },
        async addExternal() {
            const formData = new FormData()
            if (!await this.checkvalidate()) {
                this.reloadBtn = true
                for (let i = 0; i < this.files.length; i++) {
                    formData.append('ExternalTravelerProfile', this.files[i])
                }
                const app = this
                this.dialogAddExternal = this.EX_PROFILE.EX_ID != null ? true : false
                const PROFILE = JSON.parse(localStorage.PROFILE)
                let req = {
                    EX_ID: this.EX_PROFILE.EX_ID,
                    TITLE: this.EX_PROFILE.TITLE,
                    EX_FNAME: this.EX_PROFILE.EX_FNAME,
                    EX_LNAME: this.EX_PROFILE.EX_LNAME,
                    EX_PHONE_NO: this.EX_PROFILE.EX_PHONE_NO,
                    EX_EMAIL: this.EX_PROFILE.EX_EMAIL,
                    EX_OFFICE_PHONE: this.EX_PROFILE.EX_OFFICE_PHONE,
                    Visa: null,
                    Passport: null,
                    EarnMiles: this.EX_PROFILE.EarnMiles,
                    SPECIAL_MEAL: this.EX_PROFILE.SPECIAL_MEAL,
                    Company: this.EX_PROFILE.Company,
                    Birthday: moment(this.EX_PROFILE.Birthday).format(this.formatDateString),
                    PREFERRED_SEAT: this.EX_PROFILE.PREFERRED_SEAT,
                    CBYNAME: this.EX_PROFILE.CBY?.split(" (")[0] ? this.EX_PROFILE.CBY?.split(" (")[0] : PROFILE.EMPLOYEE_ID,
                    CBY: this.EX_PROFILE.CBY?.split(" (")[1].split(")")[0] ? this.EX_PROFILE.CBY?.split(" (")[1].split(")")[0] : PROFILE.EMPLOYEE_ID,
                    CREATED_BY : PROFILE.EMPLOYEE_ID,
                    ParentsOfChild: this.EX_PROFILE.ParentsOfChild ? 1 : 0,
                    IsBoard: this.isBoard ? 1 : 0,
                }
                if (this.totalAge(this.EX_PROFILE.Birthday) >= 144) {
                    req.ParentsOfChild = 0
                }
                if (this.totalAge(this.EX_PROFILE.Birthday) < 144) {
                    req.IsBoard = 0
                    req.ParentsOfChild = 1
                }
                const files = formData
                axios.post(`${this.$store.state.API_PATH}FileUpload/Upload?ID=` + this.EX_PROFILE.EX_ID, files,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                axios.post(this.$store.state.API_PATH + 'ExternalTraveler/InsertExternalTraveler', req, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    if (res.data.Status) {
                        this.EX_PROFILE = res.data.Result
                        this.EX_PROFILE.CBY = `${res.data.Result.CBYNAME} (${res.data.Result.CBY})`
                        this.CREATED_BY = PROFILE.EMPLOYEE_ID
                        if (req.EX_ID !== null && req.EX_ID !== '') {
                            app.files = []
                            app.dialogAddExternal = false
                            Swal.fire({
                                icon: 'success',
                                text: 'Success',
                                confirmButtonColor: '#00AEEF'
                            }).then(() => {
                                app.closeAddExternal()
                                app.getExternalTraveler()
                            }, () => { })
                        }
                        this.reloadBtn = false
                        location.href = '#ProfileDetail'
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: res.data.ErrorMessage,
                            confirmButtonColor: '#00AEEF'
                        }).then(() => {
                            app.dialogAddExternal = true
                        }, () => { })
                    }
                    req = null
                })
                .catch(() => {
                    this.$store.state.LOADING = false
                    this.dialogAddExternal = true
                })
            } else {
                this.reloadBtn = false
            }
        },
        deleteExternal() {
            if (this.selectedItem.length > 0) {
                let EX_ID = ""
                const app = this
                this.selectedItem.forEach(item => {
                    EX_ID += EX_ID !== "" ? `,${item.EX_ID}` : `${item.EX_ID}`
                })
                Swal.fire({
                    icon: 'warning',
                    text: 'would you like to to delete?',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    confirmButtonColor: '#00AEEF'

                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.state.LOADING = true
                        const PROFILE = JSON.parse(localStorage.PROFILE)
                        axios.post(this.$store.state.API_PATH + 'ExternalTraveler/DeleteExternalTraveler', {
                            EX_ID: EX_ID,
                            CREATED_BY: PROFILE.EMPLOYEE_ID,
                        }, {
                            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        })
                            .then(res => {
                                this.$store.state.LOADING = false
                                if (res.data.Status) {
                                    this.selectedItem = []
                                    Swal.fire({
                                        icon: 'success',
                                        text: 'Success',
                                        confirmButtonColor: '#00AEEF'
                                    }).then(() => {
                                        app.getExternalTraveler()
                                    }, () => { })
                                }
                                else {
                                    Swal.fire({
                                        icon: 'error',
                                        text: res.data.ErrorMessage,
                                        confirmButtonColor: '#00AEEF'
                                    }).then(() => {
                                    }, () => { })
                                }
                            })
                            .catch(() => {
                                this.$store.state.LOADING = false
                            })
                    }
                })
            }
        },
        getExternalTraveler() {
            this.itemExternal = []
            this.$store.state.LOADING = true
            axios.post(this.$store.state.API_PATH + 'ExternalTraveler/GetExternalTraveler', {
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
                .then(res => {
                    this.$store.state.LOADING = false
                    if (res.data.Status) {
                        let n = 1
                        const menuConfigData = localStorage.getItem('menuConfig');
                        this.$store.state.menuConfig = JSON.parse(menuConfigData);
                        console.log(res.data.Result);
                        res.data.Result.forEach(el => {
                            el.PREFERRED_SEAT = el.PREFERRED_SEAT ?? ''
                            el.SPECIAL_MEAL = el.SPECIAL_MEAL ?? ''
                            el.Birthday = moment(el.Birthday).format(this.formatDateString)
                            this.itemExternal.push({ ...el, No: n })
                            n++
                        })
                    }
                    // else {
                    //     Swal.fire({
                    //         icon: 'error',
                    //         text: res.data.ErrorMessage,
                    //         confirmButtonColor: '#00AEEF'
                    //     }).then(() => {

                    //     })
                    // }
                })
                .catch(() => {
                    this.$store.state.LOADING = false
                })
        },
        closeAddExternal() {
            this.TITLE = ""
            this.birthDate.value = ""
            this.EX_FNAME = ""
            this.EX_LNAME = ""
            this.EX_PHONE_NO = ""
            this.EX_EMAIL = ""
            this.EX_IDs = ""
            this.EX_FNAMEs = ""
            this.EX_LNAMEs = ""
            this.EX_EMAILs = ""
            this.dialogAddExternal = false
            this.isBoard = false
        },
        rowClicked(item) {
            this.getInternalID(item.CBY)
            if(this.$store.state.buttonPermit.menuConfig.Update){
                this.isBoard = item.IsBoard === 1 ? true : false;
                this.EX_PROFILE = item
                this.birthDate.value = item.EX_ID ? item.Birthday : ''
                this.GetFile('ExternalTravelerProfile')
                this.GetExternalTravelerByID(item.EX_ID).then(res => {
                    if (res.data.Status) {
                        this.EX_PROFILE = res.data.Result
                        this.EX_PROFILE.CBY = `${item.CBYNAME} (${item.CBY})`
                        this.Airline = this.EX_PROFILE.EarnMiles
                        if (this.EX_PROFILE.EX_PHONE_NO && this.EX_PROFILE.EX_PHONE_NO !== '-') {
                            if (this.EX_PROFILE.EX_PHONE_NO.split(" ")[1]) {
                                this.MOBILE_CODE = res.data.Result.EX_PHONE_NO.split(" ")[0]
                                this.EX_PROFILE.EX_PHONE_NO = res.data.Result.EX_PHONE_NO.split(" ")[1]
                            }
                        }
                        this.dialogAddExternal = true
                    }
                })
            }
        },
        GetExternalTravelerByID(ID) {
            return axios.post(`${this.$store.state.API_PATH}ExternalTraveler/GetExternalTravelerByID?val=${ID}`, {},
                {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
        }
    },
    async mounted() {
        const textRequire = "This field is required"
        // this.birthDate.value = this.EX_PROFILE.Birthday ?? this.EX_PROFILE.Birthday
        this.rules = {
            TITLE: [val => (val || '').length > 0 || textRequire],
            EX_FNAME: [val => (val || '').length > 0 || textRequire],
            EX_LNAME: [val => (val || '').length > 0 || textRequire],
            EX_PHONE_NO: [val => (val || '').length > 0 || textRequire],
            CBY: [val => (val || '').length > 0 || textRequire],
            Birthday: [val => (val || '').length > 0 || textRequire],
            OthMiles: [val => (val || '').length > 0 || textRequire],
            Airline: [val => (val || '').length > 0 || textRequire],
        }
        await this.getEarnMiles()
        await this.getSpecialMeal()
        await this.getExternalTraveler()
    }
}
</script>
<style scoped>* {
    scroll-behavior: smooth;
}

.font-W700 {
    font-weight: 700;
}

.font-W400 {
    font-weight: 400;
}

.btn-table {
    background: rgba(0, 174, 239, 0.1) !important;
}

.card-detail {
    background: #F8F9FA;
    border: 1px solid #EBEEF4;
    border-radius: 10px;
    box-shadow: 0px 2px 20px rgba(4, 49, 128, 0.1);

}</style>
