<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col class="h-space">
                <span class="font-W700" style="font-size: 1em;">{{ FilterFieldStatus ? FilterFieldStatus.ReportName : "" }} Report</span>
            </v-col>
        </v-row>
        <v-card class="card" elevation="0">
            <v-card-text>
                <div class="bgcard">
                    <v-select placeholder="Select Report Type" v-model="ReportSearch.Keywording"
                    :items="ReportsList" item-text="ReportName" item-value="ReportID" dense
                    hide-details background-color="white" outlined @change="getReport()"></v-select>
                </div>
            </v-card-text>
        </v-card>
        <br />
        <v-card class="card" v-if="ReportSearch.Keywording">
            <v-card-text>
                <div class="bgcard">
                    <v-row>
                        <template>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.EMPLOYEE_ID">
                                Employee ID
                                <v-text-field dense outlined background-color="white" hide-details
                                    v-model="ReportSearch.EMPLOYEE_ID"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.TRNo">
                                TR No
                                <v-text-field dense outlined background-color="white" hide-details
                                    v-model="ReportSearch.TRNo"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.Status">
                                Status
                                <v-select chips clearable multiple dense outlined v-model="ReportSearch.Status"
                                    background-color="white" hide-details :items="StatusList">
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index < 2">
                                            <span>{{ item[1] }}</span>
                                        </v-chip>
                                        <span v-if="index === 1" class="text-grey text-caption align-self-center">
                                        </span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.BudgetHolder">
                                Budget Holder
                                <v-autocomplete v-model="ReportSearch.BudgetHolder" clearable
                                    :items="BudgetHolderList" ref="BudgetHolder"
                                    item-text="BudgetHolder" item-value="BudgetHolder"
                                    dense hide-details="true" background-color="white" outlined>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.BusinessTravelType">
                                Business Travel Type
                                <v-select dense outlined clearable background-color="white" hide-details
                                :items="BusinessTravelTypeList" v-model="ReportSearch.BusinessTravelType"></v-select>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.BusinessTravelCase">
                                Business Travel Case
                                <v-select dense outlined clearable background-color="white"
                                v-model="ReportSearch.BusinessTravelCase" :items="BusinessTravelCaseList"
                                hide-details></v-select>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.Country">
                                Country
                                <v-autocomplete v-model="ReportSearch.Country" clearable
                                @change="getCitiesCode()" @input.native="getCountryCode($event.srcElement.value)" :items="country" ref="Country"
                                item-text="text" item-value="Code" dense hide-details="true" background-color="white"
                                outlined></v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.CityOrProvince">
                                City/Province
                                <v-autocomplete v-model="ReportSearch.CityOrProvince" background-color="white"
                                :items="CityAndProvinceList" hide-details outlined dense clearable item-text="text"
                                item-value="code" @input.native="getCitiesCode($event.srcElement.value)">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.PurposeofTravel">
                                PurposeofTravel
                                <v-autocomplete v-model="ReportSearch.PurposeofTravel" clearable
                                :items="PurposeofTravelList" ref="PurposeofTravelList"
                                item-text="PurDesc" item-value="PurDesc" dense hide-details="true" background-color="white"
                                outlined></v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.SubmittedDateFrom">
                                Submitted Date From
                                <v-menu
                                    v-model="ReportSearch.SubmittedDateForm.startDateMenu"
                                    :close-on-content-click="false"
                                    hide-details
                                    :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:[`activator`]="{ on, attrs }">
                                        <v-text-field
                                            background-color="white"
                                            hide-details ref="TRSubmittedDate"
                                            clearable :value="formatDatepicker(ReportSearch.SubmittedDateForm.startDate)"
                                            @click:clear="ReportSearch.SubmittedDateForm.startDate = ''"
                                            v-on="on" outlined dense readonly v-bind="attrs"
                                            >
                                            <v-icon slot="append" color="primary">
                                                mdi-calendar
                                            </v-icon>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="ReportSearch.SubmittedDateForm.startDate"
                                        @input="ReportSearch.SubmittedDateForm.startDateMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.SubmittedDateTo">
                                        Submitted Date To
                                        <v-menu v-model="ReportSearch.SubmittedDateForm.toDateMenu"
                                            :close-on-content-click="false" hide-details :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field background-color="white" hide-details ref="TRSubmittedDate"
                                                    clearable :value="formatDatepicker(ReportSearch.SubmittedDateForm.toDate)"
                                                    v-on="on" outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.SubmittedDateForm.ToDate = ''"
                                                    >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.SubmittedDateForm.toDate"
                                                @input="ReportSearch.SubmittedDateForm.toDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.BusinessDateStartDate">
                                        Business Date From
                                        <v-menu v-model="ReportSearch.BusinessDateFrom.startDateMenu"
                                            :close-on-content-click="false" hide-details :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field clearable background-color="white" hide-details
                                                    ref="BusinessStartDate"
                                                    :value="formatDatepicker(ReportSearch.BusinessDateFrom.startDate)"
                                                    v-on="on"
                                                    outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.BusinessDateFrom.startDate = ''"
                                                >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.BusinessDateFrom.startDate" @input="ReportSearch.BusinessDateFrom
                                                .startDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.BusinessDateToDate">
                                        To
                                        <v-menu v-model="ReportSearch.BusinessDateFrom.toDateMenu"
                                            :close-on-content-click="false" hide-details :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field clearable background-color="white" hide-details
                                                    ref="BusinessStartDate"
                                                    :value="formatDatepicker(ReportSearch.BusinessDateFrom.toDate)" v-on="on"
                                                    outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.BusinessDateFrom.toDate = ''"
                                                    >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.BusinessDateFrom.toDate"
                                                @input="ReportSearch.BusinessDateFrom.toDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.DepartureOnStartDate">
                                        Departure On
                                        <v-menu v-model="ReportSearch.DepartureOn.startDateMenu" :close-on-content-click="false"
                                            hide-details :nudge-right="40" transition="scale-transition" offset-y
                                            min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field clearable background-color="white" hide-details
                                                    ref="BusinessStartDate"
                                                    :value="formatDatepicker(ReportSearch.DepartureOn.startDate)" v-on="on"
                                                    outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.DepartureOn.startDate = ''"
                                                    >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.DepartureOn.startDate"
                                                @input="ReportSearch.DepartureOn.startDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.DepartureOnToDate">
                                        To
                                        <v-menu v-model="ReportSearch.DepartureOn.toDateMenu" :close-on-content-click="false"
                                            hide-details :nudge-right="40" transition="scale-transition" offset-y
                                            min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field clearable background-color="white" hide-details
                                                    ref="BusinessStartDate"
                                                    :value="formatDatepicker(ReportSearch.DepartureOn.toDate)" v-on="on"
                                                    outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.DepartureOn.toDate = ''"
                                                    >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.DepartureOn.toDate"
                                                @input="ReportSearch.DepartureOn.toDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.Project">
                                Project
                                <v-autocomplete v-model="ReportSearch.Project" background-color="white"
                                :items="ProjectList" hide-details outlined dense clearable item-text="ProjectName"
                                item-value="ProjectName">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.Company">
                                Company
                                <v-autocomplete v-model="ReportSearch.Company" background-color="white"
                                :items="ProjectList" hide-details outlined dense clearable item-text="CompanyName"
                                item-value="CompanyName">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.TicketStatus">
                                Ticket Status
                                <v-select dense outlined clearable background-color="white" hide-details
                                :items="TicketStatusList" v-model="ReportSearch.TicketStatus"></v-select>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.TravelerName">
                                Traveler Name
                                <v-autocomplete v-model="ReportSearch.TravelerName" background-color="white"
                                :items="TravelerNameList" hide-details outlined dense clearable item-text="Name"
                                item-value="EMPLOYEE_ID" @input.native="GetTravellerName($event.srcElement.value)">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.Agency">
                                Agency
                                <v-autocomplete v-model="ReportSearch.Agency" background-color="white"
                                :items="AgencyList" hide-details outlined dense clearable item-text="Name"
                                item-value="officeID">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.ApproveCode">
                                Approve Code
                                <v-text-field dense outlined background-color="white" hide-details
                                    v-model="ReportSearch.ApproveCode"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.InvoiceNo">
                                Invoice No.
                                <v-text-field dense outlined background-color="white" hide-details
                                    v-model="ReportSearch.InvoiceNo"></v-text-field>
                            </v-col>
                            <v-col  cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.IssueStartDate">
                                        Issue Date From
                                        <v-menu v-model="ReportSearch.IssueDateForm.startDateMenu"
                                            :close-on-content-click="false" hide-details :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field background-color="white" hide-details ref="TRSubmittedDate"
                                                    clearable :value="formatDatepicker(ReportSearch.IssueDateForm.startDate)"
                                                    v-on="on" outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.IssueDateForm.startDate = ''"
                                                    >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.IssueDateForm.startDate"
                                                @input="ReportSearch.IssueDateForm.startDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col  cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.IssueEndDate">
                                        Issue Date To
                                        <v-menu v-model="ReportSearch.IssueDateForm.toDateMenu"
                                            :close-on-content-click="false" hide-details :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field background-color="white" hide-details ref="TRSubmittedDate"
                                                    clearable :value="formatDatepicker(ReportSearch.IssueDateForm.toDate)"
                                                    v-on="on" outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.IssueDateForm.toDate = ''"
                                                    >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.IssueDateForm.toDate"
                                                @input="ReportSearch.IssueDateForm.toDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.BTTUser">
                                    BTT User
                                    <v-autocomplete v-model="ReportSearch.BTTUser" background-color="white"
                                    :items="BTTUserList" hide-details outlined dense clearable item-text="BTTName"
                                    item-value="BTTId" @input.native="GetBTTUser($event.srcElement.value)">
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.RefundRequestStartDate">
                                        Refund Request Date From
                                        <v-menu v-model="ReportSearch.RefundRequestDateForm.startDateMenu"
                                            :close-on-content-click="false" hide-details :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field background-color="white" hide-details ref="TRSubmittedDate"
                                                    clearable :value="formatDatepicker(ReportSearch.RefundRequestDateForm.startDate)"
                                                    v-on="on" outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.RefundRequestDateForm.startDate = ''"
                                                    >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.RefundRequestDateForm.startDate"
                                                @input="ReportSearch.RefundRequestDateForm.startDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.RefundRequestToDate">
                                        Refund Request Date To
                                        <v-menu v-model="ReportSearch.RefundRequestDateForm.toDateMenu"
                                            :close-on-content-click="false" hide-details :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field background-color="white" hide-details ref="TRSubmittedDate"
                                                    clearable :value="formatDatepicker(ReportSearch.RefundRequestDateForm.toDate)"
                                                    v-on="on" outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.RefundRequestDateForm.toDate = ''"
                                                    >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.RefundRequestDateForm.toDate"
                                                @input="ReportSearch.RefundRequestDateForm.toDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.TravelStartDate">
                                        Travel Date From
                                        <v-menu v-model="ReportSearch.TravelDateForm.startDateMenu"
                                            :close-on-content-click="false" hide-details :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field background-color="white" hide-details ref="TRSubmittedDate"
                                                    clearable :value="formatDatepicker(ReportSearch.TravelDateForm.startDate)"
                                                    v-on="on" outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.TravelDateForm.startDate = ''"
                                                    >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.TravelDateForm.startDate"
                                                @input="ReportSearch.TravelDateForm.startDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.TravelToDate">
                                        Travel Date To
                                        <v-menu v-model="ReportSearch.TravelDateForm.toDateMenu"
                                            :close-on-content-click="false" hide-details :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field background-color="white" hide-details ref="TRSubmittedDate"
                                                    clearable :value="formatDatepicker(ReportSearch.TravelDateForm.toDate)"
                                                    v-on="on" outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.TravelDateForm.toDate = ''"
                                                    >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.TravelDateForm.toDate"
                                                @input="ReportSearch.TravelDateForm.toDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.CheckInDate">
                                        Check in Date
                                        <v-menu v-model="ReportSearch.CheckForm.startDateMenu"
                                            :close-on-content-click="false" hide-details :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field clearable background-color="white" hide-details
                                                    ref="BusinessStartDate"
                                                    :value="formatDatepicker(ReportSearch.CheckForm.startDate)" v-on="on"
                                                    outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.CheckForm.startDate = ''"
                                                    >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.CheckForm.startDate"
                                                @input="ReportSearch.CheckForm.startDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.CheckOutDate">
                                        Check Out Date
                                        <v-menu v-model="ReportSearch.CheckForm.toDateMenu"
                                            :close-on-content-click="false" hide-details :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:[`activator`]="{ on, attrs }">
                                                <v-text-field clearable background-color="white" hide-details
                                                    ref="BusinessStartDate"
                                                    :value="formatDatepicker(ReportSearch.CheckForm.toDate)" v-on="on"
                                                    outlined dense readonly v-bind="attrs"
                                                    @click:clear="ReportSearch.CheckForm.toDate = ''"
                                                    >
                                                    <v-icon slot="append" color="primary">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="ReportSearch.CheckForm.toDate"
                                                @input="ReportSearch.CheckForm.toDateMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.Department">
                                Department
                                <v-autocomplete v-model="ReportSearch.Department" background-color="white"
                                :items="DepartmentList" hide-details outlined dense clearable item-text="ABBREVIATION"
                                item-value="ABBREVIATION">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="6" v-if="FilterFieldStatus.HotelName">
                                Hotel Name
                                <v-text-field dense outlined background-color="white" hide-details
                                v-model="ReportSearch.HotelName"></v-text-field>
                            </v-col>
                        </template>
                    </v-row>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="0" color="primary" @click="search()" :loading="SearchingItem" large>Search</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        <br />
        <v-card class="card" v-if="ReportDataTable && ReportDataTable.length > 0">
            <v-card-text v-if="ReportSearch.Keywording === 'R0004'">
                <div class="bgcard">
                    <v-data-table class="card f-text-22" hide-default-footer
                        @page-count="pageCount = $event" :page.sync="page"
                        :items-per-page="itemPage" @load="onPagination($event)"
                        :items="ReportDataTable && ReportDataTable.length > 0 ? ReportDataTable : []"
                        :headers="ReportHeaders" mobile-breakpoint>
                        <template v-slot:body="{ items }">
                            <tbody v-for="item in items" :key="item.id" @mouseover="highlightRow(item.id)"
                            @mouseleave="unhighlightRow(item.id)"
                            :class="{ 'highlighted-row': highlightedRows.includes(item.id) }">
                                <tr v-for="(subItem, i) in item.itemReport" :key="'f'+i" @click="getDataToReportFlight(subItem)" style="cursor: pointer;">
                                    <td>
                                        {{ subItem.OriginCountry }}
                                    </td>
                                    <td>
                                        {{ subItem.DestinationCountry }}
                                    </td>
                                    <td>
                                        {{ subItem.Year }}
                                    </td>
                                    <td>
                                        {{ subItem.Month }}
                                    </td>
                                    <td class="text-right">
                                        {{ subItem.NoOfBooking }}
                                    </td>
                                    <td class="text-right">
                                        {{ subItem.TotalPriceAmount }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd">
                                        <strong><span>Total</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalNoOfBooking }}</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalTotalPriceAmount }}</span></strong>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
            <v-card-text v-else-if="ReportSearch.Keywording === 'R0005'">
                <div class="bgcard">
                    <v-data-table class="card f-text-22" hide-default-footer
                        @page-count="pageCount = $event" :page.sync="page"
                        :items-per-page="itemPage" @load="onPagination($event)"
                        :items="ReportDataTable && ReportDataTable.length > 0 ? ReportDataTable : []"
                        :headers="ReportHeaders" mobile-breakpoint>
                        <template v-slot:body="{ items }">
                            <tbody v-for="item in items" :key="item.id" @mouseover="highlightRow(item.id)"
                            @mouseleave="unhighlightRow(item.id)"
                            :class="{ 'highlighted-row': highlightedRows.includes(item.id) }">
                                <tr v-for="(subItem, i) in item.itemReport" :key="'f'+i" @click="getDataToReportFlight(subItem)" style="cursor: pointer;">
                                    <td>
                                        {{ subItem.Agency }}
                                    </td>
                                    <td>
                                        {{ subItem.Year }}
                                    </td>
                                    <td>
                                        {{ subItem.Month }}
                                    </td>
                                    <td class="text-right">
                                        {{ subItem.NoOfBooking }}
                                    </td>
                                    <td class="text-right">
                                        {{ subItem.TotalPriceAmount }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd">
                                        <strong><span>Total</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalNoOfBooking }}</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalTotalPriceAmount }}</span></strong>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
            <v-card-text v-else-if="ReportSearch.Keywording === 'R0006'">
                <div class="bgcard">
                    <v-data-table class="card f-text-22" hide-default-footer
                        @page-count="pageCount = $event" :page.sync="page"
                        :items-per-page="itemPage" @load="onPagination($event)"
                        :items="ReportDataTable && ReportDataTable.length > 0 ? ReportDataTable : []"
                        :headers="ReportHeaders" mobile-breakpoint>
                        <template v-slot:body="{ items }">
                            <tbody v-for="item in items" :key="item.id" @mouseover="highlightRow(item.id)"
                            @mouseleave="unhighlightRow(item.id)"
                            :class="{ 'highlighted-row': highlightedRows.includes(item.id) }">
                                <tr v-for="(subItem, i) in item.itemReport" :key="'f'+i" @click="getDataToReportFlight(subItem)" style="cursor: pointer;">
                                    <td>
                                        {{ subItem.Airline }}
                                    </td>
                                    <td>
                                        {{ subItem.Year }}
                                    </td>
                                    <td>
                                        {{ subItem.Month }}
                                    </td>
                                    <td class="text-right">
                                        {{ subItem.NoOfBooking }}
                                    </td>
                                    <td class="text-right">
                                        {{ subItem.TotalPriceAmount }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd">
                                        <strong><span>Total</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalNoOfBooking }}</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalTotalPriceAmount }}</span></strong>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
            <v-card-text v-else-if="ReportSearch.Keywording === 'R0011'">
                <div class="bgcard">
                    <v-data-table class="card f-text-22" hide-default-footer
                        @page-count="pageCount = $event" :page.sync="page"
                        :items-per-page="itemPage" @load="onPagination($event)"
                        :items="ReportDataTable && ReportDataTable.length > 0 ? ReportDataTable : []"
                        :headers="ReportHeaders" mobile-breakpoint>
                        <template v-slot:body="{ items }">
                            <tbody v-for="item in items" :key="item.id" @mouseover="highlightRow(item.id)"
                            @mouseleave="unhighlightRow(item.id)"
                            :class="{ 'highlighted-row': highlightedRows.includes(item.id) }">
                                <tr v-for="(subItem, i) in item.itemReport" :key="'f'+i" @click="getDataToReportHotel(subItem)" style="cursor: pointer;">
                                    <td>
                                        {{ subItem.Country }}
                                    </td>
                                    <td>
                                        {{ subItem.Year }}
                                    </td>
                                    <td>
                                        {{ subItem.Month }}
                                    </td>
                                    <td class="text-right">
                                        {{ subItem.RoomNight }}
                                    </td>
                                    <td class="text-right">
                                        {{ subItem.TotalPriceAmount }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd">
                                        <strong><span>Total</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalRoomNight }}</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalTotalPriceAmount }}</span></strong>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
            <v-card-text v-else-if="ReportSearch.Keywording === 'R0012'">
                <div class="bgcard">
                    <v-data-table class="card f-text-22" hide-default-footer
                        @page-count="pageCount = $event" :page.sync="page"
                        :items-per-page="itemPage" @load="onPagination($event)"
                        :items="ReportDataTable && ReportDataTable.length > 0 ? ReportDataTable : []"
                        :headers="ReportHeaders" mobile-breakpoint>
                        <template v-slot:body="{ items }">
                            <tbody v-for="item in items" :key="item.id" @mouseover="highlightRow(item.id)"
                            @mouseleave="unhighlightRow(item.id)" :class="{ 'highlighted-row': highlightedRows.includes(item.id) }">
                                <template v-for="(subItem, i) in item.itemReport">
                                    <tr :key="'f'+i" style="cursor: pointer;">
                                        <td>
                                            <v-btn icon @click="subItem.ShowDetail = true"
                                            v-if="!subItem.ShowDetail"><v-icon>mdi-arrow-down-drop-circle-outline</v-icon></v-btn>
                                            <v-btn icon @click="subItem.ShowDetail = false"
                                            v-else><v-icon>mdi-arrow-up-drop-circle-outline</v-icon></v-btn>
                                        </td>
                                        <td @click="getDataToReportHotel(subItem)">
                                            {{ subItem.HotelName }}
                                        </td>
                                        <td @click="getDataToReportHotel(subItem)">
                                            {{ subItem.Country }}
                                        </td>
                                        <td @click="getDataToReportHotel(subItem)">
                                            {{ subItem.Year }}
                                        </td>
                                        <td @click="getDataToReportHotel(subItem)">
                                            {{ subItem.Month }}
                                        </td>
                                        <td @click="getDataToReportHotel(subItem)" class="text-right">
                                            {{ subItem.RoomNight }}
                                        </td>
                                        <td @click="getDataToReportHotel(subItem)" class="text-right">
                                            {{ subItem.TotalPriceAmount }}
                                        </td>
                                    </tr>
                                    <template v-if="subItem.ShowDetail">
                                        <template v-for="(rt, x) in subItem.RoomTypeList">
                                            <tr :key="'a'+i+x" style="background-color: #dff6da;">
                                                <td></td>
                                                <td>
                                                    {{ rt.RoomType }}
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td class="text-right">
                                                    {{ rt.RoomNight }}
                                                </td>
                                                <td class="text-right">
                                                    {{ rt.TotalPriceAmount }}
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                                <tr>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd">
                                        <strong><span>Total</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalRoomNight }}</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalTotalPriceAmount }}</span></strong>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
            <v-card-text v-else-if="ReportSearch.Keywording === 'R0013'">
                <div class="bgcard">
                    <v-data-table class="card f-text-22" hide-default-footer
                        @page-count="pageCount = $event" :page.sync="page"
                        :items-per-page="itemPage" @load="onPagination($event)"
                        :items="ReportDataTable && ReportDataTable.length > 0 ? ReportDataTable : []"
                        :headers="ReportHeaders" mobile-breakpoint>
                        <template v-slot:body="{ items }">
                            <tbody v-for="item in items" :key="item.id" @mouseover="highlightRow(item.id)"
                            @mouseleave="unhighlightRow(item.id)"
                            :class="{ 'highlighted-row': highlightedRows.includes(item.id) }">
                                <tr v-for="(subItem, i) in item.itemReport" :key="'f'+i" @click="getDataToReportHotel(subItem)" style="cursor: pointer;">
                                    <td>
                                        {{ subItem.Company }}
                                    </td>
                                    <td>
                                        {{ subItem.Year }}
                                    </td>
                                    <td>
                                        {{ subItem.Month }}
                                    </td>
                                    <td class="text-right">
                                        {{ subItem.RoomNightDomestic }}
                                    </td>
                                    <td class="text-right">
                                        {{ subItem.TotalPriceAmountDomestic }}
                                    </td>
                                    <td class="text-right">
                                        {{ subItem.RoomNightInternational }}
                                    </td>
                                    <td class="text-right">
                                        {{ subItem.TotalPriceAmountInternational }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd">
                                        <strong><span>Total</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.RoomNightDomestic }}</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalPriceAmountDomestic }}</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.RoomNightInternational }}</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalPriceAmountInternational }}</span></strong>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
            <v-card-text v-else-if="ReportSearch.Keywording === 'R0014'">
                <div class="bgcard">
                    <v-data-table class="card f-text-22" hide-default-footer
                        @page-count="pageCount = $event" :page.sync="page"
                        :items-per-page="itemPage" @load="onPagination($event)"
                        :items="ReportDataTable && ReportDataTable.length > 0 ? ReportDataTable : []"
                        :headers="ReportHeaders" mobile-breakpoint>
                        <template v-slot:body="{ items }">
                            <tbody v-for="item in items" :key="item.id" @mouseover="highlightRow(item.id)"
                            @mouseleave="unhighlightRow(item.id)" :class="{ 'highlighted-row': highlightedRows.includes(item.id) }">
                                <template v-for="(subItem, i) in item.itemReport">
                                    <tr :key="'f'+i" style="cursor: pointer;">
                                        <td>
                                            <v-btn icon @click="subItem.ShowDetail = true"
                                            v-if="!subItem.ShowDetail"><v-icon>mdi-arrow-down-drop-circle-outline</v-icon></v-btn>
                                            <v-btn icon @click="subItem.ShowDetail = false"
                                            v-else><v-icon>mdi-arrow-up-drop-circle-outline</v-icon></v-btn>
                                        </td>
                                        <td @click="getDataToReportHotel(subItem)">
                                            {{ subItem.HotelName }}
                                        </td>
                                        <td @click="getDataToReportHotel(subItem)">
                                            {{ subItem.Country }}
                                        </td>
                                        <td @click="getDataToReportHotel(subItem)">
                                            {{ subItem.Year }}
                                        </td>
                                        <td @click="getDataToReportHotel(subItem)">
                                            {{ subItem.Month }}
                                        </td>
                                        <td @click="getDataToReportHotel(subItem)" class="text-right">
                                            {{ subItem.RoomNight }}
                                        </td>
                                        <td @click="getDataToReportHotel(subItem)" class="text-right">
                                            {{ subItem.TotalPriceAmount }}
                                        </td>
                                    </tr>
                                    <template v-if="subItem.ShowDetail">
                                        <template v-for="(rt, x) in subItem.RoomTypeList">
                                            <tr :key="'a'+i+x" style="background-color: #dff6da;">
                                                <td></td>
                                                <td>
                                                    {{ rt.RoomType }}
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td class="text-right">
                                                    {{ rt.RoomNight }}
                                                </td>
                                                <td class="text-right">
                                                    {{ rt.TotalPriceAmount }}
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                                <tr>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd"></td>
                                    <td style="border-bottom: 1px solid #dddddd">
                                        <strong><span>Total</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalRoomNight }}</span></strong>
                                    </td>
                                    <td style="border-bottom: 1px solid #dddddd" class="text-right">
                                        <strong><span>{{ item.TotalTotalPriceAmount }}</span></strong>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
            <v-card-text v-else>
                <v-btn elevation="0" color="primary" :disabled="ReportDataTable && ReportDataTable.length > 0 ? false : true"
                    @click="onExport()">Export</v-btn>
                <div class="h-space"></div>
                <div class="bgcard">
                    <v-data-table class="card f-text-22" hide-default-footer
                        @page-count="pageCount = $event" :page.sync="page"
                        :items-per-page="itemPage" @load="onPagination($event)"
                        :items="ReportDataTable && ReportDataTable.length > 0 ? ReportDataTable : []"
                        :headers="ReportHeaders" mobile-breakpoint>
                        <template v-slot:[`item.No`]="{ index }">
                            {{ index+1 }}
                        </template>
                        <template v-slot:[`item.View`]="{ item }">
                            <v-btn elevation="0" color="primary" @click="viewReportR0018(item)">View</v-btn>
                        </template>
                        <template v-slot:[`item.Reminder`]="{ item }">
                            <v-btn elevation="0" color="primary" @click="SendMailReminderApproveTR(item)">Click to send email</v-btn>
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
            <v-card-text>
                <v-row class="mt-3 hidden-xs-only" no-gutters>
                    <v-col class="d-flex" cols="12">
                        <span class="mr-3 mt-3">Show</span>
                        <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                            v-model="itemPage" background-color="white" class="select-data-table shrink"
                            style="min-width: 100px;"></v-select>
                        <span class="ml-3 mt-3">items per page</span>
                        <v-pagination v-model="page" :length="pageCount" :total-visible="5" class="pagination-custom"
                            style="width: 60%;"></v-pagination>
                    </v-col>
                </v-row>
                <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                    <v-col class="d-flex" cols="12">
                        <span class="mr-3 mt-3">Show</span>
                        <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                            v-model="itemPage" background-color="white" class="select-data-table shrink"
                            style="min-width: 100px;"></v-select>
                        <span class="ml-3 mt-3">items per page</span>
                    </v-col>
                    <v-col>
                        <v-pagination v-model="page" :length="pageCount" :total-visible="5" class="pagination-custom">
                        </v-pagination>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialogR0018" persistent width="900px">
            <v-card elevation="0" title color="primary" class="pt-1 card">
                <v-card>
                    <v-card-text>
                        <v-card class="card-detail" title elevation="0">
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="6" lg="3" md="3" sm="3">
                                        <span color="black">
                                            <strong>TR No.</strong>
                                        </span>
                                    </v-col>
                                    <v-col>
                                        <span>
                                            {{ R0018View.TRNo }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="6" lg="3" md="3" sm="3">
                                        <span color="black">
                                            <strong>Budget Holder</strong>
                                        </span>
                                    </v-col>
                                    <v-col>
                                        <span>
                                            {{ R0018View.BudgetHolder }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="6" lg="3" md="3" sm="3">
                                        <span color="black">
                                            <strong>Company</strong>
                                        </span>
                                    </v-col>
                                    <v-col>
                                        <span>
                                            {{ R0018View.Company }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="6" lg="3" md="3" sm="3">
                                        <span color="black">
                                            <strong>Traveler Name</strong>
                                        </span>
                                    </v-col>
                                    <v-col>
                                        <span>
                                            {{ R0018View.TravelerName }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="6" lg="3" md="3" sm="3">
                                        <span color="black">
                                            <strong>Business Start Date</strong>
                                        </span>
                                    </v-col>
                                    <v-col>
                                        <span>
                                            {{ R0018View.BusinessStartDate }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="6" lg="3" md="3" sm="3">
                                        <span color="black">
                                            <strong>Business End Date</strong>
                                        </span>
                                    </v-col>
                                    <v-col>
                                        <span>
                                            {{ R0018View.BusinessEndDate }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <br>
                                <v-row no-gutters>
                                    <v-col>
                                        <table style="width: 100%; border-collapse: collapse;" aria-label="">
                                            <tr hidden><th></th></tr>
                                            <tr>
                                                <span color="black">
                                                    <strong>Flight Booking</strong>
                                                </span>
                                            </tr>
                                            <tbody v-for="(f, i) in R0018View.Flights" :key="i">
                                                <tr :style="f.IsUse === 0 ? 'background-color: #e6b8af;' : ''">
                                                    <td>
                                                        {{ f.Route }}
                                                    </td>
                                                    <td>
                                                        {{ f.PNR }}
                                                    </td>
                                                    <td>
                                                        {{ f.FlightNumber }}
                                                    </td>
                                                    <td>
                                                        {{ f.IsUse === 1 ? "Used" : "Not Used" }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <br>
                                            <tr>
                                                <span color="black">
                                                    <strong>Hotel Booking</strong>
                                                </span>
                                            </tr>
                                            <tbody v-for="(h, i) in R0018View.Hotels" :key="'h'+i">
                                                <tr>
                                                    <td colspan="4">
                                                        <span color="black">
                                                            <strong>{{ h.HotelName }}</strong>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr v-for="(hh, n) in h.HotelDetail" :key="'hh'+n"
                                                :style="hh.IsUse === 0 ? 'background-color: #e6b8af;' : ''">
                                                    <td>
                                                        {{ hh.DateStart }}
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        {{ hh.IsUse === 1 ? "Used" : "Not Used" }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn outlined color="primary" class="mr-1" @click="closeViewReport()">Close</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment'
import * as XLSX from 'xlsx'
import axios from 'axios'
export default {
    data() {
        return {
            highlightedRows: [],
            R0018View: {
                TRNo: "",
                BudgetHolder: "",
                Company: "",
                EmployeeID: "",
                TravelerName: "",
                BusinessStartDate: "",
                BusinessEndDate: "",
                Flights: [],
                Hotels: []
            },
            dialogR0018: false,
            itemPage: 10,
            page: 1,
            pageCount: 2,
            itemsPerPage: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            SearchingItem: false,
            CityAndProvinceList: [],
            country: [],
            SearchAdvanced: false,
            ReportHeaders: [],
            ReportsList: [],
            StatusList: ["'Submitted'", "'Reject'", "'Draft'", "'Approved Final'"],
            ReportDataTable: [],
            BusinessTravelTypeList: ['Domestic', 'Overseas'],
            BusinessTravelCaseList: ['NORMAL', 'URGENT'],
            PurposeofTravelList: [],
            ProjectList: [],
            AgencyList: [],
            TicketStatusList: ['Booked','Re-Issue','Issuing', 'Issued','Closed'],
            TravelerNameList:[],
            InvoiceNoList:[],
            BTTUserList:[],
            DepartmentList: [],
            BudgetHolderList: [],
            FilterFieldStatus:{
                    EMPLOYEE_ID: 0,
                    TRNo: 0,
                    Status: 0,
                    BudgetHolder: 0,
                    BusinessTravelType: 0,
                    BusinessTravelCase: 0,
                    Country: 0,
                    CityOrProvince: 0,
                    PurposeofTravel: 0,
                    SubmittedDateFrom: 0,
                    SubmittedDateTo: 0,
                    BusinessDateStartDate: 0,
                    BusinessDateToDate: 0,
                    DepartureOnStartDate: 0,
                    DepartureOnToDate: 0,
                    Project: 0,
                    Company: 0,
                    IssueStartDate: 0,
                    IssueEndDate: 0,
                    BTTUser: 0,
                    RefundRequestStartDate: 0,
                    RefundRequestToDate: 0,
                    TravelStartDate: 0,
                    TravelToDate: 0,
                    CheckInDate: 0,
                    CheckOutDate: 0,
                    Department: 0,
                    HotelName: 0,
                    ReportName: ""
            },
            ReportSearch: {
                TicketStatus: "",
                PurposeofTravel: "",
                Employee_ID: "",
                Keywording: '',
                TRNo: "",
                Group: "",
                Status: [],
                BudgetHolder: "",
                BusinessTravelType: "",
                BusinessTravelCase: "",
                Div: "",
                Country: "",
                CityOrProvince: "",
                Dept: "",
                Project: "",
                Company: "",
                BTTUser: "",
                HotelName: "",
                BusinessDateFrom: {
                    startDate: "",
                    startDateMenu: false,
                    toDate: "",
                    toDateMenu: false
                },
                Section: "",
                DepartureOn: {
                    startDate: "",
                    startDateMenu: false,
                    toDate: "",
                    toDateMenu: false
                },
                SubmittedDateForm: {
                    startDate: "",
                    startDateMenu: false,
                    toDate: "",
                    toDateMenu: false
                },
                CheckForm: {
                    startDate: "",
                    startDateMenu: false,
                    toDate: "",
                    toDateMenu: false
                },
                TravelDateForm: {
                    startDate: "",
                    startDateMenu: false,
                    toDate: "",
                    toDateMenu : false
                },
                RefundRequestDateForm: {
                    startDate: "",
                    startDateMenu: false,
                    toDate: "",
                    toDateMenu: false
                },
                IssueDateForm: {
                    startDate: "",
                    startDateMenu: false,
                    toDate: "",
                    toDateMenu: false
                },
            }
        }
    },
    methods: {
        async getDataToReportFlight(item){
            if(this.ReportSearch.Keywording === "R0004"){
                this.ReportSearch.Country = `${item.OriginCountry ?? ""},${item.DestinationCountry ?? ""}`
                this.getCountryCode(item.OriginCountry ?? item.DestinationCountry)
            }
            else if(this.ReportSearch.Keywording === "R0005"){
                this.ReportSearch.Agency = item.Agency
            }
            this.ReportSearch.Keywording = "R0003"
            this.FilterFieldStatus = this.ReportsList.filter(x=>x.ReportID === this.ReportSearch.Keywording)[0]
            await this.GetReportHeadersInTable()
            await this.GetReportByRequest()
        },
        async getDataToReportHotel(item){
            if(this.ReportSearch.Keywording === "R0011"){
                await this.getCountryCode(item.Country)
                this.ReportSearch.Country = this.country.filter(x=>x.Name === item.Country)[0].Code
            }
            else if(this.ReportSearch.Keywording === "R0012" || this.ReportSearch.Keywording === "R0014"){
                await this.getCountryCode(item.Country)
                this.ReportSearch.Country = this.country.filter(x=>x.Name === item.Country)[0].Code
                this.ReportSearch.HotelName = item.HotelName
            }
            else if(this.ReportSearch.Keywording === "R0013"){
                this.ReportSearch.Company = item.Company
            }
            this.ReportSearch.Keywording = "R0010"
            this.FilterFieldStatus = this.ReportsList.filter(x=>x.ReportID === this.ReportSearch.Keywording)[0]
            await this.GetReportHeadersInTable()
            await this.GetReportByRequest()
        },
        async getDataToReportOG(item){
            this.ReportSearch.Country = `${item.OriginCountry ?? ""},${item.DestinationCountry ?? ""}`
            if(item.Year && item.Month){
                this.ReportSearch.BusinessDateFrom.startDate =
                moment(`${item.Year}-${item.Month}-01`).format("YYYY-MM-DD")
                this.ReportSearch.BusinessDateFrom.toDate =
                moment(`${item.Year}-${item.Month}-${moment([item.Year, this.caldaysInMonth(item.Month)]).daysInMonth()}`).format("YYYY-MM-DD")
            }
            this.ReportSearch.Keywording = "R0003"
            this.FilterFieldStatus = this.ReportsList.filter(x=>x.ReportID === this.ReportSearch.Keywording)[0]
            await this.GetReportHeadersInTable()
            await this.GetReportByRequest()
        },
        viewReportR0018(item){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}Report/ViewReportR0018`, item,{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}`}
            })
            .then(async (res) => {
                if (res.data.Status) {
                    this.R0018View = res.data.Result
                    this.$store.state.LOADING = false
                    this.dialogR0018 = true
                }
                else{
                    this.$store.state.LOADING = false
                }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        closeViewReport(){
            this.dialogR0018 = false
            this.R0018View = {
                TRNo: "",
                BudgetHolder: "",
                Company: "",
                EmployeeID: "",
                TravelerName: "",
                BusinessStartDate: "",
                BusinessEndDate: "",
                Flights: [],
                Hotels: []
            }
        },
        getReport(){
            this.ReportDataTable = []
            this.ReportHeaders = []
            this.FilterFieldStatus = {
                EMPLOYEE_ID: 0,
                TRNo: 0,
                Status: 0,
                BudgetHolder: 0,
                BusinessTravelType: 0,
                BusinessTravelCase: 0,
                Country: 0,
                CityOrProvince: 0,
                PurposeofTravel: 0,
                SubmittedDateFrom: 0,
                SubmittedDateTo: 0,
                BusinessDateStartDate: 0,
                BusinessDateToDate: 0,
                DepartureOnStartDate: 0,
                DepartureOnToDate: 0,
                Project: 0,
                Company: 0,
                TicketStatus: 0,
                TravelerName: 0,
                Agency: 0,
                ApproveCode: 0,
                InvoiceNo: 0,
                IssueStartDate: 0,
                IssueEndDate: 0,
                BTTUser: 0,
                RefundRequestStartDate: 0,
                RefundRequestToDate: 0,
                TravelStartDate: 0,
                TravelToDate: 0,
                CheckInDate: 0,
                CheckOutDate: 0,
                Department: 0,
                HotelName: 0,
                ReportName: ""
            }
            this.ReportSearch.TicketStatus = ""
            this.ReportSearch.PurposeofTravel = ""
            this.ReportSearch.Employee_ID = ""
            this.ReportSearch.TRNo = ""
            this.ReportSearch.Group = ""
            this.ReportSearch.BudgetHolder = ""
            this.ReportSearch.BusinessTravelType = ""
            this.ReportSearch.BusinessTravelCase = ""
            this.ReportSearch.Div = ""
            this.ReportSearch.Country = ""
            this.ReportSearch.CityOrProvince = ""
            this.ReportSearch.Dept = ""
            this.ReportSearch.Project = ""
            this.ReportSearch.Company = ""
            this.ReportSearch.BTTUser = ""
            this.ReportSearch.Agency = ""
            this.ReportSearch.HotelName = ""
            this.ReportSearch.BusinessDateFrom.startDate = ""
            this.ReportSearch.BusinessDateFrom.startDateMenu = false
            this.ReportSearch.BusinessDateFrom.toDate = ""
            this.ReportSearch.BusinessDateFrom.toDateMenu = false
            this.ReportSearch.Section = ""
            this.ReportSearch.DepartureOn.startDate = ""
            this.ReportSearch.DepartureOn.startDateMenu = false
            this.ReportSearch.DepartureOn.toDate = ""
            this.ReportSearch.DepartureOn.toDateMenu = false
            this.ReportSearch.SubmittedDateForm.startDate = ""
            this.ReportSearch.SubmittedDateForm.startDateMenu = false
            this.ReportSearch.SubmittedDateForm.toDate = ""
            this.ReportSearch.SubmittedDateForm.toDateMenu = false
            this.ReportSearch.CheckForm.startDate = ""
            this.ReportSearch.CheckForm.startDateMenu = false
            this.ReportSearch.CheckForm.toDate = ""
            this.ReportSearch.CheckForm.toDateMenu = false
            this.ReportSearch.TravelDateForm.startDate = ""
            this.ReportSearch.TravelDateForm.startDateMenu = false
            this.ReportSearch.TravelDateForm.toDate = ""
            this.ReportSearch.TravelDateForm.toDateMenu = false
            this.ReportSearch.RefundRequestDateForm.startDate = ""
            this.ReportSearch.RefundRequestDateForm.startDateMenu = false
            this.ReportSearch.RefundRequestDateForm.toDate = ""
            this.ReportSearch.RefundRequestDateForm.toDateMenu = false
            this.ReportSearch.IssueDateForm.startDate = ""
            this.ReportSearch.IssueDateForm.startDateMenu = false
            this.ReportSearch.IssueDateForm.toDate = ""
            this.ReportSearch.IssueDateForm.toDateMenu = false
            this.GetPurposeofTravel();
            this.GetProjectCompanyName();
            this.GetBTTInfo();
            this.GetBudgetHolder();
            this.GetDepartment();
            this.GetAgency();
            this.GetReportHeadersInTable()
            this.FilterFieldStatus = this.ReportsList.filter(x=>x.ReportID === this.ReportSearch.Keywording)[0]
        },
        GetAgency(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}Report/GetAgencyByReport`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}`}
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.AgencyList = res.data.Result
                }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        GetDepartment(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}Report/GetDepartmentByReport`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.DepartmentList = res.data.Result.map((item) => item.ABBREVIATION)
                }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        GetBTTInfo(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}BusinessTravel/GetBusinessTravelByReport`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.BTTUserList = res.data.Result
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        GetTravellerName(val){
            axios.post(`${this.$store.state.API_PATH}Report/GetTravellerNameByReport`,{
                TravelerName: val
            },{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}`}
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.TravelerNameList = res.data.Result
                }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        GetProjectCompanyName(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}BusinessTravel/GetBudgetHolderByReport`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}`}
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.ProjectList = res.data.Result.filter((item) => item.ProjectName)
                    this.ProjectList = res.data.Result.filter((item) => item.CompanyName)
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        GetBudgetHolder(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}TravelRequest/GetBudgetHolderByReport`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}`}
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.BudgetHolderList = res.data.Result.map((item) => item.BudgetHolder)
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        GetPurposeofTravel(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}ProposeOfTravel/GetTableProposeOfTravel`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}`}
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.PurposeofTravelList = res.data.Result.filter((item) => item.StsId === '1')
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        getCitiesCode() {
            axios.post(`${this.$store.state.API_PATH}CityCode/${this.ReportSearch.Country === 'TH' ? 'GetProvince' : 'GetCityCodeByCountryCode'}`, {
                CountryCode: this.ReportSearch.Country,
                Language: this.$store.state.Language,
                Country: this.ReportSearch.Country
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then((resCities) => {
                if (resCities.data.Status) {
                    if(this.ReportSearch.Country === 'TH'){
                        this.CityAndProvinceList = resCities.data.Result.map((elm) => ({
                            ...elm,
                                text: `${elm.name_en} (${elm.code})`,
                            }));
                    } else {
                         this.CityAndProvinceList = resCities.data.Result.map((elm) => ({
                            ...elm,
                                text: `${elm.Name} (${elm.Code})`,
                            }));
                    }
                } else {
                    this.CityAndProvinceList = [];
                }
            }).catch((error) => {
                console.error(error);
                this.CityAndProvinceList = []; // Handle error by setting an empty list
            });
        },
        async getCountryCode(input) {
            if (input) {
                const checkCountryItem = this.country.filter((el) => el.text.toLowerCase().substr(0, input.length) === input.toLowerCase());
                if (checkCountryItem.length === 0) {
                    await axios.post(`${this.$store.state.API_PATH}CountryCode/GetCountryCode`,
                        {
                            Code: input,
                            Language: this.$store.state.Language,
                        },
                        {
                            headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
                        }
                    ).then((res) => {
                        if (res.data.Status) {
                            this.country = [];
                            res.data.Result.forEach((elm) => {
                                this.country.push({
                                    ...elm,
                                    text: `${elm.Name} (${elm.Code})`,
                                });
                            });
                        }
                    }).catch((err) => err);
                }
            }
        },
        getReportFilter() {
            axios.get(`${this.$store.state.API_PATH}Report/GetReport`, {
                headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
            }
            ).then((res) => {
                if (res.data.Status) {
                    this.ReportsList = res.data.Result
                }
            }).catch((err) => err)
        },
        async GetReportHeadersInTable() {
            this.ReportHeaders = []
            await axios.post(`${this.$store.state.API_PATH}Report/GetReportHeadersInTable`, {
                ReportID: this.ReportSearch.Keywording
            }, {
                headers: { Authorization: `Basic ${this.$store.state.authenApi}` },
            }
            ).then(async(res) => {
                if (res.data.Status) {
                    this.ReportHeaders = res.data.Result
                    if(this.ReportSearch.Keywording === "R0002"){
                        if(res.data.Result[0].ListAgents && res.data.Result[0].ListAgents.length > 0){
                            for(const a of res.data.Result[0].ListAgents){
                                await new Promise(resolve=>{
                                    this.ReportHeaders.push({
                                        text: a.Name,
                                        value: a.Name,
                                        align: a.align
                                    })
                                    resolve()
                                })
                            }
                        }
                    }
                }
            }).catch((err) => err)
        },
        formatDatepicker(date) {
            return date ? moment(date).format('DD MMM YYYY') : "-"
        },
        search() {
            this.SearchingItem = true
            this.GetReportByRequest();
        },
        async GetReportByRequest() {
            this.ReportDataTable = []
            await axios.post(this.$store.state.API_PATH + 'Report/GetReportByRequest', this.ReportSearch, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async(res) => {
                if(res.data.Status){
                    for(const r of res.data.Result){
                        await new Promise(async(resolve)=>{
                            if(r.ListAgents && r.ListAgents.length > 0){
                                const obj = {}
                                for(const a of r.ListAgents){
                                    await new Promise(resolve2=>{
                                        Object.assign(obj, { [a.Name]: a.Price })
                                        resolve2()
                                        resolve()
                                    })
                                }
                                this.ReportDataTable.push({
                                    ...r,
                                    ...obj
                                })
                            }
                            else{
                                this.ReportDataTable.push({
                                    ...r
                                })
                                resolve()
                            }
                        })
                    }
                    this.SearchingItem = false
                }
                else{
                    this.SearchingItem = false
                }
            }).catch(res => {
                this.SearchingItem = false
                console.log(res);
            })
        },
        async onExport() {
            const Heading = [[ ...this.ReportHeaders.map(x=>x.text) ]]
            const sheet = XLSX.utils.json_to_sheet(this.ReportDataTable)
            const newBook = XLSX.utils.book_new()
            XLSX.utils.sheet_add_aoa(sheet, Heading)
            XLSX.utils.book_append_sheet(newBook, sheet)
            XLSX.writeFile(newBook, `${this.FilterFieldStatus.ReportName}(${moment().format('HH:mm:SS DD MMM YYYY')}).xlsx`)
        },
        SendMailReminderApproveTR(item){
            this.$store.state.LOADING = true
            axios.get(`${this.$store.state.API_PATH}TravelRequest/NotificationReminderApproveTR?TRNo=${item.TRNo}`, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async(res) => {
                this.$store.state.LOADING = false
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        text: res.data.ErrorMessage,
                        confirmButtonColor: '#00AEEF'
                    }).then(() => {}, () => {})
                }
            }).catch(res => {
                this.$store.state.LOADING = false
                console.log(res)
            })
        },
        highlightRow(itemId) {
            if (!this.highlightedRows.includes(itemId)) {
                this.highlightedRows.push(itemId);
            }
        },
        unhighlightRow(itemId) {
            const index = this.highlightedRows.indexOf(itemId);
            if (index !== -1) {
                this.highlightedRows.splice(index, 1);
            }
        },
        caldaysInMonth(month){
            let result = ""
            switch(month){
                case "January":
                    result = 0
                break;
                case "February":
                    result = 1
                break;
                case "March":
                    result = 2
                break;
                case "April":
                    result = 3
                break;
                case "May":
                    result = 4
                break;
                case "June":
                    result = 5
                break;
                case "July":
                    result = 6
                break;
                case "August":
                    result = 7
                break;
                case "September":
                    result = 8
                break;
                case "October":
                    result = 9
                break;
                case "November":
                    result = 10
                break;
                case "December":
                    result = 11
                break;
            }
            return result
        },
    },
    mounted(){
        this.getReportFilter()
    }
}
</script>
<style scoped>
.highlighted-row {
  background-color: #aaddff; /* #eeeeee; */
}
td {
  padding: 5px;
}
</style>
