<template>
        <div v-if="travel != null && travel != undefined">
            <v-card class="text-traveler card"
                :style="travel.TravelCard != null && travel.TravelCard.IsDelete == 0 ? '' : 'pointer-events: none; background-color: #eeeeee;'"
                elevation="0">
                <v-card-text :style="travel.TravelCard != null && travel.TravelCard.IsDelete == 0 ?
                    'background-color: #00AEEF; color: white; height: 60px; position: block; width: 100%' :
                    'background-color: #c0c0c0;'">
                    <span class="font-W400">
                        Traveler No. {{ num + 1 }}
                    </span>
                    <span style="float: right;">
                        {{ travel.emp_ID != null && travel.CreateBy !== null ? 'External' : 'Internal' }}
                        <v-chip color="error" x-small v-if="travel.TravelCard != null && travel.TravelCard.IsDelete != 0">
                            <span>
                                Deleted
                            </span>
                        </v-chip>
                    </span>
                </v-card-text>
                <v-card-text>
                    <div class="greentap">
                        <span class="f-text-24-b">{{ travel.fullname }} <div style="float: right" class="f-text-detail">{{
                            travel.age }}</div>
                        </span>
                        <table style="width: 100%" aria-label="" class="f-text-20-b"
                            v-if="travel && !travel.age || travel.age === 'adult' || travel.age === ''">
                            <thead>
                                <tr>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Office: -
                                    </td>
                                    <td style="float: right;">
                                        <span>
                                            Mobile: {{ travel.mobile }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <span>
                                            {{ travel.email }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-else style="width: 100%" aria-label="" class="f-text-20-b">
                            <thead>
                                <tr>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr>
                                <td>
                                    <span style="font-size: .7rem;">
                                        {{ travel.age }}
                                    </span>
                                </td>
                                <td style="float: right;">
                                    <span style="font-size: .7rem;">
                                        mobile: {{ travel.mobile }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <span>
                                        {{ travel.email }}
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <br />
                    <div style="border: #EBEEF4 0.5px solid !important; border-radius: 5px; padding: 6px;">
                        <span class="f-text-20">
                            Dept / Div: <span class="f-text-18 font-W700">{{ travel.dep }} {{ travel.div }}</span>
                            <br />
                            Status: <span class="f-text-18 font-W700" v-if=" travel.flights.filter(x => x.ReIssueSelected === 1 && x.Status === 'Re-Issue').length > 0">
                                        Re-Issue
                                    </span>
                                    <span class="f-text-18 font-W700" v-else>
                                        {{ travel.status }}
                                    </span>
                            <br />
                            <span v-if="travel.CreateBy === null">
                                Line Approver: <span class="f-text-18 font-W700">{{ travel.lineApprover }}</span>
                            </span>
                        </span>
                    </div>
                    <div class="f-text-22-b h-space" v-if="!NoRequireServices">
                        Services
                    </div>
                    <!--Flight-->
                    <div class="bgcard" v-if="!NoRequireServices">
                        <table style="width: 100%" aria-label="" class="f-text-20">
                            <thead>
                                <tr>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr>
                                <td style="width: 20px">
                                    <span>
                                        <v-badge :content="travel.flights.length" color="#2D6FE3"
                                            v-if="travel.flights != null && travel.flights.length > 0 && Object.keys(travel.flights) !== 0">
                                        </v-badge>
                                        <div class="pb-1 f-text-20-b font-W400" v-else>
                                            Flight
                                        </div>
                                    </span>
                                </td>
                                <td
                                    v-if="travel.flights != null && travel.flights.length > 0 && Object.keys(travel.flights) !== 0">
                                    <div class="pb-1 f-text-20 font-W400">
                                        Flights
                                    </div>
                                </td>
                                <td
                                    v-if="travel.flights != null && travel.flights.length > 0 && Object.keys(travel.flights) !== 0 > 0">
                                    <v-menu v-if="travel.status !== txtApproveFinal && travel.status !== 'Re-Issue'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-img :src="require('@/assets/icons/Setting.svg')"
                                                style="float: right; cursor: pointer;" width="16" v-bind="attrs" small
                                                v-on="on" />
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="(settingflight, i) in settingflights" :key="i">
                                                <v-list-item-title style="cursor: pointer;" @click="addFlight">{{
                                                    settingflight.setting
                                                }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </table>
                        <div v-if="travel.flights != null && travel.flights.length > 0 && Object.keys(travel.flights) !== 0">
                            <div v-for="(itemFlight, index) in travel.flights" :key="index" >
                                <template>
                                    <!-- Re-Issue Header -->
                                    <div class="h-space" v-if="ReIssueBooking && ReIssueBooking.length > 0 &&
                                     ReIssueBooking.filter(x => x.OriginalBookingOID === itemFlight.BookingOID
                                    || (itemFlight.BookingOfflineOID && x.OriginalBookingOID === itemFlight.BookingOfflineOID.toLowerCase())).length > 0"></div>
                                    <span v-if="ReIssueBooking && ReIssueBooking.length > 0 && ReIssueBooking.filter(x => x.OriginalBookingOID === itemFlight.BookingOID
                                    || (itemFlight.BookingOfflineOID && x.OriginalBookingOID === itemFlight.BookingOfflineOID.toLowerCase())).length > 0"
                                    style="padding: 5px 10px 0px 10px; background-color: #00c7f6; color: white;
                                        border-radius: 8px 8px 0px 0px;" class="f-text-18">
                                        Re-issue Ticket
                                    </span>
                                    <!--END Re-Issue Header-->

                                    <!-- Border -->
                                    <div :style="ReIssueBooking && ReIssueBooking.length > 0 && ReIssueBooking.filter(x => x.OriginalBookingOID === itemFlight.BookingOID
                                        || (itemFlight.BookingOfflineOID && x.OriginalBookingOID === itemFlight.BookingOfflineOID.toLowerCase())).length > 0
                                        ? 'border: 1px solid #5edfff; padding: 5px; border-radius: 5px;' : ''">
                                        <!-- Re-Issue Flight Detail -->
                                        <template v-if="itemFlight.IsReIssue === 0 || (ReIssueBooking && ReIssueBooking.length > 0 &&
                                                ReIssueBooking.filter(x => x.OriginalBookingOID === itemFlight.BookingOID
                                                || (itemFlight.BookingOfflineOID &&
                                                x.OriginalBookingOID === itemFlight.BookingOfflineOID.toLowerCase())).length > 0)">
                                            <div v-for="(itemReIssue, index) in ReIssueBooking.filter(x => x.OriginalBookingOID === itemFlight.BookingOID
                                                || (itemFlight.BookingOfflineOID &&
                                                x.OriginalBookingOID === itemFlight.BookingOfflineOID.toLowerCase()))" :key="`Issue${index}`" >
                                                <template v-if="index !== 0">
                                                    <div style="height: 10px;"></div>
                                                    <v-divider></v-divider>
                                                    <div style="height: 10px;"></div>
                                                </template>
                                                <v-row>
                                                    <v-col cols="8">
                                                        <span class="badge-info f-text-16 font-W400"
                                                            style="padding: 1px; border: solid #00adef 1.5px; background-color: #f2fcff;">
                                                            <span class="f-text-16 font-W400" style="color: #00AEEF">
                                                                &nbsp; PNR {{ itemReIssue.PNR ? itemReIssue.PNR : 'Pending' }}
                                                                <span v-if="itemReIssue.ReIssueSelected === 1" style="color: red;" class="f-text-16 font-W400" >
                                                                    Re-Issue
                                                                </span>
                                                                <span v-else-if="itemReIssue.IsReIssue === 1" style="color: red;" class="f-text-16 font-W400">
                                                                    Old Booking
                                                                </span>
                                                                &nbsp;
                                                            </span>
                                                        </span>
                                                    </v-col>
                                                    <template v-if="itemReIssue.IsReIssue === 0 || itemReIssue.ReIssueSelected === 1">
                                                        <v-col cols="2"></v-col>
                                                        <v-col cols="2">
                                                            <div class="text-center" style="float: center" v-if="travel.status === txtApproveFinal || travel.status === 'Re-Issue'">
                                                                <v-btn x-small width="4" icon
                                                                    @click="ReIssueFlight(itemReIssue, itemReIssue.isFlightOffline ? 'Offline' : 'Online')">
                                                                    <v-img :src="require('@/assets/icons/EditDetail.svg')"></v-img>
                                                                </v-btn>
                                                            </div>
                                                            <div class="text-center" style="float: center" v-else>
                                                                <v-btn x-small width="4" icon
                                                                    @click="RemoveFlight('alert', itemReIssue, travelers)">
                                                                    <v-img :src="require('@/assets/icons/deleteInfo.svg')"></v-img>
                                                                </v-btn>
                                                            </div>
                                                        </v-col>
                                                    </template>
                                                </v-row>
                                                <div v-if="itemReIssue.TravelWith && itemReIssue.TravelWith !== '0'"
                                                    class="f-text-detail pl-4" style="background-color: #FFDAE7;">
                                                    <div class="h-space"></div>
                                                    {{ `Travel With: ${travelers.filter(x => x.emp_ID === itemReIssue.TravelWith)[0].fullname}` }}
                                                </div>
                                                <span style="height: 10px;"></span>
                                                <span class="f-text-16 font-W400" style="font-size: 0.8rem;">
                                                    <strong style="font-size: 0.8rem;" class="hidden-sm-and-down">
                                                        {{ itemReIssue.depFlight[0].depCity.name.split(',')[0] }}
                                                        ({{ itemReIssue.depFlight[0].depCity.code }})
                                                    </strong>
                                                    <template v-if="itemReIssue.retFlight !== null && itemReIssue.retFlight.length > 0">
                                                        <img :src="require('@/assets/icons/tweenArrows.svg')" style="margin-top: 10px;"
                                                            alt="" />
                                                        <strong style="font-size: 0.8rem;" class="hidden-sm-and-down">
                                                            {{ itemReIssue.retFlight[0].depCity.name.split(',')[0] }}
                                                            ({{ itemReIssue.retFlight[0].depCity.code }})
                                                        </strong>
                                                    </template>
                                                    <template v-else>
                                                        <img :src="require('@/assets/icons/Arrow_Left.svg')" style="margin-top: 10px;"
                                                            alt="" width="10px" />
                                                        <strong style="font-size: 0.8rem;" class="hidden-sm-and-down">
                                                            {{ itemReIssue.depFlight[0].arrCity.name.split(',')[0] }}
                                                            ({{ itemReIssue.depFlight[0].arrCity.code }})
                                                        </strong>
                                                    </template>
                                                </span>
                                                <v-card-actions @click="showDetail(`OfflineIssue${index}`)"
                                                 class="f-text-18" style="cursor: pointer; color: #00AEEF"
                                                 v-if="itemReIssue.ReIssueSelected === 0">
                                                    <v-spacer></v-spacer>
                                                    {{ showOldBooking && showOldBooking === `OfflineIssue${index}` ? 'Hide Detail' : 'Show Details' }}
                                                </v-card-actions>
                                                <template v-if="itemReIssue.ReIssueSelected === 1 || showOldBooking && showOldBooking === `OfflineIssue${index}`">
                                                    <br />
                                                    <span class="f-text-16 font-W400">
                                                        Flight Ticket Status
                                                        <strong class="f-text-16">
                                                            {{ itemReIssue.BTTStatus }}
                                                        </strong>
                                                    </span>
                                                    <br />
                                                    <span></span>
                                                    <span class="f-text-16 font-W400">
                                                        {{ itemReIssue.depFlight[0].depDisplayDateTime.shortDate }}
                                                        <template v-if="itemReIssue.retFlight !== null && itemReIssue.retFlight.length > 0">
                                                            -
                                                            {{ itemReIssue.retFlight[0].depDisplayDateTime.shortDate }}
                                                            <strong class="f-text-16" v-if="itemReIssue.Svc_class">
                                                                |
                                                                {{
                                                                    itemReIssue.Svc_class === 'Y' ||
                                                                    itemReIssue.Svc_class === 'W' ||
                                                                    itemReIssue.Svc_class === 'M' ?
                                                                    'Economy' : itemReIssue.Svc_class === 'C' ?
                                                                    'Business' : 'First'
                                                                }}
                                                            </strong>
                                                        </template>
                                                        <br />
                                                        Departure time <strong class="f-text-16">
                                                            {{itemReIssue.depFlight[0].depDisplayDateTime.displayTime}}
                                                        </strong>
                                                        <br />
                                                        <template v-if="false">
                                                            Price <strong class="f-text-16" v-if="!travel.age ||
                                                                    travel.age === 'Adult'">{{ itemReIssue.adtFare ?
                                                                    new Intl.NumberFormat().format(itemReIssue.adtFare.net) + ' THB' : 'Pending' }}
                                                            </strong>
                                                            <strong class="f-text-16"
                                                                v-else-if="itemReIssue.chdPaxs && itemReIssue.chdPaxs.filter(x => x.emp_ID === travel.emp_ID) !== null">
                                                                {{ itemReIssue.chdFare ? new Intl.NumberFormat().format(itemReIssue.chdFare.net) + ' THB' : 'Pending' }}
                                                            </strong>
                                                            <strong class="f-text-16"
                                                                v-else-if="itemReIssue.infPaxs && itemReIssue.infPaxs.filter(x => x.emp_ID === travel.emp_ID) !== null">
                                                                {{ itemReIssue.infFare ? new Intl.NumberFormat().format(itemReIssue.infFare.net) + ' THB' : 'Pending' }}
                                                            </strong>
                                                            <br />
                                                        </template>
                                                    </span>
                                                    <br />
                                                    <div class="f-text-20-b">
                                                        Departure
                                                    </div>
                                                    <div class="h-space"></div>
                                                    <template v-for="(itemFlightDetail, index) in itemReIssue.depFlight">
                                                        <div :key="`D${index}`">
                                                            <v-card elevation="0">
                                                                <v-card-text>
                                                                    <table aria-label="" style="width: 100%">
                                                                        <thead>
                                                                            <tr>
                                                                                <th></th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tr>
                                                                            <td colspan="4">
                                                                                <span>
                                                                                    <strong class="f-text-18 f-W500" style="float:right;">
                                                                                        {{
                                                                                            itemFlightDetail.depDisplayDateTime.shortDate
                                                                                        }}
                                                                                    </strong>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style="width: 20px">
                                                                                <v-img alt="user"
                                                                                    v-if="itemFlightDetail.airline.code && itemFlightDetail.airline.name"
                                                                                    :src="require('@/assets/logoAirline/square/' + itemFlightDetail.airline.code + '.png')"
                                                                                    width="30" />
                                                                            </td>
                                                                            <td>
                                                                                <span class="ml-2 f-text-18 hidden-sm-and-down">
                                                                                    {{ itemFlightDetail.airline.name }}
                                                                                </span>
                                                                                <span class="ml-2 f-text-18 hidden-md-and-up">
                                                                                    {{ itemFlightDetail.airline.code }}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <strong class="f-text-18" style="float:right;">
                                                                                    {{ itemFlightDetail.airline.code }}
                                                                                    {{ itemFlightDetail.flightNumber }}
                                                                                </strong>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <v-row>
                                                                        <v-col class="text-center" cols="4" md="3">
                                                                            <span class="f-text-18">
                                                                                {{ itemFlightDetail.depDisplayDateTime.displayTime }}
                                                                            </span>
                                                                            <br />
                                                                            <v-chip x-small color="#A1D1FD">
                                                                                <span>
                                                                                    <div class="f-text-18" style="font-size: .5rem;">
                                                                                        {{ itemFlightDetail.depCity.code }}
                                                                                    </div>
                                                                                </span>
                                                                            </v-chip>
                                                                        </v-col>
                                                                        <v-col class="text-center" cols="4" md="6">
                                                                            <div style="height: 13px;"></div>
                                                                            <span>
                                                                                <v-img :src="require('@/assets/icons/ArrowAirline.svg')" />
                                                                            </span>
                                                                        </v-col>
                                                                        <v-col cols="4" md="3" class="text-center">
                                                                            <span class="f-text-18">
                                                                                {{ itemFlightDetail.arrDisplayDateTime.displayTime }}
                                                                            </span>
                                                                            <br />
                                                                            <v-chip x-small color="#A1D1FD">
                                                                                <span class="f-text-18">
                                                                                    {{ itemFlightDetail.arrCity.code }}
                                                                                </span>
                                                                            </v-chip>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>
                                                            <br />
                                                        </div>
                                                    </template>
                                                    <br v-if="itemFlight.retFlight !== null && itemFlight.retFlight.length > 0" />
                                                    <div class="f-text-20-b" v-if="itemFlight.retFlight !== null && itemFlight.retFlight.length > 0">
                                                        Return
                                                    </div>
                                                    <div class="h-space"></div>
                                                    <template v-for="(itemFlightDetail, index) in itemReIssue.retFlight">
                                                        <v-card elevation="0" v-if="itemReIssue.retFlight !== null && itemReIssue.retFlight.length > 0" :key="`R${index}`">
                                                            <v-card-text>
                                                                <table style="width: 100%" aria-label="">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tr>
                                                                        <td colspan="4">
                                                                            <strong style="float:right;" class="f-text-18">
                                                                                {{ itemFlightDetail.depDisplayDateTime.shortDate }}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="width: 20px;">
                                                                            <v-img alt="user"
                                                                                v-if="itemFlightDetail.airline.code && itemFlightDetail.airline.name"
                                                                                :src="require('@/assets/logoAirline/square/' + itemFlightDetail.airline.code + '.png')"
                                                                                width="30" />
                                                                        </td>
                                                                        <td>
                                                                            <span class="ml-2 f-text-18 hidden-sm-and-down">
                                                                                {{ itemFlightDetail.airline.name }}
                                                                            </span>
                                                                            <strong class="ml-2 f-text-18 hidden-md-and-up">
                                                                                {{ itemFlightDetail.airline.code }}
                                                                            </strong>
                                                                        </td>
                                                                        <td>
                                                                            <strong class="f-text-18" style="float:right;">
                                                                                {{ itemFlightDetail.airline.code }}
                                                                                {{ itemFlightDetail.flightNumber }}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <v-row>
                                                                    <v-col class="text-center" cols="4" md="3">
                                                                        <span class="f-text-18">
                                                                            {{ itemFlightDetail.depDisplayDateTime.displayTime }}
                                                                        </span>
                                                                        <br />
                                                                        <v-chip x-small color="#A1D1FD" style="font-size: .5rem">
                                                                            <span class="f-text-18">
                                                                                {{ itemFlightDetail.depCity.code }}
                                                                            </span>
                                                                        </v-chip>
                                                                    </v-col>
                                                                    <v-col cols="4" md="6">
                                                                        <div style="height: 13px;"></div>
                                                                        <span>
                                                                            <v-img :src="require('@/assets/icons/ArrowAirline.svg')" />
                                                                        </span>
                                                                    </v-col>
                                                                    <v-col class="text-center" cols="4" md="3">
                                                                        <span class="f-text-18">
                                                                            {{ itemFlightDetail.arrDisplayDateTime.displayTime }}
                                                                        </span>
                                                                        <br />
                                                                        <v-chip x-small color="#A1D1FD" style="font-size: .5rem">
                                                                            <span class="f-text-18">
                                                                                {{ itemFlightDetail.arrCity.code }}
                                                                            </span>
                                                                        </v-chip>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                        <br :key="`b${index}`">
                                                    </template>
                                                </template>
                                            </div>
                                        </template>
                                        <!-- END Re-Issue Flight Detail -->
                                        <!-- Flight Booking Detail -->
                                        <template> <!--ReIssue Check -->
                                            <div v-if="itemFlight.depFlight && itemFlight.depFlight.length > 0">
                                                <div style="height: 10px;"></div>
                                                <v-divider></v-divider>
                                                <div style="height: 10px;"></div>
                                                <v-row>
                                                    <v-col cols="8">
                                                        <span class="badge-info f-text-16 font-W400"
                                                            style="padding: 1px; border: solid #00adef 1.5px; background-color: #f2fcff;">
                                                            <span class="f-text-16 font-W400" style="color: #00AEEF">
                                                                &nbsp; PNR {{ itemFlight.PNR ? itemFlight.PNR : 'Pending' }}
                                                                <span v-if="itemFlight.isFlightOffline && itemFlight.IsReIssue === 0"
                                                                 style="color: red;" class="f-text-16 font-W400" >
                                                                    Offline
                                                                </span>
                                                                <span v-else-if="itemFlight.IsReIssue === 1" style="color: red;" class="f-text-16 font-W400">
                                                                    Old Booking
                                                                </span>
                                                                &nbsp;
                                                            </span>
                                                        </span>
                                                    </v-col>
                                                    <template v-if="itemFlight.IsReIssue === 0 || itemFlight.ReIssueSelected === 1">
                                                        <v-col cols="2"></v-col>
                                                        <v-col cols="2">
                                                            <div class="text-center" style="float: center" v-if="travel.status === txtApproveFinal || travel.status === 'Re-Issue'">
                                                                <v-btn x-small width="4" icon
                                                                    @click="ReIssueFlight(itemFlight, itemFlight.isFlightOffline ? 'Offline' : 'Online')">
                                                                    <v-img :src="require('@/assets/icons/EditDetail.svg')"></v-img>
                                                                </v-btn>
                                                            </div>
                                                            <div class="text-center" style="float: center" v-else>
                                                                <v-btn x-small width="4" icon
                                                                    @click="RemoveFlight('alert', itemFlight, travelers)">
                                                                    <v-img :src="require('@/assets/icons/deleteInfo.svg')"></v-img>
                                                                </v-btn>
                                                            </div>
                                                        </v-col>
                                                    </template>
                                                </v-row>
                                                <div class="f-text-detail pl-4" v-if="itemFlight.TravelWith && itemFlight.TravelWith !== '0'" style="background-color: #FFDAE7;">
                                                    {{ `Travel With: ${travelers.filter(x => x.emp_ID === itemFlight.TravelWith)[0].fullname}` }}
                                                </div>
                                                <span style="height: 10px;"></span>
                                                <span v-if="!itemFlight.isFlightOffline"
                                                    class="f-text-16 font-W400" style="font-size: 0.8rem;">
                                                    <strong style="font-size: 0.8rem;" class="">
                                                        {{ itemFlight.depFlight[0].depCity.name.split(',')[0] }}
                                                        ({{ itemFlight.depFlight[0].depCity.code }})
                                                    </strong>
                                                    <template v-if="itemFlight.retFlight !== null && itemFlight.retFlight.length > 0">
                                                        <img :src="require('@/assets/icons/tweenArrows.svg')" class="mt-3" alt=""
                                                            width="10px" />
                                                        <strong style="font-size: 0.8rem;" class="">
                                                            {{ itemFlight.retFlight[0].depCity.name.split(',')[0] }}
                                                            ({{ itemFlight.retFlight[0].depCity.code }})
                                                        </strong>
                                                    </template>
                                                </span>
                                            <span class="f-text-16 font-W400" style="font-size: 0.8rem;" v-else>
                                                <strong style="font-size: 0.8rem;" class="hidden-sm-and-down">
                                                    {{ itemFlight.depFlight[0].depCity.name.split(',')[0] }}
                                                    ({{ itemFlight.depFlight[0].depCity.code }})
                                                </strong>
                                                <template v-if="itemFlight.retFlight !== null && itemFlight.retFlight.length > 0">
                                                    <img :src="require('@/assets/icons/tweenArrows.svg')" style="margin-top: 10px;"
                                                        alt="" />
                                                    <strong style="font-size: 0.8rem;" class="hidden-sm-and-down">
                                                        {{ itemFlight.retFlight[0].depCity.name.split(',')[0] }}
                                                        ({{ itemFlight.retFlight[0].depCity.code }})
                                                    </strong>
                                                </template>
                                                <template v-else>
                                                    <img :src="require('@/assets/icons/Arrow_Left.svg')" style="margin-top: 10px;"
                                                        alt="" width="10px" />
                                                    <strong style="font-size: 0.8rem;" class="hidden-sm-and-down">
                                                        {{ itemFlight.depFlight[0].arrCity.name.split(',')[0] }}
                                                        ({{ itemFlight.depFlight[0].arrCity.code }})
                                                    </strong>
                                                </template>
                                            </span>
                                            <br />
                                            <span v-if="itemFlight.IsReIssue === 0 && itemFlight.TKTL" class="f-text-18">
                                                Ticket Expiry: {{fixformatdate(itemFlight.TKTL, 'DD MMM YYYY HH:mm')}}
                                                <span class="f-text-16" :style="sumDate(itemFlight.TKTL) < 72 ? 'color:red' : ''">
                                                    ({{sumDate(itemFlight.TKTL) < 0 ? 0 : sumDate(itemFlight.TKTL)}} Hours)
                                                </span>
                                            </span>
                                            <br />
                                            <v-card-actions @click="showDetail(`OnlineIssue${index}`)" class="f-text-18"
                                             style="cursor: pointer; color: #00AEEF"  v-if="itemFlight.IsReIssue === 1 &&
                                              itemFlight.ReIssueSelected === 0">
                                                <v-spacer></v-spacer>
                                                {{ showOldBooking && showOldBooking === `OnlineIssue${index}` ? 'Hide Detail' : 'Show Details' }}
                                            </v-card-actions>
                                            <template v-if="itemFlight.IsReIssue === 0 || (showOldBooking && showOldBooking === `OnlineIssue${index}`)">
                                                <span class="f-text-16 font-W400">
                                                    {{ itemFlight.depFlight[0].depDisplayDateTime.shortDate }}
                                                    <template v-if="itemFlight.retFlight !== null && itemFlight.retFlight.length > 0">
                                                        -
                                                        {{ itemFlight.retFlight[0].depDisplayDateTime.shortDate }} |
                                                        <strong class="f-text-16">
                                                            {{
                                                                itemFlight.Svc_class === 'Y' ||
                                                                itemFlight.Svc_class === 'W' ||
                                                                itemFlight.Svc_class === 'M' ?
                                                                'Economy' : itemFlight.Svc_class === 'C' ?
                                                                'Business' : 'First'
                                                            }}
                                                        </strong>
                                                    </template>
                                                    <br />
                                                    Departure time <strong class="f-text-16">{{
                                                        itemFlight.depFlight[0].depDisplayDateTime.displayTime
                                                    }}</strong>
                                                    <br />
                                                    Price <strong class="f-text-16" v-if="!travel.age ||
                                                            travel.age === 'Adult'">{{ itemFlight.adtFare ?
                                                            new Intl.NumberFormat().format(itemFlight.adtFare.net) + ' THB' : 'Pending' }}
                                                        </strong>
                                                    <strong class="f-text-16"
                                                        v-else-if="itemFlight.chdPaxs && itemFlight.chdPaxs.filter(x => x.emp_ID === travel.emp_ID) !== null">
                                                        {{ itemFlight.chdFare ? new Intl.NumberFormat().format(itemFlight.chdFare.net) + ' THB' :
                                                            'Pending' }}
                                                        </strong>
                                                    <strong class="f-text-16"
                                                        v-else-if="itemFlight.infPaxs && itemFlight.infPaxs.filter(x => x.emp_ID === travel.emp_ID) !== null">
                                                        {{ itemFlight.infFare ? new Intl.NumberFormat().format(itemFlight.infFare.net) + ' THB' :
                                                            'Pending' }}
                                                        </strong>
                                                </span>
                                                <br />
                                                <span class="f-text-16">
                                                    BTT Status:
                                                    <span :style="`border: ${statusColor(itemFlight.BTTStatus)[0]}
                                                     0.5px solid; border-radius: 65px !important;
                                                      background-color:${statusColor(itemFlight.BTTStatus)[0]};
                                                       color:${statusColor(itemFlight.BTTStatus)[1]}`">
                                                        <span class="f-text-16 pr-2 pl-2">
                                                            {{ itemFlight.BTTStatus && itemFlight.BTTStatus !== null ?
                                                             itemFlight.BTTStatus : 'Booked'}}
                                                        </span>
                                                    </span>
                                                </span>
                                                <br />
                                                <div class="h-space"></div>
                                                <div class="f-text-20-b">
                                                    Departure
                                                </div>
                                                <div class="h-space"></div>
                                                <template v-for="(itemFlightDetail, index) in itemFlight.depFlight">
                                                    <div :key="`D${index}`">
                                                        <v-card elevation="0"
                                                            :style="!itemFlight.isFlightOffline &&
                                                             ReIssueBooking.filter(x => x.PNR === itemFlight.PNR).length > 0 ?
                                                            'background-color: #efefef; border-radius: 5px;' : ''">
                                                            <v-card-text>
                                                                <table aria-label="" style="width: 100%">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tr>
                                                                        <td colspan="4">
                                                                            <span>
                                                                                <strong class="f-text-18 f-W500" style="float:right;">
                                                                                    {{
                                                                                        itemFlightDetail.depDisplayDateTime.shortDate
                                                                                    }}
                                                                                </strong>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="width: 20px">
                                                                            <v-img alt="user"
                                                                                v-if="itemFlightDetail.airline.code && itemFlightDetail.airline.name"
                                                                                :src="require('@/assets/logoAirline/square/' + itemFlightDetail.airline.code + '.png')"
                                                                                width="30" />
                                                                        </td>
                                                                        <td>
                                                                            <span class="ml-2 f-text-18 hidden-sm-and-down">
                                                                                {{ itemFlightDetail.airline.name }}
                                                                            </span>
                                                                            <span class="ml-2 f-text-18 hidden-md-and-up">
                                                                                {{ itemFlightDetail.airline.code }}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <strong class="f-text-18" style="float:right;">
                                                                                {{ itemFlightDetail.airline.code }}
                                                                                {{ itemFlightDetail.flightNumber }}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <v-row>
                                                                    <v-col class="text-center" cols="4" md="3">
                                                                        <span class="f-text-18">
                                                                            {{ itemFlightDetail.depDisplayDateTime.displayTime }}
                                                                        </span>
                                                                        <br />
                                                                        <v-chip x-small color="#A1D1FD">
                                                                            <span>
                                                                                <div class="f-text-18" style="font-size: .5rem;">
                                                                                    {{ itemFlightDetail.depCity.code }}
                                                                                </div>
                                                                            </span>
                                                                        </v-chip>
                                                                    </v-col>
                                                                    <v-col class="text-center" cols="4" md="6">
                                                                        <div style="height: 13px;"></div>
                                                                        <span>
                                                                            <v-img :src="require('@/assets/icons/ArrowAirline.svg')" />
                                                                        </span>
                                                                    </v-col>
                                                                    <v-col cols="4" md="3" class="text-center">
                                                                        <span class="f-text-18">
                                                                            {{ itemFlightDetail.arrDisplayDateTime.displayTime }}
                                                                        </span>
                                                                        <br />
                                                                        <v-chip x-small color="#A1D1FD">
                                                                            <span class="f-text-18">
                                                                                {{ itemFlightDetail.arrCity.code }}
                                                                            </span>
                                                                        </v-chip>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                        <br />
                                                    </div>
                                                </template>
                                                <br v-if="itemFlight.retFlight !== null && itemFlight.retFlight.length > 0" />
                                                <div class="f-text-20-b" v-if="itemFlight.retFlight !== null && itemFlight.retFlight.length > 0">
                                                    Return
                                                </div>
                                                <div class="h-space"></div>
                                                <template v-for="(itemFlightDetail, index) in itemFlight.retFlight">
                                                    <div :key="`O${index}`">
                                                        <v-card elevation="0" :style="!itemFlight.isFlightOffline && ReIssueBooking.filter
                                                        (x => x.PNR === itemFlight.PNR).length > 0 ?
                                                            'background-color: #efefef; border-radius: 5px; width: 100%' : 'width: 100%;'"
                                                            v-if="itemFlight.retFlight !== null && itemFlight.retFlight.length > 0">
                                                            <v-card-text>
                                                                <table style="width: 100%" aria-label="">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tr>
                                                                        <td colspan="4">
                                                                            <strong style="float:right;" class="f-text-18">
                                                                                {{ itemFlightDetail.depDisplayDateTime.shortDate }}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="width: 20px;">
                                                                            <v-img alt="user"
                                                                                v-if="itemFlightDetail.airline.code && itemFlightDetail.airline.name"
                                                                                :src="require('@/assets/logoAirline/square/' + itemFlightDetail.airline.code + '.png')"
                                                                                width="30" />
                                                                        </td>
                                                                        <td>
                                                                            <span class="ml-2 f-text-18 hidden-sm-and-down">
                                                                                {{ itemFlightDetail.airline.name }}
                                                                            </span>
                                                                            <strong class="ml-2 f-text-18 hidden-md-and-up">
                                                                                {{ itemFlightDetail.airline.code }}
                                                                            </strong>
                                                                        </td>
                                                                        <td>
                                                                            <strong class="f-text-18" style="float:right;">
                                                                                {{ itemFlightDetail.airline.code }}
                                                                                {{ itemFlightDetail.flightNumber }}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <v-row>
                                                                    <v-col class="text-center" cols="4" md="3">
                                                                        <span class="f-text-18">
                                                                            {{ itemFlightDetail.depDisplayDateTime.displayTime }}
                                                                        </span>
                                                                        <br />
                                                                        <v-chip x-small color="#A1D1FD" style="font-size: .5rem">
                                                                            <span class="f-text-18">
                                                                                {{ itemFlightDetail.depCity.code }}
                                                                            </span>
                                                                        </v-chip>
                                                                    </v-col>
                                                                    <v-col cols="4" md="6">
                                                                        <div style="height: 13px;"></div>
                                                                        <span>
                                                                            <v-img :src="require('@/assets/icons/ArrowAirline.svg')" />
                                                                        </span>
                                                                    </v-col>
                                                                    <v-col class="text-center" cols="4" md="3">
                                                                        <span class="f-text-18">
                                                                            {{ itemFlightDetail.arrDisplayDateTime.displayTime }}
                                                                        </span>
                                                                        <br />
                                                                        <v-chip x-small color="#A1D1FD" style="font-size: .5rem">
                                                                            <span class="f-text-18">
                                                                                {{ itemFlightDetail.arrCity.code }}
                                                                            </span>
                                                                        </v-chip>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                        <br />
                                                    </div>
                                                </template>
                                                <v-card elevation="0" :style="!itemFlight.isFlightOffline &&
                                                 ReIssueBooking.filter(x => x.PNR === itemFlight.PNR).length > 0 ?
                                                    'background-color: #efefef; border-radius: 5px; width: 100%' : 'width: 100%;'"
                                                    v-if="travel.flights != null && travel.flights.length > 0 && travel.flights[0].adtFare">
                                                    <v-card-text>
                                                        <span v-for="(itemBaggages, index) in travel.flights[0].adtFare.baggages"
                                                            :key="index">
                                                            <v-chip small color="#f8f9fa" class="f-text-18">
                                                                <v-img src="../../assets/icons/Suitcase.svg" style="margin: 8px;"
                                                                    width="15" />
                                                                <span
                                                                    v-if="itemBaggages.baggageUnit == 'N'">
                                                                    {{
                                                                        itemBaggages.baggageNo
                                                                    }}
                                                                    piece
                                                                </span>
                                                                <span
                                                                    v-else-if="itemBaggages.baggageUnit == 'W' || typeof(parseInt(itemBaggages.baggageUnit)) === 'number'">
                                                                    {{
                                                                        itemBaggages.baggageNo
                                                                    }}
                                                                    kg
                                                                </span>
                                                            </v-chip>
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                                <v-card elevation="0" v-if="!itemFlight.isFlightOffline" :style="!itemFlight.isFlightOffline &&
                                                 ReIssueBooking.filter(x => x.PNR === itemFlight.PNR).length > 0 ?
                                                    'background-color: #efefef; border-radius: 5px; width: 100%' : 'width: 100%;'">
                                                    <br />
                                                    <v-card-text>
                                                        <strong class="f-text-18">
                                                            Special Request
                                                        </strong>
                                                        <br />
                                                        <span class="f-text-16">
                                                            Preferred Seat
                                                        </span>
                                                        <div
                                                            style="border: solid 0.5px #f4f4f4; border-radius: 5px; background: #f4f4f4;">
                                                            <span class="ml-2 f-text-16">
                                                                <template v-if="!travel.age || itemFlight.adtPaxs && itemFlight.adtPaxs.length > 0 &&
                                                                    itemFlight.adtPaxs.filter(x => x.emp_id === travel.emp_ID)">
                                                                    {{
                                                                        itemFlight.adtPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID).length > 0 &&
                                                                        !itemFlight.adtPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID).seatRequest ?
                                                                        dataSeat.filter(y => y.Code === itemFlight.adtPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID)
                                                                        [0].seatRequest)[0].Description : 'No Preferred'
                                                                    }}
                                                                </template>
                                                                <template
                                                                    v-else-if="itemFlight.chdPaxs && itemFlight.chdPaxs.length > 0 &&
                                                                            itemFlight.chdPaxs.filter(x => x.emp_id === travel.emp_ID) !== null">
                                                                    {{
                                                                        itemFlight.chdPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID).length > 0 &&
                                                                        !itemFlight.chdPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID).seatRequest ?
                                                                        dataSeat.filter(y => y.Code === itemFlight.chdPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID)
                                                                        [0].seatRequest)[0].Description : 'No Preferred'
                                                                    }}
                                                                </template>
                                                                <template
                                                                    v-else-if="itemFlight.infPaxs && itemFlight.infPaxs.length > 0
                                                                            && itemFlight.infPaxs.filter(x => x.emp_id === travel.emp_ID) !== null">
                                                                    {{
                                                                        itemFlight.infPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID).length > 0 &&
                                                                        !itemFlight.infPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID).seatRequest ?
                                                                        dataSeat.filter(y => y.Code === itemFlight.infPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID)
                                                                        [0].seatRequest)[0].Description : 'No Preferred'
                                                                    }}
                                                                </template>
                                                            </span>
                                                        </div>
                                                        <div style="height: 8px"></div>
                                                        <span class="f-text-16">
                                                            Special Meal
                                                        </span>
                                                        <div
                                                            style="border: solid 0.5px #f4f4f4; border-radius: 5px; background: #f4f4f4;">
                                                            <span class="ml-2 f-text-16"
                                                                v-if="dataSpecialMeal && dataSpecialMeal.length > 0">
                                                                <template
                                                                    v-if="itemFlight.adtPaxs && itemFlight.adtPaxs.length > 0 &&
                                                                        itemFlight.adtPaxs.filter(x => x.emp_id === travel.emp_ID).length > 0">
                                                                    {{ dataSpecialMeal.filter(y => y.MealCode ===
                                                                        itemFlight.adtPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID)[0].mealRequest) != '' ?
                                                                        dataSpecialMeal.filter(y => y.MealCode === itemFlight.adtPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID)
                                                                        [0].mealRequest)[0].Description : 'No Preferred'
                                                                    }}
                                                                </template>
                                                                <template
                                                                    v-else-if="itemFlight.chdPaxs && itemFlight.chdPaxs.length > 0
                                                                            && itemFlight.chdPaxs.filter(x => x.emp_id === travel.emp_ID).length > 0">
                                                                    {{
                                                                        dataSpecialMeal.filter(y => y.MealCode ===
                                                                            itemFlight.chdPaxs.filter
                                                                                (x => x.emp_id === travel.emp_ID)[0].mealRequest) != '' ?
                                                                        dataSpecialMeal.filter(y => y.MealCode === itemFlight.chdPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID)
                                                                        [0].mealRequest)[0].Description : 'No Preferred'
                                                                    }}
                                                                </template>
                                                                <template
                                                                    v-else-if="itemFlight.infPaxs && itemFlight.infPaxs.length > 0
                                                                            && itemFlight.infPaxs.filter(x => x.emp_id === travel.emp_ID).length > 0">
                                                                    {{
                                                                        dataSpecialMeal.filter(y => y.MealCode ===
                                                                            itemFlight.infPaxs.filter
                                                                                (x => x.emp_id === travel.emp_ID)[0].mealRequest) != '' ?
                                                                        dataSpecialMeal.filter(y => y.MealCode === itemFlight.infPaxs.filter
                                                                            (x => x.emp_id === travel.emp_ID)
                                                                        [0].mealRequest)[0].Description : 'No Preferred'
                                                                    }}
                                                                </template>
                                                            </span>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </template>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div v-else>
                            <v-btn small width="100%" elevation="0" style="background-color: white;" @click="addFlight()">
                                <img :src="require('@/assets/icons/PrimaryAdd.svg')" width="20" alt="" />
                                <span class="ml-2 f-text-20 font-W400">Add Flight Schedule</span>
                            </v-btn>
                        </div>
                    </div>
                    <!--/Flight-->
                    <br v-if="!NoRequireServices" />
                    <!--Hotel-->
                    <div class="bgcard" v-if="!NoRequireServices">
                        <table style="width: 100%" aria-label="">
                            <thead>
                                <tr>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr>
                                <td style="width: 20px">
                                    <span>
                                        <v-badge :content="travel.hotels.length" color="#F9B50B"
                                            v-if="travel.hotels != null && travel.hotels.length > 0">
                                        </v-badge>
                                        <div class="f-text-20-b font-W400" v-else>
                                            Hotel
                                        </div>
                                    </span>
                                </td>
                                <td v-if="travel.hotels != null && travel.hotels.length > 0">
                                    <div class="f-text-20-b font-W400">
                                        Hotel
                                    </div>
                                </td>
                                <td v-if="travel.hotels != null && travel.hotels.length > 0">
                                    <v-menu>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-img :src="require('@/assets/icons/Setting.svg')"
                                                style="float: right; cursor: pointer;" width="16" v-bind="attrs" small
                                                v-on="on" />
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="(settingHotel, i) in settingHotels" :key="i">
                                                <v-list-item-title style="cursor: pointer;" @click="newHotelToCard(null)">{{
                                                    settingHotel.setting
                                                }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </table>
                        <div v-if="travel.hotels != null && travel.hotels.length > 0">
                            <v-row v-for="(travelItem, n) in travel.hotels" :key="n">
                                <v-col v-if="travelItem.ReqServ !== 'No Service Required'">
                                    <template v-if="travelItem.ReIssueSelected">
                                        <div class="h-space"></div>
                                        <span style="padding: 5px 10px 0px 10px; background-color: #00c7f6; color: white;
                                        border-radius: 8px 8px 0px 0px;" class="f-text-18">
                                            Re-issue Hotel
                                        </span>
                                    </template>
                                    <v-row :style="travelItem.ReIssueSelected ? 'border: 1px solid #5edfff; padding: 5px; border-radius: 5px;' : ''">
                                        <v-col>
                                            <v-row>
                                                <v-col>
                                                    <v-divider style="margin-top: 10px; margin-bottom: 10px;"></v-divider>
                                                    <span class="badge-info"
                                                        style="padding: 1px; border: solid #00adef 1.5px; background-color: #f2fcff;">
                                                        <span class="f-text-18" style="color: #00AEEF">
                                                            &nbsp; Booking Ref. {{ travelItem.BookingType === "Offline" ? "Pending" : travelItem.HTNo }}
                                                            <span style="color: red;" v-if="travelItem.BookingType === 'Offline'">Offline</span>
                                                            &nbsp;
                                                        </span>
                                                    </span>
                                                    <span style="float: right;">
                                                        <v-btn x-small width="4" class="pr-2"
                                                        v-if="travel.status === txtApproveFinal || travel.status === 'Re-Issue'" icon
                                                        @click="newHotelToCard(travelItem)">
                                                            <v-img :src="require('@/assets/icons/EditDetail.svg')"></v-img>
                                                        </v-btn>
                                                        <v-btn x-small width="4" class="pr-2" v-else icon
                                                            @click="RemoveHotel('alert', travelItem, travel)">
                                                            <v-img :src="require('@/assets/icons/deleteInfo.svg')"></v-img>
                                                        </v-btn>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-2">
                                                <v-col>
                                                    <span style="font-size: 1em;" class="span-W500">
                                                        <strong>{{ travelItem.HotelName }}</strong>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col class="d-flex column-flex">
                                                    <span class="mr-2">
                                                        <v-chip color="#004FBC" draggable x-small dark>Hotel</v-chip>
                                                    </span>
                                                    <span>
                                                        <v-rating length="5" :value="travelItem.HTL_RATING" readonly color="#F9B50B"
                                                            dense small></v-rating>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-2">
                                                <v-col>
                                                    <span class="span-W500">
                                                        {{ travelItem.Description ? travelItem.Description.length > 150 ?
                                                            `${travelItem.Description.substr(0, 210)}...` : travelItem.Description : ""
                                                        }}
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-2">
                                                <v-col>
                                                    <span style="color: #747678;">
                                                        <span class="font-W400">Check-In</span>
                                                        <span class="font-W700">
                                                            {{ checkInDateFormat(travelItem.CheckinDateStart) }}
                                                        </span>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-2">
                                                <v-col>
                                                    <v-img v-if="travelItem.HotelFilePath" width="100%" class="text-center"
                                                        height="100px" :src="travelItem.HotelFilePath" />
                                                    <v-img v-else width="100%" class="text-center"
                                                        :src="require('@/assets/image/cover_hotel.png')" />
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-2">
                                                <v-col>
                                                    <v-card class="card" elevation="0">
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col>
                                                                    <span style="font-size: 14px;">
                                                                        ({{ travelItem.NumberOfRoom }}x) {{ travelItem.RoomType }}
                                                                    </span>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters class="mt-2" align="center">
                                                                <v-col cols="1">
                                                                    <v-img :src="require('@/assets/icons/bed_1.svg')" min-width="20"
                                                                        class="shrink"></v-img>
                                                                </v-col>
                                                                <v-col>
                                                                    <span style="font-size: 14px;"
                                                                        class="d-flex align-center ml-2 font-W700">
                                                                        {{ travelItem.BedType }}
                                                                    </span>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters class="mt-2" align="center">
                                                                <v-col cols="1">
                                                                    <v-img :src="require('@/assets/icons/man_1.svg')" min-width="20"
                                                                        class="shrink"></v-img>
                                                                </v-col>
                                                                <v-col>
                                                                    <span style="font-size: 14px;"
                                                                        class="d-flex align-center ml-2 font-W700">
                                                                        {{ travelItem.Occupancy }} Guest
                                                                    </span>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-2">
                                                <v-col>
                                                    <v-card class="card" elevation="0">
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col>
                                                                    <span style="font-size: 14px;">
                                                                        <strong>Amount</strong>
                                                                    </span>
                                                                </v-col>
                                                                <v-col class="text-right">
                                                                    <span style="font-size: 14px;">
                                                                        <strong>
                                                                            {{ travelItem.Amount.toLocaleString('th', {
                                                                                minimumFractionDigits: 2, maximumFractionDigits: 2
                                                                            }) }}
                                                                            THB
                                                                        </strong>
                                                                    </span>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col v-else>
                                    <v-card elevation="0" class="card">
                                        <v-card-text>
                                            <v-row>
                                                <v-col>
                                                    <span>{{ travelItem.ReqServ }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-else>
                            <v-btn small width="100%" elevation="0" style="background-color: white;"
                                @click="newHotelToCard(null)">
                                <img :src="require('@/assets/icons/PrimaryAdd.svg')" width="20" alt="" />
                                <span class="ml-2 f-text-20-b font-W400">Add Hotel Schedule</span>
                            </v-btn>
                        </div>
                    </div>
                    <!--/Hotel-->
                    <br v-if="!NoRequireServices" />
                    <!--Passport-->
                    <div class="bgcard" v-if="!NoRequireServices">
                        <table style="width: 100%" aria-label="">
                            <thead>
                                <tr>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr>
                                <td style="width: 20px">
                                    <span class="f-text-20-b font-W400">
                                        Passport
                                    </span>
                                </td>
                                <td v-if="empProfile != null && travel.passport !== null && empProfile.EMPLOYEE_ID != null && false"> <!--hidden this text from v-if="false"-->
                                    <v-menu v-if="empProfile.POSITION.indexOf('Secretary') != -1 ||
                                        empProfile.EMPLOYEE_ID === travel.CreateBy ||
                                        travel.emp_ID === empProfile.EMPLOYEE_ID">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-img :src="require('@/assets/icons/Setting.svg')"
                                                style="float: right; cursor: pointer;" width="16" v-bind="attrs" small
                                                v-on="on" />
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="(itemsettingpassport, i) in settingpassport" :key="i">
                                                <v-list-item-title style="cursor: pointer;" @click="addPassport()">{{
                                                    itemsettingpassport.setting
                                                }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </table>
                        <div>
                            <template v-if="empProfile && empProfile.EMPLOYEE_ID && travel.passport != null">
                                <span elevation="0" v-for="(item, index) in travel.passport" :key="index">
                                    <v-card elevation="0" style="background-color: white;">
                                        <v-card-text v-if="item && item.PassportType !== 'Required'">
                                            <v-row>
                                                <v-col>
                                                    <strong class="f-text-20">
                                                        {{ item.PassportType }}
                                                    </strong>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-btn x-small width="4" icon @click="removePandV(item, 'Passport')">
                                                        <v-img :src="require('@/assets/icons/deleteInfo.svg')"></v-img>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col>
                                                    <span class="f-text-16">
                                                        Passport No: <strong class="f-text-16">{{ empProfile != null &&
                                                            empProfile.POSITION.indexOf('Secretary') != -1 ||
                                                            empProfile.EMPLOYEE_ID === travel.emp_ID ?
                                                            item.PassportNo : 'XX-XXXXXXX'
                                                        }}</strong>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col>
                                                    <span class="f-text-16">
                                                        Expiry Date: <strong class="f-text-16">{{ empProfile != null &&
                                                            empProfile.POSITION.indexOf('Secretary') != -1 ||
                                                            empProfile.EMPLOYEE_ID === travel.emp_ID ?
                                                            formatdate(item.ExpiryDate) : 'XX XXX XXXX'
                                                        }}</strong>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col>
                                                    <span class="f-text-16">
                                                        Request New Passport: <strong class="f-text-16">{{
                                                            item.RequestNewPassport }}</strong>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-text v-else>
                                            <v-row>
                                                <v-col>
                                                    <strong class="f-text-20">
                                                        Request New Passport
                                                    </strong>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-btn x-small width="4" icon @click="removePandV(item, 'Passport')">
                                                        <v-img :src="require('@/assets/icons/deleteInfo.svg')"></v-img>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                    <br />
                                </span>
                            </template>
                            <v-btn small width="100%" elevation="0" style="background-color: white;" @click="addPassport()">
                                <img :src="require('@/assets/icons/PrimaryAdd.svg')" width="20" alt="" />
                                <span class="ml-2">Add Passport</span>
                            </v-btn>
                        </div>
                    </div>
                    <!--/Passport-->
                    <br v-if="!NoRequireServices" />
                    <!--Visa-->
                    <div class="bgcard" v-if="!NoRequireServices">
                        <table style="width: 100%" aria-label="">
                            <thead>
                                <tr>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="width: 20px">
                                        <span>
                                            <div class="f-text-20-b font-W400">
                                                Visa
                                            </div>
                                        </span>
                                    </td>
                                    <td v-if="empProfile != null && travel.visa !== null && travel.emp_ID != null && false"> <!--hidden this text from v-if="false"-->
                                        <v-menu v-if="empProfile.POSITION.indexOf('Secretary') != -1 ||
                                            empProfile.EMPLOYEE_ID === travel.CreateBy ||
                                            travel.emp_ID === empProfile.EMPLOYEE_ID">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-img :src="require('@/assets/icons/Setting.svg')"
                                                    style="float: right; cursor: pointer;" width="16" v-bind="attrs" small
                                                    v-on="on" />
                                            </template>
                                            <v-list>
                                                <v-list-item v-for="(itemsettingvisa, i) in settingvisa" :key="i">
                                                    <v-list-item-title style="cursor: pointer;" @click="addVisa()">{{
                                                        itemsettingvisa.setting
                                                    }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <template v-if="empProfile && empProfile.EMPLOYEE_ID && travel.visa != null">
                                <span elevation="0" v-for="(item, index) in travel.visa" :key="index">
                                    <v-card elevation="0" style="background-color: white;">
                                        <v-card-text v-if="item && item.VisaType !== 'Required'">
                                            <v-row>
                                                <v-col>
                                                    <strong class="f-text-20">
                                                        {{ item.VisaType }}
                                                    </strong>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-btn x-small width="4" icon @click="removePandV(item, 'Visa')">
                                                        <v-img :src="require('@/assets/icons/deleteInfo.svg')"></v-img>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col>
                                                    <span class="f-text-18">
                                                        Country's Visa: <strong class="f-text-18">{{ item.Country }}</strong>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col>
                                                    <span class="f-text-18">
                                                        Visa No: <strong class="f-text-18">{{ empProfile != null &&
                                                            empProfile.POSITION.indexOf('Secretary') != -1 ||
                                                            empProfile.EMPLOYEE_ID === travel.emp_ID ?
                                                            item.VisaNo : 'XX-XXXXXXX'
                                                        }}</strong>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col>
                                                    <span class="f-text-18">
                                                        Issue Date: <strong class="f-text-18">{{ empProfile != null &&
                                                            empProfile.POSITION.indexOf('Secretary') != -1 ||
                                                            empProfile.EMPLOYEE_ID === travel.emp_ID ?
                                                            formatdate(item.IssueDate) : 'XX XXX XXXX'
                                                        }}</strong>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col>
                                                    <span class="f-text-18">
                                                        Expiry Date: <strong class="f-text-18">{{ formatdate(item.ExpiryDate)
                                                        }}</strong>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-text v-else>
                                            <v-row>
                                                <v-col>
                                                    <span>
                                                        <h3 style="text-align: center;">
                                                            Request New Visa
                                                        </h3>
                                                    </span>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-btn x-small width="4" icon @click="removePandV(item, 'Visa')">
                                                        <v-img :src="require('@/assets/icons/deleteInfo.svg')"></v-img>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                    <br />
                                </span>
                            </template>
                            <v-btn small width="100%" v-if="empProfile != null" elevation="0"
                                style="background-color: white;" @click="addVisa()">
                                <img :src="require('@/assets/icons/PrimaryAdd.svg')" width="20" alt="" />
                                <span class="ml-2 f-text-20">Add Visa</span>
                            </v-btn>
                        </div>
                    </div>
                    <!--/Visa-->
                    <br v-if="!NoRequireServices" />
                    <!--Travel Expense-->
                    <div v-if="!travel.CreateBy && travel.canTravelExpense" class="bgcard">
                        <table style="width: 100%" aria-label="">
                            <thead>
                                <tr>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr>
                                <td style="width: 150px">
                                    <v-row>
                                        <v-col>
                                            <span class="f-text-20-b font-W400">
                                                Travel Expense
                                            </span>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </table>
                        <template
                            v-if="empProfile && empProfile.EMPLOYEE_ID && travel.travelExpens != null && travel.travelExpens.length > 0">
                            <v-card elevation="0">
                                <v-card-text>
                                    <v-row v-for="(itemTravelExpe, iTravelExpe) in travel.travelExpens" :key="iTravelExpe"
                                        no-gutters class="mb-1">
                                        <v-col>
                                            {{ itemTravelExpe.Description }}
                                        </v-col>
                                        <v-col class="text-right">
                                            <v-btn x-small width="4" icon
                                                @click="removeTravelExpense('alert', itemTravelExpe)">
                                                <v-img :src="require('@/assets/icons/deleteInfo.svg')"></v-img>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </template>
                        <template v-else>
                            <v-btn small width="100%" elevation="0" style="background-color: white;"
                                @click="addTravelExpen()"><img :src="require('@/assets/icons/PrimaryAdd.svg')" width="20"
                                    alt="" />
                                <span class="ml-2 f-text-20">Add Travel Expense</span>
                            </v-btn>
                        </template>
                    </div>
                    <!--/Travel Expense-->
                </v-card-text>
                <v-card-actions>
                    <v-btn color="info" outlined @click="OpenTravelCard()">
                        <img :src="require('@/assets/icons/deleteInfo.svg')" width="20" alt="" />
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="info" @click="travelReqPrintPage(travel)"><img :src="require('@/assets/icons/Print.svg')"
                            width="20" alt="" /></v-btn>
                </v-card-actions>
                <v-card-actions v-if="travel.canBudgetHolder === 0 && travel.canLineApproved === 1" class="mb-2" >
                    <v-row class="text-center" style="pointer-events: auto;">
                        <v-col>
                            <v-btn class="ma-1" outlined color="#B44545" elevation="0" @click="actionStatusMytask('Reject', travel)" dark>Reject</v-btn>
                            <v-btn class="ma-1" color="#F0BC2D" elevation="0" @click="actionStatusMytask('Return', travel)" dark>Return</v-btn>
                            <v-btn class="btn-success ma-1" elevation="0" @click="actionStatusMytask('Approve', travel)" dark>Approve</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
            <v-dialog persistent v-if="sflightdialog" v-model="sflightdialog">
                <FlightBooking :selectTraveler="travel" :airline="airline" :flightAction="flightAction" :flightType="flightType"
                    :travelers="travelers.filter(elm => elm.TravelCard != null && elm.TravelCard.IsDelete == 0)"
                    :key="reloading" :coperateData="coperateData" @closeflightbook="sflightdialog = $event" />
            </v-dialog>
            <v-dialog width="500" v-model="sRemoveFlight.RemoveFlight">
                <v-card>
                    <v-card-title>
                        Remove
                    </v-card-title>
                    <v-card-text>
                        would you like to remove flight
                        <span v-if="sRemoveFlight.RemoveFlight">
                            <strong>
                                {{ sRemoveFlight.focusFlight.PNR }}
                            </strong>
                        </span>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small text @click="sRemoveFlight.RemoveFlight = false">Cancel</v-btn>
                        <v-btn small color="red" style="color: white;" @click="RemoveFlight('remove', null, null)">
                            Continue</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="showPassport" width="500">
                <v-card>
                    <v-card-title>
                        Select Passport
                    </v-card-title>
                    <v-card-text>
                        <span>
                            <v-card elevation="0">
                                <v-card-text v-if="passports != null && passports.length > 0">
                                    <v-row>
                                        <span v-for="(itemPassport, index) in passports" :key="index">
                                            <v-col md="12" sm="12" v-if="itemPassport && itemPassport.PassportType !== 'Required'">
                                                <v-card class="passport" @click="travel != null && travel.passport != null &&
                                                    travel.passport.filter(x => x.PassportOID === itemPassport.PassportOID).length > 0 ?
                                                    showPassport = true : selectPassport(travel, itemPassport)">
                                                    <v-card-title>
                                                        <span style="font-size: 16px">
                                                            {{ itemPassport.PassportType }}
                                                        </span>
                                                        <v-spacer></v-spacer>
                                                        <span style="font-size: 16px">
                                                            {{ travel != null && travel.passport != null &&
                                                                travel.passport.filter(x => x.PassportOID === itemPassport.PassportOID).length > 0 ?
                                                                'Selected' : ''
                                                            }}
                                                        </span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-divider></v-divider>
                                                    </v-card-text>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col cols="12" xs="12" sm="6" md="6">
                                                                Passport No.
                                                                <br />
                                                                <strong>
                                                                    {{ itemPassport.PassportNo }}
                                                                </strong>
                                                            </v-col>
                                                            <v-col cols="12" xs="12" sm="6" md="6">
                                                                Request New Passport
                                                                <br />
                                                                <strong>
                                                                    {{ itemPassport.RequestNewPassport }}
                                                                </strong>
                                                            </v-col>
                                                            <v-col cols="12" xs="12" sm="6" md="6">
                                                                Expiry Date
                                                                <br />
                                                                <strong>
                                                                    {{ formatdate(itemPassport.ExpiryDate) }}
                                                                </strong>
                                                            </v-col>
                                                            <v-col cols="12" xs="12" sm="6" md="6">
                                                                Country
                                                                <br />
                                                                <strong>
                                                                    {{ itemPassport.Country }}
                                                                </strong>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </span>
                                    </v-row>
                                </v-card-text>
                                <v-card-text>
                                    <v-card class="passport" :disabled="travel != null && travel.passport != null && passports !== null &&
                                        travel.passport.filter(x => x && x.PassportType === 'Required').length > 0 ? true : false">
                                        <v-card-text style="cursor: pointer;" @click="RequirePass(travel)">
                                            <v-row>
                                                <v-col class="text-center">
                                                    <h4>
                                                        Request New Passport
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </span>
                        <span>
                            <v-btn small width="100%" elevation="0" style="background-color: white;"
                                @click="openPass()"><img :src="require('@/assets/icons/PrimaryAdd.svg')" width="20"
                                    alt="" />
                                <span class="ml-2">Add New Passport</span>
                            </v-btn>
                            <v-dialog v-model="DialogPassport" width="800">
                                <Passport @DialogPassport="DialogPassport = $event" :_ID="travel.emp_ID"
                                    @PassportUpdate="passports = $event" :key="componentKey"></Passport>
                            </v-dialog>
                            <v-spacer></v-spacer>
                            <br />
                            <v-btn @click="showPassport = false" text color="info" style="float: right">
                                Cancel
                            </v-btn>
                            <br />
                        </span>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="DialogTravelCard" width="500" persistent>
                <v-card>
                    <v-card-title>
                        Remove
                    </v-card-title>
                    <v-card-text>
                        would you like to cancel Traveler Card "{{ travel.fullname }}"
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small text @click="DialogTravelCard = false">
                            Cancel
                        </v-btn>
                        <v-btn small color="error" :loading="removeloading" @click="RemoveTravel()">
                            Remove
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="showVisa" width="500">
                <v-card>
                    <v-card-title>
                        Select Visa
                    </v-card-title>
                    <v-card-text>
                        <span>
                            <v-card elevation="0">
                                <v-card-text v-if="visa != null && visa.length > 0">
                                    <v-row>
                                        <span v-for="(itemVisa, index) in visa" :key="index">
                                            <v-col md="12" sm="12" v-if="itemVisa && itemVisa.VisaType !== 'Required'">
                                                <v-card class="passport" @click="travel != null && travel.visa != null && travel.visa.length > 0 &&
                                                travel.visa.filter(x => x.VisaOID === itemVisa.VisaOID).length > 0 ?
                                                    showVisa = true : selectVisa(travel, itemVisa)">
                                                    <v-card-title>
                                                        <span style="font-size: 16px">
                                                            {{ itemVisa.VisaType }}
                                                        </span>
                                                        <v-spacer></v-spacer>
                                                        <span style="font-size: 16px">
                                                            {{ travel != null && travel.visa != null && travel.visa.length > 0 &&
                                                                travel.visa.filter(x => x.VisaOID === itemVisa.VisaOID).length > 0 ? 'Selected' : ''
                                                            }}
                                                        </span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-divider></v-divider>
                                                    </v-card-text>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col cols="12" xs="12" sm="6" md="6">
                                                                Visa No.
                                                                <br />
                                                                <strong>
                                                                    {{ itemVisa.VisaNo }}
                                                                </strong>
                                                            </v-col>
                                                            <v-col cols="12" xs="12" sm="6" md="6">
                                                                Country
                                                                <br />
                                                                <strong>
                                                                    {{ itemVisa.Country }}
                                                                </strong>
                                                            </v-col>
                                                            <v-col cols="12" xs="12" sm="6" md="6">
                                                                Issue Date
                                                                <br />
                                                                <strong>
                                                                    {{ formatdate(itemVisa.IssueDate) }}
                                                                </strong>
                                                            </v-col>
                                                            <v-col cols="12" xs="12" sm="6" md="6">
                                                                Expiry Date
                                                                <br />
                                                                <strong>
                                                                    {{ formatdate(itemVisa.ExpiryDate) }}
                                                                </strong>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </span>
                                    </v-row>
                                </v-card-text>
                                <v-card-text>
                                    <v-card class="passport" :disabled="travel != null && travel.visa != null && visa !== null &&
                                        travel.visa.filter(x => x && x.VisaType === 'Required').length > 0 ? true : false">
                                        <v-card-text style="cursor: pointer;" @click="RequireVisa(travel)">
                                            <v-row>
                                                <v-col class="text-center">
                                                    <h4>
                                                        Request New Visa
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </span>
                        <span>
                            <br />
                            <v-btn small width="100%" elevation="0" style="background-color: white;"
                                @click="openVisa()"><img :src="require('@/assets/icons/PrimaryAdd.svg')" width="20"
                                    alt="" />
                                <span class="ml-2">Add New Visa</span>
                            </v-btn>
                            <v-dialog v-model="DialogVisa" width="800">
                                <Visa @VisaUpdate="visa = $event" :_ID="travel.emp_ID" @DialogVisa="DialogVisa = $event"
                                    :key="componentKey">
                                </Visa>
                            </v-dialog>
                            <v-spacer></v-spacer>
                            <br />
                            <v-btn @click="showVisa = false" text color="info" style="float: right">
                                Cancel
                            </v-btn>
                            <br />
                        </span>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="showHotel" persistent>
                <v-card>
                    <v-card-title>
                        Hotel Booking
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-tabs v-model="tabHotel">
                                    <v-tab>
                                        <img :src="require('@/assets/icons/hotel_online.svg')" alt="" />
                                        <span class="ml-2">Search Hotel (Online Booking)</span>
                                    </v-tab>
                                    <v-tab @click="getRoomAndBedType()">
                                        <img :src="require('@/assets/icons/hotel_offline.svg')" alt="" />
                                        <span class="ml-2">Add Hotel (Offline Booking)</span>
                                    </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tabHotel">
                                    <!-- Online Start -->
                                    <v-tab-item>
                                        <v-card elevation="0" class="bgcard mt-3">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col>
                                                        <span>
                                                            <strong>Search Hotel</strong>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-2">
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <span>Country
                                                            <span style="color: red;">*</span>
                                                        </span>
                                                        <v-autocomplete outlined dense :items="CountryItem" ref="Country"
                                                            background-color="white" :rules="ruleTraveler.CountryReq"
                                                            @change="getCitiesCode()"
                                                            @input.native="getCountryCodeHotel($event.srcElement.value)"
                                                            placeholder="Country" v-model="hotelObj.Country"
                                                            item-text="Name" item-value="Code"></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                                        <span>City or Province
                                                            <span style="color: red;">*</span>
                                                        </span>
                                                        <v-autocomplete outlined dense :items="CityorProvinceItem"
                                                            background-color="white" ref="CityorProvince"
                                                            :rules="ruleTraveler.CityorProvince" item-text="text"
                                                            item-value="Code" placeholder="City or Province"
                                                            v-model="hotelObj.CityorProvince"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <span>Business Start Date</span>
                                                        <v-text-field outlined dense background-color="white"
                                                            :value="formatDateLL(BusinessStartDate)"
                                                            disabled></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                                        <span>Business End Date</span>
                                                        <v-text-field outlined dense :value="formatDateLL(BusinessEndDate)"
                                                            background-color="white" disabled></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <span>Check In
                                                            <span style="color: red;">*</span>
                                                        </span>
                                                        <v-menu v-model="menuCheckinDateStartOffline"
                                                            :close-on-content-click="false" :nudge-right="40"
                                                            transition="scale-transition" offset-y min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field outlined dense background-color="white"
                                                                    :value="formatDateLL(hotelObj.CheckinDateStart)"
                                                                    readonly v-bind="attrs" v-on="on"
                                                                    :rules="ruleTraveler.checkinDateStartReq">
                                                                    <template v-slot:append>
                                                                        <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                                    </template>
                                                                </v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="hotelObj.CheckinDateStart"
                                                                @input="menuCheckinDateStartOffline = false"
                                                                color="#00AEEF"></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                                        <span>Check Out
                                                            <span style="color: red;">*</span>
                                                        </span>
                                                        <v-menu v-model="menuCheckinDateEndOffline"
                                                            :close-on-content-click="false" :nudge-right="40"
                                                            transition="scale-transition" offset-y min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field outlined dense background-color="white"
                                                                    :value="formatDateLL(hotelObj.CheckinDateEnd)" readonly
                                                                    v-bind="attrs" v-on="on">
                                                                    <template v-slot:append>
                                                                        <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                                    </template>
                                                                </v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="hotelObj.CheckinDateEnd"
                                                                @input="menuCheckinDateEndOffline = false" color="#00AEEF"
                                                                :min="minDate"></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="12" lg="4" md="4" sm="6">
                                                        <span>Night</span>
                                                        <v-text-field outlined dense disabled v-model="sumNight"
                                                            background-color="white" type="number"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="4" md="4" sm="6">
                                                        <v-checkbox class="theme-background"
                                                        label="Share Room (All selected travellers will stay in same room)" dense></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters v-if="!isReIssue">
                                                    <v-col cols="12">
                                                        <span>Traveler</span>
                                                        <v-row align="center" justify="center">
                                                            <v-col cols="12" lg="5" md="5" sm="12">
                                                                <v-card elevation="0" class="card-radius5px">
                                                                    <v-card-text>
                                                                        <span><strong>Standby</strong></span>
                                                                        <v-data-table hide-default-footer checkbox-color="primary"
                                                                            :items="StandbyItem" item-key="fullname"
                                                                            v-model="Selectedtable" show-select
                                                                            :headers="header" mobile-breakpoint>
                                                                            <template v-slot:[`item.no`]="{ index }">
                                                                                {{ index + 1 }}
                                                                            </template>
                                                                        </v-data-table>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col cols="12" lg="2" md="2" sm="12" class="text-center">
                                                                <v-btn class="btn-table ma-1" @click="addTravelerHotel()">
                                                                    <img class="hidden-sm-and-down"
                                                                        :src="require('@/assets/icons/Arrowrightinfo.svg')"
                                                                        alt="" />
                                                                    <img class="hidden-md-and-up"
                                                                        :src="require('@/assets/icons/ArrowPrimaryUP.svg')"
                                                                        alt="" />
                                                                    <span class="ml-4" style="font-size: .8rem;">Add</span>
                                                                    <span class="ml-4"></span>
                                                                </v-btn><br class="hidden-sm-and-down">
                                                                <v-btn class="btn-table ma-1"
                                                                    @click="removetravelerHotel()">
                                                                    <img class="hidden-sm-and-down"
                                                                        :src="require('@/assets/icons/ArrowLeftInfo.svg')"
                                                                        alt="" />
                                                                    <img class="hidden-md-and-up"
                                                                        :src="require('@/assets/icons/ArrowPrimaryDown.svg')"
                                                                        alt="" />
                                                                    <span class="ml-1"
                                                                        style="font-size: .8rem;">Remove</span>
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col cols="12" lg="5" md="5" sm="12">
                                                                <v-card elevation="0" class="card-radius5px">
                                                                    <v-card-text>
                                                                        <span><strong>Selected</strong></span>
                                                                        <v-data-table hide-default-footer checkbox-color="primary"
                                                                            :items="SelectedItem" item-key="fullname"
                                                                            v-model="Standbytable" show-select
                                                                            :headers="header" mobile-breakpoint>
                                                                            <template v-slot:[`item.no`]="{ index }">
                                                                                {{ index + 1 }}
                                                                            </template>
                                                                        </v-data-table>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <!-- Online End -->
                                    <!-- Offline Start -->
                                    <v-tab-item>
                                        <v-card elevation="0" class="bgcard mt-3">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col>
                                                        <span>
                                                            <strong>Hotel</strong>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-2 d-flex flex-column">
                                                    <v-col cols="12" lg="6" md="6">
                                                        <span>Service Requirement
                                                            <span style="color: red;">*</span>
                                                        </span>
                                                        <v-select outlined dense :items="ReqServItem" ref="ReqServ"
                                                            background-color="white" :rules="ruleTraveler.ReqServReq"
                                                            placeholder="No Service Required"
                                                            v-model="hotelObj.ReqServ"></v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters v-if="hotelObj.ReqServ !== 'No Service Required'">
                                                    <v-col cols="12" lg="5" md="5" sm="5">
                                                        <span>Check In
                                                            <span style="color: red;">*</span>
                                                        </span>
                                                        <v-menu v-model="menuCheckinDateStartOnline"
                                                            :close-on-content-click="false" :nudge-right="40"
                                                            transition="scale-transition" offset-y min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field outlined dense background-color="white"
                                                                    :value="formatDateLL(hotelObj.CheckinDateStart)"
                                                                    readonly v-bind="attrs" v-on="on"
                                                                    :rules="ruleTraveler.checkinDateStartReq">
                                                                    <template v-slot:append>
                                                                        <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                                    </template>
                                                                </v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="hotelObj.CheckinDateStart"
                                                                @input="menuCheckinDateStartOnline = false"
                                                                color="#00AEEF"></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" lg="5" md="5" sm="5" class="pl-lg-2 pl-md-2 pl-sm-2">
                                                        <span>Check Out
                                                            <span style="color: red;">*</span>
                                                        </span>
                                                        <v-menu v-model="menuCheckinDateEndOnline"
                                                            :close-on-content-click="false" :nudge-right="40"
                                                            transition="scale-transition" offset-y min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field outlined dense background-color="white"
                                                                    :value="formatDateLL(hotelObj.CheckinDateEnd)" readonly
                                                                    v-bind="attrs" v-on="on">
                                                                    <template v-slot:append>
                                                                        <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                                    </template>
                                                                </v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="hotelObj.CheckinDateEnd"
                                                                @input="menuCheckinDateEndOnline = false" color="#00AEEF"
                                                                :min="minDate"></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" lg="2" md="2" sm="2" class="pl-lg-2 pl-md-2 pl-sm-2">
                                                        <span>Night</span>
                                                        <v-text-field outlined dense v-model="sumNight" type="number"
                                                            background-color="white" disabled></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters v-if="hotelObj.ReqServ !== 'No Service Required'">
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <span>Country
                                                            <span style="color: red;">*</span>
                                                        </span>
                                                        <v-autocomplete outlined dense :items="CountryItem" ref="Country"
                                                            :rules="ruleTraveler.CountryReq" @change="getCitiesCode()"
                                                            @input.native="getCountryCodeHotel($event.srcElement.value)"
                                                            background-color="white" placeholder="Country"
                                                            v-model="hotelObj.Country" item-text="Name"
                                                            item-value="Code"></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                                        <span>City or Province
                                                            <span style="color: red;">*</span>
                                                        </span>
                                                        <v-autocomplete outlined dense :items="CityorProvinceItem"
                                                            ref="CityorProvince" background-color="white"
                                                            :rules="ruleTraveler.CityorProvinceReq" item-text="text"
                                                            item-value="Code" placeholder="City or Province"
                                                            v-model="hotelObj.CityorProvince"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters v-if="hotelObj.ReqServ !== 'No Service Required'">
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <span>Hotel Name
                                                            <span style="color: red;">*</span>
                                                        </span>
                                                        <v-text-field outlined dense v-model="hotelObj.HotelName"
                                                            background-color="white" :rules="ruleTraveler.HotelNameReq"
                                                            ref="HotelName" placeholder="Hotel Name"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                                        <span>Hotel Description</span>
                                                        <v-text-field outlined dense v-model="hotelObj.Description"
                                                            background-color="white" ref="Description"
                                                            placeholder="Hotel Description"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <span>Room Type</span>
                                                        <v-autocomplete outlined dense :items="RoomTypeItem"
                                                            background-color="white" placeholder="Room Type"
                                                            v-model="hotelObj.RoomType" item-text="RMT_NAME"
                                                            item-value="RMT_NAME"></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                                        <span>Bed Type</span>
                                                        <v-autocomplete outlined dense :items="BedTypeItem"
                                                            background-color="white" placeholder="Bed Type"
                                                            v-model="hotelObj.BedType" item-text="BDT_NAME"
                                                            item-value="BDT_NAME"></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters v-if="hotelObj.ReqServ !== 'No Service Required'">
                                                    <v-col cols="12" lg="6" md="6" sm="12">
                                                        <span>Number of Room</span>
                                                        <v-text-field outlined dense placeholder="Number of Room"
                                                            background-color="white" type="number"
                                                            v-model="hotelObj.NumberOfRoom"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <span>Occupancy</span>
                                                        <v-text-field outlined dense v-model="hotelObj.Occupancy"
                                                            background-color="white"  class="pl-lg-2 pl-md-2"
                                                            ref="Occupancy" placeholder="Occupancy"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters v-if="hotelObj.ReqServ !== 'No Service Required'">
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <span>Price per night</span>
                                                        <v-text-field outlined dense v-model="hotelObj.PricePerNight"
                                                            background-color="white" type="number"
                                                            placeholder="Price per night"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="4" md="4" sm="6" class="pl-lg-2 pl-md-2 pl-sm-2">
                                                        <span>Amount</span>
                                                        <v-text-field outlined dense v-model="hotelObj.Amount" type="number"
                                                            background-color="white" placeholder="Amount"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="2" md="2" sm="12" class="pl-lg-2 pl-md-2">
                                                        <span>Currency</span>
                                                        <v-select outlined dense :items="CurrencyItem" ref="Currency"
                                                            background-color="white" v-model="hotelObj.Currency"></v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters v-if="hotelObj.ReqServ !== 'No Service Required' && !isReIssue">
                                                    <v-col cols="12">
                                                        <span>Traveler</span>
                                                        <v-row>
                                                            <v-col cols="12" lg="5" md="5" sm="12">
                                                                <v-card elevation="0" class="card-radius5px">
                                                                    <v-card-text>
                                                                        <span><strong>Standby</strong></span>
                                                                        <v-data-table hide-default-footer checkbox-color="primary"
                                                                            :items="StandbyItem" item-key="fullname"
                                                                            v-model="Selectedtable" show-select
                                                                            :headers="header" mobile-breakpoint>
                                                                            <template v-slot:[`item.no`]="{ index }">
                                                                                {{ index + 1 }}
                                                                            </template>
                                                                        </v-data-table>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col cols="12" lg="2" md="2" sm="12" class="text-center">
                                                                <v-btn class="btn-table ma-1" @click="addTravelerHotel()">
                                                                    <img class="hidden-sm-and-down"
                                                                        :src="require('@/assets/icons/Arrowrightinfo.svg')"
                                                                        alt="" />
                                                                    <img class="hidden-md-and-up"
                                                                        :src="require('@/assets/icons/ArrowPrimaryUP.svg')"
                                                                        alt="" />
                                                                    <span class="ml-4" style="font-size: .8rem;">Add</span>
                                                                    <span class="ml-4"></span>
                                                                </v-btn><br class="hidden-sm-and-down">
                                                                <v-btn class="btn-table ma-1"
                                                                    @click="removetravelerHotel()">
                                                                    <img class="hidden-sm-and-down"
                                                                        :src="require('@/assets/icons/ArrowLeftInfo.svg')"
                                                                        alt="" />
                                                                    <img class="hidden-md-and-up"
                                                                        :src="require('@/assets/icons/ArrowPrimaryDown.svg')"
                                                                        alt="" />
                                                                    <span class="ml-1"
                                                                        style="font-size: .8rem;">Remove</span>
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col cols="12" lg="5" md="5" sm="12">
                                                                <v-card elevation="0" class="card-radius5px">
                                                                    <v-card-text>
                                                                        <span><strong>Selected</strong></span>
                                                                        <v-data-table hide-default-footer checkbox-color="primary"
                                                                            :items="SelectedItem" item-key="fullname"
                                                                            v-model="Standbytable" show-select
                                                                            :headers="header" mobile-breakpoint>
                                                                            <template v-slot:[`item.no`]="{ index }">
                                                                                {{ index + 1 }}
                                                                            </template>
                                                                        </v-data-table>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <!-- Offline End -->
                                </v-tabs-items>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-row class="mt-1 mb-1 text-center">
                            <v-col>
                                <v-btn outlined color="primary" width="120" class="mr-1"
                                    @click="closeSearchHotelsInfo()">Cancel</v-btn>
                                <v-btn color="primary" width="120" class="ml-1"
                                @click="tabHotel === 0 ? gotoSearchHotelsOnline() : saveHotelOffline()">Continue</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog width="500" v-model="removeHotelInCard" persistent>
                <v-card>
                    <v-card-title>
                        Remove
                    </v-card-title>
                    <v-card-text>
                        would you like to remove hotel
                        <span>
                            <strong>
                                {{ HTNo }}
                            </strong>
                        </span>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small text @click="RemoveHotel('cancel', null, null)">Cancel</v-btn>
                        <v-btn small elevation="0" color="red" style="color: white;"
                            @click="RemoveHotel('remove', null, null)">
                            Continue</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog width="500" v-model="dialogTravelExpense" persistent>
                <v-card>
                    <v-card-title>
                        <v-label><strong>Travel Expense</strong></v-label>
                    </v-card-title>
                    <v-card-text>
                        <v-card elevation="0" class="bgcard pa-0">
                            <v-card-title>
                                <strong>Travel Expense</strong>
                            </v-card-title>
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <span>
                                            Account No.
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-text-field outlined dense v-model="accountNo" ref="accountNo"
                                            placeholder="Account Number" :rules="ruleTraveler.accountNoReq"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <span>
                                            Due Date.
                                            <span style="color: red;">*</span>
                                        </span>
                                        <v-menu v-model="menuDueDateTravelExpe" :close-on-content-click="false"
                                            :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field outlined dense placeholder="Select Date"
                                                    :value="DueDateTravelExpe ? formatDateLL(DueDateTravelExpe) : ''"
                                                    readonly v-bind="attrs" v-on="on"
                                                    :rules="ruleTraveler.DueDateTravelExpeReq" ref="DueDateTravelExpe">
                                                    <template v-slot:append>
                                                        <v-icon color="#00AEEF">mdi-calendar-month</v-icon>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="DueDateTravelExpe"
                                                @input="menuDueDateTravelExpe = false"
                                                :rules="ruleTraveler.DueDateTravelExpeReq" color="#00AEEF"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col>
                                        <v-radio-group dense v-model="chooseMoney" hide-details>
                                            <v-radio key="Sepecify" label="Sepecify" value="Sepecify">
                                                <template v-slot:label>
                                                    <v-row>
                                                        <v-col class="d-flex flex-row align-center">
                                                            Sepecify
                                                            <v-text-field class="ma-1" outlined dense v-model="moneySpecify"
                                                                hide-details></v-text-field>
                                                            <v-select class="ma-1" outlined dense style="width: 100px;"
                                                                v-model="currencyTravelExpe" :items="ItemCurrencyTravelExpe"
                                                                hide-details></v-select>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-radio>
                                            <v-radio v-for="(item, index) in itemlengthMoney" :key="index" :label="item.txt"
                                                :value="item.val"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-row class="mt-1 mb-1 text-center">
                            <v-col>
                                <v-btn outlined color="primary" width="120" class="mr-1"
                                    @click="cancelNewTravelExpense()">Cancel</v-btn>
                                <v-btn color="primary" width="120" class="ml-1" elevation="0"
                                    @click="saveTravelExpense()">Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog width="500" v-model="removeTravelExpenseInCard" persistent>
                <v-card>
                    <v-card-title>
                        Remove
                    </v-card-title>
                    <v-card-text>
                        would you like to remove travel expense ?
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small text @click="removeTravelExpense('cancel', null)">Cancel</v-btn>
                        <v-btn small elevation="0" color="red" style="color: white;"
                            @click="removeTravelExpense('remove', null)">
                            Continue
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogActionStatus" persistent width="400px">
                <v-card elevation="0" title color="primary" class="pt-1 ">
                    <v-card>
                    <v-card-text>
                        <v-card title elevation="0">
                        <v-card-title>
                            <v-row>
                            <v-col class="text-center">
                                {{ titleActionStatus }}
                            </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col class="text-center">
                                    <span>Traveller : {{ fullnameText }}</span>
                                </v-col>
                            </v-row>
                            <v-row v-if="statusMytaskMsg !== 'Approve'">
                                <v-col class="text-center">
                                    <v-textarea outlined dense background-color="white" auto-grow v-model="noteTR"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                        <v-col class="text-center">
                            <v-btn outlined color="primary" class="mr-1" @click="closeDialogActionStatus()">Close</v-btn>
                            <v-btn color="primary" class="ml-1" @click="confirmDialogActionStatus()">Confirm</v-btn>
                        </v-col>
                        </v-row>
                    </v-card-text>
                    </v-card>
                </v-card>
            </v-dialog>
            <v-dialog width="500" v-model="dRemovePassport.Remove">
                <v-card>
                    <v-card-title>
                        Remove
                    </v-card-title>
                    <v-card-text>
                        would you like to remove passport
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small text @click="dRemovePassport.Remove = false, dRemovePassport.PassportOID = ''">Cancel</v-btn>
                        <v-btn small color="red" style="color: white;" @click="confirmRemovePandV()">
                            Continue</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog width="500" v-model="dRemoveVisa.Remove">
                <v-card>
                    <v-card-title>
                        Remove
                    </v-card-title>
                    <v-card-text>
                        would you like to remove visa
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small text @click="dRemoveVisa.Remove = false, dRemoveVisa.VisaOID = ''">Cancel</v-btn>
                        <v-btn small color="red" style="color: white;" @click="confirmRemovePandV()">
                            Continue</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
</template>

<script>
import moment from 'moment';
import FlightBooking from '../searchFlight/FlightBooking.vue';
import axios from 'axios';
import Passport from '../Passport.vue';
import Visa from '../Visa.vue';
import Swal from 'sweetalert2'
import SimpleCrypto from "simple-crypto-js"
export default {
    components: {
        FlightBooking,
        Passport,
        Visa
    },
    props: {
        travel: {},
        travelers: [],
        num: "",
        BTTStatus: "",
        BusinessStartDate: "",
        BusinessEndDate: "",
        NoRequireServices: false,
        TRNo: "",
        BudgetHolderTR: "",
    },
    data() {
        return {
            dRemovePassport: {
                Remove: false,
                PassportOID: "",
                Type: ""
            },
            dRemoveVisa: {
                Remove: false,
                VisaOID: "",
                Type: ""
            },
            strYMD: "YYYY-MM-DD",
            txtPendingBudgetHolder: "Pending Budget Holder",
            txtPendingLineApprover: "Pending Line Approver",
            txtApproveFinal: "Approve Final",
            isReIssue: false,
            noteTR: "",
            canBudgetHolder: 0,
            canLineApproved: 0,
            EMPLOYEE_ID: "",
            dialogActionStatus: false,
            TravelerOID: "",
            titleActionStatus: "",
            TRNoText: "",
            fullnameText: "",
            statusMytask: "",
            statusMytaskMsg: "",
            BudgetHolderApprover: "",
            LineApprovalD: "",
            showOldBooking: null,
            ReIssueBooking: [],
            ReIssueHotel: [],
            flightType: '',
            flightAction: '',
            airline: {
                retFlight: [],
                depFlight: []
            },
            travelExpenseID: "",
            removeTravelExpenseInCard: false,
            moneySpecify: "",
            itemlengthMoney: [
                { txt: "5,000.00 THB (1-5 days)", val: 5000 },
                { txt: "10,000.00 THB (6-10 days)", val: 10000 },
            ],
            ItemCurrencyTravelExpe: ["THB"],
            currencyTravelExpe: "THB",
            chooseMoney: 0,
            DueDateTravelExpe: "",
            accountNo: "",
            menuDueDateTravelExpe: false,
            dialogTravelExpense: false,
            removeTravel: {},
            removeTravelItem: {},
            removeHotelInCard: false,
            HotelOffline: "",
            HTNo: "",
            RoomTypeItem: [],
            BedTypeItem: [],
            SelectedItem: [],
            StandbyItem: [],
            header: [
                { text: 'No.', value: 'no', },
                { text: 'Traveler On Mission', value: 'fullname', },
                { text: 'Type', value: 'Type', }
            ],
            Selectedtable: [],
            Standbytable: [],
            StatusItem: ["USED"],
            CurrencyItem: ["THB"],
            OccupancyItem: [],
            CountryItem: [],
            CityorProvinceItem: [],
            HotelNameItem: [],
            menuCheckinDateStartOffline: false,
            menuCheckinDateEndOffline: false,
            menuCheckinDateStartOnline: false,
            menuCheckinDateEndOnline: false,
            ruleTraveler: {
                ReqServReq: [v => !!v || 'Service requirement is required'],
                checkinDateStartReq: [v => !!v || 'Check in is required'],
                CountryReq: [v => !!v || 'Country is required'],
                CityorProvinceReq: [v => !!v || 'City or province is required'],
                SelectHotelReq: [v => !!v || 'Select hotel is required'],
                HotelNameReq: [v => !!v || 'Hotel name is required'],
                RoomTypeReq: [v => !!v || 'Room type is required'],
                NumberOfRoomReq: [v => !!v || 'Number of room is required'],
                OccupancyReq: [v => !!v || 'Occupancy is required'],
                BedTypeReq: [v => !!v || 'Bed type is required'],
                PricePerNightReq: [v => !!v || 'Price per night is required'],
                AmountReq: [v => !!v || 'Amount is required'],
                CurrencyReq: [v => !!v || 'Currency is required'],
                StatusReq: [v => !!v || 'Status is required'],
                accountNoReq: [v => !!v || 'Account No is required'],
                DueDateTravelExpeReq: [v => !!v || 'Due Date is required'],
            },
            hotelObj: {
                HTNo: "",
                ReqServ: "",
                ReqServOptnal: "",
                CheckinDateStart: this.BusinessStartDate,
                CheckinDateEnd: this.BusinessEndDate,
                Country: "",
                CityorProvince: "",
                SelectHotel: "",
                HotelName: "",
                Description: "",
                RoomType: "",
                NumberOfRoom: 1,
                Occupancy: 1,
                BedType: "",
                PricePerNight: 0,
                Amount: 0,
                Currency: "THB",
                Status: "USED",
                BookingType: "",
                Rating: 0,
                HTL_EMAIL: ""
            },
            ReqServItem: ["Reservation Only", "No Service Required", "Voucher"],
            tabHotel: 0,
            showHotel: false,
            reloading: 0,
            removeloading: false,
            formReqID: null,
            sflightdialog: false,
            sRemoveFlight: {
                RemoveFlight: false,
                Traveler: [],
                focusFlight: null
            },
            showPassport: false,
            showVisa: false,
            coperateData: {},
            dataSeat: [
                { Code: "", Description: "No Preferred" },
                { Code: "A", Description: "Aisle seat" },
                { Code: "W", Description: "Window seat" },
            ],
            settingHotels: [
                { setting: 'Add Hotel Schedule' },
            ],
            settingflights: [
                { setting: 'Add Flight Schedule' },
            ],
            settingpassport: [
                { setting: 'Select Passport Schedule' },
            ],
            settingvisa: [
                { setting: 'Select Visa Schedule' },
            ],
            settingTravelExpe: [
                { setting: 'Add Travel Expense' },
            ],
            passports: [],
            dataSpecialMeal: [],
            visa: [],
            senddata: null,
            DialogPassport: false,
            DialogVisa: false,
            empProfile: null,
            componentKey: 0,
            DialogTravelCard: false,
            UpdatePassportTravelerPath: "Traveler/UpdatePassportTraveler",
            UpdateVisaTravelerPath: "Traveler/UpdateVisaTraveler",
            SimpleCrypto: new SimpleCrypto("aoc"),
            departureDateTime: "",
            retDateTime: "",
            BookingClass: []
        }
    },
    methods: {
         statusColor(status){
            let res = ''
            let text = 'black'
            switch (status){
                case 'Issuing' :
                    res = '#fff8a8'
                break;
                case 'Issued' :
                    text = 'white'
                    res = 'btn-success'
                break;
                case 'Re-Issue' :
                    res = '#FF5656'
                    text = 'white'
                break;
                default:
                    res = '#00AEEF'
                    text = 'white'
            }

            return [res, text]
        },
        updateStatusTraveler(){
            const req = {
                EMPLOYEE_ID: this.travel.emp_ID,
                TravelRequestOID: this.travel.TravelRequestOID,
                Status: null
            }

            if(this.travel.status === this.txtApproveFinal){
                req.Status = 'Re-Issue'
            }else if(this.travel.status === 'Submitted' || this.travel.status === this.txtPendingLineApprover || this.travel.status === this.txtPendingBudgetHolder){
                req.Status = 'Revised'
            }else {
                req.Status = this.travel.status
            }
            axios.post(`${this.$store.state.API_PATH}Traveler/UpdateStatusTraveler`, req, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
        },
        showDetail(val){
            if(val === this.showOldBooking){
                this.showOldBooking = null
            }else{
                this.showOldBooking = val
            }
        },
        async travelReqPrintPage(el) {
            await this.getBookingClassDatabase()
            let lifeCycleItemData = []
            if(sessionStorage.lifeCycleItem){
                lifeCycleItemData = JSON.parse(sessionStorage.lifeCycleItem) ?? []
            }
            const lifeCycleItem = [['No.','Name','Action Type']]
            if(lifeCycleItemData != null) {
                const dataLifeCycle = lifeCycleItemData.filter((item) => item.EMPLOYEE_ID === el.emp_ID)
                lifeCycleItem.push([ 1, el.budgetHolderApprover, "Budget Holder" ])
                lifeCycleItem.push([ 2, dataLifeCycle.length > 0 ? dataLifeCycle[0].LINE_APPROVE :  "-", "Line Approver" ])
            }
            const hotelSche = [['Service Req.','Country/City','Hotel Name', 'Check-In', 'Check-Out', 'Night', 'Status']]
            if(el.hotels != null && el.hotels.length > 0){
                for(const element of el.hotels){
                    await new Promise(async (resolve) => {
                        const serviceReq = element.ReqServ === null ? "-" : element.ReqServ
                        const CountryCity = element.Country && element.CityorProvince === null ? "-" : `${element.Country}/${element.CityorProvince}`
                        const hotelName = element.HotelName === null ? "-" : element.HotelName
                        const checkInDate = element.CheckinDateStart === null ? "-" : moment(element.CheckinDateStart).format(this.strYMD)
                        const checkOutDate = element.CheckinDateEnd === null ? "-" : moment(element.CheckinDateEnd).format(this.strYMD)
                        const amountNight = element.Night === null ? "-" : element.Night
                        const Status = "-"
                        if(el.hotels.length > 0){
                            hotelSche.push([ serviceReq, CountryCity, hotelName, checkInDate, checkOutDate, amountNight, Status, ])
                        }
                        resolve()
                    })
                }
            } else{
                hotelSche.push(["-", "-", "-", "-", "-", "-", "-" ])
            }
            const travelSche = [['Date', 'Route', 'Flight', 'Departure Time', 'Arrive Time', 'Class', 'Seat', 'Status']]
            if (el.flights != null && el.flights.length > 0) {
                for (const element of el.flights) {
                    await new Promise(async (resolve) => {
                        const departureDateTime = element.depFlight.map((item) => {
                            return moment(item.departureDateTime).format(this.strYMD);
                        })
                        this.departureDateTime = departureDateTime
                        const depCityCode = element.depFlight.map((item) => {
                            return `${item.depCity.code} - ${item.arrCity.code}`;
                        })
                        const flightNumber = element.depFlight.map((item) => {
                            return `${item.airline.code}${item.flightNumber === null ? "" : item.flightNumber}`;
                        })
                        const departureTime = element.depFlight.map((item) => {
                            return `${item.depDisplayDateTime.displayTime}`;
                        })
                        const arriveTime = element.depFlight.map((item) => {
                            return `${item.arrDisplayDateTime.displayTime}`;
                        })
                        let classFlight = "-";
                        const chkSvcClass = this.BookingClass.filter((item) => item.BookingCode === element.Svc_class)
                        if(chkSvcClass.length > 0){
                            classFlight = chkSvcClass[0].Description
                        }
                        let seat = []
                        let requestSeat = ''
                        if(!this.travel.age || this.travel.age === ''){
                           seat = element.adtPaxs.filter((item) => item.emp_id === el.emp_ID)
                        }else if(this.travel.age && this.travel.age === 'Child'){
                           seat = element.chdPaxs.filter((item) => item.emp_id === el.emp_ID)
                        }else if(this.travel.age && this.travel.age === 'Infant'){
                           seat = element.infPaxs.filter((item) => item.emp_id === el.emp_ID)
                        }
                        requestSeat = seat[0].seatRequest = "" ? seat[0].seatRequest : 'No prefered' ;
                        const Status = "-"
                        const retDateTime = element.retFlight.length > 0 ? element.retFlight.map((item) => {
                            return moment(item.departureDateTime).format(this.strYMD);
                        }) : [];
                        const retCityCode = element.retFlight.length > 0 ? element.retFlight.map((item) => {
                            return `${item.depCity.code} - ${item.arrCity.code}`;
                        }) : [];
                        const retflightNumber = element.retFlight.length > 0 ? element.retFlight.map((item) => {
                            return `${item.airline.code}${item.flightNumber === null ? "" : item.flightNumber}`;
                        }) : [];
                        const retDepartureTime = element.retFlight.length > 0 ? element.retFlight.map((item) => {
                            return `${item.depDisplayDateTime.displayTime}`;
                        }) : [];
                        const retArriveTime = element.retFlight.length > 0 ? element.retFlight.map((item) => {
                            return `${item.arrDisplayDateTime.displayTime}`;
                        }) : [];
                        const retSeat = element.adtPaxs.filter((item) => item.emp_id === el.emp_ID)
                        const retRequestSeat = retSeat.seatRequest = "" ? retSeat.seatRequest : 'No prefered' ;
                        const retStatus = "-"
                        travelSche.push([ ...departureDateTime, ...depCityCode, ...flightNumber,
                        ...departureTime, ...arriveTime, classFlight, requestSeat, Status ])
                        if(element.retFlight.length > 0){
                            travelSche.push([ ...retDateTime, ...retCityCode, ...retflightNumber,
                            ...retDepartureTime, ...retArriveTime, classFlight, retRequestSeat, retStatus ])
                        }
                        resolve()
                    })
                }
            } else {
                travelSche.push([ "-", "-", "-", "-", "-", "-", "-", "-" ])
            }
            const PROFILE = JSON.parse(sessionStorage.coperateData)
            const startDate = moment(PROFILE.startDate);
            const endDate = moment(PROFILE.endDate);
            const BussDuration = endDate.diff(startDate, 'days');
            const depDepartureDateTime = el.flights.length > 0 ? moment(el.flights[0]?.depFlight[0]?.departureDateTime) : "";
            const depFormattedDate = depDepartureDateTime ? depDepartureDateTime.format(this.strYMD) :   "-";
            const retDepartureDateTime = el.flights.length > 0 ? moment(el.flights[el.flights.length - 1]?.retFlight[0]?.departureDateTime) : "";
            const retFormattedDate = retDepartureDateTime ? retDepartureDateTime.format(this.strYMD) :   "-";
            const depDate = new Date(depFormattedDate);
            const retDate = new Date(retFormattedDate);
            const timeDifference = retDate.getTime() - depDate.getTime();
            const dayDifference = timeDifference ? timeDifference / (1000 * 3600 * 24) : "-"
            const passportNo = el.passport === null ? "-"  : el.passport.PassportNo;
            const expiryDate = el.passport === null ? "-" : moment(el.passport.ExpiryDate).format(this.strYMD);
            const requestNewPassport = el.passport === null ? "-" : el.passport.RequestNewPassport;
            const passportCountry = el.passport === null ? "-" : el.passport.Country
            const visaNo = el.visa === null ? "-" : el.visa.VisaNo;
            const IssueDate = el.visa === null ? "-" : moment(el.visa.IssueDate).format(this.strYMD);
            const visaCountry = el.visa === null ? "-" : el.visa.Country;
            const visaExpiryDate = el.visa === null ? "-" : moment(el.ExpiryDate).format(this.strYMD);
            const typePerson = el.Type;
            const accountNo = el.travelExpens.length > 0 ? el.travelExpens[0].AccountNo : "-";
            const servicesReq = this.NoRequireServices === false ? " Reservation and Issuance" : "No services Require";
            const docDefinition = {
                content: [
                    { columns: [ { text: 'VIEW - Details of Persons on Mission', style: 'header', alignment: 'center' } ] },
                    '\n', { alignment: 'justify',
                        columns: [ { text: '', style: 'subheader' }, { text: '', style: 'subheader' },
                        { text: `TR NUMBER: ${PROFILE.TRNo}`, style: 'subheader' } ],
                    }, { alignment: 'justify', columns: [ { text: 'DETAIL OF PERSONS ON MISSION :', style: 'subheader' }, ],
                    }, { alignment: 'justify', columns: [ { text: `Type : ${typePerson}`, style: 'subheader' }, ],
                    }, { alignment: 'justify', columns: [ { text: `Name: ${el.fullname}`, style: 'subheader' }, ]
                    }, { alignment: 'justify',
                        columns: [ { text: (el.dep ? `Dept/Div:${el.dep}/${el.div}` : `-` ), style: 'subheader' }, ]
                    }, { alignment: 'justify',
                        columns: [ { text: `Position: ${el.position != null ? el.position : "-"}`, style: 'subheader' }, ],
                    }, { alignment: 'justify', columns: [ { text: `ID No : ${el.emp_ID}`, style: 'subheader' }, ],
                    }, '\n', { alignment: 'justify',
                        columns: [
                            { text: (el.travelExpens.length > 0 ? `Advance Amount: ${el.travelExpens[0].Amount.toLocaleString('th',
                                {minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${el.travelExpens[0].Currency}` : `-` )
                                , style: 'subheader'
                            },
                            { text: '', style: 'subheader'},
                        ]
                    }, { alignment: 'justify', columns: [ { text: `Purpose of Travel: Mission : ${PROFILE.PurId}`, style: 'subheader' }, ]
                    }, { alignment: 'justify',
                        columns: [ { text: `Pay By: ${accountNo}`, style: 'subheader' }, ]
                    }, { alignment: 'justify',
                        columns: [
                            { text: `Bussiness Date : ${PROFILE.startDate}`, style: 'subheader' },
                            { text: `To : ${PROFILE.endDate}`, style: 'subheader' },
                            { text: (BussDuration > 1 ? `Total: ${BussDuration} days` : `Total: ${BussDuration} day`), style: 'subheader' }, ]
                    }, { alignment: 'justify',
                        columns: [
                            { text: `Departure On : ${depFormattedDate}`, style: 'subheader' },
                            { text: `Return On : ${retFormattedDate}`, style: 'subheader' },
                            { text: (dayDifference !== "-" ? `Total: ${dayDifference} days` : dayDifference), style: 'subheader' }, ]
                    }, '\n', { alignment: 'justify', columns: [ { text: 'TRAVEL SCHEDULE', style: 'subheader' }, ]
                    }, { style: 'tableExample', alignment: 'center',
                        table: { widths: ['*', '*', '*', '*', '*', '*', '*', '*'], body: travelSche, style: 'subheader' }
                    }, '\n', { alignment: 'justify', columns: [ { text: 'SERVICES REQUIRESTED', style: 'subheader' }, ]
                    }, { alignment: 'justify', columns: [ { text: `Ticket : ${servicesReq}`, style: 'subheader' }, ]
                    }, '\n', { alignment: 'justify', columns: [ { text: 'Hotel', style: 'subheader' }, ]
                    }, { style: 'tableExample', alignment: 'center',
                        table: { widths: ['*', '*', '*', '*', '*', '*', '*'],
                        body: hotelSche , style: 'subheader', alignment: 'center' }
                    }, '\n', { alignment: 'justify',
                        columns: [ { text: 'Passport :', style: 'subheader' }, ]
                    }, { alignment: 'justify',
                        columns: [
                            { text: `Passport No : ${passportNo}`, style: 'subheader' },
                            { text: `Request New Passport : ${requestNewPassport}`, style: 'subheader' } ]
                    }, { alignment: 'justify',
                        columns: [
                            { text: `Expiry Date : ${expiryDate}`, style: 'subheader' },
                            { text: `Country : ${passportCountry}`, style: 'subheader' } ]
                    }, '\n', { alignment: 'justify', columns: [ { text: 'Visa :', style: 'subheader' }, ]
                    }, { alignment: 'justify',
                        columns: [
                            { text: `Visa No : ${visaNo}`, style: 'subheader' },
                            { text: `Country : ${visaCountry}`, style: 'subheader' } ]
                    }, { alignment: 'justify',
                        columns: [
                            { text: `Issue Date : ${IssueDate}`, style: 'subheader' },
                            { text: `Expiry Date : ${visaExpiryDate}`, style: 'subheader' } ]
                    }, '\n', { alignment: 'justify', columns: [ { text: 'Life Cycle :', style: 'subheader' }, ]
                    }, { style: 'tableExample', alignment: 'center',
                        table: { widths: ['auto', '*', 70], body: lifeCycleItem , style: 'subheader' } }
                ],
                styles: { header: { fontSize: 18, bold: true }, subheader: { fontSize: 10, bold: true },
                    quote: { italics: true }, small: { fontSize: 8 }, tableExample: { bold: true, fontSize: 10, color: 'black' }
                }
            };
            pdfMake.createPdf(docDefinition).open();
        },
        addTravelExpen() {
            this.accountNo = this.travel.accountNo
            this.dialogTravelExpense = true
        },
        checkbirthDate(val, birthdate) {
            let type = ''
            if (val.depFlight[0].departureDateTime) {
                if (parseInt(moment(val.depFlight[0].departureDateTime).diff(moment(birthdate), 'years')) >= 12) {
                    type = 'Adult'
                } else if (parseInt(moment(val.depFlight[0].departureDateTime).diff(moment(birthdate), 'years')) < 12) {
                    type = 'Child'
                } else if (parseInt(moment(val.depFlight[0].departureDateTime).diff(moment(birthdate), 'years')) >= 2) {
                    type = 'Infant'
                }
            }
            if (val.retFlight[0].departureDateTime) {
                if (parseInt(moment(val.retFlight[0].departureDateTime).diff(moment(birthdate), 'years')) >= 12) {
                    type = 'Adult'
                } else if (parseInt(moment(val.retFlight[0].departureDateTime).diff(moment(birthdate), 'years')) < 12) {
                    type = 'Child'
                } else if (parseInt(moment(val.retFlight[0].departureDateTime).diff(moment(birthdate), 'years')) >= 2) {
                    type = 'Infant'
                }
            }
            return type
        },
        removeTravelExpense(type, itemTravelExpe) {
            if (type === "alert") {
                this.travelExpenseID = itemTravelExpe.ID
                this.removeTravelExpenseInCard = true
            }
            else if (type === "remove") {
                this.removeTravelExpenseInCard = false
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}TravelExpense/RemoveTravelExpense`, {
                    ID: this.travelExpenseID
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                    .then((res) => {
                        this.$store.state.LOADING = false
                        if (res.data.Status) {
                            this.travel.travelExpens.splice(this.travel.travelExpens.findIndex(x => x.ID === this.travelExpenseID), 1)
                        }
                    })
                    .catch(() => {
                        window.location.reload()
                    })
            }
            else {
                this.travelExpenseID = ""
                this.removeTravelExpenseInCard = false
            }
        },
        saveTravelExpense() {
            if (!this.$refs.accountNo.validate() || !this.$refs.DueDateTravelExpe.validate()) {
                if (!this.$refs.accountNo.validate()) {
                    this.$refs.accountNo.focus()
                }
                else {
                    this.$refs.DueDateTravelExpe.focus()
                }
            }
            else {
                const PROFILE = JSON.parse(localStorage.PROFILE)
                this.dialogTravelExpense = false
                this.$store.state.LOADING = true
                const Amount = this.chooseMoney === "Sepecify" ? Number(this.moneySpecify) : this.chooseMoney
                let descTravelExpe = ""
                const filterRadioTravelExpe = this.itemlengthMoney.filter(x => x.val === Amount)
                if (filterRadioTravelExpe.length > 0) {
                    descTravelExpe = filterRadioTravelExpe[0].txt
                } else if (Amount) {
                    descTravelExpe = `${Amount.toLocaleString('th', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.currencyTravelExpe}`
                } else {
                    descTravelExpe = `0.00 ${this.currencyTravelExpe}`
                }
                const reqTravelExpe = {
                    TravelCardOID: this.travel.TravelCardOID.toUpperCase(),
                    AccountNo: this.accountNo,
                    DueDate: this.DueDateTravelExpe,
                    Amount: Amount,
                    Currency: this.currencyTravelExpe,
                    Description: descTravelExpe,
                    Cby: PROFILE.EMPLOYEE_ID_BTT ? PROFILE.EMPLOYEE_ID_BTT : PROFILE.EMPLOYEE_ID
                }
                axios.post(`${this.$store.state.API_PATH}TravelExpense/InsertTravelExpense`, reqTravelExpe, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then((res) => {
                    this.$store.state.LOADING = false
                    if (res.data.Status) {
                        if (this.travel.travelExpens === null) {
                            this.travel.travelExpens = []
                            this.travel.travelExpens.push(reqTravelExpe)
                        }
                        else {
                            this.travel.travelExpens.push(reqTravelExpe)
                        }
                        this.cancelNewTravelExpense()
                    }
                }, err => {
                    console.log(err)
                    this.$store.state.LOADING = false
                })
            }
        },
        cancelNewTravelExpense() {
            this.dialogTravelExpense = false
            this.moneySpecify = ""
            this.currencyTravelExpe = "THB"
            this.DueDateTravelExpe = ""
            this.accountNo = ""
            this.chooseMoney = 0
            this.menuDueDateTravelExpe = false
        },
        async newHotelToCard(dataHotel) {
            if(dataHotel){
                this.isReIssue = true
                sessionStorage.isReIssue = this.isReIssue
                sessionStorage.HotelOID = dataHotel.HotelOfflineOID
                this.$store.state.isReIssue = this.isReIssue
                this.$store.state.HotelOID = dataHotel.HotelOfflineOID
                this.hotelObj.CheckinDateStart = moment(dataHotel.CheckinDateStart).format(this.strYMD)
                this.hotelObj.CheckinDateEnd = moment(dataHotel.CheckinDateEnd).format(this.strYMD)
                this.hotelObj.Country = dataHotel.Country
                this.hotelObj.CityorProvince = dataHotel.CityorProvince
                this.getCountryCodeHotel(dataHotel.Country)
                this.getCitiesCode()
                this.SelectedItem = this.travelers.filter(x=>x.emp_ID === this.travel.emp_ID)
                this.showHotel = true
            }
            else{
                const requestInfo = JSON.parse(sessionStorage.coperateData)
                this.SelectedItem = []
                this.StandbyItem = []
                this.$store.state.LOADING = true
                for (const item of this.travelers) {
                    await new Promise((resolve) => {
                        if (item.emp_ID === this.empProfile.EMPLOYEE_ID || item.emp_ID === this.travel.emp_ID) {
                            this.SelectedItem.push(item)
                        }
                        else {
                            if (requestInfo.EMPLOYEE_ID === this.empProfile.EMPLOYEE_ID) {
                                this.StandbyItem.push(item)
                            }
                        }
                        resolve()
                    })
                }
                this.$store.state.LOADING = false
                this.showHotel = true
            }
        },
        RemoveHotel(type, travelItem, travel) {
            if (type === "alert") {
                this.removeHotelInCard = true
                this.HotelOffline = travelItem.HotelOfflineOID
                this.HTNo = travelItem.HTNo
                this.removeTravel = travel
                this.removeTravelItem = travelItem
            }
            else if (type === "remove") {
                this.removeHotelInCard = false
                this.showHotel = false
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}HotelBooking/CancelHotelBooking`, {
                    HotelOfflineOID: this.HotelOffline
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then(() => {
                    window.location.reload()
                })
                .catch(() => {
                    window.location.reload()
                })
            }
            else {
                this.HotelOffline = ""
                this.HTNo = ""
                this.removeTravel = {}
                this.removeTravelItem = {}
                this.removeHotelInCard = false
            }
        },
        async gotoSearchHotelsOnline() {
            const requestInfo = JSON.parse(sessionStorage.coperateData)
            if (!this.$refs.Country.validate() || !this.$refs.CityorProvince.validate()) {
                if (!this.$refs.Country.validate()) {
                    this.$refs.Country.focus()
                }
                else {
                    this.$refs.CityorProvince.focus()
                }
            }
            else {
                const CountryText = this.CountryItem.filter(e => e.Code === this.hotelObj.Country)[0].Name
                const CityorProvinceText = this.$store.state.Language === "en" ?
                    this.CityorProvinceItem.filter(e => e.Code === this.hotelObj.CityorProvince)[0].name_en :
                    this.CityorProvinceItem.filter(e => e.Code === this.hotelObj.CityorProvince)[0].name_th
                const dateString = `${this.checkInDateFormat(this.hotelObj.CheckinDateStart)} - ${this.checkInDateFormat(this.hotelObj.CheckinDateEnd)}`
                const BusDate = `${this.formatDateLL(this.BusinessStartDate)} - ${this.formatDateLL(this.BusinessEndDate)}`
                const newArr = []
                for (const item of this.SelectedItem) {
                    await new Promise((resolve) => {
                        newArr.push({
                            TravelCardOID: item.TravelCardOID,
                            TravelRequestOID: item.TravelRequestOID,
                            TravelerOID: item.TravelerOID,
                            emp_ID: item.emp_ID,
                            fullname: item.fullname,
                            email: item.email,
                            firstname: item.firstname,
                            title: item.title,
                            mobile: item.mobile,
                            position: item.position,
                            dep: item.dep,
                            div: item.div,
                            lastname: item.lastname
                        })
                        resolve()
                    })
                }
                const data = {
                    Country: this.hotelObj.Country,
                    CityorProvince: this.hotelObj.CityorProvince,
                    CheckinDateStart: this.hotelObj.CheckinDateStart,
                    CheckinDateEnd: this.hotelObj.CheckinDateEnd,
                    Occupancy: this.SelectedItem.length,
                    Location: `${CountryText}, ${CityorProvinceText}`,
                    DescSearch: `${dateString} | ${this.sumNight} nights | ${this.hotelObj.NumberOfRoom} Room, ${this.SelectedItem.length} Guest`,
                    TRNoText: `TR No.${requestInfo.TRNo}`,
                    BusDateText: `(Business Start -End: ${BusDate})`,
                    Night: this.sumNight,
                    BusinessStartDate: this.BusinessStartDate,
                    BusinessEndDate: this.BusinessEndDate,
                    Traveler: newArr
                }
                sessionStorage.SelectedItemHotel = this.SimpleCrypto.encrypt(this.SelectedItem)
                window.location = `/searchHotel?ID=${encodeURIComponent(this.SimpleCrypto.encrypt(data))}`
            }
        },
        saveHotelOffline() {
            if (!this.$refs.ReqServ.validate()) {
                this.$refs.ReqServ.focus()
            }
            else {
                if (this.hotelObj.ReqServ !== 'No Service Required') {
                    if (!this.$refs.Country.validate() || !this.$refs.CityorProvince.validate() ||
                        !this.$refs.HotelName.validate()) {
                        if (!this.$refs.Country.validate()) {
                            this.$refs.Country.focus()
                        }
                        else if (!this.$refs.CityorProvince.validate()) {
                            this.$refs.CityorProvince.focus()
                        }
                        else {
                            this.$refs.HotelName.focus()
                        }
                    }
                    else {
                        if(sessionStorage.isReIssue){
                            this.reIssueHotelBookingOffline()
                        }
                        else{
                            this.addHotelOffline()
                        }
                    }
                }
                else {
                    if(sessionStorage.isReIssue){
                        this.reIssueHotelBookingOffline()
                    }
                    else{
                        this.addHotelOffline()
                    }
                }
            }
        },
        closeSearchHotelsInfo() {
            sessionStorage.removeItem("isReIssue")
            sessionStorage.removeItem("HotelOID")
            this.$store.state.isReIssue = false
            this.$store.state.HotelOID = ""
            this.isReIssue = false
            this.showHotel = false
            this.hotelObj = {
                HTNo: "",
                ReqServ: "",
                ReqServOptnal: "",
                CheckinDateStart: this.BusinessStartDate,
                CheckinDateEnd: this.BusinessEndDate,
                Country: "",
                CityorProvince: "",
                SelectHotel: "",
                HotelName: "",
                RoomType: "",
                NumberOfRoom: 1,
                Occupancy: 1,
                BedType: "",
                PricePerNight: 0,
                Amount: 0,
                Currency: "THB",
                Status: "USED",
                BookingType: "",
                Rating: 0
            }
        },
        addHotelOffline() {
            this.showHotel = false
            this.$store.state.LOADING = true
            if (this.travel.hotels === null) {
                this.travel.hotels = []
            }
            axios.post(`${this.$store.state.API_PATH}HotelBooking/InsertHotelBookingOffline`, {
                ...this.hotelObj,
                NumberOfRoom: Number(this.hotelObj.NumberOfRoom),
                Occupancy: Number(this.hotelObj.Occupancy),
                PricePerNight: Number(this.hotelObj.PricePerNight),
                Amount: Number(this.hotelObj.Amount),
                Night: this.sumNight,
                TravelCardOID: this.travel.TravelCardOID.toUpperCase(),
                EMPLOYEE_ID: this.travel.emp_ID,
                TravelRequestOID: this.travel.TravelRequestOID.toUpperCase()
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
                .then((res) => {
                    if (res.data.Status) {
                        this.$store.state.LOADING = false
                        this.travel.hotels.push({
                            ...this.hotelObj,
                            Amount: Number(this.hotelObj.Amount),
                            BookingType: "Offline",
                            HTNo: "Pending",
                            HotelOfflineOID: res.data.Result
                        })
                        this.closeSearchHotelsInfo()
                    }
                    else {
                        window.location.reload()
                    }
                })
                .catch(() => {
                    window.location.reload()
                })
        },
        reIssueHotelBookingOffline(){
            this.showHotel = false
            this.$store.state.LOADING = true
            if (this.travel.hotels === null) {
                this.travel.hotels = []
            }
            axios.post(`${this.$store.state.API_PATH}HotelBooking/ReIssueHotelBookingOffline`, {
                ...this.hotelObj,
                NumberOfRoom: Number(this.hotelObj.NumberOfRoom),
                Occupancy: Number(this.hotelObj.Occupancy),
                PricePerNight: Number(this.hotelObj.PricePerNight),
                Amount: Number(this.hotelObj.Amount),
                Night: this.sumNight,
                TravelCardOID: this.travel.TravelCardOID.toUpperCase(),
                EMPLOYEE_ID: this.travel.emp_ID,
                TravelRequestOID: this.travel.TravelRequestOID.toUpperCase(),
                BookingType: "Offline",
                HotelOID: this.$store.state.HotelOID ?? ""
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then((res) => {
                window.location.reload()
            })
            .catch(() => {
                window.location.reload()
            })
        },
        getRoomAndBedType() {
            if(this.RoomTypeItem.length <= 1){
                this.RoomTypeItem = []
                this.RoomTypeItem.push({
                    RMT_ID: "",
                    RMT_NAME: "",
                    RMT_DESC: "",
                    RMT_ORDER: "",
                    STS_ID: "",
                    CREATED_BY: "",
                    CREATED_DATE: "",
                    UPDATED_BY: "",
                    UPDATED_DATE: "",
                    HTL_ID: ""
                })
                axios.get(`${this.$store.state.API_PATH}RoomType/GetRoomTypeActive`, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    if (res.data.Status) {
                        this.RoomTypeItem = this.RoomTypeItem.concat(res.data.Result)
                    }
                })
                .catch((err) => err)
            }
            if(this.BedTypeItem.length <= 1){
                this.BedTypeItem = []
                this.BedTypeItem.push({
                    BDT_ID: "",
                    BDT_NAME: "",
                    BDT_DESC: "",
                    BDT_ORDER: "",
                    STS_ID: "",
                    CREATED_BY: "",
                    CREATED_DATE: "",
                    UPDATE_BY: "",
                    UPDATE_DATE: ""
                })
                axios.get(`${this.$store.state.API_PATH}BedType/GetBedTypeActive`, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    if (res.data.Status) {
                        this.BedTypeItem = this.BedTypeItem.concat(res.data.Result)
                    }
                })
                .catch((err) => err)
            }
        },
        getHotelBySearch(input) {
            const checkItem = this.HotelNameItem.filter((el) => el.val.toLowerCase().substr(0, input.length) === input.toLowerCase())
            if (checkItem.length === 0) {
                this.HotelNameItem = []
                axios.post(`${this.$store.state.API_PATH}GetHotelBySearch`, {
                    HTL_NAME: input,
                    PRV_ID: this.hotelObj.CityorProvince,
                    COUNTRY_ID: this.hotelObj.Country
                })
                .then((res) => {
                    if (res.data.Status) {
                        this.HotelNameItem = res.data.Result
                    }
                })
                .catch((err) => err)
            }
        },
        getCitiesCode() {
            this.CityorProvinceItem = []
            this.HotelNameItem = []
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}CityCode/${this.hotelObj.Country === 'TH' ? 'GetProvince' : 'GetCityCodeByCountryCode'}`, {
                CountryCode: this.hotelObj.Country,
                Language: this.$store.state.Language,
                Country: this.hotelObj.Country
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then((resCities) => {
                if (resCities.data.Status) {
                    resCities.data.Result.forEach((elm) => {
                        if (this.hotelObj.Country === 'TH') {
                            const findname = elm[`name_${this.$store.state.Language}`]
                            this.CityorProvinceItem.push({ ...elm, Code: elm.code, text: `${findname} (${elm.code})` })
                        } else {
                            this.CityorProvinceItem.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                        }
                    })
                    this.$store.state.LOADING = false
                }
            }, ErrorMessage => {
                console.log(ErrorMessage)
                this.$store.state.LOADING = false
            })
        },
        async addTravelerHotel() {
            if (this.Selectedtable.length > 0) {
                this.Standbytable = []
                for (const item of this.Selectedtable) {
                    await new Promise((resolve) => {
                        this.SelectedItem.push(item)
                        this.StandbyItem.splice(this.StandbyItem.findIndex(el => el.emp_ID === item.emp_ID), 1)
                        resolve()
                    })
                }
                this.Selectedtable = []
            }
        },
        async removetravelerHotel() {
            if (this.Standbytable.length > 0) {
                this.Selectedtable = []
                for (const item of this.Standbytable) {
                    await new Promise((resolve) => {
                        this.StandbyItem.push(item)
                        this.SelectedItem.splice(this.SelectedItem.findIndex(el => el.emp_ID === item.emp_ID), 1)
                        resolve()
                    })
                }
                this.Standbytable = []
            }
        },
        formatDateLL(date) {
            return date ? moment(date).format("ll") : moment().format("ll")
        },
        getSpecialMeal() {
            axios.post(`${this.$store.state.API_PATH}SpecialMeal/GetSpecialMeal`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
                .then(res => {
                    if (res.data.Status) {
                        this.dataSpecialMeal = res.data.Result
                    }
                })
                .catch(err => err)
        },
        OpenTravelCard() {
            this.travel.ListBookingOID = this.travel.flights != null && this.travel.flights.length > 0 ? this.travel.flights.map(item => item.bookingOID) : null
            this.DialogTravelCard = true
        },
        pagereloading() {
            this.reloading += 1
        },
        RemoveTravel() {
            this.removeloading = true
            const formReqID = JSON.parse(sessionStorage.formReqID)
            const PROFILE = JSON.parse(localStorage.PROFILE)
            const CancelTravelCardRequest = {
                Traveler: this.travel,
                TRNo: formReqID.TRNo,
                FullName: this.travel.fullname,
                CREATED_BY: PROFILE.EMPLOYEE_ID
            }
            axios.post(this.$store.state.API_PATH + 'Traveler/RemoveTravelCard', CancelTravelCardRequest, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(() => {
                this.$emit("travelers", this.travelers.filter(x => x.TravelCardOID !== this.travel.TravelCard.TravelCardOID))
                if (this.empProfile.POSITION.indexOf('Secretary') === -1) {
                    const req = [{
                        EMPLOYEE_ID: this.travel.emp_ID,
                        BookingOID: null,
                        BudgetHolderApprover: null,
                        LineApprovalD: null,
                        BTTId: null,
                        TravelCardOID: null,
                        TravelRequestOID: this.travel.TravelRequestOID,
                        TravelCard: null,
                        PreferredSeat: null,
                        SpecialMeal: null,
                        Passport: [{
                            PassportOID: null,
                            PassportNo: null,
                            PassportType: null,
                            RequestNewPassport: null,
                            Country: null,
                            ExpiryDate: null,
                        }],
                        Visa: [{
                            VisaOID: null
                        }],
                        SpecialRequest: {
                            PreferredSeat: null,
                            SpecialMeal: null,
                        },
                    }]
                    axios.post(`${this.$store.state.API_PATH}Traveler/CreateListTraveler`, req, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    }).then(() => {
                        location.href = 'createTravelRq'
                    })
                }
                this.$emit("getTRLifeCycleByPermission")
                this.removeloading = false
                this.DialogTravelCard = false
            }).catch(err => {
                this.removeloading = false
            })
        },
        focusRender() {
            this.componentKey += 1
        },
        openPass() {
            this.DialogPassport = true
            this.focusRender()
        },
        openVisa() {
            this.DialogVisa = true
            this.focusRender()
        },
        ShowDialogPass(val, PassportInfo) {
            this.forceRerender()
            if (val === 'add') {
                this.DialogPassport = true
                this.senddata = null
            } else if (val === 'edit') {
                this.DialogPassport = true
                this.senddata = PassportInfo
            }
        },
        RequirePass(pTraveler) {
            const chkRequired = this.passports != null && this.passports.length > 0 ? this.passports.filter(x => x && x.PassportType === 'Required') : null
            const req = {
                TravelerOID: pTraveler.TravelerOID,
                TravelCardOID: pTraveler.TravelCardOID,
                TravelRequestOID: this.formReqID.TravelId,
                EMPLOYEE_ID: pTraveler.emp_ID,
                PassportOID: null
            }
            if (chkRequired !== null && chkRequired.length > 0) {
                req.Passport = [chkRequired[0]]
                req.PassportOID = chkRequired[0].PassportOID
                this.showPassport = false
                axios.post(this.$store.state.API_PATH + this.UpdatePassportTravelerPath, req, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    if(res.data.Status && res.data.Result){
                        this.travel.passport.push(res.data.Result)
                    }
                    this.showPassport = false
                })
            } else {
                const PassportInfo = {
                    PassportType: 'Required',
                    EMPLOYEE_ID: this.travel.emp_ID
                }
                axios.post(this.$store.state.API_PATH + 'Passport/CreatePassport', PassportInfo, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    req.Passport = [res.data.Result.filter(x => x && x.PassportType === 'Required')[0]]
                    req.PassportOID = res.data.Result.filter(x => x && x.PassportType === 'Required')[0].PassportOID
                    this.showPassport = false
                    console.log(res.data.Result, req);
                    axios.post(this.$store.state.API_PATH + this.UpdatePassportTravelerPath, req, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    }).then(res2 => {
                        this.travel.passport.push(res2.data.Result)
                        this.showPassport = false
                    })
                })
            }
        },
        RequireVisa(pTraveler) {
            const chkRequired = this.visa != null && this.visa.length > 0 ? this.visa.filter(x => x && x.VisaType === 'Required') : null
            const req = {
                TravelerOID: pTraveler.TravelerOID,
                TravelCardOID: pTraveler.TravelCardOID,
                TravelRequestOID: this.formReqID.TravelId,
                EMPLOYEE_ID: pTraveler.emp_ID,
                VisaOID: null,
            }
            if (chkRequired != null && chkRequired.length > 0) {
                req.Visa = [chkRequired[0]]
                req.VisaOID = chkRequired[0].VisaOID
                this.showVisa = false
                axios.post(this.$store.state.API_PATH + this.UpdateVisaTravelerPath, req, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                     if(res.data.Status && res.data.Result){
                        this.travel.visa.push(res.data.Result)
                    }
                    this.showVisa = false
                })
            } else {
                const VisaObj = {
                    VisaType: 'Required',
                    EMPLOYEE_ID: this.travel.emp_ID
                }
                axios.post(this.$store.state.API_PATH + 'Visa/CreateVisa', VisaObj, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    req.Visa = [res.data.Result.filter(x => x && x.VisaType === 'Required')[0]]
                    req.VisaOID = res.data.Result.filter(x => x && x.VisaType === 'Required')[0].VisaOID
                    this.showVisa = false
                    axios.post(this.$store.state.API_PATH + this.UpdateVisaTravelerPath, req, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    }).then(res2 => {
                        this.travel.visa.push(res2.data.Result)
                        this.showVisa = false
                    })
                })
            }
        },
        selectPassport(pTraveler, pPassport) {
            const req = {
                TravelerOID: this.travel.TravelerOID,
                TravelRequestOID: this.formReqID.TravelId,
                TravelCardOID: this.travel.TravelCardOID,
                EMPLOYEE_ID: pTraveler.emp_ID,
                Status: this.travel.status,
                Passport: [{pPassport}],
                PassportOID: pPassport.PassportOID
            }
            axios.post(this.$store.state.API_PATH + this.UpdatePassportTravelerPath, req, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                if(res.data.Status && res.data.Result){
                    this.travel.passport.push(res.data.Result)
                    this.showPassport = false
                    this.updateStatusTraveler()
                }
            })
        },
        selectVisa(pTraveler, pVisa) {
            const req = {
                TravelerOID: this.travel.TravelerOID,
                TravelRequestOID: this.formReqID.TravelId,
                TravelCardOID: this.travel.TravelCardOID,
                EMPLOYEE_ID: pTraveler.emp_ID,
                Status: this.travel.status,
                VisaOID: pVisa.VisaOID,
                Visa: [{pVisa}]
            }
            axios.post(this.$store.state.API_PATH + this.UpdateVisaTravelerPath, req, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                if(res.data.Status && res.data.Result){
                    console.log(res.data.Result);
                    this.travel.visa.push(res.data.Result)
                    this.showVisa = false
                    this.updateStatusTraveler()
                }
            })
        },
        addPassport() {
            this.showPassport = true
            axios.post(`${this.$store.state.API_PATH}Passport/GetPassportByID?pEmployeeID=${this.travel.emp_ID}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.passports = res.data.Result
            })
        },
        addVisa() {
            this.showVisa = true
            axios.post(`${this.$store.state.API_PATH}Visa/GetVisa?val=${this.travel.emp_ID}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.visa = res.data.Result
            })
        },
        fixformatdate(val, format){
            return moment(val).format(format)
        },
        formatdate(val) {
            return moment(val).format('DD MMM YYYY')
        },
        RemoveFlight(type, val, travelers) {
            sessionStorage.removeItem('RemoveFlight')
            if (type === 'alert') {
                this.sRemoveFlight.RemoveFlight = true
                this.sRemoveFlight.focusFlight = val
                this.sRemoveFlight.Traveler.push(...travelers)
            } else if (type === 'remove') {
                if (!this.sRemoveFlight.focusFlight.isFlightOffline) {
                    sessionStorage.RemoveFlight = JSON.stringify(this.sRemoveFlight)
                    setTimeout(() => {
                        location.href = 'CancelFlights'
                    }, 1);
                }
                else {
                    this.sRemoveFlight.RemoveFlight = false
                    this.$store.state.LOADING = true
                    axios.post(this.$store.state.API_PATH + 'Traveler/CancelTravelerOffline', {
                        BookingOfflineOID: this.sRemoveFlight.focusFlight.BookingOfflineOID
                    }, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    })
                    .then(res => {
                        this.$store.state.LOADING = false
                        if (res.data.Status) {
                            Swal.fire({
                                icon: 'success',
                                text: 'Success',
                            }).then(() => {
                                window.location.href = ""
                            })
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                text: res.data.ErrorMessage,
                            }).then(() => {
                                window.location.href = ""
                            })
                        }
                    }, err => {
                        console.log(err)
                        this.$store.state.LOADING = false
                    })
                }
            }
        },
        ReIssueFlight(val, type){
            this.flightType = type
            this.flightAction = 'Re-Issue'
            this.airline = val
            this.pagereloading()
            this.sflightdialog = true
        },
        addFlight() {
            sessionStorage.removeItem('travelers')
            this.flightAction = 'Add'
            this.pagereloading()
            this.sflightdialog = true
            this.coperateData = JSON.parse(sessionStorage.coperateData)
        },
        addHotel() {
            location.href = "/searchhotel";
        },
        editHotel(tl, tr){
            console.log(tl, tr)
        },
        removeTraveler() {
            this.travel = null;
        },
        getCountryCodeHotel(Countryinput) {
            if (Countryinput) {
                const checkItem = this.CountryItem.filter((el) => el.text.toLowerCase().substr(0, Countryinput.length) === Countryinput.toLowerCase());
                if (checkItem.length === 0) {
                    axios.post(`${this.$store.state.API_PATH}CountryCode/GetCountryCode`, {
                        Code: Countryinput,
                        Language: this.$store.state.Language,
                    }, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    })
                        .then((resCountrie) => {
                            if (resCountrie.data.Status) {
                                this.CountryItem = []
                                resCountrie.data.Result.forEach((elm) => {
                                    this.CountryItem.push({
                                        ...elm,
                                        text: `${elm.Name} (${elm.Code})`,
                                    })
                                })
                            }
                        })
                        .catch((err) => err);
                }
            }
        },
        checkInDateFormat(date) {
            return moment(date).format("ddd, DD MMM YYYY")
        },
        sumDate(val){
            return moment(val).diff(moment(), 'hours')
        },
        async getBookingClassDatabase(){
            await axios.post(`${this.$store.state.API_PATH}BookingClass/GetBookingClass`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                if (res.data.Status) {
                    this.BookingClass = res.data.Result
                    }
            }).catch(() => {
            })
        },
        closeDialogActionStatus(){
            this.dialogActionStatus = false
            this.TravelerOID = ""
            this.titleActionStatus = ""
            this.TRNoText = ""
            this.fullnameText = ""
            this.statusMytask = ""
            this.statusMytaskMsg = ""
            this.BudgetHolderApprover = ""
            this.LineApprovalD = ""
            this.noteTR = ""
        },
        confirmDialogActionStatus() {
            let isApproved = ""
            let StatusAction = ""
            if (this.BudgetHolderApprover) {
                isApproved = "Budget Holder"
            }
            else if (this.LineApprovalD) {
                isApproved = "Line Approve"
            }
            const Status = [this.txtApproveFinal, this.txtPendingLineApprover, this.txtPendingBudgetHolder]
            if (this.statusMytask !== Status[0]) {
                StatusAction = this.statusMytask
                this.BudgetHolderApprover = ""
                this.LineApprovalD = ""
            }
            else {
                if (this.BudgetHolderApprover === this.LineApprovalD || (this.BudgetHolderApprover && this.LineApprovalD)) {
                    StatusAction = Status[0]
                }
                else if (this.BudgetHolderApprover && !this.LineApprovalD) {
                    StatusAction = Status[1]
                }
                else {
                    StatusAction = Status[2]
                }
            }
            const PROFILE = JSON.parse(localStorage.PROFILE)
            setTimeout(() => {
                this.dialogActionStatus = false
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}TravelRequest/UpdateStatusMytask`, {
                    TRNo: this.TRNoText,
                    TravelerArrID: this.TravelerArrID,
                    BudgetHolderApprover: this.BudgetHolderApprover,
                    LineApprovalD: this.LineApprovalD,
                    TravelRequestOID: this.TravelRequestOID,
                    Status: StatusAction,
                    Note: this.noteTR,
                    ActionName: `${PROFILE.FIRSTNAME} ${PROFILE.LASTNAME} ( ${PROFILE.POSITION} )`,
                    IsApproved: isApproved,
                    EMPLOYEE_ID: PROFILE.EMPLOYEE_ID
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(async(res) => {
                    this.$store.state.LOADING = false
                    if (res.data.Status) {
                        this.$store.commit("travelRequest/getTRLifeCycleByPermission", {
                            TRNo: this.TRNo
                        })
                        this.$store.commit("travelRequest/getTravelRequestLogByTRNo", {
                            TRNo: this.TRNo
                        })
                        const formReqID = JSON.parse(sessionStorage.formReqID);
                        formReqID.Pending = 0
                        sessionStorage.formReqID = formReqID
                        Swal.fire({
                            icon: 'success',
                            text: `${this.statusMytaskMsg} Success`,
                            confirmButtonColor: '#00AEEF'
                        }).then(()=>{
                            this.travel.canBudgetHolder = 0
                            this.travel.canLineApproved = 0
                        },()=>{})
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: res.data.ErrorMessage,
                            confirmButtonColor: '#00AEEF'
                        }).then(() => { window.location.href = "/" }, () => { })
                    }
                }).catch(() => {
                    this.$store.state.LOADING = false
                    window.location.href = "/"
                })
            }, 100)
        },
        async actionStatusMytask(type, obj) {
            if (this.$store.state.ItemPending.length > 0) {
                const haveTraveller = this.$store.state.ItemPending.filter(x=>x.EMPLOYEE_ID === obj.emp_ID)
                if(haveTraveller.length > 0){
                    const PROFILE = JSON.parse(localStorage.PROFILE)
                    const item = haveTraveller[0]
                    for(const elm of item.TravelerArrID){
                        await new Promise((resolve)=>{
                            if (PROFILE.EMPLOYEE_ID === item.TravelRequestApprover) {
                                elm.BudgetHolder = PROFILE.EMPLOYEE_ID
                            }
                            else if (item.BudgetHolderApprover === item.TravelRequestApprover) {
                                elm.BudgetHolder = item.BudgetHolderApprover
                            }
                            else {
                                elm.BudgetHolder = ""
                            }
                            if(!elm.LineApprover){
                                if (item.LineApprovedArr.filter(x=>x.Traveller === elm.Traveller)[0].Approved.split(",")
                                .filter(x=>x === PROFILE.EMPLOYEE_ID).length > 0) {
                                    elm.LineApprover = PROFILE.EMPLOYEE_ID
                                }
                                else if (item.LineApprovalD === item.LINE_APPROVE) {
                                    elm.LineApprover = item.LineApprovalD
                                }
                                else {
                                    elm.LineApprover = ""
                                }
                            }
                            if(type === "Approve"){
                                if(elm.BudgetHolder && elm.LineApprover){
                                    elm.Status = this.txtApproveFinal
                                }
                                else if(elm.BudgetHolder){
                                    elm.Status = this.txtPendingLineApprover
                                }
                                else if(elm.LineApprover){
                                    elm.Status = this.txtPendingBudgetHolder
                                }
                            }
                            else{
                                elm.Status = type
                            }
                            setTimeout(()=>{
                                resolve()
                            }, 1)
                        })
                    }
                    this.statusMytask = type
                    this.statusMytaskMsg = type
                    if (type === "Approve") {
                        this.statusMytask = this.txtApproveFinal
                        this.titleActionStatus = "Approve Confirmation !"
                    }
                    else if (type === "Return") {
                        this.titleActionStatus = "Return Confirmation !"
                    }
                    else {
                        this.titleActionStatus = "Reject Confirmation !"
                    }
                    this.TravelerOID = item.TravelerOID
                    this.TravelerArrID = item.TravelerArrID
                    this.TRNoText = item.TRNo
                    this.fullnameText = obj.fullname
                    this.TravelRequestOID = item.TravelRequestOID
                    this.dialogActionStatus = true
                }
            }
        },
        confirmRemovePandV(){
            let req = {}
            if(this.dRemovePassport.PassportOID){
                req = {
                    PassportOID: this.dRemovePassport.PassportOID
                }
            }
            else if(this.dRemoveVisa.VisaOID){
                req = {
                    VisaOID: this.dRemoveVisa.VisaOID
                }
            }
            this.dRemovePassport.Remove = false
            this.dRemoveVisa.Remove = false
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}Traveler/${this.dRemovePassport.Type === "Passport" ? "RemovePassportInCard" : "RemoveVisaInCard"}`, req, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(res => {
                if (res.data.Status) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Success',
                        confirmButtonColor: '#00AEEF'
                    }).then(() => {
                        this.travel.passport = this.travel?.passport?.filter(x => this.dRemovePassport.PassportOID !== x.PassportOID)
                        this.travel.visa = this.travel?.visa?.filter(x => this.dRemoveVisa.VisaOID !== x.VisaOID)
                        this.$store.state.LOADING = false
                        this.dRemovePassport.PassportOID = ""
                        this.dRemoveVisa.VisaOID = ""
                    })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: res.data.ErrorMessage,
                        confirmButtonColor: '#00AEEF'
                    }).then(() => {
                        this.$store.state.LOADING = false
                    })
                }
            }, err => {
                console.log(err)
                this.$store.state.LOADING = false
            })
        },
        removePandV(p, type){
            if(type === "Passport"){
                this.dRemovePassport.Remove = true
                this.dRemovePassport.PassportOID = p.PassportOID
                this.dRemovePassport.Type = type
            }
            else if(type === "Visa"){
                this.dRemoveVisa.Remove = true
                this.dRemoveVisa.VisaOID = p.VisaOID
                this.dRemoveVisa.Type = type
            }
        },
    },
    computed: {
        getWidthDevice() {
            return window.innerWidth
        },
        sumNight() {
            return moment(this.hotelObj.CheckinDateEnd).diff(moment(this.hotelObj.CheckinDateStart), 'days')
        },
        minDate() {
            return new Date(new Date().setDate(new Date(this.hotelObj.CheckinDateStart).getDate() + 1)).toISOString().substr(0, 10)
        }
    },
    mounted() {
        sessionStorage.removeItem("SelectedItemHotel")
        sessionStorage.removeItem("StandbyItemHotel")
        sessionStorage.removeItem("isReIssue")
        sessionStorage.removeItem("HotelOID")
        this.getSpecialMeal()
        if(this.travel.flights && this.travel.flights.length > 0){
            this.ReIssueBooking = this.travel.flights.filter(x => x.OriginalBookingOID);
            this.travel.flights = this.travel.flights.filter(x => !x.OriginalBookingOID)
            this.ReIssueBooking.sort((a, b) => b.ReIssueSelected - a.ReIssueSelected)
        }
        this.formReqID = JSON.parse(sessionStorage.formReqID)
        this.empProfile = JSON.parse(localStorage.PROFILE)
        this.EMPLOYEE_ID = this.empProfile.EMPLOYEE_ID
        if(!this.travel.haveBudgetHolder && this.BudgetHolderTR.indexOf(this.empProfile.EMPLOYEE_ID) !== -1){
            this.travel.canBudgetHolder = 1
        }
        if(!this.travel.haveLineApproved && this.travel.LineApprovedTraveller){
            const haveItem = this.travel.LineApprovedTraveller.split(',').filter(x=>x === this.EMPLOYEE_ID)
            if(haveItem.length > 0 && haveItem[0] === this.EMPLOYEE_ID){
                this.travel.canLineApproved = 1
            }
        }
    },
}
</script>

<style>
.passport:hover {
    background-color: #e2e0e0;
}

.deleteFlight:hover {
    cursor: pointer;
}

.greentap {
    border-left: solid 4px #99e210;
    padding: 4px;
}

.text-traveler span {
    font-size: .6rem;
}
</style>
