<template>
    <v-row>
        <v-col cols="12">
        </v-col>
        <v-col cols="12" md="8">
            <FlightDetail v-if="RemoveFlight != null" :remove="RemoveFlight.RemoveFlight" :airline="RemoveFlight.focusFlight" :coperateData="coperateData" :travelers="RemoveFlight.Traveler"></FlightDetail>
        </v-col>
        <v-col cols="12" class="sticky" md="4">
            <ProjectDetails v-if="RemoveFlight != null" :coperateData="coperateData" 
                :airline="RemoveFlight != null && RemoveFlight.focusFlight != null ? RemoveFlight.focusFlight : null" :travelers="RemoveFlight.Traveler"
                @callContinueBooking="continueBooking = $event"
                :pnr="this.RemoveFlight != null && this.RemoveFlight.focusFlight != null ? this.RemoveFlight.focusFlight.PNR : null"
            />
        </v-col>
    </v-row>
</template>

<script lang="ts">
import ProjectDetails from '@/components/cancelFlight/ProjectDetail.vue'
import FlightDetail from '@/components/searchFlight/FlightDetail.vue'
export default {
    name: 'CancelFlights',
    components: {
        ProjectDetails,
        FlightDetail
    },
    data(){
        return{
            coperateData: null,
            RemoveFlight: null,
            flightRequest: null
        }
    },
    mounted(){
        if(sessionStorage.coperateData != null && sessionStorage.coperateData != undefined){
            this.coperateData = JSON.parse(sessionStorage.coperateData)
            this.RemoveFlight = JSON.parse(sessionStorage.RemoveFlight)
        }
    }
}
</script>
