<template>
    <v-card class="card" elevation="0">
        <v-card-text>
            Flights
        </v-card-text>
        <v-card-text>
            <template v-if="depflight != null">
                <v-card class="card" elevation="1" v-if="!changdep" @click="Changdep()">
                    <v-card-text :class="selectflight == 1 ? 'flight-select' : ''">
                        <span>
                            {{ depflight.flightDetails[0].depDisplayDateTime.shortDate }}
                        </span>
                        <br />
                        <div class="f-text-16">
                            {{ depflight.flightDetails[0].depCity.name.split(',')[0].split("Int'l")[0] }}
                            ({{ depflight.flightDetails[0].depCity.code }})
                            <img :src="require('@/assets/icons/ArrowRight.svg')" alt="" />
                            {{ depflight.flightDetails[depflight.flightDetails.length - 1].arrCity.name.split(',')[0].split("Int'l")[0] }}
                            ({{ depflight.flightDetails[depflight.flightDetails.length - 1].arrCity.code }})
                        </div>
                    </v-card-text>
                </v-card>
                <v-card class="card" elevation="1" v-else>
                    <v-card-text>
                        <div @click="Changdep()" style="cursor: pointer;">
                            <span class="f-text-16">
                                {{ depflight.flightDetails[0].depDisplayDateTime.shortDate }}
                            </span>
                            <br />
                            <span class="f-text-16">
                                {{ depflight.flightDetails[0].depCity.name.split(',')[0].split("Int'l")[0] }}
                                ({{ depflight.flightDetails[0].depCity.code }})
                                <img :src="require('@/assets/icons/ArrowRight.svg')" alt="" />
                                {{ depflight.flightDetails[depflight.flightDetails.length -
                                        1].arrCity.name.split(',')[0].split("Int'l")[0]
                                }}
                                ({{ depflight.flightDetails[depflight.flightDetails.length - 1].arrCity.code }})
                            </span>
                            <br />
                        </div>
                        <div>
                            <v-row>
                                <v-col cols="2">
                                    <br />
                                    <v-avatar size="35">
                                        <v-img :src="require('@/assets/logoAirline/square/' + depflight.flightDetails[0].airline.code + '.png')" />
                                    </v-avatar>
                                </v-col>
                                <v-col cols="9">
                                    <div class="pt-5 pr-1">
                                        <div style="height: 7px;"></div>
                                        {{ depflight.flightDetails[0].airline.name }}
                                    </div>
                                </v-col>
                                <v-col cols="4">
                                    <strong>
                                        <!-- Bisiness -->
                                    </strong>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row>
                            <v-col cols="4" class="text-center">
                                <span class="f-text-16">
                                    {{ depflight.flightDetails[0].depDisplayDateTime.displayTime }}
                                </span>
                                <br />
                                <v-chip x-small color="#ccecf8">
                                    <div class="f-text-14">
                                        {{ depflight.flightDetails[0].depCity.code }}
                                    </div>
                                </v-chip>
                            </v-col>
                            <v-col cols="4" class="text-center">
                                <div style="height: 5px;"></div>
                                <span style="position: relative;">
                                    <div class="f-text-16">
                                    {{
                                        formatDate(depflight.totalTime)
                                    }}
                                    </div>
                                    <img :src="require('../../assets/icons/betweenlineStop.svg')"
                                        style="position: absolute; top: 50%; left: 0%; transform: translate(-50%, -50%);"
                                        width="90" alt="" />
                                        <br />
                                    <span class="f-text-16" style="font-size: .7rem;" v-if="depflight.flightDetails.length == 1">
                                        Direct
                                    </span>
                                    <span class="f-text-16" v-else>
                                        {{depflight.flightDetails.length - 1}} Stop
                                    </span>
                                </span>
                            </v-col>
                            <v-col cols="4" class="text-center">
                                <span class="f-text-16">
                                    {{ depflight.flightDetails[depflight.flightDetails.length -
                                            1].arrDisplayDateTime.displayTime
                                    }}
                                </span>
                                <br />
                                <v-chip x-small color="#ccecf8">
                                    <div class="f-text-14">
                                        {{ depflight.flightDetails[depflight.flightDetails.length - 1].arrCity.code }}
                                    </div>
                                </v-chip>
                            </v-col>
                            <v-col cols="12">
                                <button v-if="selectflight == 2" @click="showYourFlight(1)"
                                  style="color:#4167fe;
                                  background-color: #ebeef5;
                                  width: 100%;
                                  border-radius: 4px; padding: 4px;
                                  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
                                  transition: 0.2s;">
                                    <div>Change Flight</div>
                                </button>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>
            <template v-else>
                <v-card-text :class="selectflight == 1 ? 'flight-select' : ''">
                    <span style="font-size: 0.7rem;">
                        Select Departure Flight
                    </span>
                </v-card-text>
            </template>
            <!--Return-->
            <br />
            <template v-if="flightRequest != null && flightRequest.tripType !== 'O'">
                <template v-if="returnflight != null">
                    <v-card class="card" elevation="1" v-if="!changreturn" @click="Changreturn()">
                        <v-card-text :class="selectflight == 2 ? 'flight-select' : ''">
                            <span>
                                {{ returnflight.flightDetails[0].depDisplayDateTime.shortDate }}
                            </span>
                            <br />
                            <div class="f-text-16">
                                {{ returnflight.flightDetails[0].depCity.name.split(',')[0].split("Int'l")[0] }}
                                ({{ returnflight.flightDetails[0].depCity.code }})
                                <img :src="require('@/assets/icons/ArrowRight.svg')" alt="" />
                                {{ returnflight.flightDetails[returnflight.flightDetails.length - 1].arrCity.name.split(',')[0].split("Int'l")[0] }}
                                ({{ returnflight.flightDetails[returnflight.flightDetails.length - 1].arrCity.code }})
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-card class="card" elevation="1" v-else>
                        <v-card-text>
                            <div @click="Changreturn()" style="cursor: pointer;">
                                <span class="f-text-16">
                                    {{ returnflight.flightDetails[0].depDisplayDateTime.shortDate }}
                                </span>
                                <br />
                                <div class="f-text-16">
                                    {{ returnflight.flightDetails[0].depCity.name.split(',')[0].split("Int'l")[0] }}
                                    ({{ returnflight.flightDetails[0].depCity.code }})
                                    <img :src="require('@/assets/icons/ArrowRight.svg')" alt="" />
                                    {{ returnflight.flightDetails[returnflight.flightDetails.length -
                                            1].arrCity.name.split(',')[0].split("Int'l")[0]
                                    }}
                                    ({{ returnflight.flightDetails[returnflight.flightDetails.length - 1].arrCity.code }})
                                </div>
                            </div>
                            <div>
                                <v-row>
                                    <v-col cols="2">
                                        <br />
                                        <v-avatar size="35">
                                            <v-img :src="require('@/assets/logoAirline/square/' + returnflight.flightDetails[0].airline.code + '.png')" />
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="9">
                                        <span class="f-text-16">
                                            <div class="pt-5 pr-1">
                                                <div style="height: 7px;"></div>
                                                {{ returnflight.flightDetails[0].airline.name }}
                                            </div>
                                        </span>
                                    </v-col>
                                    <v-col cols="1">
                                        <strong style="font-size: 0.7rem;">
                                            <!-- Bisiness -->
                                        </strong>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row>
                                <v-col cols="4">
                                    <span class="f-text-16">
                                        {{ returnflight.flightDetails[0].depDisplayDateTime.displayTime }}
                                    </span>
                                    <br />
                                    <v-chip x-small color="#ccecf8">
                                        <span class="f-text-16">
                                            {{ returnflight.flightDetails[0].depCity.code }}
                                        </span>
                                    </v-chip>
                                </v-col>
                                <v-col cols="4" class="text-center">
                                    <div style="height: 13px;"></div>
                                    <span>
                                        <span style="position: relative;">
                                            <span class="f-text-16">
                                                {{
                                                    formatDate(returnflight.totalTime)
                                                }}
                                            </span>
                                            <img :src="require('../../assets/icons/betweenlineStop.svg')"
                                                style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                                width="90"  alt="" />
                                            <span class="f-text-16" v-if="returnflight.flightDetails.length == 1">
                                                Direct
                                            </span>
                                            <span class="f-text-16" v-else>
                                                {{
                                                        returnflight.flightDetails.length
                                                        -
                                                        1
                                                }} Stop
                                            </span>
                                        </span>
                                    </span>
                                </v-col>
                                <v-col cols="4">
                                    <span class="f-text-16">
                                        {{ returnflight.flightDetails[returnflight.flightDetails.length -
                                                1].arrDisplayDateTime.displayTime
                                        }}
                                    </span>
                                    <br />
                                    <v-chip x-small color="#ccecf8">
                                        <span class="f-text-16">
                                            {{ returnflight.flightDetails[returnflight.flightDetails.length - 1].arrCity.code }}
                                        </span>
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>
                <template v-else>
                    <v-card-text :class="selectflight == 2 ? 'flight-select' : ''">
                        <span style="font-size: 0.7rem;">
                            Select Return Flight
                        </span>
                    </v-card-text>
                </template>
            </template>
        </v-card-text>
    </v-card>
</template>
<script>
import moment from 'moment'
export default {
    name: "sFilter",
    props: {
        depflight: null,
        returnflight: null,
        selectflight: 0, //dep = 1, return = 2
        flightRequest: Object,
    },
    data() {
        return {
            changdep: true,
            changreturn: false,
        }
    },
    watch: {
        selectflight(){
            if(this.selectflight === 1){
                this.$emit('returnflight', null)
            }
        },
        depflight(){
            // console.log(this.depflight)
        },
        returnflight(){
            // console.log(this.returnflight)
        }
    },
    methods: {
        formatDate(val){
            let res = ''
            if(val){
                res = `${val.slice(0, 2)}h ${val.slice(2, 4)}m`
            }
            return res
        },
        timespanFlight_v2(depflight, arrflight) {
            let result = 0
            const dep = moment(depflight).format('YYYY-MM-DD HH:mm:ss')
            const arr = moment(arrflight).format('YYYY-MM-DD HH:mm:ss')
            const cal = parseInt(moment(arr).diff(moment(dep), 'minutes', true))
            result += cal

            result = this.toHoursAndMinutes(result)
            return `${result.hours}h ${result.minutes}m`
        },
        toHoursAndMinutes(totalMinutes) {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return { hours, minutes };
        },
        Changdep() {
            this.changdep = !this.changdep;
        },
        Changreturn() {
            this.changreturn = !this.changreturn;
        },
        showYourFlight(val) {
            this.$emit("changeval", val)
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

.font-W700 {
    font-weight: 700;
}
.font-W400 {
    font-weight: 400;
}
.hideCard {
    display: none;
}

.flight-select {
    border-left: solid 4px #99e210;
}

.theme-background {
    background-color: white;
    border-radius: 10px;
    padding: 8px 8px;
}
</style>
