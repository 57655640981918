<template>
  <v-card>
    <v-card-title>
      Visa
    </v-card-title>
    <v-card-text ref="form">
    <br />
      <v-row class="bg-card">
        <v-col cols="12" md="12" xs="12">
          <br />
        </v-col>
        <v-col cols="12" md="12" xs="12">
          Type of Entry
          <v-select outlined dense v-model="Visa.VisaType" :items="VisaType" ref="VisaType" :rules="rule.VisaType" placeholder="Select Visa Type"
          background-color="white"
          ></v-select>
        </v-col>
        <v-col cols="12" md="12" xs="12">
          Visa No.
          <v-text-field outlined dense v-model="Visa.VisaNo" ref="VisaNo" :rules="rule.VisaNo"
          background-color="white"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" xs="12">
          Issue Date
          <v-menu v-model="issueDate.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:[`activator`]="{ on, attrs }">
              <v-text-field :value="formatDate(issueDate.date)" outlined dense readonly v-bind="attrs" v-on="on" ref="IssueDate"
              background-color="white"
              >
                <v-icon slot="append" color="info"> mdi-calendar </v-icon>
              </v-text-field>
            </template>
            <v-date-picker v-model="issueDate.date" @input="issueDate.menu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="12" xs="12">
          Expiry Date
          <v-menu v-model="expiryDate.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:[`activator`]="{ on, attrs }">
              <v-text-field :value="formatDate(expiryDate.date)" outlined dense readonly v-bind="attrs" v-on="on" ref="ExpiryDate"
              background-color="white"
              >
                <v-icon slot="append" color="info"> mdi-calendar </v-icon>
              </v-text-field>
            </template>
            <v-date-picker v-model="expiryDate.date" :min="nowDate" @input="expiryDate.menu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="12" xs="12">
          Country's Visa
          <v-autocomplete
              v-model="countryCode"
              @input.native="getCountryCode($event.srcElement.value)"
              :items="countryTravel"
              ref="Country"
              background-color="white"
              :rules="rule.Country"
              item-text="text"
              item-value="text"
              dense
              outlined
            ></v-autocomplete>
        </v-col>
       <v-col md="12">
        <template v-if="files && files.length > 0">
            <v-col cols="12" v-for="(fileitem, index) in files" :key="'n' + index">
                <v-card class="card">
                    <v-card-text class="bgcard">
                        <v-row>
                            <v-col cols="2">
                                <v-icon v-if="fileitem.name.split('.')
                                [fileitem.name.split('.').length - 1] == 'xlsx' || fileitem.name.split('.')
                                [fileitem.name.split('.').length - 1] == 'xls'" class="mt-3" color="success">
                                mdi-file-excel</v-icon>
                                <v-icon v-else-if="fileitem.name.split('.')
                                [fileitem.name.split('.').length - 1] == 'png'" class="mt-3" color="error">
                                mdi-file-png-box</v-icon>
                                <v-icon v-else-if="fileitem.name.split('.')
                                [fileitem.name.split('.').length - 1] == 'pdf'" class="mt-3" color="error">
                                mdi-file-pdf-box</v-icon>
                                <v-icon v-else-if="fileitem.name.split('.')
                                [fileitem.name.split('.').length - 1] == 'jpg'" class="mt-3" color="error">
                                mdi-file-jpg-box</v-icon>
                                <v-icon v-else-if="fileitem.name.split('.')
                                [fileitem.name.split('.').length - 1] == 'zip'" class="mt-3" color="#EAC282">
                                mdi-zip-box</v-icon>
                                <v-icon v-else-if="fileitem.name.split('.')
                                [fileitem.name.split('.').length - 1] == 'rar'" class="mt-3" color="#EAC282">
                                mdi-bookmark-box-multiple</v-icon>
                                <v-icon v-else-if="fileitem.name.split('.')
                                [fileitem.name.split('.').length - 1] == 'docx' || fileitem.name.split('.')
                                [fileitem.name.split('.').length - 1] == 'doc'" class="mt-3" color="primary">
                                mdi-file-word</v-icon>
                                <v-icon v-else-if="fileitem.name.split('.')
                                [fileitem.name.split('.').length - 1] == 'xml'" class="mt-3" color="primary">
                                mdi-xml</v-icon>
                                <v-icon v-else-if="fileitem.name.split('.')
                                [fileitem.name.split('.').length - 1] ==  'txt'" class="mt-3" color="#E3751C">
                                mdi-file-document</v-icon>
                                <v-icon v-else-if="fileitem.name.split('.')
                                [fileitem.name.split('.').length - 1] ==  'svg'" class="mt-3" color="#E3751C">
                                mdi-svg</v-icon>
                                <v-icon v-else class="mt-3">mdi-file-outline</v-icon>
                            </v-col>
                            <v-col cols="10" style="height: 55px;">
                                <v-icon style="float: right; cursor: pointer;" @click="remove('alert', fileitem), DialogRemove = true">
                                  mdi-close</v-icon>
                                <div class="mt-3">
                                    {{ fileitem.name.length > 15 ? fileitem.name.split('.')[0].slice(0, 10) + '...' + fileitem.name.split('.')
                                    [fileitem.name.split('.').length - 1]  : fileitem.name }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
          </template>
          <template v-else-if="VisaFile && VisaFile.length > 0">
              <v-col cols="12" v-for="(fileitem, index1) in VisaFile" :key="'o' + index1">
                  <v-card class="card">
                      <v-card-text class="bgcard">
                          <v-row>
                              <v-col cols="2" @click="openFile(fileitem)" style="cursor: pointer;">
                                  <v-icon v-if="fileitem.FileName.split('.')[1] === 'xlsx' || fileitem.FileName.split('.')[1] === 'xls'"
                                  class="mt-3" color="success">mdi-file-excel</v-icon>
                                  <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'png'" class="mt-3" color="error">
                                  mdi-file-png-box</v-icon>
                                  <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'pdf'" class="mt-3" color="error">
                                  mdi-file-pdf-box</v-icon>
                                  <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'jpg'"
                                   class="mt-3" color="error">mdi-file-jpg-box</v-icon>
                                  <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'zip'"
                                  class="mt-3" color="#EAC282">mdi-zip-box</v-icon>
                                  <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'rar'"
                                  class="mt-3" color="#EAC282">mdi-bookmark-box-multiple</v-icon>
                                  <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'docx' || fileitem.FileName.split('.')[1] === 'doc'"
                                  class="mt-3" color="primary">
                                  mdi-file-word</v-icon>
                                  <v-icon v-else-if=" fileitem.FileName.split('.')[1] === 'xml'"
                                  class="mt-3" color="primary">mdi-xml</v-icon>
                                  <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'txt'"
                                  class="mt-3" color="#E3751C">mdi-file-document</v-icon>
                                  <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'svg'"
                                  class="mt-3" color="#E3751C">
                                  mdi-svg</v-icon>
                                  <v-icon v-else class="mt-3">mdi-file-outline</v-icon>
                              </v-col>
                              <v-col cols="10" style="height: 55px;">
                                  <v-icon style="float: right; cursor: pointer;" @click="remove('alert', fileitem), DialogRemove = true">mdi-close</v-icon>
                                  <div class="mt-3" @click="openFile(fileitem)" style="cursor: pointer;">
                                      {{
                                        fileitem.FileName.length > 15 ?
                                        fileitem.FileName.slice(0, 10) + '...' +
                                        fileitem.FileName.split('.')[1] : fileitem.FileName
                                      }}
                                  </div>
                              </v-col>
                          </v-row>
                      </v-card-text>
                  </v-card>
              </v-col>
          </template>
          <div style="float: right;" v-if="VisaFile.length || files.length">(1/1)</div>
          <div style="float: right;" v-else>(0/1)</div>
          <v-dialog v-model="DialogRemove" width="500">
              <v-card>
                <v-card-title>
                    Remove
                </v-card-title>
                <v-card-text>
                  <template v-if="FileFocus && FileFocus.FilePath != undefined">
                        would you like to remove '{{FileFocus.FileName.split('\\')[FileFocus.FileName.split('\\').length - 1]}}' ?
                    </template>
                    <template v-else-if="FileFocus && FileFocus.name != undefined">
                        would you like to remove '{{FileFocus.name.split('\\')[FileFocus.name.split('\\').length - 1]}}' ?
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small text @click="DialogRemove = false">Cancel</v-btn>
                    <v-btn small color="error" @click="removeFiles(
                      FileFocus, FileFocus.FilePath == undefined ? 'NotSave' : 'Saved'
                      )">Remove</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
              depressed
              @click="InputFile">
              <v-img class="shrink mr-2" :src="require('../assets/icons/Add.svg')"
              width="22" />
              Upload File(s)
          </v-btn>
          <div style="float: right;">
            <input type="file" counter multiple hide-details="" ref="input_file" style="display: none;"
                width="22" outlined show-size background-color="white"
                accept="image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, .pdf,
                application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                @change="AddAttachFile($event)" />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="#00AEEF" @click="close()">Cancel</v-btn>
      &nbsp;
        <v-btn color="#00AEEF" style="color: white" @click="create(Visa.VisaOID != null ? 'Update' : 'Add')"
         :loading="loadingVisa">Save</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
  import moment from "moment";
  import axios from 'axios';
  export default {
    props: {
      valVisa: Object,
      _ID: String
    },
    data() {
      return {
        nowDate: moment().format("YYYY-MM-DD"),
        file: null,
        files: [],
        FileFocus: null,
        countryCode: "",
        DialogRemove: false,
        VisaFile: [],
        countryTravel: [],
        loadingVisa: false,
        VisaType: [
          "Courtesy Visa",
          "Diplomatic Visa",
          "Official Visa",
          "Diplomatic Visa",
          "Non-Immigrant Visa",
          "Tourist Visa",
          "Transit Visa",
          "Long Stay O-A, O-X",
        ],
        requireField: "This field is required",
        rule: {
          VisaType: [val => (val || '').length > 0 || this.requireField],
          Country: [val => (val || '').length > 0 || this.requireField],
          VisaNo: [val => (val || '').length > 0 || this.requireField],
          ExpiryDate: [val => (val || '').length > 0 || this.requireField],
          issueDate: [val => (val || '').length > 0 || this.requireField],
        },
        requestNewPass: ["No Service Required"],
        expiryDate: {
          date: null,
          menu: false,
          modal: false,
        },
        issueDate: {
          date: null,
          menu: false,
          modal: false,
        },
        Visa: {
          Country: null,
          VisaNo: null,
          VisaType: null,
          EMPLOYEE_ID: null,
          issueDate: null,
          ExpiryDate: null,
          VisaOID: null
        },
        employee: null,
        formatYMD: "YYYY-MM-DD"
      };
    },
    watch: {
      expiryDate() {
      }
    },
    methods: {
      InputFile(){
        this.$refs.input_file.click()
      },
      openFile(val){
        window.open(val.FilePath)
      },
      GetFile(val){
          axios.post(`${this.$store.state.API_PATH}FileUpload/GetFile?ID=${this.Visa.EMPLOYEE_ID}|${val}`).then(res => {
              this.VisaFile = res.data.Result
          })
      },
      AddAttachFile(event) {
        this.files = []
        if(event.target.files.length > 0){
          this.files.push(event.target.files[0])
        }
      },
      remove(processing, val) {
        if (processing === 'alert') {
          this.FileFocus = val
        }
        if (processing === 'remove') {
            this.removeFiles(this.FileFocus, 'Saved')
        }
      },
      removeFiles(val, key){
          if(key === 'NotSave'){
              this.files = this.files.filter(x => x.name !== val.name)
          }else if(key === 'Saved'){
              const formData = new FormData();
              for(let i = 0; i < this.files.length; i++){
                  formData.append('Visa', this.files[0])
              }
              const files = formData
              axios.post(`${this.$store.state.API_PATH}FileUpload/RemoveFile?FileOID=`+
              `${this.employee.EMPLOYEE_ID}|${val.FilePath.split('?')[0]}|${val.FileOID}|Visa|${val.FileName}`, files,
              {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              }).then(res =>{
                  if(res.data){
                      this.VisaFile = this.VisaFile.filter(x => x.FileOID !== val.FileOID)
                  }
              })
          }
          this.DialogRemove = false
      },
      formatDate(val) {
        let result = ""
        if (val) {
          result = moment(val).format("DD MMM YY")
        }
        return result
      },
      getCountryCode(input) {
        if (input) {
          const checkItem = this.countryTravel.filter((el) => el.text.toLowerCase().substr(0, input.length) === input.toLowerCase());
          if (checkItem.length === 0) {
            axios
              .post(`${this.$store.state.API_PATH}CountryCode/GetCountryCode`, {
                Code: input,
                Language: this.$store.state.Language,
              }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
              })
              .then((res) => {
                if (res.data.Status) {
                  this.countryTravel = [];
                  res.data.Result.forEach((elm) => {
                    this.countryTravel.push({
                      ...elm,
                      text: `${elm.Name} (${elm.Code})`,
                    });
                  });
                }
              })
              .catch((err) => err);
          }
        }
      },
      close() {
        this.$emit("DialogVisa", false)
      },
      async create(type) {
        if (this.employee != null) {
          const formData = new FormData()
          for(let i = 0; i < this.files.length; i++){
            formData.append('Visa', this.files[i])
          }
          this.Visa.ExpiryDate = await moment(this.expiryDate.date).format(this.formatYMD)
          this.Visa.IssueDate = await moment(this.issueDate.date).format(this.formatYMD)

          this.Visa.EMPLOYEE_ID = await this._ID ?? this.employee.EMPLOYEE_ID
          this.loadingPassport = true
          let checkvalid = false
          checkvalid = this.requireVisa()
          if(!checkvalid){
            this.Visa.Country = this.countryCode
            if(type === 'Add'){
              await axios.post(`${this.$store.state.API_PATH}Visa/CreateVisa`, this.Visa, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
              }).then(res => {
                this.$emit("VisaUpdate", res.data.Result)
                this.$emit("DialogVisa", false)
              })
            }else if(type === 'Update'){
              await axios.post(`${this.$store.state.API_PATH}Visa/ModifyVisa`, this.Visa, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
              }).then(res => {
                if(res.data?.Status){
                  this.$emit("VisaUpdate", res.data.Result)
                  this.$emit("DialogVisa", false)
                }
              })
            }
            const files = formData
            axios.post(`${this.$store.state.API_PATH}FileUpload/Upload?ID=` + this.Visa.EMPLOYEE_ID, files,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
          }
          this.loadingPassport = false
        }
      },
      requireVisa(){
        let checkvalid = false
        if(!this.$refs.VisaType.validate()){
          this.$refs.VisaType.focus()
          this.$refs.VisaType.validate(true)
          checkvalid = true
        }
        if(!this.$refs.Country.validate()){
          this.$refs.Country.focus()
          this.$refs.Country.validate(true)
          checkvalid = true
        }
        if(!this.$refs.VisaNo.validate()){
          this.$refs.VisaNo.focus()
          this.$refs.VisaNo.validate(true)
          checkvalid = true
        }
        if(!this.$refs.ExpiryDate.validate()){
          this.$refs.ExpiryDate.focus()
          this.$refs.ExpiryDate.validate(true)
          checkvalid = true
        }
        if(!this.$refs.IssueDate.validate()){
          this.$refs.IssueDate.focus()
          this.$refs.IssueDate.validate(true)
          checkvalid = true
        }
        return checkvalid
      }
    },
    mounted() {
      this.employee = JSON.parse(localStorage.PROFILE)

      if(this.valVisa !== null && this.valVisa !== undefined){
        this.Visa.VisaOID = this.valVisa.VisaOID
        this.Visa.VisaNo = this.valVisa.VisaNo
        this.Visa.VisaType = this.valVisa.VisaType
        this.Visa.EMPLOYEE_ID = this._ID === undefined ? this.valVisa.EMPLOYEE_ID : this._ID
        this.countryCode = this.valVisa.Country
        this.countryTravel.push({
          text: this.countryCode,
          Code: "",
          Language: "",
          Name: "",
          Alpha3: null
        })
        this.issueDate.date = moment(this.valVisa.IssueDate).format(this.formatYMD)
        this.expiryDate.date = moment(this.valVisa.ExpiryDate).format(this.formatYMD)
        this.GetFile('Visa')
      }
    }
  };
</script>
<style scoped>
.bg-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 10px;
}
.remark {
  color: red;
}
</style>
