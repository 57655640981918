<template>
    <v-row>
        <v-col cols="12" lg="8" md="8" sm="12">
            <v-card elevation="0" class="card">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <span class="font-W500" style="color: #747678; font-size: 20px;">Hotel Detail</span>
                        </v-col>
                    </v-row>
                    <v-row class="bgcard mt-2" no-gutters>
                        <v-col>
                            <v-row>
                                <v-col cols="12" lg="8" md="8">
                                    <v-row>
                                        <v-col class="d-flex">
                                            <span>
                                                <strong>{{ dataHotel.HTL_NAME }}</strong>
                                            </span>
                                            <v-chip small class="ml-2" color="#004FBC" dark>Hotel</v-chip>
                                            <v-rating small length="5" readonly v-model="dataHotel.HTL_RATING" color="#F9B50B" dense></v-rating>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col>
                                            <img :src="require('@/assets/icons/map-pin.svg')" width="15" alt="" />
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col>
                                            <span>
                                                Check-In {{ formatDateLL(dataHotel.CheckinDateStart) }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" lg="4" md="4">
                                    <v-img :src="dataHotel.HotelFilePath" style="height: 80px; border-radius: 5px;" width="100%" alt="" />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col>
                                    <span>
                                        1 Rooms, {{ dataHotel.Occupancy }} Guests
                                    </span>
                                    <span style="color: black;"><strong>{{ formatDateFull(dataHotel.CheckinDateStart) }} ({{ dataHotel.Night }} night)</strong></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" style="background-color: #F0F9FD;">
                                    <v-row no-gutters>
                                        <v-col cols="12" lg="4" md="4" sm="5">
                                            <span style="color: black;">Check-in : {{ checkInDateFormat(dataHotel.CheckinDateStart) }}</span>
                                        </v-col>
                                        <v-col>
                                            <span style="color: black;">Check-out : {{ checkInDateFormat(dataHotel.CheckinDateEnd) }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col cols="12" lg="4" md="4">
                                    <v-img v-if="dataHotel.HotelRoomFilePath" :src="dataHotel.HotelRoomFilePath" style="height: 100px; border-radius: 5px;" width="100%" alt="" />
                                    <div v-else class="text-center">
                                        No Image
                                    </div>
                                </v-col>
                                <v-col cols="12" lg="6" md="6" style="color: black;">
                                    <v-row>
                                        <v-col>
                                            <span>(1x) {{ dataHotel.RoomType }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters align="center">
                                        <v-col cols="1">
                                            <v-img class="mr-1 shrink" max-width="25" :src="require(('@/assets/icons/Vector.svg'))" alt=""></v-img>
                                        </v-col>
                                        <v-col>
                                            <span>
                                                {{ dataHotel.BedType }}
                                            </span>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-img class="mr-1 shrink mx-auto" max-width="17" :src="require(('@/assets/icons/Guest.svg'))" alt=""></v-img>
                                        </v-col>
                                        <v-col>
                                            <span>{{ dataHotel.Occupancy }} Guest</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <span class="font-W500" style="color: #747678; font-size: 20px;">Contact Detail</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card elevation="0" outlined class="card">
                                <v-row class="ma-1" style="background-color: #F8F9FA;">
                                    <v-col>
                                        <span style="color: #747678;">Name</span><br>
                                        <span>{{ dataHotel.TITLE }} {{ dataHotel.FIRSTNAME }} {{ dataHotel.LASTNAME }}</span>
                                    </v-col>
                                    <v-col>
                                        <span style="color: #747678;">Email</span><br>
                                        <span>{{ dataHotel.EMAIL_ID }}</span>
                                    </v-col>
                                    <v-col>
                                        <span style="color: #747678;">Office Phone No.</span><br>
                                        <span>-</span>
                                    </v-col>
                                    <v-col>
                                        <span style="color: #747678;">mobile Phone No.</span><br>
                                        <span>{{ dataHotel.MOBILE_NO }}</span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <span class="font-W500" style="color: #747678; font-size: 20px;">Traveler Information</span>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2" v-for="(item, index) in dataHotel.Traveler" :key="index" no-gutters>
                        <v-col>
                            <v-card elevation="0" outlined class="card">
                                <v-row class="ma-1">
                                    <v-col>
                                        <span>
                                            <strong>Traveler {{ index + 1 }} (Internal)</strong>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-1" style="background-color: #F8F9FA; border: 1px solid #EBEEF4;">
                                    <v-col>
                                        <v-row>
                                            <v-col>
                                                <span>
                                                    <strong>Personal Data</strong>
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <span>
                                                    <span style="color: #747678;">Title</span><br>
                                                    {{ item.title }}
                                                </span>
                                            </v-col>
                                            <v-col>
                                                <span>
                                                    <span style="color: #747678;">First name</span><br>
                                                    {{ item.firstname }}
                                                </span>
                                            </v-col>
                                            <v-col>
                                                <span>
                                                    <span style="color: #747678;">Mid name</span><br>
                                                    -
                                                </span>
                                            </v-col>
                                            <v-col>
                                                <span>
                                                    <span style="color: #747678;">Last name</span><br>
                                                    {{ item.lastname }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <span>
                                                    <span style="color: #747678;">Email</span><br>
                                                    {{ item.email }}
                                                </span>
                                            </v-col>
                                            <v-col>
                                                <span>
                                                    <span style="color: #747678;">Division</span><br>
                                                    {{ item.div }}
                                                </span>
                                            </v-col>
                                            <v-col>
                                                <span>
                                                    <span style="color: #747678;">Department</span><br>
                                                    {{ item.dep }}
                                                </span>
                                            </v-col>
                                            <v-col>
                                                <span>
                                                    <span style="color: #747678;">Position</span><br>
                                                    {{ item.position }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <span>
                                                    <span style="color: #747678;">Office Phone No.</span><br>
                                                    -
                                                </span>
                                            </v-col>
                                            <v-col>
                                                <span>
                                                    <span style="color: #747678;">mobile Phone No.</span><br>
                                                    {{ item.mobile }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" class="pa-0 ma-0">
                            <v-checkbox hide-details v-model="checkbox1"
                            label="Agree to all of the above conditions. I have read all the terms carefully."></v-checkbox>
                            <v-checkbox hide-details v-model="checkbox2"
                            label="Confirmed All review of traveler details are correct."></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="12">
            <div class="sticky">
                <v-card elevation="0" class="card">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <span class="font-W500" style="color: #747678; font-size: 20px;">Project Details</span>
                            </v-col>
                        </v-row>
                        <v-row class="bgcard ma-1">
                            <v-col class="pa-0">
                                <v-row>
                                    <v-col>
                                        <span style="color: black;">
                                            <strong>Budget Holder</strong>
                                        </span>
                                    </v-col>
                                    <v-col class="text-right">
                                        <span style="color: black;">
                                            <strong>{{ coperateData.BudgetHolder }}</strong>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <span style="color: black;">
                                            Project
                                        </span>
                                    </v-col>
                                    <v-col class="text-right">
                                        <span style="color: black;">
                                            {{ coperateData.ProjectName }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <span style="color: black;">
                                            Company
                                        </span>
                                    </v-col>
                                    <v-col class="text-right">
                                        <span style="color: black;">
                                            {{ coperateData.CompanyName }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <span style="color: black;">
                                            Business Start-End
                                        </span>
                                    </v-col>
                                    <v-col class="text-right">
                                        <span style="color: black;">
                                            {{ formatDateMMMDYYYY(coperateData.startDate) }} - {{ formatDateMMMDYYYY(coperateData.endDate) }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <span class="font-W500" style="color: #747678; font-size: 20px;">Payment Detail</span>
                            </v-col>
                        </v-row>
                        <v-row class="bgcard ma-1">
                            <v-col class="pa-0">
                                <v-row>
                                    <v-col>
                                        <span style="color: black;">
                                            (1x) {{  dataHotel.RoomType }}
                                            ({{ dataHotel.BedType }}) ({{ dataHotel.Night }} night)
                                        </span>
                                    </v-col>
                                    <v-col class="text-right">
                                        <span style="color: black;">
                                            {{ dataHotel.HOTEL_PRICE ? dataHotel.HOTEL_PRICE.toLocaleString('th', {
                                                minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0 }}/night
                                            <br />
                                            THB {{ (dataHotel.HOTEL_PRICE * dataHotel.Night).toLocaleString('th', {
                                                minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <span style="color: black;">
                                            Taxes and fees
                                        </span>
                                    </v-col>
                                    <v-col class="text-right">
                                        <span style="color: black;">
                                            THB 0.00
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <span style="color: black;">
                                            <strong>Total</strong>
                                        </span>
                                    </v-col>
                                    <v-col class="text-right">
                                        <span style="color: black;">
                                            <strong>
                                                THB {{ (dataHotel.HOTEL_PRICE * dataHotel.Night).toLocaleString('th', {
                                                minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                            </strong>
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row align-content="center">
                            <v-col class="text-right">
                                <v-btn outlined color="primary" @click="backPage" class="mr-2" width="135">Cancel</v-btn>
                                <v-btn elevation="0" color="primary" width="135" :disabled="!checkbox1 || !checkbox2"
                                @click="!$store.state.isReIssue ? addHotelBooking() : reIssueHotelBooking()">Confirm booking</v-btn>
                                <v-dialog v-model="dialogฺBookingComplete" width="500" persistent>
                                    <div style="border: solid .6px #F8F9FA; background-color: #F8F9FA; padding: 15px; border-radius: 5px;">
                                        <v-card-title>
                                            <v-spacer></v-spacer>
                                            <h3>
                                                Booking Complete
                                            </h3>
                                            <v-spacer></v-spacer>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row class="text-center">
                                                <v-col>
                                                    This booking is valid for 36 hours. <br />
                                                    Please confirm your booking within the specified time.
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                        <v-card elevation="0">
                                            <v-card-title>
                                                <v-spacer></v-spacer>
                                                <span style="font-size: 1rem; color:#00AEEF">
                                                    {{ formatDateDDMMYYYY_HHMM() }}
                                                </span>
                                                <v-spacer></v-spacer>
                                            </v-card-title>
                                        </v-card>
                                        <br />
                                        <v-card elevation="0">
                                            <v-card-title>
                                                <h5>
                                                    Traveler
                                                </h5>
                                                <v-spacer></v-spacer>
                                                <h5>
                                                    Booking Ref.
                                                </h5>
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-row v-for="(item, index) in BookingCompleteItem" :key="index">
                                                    <v-col cols="8">
                                                        {{ `${item.FIRSTNAME} ${item.LASTNAME}` }}
                                                    </v-col>
                                                    <v-col cols="4" style="color: #7ab800;">
                                                        <strong style="float: right;">
                                                            {{ item.BookingID }}
                                                        </strong>
                                                    </v-col>
                                                </v-row>
                                                <br />
                                            </v-card-text>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="primary" @click="closeHotelBooking()" small>Back to Travel Request</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </div>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
    name: "FlightDetail",
    props: {
        formContinue: Boolean,
    },
    data() {
        return {
            BedType: [],
            RoomType: [],
            BookingCompleteItem: [],
            dialogฺBookingComplete: false,
            checkbox1: false,
            checkbox2: false,
            FareCondition: [],
            isActive: false,
            tab: null,
            i: 1,
            Hotel: [
                {
                    HotelID: "1",
                    TitleHotelName: "The Milestone Hotel",
                    Rating: 5,
                    LocationHotel: "Kensington Court, Central London, London, England, W8 5DL",
                    DescriptionHotel: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda, illum.",
                    RoomDetail: [
                        {
                            RoomTitle: "Superior King or Twin Room",
                            RoomDescription: "Beds 1 double or 2 Twins"
                        }
                    ],
                    Pricing: "25,000.00 - 30,000.00",
                    TitleImageHotel: "Hotel.jpg",
                    Room:
                        {
                            TitleRoomName: "Superior Room (Double)",
                            Bed: "1 Queen Bed",
                            Guest: 2,
                            Feature: ["Free Breakfast", "Free WIFI"],
                            PriceRoomHotel: "21,000.00",
                            ImageRoom: "Room1.jpg"
                        }
                }
            ],
            dataHotel: {},
            coperateData: {}
        }
    },
    methods: {
        backPage(){
            sessionStorage.removeItem("YourHotel")
            this.$store.state.showHotelInfo = true
            this.$store.state.showHotelTripSum = false
        },
        closeHotelBooking(){
            sessionStorage.removeItem("YourHotel")
            window.location = "/createTravelRq"
        },
        addHotelBooking(){
            this.$store.state.LOADING = true
            const PROFILE = JSON.parse(localStorage.PROFILE)
            axios.post(`${this.$store.state.API_PATH}HotelBooking/InsertHotelBooking`,{
                HotelName: this.dataHotel.HTL_NAME,
                Description: this.dataHotel.HTL_DESC,
                HTL_ID: this.dataHotel.HTL_ID,
                HTL_RATING: this.dataHotel.HTL_RATING,
                HTPRC_ID: this.dataHotel.HTPRC_ID,
                HotelFilePath: this.dataHotel.HotelFilePath,
                Country: this.dataHotel.Country,
                HotelRoomFilePath: this.dataHotel.HotelRoomFilePath,
                CityorProvince: this.dataHotel.CityorProvince,
                RMT_ID: this.dataHotel.RMT_ID,
                BDT_ID: this.dataHotel.BDT_ID,
                NumberOfRoom: 1,
                BedType: this.dataHotel.BDT_NAME,
                Occupancy: this.dataHotel.Occupancy,
                PricePerNight: Number(this.dataHotel.HOTEL_PRICE),
                Amount: Number(this.dataHotel.HOTEL_PRICE * this.dataHotel.Night),
                Currency: "THB",
                CheckinDateStart: this.dataHotel.CheckinDateStart,
                CheckinDateEnd: this.dataHotel.CheckinDateEnd,
                Traveler: this.dataHotel.Traveler,
                Night: Number(this.dataHotel.Night),
                CREATED_BY: PROFILE.EMPLOYEE_ID,
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then((res) => {
                if (res.data.Status) {
                    this.$store.state.LOADING = false
                    this.BookingCompleteItem = res.data.Result
                    this.dialogฺBookingComplete = true
                }
                else{
                    this.$store.state.LOADING = false
                    window.location.reload()
                }
            })
            .catch(() => {
                this.$store.state.LOADING = false
                window.location.reload()
            })
        },
        reIssueHotelBooking(){
            this.$store.state.LOADING = true
            const PROFILE = JSON.parse(localStorage.PROFILE)
            axios.post(`${this.$store.state.API_PATH}HotelBooking/ReIssueHotelBooking`,{
                HotelName: this.dataHotel.HTL_NAME,
                Description: this.dataHotel.HTL_DESC,
                HTL_ID: this.dataHotel.HTL_ID,
                HTPRC_ID: this.dataHotel.HTPRC_ID,
                HTL_RATING: this.dataHotel.HTL_RATING,
                HotelFilePath: this.dataHotel.HotelFilePath,
                HotelRoomFilePath: this.dataHotel.HotelRoomFilePath,
                Country: this.dataHotel.Country,
                CityorProvince: this.dataHotel.CityorProvince,
                RMT_ID: this.dataHotel.RMT_ID,
                BDT_ID: this.dataHotel.BDT_ID,
                NumberOfRoom: 1,
                Occupancy: this.dataHotel.Occupancy,
                BedType: this.dataHotel.BDT_NAME,
                PricePerNight: Number(this.dataHotel.HOTEL_PRICE),
                Amount: Number(this.dataHotel.HOTEL_PRICE * this.dataHotel.Night),
                Currency: "THB",
                CheckinDateStart: this.dataHotel.CheckinDateStart,
                CheckinDateEnd: this.dataHotel.CheckinDateEnd,
                Night: Number(this.dataHotel.Night),
                Traveler: this.dataHotel.Traveler,
                CREATED_BY: PROFILE.EMPLOYEE_ID,
                HotelOID: this.$store.state.HotelOID ?? ""
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then((res) => {
                if (res.data.Status) {
                    sessionStorage.removeItem("isReIssue")
                    sessionStorage.removeItem("HotelOID")
                    this.$store.state.LOADING = false
                    this.BookingCompleteItem = res.data.Result
                    this.dialogฺBookingComplete = true
                }
                else{
                    this.$store.state.LOADING = false
                    window.location.reload()
                }
            })
            .catch(() => {
                this.$store.state.LOADING = false
                window.location.reload()
            })
        },
        ShowDetails() {
            this.isActive = !this.isActive;
        },
        formatDateDDMMYYYY_HHMM(date){
            return moment().format("DD/MM/YYYY hh:mm")
        },
        formatDateLL(date){
            return moment(date).format("ll")
        },
        checkInDateFormat(date){
            return moment(date).format("ddd, DD MMM YYYY")
        },
        formatDateFull(date){
            return moment(date).format("DD MMM YYYY")
        },
        formatDateMMMDYYYY(date){
            return moment(date).format("MMM DD, YYYY")
        },
    },
    mounted(){
        const txtFareCondition = "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        this.FareCondition = [
            { title: "MIN STAY", text: txtFareCondition },
            { title: "MAX STAY", text: txtFareCondition },
            { title: "SALES RESTRICTIONS", text: txtFareCondition },
            { title: "ADVANCE PURCHASE / RESERVATIONS AND TICKETING", text: txtFareCondition },
            { title: "PENALTIES", text: txtFareCondition },
        ]
        if(sessionStorage.YourHotel){
            this.dataHotel = JSON.parse(sessionStorage.YourHotel)
            this.coperateData = JSON.parse(sessionStorage.coperateData)
        }
    }
}
</script>
<style scoped>
.text-profile {
    color: black;
    font-weight: 500;
}
.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
}
</style>
