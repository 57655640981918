<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card elevation="0" title>
                    <v-card-text>
                        <v-row no-gutters align="center" justify="center">
                            <v-col lg="4" cols="8">
                                Subject
                                <v-text-field outlined dense background-color="white" v-model="TEXT_SEARCH"
                                    @keypress.enter="getNews()"></v-text-field>
                            </v-col>
                            <v-col md="auto" sm="auto">
                                <v-btn color="primary" elevation="0" class="ml-1 mb-2" @click="getNews()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card elevation="0" title>
                    <v-card-text>
                        <v-row no-gutters align="center">
                            <v-col class="text-lg-right text-sm-right text-center">
                                <v-btn class="btn-table mr-2" elevation="0" @click="deleteNews()" v-if="$store.state.buttonPermit.menuConfig.Delete">
                                    <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1"></v-img>
                                    Delete
                                </v-btn>
                                <v-btn class="btn-table mr-2" elevation="0" @click="resetInfo()">
                                    <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1"></v-img>
                                    Reset
                                </v-btn>
                                <v-btn class="btn-table" elevation="0" @click="actionNews('Add')" v-if="$store.state.buttonPermit.menuConfig.Add">
                                    <v-img :src="require('@/assets/icons/Add_trans.svg')" class="mr-1"></v-img>
                                    Add
                                </v-btn>
                                <v-dialog v-if="dialogAddOrEditNews" v-model="dialogAddOrEditNews" persistent width="1000px" :retain-focus="false">
                                    <v-card elevation="0" title color="primary" class="pt-1 card">
                                        <v-card>
                                            <v-card-text>
                                                <v-card class="card-detail" title elevation="0">
                                                    <v-card-title>
                                                        <v-row no-gutters>
                                                            <v-col class="text-center">
                                                                {{ AddOrEdit }} News
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                Order
                                                                <v-text-field outlined dense background-color="white"
                                                                    v-model="Order"></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                Date<span style="color: red;">*</span>
                                                                <v-menu v-model="menuDateNews"
                                                                    :close-on-content-click="false" :nudge-right="40"
                                                                    transition="scale-transition" offset-y min-width="auto">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-text-field outlined dense
                                                                            placeholder="Select Date"
                                                                            :value="DateNews ? formatDateLL(DateNews) : ''"
                                                                            readonly v-bind="attrs" v-on="on"
                                                                            :rules="rule.DateNews" ref="DateNews">
                                                                            <template v-slot:append>
                                                                                <v-icon
                                                                                    color="#00AEEF">mdi-calendar-month</v-icon>
                                                                            </template>
                                                                        </v-text-field>
                                                                    </template>
                                                                    <v-date-picker v-model="DateNews"
                                                                        @input="menuDateNews = false" :rules="rule.DateNews"
                                                                        color="#00AEEF"></v-date-picker>
                                                                </v-menu>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                Preview<span style="color: red;">*</span>
                                                                <v-textarea outlined dense background-color="white"
                                                                    v-model="Preview" ref="Preview"
                                                                    :rules="rule.Preview"></v-textarea>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                Subject<span style="color: red;">*</span>
                                                                <v-text-field outlined dense background-color="white"
                                                                    v-model="Subject" ref="Subject"
                                                                    :rules="rule.Subject"></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                Image Cover
                                                                <v-file-input outlined dense accept="image/*"
                                                                    @change="attachImage()"
                                                                    v-model="fileImg"></v-file-input>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                Description

                                                                <editor v-if="showMce" v-model="Description"
                                                                    api-key="zme9n7btzvgd8wqufmthz6wgggu9n3z8e45y1ehvd2r3yf8a"
                                                                    :init="$store.state.configTinyMce" />
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-card-text>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col class="text-center">
                                                        <v-btn outlined color="primary" class="mr-1"
                                                            @click="closeAddOrEditNews()">Close</v-btn>
                                                        <v-btn color="primary" class="ml-1" v-if="AddOrEdit === 'Add'"
                                                            @click="validAddOrEditNews()">Save</v-btn>
                                                        <v-btn color="primary" class="ml-1" v-else
                                                            @click="validAddOrEditNews()">Save</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row><br>
                        <v-card elevation="0">
                            <v-data-table :headers="headerNews" :items="itemNews" class="elevation-1" item-key="ID"
                                @click:row="rowClicked" show-select v-model="selectedItem" checkbox-color="primary"
                                mobile-breakpoint style="white-space: nowrap" hide-default-footer height="45vh"
                                @page-count="pageCount = $event" :page.sync="pageNews" :items-per-page="itemPageNews">
                                <template v-slot:[`item.no`]="{ index }">
                                    {{ index + 1 }}
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-img style="cursor: pointer;" :src="require('@/assets/icons/Source_File.svg')"
                                        @click="getReturnDetail(item)"></v-img>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import imageCompression from 'browser-image-compression';
import * as moment from 'moment'
import Swal from 'sweetalert2';
import axios from "axios";
export default {
    components: {
        'editor': Editor
    },
    data() {
        return {
            showMce: false,
            checkedId: [],
            fileImg: null,
            selectedItem: [],
            pageCount: 2,
            pageNews: 1,
            itemPageNews: 10,
            itemPagePageNews: [
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemNews: [],
            headerNews: [
                { text: 'No.', value: 'no', sortable: false },
                { text: 'Effective Date.', value: 'DateInTable', sortable: false },
                { text: 'Subject', value: 'Subject', sortable: false },
                { text: 'Preview', value: 'Preview', sortable: false },
            ],
            Order: "",
            Subject: "",
            Preview: "",
            AddOrEdit: "",
            DateNews: "",
            ImgCover: "",
            menuDateNews: false,
            dialogAddOrEditNews: false,
            TEXT_SEARCH: "",
            rule: {
                DateNews: [v => !!v || 'Date is required'],
                Subject: [v => !!v || 'Subject is required'],
                Preview: [v => !!v || 'Preview is required'],
            },
            Description: "",
            ID: "",
        }
    },
    methods: {
        async attachImage() {
            if (this.fileImg) {
                const file = await imageCompression(this.fileImg, { maxSizeMB: 0.1, useWebWorker: true })
                const reader = new FileReader()
                reader.onload = (event) => {
                    this.ImgCover = event.target.result
                }
                reader.readAsDataURL(file)
            }
        },
        rowClicked(item) {
            if(this.$store.state.buttonPermit.menuConfig.Update){
                this.AddOrEdit = "Edit"
                this.ID = item.ID
                this.Order = item.Order
                this.Subject = item.Subject
                this.Preview = item.Preview
                this.DateNews = item.Date
                this.ImgCover = item.ImgCover
                axios.post(this.$store.state.API_PATH + "News/GetNewsDescription", {
                    "ID": `${item.ID}`
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(async (res) => {
                    if (res.data.Status) {
                        this.Description = res.data.Result[0].Description
                    }
                    setTimeout(() => {
                        this.showMce = true
                    }, 1)
                    this.dialogAddOrEditNews = true
                }).catch(err => {
                    this.$store.state.LOADING = false
                    console.log(err)
                })
            }
        },
        validAddOrEditNews() {
            if (!this.$refs.DateNews.validate() || !this.$refs.Subject.validate()) {
                if (!this.$refs.DateNews.validate()) {
                    this.$refs.DateNews.focus()
                }
                else {
                    this.$refs.Subject.focus()
                }
            }
            else {
                const PROFILE = JSON.parse(localStorage.PROFILE)
                this.$store.state.LOADING = true
                this.dialogAddOrEditNews = false
                const req = {
                    Order: this.Order,
                    Date: this.DateNews,
                    ID: this.ID,
                    Subject: this.Subject,
                    Preview: this.Preview,
                    Description: this.Description,
                    PinPost: 0,
                    ImgCover: this.ImgCover,
                    Cby: PROFILE.EMPLOYEE_ID,
                }
                if (this.AddOrEdit === "Add") {
                    this.addNews(req)
                }
                else {
                    this.updateNews(req)
                }
            }
        },
        closeAddOrEditNews() {
            this.dialogAddOrEditNews = false
            this.Order = ""
            this.Subject = ""
            this.Preview = ""
            this.AddOrEdit = ""
            this.DateNews = ""
            this.Description = ""
        },
        addNews(req) {
            axios.post(this.$store.state.API_PATH + "News/InsertNews", req, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.resApiAddOrEditNews(res)
            }).catch(err => {
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        updateNews(req) {
            axios.post(this.$store.state.API_PATH + "News/UpdateNews", req, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.resApiAddOrEditNews(res)
            }).catch(err => {
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        resApiAddOrEditNews(res) {
            this.$store.state.LOADING = false
            if (res.data.Status) {
                Swal.fire({
                    icon: 'success',
                    text: 'Success',
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.getNews()
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: res.data.ErrorMessage,
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    window.location.reload()
                })
            }
        },
        deleteNews() {
            if (this.selectedItem.length > 0) {
                Swal.fire({
                    icon: 'warning',
                    text: 'would you like to delete?',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    confirmButtonColor: '#00AEEF'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.state.LOADING = true
                        axios.post(this.$store.state.API_PATH + 'News/DeleteNews', {
                            ID: this.selectedItem.map(val => `${val.ID}`).toString(),
                        }, {
                            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        })
                            .then(res => {
                                this.$store.state.LOADING = false
                                if (res.data.Status) {
                                    Swal.fire({
                                        icon: 'success',
                                        text: 'Success',
                                        confirmButtonColor: '#00AEEF'
                                    }).then(() => {
                                        this.selectedItem = []
                                        this.getNews()
                                    }, () => { })
                                }
                                else {
                                    Swal.fire({
                                        icon: 'error',
                                        text: res.data.ErrorMessage,
                                        confirmButtonColor: '#00AEEF'
                                    }).then(() => {
                                    }, () => { })
                                }
                            })
                            .catch(() => {
                                this.$store.state.LOADING = false
                            })
                    }
                })
            }
        },
        resetInfo() {
            this.getNews()
        },
        actionNews(AddOrEdit) {
            this.AddOrEdit = AddOrEdit
            this.dialogAddOrEditNews = true
            setTimeout(() => {
                this.showMce = true
            }, 1)
        },
        getNews() {
            this.itemNews = []
            this.$store.state.LOADING = true
            axios.post(this.$store.state.API_PATH + "News/GetNews", {
                Subject: this.TEXT_SEARCH
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then((res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.itemNews = res.data.Result
                    const menuConfigData = localStorage.getItem('menuConfig');
                    this.$store.state.menuConfig = JSON.parse(menuConfigData);
                }
            }).catch(err => {
                this.$store.state.LOADING = false
                console.log(err)
            })
        },
        formatDateLL(date) {
            return date ? moment(date).format("ll") : moment().format("ll")
        },
    },
    mounted() {
        this.getNews()

    },
}
</script>
