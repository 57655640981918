<template>
    <div class="sticky">
        <v-card class="card">
            <v-card-title>
                Project Details
            </v-card-title>
            <v-card-text>
                <div style="border: solid .6px #c3c3c3; padding: 15px; border-radius: 5px;">
                    <v-row>
                        <v-col cols="4">
                            <strong>
                                Budget Holder
                            </strong>
                        </v-col>
                        <v-col cols="8">
                            <strong style="float: right;">
                                {{ coperateData.BudgetHolder }}
                            </strong>
                        </v-col>
                        <v-col cols="4">
                            <strong>
                                Project
                            </strong>
                        </v-col>
                        <v-col cols="8">
                            <span style="float: right;">
                                {{ coperateData.ProjectName }}
                            </span>
                        </v-col>
                        <v-col cols="4">
                            <strong>
                                Company
                            </strong>
                        </v-col>
                        <v-col cols="8">
                            <span style="float: right; font-size: .8rem;">
                                {{ coperateData.CompanyName && coperateData.CompanyName.length > 20 ?
                                    coperateData.CompanyName.slice(0, 20) + '...' : 'false'
                                }}
                            </span>
                        </v-col>
                        <v-col cols="4">
                            <strong>
                                Bussiness
                                <br />
                                Start-End
                            </strong>
                        </v-col>
                        <v-col cols="8">
                            <span style="float: right; font-size: .8rem;">
                                {{ formatDatepicker(coperateData.startDate) + " to " +
                                    formatDatepicker(coperateData.endDate)
                                }}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            <strong>
                                Purpose of Travel
                            </strong>
                        </v-col>
                        <v-col cols="6">
                            <span style="float: right;">
                                {{ coperateData.PurId }}
                            </span>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>

            <v-card-title>
                Payment Details
            </v-card-title>
            <v-card-text>
                <div v-if="setSession && ticketPricingModified"
                    style="border: solid .6px #F8F9FA; background-color: #F8F9FA; padding: 15px; border-radius: 5px;">
                    <v-row>
                        <v-col cols="6">
                            <strong v-if="ticketPricingModified.PaymentDetail && ticketPricing.adtPaxs.length > 0">
                                {{ parseInt(ticketPricing.adtPaxs.length) > 1 ? "Adults" : "Adult" }} x{{
                                    ticketPricingModified.PaymentDetail.AdtTravelers
                                }}
                            </strong>
                            <strong v-if="ticketPricing.chdPaxs && ticketPricing.chdPaxs.length > 0">
                                <br />
                                {{ parseInt(ticketPricing.chdPaxs.length) > 1 ? "Children" : "Child" }} x{{
                                    ticketPricingModified.PaymentDetail.ChdTravelers
                                }}
                            </strong>
                            <strong v-if="ticketPricing.infPaxs && ticketPricing.infPaxs.length > 0">
                                <br />
                                {{ parseInt(ticketPricing.infPaxs.length) > 1 ? "Infants" : "Infant" }} x{{
                                    ticketPricingModified.PaymentDetail.InfTravelers
                                }}
                            </strong>
                        </v-col>
                        <v-col cols="6">
                            <span style="float: right; font-size: .8rem;"
                                v-if="ticketPricing.adtPaxs != null && ticketPricing.adtPaxs.length > 0">
                                THB {{ new Intl.NumberFormat().format(ticketPricingModified.PaymentDetail.TotalAdtFare) }}
                            </span>
                            <br />
                            <span style="float: right; font-size: .8rem;"
                                v-if="ticketPricing.chdPaxs && ticketPricing.chdPaxs.length > 0">
                                THB {{ new Intl.NumberFormat().format(ticketPricingModified.PaymentDetail.TotalChdFare)
                                }}
                            </span>
                            <br />
                            <span style="float: right; font-size: .8rem;"
                                v-if="ticketPricing.infPaxs && ticketPricing.infPaxs.length > 0">
                                THB {{ new Intl.NumberFormat().format(ticketPricingModified.PaymentDetail.TotalInfFare)
                                }}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            <strong>
                                Taxes and fees
                            </strong>
                        </v-col>
                        <v-col cols="6">
                            <span style="float: right; font-size: .8rem;">
                                +{{ new Intl.NumberFormat().format(ticketPricingModified.PaymentDetail.TotalTaxesAndFees) }}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            <strong>
                                Total
                            </strong>
                        </v-col>
                        <v-col cols="6">
                            <span style="float: right; font-size: .8rem;">
                                THB {{ new Intl.NumberFormat().format(ticketPricingModified.PaymentDetail.TotalFare) }}
                            </span>
                        </v-col>
                    </v-row>
                </div>
                <div style="width: 100%;" v-else>
                    <v-progress-circular class="" style="text-align: center;" indeterminate color="primary" :size="40"
                        :width="4"></v-progress-circular>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small class="hidden-sm-and-down" outlined color="info" @click="backpage()">Cancel</v-btn>
                <v-btn small class="hidden-md-and-up" outlined color="info" @click="close()">Cancel</v-btn>
                &nbsp;
                <v-btn small color="info" @click="checkboxDescription.chk1 && checkboxDescription.chk2 ?
                    conBooking() : ''" :loading="loading"
                    :disabled="checkboxDescription.chk1 && checkboxDescription.chk2 ? false : true">
                    <span>Confirm Booking</span>
                </v-btn>
            </v-card-actions>
            <br />
        </v-card>
        <v-dialog v-model="dialogError.alert" width="500">
            <v-card>
                <v-card-title>
                    {{ dialogError.title }}
                </v-card-title>
                <v-card-text>
                    {{ dialogError.message }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <template v-if="dialogError.code === ''">
                        <v-btn color="primary" text @click="backtoFlight()" small>Back to Select New Flights</v-btn>
                    </template>
                    <template v-else>
                        <v-btn color="primary" text @click="(dialogError.alert = false)" small>Cancel</v-btn>
                    </template>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" width="500" persistent v-if="airline != null">
            <div style="border: solid .6px #F8F9FA; background-color: #F8F9FA; padding: 15px; border-radius: 5px;">
                <v-card-title>
                    <v-spacer></v-spacer>
                    <h3>
                        Booking Complete
                    </h3>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-row class="text-center">
                        <v-col>
                            This booking is valid for {{expiryTicketDate}}. <br />
                            Please confirm your booking within the specified time.
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card>
                    <v-card-title>
                        <v-spacer></v-spacer>
                        <span style="font-size: 1rem; color:#00AEEF">
                            Depart date {{ airline.depFlight[0].depDisplayDateTime.date + "/"
                                + airline.depFlight[0].depDisplayDateTime.month + "/"
                                + airline.depFlight[0].depDisplayDateTime.year + " "
                                + airline.depFlight[0].depDisplayDateTime.displayTime
                            }}
                        </span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                </v-card>
                <br />
                <v-card>
                    <v-card-title>
                        <h5>
                            Traveler
                        </h5>
                        <v-spacer></v-spacer>
                        <h5>
                            PNR No.
                        </h5>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text v-if="dialog">
                        <v-row v-for="(itemTravel, index) in Bookingcomplete" :key="index">
                            <v-col cols="8">
                                {{ itemTravel.AdtTraveler.filter(x => !x.TravelWith)[0].fullname }}
                            </v-col>
                            <v-col cols="4" style="color: #7ab800;">
                                <strong v-if="airline.depFlight[0].operatedAirline.code !== 'DD'"
                                    style="float: right; color: #7ab800;" class="mt-2 ml-2">
                                    {{ itemTravel.AdtTraveler[0].PNR }}
                                </strong>
                                <span v-else style="float: right; color: #7ab800;" class="mt-2 ml-2">
                                    Pending
                                </span>
                            </v-col>
                            <v-col cols="12" v-if="itemTravel.AdtTraveler">
                                <span v-for="(adtItem, chdindex) in itemTravel.AdtTraveler.filter(x => x.TravelWith)" :key="chdindex">
                                    <v-row>
                                        <v-col cols="8">
                                            <img :src="require('@/assets/icons/Frame.svg')" style="position: relative"
                                                alt="" />
                                            <span style="position: absolute;" class="mt-2 ml-2"> {{ adtItem.fullname }}
                                            </span>
                                        </v-col>
                                        <v-col cols="4">
                                            <strong v-if="airline.depFlight[0].operatedAirline.code !== 'DD'"
                                                style="float: right; color: #7ab800;" class="mt-2 ml-2">
                                                {{ itemTravel.AdtTraveler[0].PNR }}
                                            </strong>
                                            <span v-else style="float: right; color: #7ab800;" class="mt-2 ml-2">
                                                Pending
                                            </span>
                                        </v-col>
                                    </v-row>
                                </span>
                            </v-col>
                            <v-col cols="12" v-if="itemTravel.ChdTraveler">
                                <span v-for="(chdItem, chdindex) in itemTravel.ChdTraveler" :key="chdindex">
                                    <v-row>
                                        <v-col cols="8">
                                            <img :src="require('@/assets/icons/Frame.svg')" style="position: relative"
                                                alt="" />
                                            <span style="position: absolute;" class="mt-2 ml-2"> {{ chdItem.fullname }}
                                            </span>
                                        </v-col>
                                        <v-col cols="4">
                                            <strong v-if="airline.depFlight[0].operatedAirline.code !== 'DD'"
                                                style="float: right; color: #7ab800;" class="mt-2 ml-2">
                                                {{ itemTravel.AdtTraveler[0].PNR }}
                                            </strong>
                                            <span v-else style="float: right; color: #7ab800;" class="mt-2 ml-2">
                                                Pending
                                            </span>
                                        </v-col>
                                    </v-row>
                                </span>
                            </v-col>
                            <v-col cols="12" v-if="itemTravel.InfTraveler">
                                <span v-for="(infItem, chdindex) in itemTravel.InfTraveler" :key="chdindex">
                                    <v-row>
                                        <v-col cols="8">
                                            <img :src="require('@/assets/icons/Frame.svg')" style="position: relative"
                                                alt="" />
                                            <span style="position: absolute;" class="mt-2 ml-2"> {{ infItem.fullname }}
                                            </span>
                                        </v-col>
                                        <v-col cols="4">
                                            <strong v-if="airline.depFlight[0].operatedAirline.code !== 'DD'"
                                                style="float: right; color: #7ab800;" class="mt-2 ml-2">
                                                {{ itemTravel.AdtTraveler[0].PNR }}
                                            </strong>
                                            <span v-else style="float: right; color: #7ab800;" class="mt-2 ml-2">
                                                Pending
                                            </span>
                                        </v-col>
                                    </v-row>
                                </span>
                            </v-col>
                        </v-row>
                        <br />
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="info" @click="closed()" small>Back to Travel Request</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

export default {
    name: "ProjectDetails",
    props: {
        formContinue: Boolean,
        airline: Object,
        coperateData: Object,
        quantityofTr: String,
        checkboxDescription: Object,
        checkboxDesMobile: Boolean,
        setSession: Boolean
    },
    data() {
        return {
            expiryTicketDate: null,
            net: 0,
            totalFare: 0,
            ticketPricingModified: null,
            ticketPricing: null,
            searchFlight: null,
            invalid: false,
            tab: null,
            dialog: false,
            loading: false,
            messageAlert: '',
            travelers: [],
            dialogError: {
                alert: false,
                message: '',
                title: '',
                code: ''
            },
            employee: null,
            Bookingcomplete: []
        }
    },
    computed: {
        // dataformContinue() {
        //     return this.formContinue
        // }
    },
    watch: {
        async setSession() {
            if (await this.setSession && await sessionStorage.ticketPricingModified) {
                setTimeout(() => {
                    this.ticketPricingModified = JSON.parse(sessionStorage.ticketPricingModified)
                }, 100);
            }
        }
    },
    methods: {
        async checkvalid() {
            const req = JSON.parse(sessionStorage.ticketPricingModified)
            const app = this
            app.invalid = false
            for (const item of req.TicketPricing) {
                await new Promise((resolve, reject) => {
                    if (!item.data.contactInfo.telNo) {
                        app.invalid = true
                    }

                    if (item.data.contactInfo.telNo === '-') {
                        app.invalid = true
                    }
                    resolve()
                })
            }
        },
        backtoFlight() {
            this.searchFlight.newreload = true
            sessionStorage.searchflightReq = JSON.stringify(this.searchFlight)
            location.href = '/searchFlight'
        },
        formatDatepicker(date) {
            return moment(date).format('DD-MM-YYYY')
        },
        async conBooking() {
            await this.checkvalid()
            if (!this.invalid) {
                const req = JSON.parse(sessionStorage.ticketPricingModified)
                const fareControl = JSON.parse(sessionStorage.airline)
                this.$emit("callContinueBooking", true);
                this.loading = true
                const modifyRequest = []
                if (this.travelers && req?.TicketPricing.length > 0) {
                    const formReqID = JSON.parse(sessionStorage.formReqID)
                    const agentService = JSON.parse(sessionStorage.airline)
                    const PROFILE = JSON.parse(localStorage.PROFILE)
                    await req.TicketPricing.forEach(async elm => {
                        const traveler = await this.travelers.filter(data => elm.data.adtPaxs.filter(x => data.emp_ID === x.emp_id).length > 0)[0]
                        const dataModify = {
                            EMPLOYEE_ID: null,
                            TravelRequestOID: null,
                            SpecialRequest: null,
                            TravelCardOID: null,
                            Position: traveler.position,
                            TRNo: formReqID.TRNo,
                            fullname: null,
                            Status: null,
                            PNR: null
                        }
                        const modify = {
                            CREATED_BY: PROFILE.EMPLOYEE_ID,
                            AdtTraveler: [],
                            ChdTraveler: [],
                            InfTraveler: [],
                            PricingRequest: null,
                            AirFare: null,
                            AgentService: null,
                            Remark: null
                        }
                        dataModify.TravelRequestOID = formReqID.TravelId
                        dataModify.fullname = traveler.fullname
                        dataModify.SpecialRequest = traveler.DefaultSpecialRequest
                        dataModify.TravelCardOID = traveler.TravelCardOID
                        dataModify.EMPLOYEE_ID = traveler.emp_ID
                        dataModify.Status = traveler.status
                        dataModify.Baggage = elm.Baggage
                        modify.AdtTraveler.push(await dataModify)
                        modify.AirFare = elm.data
                        modify.AirFare.IsBooking = this.airline.depFlight[0].operatedAirline.code === 'DD' ? false : true
                        modify.AirFare.SaveFlightBookingOID = null
                        modify.PricingRequest = JSON.parse(sessionStorage.ticketPricingReq)
                        modify.AirFare.noOfAdults = 1
                        modify.Remark = {
                            BTRnumber: this.coperateData.TRNo ? this.coperateData.TRNo : '-',
                            Project: this.coperateData.ProjectName ? this.coperateData.ProjectName : '-',
                            Department: traveler.dep ? traveler.dep : '-',
                            BudgetHolder: this.coperateData.BudgetHolder ? this.coperateData.BudgetHolder : '-',
                            TripPurpose: this.coperateData.PurId ? this.coperateData.PurId : '-',
                            Company: this.coperateData.CompanyName ? this.coperateData.CompanyName : '-',
                            // Baggage : this.coperateData.Baggage ? this.coperateData.Baggage : '-',
                        }
                        this.subConBooking(modify, elm, traveler)
                        modify.AgentService = agentService.agentService
                        modifyRequest.push(modify)
                    })
                    setTimeout(() => {
                        if (req.TicketPricing.length === modifyRequest.length) {
                            modifyRequest[0].FareControl = fareControl.fare.adtFare.fareControl
                            console.log(modifyRequest);
                            this.callBooking(modifyRequest)
                        }
                    }, 3000);
                }
            } else {
                this.dialogError.title = 'Invalid'
                this.dialogError.message = "Mobile Phone No. is required"
                this.dialogError.alert = true
                this.dialogError.code = 'Invalid'
                this.loading = false
            }
        },
        async subConBooking(modify, elm, traveler) {
            const formReqID = JSON.parse(sessionStorage.formReqID)
            if(traveler.MyTraveler && traveler.MyTraveler.length > 0){
                await traveler.MyTraveler.forEach(async MyTraveler => {
                    if(MyTraveler.age === null || MyTraveler.age === 'Adult'){
                        modify.AdtTraveler.push({
                            EMPLOYEE_ID: MyTraveler.emp_ID,
                            fullname: MyTraveler.fullname,
                            TravelRequestOID: formReqID.TravelId,
                            SpecialRequest: MyTraveler.DefaultSpecialRequest,
                            TravelCardOID: MyTraveler.TravelCardOID,
                            TravelWith: traveler.emp_ID,
                            Status: MyTraveler.status,
                            PNR: null
                        })
                        modify.AirFare.noOfAdults = elm.data.adtPaxs.length
                    }else if(MyTraveler.age === 'Child'){
                        modify.ChdTraveler.push({
                            EMPLOYEE_ID: MyTraveler.emp_ID,
                            fullname: MyTraveler.fullname,
                            TravelRequestOID: formReqID.TravelId,
                            SpecialRequest: MyTraveler.DefaultSpecialRequest,
                            TravelCardOID: MyTraveler.TravelCardOID,
                            Status: MyTraveler.status,
                            TravelWith: traveler.emp_ID,
                            PNR: null
                        })
                        modify.AirFare.noOfChildren = elm.data.chdPaxs.length
                    }else if(MyTraveler.age === 'Infant'){
                        modify.InfTraveler.push({
                            EMPLOYEE_ID: MyTraveler.emp_ID,
                            fullname: MyTraveler.fullname,
                            TravelRequestOID: formReqID.TravelId,
                            SpecialRequest: MyTraveler.DefaultSpecialRequest,
                            TravelCardOID: MyTraveler.TravelCardOID,
                            Status: MyTraveler.status,
                            TravelWith: traveler.emp_ID,
                            PNR: null
                        })
                        modify.AirFare.noOfInfants = elm.data.infPaxs.length
                    }
                })
            }
            if (elm.data.chdPaxs?.length > 0) {
                await elm.data.chdPaxs.filter(x => x.parentsOfChild === 0).forEach(async chdItem => {
                    const chdSpcReq = await this.travelers.filter(data => data.emp_ID === chdItem.emp_id)[0]
                    modify.ChdTraveler.push({
                        EMPLOYEE_ID: chdItem.emp_id,
                        fullname: chdSpcReq.fullname,
                        TravelRequestOID: formReqID.TravelId,
                        SpecialRequest: chdSpcReq.DefaultSpecialRequest,
                        TravelCardOID: chdSpcReq.TravelCardOID,
                        TravelWith: elm.data.adtPaxs.filter(x => x.parentsOfChild === 0)[0].emp_id,
                        PNR: null
                    })
                    modify.AirFare.noOfChildren = elm.data.chdPaxs.length
                })
            } else {
                modify.ChdTraveler = null
                modify.AirFare.noOfChildren = 0
            }
            if (elm.data.infPaxs?.length > 0) {
                await elm.data.infPaxs.filter(x => x.parentsOfChild === 0).forEach(async infItem => {
                    const infSpcReq = await this.travelers.filter(data => data.emp_ID === infItem.emp_id)[0]
                    modify.InfTraveler.push({
                        EMPLOYEE_ID: infItem.emp_id,
                        fullname: infSpcReq.fullname,
                        TravelRequestOID: formReqID.TravelId,
                        SpecialRequest: infSpcReq.DefaultSpecialRequest,
                        TravelCardOID: infSpcReq.TravelCardOID,
                        TravelWith: elm.data.adtPaxs.filter(x => x.parentsOfChild === 0)[0].emp_id,
                    })
                    modify.AirFare.noOfInfants = elm.data.infPaxs.length
                })
            } else {
                modify.InfTraveler = null
                modify.AirFare.noOfInfants = 0
            }
        },
        callBooking(modifyRequest) {
            this.Bookingcomplete = modifyRequest
            axios.post(this.$store.state.API_PATH + 'FlightBooking/ListBooking', modifyRequest, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
                .then(async res => {
                    if (res.data.Status) {
                        await this.Bookingcomplete.forEach(async elmTravel => {
                            if (res.data.Result != null && res.data.Result.length > 0) {
                                if(res.data.Result[0].expiryDate){
                                    const setDate = res.data.Result[0].expiryDate
                                    this.expiryTicketDate = `${setDate.substring(0, 2)} ${moment(setDate.substring(2, 4)).format('MMM')} ${setDate.substring(4, 6)}`
                                }
                                await elmTravel.AdtTraveler.filter(async x => {
                                    const findPNR = await res.data.Result.filter(ResElm => ResElm.emp_ID === x.EMPLOYEE_ID)
                                    if(findPNR && findPNR.length > 0){
                                        x.PNR = findPNR[0].PNR
                                    }
                                })
                            }
                        })
                        modifyRequest.forEach(x => {
                            if(x.AdtTraveler && x.AdtTraveler.length > 0){
                                x.AdtTraveler.forEach(item => {
                                    this.updateStatusTraveler(item)
                                })
                            }
                            if(x.ChdTraveler && x.ChdTraveler.length > 0){
                                x.ChdTraveler.forEach(item => {
                                    this.updateStatusTraveler(item)
                                })
                            }
                            if(x.InfTraveler && x.InfTraveler.length > 0){
                                x.InfTraveler.forEach(item => {
                                    this.updateStatusTraveler(item)
                                })
                            }
                        })
                        this.dialog = true
                        this.loading = false
                    } else {
                        this.dialogError.title = 'Not Found'
                        this.dialogError.message = `We can not find this flight ${res.data.ErrorMessage}, Please try again.`
                        this.dialogError.alert = true
                        this.dialogError.code = ''
                        this.loading = false
                    }
                }).catch(err => {
                    this.dialog = true
                    this.loading = false
                    console.log(err)
                })
        },
        updateStatusTraveler(val){
            const req = {
                EMPLOYEE_ID: val.EMPLOYEE_ID,
                TravelRequestOID: val.TravelRequestOID,
                Status: null
            }
            if(val.Status === 'Approve Final'){
                req.Status = 'Re-Issue'
            }else if(val.Status === 'Submitted'){
                req.Status = 'Revised'
            }else {
                req.Status = val.Status
            }
            setTimeout(() => {
                console.log(req);
                axios.post(`${this.$store.state.API_PATH}Traveler/UpdateStatusTraveler`, req, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
            }, 100);
        },
        cancelBooking() {
            this.$emit("callContinueBooking", false);
        },
        closed() {
            this.dialog = false
            window.location.href = "createTravelRq"
        },
        backpage() {
            window.location.href = "searchFlight"
        },
        close() {
            this.$emit('closeDialog', false)
        }
    },
    mounted() {
        this.ticketPricing = JSON.parse(sessionStorage.ticketPricing)
        this.searchFlight = JSON.parse(sessionStorage.searchflightReq)
        this.travelers = JSON.parse(sessionStorage.travelers)
        this.employee = JSON.parse(localStorage.PROFILE)
        if (this.setSession && sessionStorage.ticketPricingModified) {
            setTimeout(() => {
                this.ticketPricingModified = JSON.parse(sessionStorage.ticketPricingModified)
            }, 100);
        }
    }
}
</script>


<style scoped>
.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 8%;
}</style>
