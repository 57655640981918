<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <span>
                    <div class="font-W700 f-page h-space">Users List</div>
                </span>
            </v-col>
        </v-row>
        <v-card class="card" elevation="0">
            <v-card-text>
                <div class="f-main-header h-space font-W700">Search</div>
                <div>
                    <v-form ref="form" lazy-validation>
                        <v-row align="center" class="bgcard" no-gutters>
                            <v-col lg="6" sm="8" cols="12" class="pr-4">
                                Employee ID
                                <v-text-field dense outlined background-color="white"
                                 hide-details @keyup.enter="getAllUserProfiles"
                                  v-model="UserProfile.EMPLOYEE_ID"></v-text-field>
                            </v-col>
                            <v-col lg="6" sm="8" cols="12" class="pr-4">
                                Employee Name
                                <v-text-field dense outlined background-color="white"
                                  @keyup.enter="getAllUserProfiles"
                                   v-model="UserProfile.FIRSTNAME" hide-details>
                                </v-text-field>
                            </v-col>
                            <v-col lg="6" sm="8" cols="12" class="pr-4">
                                Email
                                <v-text-field dense outlined background-color="white"
                                  @keyup.enter="getAllUserProfiles"
                                   v-model="UserProfile.EMAIL_ID"
                                    hide-details>
                                </v-text-field>
                            </v-col>
                            <v-col lg="12" sm="12" cols="12">
                                <br />
                                <div class="text-center">
                                    <v-btn color="primary"
                                     @click="getAllUserProfiles" :loading="searchbtnLoading"
                                      elevation="0"
                                       class="mb-lg-0 mb-md-0 mb-sm-0 mb-6">Search</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
                <br />
            </v-card-text>
            <v-card-text>
                <div class="bgcard">
                    <v-data-table class="card" :headers="headers" :items="Employees" @click:row="getGroupList">
                        <template v-slot:[`item.No`]="{index}">
                            {{++index}}
                        </template>
                        <template v-slot:[`item.FIRSTNAME`]="{item}">
                            {{ item.FIRSTNAME + ' ' + item.LASTNAME}}
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
        </v-card>
            <v-dialog v-if="EmpFocus.Dialog" v-model="EmpFocus.Dialog">
            <v-card class="card">
                    <v-card-text>
                        <div class="f-main-header h-space font-W700">Company Data</div>
                        <div class="bgcard">
                            <br />
                            <v-row>
                                <v-col cols="2">
                                    <v-img :src="require('@/assets/image/user_default.svg')" />
                                </v-col>
                                <v-col cols="10">
                                    <v-row>
                                        <v-col cols="4">
                                            <div>Employee ID</div>
                                            <v-text-field background-color="white" readonly outlined hide-details dense v-model="EmpFocus.value.EMPLOYEE_ID"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <div>Thai Full Name</div>
                                            <v-text-field readonly outlined dense background-color="white" hide-details v-model="EmpFocus.value.THAINAME"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <div>English Full Name</div>
                                            <v-text-field readonly outlined dense background-color="white" hide-details v-model="EmpFocus.value.FIRSTNAME"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <div>Thai Position</div>
                                            <v-text-field background-color="white" readonly outlined dense hide-details v-model="EmpFocus.value.POSITION"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <div>English Position</div>
                                            <v-text-field readonly outlined dense background-color="white" hide-details v-model="EmpFocus.value.POSITIONTHAI"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <div>Email</div>
                                            <v-text-field readonly outlined dense background-color="white" hide-details v-model="EmpFocus.value.EMAIL_ID"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <div>Department</div>
                                            <v-text-field background-color="white" readonly outlined dense hide-details v-model="EmpFocus.value.DEPARTMENT_ID"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <div>Division</div>
                                            <v-text-field readonly outlined dense background-color="white" hide-details v-model="EmpFocus.value.DEPARTMENT_ID"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <div>Company</div>
                                            <v-text-field readonly outlined dense background-color="white" hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <div>Joined Date</div>
                                            <v-text-field background-color="white" readonly outlined dense ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <div>Residence</div>
                                            <v-text-field readonly outlined dense background-color="white"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <div>Office Phone No.</div>
                                            <v-text-field readonly outlined dense background-color="white" v-model="EmpFocus.value.DIRECT_PHONE"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="f-main-header h-space font-W700">Edit / Update Group</div>
                        <div class="bgcard">
                            <v-row>
                                <v-col cols="2">
                                    Avaliable Group
                                    <v-data-table show-select class="card" :items="AviliableGroup" item-key="UserGrpID"
                                    v-model="AviliableChkbox" :headers="Groupheaders" hide-default-footer hide-default-header>
                                        <template v-slot:[`item.no`]="{index}">
                                            {{ ++index }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                                <v-col cols="2" style="position: relative;">
                                    <div class="heightcenter">
                                        <v-btn class="btn-table" @click="AddGroupToUser()"><img :src="require('@/assets/icons/Arrowrightinfo.svg')" alt="" />
                                            <span class="ml-4" style="font-size: .8rem;">Add</span>
                                            <span class="ml-4"></span>
                                        </v-btn>
                                        <div style="height: 10px;"></div>
                                        <v-btn class="btn-table" @click="RemoveGroupFromUser()">
                                            <img :src="require('@/assets/icons/ArrowLeftInfo.svg')" alt="" />
                                            <span class="ml-1" style="font-size: .8rem;">Remove</span>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="2">
                                    Selected Group
                                    <v-data-table class="card" :items="SelectedGroup" show-select v-model="SelectedGroupChkbox"
                                    item-key="UserGrpID" :headers="Groupheaders" hide-default-footer hide-default-header>
                                        <template v-slot:[`item.no`]="{index}">
                                            {{ ++index }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                                <v-col v-if="PermissionOrg">
                                      <div class="containerr">
                                        Edit / Update Signature
                                        <input type="file" ref="fileInput" @change="onFileChange" style="display: none" accept="image/*">
                                        <div class="">
                                        <v-btn @click="chooseImage" class="my-4 border boder-black btn-table">
                                            BROWSER
                                            <v-img :src="require('@/assets/icons/Add_trans.svg')" class="ml-2"></v-img>
                                        </v-btn>
                                        </div>
                                        <div v-if="selectedImage != null" class="preview-image">
                                        <img :src="selectedImage" alt="" />
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                            <div v-if="PermissionOrg">
                            <div class="f-main-header h-space font-W700">Add / Edit Organization</div>
                            <div class="bgcard">
                            <v-row>
                                <v-col cols="2">
                                    Avaliable Organization
                                <v-autocomplete
                                    v-model="SelectOrgList"
                                    background-color="white"
                                    :items="OrganizeList"
                                    hide-details
                                    outlined
                                    dense
                                    item-key="ABBREVIATION"
                                    clearable
                                    chips
                                    deletable-chips
                                    return-object
                                    item-text="ABBREVIATION"
                                    multiple
                                    small-chips
                                    label="Search"
                                    solo
                                    >
                                </v-autocomplete>
                                </v-col>
                                <v-col cols="2" style="position: relative;">
                                    <div class="heightcenter">
                                        <v-btn class="btn-table" @click="AddOrgToUser()"><img :src="require('@/assets/icons/Arrowrightinfo.svg')" alt="" />
                                            <span class="ml-4" style="font-size: .8rem;">Add</span>
                                            <span class="ml-4"></span>
                                        </v-btn>
                                        <div style="height: 10px;"></div>
                                        <v-btn class="btn-table" @click="RemoveOrgGroupFromUser()">
                                            <img :src="require('@/assets/icons/ArrowLeftInfo.svg')" alt="" />
                                            <span class="ml-1" style="font-size: .8rem;">Remove</span>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="2">
                                    Selected Group
                                    <v-data-table class="card" :items="GroupOrgSelected" show-select v-model="GroupOrgSelect"
                                    item-key="ABBREVIATION" :headers="Orgheaders" hide-default-footer hide-default-header>
                                        <template v-slot:[`item.no`]="{index}">
                                            {{ ++index }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </div>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn large text @click="CloseDialog()">Back</v-btn>
                            <v-btn large color="primary" @click="SaveUserGroup()">Save</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import imageCompression from 'browser-image-compression';
import Swal from 'sweetalert2'
    export default {
        name: "UserList",
        data(){
            return{
                selectedImage: null,
                PermissionOrg: false,
                OrganizeList: [],
                SelectedGroup: [],
                AviliableChkbox: [],
                SelectedGroupChkbox: [],
                SelectOrgList:[],
                GroupOrgSelected: [],
                GroupOrgSelect: [],
                GroupOrgList: [],
                headers: [
                    { text: "No.", value: "No"},
                    { text: "Eemployee ID", value: "EMPLOYEE_ID"},
                    { text: "Employee Name", value: "FIRSTNAME", width: '20'},
                    { text: "E-Mail", value: "EMAIL_ID"},
                    { text: "Office Phone Number", value: "DIRECT_PHONE"}
                ],
                Employees: [],
                UserProfile: {
                    EMPLOYEE_ID: '',
                    FIRSTNAME: '',
                    EMAIL_ID: ''
                },
                searchbtnLoading: false,
                EmpFocus: {
                    Dialog: false,
                    value: null
                },
                AviliableGroup: [],
                Groupheaders: [
                    { text: "Group Name", width: 20, value: 'Name' },
                ],
                Orgheaders: [
                    {text: 'ABBREVIATION', value: 'ABBREVIATION'}
                ]
            }
        },
        methods:{
            CloseDialog() {
                this.EmpFocus.Dialog = false;
                this.selectedImage = '';
                this.PermissionOrg = false;
                this.GroupOrgSelected = []
            },
            chooseImage() {
                this.$refs.fileInput.click();
            },
            onFileChange(event) {
                const file = event.target.files[0];
                this.readFileAndEncodeBase64(file);
            },
            async readFileAndEncodeBase64(file) {
                    const compressionOptions = {
                    maxSizeMB: 0.1,
                    useWebWorker: true,
                };
                const compressedImage = await imageCompression(file, compressionOptions);
                const reader = new FileReader()
                reader.onload = (event) => {
                    this.selectedImage = event.target.result
                }
                reader.readAsDataURL(compressedImage)
            },
            GetOrgList(){
                axios.post(this.$store.state.API_PATH + 'Report/GetDepartmentByReport', {}, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    }).then(res => {
                        this.OrganizeList = res.data.Result
                        this.$store.state.Loading = true;
                    })
            },
            AddOrgToUser(){
                this.SelectOrgList.forEach(x => {
                    this.OrganizeList = this.OrganizeList.filter(y => y.ABBREVIATION !== x.ABBREVIATION)
                })
                this.GroupOrgSelected.push(...this.SelectOrgList)
                this.SelectOrgList = []
            },
            RemoveOrgGroupFromUser(){
                this.GroupOrgSelect.forEach(x => {
                    this.GroupOrgSelected = this.GroupOrgSelected.filter(y => y.ABBREVIATION !== x.ABBREVIATION)
                })
                this.OrganizeList.push(...this.GroupOrgSelect)
                this.GroupOrgSelect = []
            },
            GetImgEncodeData(){
                this.$store.state.LOADING = true
                console.log("EMPLOYEE_ID",this.EmpFocus.value.EMPLOYEE_ID);
                axios.post(this.$store.state.API_PATH + 'Signature/GetSignature', {
                    EMPLOYEE_ID: this.EmpFocus.value.EMPLOYEE_ID != null ? this.EmpFocus.value.EMPLOYEE_ID : '',
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then((res) => {
                     if(res.data.Status){
                           if(res.data.Result.length > 0){
                                this.selectedImage = res.data.Result[0].Encode
                                this.$store.state.LOADING = false;
                           } else {
                               this.selectedImage = []
                               this.$store.state.LOADING = false;
                           }
                    }
                })
            },
            async getGroupList(val){
                if(this.$store.state.buttonPermit.menuConfig.Update){
                    await this.GetOrgSelectedGroup(val);
                    await axios.post(this.$store.state.API_PATH + 'Menu/GetGroup', {}, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        }).then(res => {
                            this.$store.state.Loading = true;
                            this.AviliableGroup = res.data.Result
                            this.SelectedGroup = [];
                            axios.post(`${this.$store.state.API_PATH}Menu/GetUserGroup?EMPLOYEE_ID=${val.EMPLOYEE_ID}`, {}, {
                            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                            }).then(res2 => {
                                if(res2.data.Status){
                                    this.$store.state.Loading = false;
                                    this.EmpFocus.Dialog = true
                                    this.EmpFocus.value = val
                                    this.SelectedGroup = res2.data.Result
                                    this.GetImgEncodeData();
                                    for (let i = 0; i < this.SelectedGroup.length; i++) {
                                        if (this.SelectedGroup[i].Name === "Admin" || this.SelectedGroup[i].Name === "BTT") {
                                            this.PermissionOrg = true;
                                            break;
                                        } else {
                                            this.PermissionOrg = false;
                                        }
                                    }
                                    this.AviliableGroup =  this.AviliableGroup.filter(x => this.SelectedGroup.filter(y => y.UserGrpID === x.UserGrpID).length === 0)
                                } else {
                                    console.log('error');
                                }
                        })
                    })
                }
            },
            GetOrgSelectedGroup(val) {
                axios.post(`${this.$store.state.API_PATH}Menu/GetOrgSelectedGroup?EMPLOYEE_ID=${val.EMPLOYEE_ID}`, {}, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        }).then(res => {
                            if(res.data.Status){
                                this.$store.state.Loading = false;
                                this.GroupOrgSelected = res.data.Result
                            } else {
                                console.log('error');
                            }
                        })
            },
            SaveUserGroup(){
                const PROFILE = JSON.parse(localStorage.PROFILE)
                axios.post(this.$store.state.API_PATH + 'Menu/SaveUserGroup', {
                    EMPLOYEE_ID: this.EmpFocus.value.EMPLOYEE_ID,
                    UserGroup: this.SelectedGroup
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then((res) => {
                    this.EmpFocus.Dialog = false
                    this.EmpFocus.value = null
                    const profile = JSON.parse(localStorage.PROFILE);
                    this.$store.state.selectedRole = this.SelectedGroup.map((item) => `${item.Name}` ).toString();
                    localStorage.PROFILE = JSON.stringify(profile);
                })

                axios.post(this.$store.state.API_PATH + 'Signature/InsertOrUpdateSignature', {
                    Emp_ID : PROFILE.EMPLOYEE_ID,
                    EMPLOYEE_ID: this.EmpFocus.value.EMPLOYEE_ID,
                    Encode: this.selectedImage,
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then((res) => {
                     console.log(res.data.Result);
                });

                axios.post(this.$store.state.API_PATH + 'Menu/SaveUserOrganize', {
                    Emp_ID : PROFILE.EMPLOYEE_ID,
                    EMPLOYEE_ID: this.EmpFocus.value.EMPLOYEE_ID,
                    OrgGroup: this.GroupOrgSelected,
                    IsActive: "1"
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then((res) => {
                    if(res.data.Status){
                            Swal.fire({
                                icon: 'success',
                                text: 'Success',
                                confirmButtonColor: '#00AEEF'
                            }).then(() => {
                                location.reload();
                            })
                    } else{
                        Swal.fire({
                            icon: 'error',
                            text: res.data.ErrorMessage,
                            confirmButtonColor: '#00AEEF'
                        }).then(() => {
                            this.dialogAddExternal = true
                        }, () => { })
                    }
                })
            },
            AddGroupToUser(){
                this.AviliableChkbox.forEach(x => {
                    this.AviliableGroup = this.AviliableGroup.filter(y => y.UserGrpID !== x.UserGrpID)
                    console.log(this.AviliableGroup);
                })
                this.SelectedGroup.push(...this.AviliableChkbox)
                    for (let i = 0; i < this.SelectedGroup.length; i++) {
                            if (this.SelectedGroup[i].Name === "Admin" || this.SelectedGroup[i].Name === "BTT") {
                                this.PermissionOrg = true;
                            } else {
                                this.PermissionOrg = false;
                            }
                        }
                this.AviliableChkbox = []
            },
            RemoveGroupFromUser(){
                    this.SelectedGroupChkbox.forEach(x => {
                    this.SelectedGroup = this.SelectedGroup.filter(y => y.UserGrpID !== x.UserGrpID)
                })
                this.AviliableGroup.push(...this.SelectedGroupChkbox)

                        for (let i = 0; i < this.AviliableGroup.length; i++) {
                                if (this.AviliableGroup[i].Name === "Admin" && "BTT") {
                                    this.PermissionOrg = false;
                                    break;
                                } else {
                                    this.PermissionOrg = true;
                                }
                        }
                this.SelectedGroupChkbox = []
            },
            getAllUserProfiles(){
                axios.post(`${this.$store.state.API_PATH}UserProfile/GetAllUserProfile?EMAIL_ID=${
                    this.UserProfile.EMAIL_ID}&EMPLOYEE_ID=${
                        this.UserProfile.EMPLOYEE_ID}&FIRSTNAME=${
                            this.UserProfile.FIRSTNAME}`, {
                } , {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    if(res.data.Status){
                        this.Employees = res.data.Result
                        this.GroupOrgList = res.data.Result
                        const menuConfigData = localStorage.getItem('menuConfig');
                        this.$store.state.menuConfig = JSON.parse(menuConfigData);
                        this.GetOrgList();
                    }
                })
            },
        },
        mounted(){
            this.getAllUserProfiles();
        }
    }
</script>
<style>
/* CSS สำหรับการแสดงรูปภาพตัวอย่าง */
.preview-image {
  margin-top: 10px;
}

.button-container {
  margin-top: 20px;
}

.containerr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.preview-image img {
  max-width: 200px;
  max-height: 200px;
}
</style>
