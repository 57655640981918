<template>
        <div>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <span>
                        <span class="font-W700" style="font-size: 1em;">Bed Type</span>
                    </span>
                </v-col>
            </v-row>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row no-gutters align="center" justify="center">
                                <v-col lg="4" cols="8">
                                    Bed Type Name
                                    <v-text-field outlined dense background-color="white"
                                    v-model="TEXT_SEARCH" @keypress.enter="getBedType()"></v-text-field>
                                </v-col>
                                <v-col md="auto" sm="auto">
                                    <v-btn color="primary" elevation="0" class="ml-1" @click="getBedType()">Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row no-gutters align="center">
                                <v-col class="text-lg-right text-sm-right text-center">
                                    <v-btn class="btn-table mr-2" elevation="0" @click="deleteBedType()" v-if="$store.state.buttonPermit.menuConfig.Delete">
                                        <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1"></v-img>
                                        Delete
                                    </v-btn>
                                    <v-btn class="btn-table mr-2" elevation="0" @click="resetInfo()">
                                        <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1"></v-img>
                                        Reset
                                    </v-btn>
                                    <v-btn class="btn-table" elevation="0" @click="actionBedType('Add')" v-if="$store.state.buttonPermit.menuConfig.Add">
                                        <v-img :src="require('@/assets/icons/Add_trans.svg')" class="mr-1"></v-img>
                                        Add
                                    </v-btn>
                                    <v-dialog v-if="dialogAddOrEditBedType" v-model="dialogAddOrEditBedType" persistent
                                        width="600px">
                                        <v-card elevation="0" title color="primary" class="pt-1 card">
                                            <v-card>
                                                <v-card-text>
                                                    <v-card class="card-detail" title elevation="0">
                                                        <v-card-title>
                                                            <v-row no-gutters>
                                                                <v-col class="text-center">
                                                                    {{ AddOrEdit }} Bed Type
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Order
                                                                    <v-text-field outlined dense background-color="white" ref="Order" :rules="rules.Order"
                                                                    v-model="BDT_ORDER"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Bed Typed Name
                                                                    <v-text-field outlined dense background-color="white" ref="BedTypeName" :rules="rules.BedTypeName"
                                                                    v-model="BDT_NAME"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Description
                                                                    <v-textarea outlined dense background-color="white" rows="3"
                                                                    auto-grow ref="Description"
                                                                    :rules="rules.Description" v-model="BDT_DESC">
                                                                </v-textarea>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Status
                                                                    <v-radio-group dense row v-model="STS_ID">
                                                                        <v-radio key="1" value="1" label="Active"></v-radio>
                                                                        <v-radio key="0" value="0" label="Inactive"></v-radio>
                                                                    </v-radio-group>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col class="text-center">
                                                            <v-btn outlined color="primary" class="mr-1"
                                                            @click="closeAddOrEditBedType()">Close</v-btn>
                                                            <v-btn color="primary" class="ml-1" v-if="AddOrEdit === 'Add'"
                                                            @click="addBedType()">Save</v-btn>
                                                            <v-btn color="primary" class="ml-1" v-else
                                                            @click="updateBedType()">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <br>
                            <v-card elevation="0">
                                <v-data-table :headers="headerBedType" :items="itemBedType" class="elevation-1" item-key="BDT_ID"
                                @click:row="rowClicked" show-select v-model="selectedItem" checkbox-color="primary"
                                mobile-breakpoint style="white-space: nowrap" hide-default-footer height="45vh"
                                @page-count="pageCount = $event" :page.sync="page" :items-per-page="itemPage" @load="onPagination($event)">
                                    <template v-slot:[`item.no`]="{ index }">
                                        {{ index + 1 }}
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-img style="cursor: pointer;" :src="require('@/assets/icons/Source_File.svg')" @click="getReturnDetail(item)"></v-img>
                                    </template>
                                </v-data-table>
                            </v-card>
                            <v-row class="mt-3 hidden-xs-only" no-gutters>
                                <v-col class="d-flex" cols="12">
                                    <span class="mr-3 mt-3">Show</span>
                                    <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                        v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                    <span class="ml-3 mt-3">items per page</span>
                                    <v-pagination v-model="page" :length="pageCount" :total-visible="5" class="pagination-custom"
                                        style="width: 60%;"></v-pagination>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                                <v-col class="d-flex" cols="12">
                                    <span class="mr-3 mt-3">Show</span>
                                    <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                        v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                    <span class="ml-3 mt-3">items per page</span>
                                </v-col>
                                <v-col>
                                    <v-pagination v-model="page" :length="pageCount" :total-visible="5" class="pagination-custom">
                                    </v-pagination>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="DialogRemove" width="500">
            <v-card>
                <v-card-title>
                    Remove
                </v-card-title>
                <v-card-text>
                    <template v-if="FileFocus">
                        would you like to remove '{{ FileFocus.FilePath.split('\\')[FileFocus.FilePath.split('\\').length - 1] }}' ?
                    </template>
                    <template v-else>
                        would you like to remove your passport account?
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small text @click="DialogRemove = false">Cancel</v-btn>
                    <v-btn small color="error" @click="remove(typeFocus, 'remove', null)">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </div>
</template>
<script>
import Swal from 'sweetalert2'
import axios from 'axios';
export default {
    data(){
        return{
            FileFocus: null,
            typeFocus: null,
            DialogRemove: false,
            Getfiles: [],
            files: [],
            Bedfile: [],
            filesize: 0,
            STS_ID: "",
            BDT_ORDER: "",
            BDT_NAME: "",
            BDT_DESC: "",
            AddOrEdit: "",
            dialogAddOrEditBedType: false,
            TEXT_SEARCH: "",
            itemPage: 10,
            page: 1,
            pageCount: 2,
            headerBedType: [
                { text: 'No.', value: 'no', sortable: false },
                { text: 'Bed Type Name', value: 'BDT_NAME', sortable: false },
                { text: 'Description', value: 'BDT_DESC', sortable: false },
            ],
            itemsPerPage: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemBedType: [],
            selectedItem: [],
            requireField: "This field is required",
            rules:{
                Order: [(val) => (val || "").length > 0 || this.requireField],
                BedTypeName: [(val) => (val || "").length > 0 || this.requireField],
            }
        }
    },
    methods:{
        remove(type, processing, val) {
            this.typeFocus = type
            if (type === 'BedTypeFile') {
                if (processing === 'alert') {
                    this.FileFocus = val
                }
                if (processing === 'remove') {
                    this.removeFiles(this.FileFocus, 'Saved')
                    this.DialogRemove = false
                }
            }
        },
        removeFiles(val, key) {
            console.log(val);
            if (key === 'NotSave') {
                this.files = this.files.filter(x => x.name !== val.name)
                this.filesize -= val.size
            } else if (key === 'Saved') {
                const formData = new FormData();
                for (let i = 0; i < this.files.length; i++) {
                    formData.append('BedType', this.files[0])
                }

                const files = formData
                axios.post(`${this.$store.state.API_PATH}FileUpload/RemoveFile?FileOID=` + `${val.BDT_ID}|${val.FilePath}|${val.FileOID}|BedType`, files,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    if (res.data) {
                        this.Getfiles = this.Getfiles.filter(x => x.FileOID !== val.FileOID)
                    }
                })
            }
        },
        GetFile(val, BDT_ID) {
            axios.post(`${this.$store.state.API_PATH}FileUpload/GetFile?ID=${BDT_ID}|${val}`).then(res => {
                this.Getfiles = res.data.Result
            })
        },
        uploadFilesData(empID){
            const formData = new FormData()
            for (let i = 0; i < this.files.length; i++) {
                formData.append('BedType', this.files[i])
            }

            const files = formData
            axios.post(`${this.$store.state.API_PATH}FileUpload/Upload?ID=` + empID, files,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        },
        openFileUserfile(val) {
            window.open(`${this.$store.state.API_PATH}` + val.FilePath)
        },
        AddAttachFile() {
            if (this.Bedfile && this.Bedfile.length > 0) {
                const reader = new FileReader()
                reader.readAsArrayBuffer(this.Bedfile[0])

                reader.onload = (e) => {
                    this.files.push(this.Bedfile[0])
                }
            }
        },
        checkvalidate(){
            let result = false
            if (!this.$refs.Order.validate()) {
                this.$refs.Order.validate(true)
                this.$refs.Order.focus()
                result = true
            }
            if (!this.$refs.BedTypeName.validate()) {
                this.$refs.BedTypeName.validate(true)
                this.$refs.BedTypeName.focus()
                result = true
            }
            return result
        },
        updateBedType(){
            if(!this.checkvalidate()){
                this.dialogAddOrEditBedType = false
                const PROFILE = JSON.parse(localStorage.PROFILE)
                this.$store.state.LOADING = true
                this.uploadFilesData(PROFILE.EMPLOYEE_ID)
                axios.post(`${this.$store.state.API_PATH}BedType/UpdateBedType`, {
                    BDT_ID: this.BDT_ID,
                    BDT_ORDER: this.BDT_ORDER,
                    BDT_NAME: this.BDT_NAME,
                    BDT_DESC: this.BDT_DESC,
                    STS_ID: this.STS_ID,
                    UPDATE_BY: PROFILE.EMPLOYEE_ID
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.SwalAlert(res)
                }).catch(() => {
                    this.$store.state.LOADING = false
                })
            }
        },
        SwalAlert(res){
            if(res.data.Status){
                Swal.fire({
                    icon: 'success',
                    text: 'Success',
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.closeAddOrEditBedType()
                    this.getBedType()
                }, () => {})
            }
            else{
                Swal.fire({
                    icon: 'error',
                    text: res.data.ErrorMessage,
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.dialogAddExternal = true
                }, () => { })
            }
        },
        closeAddOrEditBedType(){
            this.dialogAddOrEditBedType = false
            this.BDT_ORDER = ""
            this.BDT_NAME = ""
            this.BDT_DESC = ""
            this.STS_ID = ""
            this.AddOrEdit = ""
        },
        addBedType(){
            if(!this.checkvalidate()){
                this.dialogAddOrEditBedType = false
                const PROFILE = JSON.parse(localStorage.PROFILE)
                this.$store.state.LOADING = true
                this.uploadFilesData(PROFILE.EMPLOYEE_ID)
                axios.post(`${this.$store.state.API_PATH}BedType/InsertBedType`, {
                    BDT_ORDER: this.BDT_ORDER,
                    BDT_NAME: this.BDT_NAME,
                    BDT_DESC: this.BDT_DESC,
                    STS_ID: this.STS_ID,
                    CREATED_BY: PROFILE.EMPLOYEE_ID
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.SwalAlert(res)
                }).catch(() => {
                    this.$store.state.LOADING = false
                })
            }
        },
        actionBedType(AddOrEdit){
            this.AddOrEdit = AddOrEdit
            this.dialogAddOrEditBedType = true
        },
        resetInfo(){
            this.selectedItem = []
            this.TEXT_SEARCH = ""
            this.getBedType()
        },
        deleteBedType(){
            if(this.selectedItem.length > 0){
                Swal.fire({
                    icon: 'warning',
                    text: 'would you like to to delete?',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    confirmButtonColor: '#00AEEF'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.state.LOADING = true
                        axios.post(this.$store.state.API_PATH + 'BedType/DeleteBedType', {
                            BedTypeItem: this.selectedItem
                        }, {
                            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        })
                        .then(res => {
                            this.$store.state.LOADING = false
                            if (res.data.Status) {
                                Swal.fire({
                                    icon: 'success',
                                    text: 'Success',
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                    this.selectedItem = []
                                    this.getBedType()
                                },()=>{})
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    text: res.data.ErrorMessage,
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                },()=>{})
                            }
                        })
                        .catch(() => {
                            this.$store.state.LOADING = false
                        })
                    }
                })
            }
        },
        getBedType(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}BedType/GetBedType`, {
                BDT_NAME: this.TEXT_SEARCH
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.itemBedType = res.data.Result
                    const menuConfigData = localStorage.getItem('menuConfig');
                    this.$store.state.menuConfig = JSON.parse(menuConfigData);
                }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        rowClicked(item){
            if(this.$store.state.buttonPermit.menuConfig.Update){
                this.AddOrEdit = "Edit"
                this.BDT_ID = item.BDT_ID
                this.STS_ID = item.STS_ID
                this.BDT_ORDER = item.BDT_ORDER
                this.BDT_NAME = item.BDT_NAME
                this.BDT_DESC = item.BDT_DESC
                this.dialogAddOrEditBedType = true
                this.GetFile('BedType', item.BDT_ID)
            }
        },
    },
    mounted(){
        this.getBedType()
    }
}
</script>
<style scoped>
.font-W700{
    font-weight: 700;
}
.font-W400{
    font-weight: 400;
}
.btn-table{
    background: rgba(0, 174, 239, 0.1) !important;
}
</style>
