<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <span>
                    <div class="f-text-28 font-W700">Travel Requests
                        <v-btn style="float: right" color="primary" :loading="TravelRqloading" @click="createTraveler">
                            <div class="btn-padding">
                                Create Travel Request
                            </div>
                        </v-btn>
                    </div>
                </span>
            </v-col>
        </v-row>
        <v-card class="card" elevation="0">
            <v-card-text>
                <div class="f-text-28 font-W400">Travel Requests</div>
                <br />
                <div class="bgcard">
                    <br />
                    <div class="f-text-26 font-W500">Search</div>
                    <br />
                    <v-row>
                        <v-col cols="12" xs="12" sm="6" md="4">
                            <span class="f-text-22 font-W400">TR No.</span>
                            <v-autocomplete dense outlined v-model="searchTravelRequest.TrNo"
                            background-color="white" hide-details
                            @input.native="getTrBySearch($event.srcElement.value)" item-text="TRNo" item-value="TRNo"
                            @change="ShowTravelRequest()" :items="filterTravelRequest.TrNo"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="4">
                            <span class="f-text-22 font-W400">Country</span>
                            <v-autocomplete dense outlined v-model="searchTravelRequest.Country" item-text="txt" background-color="white"
                                hide-details item-value="val" @change="ShowTravelRequest()" @input.native="getCountryCodeByCode($event.srcElement.value)"
                                :items="filterTravelRequest.Country"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="4">
                            <span class="f-text-22 font-W400">City Province</span>
                            <v-autocomplete dense outlined v-model="searchTravelRequest.CityProvince"
                                item-text="txt" background-color="white"
                                hide-details item-value="txt" @input.native="getCityCodeByCode($event.srcElement.value)"
                                :items="filterTravelRequest.CityProvince" @change="ShowTravelRequest()"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="4">
                            <span class="f-text-22 font-W400">Business Travel Type</span>
                            <v-select dense outlined @change="ShowTravelRequest()" v-model="searchTravelRequest.BusinessTravelType" background-color="white"
                            hide-details :items="filterTravelRequest.BusinessTravelType"></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="4">
                            <span class="f-text-22 font-W400">Business Travel Case</span>
                            <v-select dense outlined @change="ShowTravelRequest()" v-model="searchTravelRequest.BusinessTravelCase" background-color="white"
                            hide-details :items="filterTravelRequest.BusinessTravelCase"></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="4">
                            <span class="f-text-22 font-W400">Business Date Start</span>
                            <v-menu v-model="travelDateOffMenu" :close-on-content-click="false"
                                :nudge-right="40" transition="scale-transition" offset-y
                                min-width="auto">
                                <template v-slot:[`activator`]="{ on, attrs }">
                                    <v-text-field :value="formatDate(searchTravelRequest.BusinessStartDate)" background-color="white" hide-details
                                        outlined dense readonly v-bind="attrs" v-on="on">
                                        <v-icon slot="append" color="info" v-if="!searchTravelRequest.BusinessStartDate">
                                            mdi-calendar
                                        </v-icon>
                                        <v-icon @click="searchTravelRequest.BusinessStartDate = '', ShowTravelRequest()" slot="append" color="error" v-else>
                                            mdi-close
                                        </v-icon>
                                    </v-text-field>
                                </template>
                                <v-date-picker @change="ShowTravelRequest()" v-model="searchTravelRequest.BusinessStartDate"
                                    @input="travelDateOffMenu = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </div>
                <br />
                <div class="bgcard">
                    <v-data-table class="card f-text-22" :loading="tableLoading" mobile-breakpoint :headers="headers" :items="Showrequests"
                        hide-default-footer @page-count="pageCount = $event" :page.sync="page" :items-per-page="itemPage" @load="onPagination($event)"
                        @click:row="selectData">
                        <template v-slot:[`item.no`]="{ index }">
                            {{  index + 1  }}
                        </template>
                        <template v-slot:[`item.trNo`]="{ item }">
                            <span v-if="item.trNo <= 5" color="#FF0000">{{ item.trNo }}</span>
                            <span v-else>{{ item.trNo }}</span>
                        </template>
                    </v-data-table>
                </div>
                <v-row class="mt-3 hidden-xs-only" no-gutters>
                    <v-col class="d-flex" cols="12">
                        <span class="mr-3 mt-1 f-text-18">Show</span>
                        <v-select outlined dense hide-details :items="itemsPerPage" item-text="text" item-value="value"
                        v-model="itemPage" background-color="white" class="select-data-table shrink"></v-select>
                        <span class="ml-3 mt-1 f-text-18">items per page</span>
                        <v-pagination v-model="page" :length="pageCount" :total-visible="5"
                        class="pagination-custom" style="width: 60%;"></v-pagination>
                    </v-col>
                </v-row>
                <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                    <v-col class="d-flex" cols="12">
                        <span class="mr-3 mt-1 f-text-18">Show</span>
                        <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                        v-model="itemPage" background-color="white" class="select-data-table shrink"></v-select>
                        <span class="ml-3 mt-1 f-text-18">items per page</span>
                    </v-col>
                    <v-col>
                        <v-pagination v-model="page" :length="pageCount" :total-visible="5"
                        class="pagination-custom"></v-pagination>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialogTravelRequest" width="500">
            <v-card>
                <v-card-title>
                    Create travel request
                </v-card-title>
                <v-card-text>
                    <span v-if="!TravelRqloading">
                        would you like to create new travel request?
                    </span>
                    <span v-else>
                        Waiting...
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="info" small @click="dialogTravelRequest = false">Cancel</v-btn>
                    <v-btn color="info" @click="createTraveler" :loading="TravelRqloading" small>Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
    props: {
    },
    data() {
        return {
            BudgetHolderTR: "",
            travelDateOffMenu: false,
            nowDate: new Date().toISOString().substr(0,10),
            firstPayload: true,
            headers: [
                { text: 'No.', value: 'no' },
                { text: 'TR No.', value: 'trNo' },
                { text: 'Business Date', value: 'businDte' },
                { text: 'Business Travel Type.', value: 'busintravel' },
                { text: 'Destination', value: 'destination' },
                { text: 'Case.', value: 'case' },
                { text: 'Trip Purpose', value: 'trippurpose' },
                { text: 'Status', value: 'status' },
            ],
            requests: [],
            TravelRqloading: false,
            Showrequests: [],
            dialogTravelRequest: false,
            textSelectALL: "Select All",
            filterTravelRequest: {},
            searchTravelRequest: {},
            tableLoading: false,
            itemsPerPage:[
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemPage: 5,
            page: 1,
            pageCount: 2,
        }
    },
    methods: {
        formatDate(val){
            let res
            if(val){
                res = moment(val).format('DD MMM YY')
            }
            return res
        },
        ShowTravelRequest() {
            this.Showrequests = []
            const PROFILE = JSON.parse(localStorage.PROFILE)
            // this.$store.state.LOADING = true
            axios.post(this.$store.state.API_PATH + 'TravelRequest/GetTravelRequestSearch', {
                TRNo: this.searchTravelRequest.TrNo ?? "",
                EMPLOYEE_ID: PROFILE.EMPLOYEE_ID,
                Country: this.searchTravelRequest.Country ?? "",
                CityorProvince: this.searchTravelRequest.CityProvince ?? "",
                BusinessTravelType: this.searchTravelRequest.BusinessTravelType ?? "",
                BusinessTravelCase: this.searchTravelRequest.BusinessTravelCase ?? "",
                BusinessStartDate: this.searchTravelRequest.BusinessStartDate ?? "",
                LimitSQL: this.firstPayload ? 100 : 0
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res=>{
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    res.data.Result.forEach(elm => {
                        this.Showrequests.push({
                            no: 0,
                            TravelId: elm.TravelRequestOID,
                            trNo: elm.TRNo,
                            name: '',
                            businDte: `${elm.BusinessStartDate != null ?
                            this.formatDate(elm.BusinessStartDate) : ''} - ${elm.BusinessEndDate != null ?
                            this.formatDate(elm.BusinessEndDate) : ''}`,
                            busintravel: elm.BusinessTravelType,
                            destination: `${elm.CityorProvince.split('(', 1)} ${elm.CityorProvince !== '' ? ',' : ''} ${elm.Country}`, //CityorProvince
                            case: elm.BusinessTravelCase,
                            trippurpose: elm.PurposeofTravel,
                            status: elm.Status,
                            Country: elm.Country,
                            Remove: elm.Remove
                        })
                    })
                }
                this.firstPayload = false
            }).catch(()=>{
                this.$store.state.LOADING = false
            })
        },
        alertTravelRequest(){
            this.dialogTravelRequest = true
        },
        createTraveler() {
            this.TravelRqloading = true
            sessionStorage.removeItem('travelers')
            sessionStorage.removeItem('coperateData')
            sessionStorage.removeItem('formReqID')
            location.href = "createTravelRq"
        },
        selectData(val) {
            if(this.$store.state.buttonPermit.menuConfig.Update){
                const formReqID = {
                    TravelId: val.TravelId,
                    EMPLOYEE_ID: null,
                    TRNo: val.trNo
                }
                sessionStorage.formReqID = JSON.stringify(formReqID)
                setTimeout(() => {
                    location.href = '/createTravelRq'
                }, 1);
            }
        },
        getCityCodeByCode(input){
            if(input.trim()){
                this.$store.state.LOADING = false
                axios.post(this.$store.state.API_PATH + 'CityCode/GetCityCodeByCode', {
                    Code: input.trim(),
                    Language: this.$store.state.Language
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    this.$store.state.LOADING = false
                    if (res.data.Status) {
                        this.filterTravelRequest.CityProvince = res.data.Result
                    }
                }).catch(()=>{
                    this.$store.state.LOADING = false
                })
            }
        },
        getCountryCodeByCode(input){
            if(input.trim()){
                this.$store.state.LOADING = false
                axios.post(this.$store.state.API_PATH + 'CountryCode/GetCountryCodeByCode', {
                    Code: input.trim(),
                    Language: this.$store.state.Language
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    this.$store.state.LOADING = false
                    if (res.data.Status) {
                        this.filterTravelRequest.Country = res.data.Result
                    }
                }).catch(()=>{
                    this.$store.state.LOADING = false
                })
            }
        },
        getTrBySearch(input){
            if(input.trim().length > 3){
                this.$store.state.LOADING = false
                const PROFILE = JSON.parse(localStorage.PROFILE)
                axios.post(this.$store.state.API_PATH + 'TravelRequest/GetTravelRequestSearchByTRNo', {
                    TRNo: input,
                    EMPLOYEE_ID: PROFILE.EMPLOYEE_ID
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    this.$store.state.LOADING = false
                    if (res.data.Status) {
                        this.filterTravelRequest.TrNo = res.data.Result
                    }
                }).catch(() => {
                    this.$store.state.LOADING = false
                })
            }
        },
        getBusinessStartDate(input){
            if(input.trim().length > 5){
                this.$store.state.LOADING = true
                const PROFILE = JSON.parse(localStorage.PROFILE)
                axios.post(this.$store.state.API_PATH + 'TravelRequest/GetBusinessStartDateSearch', {
                    BusinessStartDate: input,
                    EMPLOYEE_ID: PROFILE.EMPLOYEE_ID
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    this.$store.state.LOADING = false
                    if (res.data.Status) {
                        this.filterTravelRequest.BusinessStartDate = res.data.Result
                    }
                }).catch(() => {
                    this.$store.state.LOADING = false
                })
            }
        }
    },
    mounted(){
        this.filterTravelRequest = {
            TrNo: [],
            Country: [],
            CityProvince: [],
            BusinessTravelType: ["Domestic", "Overseas"],
            BusinessTravelCase: ["", "Normal", "Urgent"],
            BusinessStartDate: [],
        }
        this.searchTravelRequest = {
            TrNo: "",
            Country: "",
            CityProvince: "",
            BusinessTravelType: "",
            BusinessTravelCase: "",
            BusinessStartDate: "",
        }
        this.ShowTravelRequest()
    },
}
</script>

<style>
.greentap {
    border-left: solid 3px #99e210;
    padding: 4px;
}

.text-traveler font {
    font-size: .7rem;
}

</style>
