<template>
    <div>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <span>
                    <span class="font-W700" style="font-size: 1em;">Hotel List</span>
                </span>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <div class="mp-1" style="font-weight: 500; color: rgb(0, 0, 0);">
                            Search
                        </div>
                        <v-row align="center" class="bgcard" no-gutters>
                            <v-col lg="4" sm="6" cols="12" class="pr-4">
                                Country
                                <v-autocomplete v-model="countryCode" item-value="Code" @change="getCityCode(false, countryCode)"
                                :items="country"
                                @input.native="getCountryCode($event.srcElement.value)" outlined dense placeholder="Select All" background-color="white">
                                </v-autocomplete>
                            </v-col>
                            <v-col lg="4" sm="6" cols="12" class="pr-4">
                                Province
                                <v-autocomplete background-color="white" :items="provinceList" item-text="text" item-value="Code"
                                    ref="CityorProvince" dense outlined>
                                </v-autocomplete>
                            </v-col>
                            <v-col lg="3" sm="6" cols="12" class="pr-4">
                                Hotel Name
                                <v-text-field outlined dense placeholder="" v-model="search" background-color="white">
                                </v-text-field>
                            </v-col>
                            <v-col lg="1" sm="6" cols="12" class="text-lg-left text-sm-left text-center">
                                <v-btn color="primary" elevation="0" class="mb-lg-0 mb-md-0 mb-sm-0 mb-6">Search
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-card class="card" elevation="0">
            <v-card-text>
                <v-row no-gutters align="center">
                    <v-col class="text-lg-right text-sm-right text-center hidden-xs-only hidden-sm-only">
                        <v-btn class="btn-table mr-2" elevation="0" @click="removeHotel()" :disabled="(selectHotel.length > 0 ? false : true)"
                            v-if="$store.state.buttonPermit.menuConfig.Delete">
                            <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1">
                            </v-img>
                            Delete
                        </v-btn>
                        <v-btn class="btn-table mr-2" elevation="0">
                            <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1">
                            </v-img>
                            Reset
                        </v-btn>
                        <v-btn class="btn-table" elevation="0" @click="addHotel()" v-if="$store.state.buttonPermit.menuConfig.Add">
                            <v-img :src="require('@/assets/icons/Add_trans.svg')" width="20px" class="mr-1">
                            </v-img>
                            Add
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="hidden-md-and-up mt-3 flex-nowrap text-center" no-gutters>
                    <v-col>
                        <v-btn class="btn-table mr-2" elevation="0" @click="removeHotel()" :disabled="(selectHotel.length > 0 ? false : true)" >
                            <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1">
                            </v-img>
                            Delete
                        </v-btn>
                        <v-btn class="btn-table mr-2" elevation="0">
                            <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1">
                            </v-img>
                            Reset
                        </v-btn>
                        <v-btn class="btn-table" elevation="0" @click="addHotel()" >
                            <v-img :src="require('@/assets/icons/Add_trans.svg')" width="20px" class="mr-1">
                            </v-img>
                            Add
                        </v-btn>
                    </v-col>
                </v-row>
                <br />
                <template>
                    <div class="bgcard">
                        <v-data-table class="card" v-model="selectHotel" :search="search" mobile-breakpoint
                        @click:row="openDialog" hide-default-footer
                        @page-count="pageCount = $event" :page.sync="page" :items-per-page="itemPage" @load="onPagination($event)"
                        item-key="HTL_ID" show-select :headers="headers" :items="hotels">
                            <template v-slot:[`item.no`]="{index}">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.HTL_NAME`]="{item}">
                                {{item.HTL_NAME.length > 40 ? item.HTL_NAME.slice(0, 40) + '...' : item.HTL_NAME}}
                            </template>
                        </v-data-table>
                    </div>
                    <v-row class="mt-3 hidden-xs-only" no-gutters>
                        <v-col class="d-flex" cols="12">
                            <span class="mr-3 mt-3">Show</span>
                            <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                v-model="itemPage" background-color="white" class="select-data-table shrink"></v-select>
                            <span class="ml-3 mt-3">items per page</span>
                            <v-pagination v-model="page" :length="pageCount" :total-visible="5" class="pagination-custom"
                                style="width: 60%;"></v-pagination>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                        <v-col class="d-flex" cols="12">
                            <span class="mr-3 mt-3">Show</span>
                            <v-select outlined dense :items="itemsPerPage" item-text="text" item-value="value"
                                v-model="itemPage" background-color="white" class="select-data-table shrink"></v-select>
                            <span class="ml-3 mt-3">items per page</span>
                        </v-col>
                        <v-col>
                            <v-pagination v-model="page" :length="pageCount" :total-visible="5" class="pagination-custom">
                            </v-pagination>
                        </v-col>
                    </v-row>
                </template>
                    <!--Dialog-->
                    <v-dialog x-large v-if="focusHotel.value" persistent v-model="focusHotel.openDialog" :key="renderNewDialogs">
                        <v-card class="">
                            <v-card-title style="position: sticky; top: 0%; z-index: 10; background-color: #FFFFFF;">
                                {{focusHotel.value.HTL_NAME ? focusHotel.value.HTL_NAME : 'Hotel Name'}}
                                <v-spacer></v-spacer>
                                <v-btn icon color="primary" text @click="(focusHotel.openDialog = false), HotelPriceFileFocus.show = false">
                                <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text>
                                <v-card class="bgcard" elevation="0">
                                    <v-card-text id="headerText">
                                        <v-row align="center" no-gutters>
                                            <template v-if="!HotelPriceFileFocus.show">
                                                <v-col cols="12">
                                                    Hotel Rating
                                                    <v-rating v-model="focusHotel.value.HTL_RATING" color="warning"></v-rating>
                                                    <br />
                                                </v-col>
                                                <v-col lg="6" sm="6" cols="12" class="pr-4">
                                                    Hotel Name <span style="color: red">*</span>
                                                    <v-text-field v-model="focusHotel.value.HTL_NAME" ref="HotelName"
                                                    :rules="hotelrule.HotelName" outlined dense background-color="white">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col lg="6" sm="6" cols="12" class="pr-4">
                                                    Email <span style="color: red">*</span>
                                                    <v-text-field v-model="focusHotel.value.HTL_EMAIL" :rules="hotelrule.Order" ref="Order"
                                                        :items="countryList" outlined dense background-color="white">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col lg="6" sm="6" cols="12" class="pr-4">
                                                    Country
                                                    <v-autocomplete v-model="focusHotel.value.COUNTRY_ID"
                                                    item-value="Code" item-text="text" :items="country" :rules="hotelrule.Country" ref="Country"
                                                    @input.native="getCountryCode($event.srcElement.value)"
                                                    outlined dense placeholder="Select All" background-color="white">
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col lg="6" sm="6" cols="12" class="pr-4">
                                                    Province
                                                    <v-autocomplete background-color="white" v-model="focusHotel.value.PRV_ID" :rules="hotelrule.CityorProvince"
                                                    @input.native="getCityCode(false, focusHotel.value.COUNTRY_ID)"
                                                    :items="provinceList" item-text="text" item-value="Code"
                                                    ref="CityorProvince" dense outlined>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col lg="6" sm="6" cols="12" class="pr-4">
                                                    Tel. <span style="color: red">*</span>
                                                    <v-text-field outlined dense
                                                    :rules="hotelrule.Tel" ref="Tel" v-model="focusHotel.value.HTL_TEL"
                                                    background-color="white">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col lg="6" sm="6" cols="12" class="pr-4">
                                                    FAX No.
                                                    <v-text-field outlined dense v-model="focusHotel.value.HTL_FAX" background-color="white">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col lg="6" sm="6" cols="12" class="pr-4">
                                                    Payment Method <span style="color: red">*</span>
                                                    <v-radio-group v-model="focusHotel.value.PAY_TYPE" ref="PaymentMethod" :rules="hotelrule.PaymentMethod">
                                                        <v-row>
                                                            <v-col cols="6" lg="4" v-for="(itempm, index) in paymentMethod" :key="index">
                                                                <v-radio :label="itempm.text" :value="itempm.code">
                                                                </v-radio>
                                                            </v-col>
                                                        </v-row>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col lg="6" sm="6" cols="12" class="pr-4">
                                                    Status <span style="color: red">*</span>
                                                    <v-radio-group v-model="focusHotel.value.STS_ID" ref="HotelStatus" :rules="hotelrule.HotelStatus">
                                                        <v-row no-gutter>
                                                            <v-col cols="6" lg="3" v-for="(itemsts, index) in statusHotel" :key="index">
                                                                <v-radio :label="itemsts.text" :value="itemsts.code">
                                                                </v-radio>
                                                            </v-col>
                                                        </v-row>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col lg="12" sm="12" cols="12">
                                                    <br />
                                                    Description
                                                    <v-textarea background-color="white" v-model="focusHotel.value.HTL_DESC" outlined rows="3"></v-textarea>
                                                </v-col>
                                                <v-col>
                                                    <v-row>
                                                        <v-col>
                                                            Image Files
                                                        </v-col>
                                                    </v-row>
                                                    <v-card class="card">
                                                        <v-card-text class="bg-card">
                                                            <v-row>
                                                                <template v-if="Getfiles && Getfiles.filter(x => x.IsHeader === 1 && x.IsDelete === 0).length > 0 ||
                                                                    focusHotel.fileuploadStandBy.filter(x => x.IsHeader === 1).length > 0">
                                                                    <v-col cols="4" class="image-container text-center">
                                                                        <v-img v-if="Getfiles.filter(x => x.IsHeader === 1 && x.IsDelete === 0).length > 0"
                                                                            width="100%" class="text-center"
                                                                            :src="Getfiles.filter(x => x.IsHeader === 1 && x.IsDelete === 0)[0].FilePath" />
                                                                        <v-img v-else-if="focusHotel.fileuploadStandBy.filter(x => x.IsHeader === 1).length > 0"
                                                                            width="100%" class="text-center"
                                                                            :src="focusHotel.fileuploadStandBy.filter(x => x.IsHeader === 1)[0].ImageShow" />
                                                                        <span class="cancel-icon" v-if="Getfiles.filter(x => x.IsHeader === 1).length > 0">
                                                                            <v-btn icon><v-icon color="error" @click="DialogRemove = true, remove('Hotel', 'alert',
                                                                            Getfiles.filter(x => x.IsHeader === 1)[0])">mdi-close</v-icon>
                                                                            </v-btn>
                                                                        </span>
                                                                        <span class="cancel-icon" v-else>
                                                                            <v-btn icon><v-icon color="error"
                                                                            @click="removeFiles(focusHotel.fileuploadStandBy.filter(x => x.IsHeader === 1)[0],
                                                                            'HotelNotSave')">mdi-close</v-icon>
                                                                            </v-btn>
                                                                        </span>
                                                                    </v-col>
                                                                </template>
                                                                <template v-else>
                                                                    <v-col cols="4">
                                                                        <div class="img-icon">
                                                                            <v-btn
                                                                                fab
                                                                                outlined
                                                                                @click="InputFile('Header')"
                                                                                color="primary">
                                                                                <v-icon dark>
                                                                                    mdi-image-plus
                                                                                </v-icon>
                                                                            </v-btn>
                                                                        </div>
                                                                        <input type="file" style="display: none;" ref="input_file_header"
                                                                        @change="uploadFileWhenInput('HeaderHotelStandBy', $event)" accept="image/*" />
                                                                    </v-col>
                                                                </template>
                                                                <v-col cols="8">
                                                                    <v-row>
                                                                        <v-col cols="4" class="image-container" v-for="(HotelPriceitem, index) in
                                                                        Getfiles.filter(x => x.IsHeader === 0)" :key="index">
                                                                             <v-img v-if="HotelPriceitem.FilePath" width="100%" class="text-center"
                                                                                :src="HotelPriceitem.FilePath" />
                                                                            <span class="cancel-icon" v-if="sortImage.show">
                                                                                <v-checkbox :key="index" @click="sortImageEvent(HotelPriceitem)" :on-icon="HotelPriceitem.Order > 0
                                                                                    ? HotelPriceitem.Order.toString() : ''"></v-checkbox>
                                                                            </span>
                                                                            <span class="cancel-icon" v-else>
                                                                                <v-btn icon @click="DialogRemove = true, remove('Hotel', 'alert', HotelPriceitem)">
                                                                                <v-icon color="error">mdi-close</v-icon></v-btn></span>
                                                                        </v-col>
                                                                        <template v-if="focusHotel.fileuploadStandBy && focusHotel.fileuploadStandBy.length > 0">
                                                                            <v-col class="image-container" cols="4" v-for="(HotelPriceitem, index) in
                                                                            focusHotel.fileuploadStandBy.filter(x => x.IsHeader === 0)" :key="`hp` + index">
                                                                                  <v-img v-if="HotelPriceitem.file" width="100%" class="text-center"
                                                                                :src="HotelPriceitem.ImageShow" />
                                                                                <span class="cancel-icon"><v-btn icon @click="removeFiles(HotelPriceitem, 'HotelNotSave')">
                                                                                    <v-icon color="error">mdi-close</v-icon></v-btn>
                                                                                </span>
                                                                            </v-col>
                                                                        <div class="h-space"></div>
                                                                        </template>
                                                                        <v-col cols="4" v-if="!sortImage.show && Getfiles.length > 0 || focusHotel.fileuploadStandBy.length > 0"
                                                                            style="border-radius: 5px; background-color: #f4fcff; border: solid 1px #00AEEF; display: flex;
                                                                            align-items: center; right: 0; justify-content: center; padding: 50px;">
                                                                            <v-btn
                                                                                fab
                                                                                outlined
                                                                                @click="InputFile('Content')"
                                                                                color="primary">
                                                                                <v-icon dark>
                                                                                    mdi-image-plus
                                                                                </v-icon>
                                                                            </v-btn>
                                                                            <input type="file" style="display: none;" ref="input_file"
                                                                            @change="uploadFileWhenInput('HotelStandBy', $event)" accept="image/*" />
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                    <br />
                                                    <br />
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            Location
                                                            <v-text-field @input.native="searchLocation" class="mb-2" background-color="white"
                                                            outlined dense hide-details></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col>
                                                            <GmapMap ref="mapRef" :center="mapCenter" :zoom="mapZoom"
                                                            style="width: 100%; height: 250px; border:0;" @click="getMapLocation">
                                                                <GmapMarker :position="mapPosition" :clickable="true" :draggable="true" />
                                                                <GmapInfoWindow :position="mapPosition" :opened="mapInfoWindowOpen">
                                                                    <div>
                                                                        <h4>{{ mapInfoWindowText }}</h4>
                                                                    </div>
                                                                </GmapInfoWindow>
                                                            </GmapMap>
                                                        </v-col>
                                                    </v-row>
                                                    <br>
                                                </v-col>
                                            </template>
                                            <v-col lg="12" sm="12" cols="12" v-if="!HotelPriceFileFocus.show">
                                                <v-row class="card" style="position: sticky; top: 8%; background-color: #FFFFFF; z-index: 10;">
                                                    <v-col cols="6">
                                                        <div style="height: 8px"></div>
                                                        Room Price
                                                    </v-col>
                                                    <v-col cols="6" class="text-lg-right text-sm-right text-center hidden-xs-only hidden-sm-only">
                                                        <div style="float: right;">
                                                            <v-btn :disabled="selectRoom.length === 0 ? true : false"
                                                            class="btn-table mr-2" elevation="0" @click="(RemoveItemdialog.alert = true)">
                                                                <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1">
                                                                </v-img>
                                                                Delete
                                                            </v-btn>
                                                            <v-btn class="btn-table mr-2" elevation="0">
                                                                <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1">
                                                                </v-img>
                                                                Reset
                                                            </v-btn>
                                                            <v-btn class="btn-table" elevation="0" @click="addRoom()">
                                                                <v-img :src="require('@/assets/icons/Add_trans.svg')" width="20px" class="mr-1">
                                                                </v-img>
                                                                Add
                                                            </v-btn>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" class="hidden-md-and-up mt-3 flex-nowrap text-center">
                                                        <v-btn x-small :disabled="selectRoom.length === 0 ? true : false"
                                                        class="btn-table mr-2" elevation="0" @click="(RemoveItemdialog.alert = true)">
                                                            <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1">
                                                            </v-img>
                                                            Delete
                                                        </v-btn>
                                                        <v-btn x-small class="btn-table mr-2" elevation="0">
                                                            <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1">
                                                            </v-img>
                                                            Reset
                                                        </v-btn>
                                                        <v-btn x-small class="btn-table" elevation="0" @click="addRoom()">
                                                            <v-img :src="require('@/assets/icons/Add_trans.svg')" width="20px" class="mr-1">
                                                            </v-img>
                                                            Add
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <br />
                                                <div v-if="(focusHotel.value.HotelPrice)">
                                                    <v-data-table checkbox-color="primary" mobile-breakpoint
                                                    class="overflow-x-auto elevation-1 card" :headers="headersRoomprice"
                                                    item-key="HTPRC_ID" v-model="selectRoom" show-select
                                                    :calculate-widths="true" :items="focusHotel.value.HotelPrice"
                                                    :items-per-page="50" hide-default-footer>
                                                        <template v-slot:[`item.FilePath`]="{item}">
                                                            <v-row>
                                                                <template v-if="item.FilePath && item.FilePath !== ''">
                                                                    <v-col cols="9">
                                                                        <v-img width="120px" v-if="item.FilePath.indexOf('.svg') === -1" class="text-center"
                                                                            :src="item.FilePath" />
                                                                    </v-col>
                                                                    <v-col cols="3" class="pt-4" style="position: relative;">
                                                                        <v-btn
                                                                        class="text-center heightcenter"
                                                                        fab
                                                                        @click="showFileManagement(item)"
                                                                        outlined
                                                                        x-small
                                                                        color="primary">
                                                                        <v-icon dark>
                                                                            mdi-image-plus
                                                                        </v-icon>
                                                                        </v-btn>
                                                                    </v-col>
                                                                </template>
                                                                <template v-else>
                                                                    <v-col cols="10">
                                                                        <div class="text-center">
                                                                            <v-btn
                                                                                fab
                                                                                x-small
                                                                                @click="showFileManagement(item)"
                                                                                outlined
                                                                                color="primary">
                                                                                <v-icon dark x-small>
                                                                                    mdi-image-plus
                                                                                </v-icon>
                                                                            </v-btn>
                                                                        </div>
                                                                    </v-col>
                                                                </template>
                                                            </v-row>
                                                        </template>
                                                        <template v-slot:[`item.BDT_ID`]="{item}">
                                                            <v-autocomplete class="mt-6" ref="BedType" :rules="HotelPricerule.BedType"
                                                            :items="bedtype" item-text="BDT_NAME" item-value="BDT_ID"
                                                            v-model="item.BDT_ID" dense outlined background-color="white"></v-autocomplete>
                                                        </template>
                                                        <template v-slot:[`item.RMT_ID`]="{item}">
                                                            <v-autocomplete class="mt-6" :items="roomtype" ref="RoomType"
                                                            :rules="HotelPricerule.RoomType" item-text="RMT_NAME"
                                                            item-value="RMT_ID" v-model="item.RMT_ID" dense outlined
                                                            background-color="white"></v-autocomplete>
                                                        </template>
                                                        <template v-slot:[`item.OC_ID`]="{item}">
                                                            <v-select class="mt-6" :items="occupancytype" ref="Occupancy"
                                                            :rules="HotelPricerule.Occupancy" item-text="text" item-value="code"
                                                            v-model="item.OC_ID" dense outlined background-color="white"></v-select>
                                                        </template>
                                                        <template v-slot:[`item.HOTEL_PRICE`]="{item}">
                                                            <v-text-field class="mt-6" :items="item.HOTEL_PRICE"
                                                            v-model="item.HOTEL_PRICE" ref="HotelPrice" :rules="HotelPricerule.HotelPrice"
                                                            dense outlined background-color="white"></v-text-field>
                                                        </template>
                                                        <template v-slot:[`item.CURR_ID`]="{item}">
                                                            <v-select class="mt-6" :items="currencytpye" v-model="item.CURR_ID"
                                                            ref="Currency" :rules="HotelPricerule.Currency" dense outlined
                                                            background-color="white"></v-select>
                                                        </template>
                                                        <template v-slot:[`item.STS_ID`]="{item}">
                                                            <v-radio-group dense v-model="item.STS_ID" ref="HotelPriceStatus"
                                                            :rules="HotelPricerule.HotelPriceStatus">
                                                                <v-row>
                                                                    <v-col cols="6" v-for="(itemsts, index) in statusHotel" :key="index">
                                                                        <v-radio :label="itemsts.text" :value="itemsts.code">
                                                                        </v-radio>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-radio-group>
                                                        </template>
                                                    </v-data-table>
                                                    <div id="lastIndex"></div>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" v-else>
                                                <v-card>
                                                    <v-card-title>
                                                        {{ roomtype.filter(x => x.RMT_ID === HotelPriceFileFocus.value.RMT_ID)[0].RMT_NAME }}
                                                        <v-spacer></v-spacer>
                                                        <v-btn v-if="!sortImage.show" x-small color="primary" @click="sortImage.show = true">Sort</v-btn>
                                                        <template v-else>
                                                            <v-btn x-small color="primary" text @click="sortImage.show = false">Cancel</v-btn>
                                                            &nbsp;
                                                            <v-btn x-small outlined color="primary" @click="sortImageSave">Save</v-btn>
                                                        </template>
                                                    </v-card-title>
                                                    <v-card-text class="bg-card">
                                                        <v-row>
                                                            <template v-if="HotelPriceFileFocus.value.RoomFiles.filter(x => x.IsHeader === 1 && x.IsDelete === 0).length > 0 ||
                                                                HotelPriceFileFocus.fileuploadStandBy.filter(x => x.IsHeader === 1).length > 0">
                                                                <v-col cols="4" class="image-container">
                                                                    <v-img width="100%" class="text-center"
                                                                        v-if="HotelPriceFileFocus.fileuploadStandBy.filter(x => x.IsHeader === 1).length > 0"
                                                                        :src="HotelPriceFileFocus.fileuploadStandBy.filter(x => x.IsHeader === 1)[0].ImageShow" />
                                                                    <v-img width="100%" class="text-center"
                                                                        v-if="HotelPriceFileFocus.value.RoomFiles.filter(x => x.IsHeader === 1 && x.IsDelete === 0).length > 0"
                                                                        :src="HotelPriceFileFocus.value.RoomFiles.filter(x => x.IsHeader === 1 && x.IsDelete === 0)[0].FilePath" />
                                                                    <span class="cancel-icon" v-if="HotelPriceFileFocus.value.RoomFiles.filter(x => x.IsHeader === 1).length > 0">
                                                                        <v-btn icon><v-icon color="error" @click="DialogRemove = true, remove('HotelRoomPrice', 'alert',
                                                                        HotelPriceFileFocus.value.RoomFiles.filter(x => x.IsHeader === 1)[0])">mdi-close</v-icon>
                                                                        </v-btn>
                                                                    </span>
                                                                    <span class="cancel-icon" v-else>
                                                                        <v-btn icon><v-icon color="error" @click="removeFiles(HotelPriceFileFocus.fileuploadStandBy
                                                                        .filter(x => x.IsHeader === 1)[0], 'NotSave')">mdi-close</v-icon>
                                                                        </v-btn>
                                                                    </span>
                                                                </v-col>
                                                            </template>
                                                            <template v-else>
                                                                <v-col cols="4">
                                                                    <div class="img-icon">
                                                                        <v-btn
                                                                            fab
                                                                            outlined
                                                                            @click="InputFile('Header')"
                                                                            color="primary">
                                                                            <v-icon dark>
                                                                                mdi-image-plus
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </div>
                                                                        <input type="file" style="display: none;" ref="input_file_header"
                                                                        @change="uploadFileWhenInput('HeaderStandBy', $event)" accept="image/*" />
                                                                </v-col>
                                                            </template>
                                                            <v-col cols="8">
                                                                <v-row>
                                                                    <v-col cols="4" class="image-container" v-for="(HotelPriceitem, index) in
                                                                    HotelPriceFileFocus.value.RoomFiles.filter(x => x.IsHeader === 0)" :key="index">
                                                                        <v-img width="100%" class="text-center"
                                                                            :src="HotelPriceitem.FilePath" />
                                                                        <span class="cancel-icon" v-if="sortImage.show">
                                                                            <v-checkbox :key="index" @click="sortImageEvent(HotelPriceitem)"
                                                                            :on-icon="HotelPriceitem.Order > 0 ? HotelPriceitem.Order.toString() : ''"></v-checkbox>
                                                                        </span>
                                                                        <span class="cancel-icon" v-else>
                                                                            <v-btn icon @click="DialogRemove = true,
                                                                                remove('HotelRoomPrice', 'alert', HotelPriceitem)"><v-icon color="error">mdi-close</v-icon>
                                                                            </v-btn>
                                                                        </span>
                                                                    </v-col>
                                                                    <template v-if="HotelPriceFileFocus.fileuploadStandBy && HotelPriceFileFocus.fileuploadStandBy.length > 0">
                                                                        <v-col class="image-container" cols="4" v-for="(HotelPriceitem, index) in
                                                                        HotelPriceFileFocus.fileuploadStandBy.filter(x => x.IsHeader === 0)" :key="`hp` + index">
                                                                       <v-img width="100%" class="text-center"
                                                                            :src="HotelPriceitem.ImageShow" />
                                                                            <span class="cancel-icon">
                                                                                <v-btn icon><v-icon color="error" @click="removeFiles(HotelPriceFileFocus.fileuploadStandBy
                                                                                .filter(x => x.IsHeader === 0)[0], 'NotSave')">mdi-close</v-icon>
                                                                                </v-btn>
                                                                            </span>
                                                                        </v-col>
                                                                    </template>
                                                                    <v-col cols="4" v-if="!sortImage.show && HotelPriceFileFocus.value.RoomFiles.length > 0 ||
                                                                        HotelPriceFileFocus.fileuploadStandBy.length > 0"
                                                                        style="border-radius: 5px; background-color: #f4fcff; border: solid 1px #00AEEF; display: flex;
                                                                        align-items: center; right: 0; justify-content: center; padding: 50px;">
                                                                        <v-btn
                                                                            fab
                                                                            outlined
                                                                            @click="InputFile('Content')"
                                                                            color="primary">
                                                                            <v-icon dark>
                                                                                mdi-image-plus
                                                                            </v-icon>
                                                                        </v-btn>
                                                                        <input type="file" style="display: none;" ref="input_file"
                                                                        @change="uploadFileWhenInput('StandBy', $event)" accept="image/*" />
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                    <v-btn large text @click="(HotelPriceFileFocus.show ? (HotelPriceFileFocus.show = false) : focusHotel.openDialog = false)">Back</v-btn>
                                    <v-btn large color="primary" :loading="buttonReload" @click="HotelPriceFileFocus.show ?
                                    uploadFileWhenInput('Save', null) : saveHotel()">Save</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-text>
                <v-dialog v-model="DialogRemove" width="500">
                    <v-card>
                        <v-card-title>
                            Remove
                        </v-card-title>
                        <v-card-text>
                            <template v-if="FileFocus">
                                would you like to remove '{{ FileFocus.FileName }}' ?
                            </template>
                            <template v-else>
                                would you like to remove Image File ?
                            </template>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small text @click="DialogRemove = false">Cancel</v-btn>
                            <v-btn small color="error" @click="remove(typeFocus, 'remove', null)">Remove</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="RemoveItemdialog.alert" width="500">
                    <v-card class="card">
                        <v-card-title>
                            Remove
                        </v-card-title>
                        <v-card-text>
                            would you like to delete Room Price ?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small text>Cancel</v-btn>
                            <v-btn small color="primary" :loading="RemoveItemdialog.processing" @click="removeRoomPrice()">Remove</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
        </v-card>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
    data(){
        return{
            mapSearch: "",
            mapInfoWindowText: "",
            mapInfoWindowOpen: false,
            mapCenter: { lat: 0, lng: 0 },
            mapZoom: 15,
            mapPosition: { lat: 0, lng: 0 },
            loadMap: false,
            contentType: 'multipart/form-data',
            sortImage: {
                show: false,
                orderList: []
            },
            HotelPriceFileFocus: {
                show: false,
                value: null,
                fileuploadStandBy: [],
            },
            buttonReload: false,
            FileFocus: null,
            typeFocus: null,
            DialogRemove: false,
            Getfiles: [],
            files: [],
            file: [],
            formatDate: 'YYYY-MM-DD',
            hotelrule: {
                Order: [val => (val || '').length > 0 || 'This Order is required'],
                HotelName: [val => (val || '').length > 0 || 'This Hotel Name is required'],
                Tel: [val => (val || '').length > 0 || 'This Tel is required'],
                PaymentMethod: [val => (val || '').length > 0 || 'This Payment Method is required'],
                HotelStatus: [val => (val || '').length > 0 || 'This HotelStatus is required'],
                CityorProvince: [val => (val || '').length > 0 || 'This CityorProvince is required'],
                Country: [val => (val || '').length > 0 || 'This Country is required'],
            },
            HotelPricerule: {
                BedType: [val => (val || '').length > 0 || 'This Bed Type is required'],
                RoomType: [val => (val || '').length > 0 || 'This Room Type is required'],
                Occupancy: [val => (val || '').length > 0 || 'This Occupancy is required'],
                HotelPrice: [val => (val || '').length > 0 || 'This Price is required'],
                Currency: [val => (val || '').length > 0 || 'This Currency is required'],
                Status: [val => (val || '').length > 0 || 'This Status is required'],
            },
            provinceList: [],
            countryCode: '',
            country: [],
            search: '',
            renderNewDialogs: 0,
            indexcounting: 0,
            RemoveItemdialog: {
                alert: false,
                processing: false
            },
            focusHotel: {
                openDialog: false,
                value: null,
                fileuploadStandBy: []
            },
            statusHotel: [
                { code: '0', text: 'Active' },
                { code: '1', text: 'Inactive' },
            ],
            paymentMethod: [
                { code: 'GA', text: 'Guest Account' },
                { code: 'INV', text: 'Invoice' },
            ],
            headers: [
                { text: "No", value: 'no'},
                { text: "Order", value: 'HTL_ORDER'},
                { text: "Hotel Name", value: 'HTL_NAME' },
                { text: "Country", value: 'COUNTRY_ID' },
                { text: "City / Province", value: 'PRV_ID' },
                { text: "Description", value: 'HTL_DESC' }
            ],
            headersRoomprice: [
                { text: "Image Room", width: 120, value: 'FilePath'},
                { text: "Room Typed", width: 250, value: 'RMT_ID'},
                { text: "Occupancy", width: 60, value: 'OC_ID'},
                { text: "Bed Typed", width: 250, value: 'BDT_ID' },
                { text: "Price", width: 120, value: 'HOTEL_PRICE' },
                { text: "Currency", width: 50, value: 'CURR_ID' },
                { text: "Status", width: 250, value: 'STS_ID' }
            ],
            selectHotel: [],
            selectRoom:[],
            countryList: [
                'TH'
            ],
            roomtype:[],
            bedtype:[],
            occupancytype:[
                { code: 'A', text: '1' },
                { code: 'S', text: 'Share-Room' },
            ],
            currencytpye:[
                'THB'
            ],
            hotels: [],
            itemsPerPage:[
                // { text: "5", value: 5 },
                // { text: "10", value: 10 },
                { text: "15", value: 15 },
                { text: "25", value: 25 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            itemPage: 15,
            page: 1,
            pageCount: 2,
        }
    },
    methods:{
        searchLocation(event){
            if(event.target.value){
                this.getLocationInMap(`address=${event.target.value}`, false, true)
            }
        },
        getMapLocation(event) {
            if(event.placeId){
                this.getLocationInMap(`placeId=${event.placeId}`, false, false)
            }
            else{
                this.getLocationInMap(`latlng=${event.latLng.lat()}${event.latLng.lng()}`, true, false)
            }
        },
        async getLocationInMap(param, showInfoWindow, focusCenter){
            this.mapInfoWindowOpen = false
            await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?${param}&key=${this.$store.state.googleMap}`)
            .then(result=>{
                if(result.data.status === "OK"){
                    const item = result.data.results[0]
                    this.mapPosition.lat = item.geometry.location.lat
                    this.mapPosition.lng = item.geometry.location.lng
                    this.mapInfoWindowText = item.formatted_address
                    if(focusCenter){
                        this.mapCenter.lat = item.geometry.location.lat
                        this.mapCenter.lng = item.geometry.location.lng
                    }
                    if(showInfoWindow){
                        this.mapInfoWindowOpen = true
                    }
                    this.mapZoom = 17
                }
            })
        },
        sortImageSave(){
            axios.post(`${this.$store.state.API_PATH}FileUpload/SortImage`, this.sortImage.orderList,
            ).then(res => {
                if(res.data.Status){
                    this.HotelPriceFileFocus.value.RoomFiles = res.data.Result
                    this.sortImage.show = false
                    this.sortImage.orderList = []
                }
            })
        },
        async sortImageEvent(val){
            if(this.sortImage.orderList.filter(x => x.FileOID === val.FileOID).length === 0){
                if(this.sortImage.orderList.length > 0){
                    const count = await Math.max(...this.sortImage.orderList.map(x => x.Order))
                    val.Order = count + 1
                }else{
                    val.Order = 1
                }
                this.sortImage.orderList.push(val)
            }else{
                const item = this.sortImage.orderList.filter(x => x.Order > val.Order)
                for(let i = 0; i < item.length; i++){
                    item[i].Order -= 1
                }
                this.sortImage.orderList = this.sortImage.orderList.filter(x => x.FileOID !== val.FileOID)
            }
        },
        uploadFileWhenInput(key, event){
            const formData = new FormData()
            const reader = new FileReader()
            if(key !== 'Save' && key !== 'SaveHotel'){
                reader.onload = (e) => {}
                reader.readAsDataURL(event.target.files[0])
            }else{
                if(key === 'Save'){
                    for (let i = 0; i < this.HotelPriceFileFocus.fileuploadStandBy.length; i++) {
                        formData.append(`HotelRoomPrice|${this.HotelPriceFileFocus.fileuploadStandBy[i].IsHeader}`,
                        this.HotelPriceFileFocus.fileuploadStandBy[i].file)
                    }
                    const files = formData
                    axios.post(`${this.$store.state.API_PATH}FileUpload/Upload?ID=` + this.HotelPriceFileFocus.value.HTPRC_ID, files,
                    {
                        headers: {
                            'Content-Type': this.contentType
                        }
                    }).then(res => {
                        this.getHotelByID(this.HotelPriceFileFocus.value.HTL_ID)
                        this.HotelPriceFileFocus.show = false
                        this.HotelPriceFileFocus.fileuploadStandBy = []
                        this.HotelPriceFileFocus.value = null
                    })
                }else if(key === 'SaveHotel'){
                    for (let i = 0; i < this.focusHotel.fileuploadStandBy.length; i++) {
                        formData.append(`Hotel|${this.focusHotel.fileuploadStandBy[i].IsHeader}`,
                        this.focusHotel.fileuploadStandBy[i].file)
                    }
                    const files = formData
                    axios.post(`${this.$store.state.API_PATH}FileUpload/Upload?ID=` + this.focusHotel.value.HTL_ID, files,
                    {
                        headers: {
                            'Content-Type': this.contentType
                        }
                    }).then(res => {
                        this.getHotelByID(this.focusHotel.value.HTL_ID)
                    })
                }
            }
        },
        InputFile(val){
            if(val === 'Header'){
                this.$refs.input_file_header.click()
            }else{
                this.$refs.input_file.click()
            }
        },
        showFileManagement(val){
            axios.post(`${this.$store.state.API_PATH}FileUpload/GetHotelPriceFiles?ID=` + val.HTPRC_ID,
            {
                headers: {
                    'Content-Type': this.contentType
                }
            }).then(res => {
                if (res.data) {
                    this.HotelPriceFileFocus.show = true
                    this.HotelPriceFileFocus.value = val
                    this.HotelPriceFileFocus.value.RoomFiles = res.data.Result
                }
            })
        },
        remove(type, processing, val) {
            this.typeFocus = type
            if (processing === 'alert') {
                this.FileFocus = val
            }
            if (processing === 'remove') {
                this.removeFiles(this.FileFocus, 'Saved')
                this.DialogRemove = false
            }
        },
        removeFiles(val, key) {
            if (key === 'HotelNotSave') {
                this.focusHotel.fileuploadStandBy = this.focusHotel.fileuploadStandBy.filter(x => x.name !== val.name)
                // this.filesize -= val.size
            } else if(key === 'NotSave'){
                this.HotelPriceFileFocus.fileuploadStandBy = this.HotelPriceFileFocus.fileuploadStandBy.filter(x => x.name !== val.name)
            } else if (key === 'Saved') {
                const formData = new FormData();
                for (let i = 0; i < this.files.length; i++) {
                    formData.append(this.typeFocus, this.files[0])
                }
                const files = formData
                axios.post(`${this.$store.state.API_PATH}FileUpload/RemoveFile?FileOID=` +
                `${this.typeFocus === 'HotelRoomPrice' ? val.HTPRC_ID : val.HTL_ID}|${val.FilePath.split('?')[0]}|${val.FileOID}|` +
                `${this.typeFocus === 'HotelRoomPrice' ? 'HotelRoomPrice' : 'Hotel'}`, files,
                {
                    headers: {
                        'Content-Type': this.contentType
                    }
                }).then(res => {
                    if (res.data) {
                        if(this.typeFocus === 'HotelRoomPrice'){
                            this.HotelPriceFileFocus.value.RoomFiles = this.HotelPriceFileFocus.value.RoomFiles.filter(x => x.FileOID !== val.FileOID)
                        }else{
                            this.Getfiles = this.Getfiles.filter(x => x.FileOID !== val.FileOID)
                        }
                    }
                })
            }
        },
        GetFile(val, HTL_ID) {
            axios.post(`${this.$store.state.API_PATH}FileUpload/GetFile?ID=${HTL_ID}|${val}`).then(res => {
                this.Getfiles = res.data.Result
            })
        },
        uploadFilesData(ID){
            const formData = new FormData()
            for (let i = 0; i < this.files.length; i++) {
                formData.append('Hotel', this.files[i])
            }

            const files = formData
            axios.post(`${this.$store.state.API_PATH}FileUpload/Upload?ID=` + ID, files,
            {
                headers: {
                    'Content-Type': this.contentType
                }
            })
        },
        openFileUserfile(val) {
            window.open(val.FilePath)
        },
        AddAttachFile() {
            if (this.Hotelfile && this.Hotelfile.length > 0) {
                const reader = new FileReader()
                reader.readAsArrayBuffer(this.Hotelfile[0])

                reader.onload = (e) => {
                    this.files.push(this.Hotelfile[0])
                }
            }
        },
        removeHotel(){
            if(this.selectHotel.length > 0){
                axios.post(this.$store.state.API_PATH + "RemoveHotel", this.selectHotel.map(x => x.HTL_ID), {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(async res => {
                    this.hotels = res.data.Result
                }).catch(err =>{
                })
            }
        },
        checkRule(){
            let checkvalid = false
            if (!this.$refs.Order.validate()) {
                this.$refs.Order.focus()
                this.$refs.Order.validate(true)
                checkvalid = true
            }
            if (!this.$refs.Tel.validate()) {
                this.$refs.Tel.focus()
                this.$refs.Tel.validate(true)
                checkvalid = true
            }
            if (!this.$refs.HotelName.validate()) {
                this.$refs.HotelName.focus()
                this.$refs.HotelName.validate(true)
                checkvalid = true
            }
            if (!this.$refs.Country.validate()) {
                this.$refs.Country.validate(true)
                checkvalid = true
            }
            if (!this.$refs.CityorProvince.validate()) {
                this.$refs.CityorProvince.validate(true)
                checkvalid = true
            }
            if (!this.$refs.HotelStatus.validate()) {
                this.$refs.HotelStatus.validate(true)
                checkvalid = true
            }
            if (!this.$refs.PaymentMethod.validate()) {
                this.$refs.PaymentMethod.validate(true)
                checkvalid = true
            }
            if (!this.$refs.RoomType.validate()) {
                this.$refs.RoomType.focus()
                this.$refs.RoomType.validate(true)
                checkvalid = true
            }
            if (!this.$refs.Occupancy.validate()) {
                this.$refs.Occupancy.focus()
                this.$refs.Occupancy.validate(true)
                checkvalid = true
            }
            if (!this.$refs.BedType.validate()) {
                this.$refs.BedType.focus()
                this.$refs.BedType.validate(true)
                checkvalid = true
            }
            if (!this.$refs.Currency.validate()) {
                this.$refs.Currency.focus()
                this.$refs.Currency.validate(true)
                checkvalid = true
            }
            if (!this.$refs.HotelPriceStatus.validate()) {
                this.$refs.HotelPriceStatus.focus()
                this.$refs.HotelPriceStatus.validate(true)
                checkvalid = true
            }
            return checkvalid
        },
        getCityCode(status, countryCode) {
            if (status) {
                this.$store.state.LOADING = true
            }
            if(countryCode){
                axios.post(`${this.$store.state.API_PATH}CityCode/${countryCode === 'TH' ? 'GetProvince' : 'GetCityCodeByCountryCode'}`, {
                    CountryCode: countryCode,
                    Language: this.$store.state.Language,
                    Country: countryCode
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then((res) => {
                    if (res.data.Status) {
                        this.provinceList = [];
                        res.data.Result.forEach((elm) => {
                            if (countryCode === 'TH') {
                                this.provinceList.push({ ...elm, Code: elm.code, text: `${elm.name_en} (${elm.code})` });
                            } else {
                                this.provinceList.push({ ...elm, text: `${elm.Name} (${elm.Code})` });
                            }
                        });
                        this.$store.state.LOADING = false;
                    }
                }).catch(() => {
                    this.$store.state.LOADING = false;
                });
            }
        },
        autoIncrement(){
            let incrementNum = 0
            if(this.hotels && this.hotels.length > 0){
                this.hotels.map(x => x.HTL_ID).filter(x => {
                    if(parseInt(x) > incrementNum){
                        incrementNum = x
                    }
                })
            }
            return incrementNum
        },
        getCountryCode(input) {
            if (input) {
                axios.post(`${this.$store.state.API_PATH}CountryCode/GetCountryCode`, {
                    Code: input,
                    Language: this.$store.state.Language,
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then((res) => {
                    if (res.data.Status) {
                        this.country = [];
                        res.data.Result.forEach((elm) => {
                            this.country.push({ ...elm, text: `${elm.Name} (${elm.Code})` });
                        });
                    }
                }).catch((err) => err);
            }
        },
        newRender(){
            this.renderNewDialogs++
        },
        async openDialog(val){
            if(this.$store.state.buttonPermit.menuConfig.Update){
                this.focusHotel.value = null
                this.selectRoom = []
                this.mapCenter = { lat: 0, lng: 0 }
                this.mapPosition = { lat: 0, lng: 0 }
                this.GetFile('Hotel', val.HTL_ID)
                await this.getHotelByID(val.HTL_ID)
                this.focusHotel.openDialog = true
                setTimeout(async()=>{
                    if(val.HTL_LOCATION && this.focusHotel.openDialog){
                        await this.getLocationInMap(`latlng=${val.HTL_LOCATION}`, false, true)
                    }
                },1000)
                this.newRender()
            }
        },
        async addHotel(){
            this.focusHotel.openDialog = true
            const PROFILE = JSON.parse(localStorage.PROFILE)
            this.focusHotel.value = {
                HTL_ID: null,
                no: '',
                COUNTRY_ID: null,
                PRV_ID: null,
                HTL_NAME: null,
                HTL_DESC: null,
                HTL_ORDER: null,
                HTL_CITY: null,
                STS_ID: '1',
                CREATED_BY: PROFILE.EMPLOYEE_ID,
                UPDATED_BY: null,
                CREATED_DATE: moment().format(this.formatDate),
                UPDATED_DATE: moment().format(this.formatDate),
                HTL_TEL: null,
                HTL_FAX: null,
                HTL_EMAIL: null,
                PAY_TYPE: 'INV',
                HotelPrice: [],
                AUTO_INCREMENT: await this.autoIncrement(),
                HTL_ADDRESS: "",
                HTL_LOCATION: null,
                HotelFiles: []
            }
            this.fileuploadStandBy = []
            this.GetFiles = []
        },
        addRoom(){
            const countIndex = this.indexcounting + 1
            this.focusHotel.value.HotelPrice.push({
                HTPRC_ID: `N(${countIndex})`,
                HTL_ID: this.focusHotel.value.HTL_ID,
                RMT_ID: null,
                BDT_ID: null,
                CURR_ID: null,
                OC_ID: null,
                HOTEL_PRICE: null,
                STS_ID: '1',
                CREATED_BY: null,
                CREATED_DATE: moment().format(this.formatDate),
                UPDATED_BY: null,
            })

            location.href = '#lastIndex'
        },
        saveHotel(){
            this.focusHotel.value.HTL_ADDRESS = this.mapInfoWindowText
            this.focusHotel.value.HTL_LOCATION = this.mapPosition.lat && this.mapPosition.lng ? `${this.mapPosition.lat},${this.mapPosition.lng}` : ""
            if(!this.checkRule()){
                this.$store.state.LOADING = true
                this.uploadFileWhenInput('SaveHotel', null)
                axios.post(this.$store.state.API_PATH + "ModifyHotel", this.focusHotel.value, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(async res => {
                    this.uploadFilesData(this.focusHotel.value.HTL_ID)
                    this.focusHotel.openDialog = false
                    this.getHotelList()
                    this.$store.state.LOADING = false
                }).catch(err =>{
                    console.log(err)
                    this.$store.state.LOADING = false
                })
            }
        },
        async removeRoomPrice(){
            this.RemoveItemdialog.processing = true
            this.RemoveItemdialog.alert = true
            const inDatabase = []
            const newItem = []
            await this.selectRoom.forEach(async x => {
                if(x.HTPRC_ID[0] !== 'N'){
                    await inDatabase.push(x)
                }else{
                    await newItem.push(x)
                }
            })
            if(inDatabase.length > 0){
                axios.post(this.$store.state.API_PATH + "RemoveHotelPrice", this.selectRoom, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(async res => {
                    this.focusHotel.value = res.data.Result
                    this.RemoveItemdialog.processing = false
                    this.RemoveItemdialog.alert = false
                }).catch(err =>{
                    this.RemoveItemdialog.processing = false
                    this.RemoveItemdialog.alert = false
                })
            }

            if(newItem.length > 0){
                this.focusHotel.value.HotelPrice = this.focusHotel.value.HotelPrice.filter(a => this.selectRoom.findIndex(c => a.HTPRC_ID === c.HTPRC_ID) < 0)
                this.RemoveItemdialog.processing = false
                this.RemoveItemdialog.alert = false
            }
        },
        async getHotelList(){
            this.$store.state.LOADING = true
            await axios.post(this.$store.state.API_PATH + "GetHotel", {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async res => {
                this.hotels = res.data.Result
                this.$store.state.LOADING = false
            }).catch(err =>{
                this.$store.state.LOADING = false
            })
        },
        getHotelByID(val){
            if(!this.$store.state.LOADING){
                this.$store.state.LOADING = true
            }
            axios.post(`${this.$store.state.API_PATH}GetHotelByID?val=${val}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.focusHotel.value = res.data.Result
                this.getCountryCode(this.focusHotel.value.COUNTRY_ID)
                this.getCityCode(true, this.focusHotel.value.COUNTRY_ID)

                this.$store.state.LOADING = false
            }).catch(err =>{
                this.$store.state.LOADING = false
            })
        },
        getBedType(){
            axios.post(`${this.$store.state.API_PATH}BedType/GetBedType`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async (res) => {
                if (res.data.Status) {
                    this.bedtype = res.data.Result
                }
            })
        },
        getRoomType(){
            axios.post(`${this.$store.state.API_PATH}RoomType/GetRoomType`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                if (res.data.Status) {
                    this.roomtype = res.data.Result
                }
            })
        },
    },
    async mounted(){
        await this.getHotelList();
        await this.getRoomType();
        await this.autoIncrement();
        await this.getBedType();
    }
}
</script>
<style>
    .img-icon{
        border-radius: 5px;
        background-color: #f4fcff;
        border: solid 1px #00AEEF;
        display: flex;
        align-items: center;
        right: 0;
        justify-content: center;
        padding: 90px;
    }
    .image-container{
        position: relative;
        display: inline-block;
    }
    .cancel-icon {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 24px;
        color: white;
        padding: 5px;
    }
    .card-detail {
        background: #F8F9FA;
        border: 1px solid #EBEEF4;
        border-radius: 10px;
        padding: 10px;
    }
</style>
