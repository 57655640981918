<template>
    <v-card class="mb-3">
        <v-col>
            <div v-html="htmlStringHomeContent"></div>
        </v-col>
    </v-card>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            htmlStringHomeContent: ""
        }
    },
    methods: {
        getHomeContentDescription(HomeContentID) {
            this.$store.state.LOADING = true
            axios.post(this.$store.state.API_PATH + "HomeContent/GetHomeContentDescription", {
                ID: HomeContentID
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.htmlStringHomeContent = res.data.Result[0].Description
                }
            }).catch(err => {
                this.$store.state.LOADING = false
                console.log(err)
            })
        }
    },
    mounted() {
        if (this.$route.query.ID) {
            this.getHomeContentDescription(this.$route.query.ID)
        }
    }
}
</script>
