<template>
    <div>
        <CreateTravelRequest />
    </div>
</template>

<script lang="ts">

import CreateTravelRequest from "@/components/travelRequest/CreateTravelRequest.vue"

export default {
    data() {
        return {
          
        }
    },
    mounted(){
    },
    components: {
        CreateTravelRequest
    }
}
</script>
