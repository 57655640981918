import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import HomeContentManagement from "../views/HomeContentManagement.vue";
import HomeContent from "../views/HomeContent.vue";
import InternalTravelerProfile from "../views/InternalTravelerProfile.vue";
import ExternalTravelerProfile from "../views/ExternalTravelerProfile.vue";
import SearchFlight from "../views/SearchFlight.vue";
import MyTaskBTT from "../views/MyTaskBTT.vue";
import MyTask from "../views/MyTask.vue";
import Btt from "../views/Btt.vue";
import Reconcile from "../views/Reconcile.vue";
import Search from "../views/Search.vue";
import Home from "../views/Home";
import SearchHotel from "@/views/SearchHotel.vue";
import HotelData from "@/views/HotelData.vue";
import MyTravelerProfile from "@/views/MyTravelerProfile.vue";
import CancelFlights from "@/views/CancelFlights.vue";
import TripSummary from "@/views/TripSummary.vue";
//Travel Request
import TravelRequest from "@/views/TravelRequest.vue";
import EditTravelRequest from "@/views/TravelRequest/EditTravelRequest.vue";
import TravelRequestInfo from "@/views/TravelRequestInfo.vue";

import AgentService from "@/views/AgentService.vue";
import CreateTravelRq from "@/views/CreateTravelRq.vue";
import BedTypeData from "@/views/BedTypeData.vue";
import RoomTypeData from "@/views/RoomTypeData.vue";
import GroupList from "@/views/GroupLIst.vue";
import UserList from "@/views/UserList.vue";
import Report from "@/views/Report.vue";
import BTMDocManagement from "@/views/BTMDocManagement.vue";
import BTMDocument from "@/views/BTMDocument.vue";
import NewsDocument from "@/views/NewsDocument.vue";
import NewsManagement from "@/views/NewsManagement.vue";
import QuestionareTR from "@/views/QuestionareTR.vue";
import ShortHaul from "@/views/ShortHaul.vue";
import Tariff from "@/views/Tariff.vue";
import RptOffPss from "@/components/doc/RptOffPss.vue";
import BusinessTravelCase from "@/views/BusinessTravelCase.vue";
import Province from "@/views/Province.vue";
import CountryCode from "@/views/CountryCode.vue";
import CityCode from "@/views/CityCode.vue";
import Embassy from "@/views/Embassy.vue";
import AirportCode from "@/views/AirportCode.vue";
import ProposeOfTravel from "@/views/PurposeOfTravel.vue";
import PreferredSeat from "@/views/PreferredSeat.vue";
import SpecialMeal from "@/views/SpecialMeal.vue";
import BookingClass from "@/views/BookingClass.vue";
import TGDirectTicketing from "@/views/TGDirectTicketing.vue";
import Currency from "@/views/Currency.vue";
import RptOffPss2 from "@/components/doc/RptOffPss2.vue";
import RptOffPss3 from "@/components/doc/RptOffPss3.vue";
import RptOffPss4 from "@/components/doc/RptOffPss4.vue";
import RptSponLet from "@/components/doc/RptSponLet.vue";
import RptVisa from "@/components/doc/RptVisa.vue";
import RptAutVisa from "@/components/doc/RptAutVisa.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/HomeContentManagement",
    name: "HomeContentManagement",
    component: HomeContentManagement,
  },
  {
    path: "/HomeContent",
    name: "HomeContent",
    component: HomeContent,
  },
  {
    path: "/InternalTravelerProfile",
    name: "InternalTravelerProfile",
    component: InternalTravelerProfile,
  },
  {
    path: "/ExternalTravelerProfile",
    name: "ExternalTravelerProfile",
    component: ExternalTravelerProfile,
  },
  {
    path: "/searchFlight",
    name: "SearchFlight",
    component: SearchFlight,
  },
  {
    path: "/MyTaskBTT",
    name: "MyTaskBTT",
    component: MyTaskBTT,
  },
  {
    path: "/MyTask",
    name: "MyTask",
    component: MyTask,
  },
  {
    path: "/Btt",
    name: "Btt",
    component: Btt,
  },
  {
    path: "/Reconcile",
    name: "Reconcile",
    component: Reconcile,
  },
  {
    path: "/Search",
    name: "Search",
    component: Search,
  },
  {
    path: "/searchHotel",
    name: "SearchHotel",
    component: SearchHotel,
    props: true,
  },
  {
    path: "/MyTravelerProfile",
    name: "MyTravelerProfile",
    component: MyTravelerProfile,
  },
  {
    path: "/travelRequest",
    name: "TravelRequest",
    component: TravelRequest,
  },
  {
    path: "/travelRequest/edit",
    name: "EditTravelRequest",
    component: EditTravelRequest,
  },
  {
    path: "/createTravelRq",
    name: "CreateTravelRq",
    component: CreateTravelRq,
  },
  {
    path: "/tripSummary",
    name: "TripSummary",
    component: TripSummary,
  },
  {
    path: "/cancelFlights",
    name: "CancelFlights",
    component: CancelFlights,
  },
  {
    path: "/travelRequestInfo",
    name: "TravelRequestInfo",
    component: TravelRequestInfo,
  },
  {
    path: "/BedTypeData",
    name: "BedTypeData",
    component: BedTypeData,
  },
  {
    path: "/RoomTypeData",
    name: "RoomTypeData",
    component: RoomTypeData,
  },
  {
    path: "/HotelData",
    name: "HotelData",
    component: HotelData,
  },
  {
    path: "/BtmDocManagement",
    name: "BtmDocManagement",
    component: BTMDocManagement,
  },
  {
    path: "/BTMDocument",
    name: "BTMDocument",
    component: BTMDocument,
  },
  {
    path: "/NewsDocument",
    name: "NewsDocument",
    component: NewsDocument,
  },
  {
    path: "/NewsManagement",
    name: "NewsManagement",
    component: NewsManagement,
  },
  {
    path: "/GroupList",
    name: "GroupList",
    component: GroupList,
  },
  {
    path: "/UserList",
    name: "UserList",
    component: UserList,
  },
  {
    path: "/AgentService",
    name: "AgentService",
    component: AgentService,
  },
  {
    path: "/Report",
    name: "Report",
    component: Report,
  },
  {
    path: "/ShortHaul",
    name: "ShortHaul",
    component: ShortHaul,
  },
  {
    path: "/Tariff",
    name: "Tariff",
    component: Tariff,
  },
  {
    path: "/QuestionareTR",
    name: "QuestionareTR",
    component: QuestionareTR,
  },
  {
    path: "/BusinessTravelCase",
    name: "BusinessTravelCase",
    component: BusinessTravelCase,
  },
  {
    path: "/CountryCode",
    name: "CountryCode",
    component: CountryCode,
  },
  {
    path: "/CityCode",
    name: "CityCode",
    component: CityCode,
  },
  {
    path: "/Province",
    name: "Province",
    component: Province,
  },
  {
    path: "/Embassy",
    name: "Embassy",
    component: Embassy,
  },
  {
    path: "/AirportCode",
    name: "AirportCode",
    component: AirportCode,
  },
  {
    path: "/ProposeOfTravel",
    name: "ProposeOfTravel",
    component: ProposeOfTravel,
  },
  {
    path: "/PreferredSeat",
    name: "PreferredSeat",
    component: PreferredSeat,
  },
  {
    path: "/SpecialMeal",
    name: "SpecialMeal",
    component: SpecialMeal,
  },
  {
    path: "/BookingClass",
    name: "BookingClass",
    component: BookingClass,
  },
  {
    path: "/TGDirectTicketing",
    name: "TGDirectTicketing",
    component: TGDirectTicketing,
  },
  {
    path: "/Currency",
    name: "Currency",
    component: Currency,
  },
  {
    path: "/doc/RptOffPss",
    name: "RptOffPss",
    component: RptOffPss,
  },
  {
    path: "/doc/RptOffPss2",
    name: "RptOffPss2",
    component: RptOffPss2,
  },
  {
    path: "/doc/RptOffPss3",
    name: "RptOffPss3",
    component: RptOffPss3,
  },
  {
    path: "/doc/RptOffPss4",
    name: "RptOffPss4",
    component: RptOffPss4,
  },
  {
    path: "/doc/RptSponLet",
    name: "RptSponLet",
    component: RptSponLet,
  },
  {
    path: "/doc/RptVisa",
    name: "RptVisa",
    component: RptVisa,
  },
  {
    path: "/doc/RptAutVisa",
    name: "RptAutVisa",
    component: RptAutVisa,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
