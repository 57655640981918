import { UserAgentApplication } from "msal";

var msalConfig = {
  auth: {
    // clientId: "a544a0fb-a69f-41c2-b28c-80231fb20c35",
    // authority: "https://login.microsoftonline.com/5d952400-0849-4ac6-85a9-c172df5e3800",
    // redirectURI: "http://localhost:8080/login",
    // client_secret: "lRY8Q~0AnaFfj1RN~J7Umh67igiwI0mb.5JnScsE"

    clientId: "f1268119-ccd2-4f09-8643-139a8241ee29",
    authority: "https://login.microsoftonline.com/c03b7ea5-365c-4a0f-b767-955babc64911",
    redirectURI: "http://localhost:2600/login",
    client_secret: "wOW8Q~yIWQBgf1LPCW3rTvA2hx2e4QbCbveWAbF7",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

var requestObj = {
  scopes: ["user.read"],
};

var myMSALObj = new UserAgentApplication(msalConfig);

var login = async () => {
  var authResult = await myMSALObj.loginPopup(requestObj);
  return authResult.account;
};

var getAccount = async () => {
  return myMSALObj.getAccount();
};

var logoff = () => {
  myMSALObj.logout();
};

export default {
  login,
  getAccount,
  logoff,
};
