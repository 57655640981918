import axios from "axios";
import store from "../index";
export default {
  namespaced: true,
  state: {
    lifeCycleItem: [],
    HaveBudgetHolder: false,
    TravelRequestLog: [],
  },
  actions: {
    async getTRLifeCycleByPermission({ commit }, param) {
      return new Promise(async (resolve, reject) => {
        await commit("getTRLifeCycleByPermission", param);
        resolve();
      });
    },
    getTravelRequestLogByTRNo({ commit }, param) {
      commit("getTravelRequestLogByTRNo", param);
    },
  },
  mutations: {
    getTRLifeCycleByPermission(state, param) {
      sessionStorage.removeItem("lifeCycleItem");
      this.lifeCycleItem = [];
      const PROFILE = JSON.parse(localStorage.PROFILE);
      axios
        .post(
          store.state.API_PATH + "TravelRequest/GetTravelRequestLifeCycleByPermission",
          {
            TRNo: param.TRNo,
            EMPLOYEE_ID: PROFILE.EMPLOYEE_ID,
          },
          {
            headers: { Authorization: `Basic ${store.state.authenApi}` },
          }
        )
        .then((res) => {
          if (res.data.Status) {
            sessionStorage.lifeCycleItem = JSON.stringify(res.data.Result);
            state.lifeCycleItem = res.data.Result;
            if (res.data.Result != null && res.data.Result.length > 0) {
              state.HaveBudgetHolder = res.data.Result.filter((e) => e.HaveBudgetHolder).length > 0 ? true : false;
            }
          }
        });
    },
    getTravelRequestLogByTRNo(state, param) {
      sessionStorage.removeItem("TravelRequestLog");
      axios
        .get(`${store.state.API_PATH}TravelRequestLog/GetTravelRequestLogByTRNo?TRNo=${param.TRNo}`, {
          headers: { Authorization: `Basic ${store.state.authenApi}` },
        })
        .then((res) => {
          if (res.data.Status) {
            sessionStorage.TravelRequestLog = JSON.stringify(res.data.Result);
            state.TravelRequestLog = res.data.Result;
          }
        });
    },
  },
};
