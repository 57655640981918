<template>
    <v-card elevation="0">
        <v-card-text>
            Filters
        </v-card-text>
        <v-card class="card">
            <v-card-text>
                Tramsit
                <v-radio-group dense v-model="Tramsit">
                    <v-radio key="Any" label="Any" value="Any" class="theme-background"></v-radio>
                    <div style="height: 2px;"></div>
                    <v-radio key="Nonstop (direct)" label="Nonstop" value="Nonstop" class="theme-background">
                    </v-radio>
                    <div style="height: 2px;"></div>
                    <v-radio key="1stop" label="1 stop" value="1stop" class="theme-background"></v-radio>
                    <div style="height: 2px;"></div>
                    <v-radio key="2stop" label="2 stop +" value="2stop" class="theme-background"></v-radio>
                </v-radio-group>
            </v-card-text>
        </v-card>
        <br />
        <v-card class="card">
            <v-card-text>
                Times
                <v-tabs v-model="tabTime">
                    <v-tab key="Departure">
                        <span style="font-size: .8rem;">
                            Departure
                        </span>
                    </v-tab>
                    <v-tab key="Return">
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabTime">
                    <v-tab-item key="Departure">
                        <v-card elevation="0" style="background-color: #F8F9FA;">
                            <br />
                            <v-card-text>
                                Departure Time
                                <br />
                                <span style="font-size: .8rem; color: #66c6e9;">
                                    {{flightTime.dep[0] === 0 && flightTime.dep[1] === 23 ? 'All day' : 'From ' + flightTime.dep[0] + ':00 To ' + flightTime.dep[1] + ':00'}}
                                </span>
                                <br />
                                <span>
                                    <v-range-slider v-model="flightTime.dep" min="0" max="23" @change="SelectflightTime()"></v-range-slider>
                                </span>
                            </v-card-text>
                            <br />
                            <v-card-text>
                                Arrival Time
                                <br />
                                <span style="font-size: .8rem; color: #66c6e9;">
                                    {{flightTime.arr[0] === 0 && flightTime.arr[1] === 23 ? 'All day' : 'From ' + flightTime.arr[0] + ':00 To ' + flightTime.arr[1] + ':00'}}
                                </span>
                                <br />
                                <span>
                                    <v-range-slider v-model="flightTime.arr" min="0" max="23" @change="SelectflightTime()"></v-range-slider>
                                </span>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item key="Return">

                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
        <br />
        <v-card class="card">
            <v-card-text>
                <span>
                    Airlines
                    <br />
                    <br />
                </span>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field solo label="Search Airlines" @input="searchCarries()" v-model="searchCarrie"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-btn @click="clearTextandSelected()" color="info" style="margin-top: -35px; float: right;">
                            <span small style="font-size: .7rem;">Deselect</span>
                        </v-btn>
                    </v-col>
                </v-row>
                Results
                <br />
                <v-checkbox @click="allcarries()" v-model="allcheck" hide-details class="theme-background" label="All Airlines" dense>
                </v-checkbox>
                <span v-if="filterairline != null">
                    <div v-for="(itemcarries, index) in searchCarries(filterairline.airlines)" :key="index">
                        <v-checkbox @click="selectCarries(itemcarries)" v-model="selectedCarries"
                        hide-details class="theme-background"
                        :label="itemcarries.name" :value="itemcarries.name" dense>
                        </v-checkbox>
                    </div>
                </span>
            </v-card-text>
        </v-card>
    </v-card>
</template>

<script>
export default {
    name: "sFilter",
    props:{
        carries: Object,
        flightRequest: {}
    },
    data() {
        return {
            tab: null,
            tabTime: null,
            InResult: null,
            ChangSearch: false,
            YourFlight: false,
            Tramsit: 'Any',
            filterairline: null,
            selectedCarries: [],
            allcheck: true,
            searchCarrie: '',
            flightTime:{
                dep: [0, 23],
                arr: [0, 23]
            }
        }
    },
    watch:{
        Tramsit(){
            this.$emit('Tramsit', this.Tramsit)
        },
        carries(){
            this.filterairline = this.carries
            this.allcarries()
        },
        allcheck(){
        }
    },
    methods: {
        SelectflightTime(){
            this.$emit('flightTime', this.flightTime)
        },
        clearTextandSelected(){
            this.searchCarrie = ''
            this.allcheck = false
            this.selectedCarries = []
            this.$emit('selectedCarries', this.selectedCarries)
        },
        searchCarries(val){
            let result = val
            if(this.filterairline.airlines != null && this.filterairline.airlines.length > 0){
                result = this.filterairline.airlines.filter(x => x.name.toLowerCase().indexOf(this.searchCarrie.toLowerCase()) > -1)
            }
            return result
        },
        allcarries(){
            if(this.carries.airlines != null && this.carries.airlines.length > 0){
                if(this.allcheck){
                    this.selectedCarries.push(...this.carries.airlines.filter(x=>x.name).map(x=>x.name))
                }else{
                    this.selectedCarries = []
                }
            }
            this.$emit('selectedCarries', this.selectedCarries)
        },
        selectCarries(val){
            if(this.allcheck){
                this.allcheck = false
                this.selectedCarries = [val.name]
            }

            if(this.carries.airlines.length === this.selectedCarries.length){
                this.allcheck = true
            }

            setTimeout(() => {
                this.$emit('selectedCarries', this.selectedCarries)
            }, 1)
        },
        ShowDetails() {
            this.isActive = !this.isActive;
        },
        chengeSearch() {
            this.ChangSearch = !this.ChangSearch;
        },
        showYourFlight(){
            this.YourFlight = true;
            this.$emit("showYourFlight", true)
        }
    },
    mounted(){
        setTimeout(() => {
            this.allcarries();
        }, 1);
        this.filterairline = this.carries
    }
}
</script>

<style scoped>
.hideCard {
    display: none;
}

.flight-select{
    border-left: solid 4px #99e210;
}

.theme-background {
    background-color: white;
    border-radius: 10px;
    padding: 8px 8px;
}
</style>
