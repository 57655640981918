<template>
  <div v-if="!CoperateLoading">
    <h3>
      <span class="f-text-20 font-W400"><a href="/travelRequest" style="color: #747678; text-decoration: none;">Travel
          Request</a> /
        <span style="text-decoration: underline;">
          Create Travel Request
        </span>
      </span>
      <v-btn style="float: right; pointer-events: auto;" @click="backtopage" text>Back</v-btn><br />
      <span class="f-text-32 font-W700">Travel Request</span>
    </h3><br />
    <v-card class="card" elevation="0">
      <v-card-text>
        <v-row class="f-text-22 font-W400">
          <v-col cols="12" xs="12" sm="6" md="4">
            Travel Request Number<br />
            <span class="f-text f-weight">{{ TRNo }}</span>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="4">
            Business Travel Type<br />
            <span class="f-text f-weight"> {{ BusinessTravelType }} </span>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="4">
            Travel Request Status<br />
            <span class="f-text f-weight"> {{ travelRequestStatus }} </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card><br />
    <v-card elevation="0" class="card" id="coperate">
      <v-card-text>
        <span class="f-text-30 font-W500">Travel Information</span>
      </v-card-text>
      <v-card-text>
        <div class="bgcard" style="position: relative;">
          <v-row>
            <v-col cols="6">
              <span class="f-text-24 font-W400">
                Corporate Budget
              </span>
            </v-col>
            <v-col cols="6">
              <template v-if="SaveCoperate === 1 && TR_EMPLOYEE_ID === EMPLOYEE.EMPLOYEE_ID && (travelRequestStatus !== 'Reject' && travelRequestStatus !== 'Canceled')">
                <div>
                  <v-btn class="btn-primary" v-if="!EditCoperate" style="float: right;"
                    @click="EditCoperate = true, disableCoperate = false">
                    <v-img :src="require('@/assets/icons/EditDetail.svg')" />
                    <span class="pl-3 hidden-sm-and-down">
                      Edit
                    </span>
                  </v-btn>
                  <span v-else style="float: right;">
                    <span class="hidden-sm-and-down">
                      <v-btn text @click="EditCoperate = false, disableCoperate = true">Cancel</v-btn>&nbsp;
                      <v-btn class="btn-primary" @click="travelRequestStatus === 'Submitted' ? dialogAlert('Revised') :
                      (travelRequestStatus === 'Approved Final' || travelRequestStatus === 'Re-issue') ? dialogAlert('Re-Issue')
                       : dialogAlert('Draft'), !RequiredCoperate() ? disableCoperate = true : disableCoperate = false,
                        !RequiredCoperate() ? EditCoperate = false :
                         EditCoperate = true" v-if="$store.state.buttonPermit.menuConfig.Update">Save</v-btn>
                    </span>
                    <span class="hidden-sm-and-up">
                      <v-btn text color="primary" x-small
                        @click="EditCoperate = false, disableCoperate = true">Cancel</v-btn>
                      <v-btn color="primary" class="btn-primary" small v-if="$store.state.buttonPermit.menuConfig.Update"
                        @click="travelRequestStatus === 'Submitted' ? dialogAlert('Revised') :
                        (travelRequestStatus === 'Approved Final' || travelRequestStatus === 'Re-issue') ? dialogAlert('Re-Issue') : dialogAlert('Draft'), !RequiredCoperate() ?
                          disableCoperate = true : disableCoperate = false, !RequiredCoperate() ? EditCoperate = false : EditCoperate = true">
                        Save
                      </v-btn>
                    </span>
                  </span>
                </div>
              </template>
            </v-col>
          </v-row><br />
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="6">
              <span>Budget Holder</span> <span style="color: red">*</span>
              <v-autocomplete hide-details class="f-text f-weight" background-color="white" v-model="BudgetHolder"
                @change="getBusinessTravel()" :append-icon="disableCoperate || travelRequestStatus === 'Approve Final' ? '' : '$dropdown'"
                :disabled="!disableCoperate && (travelRequestStatus === 'Approve Final' || travelRequestStatus === 'Re-Issue')"
                :readonly="disableCoperate || travelRequestStatus === 'Approve Final'" ref="BudgetHolder" :rules="rule.BudgetHolder" :items="budgetProject"
                item-text="BudgetHolder" item-value="BudgetHolder" dense outlined></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <span>Business Travel Type</span>
              <v-select background-color="white" hide-details dense outlined v-model="BusinessTravelType"
                :disabled="!disableCoperate && (travelRequestStatus === 'Approve Final' || travelRequestStatus === 'Re-Issue')"
                :items="TravelType" :append-icon="disableCoperate || travelRequestStatus === 'Approve Final' ? '' : '$dropdown'"
                :readonly="disableCoperate || travelRequestStatus === 'Approve Final'"
                ref="BusinessTravelType" :rules="rule.BusinessTravelType"></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <span>Project</span>
              <span style="color: red">*</span>
              <v-select background-color="white" hide-details v-model="ProjectName" :items="projectData"
                :disabled="!disableCoperate && (travelRequestStatus === 'Approve Final' || travelRequestStatus === 'Re-Issue')"
                :append-icon="disableCoperate || travelRequestStatus === 'Approve Final' ? '' : '$dropdown'" item-text="ProjectName"
                :readonly="disableCoperate || travelRequestStatus === 'Approve Final'"
                ref="Project" :rules="rule.Project" item-value="ProjectName" @change="getCompanyByProject()" outlined
                dense></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <span>Company</span>
              <v-text-field outlined dense background-color="white" hide-details v-model="CompanyName" readonly
                :disabled="!disableCoperate && (travelRequestStatus === 'Approve Final' || travelRequestStatus === 'Re-Issue')"
                ref="Company" :rules="rule.Company"></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <span>Business Start Date</span>
              <span style="color: red">*</span>
              <v-menu v-model="menuStartDate" :close-on-content-click="false" hide-details :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:[`activator`]="{ on, attrs }">
                  <v-text-field background-color="white" hide-details ref="BusinessStartDate"
                    :rules="rule.BusinessStartDate" :value="formatDatepicker(startDate)" outlined dense readonly
                    v-bind="attrs" v-on="!disableCoperate ? on : ''">
                    <v-icon v-if="!disableCoperate" slot="append" color="primary">mdi-calendar</v-icon>
                  </v-text-field>
                </template>
                <v-date-picker v-model="startDate" :min="nowDate" @input="menuStartDate = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <span>Business End Date</span>
              <span style="color: red">*</span>
              <v-menu v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:[`activator`]="{ on, attrs }">
                  <v-text-field hide-details background-color="white" ref="BusinessEndDate" :rules="rule.BusinessEndDate"
                    :value="formatDatepicker(endDate)" outlined dense readonly v-bind="attrs"
                    v-on="!disableCoperate ? on : ''">
                    <v-icon v-if="!disableCoperate" slot="append" color="primary">
                      mdi-calendar
                    </v-icon>
                  </v-text-field>
                </template>
                <v-date-picker v-model="endDate" :min="startDate" @input="menuEndDate = false">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <span>
                Country
              </span>
              <v-autocomplete :append-icon="disableCoperate ? '' : '$dropdown'" hide-details background-color="white"
                v-model="countryCode" @change="getCityCodeCreateTravel(true)" :readonly="disableCoperate" ref="Country"
                no-data-text="Please Type Country"
                :rules="rule.Country" @input.native="getCountryCodeCreteTravel($event.srcElement.value)"
                :items="countryTravel" item-text="text" item-value="Code" dense outlined></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <span>
                City or Province
              </span>
              <v-autocomplete :append-icon="disableCoperate ? '' : '$dropdown'" hide-details background-color="white"
                v-model="cityCode" :items="cityTravel" item-text="text" item-value="Code" ref="CityorProvince"
                :rules="rule.CityorProvince" :readonly="disableCoperate" dense outlined>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <span>
                Purpose of Travel
              </span>
              <v-select :append-icon="disableCoperate ? '' : '$dropdown'" v-model="PurId" hide-details
                background-color="white" :items="PurposeOfTravel" item-text="PurDesc" :readonly="disableCoperate"
                ref="PurposeofTravel" :rules="rule.PurposeofTravel" item-value="PurDesc" dense outlined>
              </v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <v-col>
                <v-checkbox label="No Service Required" :disabled="isSaved" hide-details
                  v-model="selectedNoRequireServices" />
              </v-col>
              <v-col>
               <v-text-field v-if="selectedNoRequireServices" label="Require fill the reason" :disabled="isSaved"
               outlined dense background-color="white" hide-details v-model="NoServiceRequireReason"
               :items="NoServiceRequireReason" ref="NoServiceRequireReason" :rules="rule.NoServiceRequireReason"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12">
              <div id="app">
            </div>
            <span>
                Description Purpose of Travel
            </span>
              <v-textarea background-color="white" v-model="DesofTravel" outlined dense
                :readonly="disableCoperate"></v-textarea>
            </v-col>
            <v-col cols="12">
              <span>
                Attach Files
              </span>
              <template v-if="!disableCoperate && (travelRequestStatus !== 'Reject' && travelRequestStatus !== 'Canceled')">
                <div class="h-space"></div>
                <v-btn class="btn-primary" @click="InputFile">
                  Upload File(s)
                </v-btn>
              </template>
              <br />
              <br />
              <input type="file" counter multiple hide-details="" ref="input_file" style="display: none"
                :disabled="disableCoperate" outlined show-size background-color="white"
                accept="image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, .pdf,
              application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                @change="AddAttachFile($event)" />
              <v-row>
                <template v-if="files.length > 0">
                  <v-col cols="3" v-for="(fileitem, index) in files" :key="'n' + index">
                    <v-card class="card">
                      <v-card-text class="bgcard">
                        <v-row>
                          <v-col cols="2">
                            <v-icon v-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'xlsx' ||
                              fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'xls'" class="mt-3"
                              color="success">mdi-file-excel</v-icon>
                            <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'png'"
                              class="mt-3" color="error">mdi-file-png-box</v-icon>
                            <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'pdf'"
                              class="mt-3" color="error">mdi-file-pdf-box</v-icon>
                            <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'jpg'"
                              class="mt-3" color="error">mdi-file-jpg-box</v-icon>
                            <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'zip'"
                              class="mt-3" color="#EAC282">mdi-zip-box</v-icon>
                            <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'rar'"
                              class="mt-3" color="#EAC282">mdi-bookmark-box-multiple</v-icon>
                            <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'docx' ||
                              fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'doc'" class="mt-3"
                              color="primary">mdi-file-word</v-icon>
                            <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'xml'"
                              class="mt-3" color="primary">mdi-xml</v-icon>
                            <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'txt'"
                              class="mt-3" color="#E3751C">mdi-file-document</v-icon>
                            <v-icon v-else-if="fileitem.name.split('.')[fileitem.name.split('.').length - 1] == 'svg'"
                              class="mt-3" color="#E3751C">mdi-svg</v-icon>
                            <v-icon v-else class="mt-3">mdi-file-outline</v-icon>
                          </v-col>
                          <v-col cols="10"
                            :style="disableCoperate ? 'pointer-events: none; height: 55px;' : 'height: 55px;'">
                            <v-icon style="float: right; cursor: pointer;"
                              @click="removeFiles(fileitem, 'NotSave')">mdi-close</v-icon>
                            <div class="mt-3">
                              {{ fileitem.name.length > 15 ? fileitem.name.split('.')[0].slice(0, 10) + '...' +
                                fileitem.name.split('.')[fileitem.name.split('.').length - 1] : fileitem.name }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
                <template v-if="Getfiles.length > 0">
                  <v-col cols="3" v-for="(fileitem, index1) in Getfiles" :key="'o' + index1">
                    <v-card class="card">
                      <v-card-text class="bgcard">
                        <v-row>
                          <v-col cols="2" @click="openFile(fileitem)" style="cursor: pointer;">
                            <v-icon v-if="fileitem.FileName.split('.')[1] === 'xlsx' ||
                              fileitem.FileName.split('.')[1] === 'xls'" class="mt-3"
                              color="success">mdi-file-excel</v-icon>
                            <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'png'" class="mt-3"
                              color="error">mdi-file-png-box</v-icon>
                            <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'pdf'" class="mt-3"
                              color="error">mdi-file-pdf-box</v-icon>
                            <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'jpg'" class="mt-3"
                              color="error">mdi-file-jpg-box</v-icon>
                            <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'zip'" class="mt-3"
                              color="#EAC282">mdi-zip-box</v-icon>
                            <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'rar'" class="mt-3"
                              color="#EAC282">mdi-bookmark-box-multiple</v-icon>
                            <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'docx' ||
                              fileitem.FileName.split('.')[1] === 'doc'" class="mt-3"
                              color="primary">mdi-file-word</v-icon>
                            <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'xml'" class="mt-3"
                              color="primary">mdi-xml</v-icon>
                            <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'txt'" class="mt-3"
                              color="#E3751C">mdi-file-document</v-icon>
                            <v-icon v-else-if="fileitem.FileName.split('.')[1] === 'svg'" class="mt-3"
                              color="#E3751C">mdi-svg</v-icon>
                            <v-icon v-else class="mt-3">mdi-file-outline</v-icon>
                          </v-col>
                          <v-col cols="10">
                            <v-icon
                              :style="disableCoperate ? 'pointer-events: none; float: right; display: none;' : 'cursor: pointer; float: right;'"
                              @click="DialogRemove = true, removeFiles(fileitem, 'Saved')">mdi-close</v-icon>
                            <div class="mt-3" @click="openFile(fileitem)" style="cursor: pointer;">
                              {{
                                fileitem.FileName.length > 15 ?
                                fileitem.FileName.slice(0, 10) + '...' +
                                fileitem.FileName.split('.')[1] : fileitem.FileName
                              }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <br />
        <div class="bgcard">
          <span class="f-text-24 font-W400">BTT Support</span>
          <div class="h-space"></div>
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="4">
              Name
              <br />
              <span class="f-text-22-b font-W500">
                {{ BTTName }}
              </span>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              Phone
              <br />
              <span class="f-text-22-b font-W500" v-if="BTTName">
                {{ BTTPhone }}
              </span>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              Email
              <br />
              <span class="f-text-22-b font-W500">
                {{ Email }}
              </span>
            </v-col>
          </v-row>
        </div>
        <br />
        <div class="bgcard">
          <span class="f-text-24 font-W400">Budget Holder Approver</span>
          <div class="h-space"></div>
          <v-row v-for="(a,i) in approveArr" :key="i">
            <v-col cols="12" xs="12" sm="6" md="4">
              Name
              <br />
              <span class="f-text-22-b font-W500">
                {{ a.approveName }}<br>
                {{ a.approvePosition }}
              </span>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              Phone
              <br />
              <span class="f-text-22-b font-W500">
                {{ a.approveTel ? a.approveTel : "-" }}
              </span>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              Email
              <br />
              <span class="f-text-22-b font-W500">
                {{ a.approveEmail }}
              </span>
            </v-col>
          </v-row>
        </div>
        <a id="traveler"></a>
        <v-row v-if="!EditCoperate && SaveCoperate == 0 && (travelRequestStatus !== 'Reject' && travelRequestStatus !== 'Canceled')">
          <v-col class="text-center">
            <br />
            <v-btn outlined color="primary" @click="backtotravelreq()">Cancel</v-btn>
            &nbsp;
            <v-btn color="primary" @click="saved('Draft')" v-if="$store.state.buttonPermit.menuConfig.Update">Save</v-btn>
          </v-col>
        </v-row>
        <template v-else>
          <div class="f-text-30 h-space">Traveler</div>
          <template v-if="reloadPage">
            <div class="bgcard">
              <div v-if="EMPLOYEE.POSITION.indexOf('Secretary') != -1">
                <v-layout wrap>
                  <v-flex align-self-center>
                    <span class="f-text-24-b font-W500">Details of Persons On Mission </span>
                  </v-flex>
                  <v-flex class="text-lg-right text-md-right text-sm-right hidden-sm-and-down"
                    v-if="travelRequestStatus !== 'Re-Issue' && travelRequestStatus !== 'Approve Final' &&
                    travelRequestStatus !== 'Revised' && travelRequestStatus !== 'Submitted' &&
                    (travelRequestStatus !== 'Reject' && travelRequestStatus !== 'Canceled')">
                    <v-btn :disabled="travelers.filter(x => x.TravelCard.IsDelete === 0).length >= 9 ? true : false"
                      class="btn-primary mr-3 mt-2" small @click="AddTravelerRequest('Internal')"
                      v-if="$store.state.buttonPermit.menuConfig.Add">
                      <v-img :src="require('@/assets/icons/Add_profile.svg')"></v-img>
                      <span class="ml-2"> Add Internal </span>
                    </v-btn>
                    <v-btn :disabled="travelers.filter(x => x.TravelCard.IsDelete === 0).length >= 9 ? true : false"
                      class="btn-primary mt-2" small @click="AddTravelerRequest('External')"
                      v-if="$store.state.buttonPermit.menuConfig.Add">
                      <v-img :src="require('@/assets/icons/Add_profile.svg')"></v-img>
                      <span class="ml-2"> Add External </span>
                    </v-btn>
                  </v-flex>
                  <br />
                  <br />
                  <v-row class="text-center hidden-md-and-up"
                  v-if="travelRequestStatus !== 'Re-Issue' && travelRequestStatus !== 'Approve Final' && travelRequestStatus !== 'Revised' && travelRequestStatus !== 'Submitted'
                  && (travelRequestStatus !== 'Reject' && travelRequestStatus !== 'Canceled')">
                    <v-col cols="6">
                      <v-btn :disabled="travelers.filter(x => x.TravelCard.IsDelete === 0).length > 9 ? true : false"
                        class="btn-primary mr-3 mt-2 fill-height" small @click="AddTravelerRequest('Internal')">
                        <v-img :src="require('@/assets/icons/Add_profile.svg')"></v-img>
                        <span class="ml-2"> Add Internal </span>
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn :disabled="travelers.filter(x => x.TravelCard.IsDelete === 0).length > 9 ? true : false"
                        class="btn-primary mt-2 fill-height" small @click="AddTravelerRequest('External')">
                        <v-img :src="require('@/assets/icons/Add_profile.svg')"></v-img>
                        <span class="ml-2"> Add External </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-layout>
                <v-dialog v-model="showAddTraveler" max-width="920" persistent>
                  <dialogAddTraveler :key="reloading" :typeTraveler="typeTraveler"
                    :travelers="travelers.filter(x => x.TravelCard.IsDelete === 0).length"
                    @selectedTraveler="selectedTraveler = $event" :items="seleteTravelCard"
                    @dialogOpenOrClose="showAddTraveler = $event" :GroupFilter="GroupFilter"
                    :DivisionFilter="DivisionFilter" :DepartmentFilter="DepartmentFilter" :SectionFilter="SectionFilter"
                    @getTRLifeCycleByPermission="getTRLifeCycleByPermission">
                  </dialogAddTraveler>
                </v-dialog>
              </div>
              <template v-if="travelers && travelers.length > 0">
                <div style="position: relative;">
                  <br />
                  <div style="position: absolute; right: 0">
                    Travelers
                    <span :style="travelers.filter(x => x.TravelCard.IsDelete === 0).length >= 9 ?
                      'color:red;' : ''">
                      {{ travelers.filter(x => x.TravelCard.IsDelete === 0).length }}/9
                    </span>
                  </div>
                  <br />
                  <span v-if="travelers && travelers.length > 2" style="position: -webkit-sticky; position: sticky; top: 50%; z-index: 8;">
                    <span class="hidden-sm-and-down">
                      <v-btn @mouseup="leftScroll" color="primary" fab>
                        <v-icon large>mdi-chevron-left-circle</v-icon>
                      </v-btn>
                      <v-btn @mouseup="rightScroll" color="primary" style="float: right;" fab>
                        <v-icon large>mdi-chevron-right-circle</v-icon>
                      </v-btn>
                    </span>
                    <span class="hidden-md-and-up">
                      <v-btn small @mouseup="leftScroll" color="primary" fab>
                        <v-icon>mdi-chevron-left-circle</v-icon>
                      </v-btn>
                      <v-btn small @mouseup="rightScroll" color="primary" style="float: right;" fab>
                        <v-icon>mdi-chevron-right-circle</v-icon>
                      </v-btn>
                    </span>
                  </span>
                  <v-layout class="overflow-x-auto scroll-travelers" :style="(travelRequestStatus === 'Reject' ||
                  travelRequestStatus === 'Canceled' ||
                  ItemPending.filter(x=>x.TravelRequestApprover.indexOf(EMPLOYEE.EMPLOYEE_ID) !== -1).length > 0 ||
                  ItemPending.filter(x=>x.LINE_APPROVE.indexOf(EMPLOYEE.EMPLOYEE_ID) !== -1).length > 0 ? 'pointer-events: none;' : '') +
                  'background-color: #BCBEC0; border-radius: 5px;' ">
                      <template v-for="(itemTravel, index) in travelers.filter(x => x.TravelCard.IsDelete === 0)">
                        <v-col v-if="itemTravel.emp_ID == EMPLOYEE.EMPLOYEE_ID" cols="12" md="5" sm="6" lg="4" xl="4"
                          :key="index">
                          <Traveler :travel="itemTravel" :num="index" ref="TravelerCom" :BTTStatus="BTTStatus"
                            :travelers="travelers.filter(x => x.TravelCard.IsDelete === 0)" @travelers="travelers = $event"
                            :NoRequireServices="selectedNoRequireServices" @dueDate="dueDate"
                            @getTRLifeCycleByPermission="getTRLifeCycleByPermission" :BusinessStartDate="startDate"
                            :BusinessEndDate="endDate" :TRNo="TRNo" :BudgetHolderTR="BudgetHolderTR" />
                        </v-col>
                        <v-col v-else-if="EMPLOYEE.POSITION.indexOf('Secretary') != -1 || EMPLOYEE.IS_BTT === 1 ||
                          (ItemPending.length > 0 && ItemPending.filter(x=>x.EMPLOYEE_ID === itemTravel.emp_ID).length > 0)"
                          cols="12" md="5" sm="6" lg="4" xl="4" :key="index">
                          <Traveler :travel="itemTravel" @travelers="travelers = $event" :num="index" ref="TravelerCom"
                            :BTTStatus="BTTStatus" @getTRLifeCycleByPermission="getTRLifeCycleByPermission"
                            :travelers="travelers.filter(x => x.TravelCard.IsDelete === 0)" :BusinessStartDate="startDate"
                            :BusinessEndDate="endDate" :BudgetHolderTR="BudgetHolderTR"
                            :NoRequireServices="selectedNoRequireServices" :TRNo="TRNo" />
                        </v-col>
                      </template>
                  </v-layout>
                  <br />
                  <br />
                </div>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="bgcard">
              <p style="height: 100px;"></p>
              <div class="text-center">
                <v-progress-circular indeterminate color="primary" :size="80" :width="6"></v-progress-circular>
                <br />
                <br />
                <span v-if="reloadingPage">
                  please wait
                </span>
              </div>
              <p style="height: 100px;"></p>
            </div>
          </template>
          <div class="f-text-30 h-space h-space">Summary</div>
          <div class="bgcard">
            <div class="f-text-24 font-W500"> Summary </div>
            <div class="h-space"></div>
            <v-row>
              <v-col cols="4">
                Total Number of Persons
                <br />
                <div class="f-text-22-b">
                  {{ travelers.filter(x => x.TravelCard.IsDelete === 0).length }} Person
                </div>
              </v-col>
              <v-col cols="4">
                Total Advance Amount
                <br />
                <div class="f-text-22-b">
                  {{ totalAdvance.toLocaleString('th',
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} THB
                </div>
              </v-col>
            </v-row>
          </div>
          <br />
          <div class="bgcard">
            <v-btn :disabled="this.travelers.length === 0" class="btn-primary" small @click='printPage()'>
              <v-img :src="require('@/assets/icons/PrintPrimaryColor.svg')" />
              <span class="ml-2" >Print</span>
            </v-btn>
            <v-btn v-if="TR_EMPLOYEE_ID === EMPLOYEE.EMPLOYEE_ID && (travelRequestStatus !== 'Reject'
             && travelRequestStatus !== 'Canceled')" class="btn-primary" color="#d0e6fd" small
              style="float: right; pointer-events: auto;" @click="DialogTrCancel()">
              <v-img :src="require('@/assets/icons/CancelTr.svg')" />
              <span class="ml-2">Cancel TR</span>
            </v-btn>
          </div>
          <br />
          <v-divider></v-divider>
          <br />
          <template v-if="TR_EMPLOYEE_ID === EMPLOYEE.EMPLOYEE_ID">
            <div class="text-center" v-if="!$store.state.showBtnRejectReturnApprove && travelRequestStatus !== 'Revised' &&
              travelRequestStatus !== 'Submitted' && travelRequestStatus !== 'Approve Final' &&
               travelRequestStatus !== 'Re-Issue' &&
                (travelRequestStatus !== 'Reject' && travelRequestStatus !== 'Canceled')">
              <v-btn outlined color="primary" onclick="window.location.href = '/travelRequest'">
                Back
              </v-btn>
              &nbsp;
              <v-btn color="primary" @click="dialogAlert('Draft')" v-if="$store.state.buttonPermit.menuConfig.Update">Save</v-btn>
              &nbsp;
              <v-btn class="btn-success" @click="dialogAlert('Submitted')" v-if="$store.state.buttonPermit.menuConfig.Update">Submit
              </v-btn>
            </div>
            <div class="text-center" v-if="(travelRequestStatus === 'Re-Issue' || travelRequestStatus === 'Approve Final') && isOwner">
              <v-btn outlined color="primary" onclick="window.location.href = '/travelRequest'">
                Back
              </v-btn>
              &nbsp;
              <v-btn color="#F0BC2D" @click="dialogAlert('Re-Issue')" style="color: white">Re-Issue</v-btn>
            </div>
            <div class="text-center" v-if="(travelRequestStatus === 'Submitted' || travelRequestStatus === 'Revised') && isOwner">
              <v-btn outlined color="primary" onclick="window.location.href = '/travelRequest'">
                Back
              </v-btn>
              &nbsp;
              <v-btn class="btn-success" @click="dialogAlert('Revised')" style="color: white" v-if="$store.state.buttonPermit.menuConfig.Revise">Revised</v-btn>
            </div>
            <br />
          </template>
          <template v-if="BudgetHolderTR.indexOf(EMPLOYEE.EMPLOYEE_ID) !== -1 &&
          (TravelerArrID.length > 0 && TravelerArrID.filter(x=>!x.BudgetHolder).length > 0)">
            <div class="text-center">
              <v-btn class="ma-1" outlined color="#B44545" elevation="0" @click="actionStatusMytask('Reject', false)" dark>Reject</v-btn>
              &nbsp;
              <v-btn class="ma-1" color="#F0BC2D" elevation="0" @click="actionStatusMytask('Return', false)" dark>Return</v-btn>
              &nbsp;
              <v-btn class="btn-success ma-1" elevation="0" @click="actionStatusMytask('Approve', false)" dark >Approve</v-btn>
            </div>
          </template>
          <v-card-text :style="travelRequestStatus === 'Submitted' ? 'pointer-events: auto;' : ''">
            <div class="bgcard">
              <div id="Action_History" class="f-text-30 font-W500">Action History</div>
              <div class="h-space"></div>
              <v-card class="card-note" elevation="0">
                <v-row class="overflow-x-auto myTicket mt-2" style="margin: 1px; max-height: 252px;">
                  <v-col>
                    <table aria-describedby="Action_History" style="border-collapse: collapse; width: 100%" id="Note">
                      <tr>
                        <th>Action Date</th>
                        <th>Time Stamp</th>
                        <th>Name</th>
                        <th>Action Result</th>
                        <th>Comment</th>
                      </tr>
                      <tr v-for="(item, index) in TravelRequestLog" :key="index">
                        <td>
                          <span class="span-W500">{{ item.ActionDate }}</span>
                        </td>
                        <td>
                          <span class="span-W500">{{ item.TimeStamp }}</span>
                        </td>
                        <td>
                          <span>{{ item.ActionName }}</span>
                        </td>
                        <td>
                          <span class="span-W500">{{ item.ActionResult }}</span>
                        </td>
                        <td>
                          <span class="span-W500">{{ item.Comment }}</span>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <br />
            <div id="Life_Cycle" class="f-text-30 font-W500">Life Cycle</div>
            <br />
            <v-card class="card-detail" elevation="0">
              <v-card-text>
                <v-row class="overflow-x-auto">
                  <v-col>
                    <table aria-describedby="Life_Cycle" style="white-space: nowrap; border-collapse: collapse;">
                      <thead>
                        <tr>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <table aria-describedby="Life_Cycle" style="white-space: nowrap; border-collapse: collapse;">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <span class="f-text-22-b font-W500" color="black">
                                      Budget Holder :
                                    </span>
                                  </td>
                                  <td>
                                    <table aria-describedby="Life_Cycle"
                                      style="white-space: nowrap; border-collapse: collapse;">
                                      <thead>
                                        <tr>
                                          <th></th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <v-img v-if="HaveBudgetHolder" class="ml-2"
                                              :src="require('@/assets/icons/Green_check.png')" width="10"></v-img>
                                          </td>
                                          <td>
                                            <span class="f-text-22-b span-W500 ml-2" color="black">
                                              {{ approveArr.map(a => `${a.approveName} ${a.approvePosition}`).join(" / ") }}
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table aria-describedby="Life_Cycle" style="white-space: nowrap; border-collapse: collapse;"
                      v-for="(item, index) in lifeCycleItem" :key="index">
                      <thead>
                        <tr>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <ul>
                              <table aria-describedby="Life_Cycle"
                                style="white-space: nowrap; border-collapse: collapse;">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style="vertical-align: top;">
                                      <v-img v-if="item.HaveLineApprove" style="margin-top: 6px;"
                                        :src="require('@/assets/icons/Green_check.png')" width="10"></v-img>
                                    </td>
                                    <td v-if="item.LINE_APPROVE">
                                      <span class="span-W500 ml-2" color="black">{{ item.LINE_APPROVE }}</span><br>
                                      <table aria-describedby="Life_Cycle"
                                        style="white-space: nowrap; border-collapse: collapse;">
                                        <thead>
                                          <tr>
                                            <th></th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <v-img :src="require('@/assets/icons/Frame.svg')" class="ml-2" width="15">
                                              </v-img>
                                            </td>
                                            <td>
                                              <table aria-describedby="Life_Cycle"
                                                style="white-space: nowrap; border-collapse: collapse;">
                                                <thead>
                                                  <tr>
                                                    <th></th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <span class="span-W500 ml-2" color="black">{{
                                                        item.Traveler
                                                      }}</span>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
          <br />
        </template>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogStatusTrAlert.dialog" width="500">
      <v-card>
        <v-card-title> warning </v-card-title>
        <v-card-text>
          Would you like to '{{ dialogStatusTrAlert.message }}' Travel Request?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="primary" text @click="dialogStatusTrAlert.dialog = false">Cancel</v-btn>
          <v-btn small color="primary" @click="
            saveTravelRequest(dialogStatusTrAlert.message),
            (dialogStatusTrAlert.dialog = false)
            ">
            {{ dialogStatusTrAlert.message }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDataAlert.dialog" width="500">
      <v-card>
        <v-card-title> Warning </v-card-title>
        <v-card-text>
          {{ dialogDataAlert.message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="primary" text @click="dialogDataAlert.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogActionStatus" persistent width="400px">
      <v-card elevation="0" title color="primary" class="pt-1 ">
        <v-card>
          <v-card-text>
            <v-card title elevation="0">
              <v-card-title>
                <v-row>
                  <v-col class="text-center">
                    {{ titleActionStatus }}
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row no-gutters>
                  <v-col class="text-center">
                    <span>Travel Request : {{ TRNoText }}</span>
                  </v-col>
                </v-row>
                <v-row v-if="statusMytaskMsg !== 'Approve'">
                    <v-col class="text-center">
                        <v-textarea outlined dense background-color="white" auto-grow v-model="noteTR"></v-textarea>
                    </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col class="text-center">
                <v-btn outlined color="primary" class="mr-1" @click="closeDialogActionStatus()">Close</v-btn>
                <v-btn color="primary" class="ml-1" @click="confirmDialogActionStatus()">Confirm</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="TrCancelDialog" width="500" persistent>
      <v-card>
        <v-card-title>
          Cancel Travel Request
        </v-card-title>
        <v-card-text>
          <span v-if="!TrCancelLoading">
            would you like to cancel this Travel Request?
          </span>
          <span v-else>
            Please waiting
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small text @click="TrCancelDialog = false" :disabled="TrCancelLoading">Cancel</v-btn>
          <v-btn small color="error" :loading="TrCancelLoading" @click="TrCancel">Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div v-else>
    <div style="position: relative; height: 900px;" class="heightcenter"></div>
    <v-progress-circular style="position: absolute; top: 40%; right: 40%;" class="hidden-md-and-up" indeterminate
      color="info" :size="70" :width="7"></v-progress-circular>
    <v-progress-circular style="position: absolute; top: 45%; right: 50%;" class="hidden-md-and-down" indeterminate
      color="info" :size="70" :width="7"></v-progress-circular>
  </div>
</template>

<script>
import Traveler from "@/components/travelRequest/Traveler";
import dialogAddTraveler from "@/components/travelRequest/dialog-add-traveler";
import axios from "axios";
import moment from "moment";
import Swal from 'sweetalert2'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as _ from 'lodash'
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  name: "TravelRequest",
  data() {
    return {
      dialogDataAlert: {
        dialog: false,
        message: "",
      },
      approveArr: [],
      ActionStatus: {
          ApproveFinal : "Approve Final",
          PendingLineApprover : "Pending Line Approver",
          PendingBudgetHolder : "Pending Budget Holder"
        },
      oformatDate: "YYYY-MM-DD",
      NoServiceRequireReason: '',
      dueDate: '',
      noteTR: "",
      TravelerArrID: [],
      selectedNoRequireServices: false,
      isSaved: false,
      tariff: [],
      selectedTraveler: false,
      Getfiles: [],
      file: [],
      files: [],
      filesize: 0,
      sumAdvanceAmount: 0,
      EMPLOYEE: "",
      TR_EMPLOYEE_ID: "",
      ProjectId: "",
      BTTStatus: "",
      TrCancelDialog: false,
      TrCancelLoading: false,
      CoperateLoading: true,
      ItemPending: [],
      BudgetHolderApprover: "",
      LineApprovalD: "",
      TRNoText: "",
      statusMytask: "",
      Status: "",
      TravelerOID: "",
      titleActionStatus: "",
      dialogActionStatus: false,
      reloadPage: false,
      reloadingPage: 0,
      reloading: 0,
      TravelType: [
        'Domestic',
        'Overseas'
      ],
      formatDatetoString: "",
      disableDate: true,
      dialogStatusTrAlert: {
        dialog: false,
        message: "",
      },
      statusMytaskMsg: "",
      BTTPhone: "",
      EditCoperate: false,
      SaveCoperate: null,
      budgetApprove: "",
      approveName: "",
      approvePosition: "",
      approveTel: "",
      approveEmail: "",
      travelRequestStatus: "",
      lineApproval: [],
      projectData: [],
      nowDate: new Date().toISOString().substr(0, 10),
      startDate: "",
      menuStartDate: false,
      endDate: "",
      menuEndDate: false,
      DesofTravel: null,
      TRNo: "",
      PurId: "",
      BusinessTravelType: null,
      PurposeOfTravel: [],
      cityCode: "",
      cityTravel: [],
      countryCode: "",
      countryTravel: [],
      Email: "",
      CompanyName: "",
      ProjectName: "",
      BTTSupport: "",
      BTTName: "",
      budgetProject: [],
      BudgetHolder: "",
      disableCoperate: false,
      rule: {
        BusinessTravelType: [val => (val || '').length > 0 || 'This Business Travel Type is required'],
        BudgetHolder: [val => (val || '').length > 0 || 'This Budget Holder is required'],
        Project: [val => (val || '').length > 0 || 'This Project is required'],
        Company: [val => (val || '').length > 0 || 'This Company is required'],
        BusinessStartDate: [val => (val || '').length > 0 || 'This Business Start Date is required'],
        BusinessEndDate: [val => (val || '').length > 0 || 'This Business End Date is required'],
        Country: [val => (val || '').length > 0 || 'This Country is required'],
        CityorProvince: [val => (val || '').length > 0 || 'This City or Province is required'],
        PurposeofTravel: [val => (val || '').length > 0 || 'This Purpose of Travel is required'],
        NoServiceRequireReason: [val => (val || '').length > 0 || 'This NoServiceRequireReason of Travel is required'],
      },
      headers: [
        { text: "Actiondate", value: "actdate" },
        { text: "Time Stamp", value: "timestamp" },
        { text: "Name", value: "supname" },
        { text: "Action Result", value: "actresult" },
        { text: "Comment", value: "comment" },
      ],
      acthistory: [{
        actdate: "01/05/2022",
        timestamp: "09:00",
        supname: "Davis Curtis (Assistant Officer, Business Travel Services)",
        actresult: "Drafted",
      },
      ],
      isOwner: false,
      travelers: [],
      travelerData: [],
      save: false,
      typeTraveler: "",
      seleteTravelCard: [],
      showAddTraveler: false,
      GroupFilter: [],
      DivisionFilter: [],
      DepartmentFilter: [],
      SectionFilter: [],
      accessToken: "",
      GetBudgetProjectPath: "BudgetProject/GetBudgetProject",
    };
  },
  watch: {
    selectedTraveler() {
      if (this.selectedTraveler) {
        this.reloadPage = false
        this.getTraveler();
        this.selectedTraveler = false
        this.$store.state.LOADING = false;
      }
    },
    startDate() {
      if (this.startDate > this.endDate) {
        this.endDate = this.startDate
      }
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  components: {
    Traveler,
    dialogAddTraveler,
  },
  methods: {
    async printPage() {
      const ProfileCoperate = JSON.parse(sessionStorage.coperateData);
      const PROFILE = JSON.parse(localStorage.PROFILE);
      const fullNameCountry = this.countryTravel.filter(x => x.Code === this.countryCode);
      const getActionDate = this.TravelRequestLog && this.TravelRequestLog.length > 0 ? this.TravelRequestLog.filter(x => x.ActionResult === 'Submitted') : [];
      const travelcaseDate = moment(this.startDate).add(-3, 'days').format(this.oformatDate);
      const getTravelCase = moment().format(this.oformatDate) < travelcaseDate ? "NORMAL" : "UGENT";
      const personOnMission = [['No.', 'Name', 'Dept/Div', 'Position', 'Business Date', 'Travelling Date','Advance Req','Due Date', 'Status']]
      for(const [index,element] of this.travelers.entries()) {
        await new Promise(async(resolve) => {
          let loopTravelExpens = ""
           if(element.travelExpens.length > 0) {
            for(const element1 of element.travelExpens) {
              await new Promise(resolve2 => {
                loopTravelExpens += loopTravelExpens === "" ? `${element1.DueDate}` : null
                resolve()
                resolve2()
              })
            }
          }
          personOnMission.push([
            index+1,
            element.fullname,
            element.dep === null || element.dep.length === 0 || element.dep === [] || element.dep === "" ? "-" : `${element.dep}`,
            element.position === null || element.position.length === 0 || element.position === [] || element.position === "" ? "-" : `${element.position}`,
            `${ProfileCoperate.startDate} - ${ProfileCoperate.endDate}`,
            `${ProfileCoperate.startDate} - ${ProfileCoperate.endDate}`,
            element.travelExpens.length > 0 ? "Yes" : "No",
            element.travelExpens.length > 0 ? element.travelExpens[0].DueDate : "-",
            ProfileCoperate.travelRequestStatus
          ])
          resolve()
        })
      }

      const actionHistory = [['Action Date', 'Time Stamp', 'Name', 'Action Result', 'Comment']]
      if(this.TravelRequestLog && this.TravelRequestLog.length > 0){
          for(const element of this.TravelRequestLog) {
          await new Promise(async(resolve) => {
            actionHistory.push([
              element.ActionDate,
              element.TimeStamp,
              element.ActionName,
              element.ActionResult,
              element.Comment,
            ])
            resolve()
          })
        }
      } else {
        actionHistory.push([ "-", "-", "-", "-", "-" ])
      }
      var docDefinition = {
        content: [
          {
            alignment: 'justify',
            columns: [ { text: 'Travel Request View', alignment: 'center', style: 'header' } ],
          },'\n',{
            alignment: 'justify',
            columns: [ { text: `Company : ${this.CompanyName}`, style: 'subheader' } ]
          },{
            alignment: 'justify',
            columns: [
              { text: `Status : ${this.travelRequestStatus}`, style: 'subheader' },
              { text: '', style: 'subheader' },
              { text: `TR Number : ${this.TRNo}`, style: 'subheader' }
            ]
          },{
            alignment: 'justify',
            columns: [ { text: '', style: 'subheader' } ]
          },{
            alignment: 'justify',
            columns: [
              { text: 'ERT No : wait confirm', style: 'subheader', color: 'red' },
              { text: '', style: 'subheader' },
              { text: `Travel Case : ${getTravelCase}`, style: 'subheader' },
            ],
          },{
            alignment: 'justify',
            columns: [
              { text: '	RFS No : wait confirm', style: 'subheader', color: 'red' },
              { text: '', style: 'subheader' },
              { text: `Budget Holder: ${this.BudgetHolder}`, style: 'subheader' },
            ],
          },'\n',{
            alignment: 'justify',
            columns: [ { text: 'MISSION APPROVAL : ', style: 'subheader' } ]
          },{
            alignment: 'justify',
            columns: [
              { text: `Project : ${this.ProjectName}`, style: 'subheader' },
              { text: '', style: 'subheader' },
              { text: `Business Travel Type : ${this.BusinessTravelType}`, style: 'subheader' },
            ]
          },{
            alignment: 'justify',
            columns: [
              { text: `Provice/City : ${this.cityCode}`, style: 'subheader' },
              { text: '', style: 'subheader' },
              { text: '', style: 'subheader', color: 'red' },
            ]
          },{
            alignment: 'justify',
            columns: [
              { text: `Country : ${fullNameCountry.length > 0 ? fullNameCountry[0].text : null}`, style: 'subheader' },
              { text: '', style: 'subheader' },
              { text: '', style: 'subheader' },
            ]
          },{
            alignment: 'justify',
            columns: [ { text: `Purpose of Travel : ${this.PurId}`, style: 'subheader' } ]
          },{
            alignment: 'justify',
            columns: [
              { text: `Total Advance Amount: ${this.totalAdvance.toLocaleString('th',
              { minimumFractionDigits: 2, maximumFractionDigits: 2 })} THB`, style: 'subheader' },
              { text: '', style: 'subheader' },
            ]
          },{
            alignment: 'justify',
            columns: [
              { text: `No Service Required : ${this.selectedNoRequireServices === false ? "No" : "Yes"}`, style: 'subheader' },
              { text: '', style: 'subheader' },
              { text: '', style: 'subheader' },
            ]
          },{
            alignment: 'justify',
            columns: [ { text: `Total Number of Persons : ${this.travelers.filter(x =>
              x.TravelCard.IsDelete === 0).length}`, style: 'subheader' } ]
          },'\n',{
            alignment: 'justify',
            columns: [ { text: 'DETAILS OF PERSONS ON MISSION : ', style: 'subheader' } ]
          },{ style: 'tableExample', alignment: 'center',
            table: { headerRows: 1, widths: [30, 50, 50, 50, 50, 50, 50, 51, 52], body: personOnMission },
          },'\n',{
            alignment: 'justify',
            columns: [ { text: 'ACTION HISTORY : ', style: 'subheader' } ]
          },
          {
            // define the flight table
            style: 'tableExample',
            alignment: 'center',
            table: { widths: ['auto', '*', '*', '*', '*'], body: actionHistory }
          },'\n',{
            alignment: 'justify',
            columns: [ { text: `Prepared By : ${PROFILE.FIRSTNAME} ${PROFILE.LASTNAME}`, style: 'subheader' } ]
          },{
            alignment: 'justify',
            columns: [ { text: `Mobile : ${PROFILE.MOBILE_NO}`, style: 'subheader' } ]
          },{
            alignment: 'justify',
            columns: [ { text: `Office : ${PROFILE.DIRECT_PHONE}`, style: 'subheader' } ]
          },{
            alignment: 'justify',
            columns: [ { text: `Submitted Date : ${getActionDate && getActionDate.length > 0 ?
              getActionDate[0].ActionDate : "-"}`, style: 'subheader' } ]
          },
        ],
        styles: {
          header: { fontSize: 18, bold: true },
          subheader: { fontSize: 10, bold: true },
          quote: { italics: true },
          small: { fontSize: 8 },
          tableExample: { bold: true, fontSize: 8, color: 'black' },
        }
      };
      pdfMake.createPdf(docDefinition).open();
    },
    getTRLifeCycleByPermission() {
      this.$store.commit("travelRequest/getTRLifeCycleByPermission", {
        TRNo: this.TRNo
      })
    },
    getTravelRequestLogByTRNo(){
      this.$store.commit("travelRequest/getTravelRequestLogByTRNo", {
        TRNo: this.TRNo
      })
    },
    InputFile() {
      this.$refs.input_file.click()
    },
    openFile(val) {
      window.open(val.FilePath)
    },
    GetFile(val, TRNo) {
      axios.post(`${this.$store.state.API_PATH}FileUpload/GetFile?ID=${TRNo}|${val}`).then(res => {
        this.Getfiles = res.data.Result
      })
    },
    removeFiles(val, key) {
      if (key === 'NotSave') {
        this.files = this.files.filter(x => x.name !== val.name)
      }
      else if (key === 'Saved') {
        const formData = new FormData();
        for (let i = 0; i < this.files.length; i++) {
          formData.append('TravelRequest', this.files[0])
        }
        const files = formData
        axios.post(`${this.$store.state.API_PATH}FileUpload/RemoveFile?FileOID=` +
          `${this.TRNo}|${val.FilePath.split('?', '')[0]}|${val.FileOID}|TravelRequest|${val.FileName}`, files,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(resRemoveFile => {
            if (resRemoveFile.data) {
              this.Getfiles = this.Getfiles.filter(item => item.FileOID !== val.FileOID)
            }
          })
      }
    },
    calSize(bytes) {
      const k = 1024
      const sum = Math.floor(Math.log(bytes) / Math.log(k))
      this.filesize += parseFloat((bytes / Math.pow(k, sum)).toFixed(2))
    },
    AddAttachFile(event) {
      this.files.push(...event.target.files)
    },
    backtopage() {
      location.href = '/travelRequest'
    },
    RequiredCoperate() {
      let checkvalid = false
      if (!this.$refs.BudgetHolder.validate()) {
        this.$refs.BudgetHolder.focus()
        this.$refs.BudgetHolder.validate(true)
        checkvalid = true
      }
      if (!this.$refs.Project.validate()) {
        this.$refs.Project.focus()
        this.$refs.Project.validate(true)
        checkvalid = true
      }
      if (!this.$refs.BusinessTravelType.validate()) {
        this.$refs.BusinessTravelType.focus()
        this.$refs.BusinessTravelType.validate(true)
        checkvalid = true
      }
      if (!this.$refs.Company.validate()) {
        this.$refs.Company.focus()
        this.$refs.Company.validate(true)
        checkvalid = true
      }
      if (!this.$refs.BusinessStartDate.validate()) {
        this.$refs.BusinessStartDate.focus()
        this.$refs.BusinessStartDate.validate(true)
        checkvalid = true
      }
      if (!this.$refs.BusinessEndDate.validate()) {
        this.$refs.BusinessEndDate.focus()
        this.$refs.BusinessEndDate.validate(true)
        checkvalid = true
      }
      if (!this.$refs.Country.validate()) {
        this.$refs.Country.focus()
        this.$refs.Country.validate(true)
        checkvalid = true
      }
      if (!this.$refs.CityorProvince.validate()) {
        this.$refs.CityorProvince.focus()
        this.$refs.CityorProvince.validate(true)
        checkvalid = true
      }
      if (!this.$refs.PurposeofTravel.validate()) {
        this.$refs.PurposeofTravel.focus()
        this.$refs.PurposeofTravel.validate(true)
        checkvalid = true
      }
      if(this.selectedNoRequireServices){
        if (!this.$refs.NoServiceRequireReason.validate()) {
          this.$refs.NoServiceRequireReason.focus()
          this.$refs.NoServiceRequireReason.validate(true)
          checkvalid = true
      }
      }
      return checkvalid
    },
    TrCancel() {
      this.TrCancelLoading = true
      let req = []
      if(this.travelers && this.travelers.length > 0){
        req = this.travelers
      }else{
        const formReqID = JSON.parse(sessionStorage.formReqID);
        req = [{
          TravelRequestOID: formReqID.TravelId
        }]
      }
      axios.post(`${this.$store.state.API_PATH}TravelRequest/RemoveTravelRequest`, req, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(res => {
        this.TrCancelDialog = false
        this.TrCancelLoading = false
        if (res.data.Status) {
          Swal.fire({
            icon: 'success',
            text: 'Success',
            confirmButtonColor: '#00AEEF'
          }).then(() => { window.location.href = "/travelRequest" }, () => { })
        }
      })
    },
    DialogTrCancel() {
      this.TrCancelDialog = true
      this.travelers.forEach(elm => {
        if (elm.IsDelete === 0) {
          elm.ListBookingOID = elm.flights != null && elm.flights.length > 0 ? elm.flights.map(item => item.bookingOID && item.depFlight[0].airline.code !== 'DD') : null
        }
      })
    },
    closeDialogActionStatus() {
      this.dialogActionStatus = false
      this.TravelerOID = ""
      this.titleActionStatus = ""
      this.TRNoText = ""
      this.statusMytask = ""
      this.statusMytaskMsg = ""
      this.BudgetHolderApprover = ""
      this.LineApprovalD = ""
      this.noteTR = ""
    },
    async confirmDialogActionStatus() {
      let StatusAction = ""
      const Status = [this.ActionStatus.ApproveFinal, this.ActionStatus.PendingLineApprover, this.ActionStatus.PendingBudgetHolder, "Approved"]
      if (this.statusMytask !== Status[0]) {
        StatusAction = this.statusMytask
        this.LineApprovalD = ""
        this.BudgetHolderApprover = ""
      }
      else {
        if (this.BudgetHolderApprover === this.LineApprovalD || (this.BudgetHolderApprover && this.LineApprovalD)) {
          StatusAction = Status[0]
        }
        else if (this.BudgetHolderApprover && !this.LineApprovalD) {
          StatusAction = Status[1]
        }
        else {
          StatusAction = Status[2]
        }
      }
      const PROFILE = JSON.parse(localStorage.PROFILE)
      setTimeout(() => {
        this.$store.state.LOADING = true
        this.dialogActionStatus = false
        axios.post(`${this.$store.state.API_PATH}TravelRequest/UpdateStatusMytask`, {
          TRNo: this.TRNoText,
          BudgetHolderApprover: this.BudgetHolderApprover,
          TravelerArrID: this.TravelerArrID,
          LineApprovalD: this.LineApprovalD,
          Status: StatusAction,
          TravelRequestOID: this.TravelRequestOID,
          Note: this.noteTR,
          IsApproved: this.BudgetHolderTR.indexOf(PROFILE.EMPLOYEE_ID) !== -1 ? "Budget Holder" : "",
          ActionName: `${PROFILE.FIRSTNAME} ${PROFILE.LASTNAME} ( ${PROFILE.POSITION} )`,
          EMPLOYEE_ID: PROFILE.EMPLOYEE_ID
        }, {
          headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        }).then(res => {
          this.$store.state.LOADING = false
          if (res.data.Status) {
            const formReqID = JSON.parse(sessionStorage.formReqID);
            formReqID.Pending = 0
            sessionStorage.formReqID = formReqID
            Swal.fire({
              icon: 'success',
              text: `${this.statusMytaskMsg} Success`,
              confirmButtonColor: '#00AEEF'
            }).then(() => {
              window.location.href = "/"
            }, () => { })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: res.data.ErrorMessage,
              confirmButtonColor: '#00AEEF'
            }).then(() => { window.location.href = "/" }, () => { })
          }
        }).catch(() => {
          this.$store.state.LOADING = false
          window.location.href = "/"
        })
      }, 100)
    },
    getMyTaskByPendingPerTraveller(haveEmpId) {
      const formReqID = JSON.parse(sessionStorage.formReqID)
      this.LoadingMyTasks = true
      axios.post(this.$store.state.API_PATH + "TravelRequest/GetMyTaskByPendingPerTraveller", {
        TravelRequestOID: formReqID.TravelId,
        ApproverId: this.EMPLOYEE.EMPLOYEE_ID,
        EMPLOYEE_ID: haveEmpId
      }, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(res => {
        this.LoadingMyTasks = false
        if (res.data.Status) {
          this.$store.state.ItemPending.push(res.data.Result[0])
          this.ItemPending.push(res.data.Result[0])
          const item = res.data.Result[0].TravelerArrID[0]
          this.TravelerArrID.push({ Traveller: haveEmpId, BudgetHolder: item.BudgetHolder, LineApprover : item.LineApprover, Status: "" })
        }
      }).catch(err => {
        this.LoadingMyTasks = false
        this.$store.state.LOADING = false
        console.log(err)
      })
    },
    async actionStatusMytask(type, comeFromEmail) {
      if (this.ItemPending.length > 0) {
        const PROFILE = JSON.parse(localStorage.PROFILE)
        for(const item of this.ItemPending){
          await new Promise((resolve)=>{
            const gTraveller = this.TravelerArrID.filter(x=>x.Traveller === item.EMPLOYEE_ID)[0]
            if (item.TravelRequestApprover.indexOf(PROFILE.EMPLOYEE_ID) !== -1) {
              gTraveller.BudgetHolder = PROFILE.EMPLOYEE_ID
            }
            else if (item.BudgetHolderApprover === item.TravelRequestApprover) {
              gTraveller.BudgetHolder = item.BudgetHolderApprover
            }
            else {
              gTraveller.BudgetHolder = ""
            }
            if(!gTraveller.LineApprover){
              if (item.LINE_APPROVE.split(",").filter(x=>x === PROFILE.EMPLOYEE_ID).length > 0) {
                gTraveller.LineApprover = PROFILE.EMPLOYEE_ID
              }
              else if (item.LineApprovalD === item.LINE_APPROVE) {
                gTraveller.LineApprover = item.LineApprovalD
              }
              else {
                gTraveller.LineApprover = ""
              }
            }
            if(type === "Approve"){
              if(gTraveller.BudgetHolder && gTraveller.LineApprover){
                gTraveller.Status = this.ActionStatus.ApproveFinal
              }
              else if(gTraveller.BudgetHolder){
                gTraveller.Status = this.ActionStatus.PendingLineApprover
              }
              else if(gTraveller.LineApprover){
                gTraveller.Status = this.ActionStatus.PendingBudgetHolder
              }
            }
            else{
              gTraveller.Status = type
            }
            setTimeout(()=>{
              resolve()
            }, 1)
          })
        }
        this.statusMytask = type
        this.statusMytaskMsg = type
        if (type === "Approve") {
          this.statusMytask = this.ActionStatus.ApproveFinal;
          this.titleActionStatus = "Approve Confirmation !"
        }
        else if (type === "Return") {
          this.titleActionStatus = "Return Confirmation !"
        }
        else {
          this.titleActionStatus = "Reject Confirmation !"
        }
        this.TravelerOID = this.ItemPending[0].TravelerOID
        this.TRNoText = this.ItemPending[0].TRNo
        this.TravelRequestOID = this.ItemPending[0].TravelRequestOID
        this.dialogActionStatus = !comeFromEmail ? true : false
        if (comeFromEmail) {
          this.confirmDialogActionStatus()
        }
      }
    },
    leftScroll() {
      document.querySelector(".scroll-travelers").scrollLeft -= 250;
    },
    rightScroll() {
      document.querySelector(".scroll-travelers").scrollLeft += 250;
    },
    backtotravelreq() {
      location.href = "TravelRequest";
    },
    dialogAlert(mes) {
      this.dialogStatusTrAlert.dialog = true
      if(mes === 'Draft'){
        this.dialogStatusTrAlert.message = 'Save'
      } else {
        this.dialogStatusTrAlert.message = mes
      }
    },
    formatDatepicker(date) {
      return date !== "" && date !== '0001-01-01' ? moment(date).format("DD-MMM-YYYY") : date
    },
    getVisaTraveler(val) {
      axios.post(`${this.$store.state.API_PATH}Passport/GetVisa?val=${val}`, {}, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      })
    },
    getPurposeOfTravel() {
      axios.post(`${this.$store.state.API_PATH}ProposeOfTravel/GetProposeOfTravel`, {}, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      })
        .then((res) => {
          if (res.data.Status) {
            this.PurposeOfTravel = res.data.Result;
            this.$store.state.LOADING = false;
          }
        }).catch(() => {
          this.$store.state.LOADING = false;
        });
    },
    getCountryCodeCreteTravel(input) {
      if (input) {
        const checkItem = [];
        if (checkItem.length === 0) {
          axios.post(`${this.$store.state.API_PATH}CountryCode/GetCountryCode`, {
            Code: input,
            Language: this.$store.state.Language,
          }, {
            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
          }).then((res) => {
            if (res.data.Status) {
              this.countryTravel = [];
              res.data.Result.forEach((elm) => {
                this.countryTravel.push({ ...elm, text: `${elm.Name} (${elm.Code})` });
              });
            }
          })
            .catch((err) => err);
        }
      }
    },
    getCityCodeCreateTravel(status) {
      if (status) {
        this.$store.state.LOADING = true
      }
      axios.post(`${this.$store.state.API_PATH}CityCode/${this.countryCode === 'TH' ? 'GetProvince' : 'GetCityCodeByCountryCode'}`, {
        CountryCode: this.countryCode,
        Language: this.$store.state.Language,
        Country: this.countryCode
      }, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then((res) => {
        if (res.data.Status) {
          this.cityTravel = [];
          res.data.Result.forEach((elm) => {
            if (this.countryCode === 'TH') {
              this.cityTravel.push({ ...elm, text: `${elm.name_en} (${elm.code})` });
            } else {
              this.cityTravel.push({ ...elm, text: `${elm.Name} (${elm.Code})` });
            }
          });
          this.$store.state.LOADING = false;
        }
      }).catch(() => {
        this.$store.state.LOADING = false;
      });
    },
    getBudgetProject(input) {
      if (input) {
        this.$store.state.LOADING = true;
        const checkItem = this.budgetProject.filter((el) => el.BudgetHolder.toLowerCase().substr(0, input.length) === input.toLowerCase())
        if (checkItem.length === 0) {
          axios.post(`${this.$store.state.API_PATH}${this.GetBudgetProjectPath}`, {
            BudgetHolder: this.BudgetHolder,
          }, {
            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
          }).then((res) => {
            if (res.data.Status) {
              this.budgetProject = res.data.Result
              this.$store.state.LOADING = false
            }
          })
            .catch((err) => err);
        } else {
          this.$store.state.LOADING = false;
        }
      }
    },
    getBusinessTravel() {
      this.ProjectName = ""
      this.clearInput()
      this.$store.state.LOADING = true
      const data = this.budgetProject.filter((e) => e.BudgetHolder === this.BudgetHolder)
      if (data.length > 0) {
        setTimeout(() => {
          this.projectData = data;
          this.$store.state.LOADING = false;
        }, 200);
      } else {
        this.$store.state.LOADING = false;
      }
    },
    async getCompanyByProject() {
      this.clearInput();
      this.disableDate = false
      this.$store.state.LOADING = true;
      const data = this.budgetProject.filter(e => e.BudgetHolder === this.BudgetHolder && e.ProjectName === this.ProjectName)
      if (data.length > 0) {
        if (data[0].BTTId) {
          this.CompanyName = data[0].CompanyName;
          this.BTTName = data[0].BTTName;
          this.startDate = moment().add(1, "days").format(this.formatDatetoString);
          this.endDate = moment(this.startDate).add(7, "days").format(this.formatDatetoString);
          await this.getSubCompany(data[0].BTTId, data[0].OrgID)
        }
        else {
          this.CompanyName = data[0].CompanyName;
          this.startDate = moment().add(1, "days").format(this.formatDatetoString);
          this.endDate = moment(this.startDate).add(7, "days").format(this.formatDatetoString);
          await this.getHeadBudgetHolder(data[0].OrgID)
        }
      } else {
        this.$store.state.LOADING = false;
      }
    },
    async getSubCompany(BTTId, OrgID) {
      await this.getEmployeesByID(BTTId).then(async (res) => {
        if (res.data.Result.data.employees_MastersData != null) {
          const item = res.data.Result.data.employees_MastersData;
          this.BTTSupport = item.EMPLOYEE_ID
          this.BTTPhone = item.MOBILE_NO
          this.Email = item.EMAIL_ID
          await this.getHeadBudgetHolder(OrgID)
        } else {
          this.$store.state.LOADING = false;
        }
      }, () => {
        this.$store.state.LOADING = false;
      });
    },
    async getHeadBudgetHolder(OrgID){
      await this.getOrganizationsB2B(OrgID).then(async (res3) => {
        if (res3.data.Result.Success && res3.data.Result.Data.OrganizationsData) {
          const item2 = res3.data.Result.Data.OrganizationsData;
          const headArr = []
          for(const h of item2.HeadActingsDATA){
            await new Promise(resove=>{
              if(h.ACTING_STATUS){
                if(headArr.filter(x=>x === h.ACTING_HEAD_ID).length === 0){
                  headArr.push(h.ACTING_HEAD_ID)
                  resove()
                }
                else{
                  resove()
                }
              }
              else{
                if(headArr.filter(x=>x === h.HEAD_ID).length === 0){
                  headArr.push(h.HEAD_ID)
                  resove()
                }
                else{
                  resove()
                }
              }
            })
          }
          this.approveId = headArr.toString()
          this.BudgetHolderTR = headArr.toString()
          await this.getEmployeesByIDFromSubCompany(headArr.toString())
        }
        else {
          this.$store.state.LOADING = false;
        }
      }, () => {
        this.$store.state.LOADING = false;
      });
    },
    async getEmployeesByIDFromSubCompany(EMPLOYEE_ID) {
      if (EMPLOYEE_ID) {
        const arr = EMPLOYEE_ID.split(",")
        this.approveArr = []
        for(const a of arr){
          await new Promise(async(resolve)=>{
            await this.getEmployeesByID(a).then((res3) => {
              this.$store.state.LOADING = false;
              if (res3.data.Result.data.employees_MastersData != null) {
                const item3 = res3.data.Result.data.employees_MastersData;
                this.approveArr.push({
                  approveName: `${item3.FIRSTNAME} ${item3.LASTNAME}`,
                  approveTel: item3.MOBILE_NO,
                  approveEmail: item3.EMAIL_ID,
                  approvePosition: `(${item3.POSITION})`
                })
                resolve()
              }
              else{
                resolve()
              }
            }, () => {
              this.$store.state.LOADING = false;
            });
          })
        }
      }
    },
    getOrganizationsB2B(ID) {
      return axios.get(`${this.$store.state.API_PATH}Hrws/GetOrganizationsB2B?OrgID=${ID}`, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      })
    },
    getOrganizationsAbbreviation(ID) {
      return axios.get(`${this.$store.state.API_PATH}Hrws/getOrganizationsAbbreviation?val=${ID}`, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      })
    },
    getEmployeesByID(ID) {
      return axios.post(`${this.$store.state.API_PATH}Hrws/getEmployeesByID?val=${ID}`, {}, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      })
    },
    getData() {
      this.$store.commit("getToken");
    },
    AddTravelerRequest(type) {
      this.reloading += 1;
      this.typeTraveler = type;
      this.seleteTravelCard = this.travelers.filter(x => x.TravelCard != null ? x.TravelCard.IsDelete === 0 : x)
      setTimeout(() => {
        this.showAddTraveler = true
      }, 1);

    },
    formatDate(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    saved(mes) {
      if (!this.RequiredCoperate()) {
        this.save = true
        this.SaveCoperate = 1
        this.disableCoperate = true
        this.isSaved = true
        this.saveTravelRequest(mes);
      } else {
        location.href = "#coperate";
      }
    },

    saveCoperateData() {
      const empData = JSON.parse(localStorage.PROFILE)
      const setcoperateData = {
        startDate: this.startDate,
        endDate: this.endDate,
        TRNo: this.TRNo,
        PurId: this.PurId,
        cityCode: this.cityCode,
        countryCode: this.countryCode,
        CompanyName: this.CompanyName,
        ProjectName: this.ProjectName,
        BTTName: this.BTTName,
        BusinessTravelType: this.BusinessTravelType,
        BudgetHolder: this.BudgetHolder,
        BTTPhone: this.BTTPhone,
        Email: this.Email,
        approveName: this.approveName,
        approvePosition: this.approvePosition,
        approveTel: this.approveTel,
        approveEmail: this.approveEmail,
        desofTravel: this.DesofTravel,
        EMPLOYEE_ID: empData.EMPLOYEE_ID,
        NoServiceRequire: this.selectedNoRequireServices ? 1 : 0,
        NoServiceRequireReason: this.NoServiceRequireReason
      };
      sessionStorage.coperateData = JSON.stringify(setcoperateData);
    },
    async saveTravelRequest(mes) {
      let isActive = true
      if(mes === 'Save' || mes === 'Draft'){
        mes = 'Draft'
      }else if ((this.travelRequestStatus === 'Submitted' ||
        this.travelRequestStatus === "Return" ||
        this.travelRequestStatus === this.ActionStatus.PendingBudgetHolder ||
        this.travelRequestStatus === this.ActionStatus.PendingLineApprover) && this.isOwner) {
          mes = "Revised"
      }else if (this.travelRequestStatus === this.ActionStatus.ApproveFinal){
        mes = 'Re-Issue'
      }
      if((mes !== 'Draft') && (this.travelers.length === 0 || this.travelers.filter(
        x => x.flights.length > 0 || x.hotels.length > 0 ||
         (x.passport && x.passport.length > 0) ||
          (x.visa && x.visa.length > 0)).length !== this.travelers.length)){
        isActive = false
      }
        if(isActive){
          const formData = new FormData()
          for (let i = 0; i < this.files.length; i++) {
            formData.append('TravelRequest', this.files[i])
          }
          const empData = JSON.parse(localStorage.PROFILE);
          this.saveCoperateData();
          const coperateData = JSON.parse(sessionStorage.coperateData)
          let formReqID = sessionStorage.formReqID ? JSON.parse(sessionStorage.formReqID) : null;
          const req = {
            NoServiceRequireReason: this.NoServiceRequireReason,
            NoServiceRequire: this.selectedNoRequireServices ? 1 : 0,
            disChkNoServiceRequire: this.isSaved ? 1 : 0,
            TravelRequestOID: formReqID && formReqID.TravelId != null && formReqID.TravelId !== undefined ? formReqID.TravelId : null,
            EMPLOYEE_ID: empData.EMPLOYEE_ID,
            BudgetHolder: coperateData.BudgetHolder,
            Project: coperateData.ProjectName,
            Company: coperateData.CompanyName,
            BusinessStartDate: moment(coperateData.startDate).format(this.formatDatetoString),
            BusinessEndDate: moment(coperateData.endDate).format(this.formatDatetoString),
            Country: coperateData.countryCode,
            BusinessTravelType: coperateData.BusinessTravelType,
            CityorProvince: coperateData.cityCode,
            PurposeofTravel: coperateData.PurId,
            DesofTravel: this.DesofTravel,
            BTTSupport: this.BTTSupport,
            BudgetHolderApprover: this.BudgetHolderTR,
            SaveCoperate: 1,
            Status: mes,
            TRNo: coperateData.TRNo ?? "",
            ActionName: empData.ActionByBTT ? empData.ActionByBTT : `${empData.FIRSTNAME} ${empData.LASTNAME} (${empData.POSITION})`
          };
          if (req.TravelRequestOID != null) {
            await this.UpdateTravelRequest(req, mes)
          } else {
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}TravelRequest/CreateTravelRequest`, req, {
              headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
              this.$store.state.LOADING = false
              if(res.data.Status){
                formReqID = {
                  TravelId: res.data.Result.TravelId,
                  EMPLOYEE_ID: null,
                  TRNo: null
                }
                this.TRNo = res.data.Result.DraftNo
                sessionStorage.formReqID = JSON.stringify(formReqID)
                const reqTraveler = [];
                const elm = {
                  EMPLOYEE_ID: empData.EMPLOYEE_ID,
                  BookingOID: null,
                  BudgetHolderApprover: null,
                  LineApprovalD: null,
                  BTTId: null,
                  Cdate: moment().format(),
                  Mdate: moment().format(),
                  TravelCardOID: null,
                  TravelRequestOID: res.data,
                  TravelCard: null,
                  PreferredSeat: null,
                  SpecialMeal: null,
                  Passport: [],
                  Visa: [],
                  SpecialRequest: {
                    PreferredSeat: null,
                    SpecialMeal: null,
                  },
                }
                reqTraveler.push(elm);
                if (empData.POSITION.indexOf('Secretary') === -1) {
                  axios.post(`${this.$store.state.API_PATH}Traveler/CreateListTraveler`, reqTraveler, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                  }).then(() => {
                    setTimeout(() => {
                      location.href = "createTravelRq"
                    }, 100);
                  });
                } else {
                  setTimeout(() => {
                    this.reloadPage = true
                  }, 100);
                }
              }
              else{
                Swal.fire({
                  icon: 'error',
                  text: `${res.data.ErrorMessage} (TravelRequest/CreateTravelRequest)` ,
                  confirmButtonColor: '#00AEEF'
                }).then(()=>{
                },()=>{})
              }
            },()=>{
              this.$store.state.LOADING = false
            })
          }
          const files = formData
          setTimeout(() => {
            axios.post(`${this.$store.state.API_PATH}FileUpload/Upload?ID=` + this.TRNo, files,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
          }, 500);
        }else{
          this.dialogDataAlert.message = 'Some travelers did not Request Service, please check.'
          this.dialogDataAlert.dialog = true
        }
    },
    async UpdateTravelRequest(req, mes) {
      this.$store.state.LOADING = true
      await axios.post(`${this.$store.state.API_PATH}TravelRequest/UpdateTravelRequest`, req, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(async (res) => {
        this.$store.state.LOADING = false
        const reqTraveler = [];
        if (this.travelers != null && this.travelers.length > 0) {
          await this.travelers.forEach(async (elm) => {
            const modifyTravel = await this.funcModifyTravel(req, elm, mes)
            await reqTraveler.push(modifyTravel);
          });
          if (this.travelers && await this.travelers.length === await reqTraveler.length) {
            axios.post(`${this.$store.state.API_PATH}Traveler/CreateListTraveler`, reqTraveler, {
              headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
              .then(() => {
                this.reloadTravelRequest(mes)
              });
          }
        } else {
          this.reloadTravelRequest(mes)
        }
      },()=>{
        this.$store.state.LOADING = false
      }).catch((err) => {
        console.log(err)
      })
    },
    funcModifyTravel(req, elm, mes) {
      if ((this.travelRequestStatus === 'Submitted' ||
        this.travelRequestStatus === this.ActionStatus.PendingBudgetHolder ||
        this.travelRequestStatus === this.ActionStatus.PendingLineApprover ||
        this.travelRequestStatus === 'Revised') && this.isOwner) {
        mes = "Revised"
      }
      return {
        TravelerOID: elm.TravelerOID,
        EMPLOYEE_ID: elm.emp_ID,
        ListBookingOID: elm.flights != null ? elm.flights.map(x => x.bookingOID) : null,
        HotelOID: null,
        Status: mes,
        TravelRequestOID: req.TravelRequestOID,
        TRNo: null,
        TravelWith: elm.TravelWith,
        BudgetHolderApprover: elm.budgetHolderApprover,
        LineApprovalD: elm.lineApprovalD,
        BTTId: null,
        Cdate: "2022-09-11T16:27:41.822Z",
        Mdate: "2022-09-11T16:27:41.822Z",
        TravelCardOID: elm.TravelCardOID,
        TravelCard: elm.TravelCard,
        PreferredSeat: elm.DefaultSpecialRequest.PreferredSeat,
        SpecialMeal: elm.DefaultSpecialRequest.SpecialMeal,
        PassportOID: elm.passport != null ? elm.passport.PassportOID : null,
        VisaOID: elm.visa != null ? elm.visa.VisaOID : null,
        Passport: [],
        Visa: [],
        SpecialRequest: {
          PreferredSeat: null,
          SpecialMeal: null,
        },
      }
    },
    reloadTravelRequest(mes) {
      location.href = "createTravelRq";
    },
    clearInput() {
      this.BTTPhone = "";
      this.approveName = "";
      this.approvePosition = "";
      this.approveTel = "";
      this.approveEmail = "";
      this.startDate = "";
      this.endDate = "";
      this.Email = "";
      this.CompanyName = "";
      this.BTTName = "";
    },
    getOrganizations() {
      axios.post(`${this.$store.state.API_PATH}userprofile/getOrganization?val=`, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then((res) => {
        if(res.data.Status){
          const data = res.data.Result
          this.GroupFilter = data.Group
          this.DivisionFilter = data.Division
          this.DepartmentFilter = data.Department
          this.SectionFilter = data.Section
        }
      }, (err) => {
        console.log(err);
      })
    },
    getDataBudgetProject() {
      const PROFILE = JSON.parse(localStorage.PROFILE)
      let checkItem = [];
      checkItem = this.budgetProject.filter((el) => el.BudgetHolder.toLowerCase().substr(0, this.BudgetHolder.length) === this.BudgetHolder.toLowerCase());
      if (checkItem.length === 0) {
        this.budgetProject = []
        axios.get(`${this.$store.state.API_PATH}HRWS/GetBudgetholders?ABBREVIATION=${this.BudgetHolder ?
        this.BudgetHolder : PROFILE.ABBREVIATION}&EMPLOYEE_ID=${this.TR_EMPLOYEE_ID ?
        this.TR_EMPLOYEE_ID : PROFILE.EMPLOYEE_ID}`, {
          headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        }).then((res) => {
          if (res.data.Result) {
            const ResultParse = JSON.parse(res.data.Result)
            const ResultBugetholder = ResultParse.budgetholder
            if (ResultBugetholder != null && ResultBugetholder.length > 0) {
              this.GetBudgetProjectLine2(ResultBugetholder)
            }
          }
        })
          .catch((err) => {
            console.log(err, "click/epayment/budgetholders")
          })
      }
    },
    async GetBudgetProjectLine2(ResultBugetholder) {
      let strBudget = ""
      for (const e of ResultBugetholder) {
        await new Promise(resolve => {
          strBudget += !strBudget ? `'${e}'` : `, '${e}'`
          resolve()
        })
      }
      axios.post(`${this.$store.state.API_PATH}${this.GetBudgetProjectPath}`, { BudgetHolder: strBudget }, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then((res) => {
        if (res.data.Status) {
          this.budgetProject = res.data.Result
          const data = this.budgetProject.filter((e) => e.BudgetHolder === this.BudgetHolder)
          if (data.length > 0) {
            this.projectData = data
          }
        }
      }).catch((err) => {
        console.log(err, this.GetBudgetProjectPath)
      })
    },
    getCoperate(coperateData) {
      this.NoServiceRequireReason = coperateData.NoServiceRequireReason
      this.isOwner = this.EMPLOYEE.EMPLOYEE_ID === coperateData.EMPLOYEE_ID ? true : false
      this.startDate = coperateData.startDate;
      this.endDate = coperateData.endDate;
      this.TRNo = coperateData.TRNo;
      this.travelRequestStatus = coperateData.travelRequestStatus;
      this.PurId = coperateData.PurId;
      this.cityCode = coperateData.cityCode;
      this.countryCode = coperateData.countryCode;
      this.BusinessTravelType = coperateData.BusinessTravelType;
      this.BudgetHolder = coperateData.BudgetHolder;
      this.ProjectName = coperateData.ProjectName;
      this.CompanyName = coperateData.CompanyName;
      this.BTTName = coperateData.BTTName;
      this.DesofTravel = coperateData.DesofTravel;
      this.SaveCoperate = coperateData.SaveCoperate;
      this.disableCoperate = coperateData.SaveCoperate === 0 ? false : true;
      this.selectedNoRequireServices = coperateData.NoServiceRequire === 0 ? false : true;
      this.isSaved = coperateData.disChkNoServiceRequire === 0 ? false : true;
      this.getDataBudgetProject();
      this.getCountryCodeCreteTravel(this.countryCode);
      this.getCityCodeCreateTravel(false);
    },
    async getTariff() {
      await axios.post(`${this.$store.state.API_PATH}HRWS/TariffLevelCheck?val=${''}`, {}, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then((res) => {
        this.tariff = res.data.Result
      })
    },
    LineApprovalCheck(ID) {
      return axios.post(`${this.$store.state.API_PATH}HRWS/LineApprovalCheck?val=${ID}`, {}, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      });
    },
    async LineApprover(ORGUNIT, EMPLOYEE_ID) {
      let res = ''
      await this.LineApprovalCheck(EMPLOYEE_ID).then(async (res4) => {
        const data = res4.data.Result
        let result = {}
        if(data){
          result = {
            appreoval: data.Full_Name,
            emp_ID: EMPLOYEE_ID,
          }
        }else{
          result = {
            appreoval: '',
            emp_ID: EMPLOYEE_ID,
          }
        }
        this.lineApproval.push(result);
        await this.getEmployeesByID(EMPLOYEE_ID).then((res5) => {
          if (res5?.data?.Result?.data?.employees_MastersData?.TARIFFLEVEL ||
            res5?.data?.Result?.data?.employees_MastersData?.COMP === '1001' ||
            res5?.data?.Result?.data?.employees_MastersData?.COMP === '1013' ||
            res5?.data?.Result?.data?.employees_MastersData?.COMP === '1069' ||
            res5?.data?.Result?.data?.employees_MastersData?.COMP === '1086' ||
            res5?.data?.Result?.data?.employees_MastersData?.COMP === '1015') {
            if (res5.data.Result.data.employees_MastersData.TARIFFLEVEL) {
              res = {
                TARIFFLEVEL: res5.data.Result.data.employees_MastersData.TARIFFLEVEL,
                COMP: res5.data.Result.data.employees_MastersData.COMP,
              }
            } else {
              res = {
                TARIFFLEVEL: 'Professional',
                COMP: res5.data.Result.data.employees_MastersData.COMP,
              }
            }
          }
          else {
            res = this.FindTariffByCode(res5, EMPLOYEE_ID)
          }
        });
      });
      return res
    },
    FindTariffByCode(res5, EMPLOYEE_ID) {
      let GetCode = ''
      let res = {}
      const RemoveFirstZero = parseInt(EMPLOYEE_ID).toString()
      switch (RemoveFirstZero) {
        case res5?.data?.Result?.data?.employees_MastersData?.CEOInfo &&
        res5?.data?.Result?.data?.employees_MastersData?.CEOInfo?.OrganizationsDATA?.HeadActing?.HEAD_ID:
          GetCode = res5?.data?.Result?.data?.employees_MastersData?.CEOInfo?.OrganizationsDATA?.CODE
          break;
        case res5?.data.Result?.data?.employees_MastersData?.DepartmentInfo &&
          res5?.data?.Result?.data?.employees_MastersData?.DepartmentInfo?.OrganizationsDATA?.HeadActing?.HEAD_ID:
          GetCode = res5?.data?.Result?.data?.employees_MastersData?.DepartmentInfo?.OrganizationsDATA.CODE
          break;
        case res5?.data?.Result?.data?.employees_MastersData?.GroupDivisionInfo &&
          res5?.data?.Result?.data?.employees_MastersData?.GroupDivisionInfo?.OrganizationsDATA?.HeadActing?.HEAD_ID:
          GetCode = res5?.data?.Result?.data?.employees_MastersData?.GroupDivisionInfo?.OrganizationsDATA?.CODE
          break;
        case res5?.data?.Result?.data?.employees_MastersData?.ORGUNIT &&
          res5?.data?.Result?.data?.employees_MastersData?.ORGUNIT?.OrganizationsDATA?.HeadActing?.HEAD_ID:
          GetCode = res5?.data?.Result?.data?.employees_MastersData?.ORGUNIT?.OrganizationsDATA?.CODE
          break;
        case res5?.data?.Result?.data?.employees_MastersData?.SectionInfo &&
          res5?.data?.Result?.data?.employees_MastersData?.SectionInfo?.OrganizationsDATA?.HeadActing?.HEAD_ID:
          GetCode = res5?.data?.Result?.data?.employees_MastersData?.SectionInfo?.OrganizationsDATA?.CODE
          break;
      }
      if (GetCode === '') {
        res = {
          TARIFFLEVEL: 'Professional',
          COMP: res5?.data?.Result?.data?.employees_MastersData?.COMP,
        }
      } else {
        if (GetCode === '4.0') {
          res = {
            TARIFFLEVEL: 'Senior Prof',
            COMP: res5?.data?.Result?.data?.employees_MastersData?.COMP,
          }
        } else if (GetCode === '3.0') {
          res = {
            TARIFFLEVEL: 'Mgt. (VP)',
            COMP: res5?.data?.Result?.data?.employees_MastersData?.COMP,
          }
        } else if (GetCode === '2.0' || '2.1') {
          res = {
            TARIFFLEVEL: 'Senior VP',
            COMP: res5?.data?.Result?.data?.employees_MastersData?.COMP,
          }
        } else {
          res = {
            TARIFFLEVEL: 'Professional',
            COMP: res5?.data?.Result?.data?.employees_MastersData?.COMP,
          }
        }
      }
      return res
    },
    totalAge(val) {
      const nowdate = moment().format(this.formatDatetoString)
      let result = null
      if (val) {
        result = parseInt(moment(nowdate).diff(moment(val), 'years'))
      }
      return result
    },
    async calTravelerFare(elm) {
      if (elm.flights.map(x => !x.BookingOfflineOID).length === 0
        && !elm.age && elm.TravelCard.IsDelete === 0 && elm.flights.length > 0) {
        if (elm.flights.map(x => x.adtFare !== null)) {
          elm.flights.map(x => this.sumAdvanceAmount += x.adtFare.net)
        } else if (elm.flights.map(x => x.chdFare !== null) !== null) {
          elm.flights.map(x => this.sumAdvanceAmount += x.chdFare.net)
        } else if (elm.flights.map(x => x.infFare !== null) !== null) {
          elm.flights.map(x => this.sumAdvanceAmount += x.infFare.net)
        }
      }
    },
    getTraveler() {
      const formReqID = JSON.parse(sessionStorage.formReqID)
      this.$store.state.showBtnRejectReturnApprove = formReqID.Pending && formReqID.Pending === 1 ? true : false
      const reqGetTraveler = {
        Passport: [],
        Visa: [],
        TravelRequestOID: formReqID.TravelId,
        EMPLOYEE_ID: formReqID.EMPLOYEE_ID
      };
      axios.post(`${this.$store.state.API_PATH}Traveler/GetAllTravelerByTravelRequestID`, reqGetTraveler, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(async (res) => {
        this.travelers = JSON.parse(res.data.Result) ?? []
        if (this.travelers && this.travelers.length > 0) {
          this.loopTraveller()
        } else if (this.EMPLOYEE.POSITION.indexOf('Secretary') < 0) {
          this.travellerSecretary(reqGetTraveler)
        }
        this.getTRLifeCycleByPermission()
        this.getTravelRequestLogByTRNo()
        this.reloadPage = true;
      }).catch(err => {
        console.log(err)
        this.reloadPage = true;
      });
    },
    async checkTypeTraveller(elm) {
      if (elm.birthdate) {
        if (this.totalAge(elm.birthdate) >= 12) {
          elm.age = "Adult"
        }
        else if (this.totalAge(elm.birthdate) < 12 && this.totalAge(elm.birthdate) >= 2) {
          elm.age = "Child"
        }
        else {
          elm.age = "Infant"
        }
      }
    },
    Canflight(orgunit, val) {
      const res = []
      if (val) {
        if (val.FirstClass === 1 && orgunit === '10000000') {
          res.push('F')
        }
        if (val.BusinessClass === 1) {
          if(val.ShortHaulCheck === 1 && (this.PurId === 'Scholarship' || this.PurId === 'On the job training (OJT) / On the job aassignment (OJA)')){
            res.push('Y')
          }else{
            res.push('C')
            res.push('Y')
          }
        }else{
          res.push('Y')
        }
      }
      return res
    },
    async TariffLevelCheck(orgunit, Tariff) {
      let result = {}
      let findTariff = []
      if (Tariff) {
        findTariff = await this.tariff.filter(x => x.TariffLevel.filter(y => y.TariffLevel === Tariff).length > 0)
        if (findTariff && findTariff.length > 0) {
          if (findTariff.length === 1) {
            result = {
              TariffLevel: Tariff,
              TariffOID: findTariff[0].TariffOID,
              Hours: findTariff[0].Hours,
              Color: '',
              ShortHaulCheck: findTariff[0].ShortHaulCheck === 1 ? true : false,
              CanFlight: this.Canflight(orgunit, findTariff[0]),
              SelectFlight: null,
              Level: findTariff[0].Level

            }
            result.Color = result.CanFlight[0] === 'C' ? '#FF8FBE' : findTariff[0].Color
          } else {
            const findDefault = findTariff.filter(x => x.ShortHaulCheck === 0)
            if (findDefault.length === 1) {
              result = {
                TariffLevel: Tariff,
                TariffOID: findDefault[0].TariffOID,
                Color: '',
                Hours: findDefault[0].Hours,
                ShortHaulCheck: findDefault[0].ShortHaulCheck === 1 ? true : false,
                CanFlight: this.Canflight(orgunit, findDefault[0]),
                SelectFlight: null,
                Level: findDefault[0].Level
              }
              result.Color = result.CanFlight[0] === 'C' ? '#FF8FBE' : findTariff[0].Color

            } else if(findDefault.length > 1) {
              let findHighestClass = {}
              if(findDefault.filter(x => x.FirstClass === 1).length > 0 && orgunit === '10000000'){
                findHighestClass = findDefault.filter(x => x.FirstClass === 1)[0]
              }else if(findDefault.filter(x => x.BusinessClass === 1).length > 0){
                findHighestClass = findDefault.filter(x => x.BusinessClass === 1)[0]
              }else if(findDefault.filter(x => x.EconomyClass === 1).length > 0){
                findHighestClass = findDefault.filter(x => x.EconomyClass === 1)[0]
              }
              result = {
                TariffLevel: Tariff,
                TariffOID: findHighestClass.TariffOID,
                Color: findHighestClass.Color,
                Hours: findHighestClass.Hours,
                ShortHaulCheck: findHighestClass.ShortHaulCheck === 1 ? true : false,
                CanFlight: this.Canflight(orgunit, findHighestClass),
                SelectFlight: null,
                Level: findHighestClass.Level
              }
            } else {
              result = {
                TariffLevel: Tariff,
                TariffOID: findTariff[0].TariffOID,
                Color: '',
                Hours: findTariff[0].Hours,
                ShortHaulCheck: findTariff[0].ShortHaulCheck === 1 ? true : false,
                CanFlight: this.Canflight('', findTariff[0]),
                SelectFlight: null,
                Level: findTariff[0].Level
              }

              result.Color = result.CanFlight[0] === 'C' ? '#FF8FBE' : findTariff[0].Color
            }
          }
        }
      } else {
        result = {
          TariffLevel: 'External',
          TariffOID: 'EX',
          Color: '#FF9C8F',
          ShortHaulCheck: false,
          CanFlight: ['Y'],
          SelectFlight: null,
          Level: 1
        }
      }
      return result
    },
    loopTraveller() {
      this.travelers.forEach(async (elm) => {
        if (elm.TravelCard.IsDelete === 0) {
          elm.budgetHolderApprover = `${this.approveName} ${this.approvePosition}`;
          await this.checkTypeTraveller(elm)
          await this.calTravelerFare(elm)
          elm.MyTraveler = []
          if (!elm.CreateBy) {
            elm.Type = 'Internal'
            elm.Tariff = await this.LineApprover(elm.orgunit, elm.emp_ID);
            elm.TariffLevel = await this.TariffLevelCheck(elm.orgunit, elm.Tariff.TARIFFLEVEL)
          } else {
            elm.Type = 'External'
            elm.Tariff = {
              TARIFFLEVEL: 'External'
            }
            elm.TariffLevel =  await this.TariffLevelCheck(elm.orgunit, '')
            if(elm.TariffLevel && elm.ParentsOfChild === 1){
              elm.TariffLevel.TariffOID = 'EX_OF_PARENTS'
            }
          }
        }
      })
    },
    travellerSecretary(reqGetTraveler) {
      const PROFILE = JSON.parse(localStorage.PROFILE)
      const reqTraveler = [];
      const elm = {
        EMPLOYEE_ID: PROFILE.EMPLOYEE_ID,
        BookingOID: null,
        BudgetHolderApprover: null,
        LineApprovalD: null,
        BTTId: null,
        TravelCardOID: null,
        TravelRequestOID: reqGetTraveler.TravelRequestOID,
        TravelCard: null,
        PreferredSeat: null,
        SpecialMeal: null,
        Passport: [],
        Visa: [],
        SpecialRequest: {
          PreferredSeat: null,
          SpecialMeal: null,
        },
      }
      reqTraveler.push(elm);
      axios.post(`${this.$store.state.API_PATH}Traveler/CreateListTraveler`, reqTraveler, {
        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
      }).then(async () => {
        await axios.post(`${this.$store.state.API_PATH}Traveler/GetAllTravelerByTravelRequestID`, reqGetTraveler, {
          headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        }).then((res) => {
          this.travelers = JSON.parse(res.data.Result)
          this.travelers.forEach(async (el) => {
            await this.checkTypeTraveller(el)
            await this.calTravelerFare(el)
            if (!el.CreateBy) {
              this.LineApprover(el.orgunit, el.emp_ID);
            }
          });
        }, err => {
          console.log(err)
        });
      });
    },
    conditionMounted(formReqID, res) {
      this.BTTStatus = res.data?.BTTStatus ?? "-"
      if (formReqID.EMPLOYEE_ID !== null && res.data.EMPLOYEE_ID !== formReqID.EMPLOYEE_ID) {
        this.disableCoperate = true;
      }
      if (formReqID.TRNo) {
        formReqID.TRNo = this.TRNo
        sessionStorage.formReqID = JSON.stringify(formReqID)
      }
    },
    async getBTTSupport(BTTSupport) {
      if (BTTSupport) {
        await this.getEmployeesByID(BTTSupport).then((res3) => {
          if (res3.data.Result.data.employees_MastersData !== null) {
            const item3 = res3.data.Result.data.employees_MastersData;
            if (item3) {
              this.BTTSupport = BTTSupport;
              this.BTTName = `${item3.FIRSTNAME} ${item3.LASTNAME}`;
              this.BTTPhone = item3.MOBILE_NO;
              this.Email = item3.EMAIL_ID;
            }
          }
        }).catch(err => {
          console.log(err)
        });
      }
    },
    funcModelCoperateData(item) {
      return {
        startDate: item.BusinessStartDate && item.SaveCoperate === 1 ?
          moment(item.BusinessStartDate).format(this.formatDatetoString) : '',
        endDate: item.BusinessEndDate && item.SaveCoperate === 1 ?
          moment(item.BusinessEndDate).format(this.formatDatetoString) : '',
        TRNo: item.TRNo,
        travelRequestStatus: item.Status,
        PurId: item.PurposeofTravel,
        cityCode: item.CityorProvince,
        countryCode: item.Country,
        CompanyName: item.Company,
        ProjectName: item.Project,
        BTTSupport: this.BTTSupport,
        BTTName: this.BTTName,
        BTTPhone: this.BTTPhone,
        Email: this.Email,
        BusinessTravelType: item.BusinessTravelType,
        BudgetHolder: item.BudgetHolder,
        approveId: this.approveId,
        approveName: this.approveName,
        approveTel: this.approveTel,
        approveEmail: this.approveEmail,
        approvePosition: this.approvePosition,
        SaveCoperate: item.SaveCoperate,
        DesofTravel: item.DesofTravel,
        EMPLOYEE_ID: item.EMPLOYEE_ID,
        NoServiceRequire: item.NoServiceRequire,
        NoServiceRequireReason: item.NoServiceRequireReason,
        disChkNoServiceRequire: item.isSaved
      }
    }
  },
  computed:{
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    totalAdvance(){
      return _.sumBy(this.travelers, ({ flights })=> _.sumBy(flights, f =>
        (f.adtFare?.net ?? 0) + (f.chdFare?.net ?? 0) + (f.infFare?.net ?? 0)
      ))
      + _.sumBy(this.travelers, ({ hotels })=> _.sumBy(hotels, "Amount"))
      + _.sumBy(this.travelers, ({ travelExpens })=> _.sumBy(travelExpens, "Amount"))
    },
    lifeCycleItem(){
      return this.$store.state.travelRequest.lifeCycleItem
    },
    TravelRequestLog(){
      return this.$store.state.travelRequest.TravelRequestLog
    },
    HaveBudgetHolder(){
      return this.$store.state.travelRequest.HaveBudgetHolder
    }
  },
  mounted() {
    this.$store.state.ItemPending = []
    this.formatDatetoString = this.oformatDate
    this.Cdate = moment().format(this.formatDatetoString)
    this.Mdate = moment().format(this.formatDatetoString)
    this.EMPLOYEE = JSON.parse(localStorage.PROFILE)
    let haveParamFromMail = "", haveEmpId = ""
    if (this.$route.query.TR) {
      const splTR = this.$route.query.TR.split("|")
      let Pending = 0
      if(splTR[2]){
        const splApprover = splTR[2].split(",")
        Pending = splApprover.filter(x=>x === this.EMPLOYEE.EMPLOYEE_ID).length > 0  ? 1 : 0
      }
      sessionStorage.formReqID = JSON.stringify({ EMPLOYEE_ID: null, TRNo: splTR[1], TravelId: splTR[0], Pending: Pending })
      haveParamFromMail = splTR[3]
      haveEmpId = splTR[4]
    }
    axios.get(`${this.$store.state.API_PATH}Userprofile/getToken`, {
      headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
    }).then(async (resToken) => {
      this.accessToken = resToken.data
      await this.getTariff();
      await this.getPurposeOfTravel();
      await this.getOrganizations();
      if (sessionStorage.formReqID) {
        const formReqID = JSON.parse(sessionStorage.formReqID)
        axios.post(`${this.$store.state.API_PATH}TravelRequest/GetTravelRequest?` +
          `pTravelID=${formReqID.TravelId}`, '', {
          headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        })
          .then(async (res) => {
            this.BudgetHolderTR = res.data.BudgetHolderApprover
            if(res.data.BudgetHolderApprover === this.EMPLOYEE.EMPLOYEE_ID){
              formReqID.Pending = 1
            }
            this.GetFile('TravelRequest', res.data.TRNo);
            this.TR_EMPLOYEE_ID = res.data.EMPLOYEE_ID
            this.conditionMounted(formReqID, res)
            await this.getBTTSupport(res.data.BTTSupport)
            await this.getEmployeesByIDFromSubCompany(res.data.BudgetHolderApprover)
            const coperateData = this.funcModelCoperateData(res.data)
            this.getCoperate(coperateData);
            sessionStorage.coperateData = JSON.stringify(coperateData);
            this.CoperateLoading = false
            this.getTraveler()
            if (haveParamFromMail === "A") {
              this.statusMytaskMsg = "Approve"
              if(haveEmpId){
                for(const splData of haveEmpId.split(",")){
                  if(this.ItemPending.filter(x=>x.EMPLOYEE_ID === splData).length === 0){
                    await new Promise(async(resolve)=>{
                      this.getMyTaskByPendingPerTraveller(splData)
                      resolve()
                    })
                  }
                }
              }
            }
          });
      }
      else if (sessionStorage.coperateData !== undefined) {
        const coperateData = JSON.parse(sessionStorage.coperateData);
        this.getCoperate(coperateData);
        setTimeout(() => {
          if (this.ProjectName != null && this.ProjectName !== '') {
            this.disableDate = false
          }
        }, 1000);
      } else {
        this.getCityCodeCreateTravel(false);
        this.getDataBudgetProject();
        this.SaveCoperate = 0
        this.TR_EMPLOYEE_ID = this.EMPLOYEE.EMPLOYEE_ID
        this.EditCoperate = 0
        this.CoperateLoading = false
      }
      setTimeout(() => {
        this.reloadingPage = 1
        setTimeout(() => {
          this.reloadingPage = 2
        }, 10000);
      }, 8000)
    }).catch((err) => {
      console.log(err);
      this.CoperateLoading = false
    })
    sessionStorage.removeItem("YourHotel")
    sessionStorage.removeItem("objHTL")
  },
};
</script>

<style scoped>
.full-width-textarea {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
}
.scroll-travelers {
  scroll-behavior: smooth;
}

.btnPTT {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 10px;
  gap: 10px;

  width: 150px;
  height: 36px;

  background: rgba(0, 174, 239, 0.1);
  box-shadow: 0px 4px 10px rgba(0, 128, 255, 0.12);
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.text-center {
  text-align: center;
}

.badge-info {
  padding: 0px 10px 0px 10px;
  border: 1px solid #00adef;
  color: #00adef;
  background-color: #ecfaff;
}

.scoll-x {
  width: 100px;
  overflow-x: scroll;
}

.card-note {
  background: #FFFFFF;
  border: 1px solid #E6EBF4;
  border-radius: 5px;
}

.font-W700 {
  font-weight: 700;
}

#Note th {
  text-align: left;
  background: #F8F9FA;
  color: #747678;
  font-weight: 500;
  padding: 8px;
  white-space: nowrap;
}

#Note td {
  padding: 8px;
  color: #000000;
  white-space: nowrap;
}

.card-detail {
  background: #F8F9FA;
  border: 1px solid #EBEEF4;
  border-radius: 10px;
}
</style>
