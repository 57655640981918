<template>
    <v-card class="card">
        <v-card-text>
            <v-tabs v-model="tab">
                <v-tab key="full" :disabled="selectedLowcost" style="display: none;">Full Carrier</v-tab>
                <v-tab key="low" v-if="false" :disabled="selectedFullcarrier"><v-badge dot color="warning">Low Cost</v-badge></v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item key="full">
                    <template v-if="airlines != null && !ailneDataloading">
                        <v-card elevation="0">
                            <v-card-title>
                                <h5 v-if="selectflight == 1">
                                    Select Departure Flight
                                </h5>
                                <span v-else>
                                    <h5 v-if="flightRequest.tripType !== 'O'">
                                        Select Return Flight
                                    </h5>
                                    <h5 v-else>
                                        Ticket Pricing
                                    </h5>
                                </span>
                            </v-card-title>
                        </v-card>
                        <div class="bgcard">
                            <div>
                                <div v-if="airlines.flights != null">
                                    <!--Amadeus Display-->
                                    <template v-if="sortflightAirline != null && sortflightAirline.length > 0 && !selectedLowcost">
                                        <template v-if="previousAirlines && previousAirlines.length > 0 && selectflight == 1">
                                            &nbsp; <span style="padding: 5px 30px 0px 30px;
                                                background-color: #00c7f6; color: white;
                                                border-radius: 8px 8px 0px 0px;">Previous airline booking</span>
                                                <br />
                                            <div>
                                                <div v-for="(itemAirline, index) in previousAirlines" :key="index">
                                                    <div style="border: solid red 1px; border: solid 2px #5edfff; border-radius: 10px;">
                                                        <v-card class="card">
                                                            <v-card-text>
                                                                <v-row>
                                                                    <v-col cols="12" sm="3" md="3" xs="12">
                                                                        <v-row>
                                                                            <v-col cols="4">
                                                                                <div class="text-profile text-center">
                                                                                    <v-avatar size="55">
                                                                                        <v-img width="40" alt="user"
                                                                                        :src="require('@/assets/logoAirline/square/' +
                                                                                        showAirlineLogo(itemAirline.flights.flightDetails[0].airline.code) + '.png')" />
                                                                                    </v-avatar>
                                                                                </div>
                                                                            </v-col>
                                                                            <v-col cols="8">
                                                                                <span class="text-profile">
                                                                                    <span
                                                                                        style="font-size: .6rem; position: absolute;"
                                                                                        v-if="itemAirline.flights.flightDetails[0].airline.code !=
                                                                                        itemAirline.flights.flightDetails[0].operatedAirline.code">
                                                                                        <div style="height: 8px;"></div>
                                                                                        <span style="font-size: .8rem;">
                                                                                            {{
                                                                                                itemAirline.flights.flightDetails[0].airline.name
                                                                                            }}
                                                                                        </span>
                                                                                        <br />
                                                                                        <div class="f-text-16">Operate By:
                                                                                            {{
                                                                                                itemAirline.flights.flightDetails[0].operatedAirline.name
                                                                                            }}
                                                                                        </div>
                                                                                    </span>
                                                                                    <span v-else style="font-size: .8rem;">
                                                                                        <br />
                                                                                        {{
                                                                                            itemAirline.flights.flightDetails[0].airline.name
                                                                                        }}
                                                                                    </span>
                                                                                </span>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="5" md="5" xs="12" class="text-center">
                                                                        <v-row>
                                                                            <v-col cols="4">
                                                                                <v-chip label color="#ccecf8" small
                                                                                    class="text-profile">
                                                                                    {{
                                                                                        itemAirline.flights.flightDetails[0].depDisplayDateTime.displayTime
                                                                                    }}
                                                                                </v-chip>
                                                                                <br />
                                                                                <span>
                                                                                    {{
                                                                                        itemAirline.flights.flightDetails[0].depCity.code
                                                                                    }}
                                                                                </span>
                                                                            </v-col>
                                                                            <v-col cols="4">
                                                                                <div style="height: 13px;"></div>
                                                                                <span style="position: relative;">
                                                                                    <strong style="font-size: .8rem">
                                                                                        <span>
                                                                                            {{
                                                                                                formatDate(itemAirline.flights.totalTime)
                                                                                            }}
                                                                                        </span>
                                                                                    </strong>
                                                                                    <br />
                                                                                    <img class="hidden-sm-and-down"
                                                                                        :src="require('@/assets/icons/betweenlineStop.svg')"
                                                                                        style="position: absolute; top: 50%; left: 50%;
                                                                                        transform: translate(-50%, -50%); text-align: center;"
                                                                                        width="180px" alt="" />
                                                                                    <img class="hidden-md-and-up"
                                                                                        :src="require('@/assets/icons/betweenlineStop.svg')"
                                                                                        style="position: absolute; top: 50%; left: 50%;
                                                                                        transform: translate(-50%, -50%); text-align: center;"
                                                                                        width="110px" alt="" />
                                                                                    <span style="font-size: .7rem"
                                                                                        v-if="itemAirline.flights.flightDetails.length === 1">
                                                                                        Direct
                                                                                    </span>
                                                                                    <span style="font-size: .7rem" v-else>
                                                                                        {{
                                                                                            itemAirline.flights.flightDetails.length - 1
                                                                                        }} Stops</span>
                                                                                </span>
                                                                            </v-col>
                                                                            <v-col cols="4">
                                                                                <v-chip label color="#ccecf8" small
                                                                                    class="text-profile">
                                                                                    {{
                                                                                        itemAirline.flights.flightDetails
                                                                                        [itemAirline.flights.flightDetails.length - 1].arrDisplayDateTime.displayTime
                                                                                    }}
                                                                                </v-chip>
                                                                                <br />
                                                                                <span>
                                                                                    {{
                                                                                        itemAirline.flights.flightDetails[itemAirline.flights.flightDetails.length - 1].arrCity.code
                                                                                    }}
                                                                                </span>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-col>
                                                                    <v-col cols="6" sm="2" md="2" xs="6">
                                                                        <span class="text-profile" id="pricing-airline">
                                                                            {{ itemAirline.fare.currencyCode }}
                                                                            {{ selectflight == 1 ? new Intl.NumberFormat().format(itemAirline.fare.total) : '+0' }}
                                                                            <br />
                                                                            <span
                                                                                @click="dialogFareRule.isActive = true, dialogFareRule.fareRule = itemAirline.fareRule"
                                                                                style="cursor: pointer; text-decoration: none; color: #66c6e9; font-size: 0.7rem">
                                                                                &#60; Fare Conditions
                                                                            </span>
                                                                        </span>
                                                                    </v-col>
                                                                    <v-col cols="6" sm="2" md="2" xs="6">
                                                                        <span style="float: right">
                                                                            <v-btn color="primary" style="color:white;"
                                                                                @click="selectedflight(itemAirline.flights.bookingCode,
                                                                                itemAirline.id, selectflight, itemAirline.agentService, null, itemAirline.flightBreak)">
                                                                                Select
                                                                            </v-btn>
                                                                        </span>
                                                                    </v-col>
                                                                </v-row>
                                                                <br />
                                                                <hr />
                                                            </v-card-text>
                                                            <v-card-actions @click="ShowDetails(itemAirline.flights)"
                                                                style="cursor: pointer;">
                                                                <template v-if="itemAirline.flights.bShow != false">
                                                                    <v-row>
                                                                        <v-col>
                                                                            <v-chip class="ml-2">
                                                                                <v-img
                                                                                    :src="require('@/assets/icons/seat.svg')" />
                                                                                <span class="ml-2">
                                                                                    {{
                                                                                        itemAirline.flights.flightDetails[0].cabin === 'Y' ||
                                                                                        itemAirline.flights.flightDetails[0].cabin === 'W' ||
                                                                                        itemAirline.flights.flightDetails[0].cabin === 'M' ?
                                                                                        'Economy' : itemAirline.flights.flightDetails[0].cabin === 'C' ?
                                                                                        'Business' : 'First'
                                                                                    }}
                                                                                </span>
                                                                            </v-chip>
                                                                            <v-chip class="ml-2"
                                                                                v-if="itemAirline.flights.baggageDetails != null">
                                                                                <v-img
                                                                                    :src="require('@/assets/icons/bagagge.svg')" />
                                                                                <span class="ml-2">
                                                                                    <span
                                                                                        v-if="itemAirline.flights
                                                                                        .baggageDetails.quantityCode == 'N'">
                                                                                        {{
                                                                                            itemAirline.flights
                                                                                            .baggageDetails.freeAllowance
                                                                                        }}
                                                                                        piece
                                                                                    </span>
                                                                                    <span
                                                                                        v-if="itemAirline.flights.baggageDetails.quantityCode == 'W'">
                                                                                        {{
                                                                                            itemAirline.flights.baggageDetails.freeAllowance
                                                                                        }}
                                                                                        Kg
                                                                                    </span>
                                                                                </span>
                                                                            </v-chip>
                                                                        </v-col>
                                                                    </v-row>
                                                                </template>
                                                                <template v-else>
                                                                    <v-row style="width: 100%" class="text-center">
                                                                        <v-col cols="5">
                                                                            <h5>
                                                                                {{
                                                                                    itemAirline.flights.flightDetails[0].depCity.name.split(',', 2)[1]
                                                                                }}
                                                                                ({{
                                                                                    itemAirline.flights.flightDetails[0].depCity.code
                                                                                }})
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="2">
                                                                            <div class="hidden-sm-and-dowm"></div>
                                                                            <div class="hidden-md-and-up" style="height: 15px;">
                                                                            </div>
                                                                            <v-img
                                                                                :src="require('@/assets/icons/ArrowRight.svg')"
                                                                                width="20" />
                                                                        </v-col>
                                                                        <v-col cols="5">
                                                                            <h5>
                                                                                {{
                                                                                    itemAirline.flights.flightDetails[itemAirline.flights.flightDetails.length
                                                                                        - 1].arrCity.name.split(',',
                                                                                        2)[1]
                                                                                }}
                                                                                ({{
                                                                                    itemAirline.flights.flightDetails[itemAirline.flights.flightDetails.length
                                                                                        - 1].arrCity.code
                                                                                }})
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>
                                                                </template>
                                                                <v-spacer></v-spacer>
                                                                <span class="text-profile show-details"
                                                                    style="cursor: pointer;">
                                                                    {{
                                                                        itemAirline.flights.bShow != false ? "Show Details" :
                                                                            "Hide Details"
                                                                    }}
                                                                </span>
                                                                <img :src="require(itemAirline.flights.bShow != false ?
                                                                '@/assets/icons/arrowdown.svg' : '@/assets/icons/arrowup.svg')"
                                                                 class="ml-1" width="25" style="cursor: pointer;" alt="" />
                                                            </v-card-actions>
                                                            <v-card-text v-show="!itemAirline.flights.bShow">
                                                                <v-row>
                                                                    <v-col cols="12" md="12">
                                                                        <span class="lineplane">
                                                                            <v-row
                                                                                v-for="(itemflightDetails, index) in itemAirline.flights.flightDetails"
                                                                                :key="index">
                                                                                <v-col cols="12" xs="6" md="2"
                                                                                    class="text-center">
                                                                                    <strong>
                                                                                        {{
                                                                                            itemflightDetails.depDisplayDateTime.displayTime
                                                                                        }}
                                                                                    </strong>
                                                                                    <br />
                                                                                    <span>
                                                                                        {{
                                                                                            itemflightDetails.depDisplayDateTime.shortDate
                                                                                        }}
                                                                                    </span>
                                                                                    <v-row>
                                                                                        <v-col cols="12"
                                                                                            class="hidden-md-and-up">
                                                                                            <h3>
                                                                                                {{
                                                                                                    itemflightDetails.depCity.name.split(',', 2)[1]
                                                                                                }}</h3>
                                                                                            {{
                                                                                                itemflightDetails.depCity.name.split(',',
                                                                                                    2)[0]
                                                                                            }}
                                                                                            ({{
                                                                                                itemflightDetails.depCity.code
                                                                                            }})
                                                                                            <div>
                                                                                                <br />
                                                                                                <div
                                                                                                    style="border: #EBEEF4 0.5px solid; border-radius: 8px;">
                                                                                                    <div style="padding: 10px;">
                                                                                                        <v-row>
                                                                                                            <v-col cols="12">
                                                                                                                <v-row>
                                                                                                                    <v-col
                                                                                                                        cols="4"
                                                                                                                        sm="2">
                                                                                                                        {{ itemflightDetails.airline.code }}
                                                                                                                        <v-img width="40" alt="user"
                                                                                                                        :src="require('@/assets/logoAirline/square/' +
                                                                                                                        showAirlineLogo(itemflightDetails.airline.code)
                                                                                                                        + '.png')" />
                                                                                                                    </v-col>
                                                                                                                    <v-col
                                                                                                                        cols="8"
                                                                                                                        sm="10">
                                                                                                                        <span
                                                                                                                            style="font-size: .8rem; float: right;">
                                                                                                                            {{
                                                                                                                                itemflightDetails.airline.name
                                                                                                                            }}
                                                                                                                        </span>
                                                                                                                    </v-col>
                                                                                                                    <v-col
                                                                                                                        cols="4"
                                                                                                                        sm="2">
                                                                                                                        <span
                                                                                                                            style="font-size: .8rem; float: left;">
                                                                                                                            Flight
                                                                                                                            No.
                                                                                                                        </span>
                                                                                                                    </v-col>
                                                                                                                    <v-col
                                                                                                                        cols="8"
                                                                                                                        sm="10">
                                                                                                                        <span
                                                                                                                            style="font-size: .8rem; float: right;">{{
                                                                                                                                itemflightDetails.airline.code
                                                                                                                                    +
                                                                                                                                    '-'
                                                                                                                                    +
                                                                                                                                    itemflightDetails.flightNumber
                                                                                                                            }}</span>
                                                                                                                    </v-col>
                                                                                                                    <v-col
                                                                                                                        cols="4"
                                                                                                                        sm="2"
                                                                                                                        style="font-size: .8rem; float: left;">
                                                                                                                        <span
                                                                                                                            style="font-size: .8rem;">Equipment
                                                                                                                            Type</span>
                                                                                                                    </v-col>
                                                                                                                    <v-col cols="8" sm="10">
                                                                                                                        <span
                                                                                                                        style="font-size: .8rem; padding-top: 2px; float: right;">
                                                                                                                            {{!itemAirline.flights.bShow ?
                                                                                                                            aircraftData(itemflightDetails.equipmentType) : ''}}
                                                                                                                        </span>
                                                                                                                    </v-col>
                                                                                                                    <v-col
                                                                                                                        cols="4"
                                                                                                                        sm="2"
                                                                                                                        v-if="itemAirline.flights.baggageDetails != null">
                                                                                                                        <span
                                                                                                                            style="font-size: .8rem; float: left;">Baggage</span>
                                                                                                                    </v-col>
                                                                                                                    <v-col
                                                                                                                        cols="8"
                                                                                                                        sm="10"
                                                                                                                        v-if="itemAirline.flights.baggageDetails != null">
                                                                                                                        <span
                                                                                                                            style="font-size: .8rem; float: right;">
                                                                                                                            <span
                                                                                                                                v-if="itemAirline.flights.baggageDetails
                                                                                                                                .quantityCode == 'N'">
                                                                                                                                {{
                                                                                                                                    itemAirline.flights.baggageDetails.freeAllowance
                                                                                                                                }}
                                                                                                                                piece
                                                                                                                            </span>
                                                                                                                            <span
                                                                                                                                v-if="itemAirline.flights.baggageDetails
                                                                                                                                .quantityCode == 'W'">
                                                                                                                                {{
                                                                                                                                    itemAirline.flights.baggageDetails.freeAllowance
                                                                                                                                }}
                                                                                                                                Kg
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                        <br />
                                                                                                                    </v-col>
                                                                                                                </v-row>
                                                                                                            </v-col>
                                                                                                        </v-row>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <br />
                                                                                            </div>
                                                                                            <div v-if="itemAirline.flights.baggageDetails == null"
                                                                                                style="height: 45px;"></div>
                                                                                            <h3>
                                                                                                {{
                                                                                                    itemflightDetails.arrCity.name.split(',',
                                                                                                        2)[1]
                                                                                                }}
                                                                                            </h3>
                                                                                            {{
                                                                                                itemflightDetails.arrCity.name.split(',',
                                                                                                    2)[0]
                                                                                            }}
                                                                                            ({{
                                                                                                itemflightDetails.arrCity.code
                                                                                            }})
                                                                                            <br />
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <div style="height: 223px;"
                                                                                        class="hidden-sm-and-down"></div>
                                                                                    <strong>
                                                                                        {{
                                                                                            itemflightDetails.arrDisplayDateTime.displayTime
                                                                                        }}
                                                                                    </strong>
                                                                                    <br />
                                                                                    <span>
                                                                                        {{
                                                                                            itemflightDetails.arrDisplayDateTime.shortDate
                                                                                        }}
                                                                                    </span>
                                                                                </v-col>
                                                                                <v-col class="hidden-sm-and-down" cols="12"
                                                                                    xs="3" md="1">
                                                                                    <v-img class="hidden-sm-and-down"
                                                                                        :src="require('@/assets/icons/LineairPlane.jpg')"
                                                                                        width="18">
                                                                                    </v-img>
                                                                                </v-col>
                                                                                <v-col class="hidden-sm-and-down" cols="5"
                                                                                    style="position: relative;">
                                                                                    <h3>
                                                                                        {{
                                                                                            itemflightDetails.depCity.name.split(',',
                                                                                                2)[1]
                                                                                        }}</h3>
                                                                                    {{
                                                                                        itemflightDetails.depCity.name.split(',',
                                                                                            2)[0]
                                                                                    }} ({{ itemflightDetails.depCity.code }})
                                                                                    <div>
                                                                                        <br />
                                                                                        <div
                                                                                            style="border: #EBEEF4 0.5px solid; border-radius: 8px;">
                                                                                            <div style="padding: 10px;">
                                                                                                <v-row>
                                                                                                    <v-col cols="12">
                                                                                                        <v-row>
                                                                                                            <v-col cols="2">
                                                                                                                <v-img width="40" alt="user"
                                                                                                                :src="require('@/assets/logoAirline/square/' +
                                                                                                                showAirlineLogo(itemflightDetails.airline.code) + '.png')" />
                                                                                                            </v-col>
                                                                                                            <v-col cols="6">
                                                                                                                <span
                                                                                                                    style="font-size: .8rem;">
                                                                                                                    {{
                                                                                                                        itemflightDetails.airline.name
                                                                                                                    }}
                                                                                                                </span>
                                                                                                                <br />
                                                                                                                <span
                                                                                                                    style="font-size: .8rem;">
                                                                                                                    Flight
                                                                                                                    No.
                                                                                                                </span>
                                                                                                                <br />
                                                                                                                <span
                                                                                                                    style="font-size: .8rem;">Equipment
                                                                                                                    Type</span>
                                                                                                            </v-col>
                                                                                                            <v-col cols="4">
                                                                                                                <span
                                                                                                                    style="font-size: .8rem; padding: 5px; float: right;"></span>
                                                                                                                <br />
                                                                                                                <span
                                                                                                                    style="font-size: .8rem; float: right;">{{
                                                                                                                        itemflightDetails.airline.code
                                                                                                                            + ' ' +
                                                                                                                            itemflightDetails.flightNumber
                                                                                                                    }}</span>
                                                                                                                <br />
                                                                                                                <span
                                                                                                                    style="font-size: .7rem; padding-top: 2px; float: right;">
                                                                                                                    {{!itemAirline.flights.bShow
                                                                                                                    ?
                                                                                                                    aircraftData(itemflightDetails.equipmentType)
                                                                                                                    : '' }}
                                                                                                                </span>
                                                                                                            </v-col>
                                                                                                        </v-row>
                                                                                                    </v-col>
                                                                                                    <v-col cols="2"
                                                                                                        v-if="itemAirline.flights.baggageDetails != null">
                                                                                                        <v-img alt="user"
                                                                                                            src="../../assets/icons/bagagge.svg"
                                                                                                            style="margin: 8px;"
                                                                                                            width="25" />
                                                                                                    </v-col>
                                                                                                    <v-col cols="5"
                                                                                                        v-if="itemAirline.flights.baggageDetails != null">
                                                                                                        <div
                                                                                                            style="height: 8px;">
                                                                                                        </div>
                                                                                                        <span>Baggage</span>
                                                                                                    </v-col>
                                                                                                    <v-col cols="5"
                                                                                                        v-if="itemAirline.flights.baggageDetails != null">
                                                                                                        <span
                                                                                                            style="font-size: 0.9rem; float: right; margin-top: 10px;">
                                                                                                            <span
                                                                                                                v-if="itemAirline.flights.baggageDetails.quantityCode == 'N'">
                                                                                                                {{
                                                                                                                    itemAirline.flights.baggageDetails.freeAllowance
                                                                                                                }}
                                                                                                                piece
                                                                                                            </span>
                                                                                                            <span
                                                                                                                v-if="itemAirline.flights.baggageDetails.quantityCode == 'W'">
                                                                                                                {{
                                                                                                                    itemAirline.flights.baggageDetails.freeAllowance
                                                                                                                }}
                                                                                                                Kg
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <br />
                                                                                                    </v-col>
                                                                                                </v-row>
                                                                                            </div>
                                                                                        </div>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div v-if="itemAirline.flights.baggageDetails == null"
                                                                                        style="height: 45px;"></div>
                                                                                    <h3>
                                                                                        {{
                                                                                            itemflightDetails.arrCity.name.split(',',
                                                                                                2)[1]
                                                                                        }}
                                                                                    </h3>
                                                                                    {{
                                                                                        itemflightDetails.arrCity.name.split(',',
                                                                                            2)[0]
                                                                                    }} ({{ itemflightDetails.arrCity.code }})
                                                                                    <br />
                                                                                </v-col>
                                                                                <v-col cols="12"
                                                                                    v-if="itemAirline.flights.flightDetails.length - 1 !== index">
                                                                                    <h4 class="text-center">
                                                                                        <span>
                                                                                            Flight Break:
                                                                                        </span>
                                                                                        {{timespanFlightDetail_v2(itemAirline, index)}}
                                                                                    </h4>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </span>
                                                                    </v-col>
                                                                    <v-col cols="9" md="6">
                                                                        <br />
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </template>
                                        <div v-for="(itemAirline, index) in selectflight == 1 ? sortflightAirline : selectreturnflight"
                                            :key="index + 'Airline'">
                                            <div>
                                                <v-card class="card">
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col cols="12" sm="3" md="3" xs="12">
                                                                <v-row>
                                                                    <v-col cols="4">
                                                                        <div class="text-profile text-center">
                                                                            <v-avatar size="55">
                                                                                <v-img width="40" alt="user"
                                                                                :src="require('@/assets/logoAirline/square/' +
                                                                                showAirlineLogo(itemAirline.flights.flightDetails[0].airline.code) + '.png')" />
                                                                            </v-avatar>
                                                                        </div>
                                                                    </v-col>
                                                                    <v-col cols="8">
                                                                        <span class="text-profile">
                                                                            <span
                                                                                style="font-size: .6rem; position: absolute;"
                                                                                v-if="itemAirline.flights.flightDetails[0].airline.code !=
                                                                                itemAirline.flights.flightDetails[0].operatedAirline.code">
                                                                                <div style="height: 8px;"></div>
                                                                                <span style="font-size: .8rem;">
                                                                                    {{
                                                                                        itemAirline.flights.flightDetails[0].airline.name
                                                                                    }}
                                                                                </span>
                                                                                <br />
                                                                                <div class="f-text-16">Operate By:
                                                                                    {{
                                                                                        itemAirline.flights.flightDetails[0].operatedAirline.name
                                                                                    }}
                                                                                </div>
                                                                            </span>
                                                                            <span v-else style="font-size: .8rem;">
                                                                                <br />
                                                                                {{
                                                                                    itemAirline.flights.flightDetails[0].airline.name
                                                                                }}
                                                                            </span>
                                                                        </span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col cols="12" sm="5" md="5" xs="12" class="text-center">
                                                                <v-row>
                                                                    <v-col cols="4">
                                                                        <v-chip label color="#ccecf8" small
                                                                            class="text-profile">
                                                                            {{
                                                                                itemAirline.flights.flightDetails[0].depDisplayDateTime.displayTime
                                                                            }}
                                                                        </v-chip>
                                                                        <br />
                                                                        <span>
                                                                            {{
                                                                                itemAirline.flights.flightDetails[0].depCity.code
                                                                            }}
                                                                        </span>
                                                                    </v-col>
                                                                    <v-col cols="4">
                                                                        <div style="height: 13px;"></div>
                                                                        <span style="position: relative;">
                                                                            <strong style="font-size: .8rem">
                                                                                <span>
                                                                                    {{
                                                                                        formatDate(itemAirline.flights.totalTime)
                                                                                    }}
                                                                                </span>
                                                                            </strong>
                                                                            <br />
                                                                            <img class="hidden-sm-and-down"
                                                                                :src="require('@/assets/icons/betweenlineStop.svg')"
                                                                                style="position: absolute; top: 50%; left: 50%;
                                                                                transform: translate(-50%, -50%); text-align: center;"
                                                                                width="180px" alt="" />
                                                                            <img class="hidden-md-and-up"
                                                                                :src="require('@/assets/icons/betweenlineStop.svg')"
                                                                                style="position: absolute; top: 50%; left: 50%;
                                                                                transform: translate(-50%, -50%); text-align: center;"
                                                                                width="110px" alt="" />
                                                                            <span style="font-size: .7rem"
                                                                                v-if="itemAirline.flights.flightDetails.length === 1">
                                                                                Direct
                                                                            </span>
                                                                            <span style="font-size: .7rem" v-else>
                                                                                {{
                                                                                    itemAirline.flights.flightDetails.length - 1
                                                                                }} Stops</span>
                                                                        </span>
                                                                    </v-col>
                                                                    <v-col cols="4">
                                                                        <v-chip label color="#ccecf8" small
                                                                            class="text-profile">
                                                                            {{
                                                                                itemAirline.flights.flightDetails
                                                                                [itemAirline.flights.flightDetails.length - 1].arrDisplayDateTime.displayTime
                                                                            }}
                                                                        </v-chip>
                                                                        <br />
                                                                        <span>
                                                                            {{
                                                                                itemAirline.flights.flightDetails[itemAirline.flights.flightDetails.length - 1].arrCity.code
                                                                            }}
                                                                        </span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col cols="6" sm="2" md="2" xs="6">
                                                                <span class="text-profile" id="pricing-airline">
                                                                    {{ itemAirline.fare.currencyCode }}
                                                                    {{ selectflight == 1 ? new Intl.NumberFormat().format(itemAirline.fare.total) : '+0' }}
                                                                    <br />
                                                                    <span
                                                                        @click="dialogFareRule.isActive = true, dialogFareRule.fareRule = itemAirline.fareRule"
                                                                        style="cursor: pointer; text-decoration: none; color: #66c6e9; font-size: 0.7rem">
                                                                        &#60; Fare Conditions
                                                                    </span>
                                                                </span>
                                                            </v-col>
                                                            <v-col cols="6" sm="2" md="2" xs="6">
                                                                <span style="float: right">
                                                                    <v-btn color="primary" style="color:white;"
                                                                        @click="selectedflight(itemAirline.flights.bookingCode,
                                                                        itemAirline.id, selectflight, itemAirline.agentService, null, itemAirline.flightBreak)">
                                                                        Select
                                                                    </v-btn>
                                                                </span>
                                                            </v-col>
                                                        </v-row>
                                                        <br />
                                                        <hr />
                                                    </v-card-text>
                                                    <v-card-actions @click="ShowDetails(itemAirline.flights)"
                                                        style="cursor: pointer;">
                                                        <template v-if="itemAirline.flights.bShow != false">
                                                            <v-row>
                                                                <v-col>
                                                                    <v-chip class="ml-2">
                                                                        <v-img
                                                                            :src="require('@/assets/icons/seat.svg')" />
                                                                        <span class="ml-2">
                                                                            {{
                                                                                itemAirline.flights.flightDetails[0].cabin === 'Y' ||
                                                                                itemAirline.flights.flightDetails[0].cabin === 'W' ||
                                                                                itemAirline.flights.flightDetails[0].cabin === 'M' ?
                                                                                'Economy' : itemAirline.flights.flightDetails[0].cabin === 'C' ?
                                                                                'Business' : 'First'
                                                                            }}
                                                                        </span>
                                                                    </v-chip>
                                                                    <v-chip class="ml-2"
                                                                        v-if="itemAirline.flights.baggageDetails != null">
                                                                        <v-img
                                                                            :src="require('@/assets/icons/bagagge.svg')" />
                                                                        <span class="ml-2">
                                                                            <span
                                                                                v-if="itemAirline.flights
                                                                                .baggageDetails.quantityCode == 'N'">
                                                                                {{
                                                                                    itemAirline.flights
                                                                                    .baggageDetails.freeAllowance
                                                                                }}
                                                                                piece
                                                                            </span>
                                                                            <span
                                                                                v-if="itemAirline.flights.baggageDetails.quantityCode == 'W'">
                                                                                {{
                                                                                    itemAirline.flights.baggageDetails.freeAllowance
                                                                                }}
                                                                                Kg
                                                                            </span>
                                                                        </span>
                                                                    </v-chip>
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                        <template v-else>
                                                            <v-row style="width: 100%" class="text-center">
                                                                <v-col cols="5">
                                                                    <h5>
                                                                        {{
                                                                            itemAirline.flights.flightDetails[0].depCity.name.split(',', 2)[1]
                                                                        }}
                                                                        ({{
                                                                            itemAirline.flights.flightDetails[0].depCity.code
                                                                        }})
                                                                    </h5>
                                                                </v-col>
                                                                <v-col cols="2">
                                                                    <div class="hidden-sm-and-dowm"></div>
                                                                    <div class="hidden-md-and-up" style="height: 15px;">
                                                                    </div>
                                                                    <v-img
                                                                        :src="require('@/assets/icons/ArrowRight.svg')"
                                                                        width="20" />
                                                                </v-col>
                                                                <v-col cols="5">
                                                                    <h5>
                                                                        {{
                                                                            itemAirline.flights.flightDetails[itemAirline.flights.flightDetails.length
                                                                                - 1].arrCity.name.split(',',
                                                                                2)[1]
                                                                        }}
                                                                        ({{
                                                                            itemAirline.flights.flightDetails[itemAirline.flights.flightDetails.length
                                                                                - 1].arrCity.code
                                                                        }})
                                                                    </h5>
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                        <v-spacer></v-spacer>
                                                        <span class="text-profile show-details"
                                                            style="cursor: pointer;">
                                                            {{
                                                                itemAirline.flights.bShow != false ? "Show Details" :
                                                                    "Hide Details"
                                                            }}
                                                        </span>
                                                        <img :src="require(itemAirline.flights.bShow != false ? '@/assets/icons/arrowdown.svg' : '@/assets/icons/arrowup.svg')"
                                                            class="ml-1" width="25" style="cursor: pointer;" alt="" />
                                                    </v-card-actions>
                                                    <v-card-text v-show="!itemAirline.flights.bShow">
                                                        <v-row>
                                                            <v-col cols="12" md="12">
                                                                <span class="lineplane">
                                                                    <v-row
                                                                        v-for="(itemflightDetails, index) in itemAirline.flights.flightDetails"
                                                                        :key="index">
                                                                        <v-col cols="12" xs="6" md="2"
                                                                            class="text-center">
                                                                            <strong>
                                                                                {{
                                                                                    itemflightDetails.depDisplayDateTime.displayTime
                                                                                }}
                                                                            </strong>
                                                                            <br />
                                                                            <span>
                                                                                {{
                                                                                    itemflightDetails.depDisplayDateTime.shortDate
                                                                                }}
                                                                            </span>
                                                                            <v-row>
                                                                                <v-col cols="12"
                                                                                    class="hidden-md-and-up">
                                                                                    <h3>
                                                                                        {{
                                                                                            itemflightDetails.depCity.name.split(',', 2)[1]
                                                                                        }}</h3>
                                                                                    {{
                                                                                        itemflightDetails.depCity.name.split(',',
                                                                                            2)[0]
                                                                                    }}
                                                                                    ({{
                                                                                        itemflightDetails.depCity.code
                                                                                    }})
                                                                                    <div>
                                                                                        <br />
                                                                                        <div
                                                                                            style="border: #EBEEF4 0.5px solid; border-radius: 8px;">
                                                                                            <div style="padding: 10px;">
                                                                                                <v-row>
                                                                                                    <v-col cols="12">
                                                                                                        <v-row>
                                                                                                            <v-col
                                                                                                                cols="4"
                                                                                                                sm="2">
                                                                                                                <v-img width="40" alt="user"
                                                                                                                :src="require('@/assets/logoAirline/square/' +
                                                                                                                showAirlineLogo(itemflightDetails.airline.code) + '.png')" />
                                                                                                            </v-col>
                                                                                                            <v-col
                                                                                                                cols="8"
                                                                                                                sm="10">
                                                                                                                <span
                                                                                                                    style="font-size: .8rem; float: right;">
                                                                                                                    {{
                                                                                                                        itemflightDetails.airline.name
                                                                                                                    }}
                                                                                                                </span>
                                                                                                            </v-col>
                                                                                                            <v-col
                                                                                                                cols="4"
                                                                                                                sm="2">
                                                                                                                <span
                                                                                                                    style="font-size: .8rem; float: left;">
                                                                                                                    Flight
                                                                                                                    No.
                                                                                                                </span>
                                                                                                            </v-col>
                                                                                                            <v-col
                                                                                                                cols="8"
                                                                                                                sm="10">
                                                                                                                <span
                                                                                                                    style="font-size: .8rem; float: right;">{{
                                                                                                                        itemflightDetails.airline.code
                                                                                                                            +
                                                                                                                            '-'
                                                                                                                            +
                                                                                                                            itemflightDetails.flightNumber
                                                                                                                    }}</span>
                                                                                                            </v-col>
                                                                                                            <v-col
                                                                                                                cols="4"
                                                                                                                sm="2"
                                                                                                                style="font-size: .8rem; float: left;">
                                                                                                                <span
                                                                                                                    style="font-size: .8rem;">Equipment
                                                                                                                    Type</span>
                                                                                                            </v-col>
                                                                                                            <v-col
                                                                                                                cols="8"
                                                                                                                sm="10">
                                                                                                                <span
                                                                                                                    style="font-size: .8rem; padding-top: 2px; float: right;">
                                                                                                                    {{
                                                                                                                        !itemAirline.flights.bShow ? aircraftData
                                                                                                                        (itemflightDetails.equipmentType) : ''
                                                                                                                    }}
                                                                                                                </span>
                                                                                                            </v-col>
                                                                                                            <v-col
                                                                                                                cols="4"
                                                                                                                sm="2"
                                                                                                                v-if="itemAirline.flights.baggageDetails != null">
                                                                                                                <span
                                                                                                                    style="font-size: .8rem; float: left;">Baggage</span>
                                                                                                            </v-col>
                                                                                                            <v-col
                                                                                                                cols="8"
                                                                                                                sm="10"
                                                                                                                v-if="itemAirline.flights.baggageDetails != null">
                                                                                                                <span
                                                                                                                    style="font-size: .8rem; float: right;">
                                                                                                                    <span
                                                                                                                        v-if="itemAirline.flights.baggageDetails
                                                                                                                        .quantityCode == 'N'">
                                                                                                                        {{
                                                                                                                            itemAirline.flights.baggageDetails.freeAllowance
                                                                                                                        }}
                                                                                                                        piece
                                                                                                                    </span>
                                                                                                                    <span
                                                                                                                        v-if="itemAirline.flights.baggageDetails
                                                                                                                        .quantityCode == 'W'">
                                                                                                                        {{
                                                                                                                            itemAirline.flights.baggageDetails.freeAllowance
                                                                                                                        }}
                                                                                                                        Kg
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                                <br />
                                                                                                            </v-col>
                                                                                                        </v-row>
                                                                                                    </v-col>
                                                                                                </v-row>
                                                                                            </div>
                                                                                        </div>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div v-if="itemAirline.flights.baggageDetails == null"
                                                                                        style="height: 45px;"></div>
                                                                                    <h3>
                                                                                        {{
                                                                                            itemflightDetails.arrCity.name.split(',',
                                                                                                2)[1]
                                                                                        }}
                                                                                    </h3>
                                                                                    {{
                                                                                        itemflightDetails.arrCity.name.split(',',
                                                                                            2)[0]
                                                                                    }}
                                                                                    ({{
                                                                                        itemflightDetails.arrCity.code
                                                                                    }})
                                                                                    <br />
                                                                                </v-col>
                                                                            </v-row>
                                                                            <div style="height: 223px;"
                                                                                class="hidden-sm-and-down"></div>
                                                                            <strong>
                                                                                {{
                                                                                    itemflightDetails.arrDisplayDateTime.displayTime
                                                                                }}
                                                                            </strong>
                                                                            <br />
                                                                            <span>
                                                                                {{
                                                                                    itemflightDetails.arrDisplayDateTime.shortDate
                                                                                }}
                                                                            </span>
                                                                        </v-col>
                                                                        <v-col class="hidden-sm-and-down" cols="12"
                                                                            xs="3" md="1">
                                                                            <v-img class="hidden-sm-and-down"
                                                                                :src="require('@/assets/icons/LineairPlane.jpg')"
                                                                                width="18">
                                                                            </v-img>
                                                                        </v-col>
                                                                        <v-col class="hidden-sm-and-down" cols="5"
                                                                            style="position: relative;">
                                                                            <h3>
                                                                                {{
                                                                                    itemflightDetails.depCity.name.split(',',
                                                                                        2)[1]
                                                                                }}</h3>
                                                                            {{
                                                                                itemflightDetails.depCity.name.split(',',
                                                                                    2)[0]
                                                                            }} ({{ itemflightDetails.depCity.code }})
                                                                            <div>
                                                                                <br />
                                                                                <div
                                                                                    style="border: #EBEEF4 0.5px solid; border-radius: 8px;">
                                                                                    <div style="padding: 10px;">
                                                                                        <v-row>
                                                                                            <v-col cols="12">
                                                                                                <v-row>
                                                                                                    <v-col cols="2">
                                                                                                        <v-img
                                                                                                            width="40"
                                                                                                            alt="user"
                                                                                                            :src="require('@/assets/logoAirline/square/' +
                                                                                                            showAirlineLogo(itemflightDetails.airline.code) + '.png')" />
                                                                                                    </v-col>
                                                                                                    <v-col cols="6">
                                                                                                        <span
                                                                                                            style="font-size: .8rem;">
                                                                                                            {{
                                                                                                                itemflightDetails.airline.name
                                                                                                            }}
                                                                                                        </span>
                                                                                                        <br />
                                                                                                        <span
                                                                                                            style="font-size: .8rem;">
                                                                                                            Flight
                                                                                                            No.
                                                                                                        </span>
                                                                                                        <br />
                                                                                                        <span
                                                                                                            style="font-size: .8rem;">Equipment
                                                                                                            Type</span>
                                                                                                    </v-col>
                                                                                                    <v-col cols="4">
                                                                                                        <span
                                                                                                            style="font-size: .8rem; padding: 5px; float: right;"></span>
                                                                                                        <br />
                                                                                                        <span
                                                                                                            style="font-size: .8rem; float: right;">{{
                                                                                                                itemflightDetails.airline.code
                                                                                                                    + ' ' +
                                                                                                                    itemflightDetails.flightNumber
                                                                                                            }}</span>
                                                                                                        <br />
                                                                                                        <span
                                                                                                            style="font-size: .7rem; padding-top: 2px; float: right;">
                                                                                                            {{!itemAirline.flights.bShow
                                                                                                            ?
                                                                                                            aircraftData(itemflightDetails.equipmentType)
                                                                                                            : '' }}
                                                                                                        </span>
                                                                                                    </v-col>
                                                                                                </v-row>
                                                                                            </v-col>
                                                                                            <v-col cols="2"
                                                                                                v-if="itemAirline.flights.baggageDetails != null">
                                                                                                <v-img alt="user"
                                                                                                    src="../../assets/icons/bagagge.svg"
                                                                                                    style="margin: 8px;"
                                                                                                    width="25" />
                                                                                            </v-col>
                                                                                            <v-col cols="5"
                                                                                                v-if="itemAirline.flights.baggageDetails != null">
                                                                                                <div
                                                                                                    style="height: 8px;">
                                                                                                </div>
                                                                                                <span>Baggage</span>
                                                                                            </v-col>
                                                                                            <v-col cols="5"
                                                                                                v-if="itemAirline.flights.baggageDetails != null">
                                                                                                <span
                                                                                                    style="font-size: 0.9rem; float: right; margin-top: 10px;">
                                                                                                    <span
                                                                                                        v-if="itemAirline.flights.baggageDetails.quantityCode == 'N'">
                                                                                                        {{
                                                                                                            itemAirline.flights.baggageDetails.freeAllowance
                                                                                                        }}
                                                                                                        piece
                                                                                                    </span>
                                                                                                    <span
                                                                                                        v-if="itemAirline.flights.baggageDetails.quantityCode == 'W'">
                                                                                                        {{
                                                                                                            itemAirline.flights.baggageDetails.freeAllowance
                                                                                                        }}
                                                                                                        Kg
                                                                                                    </span>
                                                                                                </span>
                                                                                                <br />
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </div>
                                                                                </div>
                                                                                <br />
                                                                            </div>
                                                                            <div v-if="itemAirline.flights.baggageDetails == null"
                                                                                style="height: 45px;"></div>
                                                                            <h3>
                                                                                {{
                                                                                    itemflightDetails.arrCity.name.split(',',
                                                                                        2)[1]
                                                                                }}
                                                                            </h3>
                                                                            {{
                                                                                itemflightDetails.arrCity.name.split(',',
                                                                                    2)[0]
                                                                            }} ({{ itemflightDetails.arrCity.code }})
                                                                            <br />
                                                                        </v-col>
                                                                        <v-col cols="12"
                                                                            v-if="itemAirline.flights.flightDetails.length - 1 !== index">
                                                                            <h4 class="text-center">
                                                                                <span>
                                                                                    Flight Break:
                                                                                </span>
                                                                                {{timespanFlightDetail_v2(itemAirline, index)}}
                                                                            </h4>
                                                                        </v-col>
                                                                    </v-row>
                                                                </span>
                                                            </v-col>
                                                            <v-col cols="9" md="6">
                                                                <br />
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                                <br />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <strong v-if="!processingflight">not found </strong>
                                        <strong v-else>Processing...</strong>
                                    </template>
                                    <!--End Amadeus Display-->
                                </div>
                                <div v-else>
                                    <strong>Not Found</strong>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="(error.Iserror != true)">
                            <div style="height: 80px"></div>
                            <div class="text-center">
                                <v-progress-circular :size="70" indeterminate color="primary"
                                    :width="7"></v-progress-circular>
                                    <div class="h-space"></div>
                                    {{notFoundFlightMessage}}
                            </div>
                            <div style="height: 80px"></div>
                        </div>
                        <div v-else-if="(airlines === null)">
                            Not Found
                        </div>
                        <div v-else-if="(airlines == null)">
                            {{ error.errorMessage }}
                        </div>
                    </template>
                    <v-dialog v-model="dialogFareRule.isActive" width="500">
                        <v-card elevation="0" style="background-color: #F8F9FA;">
                            <v-card-title>
                                Fare Conditions
                                <v-spacer></v-spacer>
                                <img :src="require('@/assets/icons/CancelDark.svg')"
                                    @click="dialogFareRule.isActive = false" style="cursor: pointer;" alt="" />
                            </v-card-title>
                            <v-card-text>
                                <div style="background-color: white; border-radius: 10px; padding: 6px;">
                                    <span v-if="dialogFareRule.fareRule.length > 0">
                                        <span v-for="itemRule in dialogFareRule.fareRule" :key="itemRule">
                                            {{ itemRule }}
                                            <br />
                                        </span>
                                    </span>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-tab-item>
                <v-tab-item key="low">
                    <template v-if="airlines != null && !ailneDataloading">
                        <v-card elevation="0">
                            <v-card-title>
                                <h5 v-if="selectflight == 1">
                                    Select Departure Flight
                                </h5>
                                <span v-else>
                                    <h5 v-if="flightRequest.tripType !== 'O'">
                                        Select Return Flight
                                    </h5>
                                    <h5 v-else>
                                        Ticket Pricing
                                    </h5>
                                </span>
                            </v-card-title>
                        </v-card>
                        <!--Nok Air Display-->
                        <br />
                        &nbsp;<span v-if="sortLowCostflightAirline.NokAir != null && sortLowCostflightAirline.NokAir.length > 0"
                             style="padding: 5px 30px 0px 30px;
                             background-color: #FFCB0B; color: white;
                             border-radius: 8px 8px 0px 0px;">Nok Air</span>
                        <div v-if="sortLowCostflightAirline.NokAir != null && sortLowCostflightAirline.NokAir.length > 0"
                            class="overflow-x-auto"
                            style="height: 650px; border: solid 2px #FFCB0B; border-radius: 10px;">
                            <div v-for="(itemAirline, index) in selectflight == 1 ? sortLowCostflightAirline.NokAir : selectreturnLowcostflight"
                                :key="index + 'Airline'">
                                <div>
                                    <v-card class="card">
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12" sm="3" md="3" xs="12">
                                                    <v-row>
                                                        <v-col cols="4">
                                                            <div class="text-profile text-center">
                                                                <v-avatar size="55">
                                                                    <v-img
                                                                        :src="require('@/assets/logoAirline/square/' +
                                                                        itemAirline.flights.flightDetails[0].airline.code + '.png')" />
                                                                </v-avatar>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <span class="text-profile">
                                                                <span style="font-size: .6rem; position: absolute;"
                                                                    v-if="itemAirline.flights.flightDetails[0].airline.code !=
                                                                    itemAirline.flights.flightDetails[0].operatedAirline.code">
                                                                    <div style="height: 8px;"></div>
                                                                    <span style="font-size: .8rem;">
                                                                        {{
                                                                        itemAirline.flights.flightDetails[0].airline.name
                                                                        }}
                                                                    </span>
                                                                    <br />
                                                                    <div class="f-text-16">Operate By:
                                                                        {{
                                                                        itemAirline.flights.flightDetails[0].operatedAirline.name
                                                                        }}
                                                                    </div>

                                                                </span>
                                                                <span v-else style="font-size: .8rem;">
                                                                    <br />
                                                                    {{
                                                                    itemAirline.flights.flightDetails[0].airline.name
                                                                    }}
                                                                </span>
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12" sm="5" md="5" xs="12" class="text-center">
                                                    <v-row>
                                                        <v-col cols="4">
                                                            <v-chip label color="#ccecf8" small class="text-profile">
                                                                {{
                                                                itemAirline.flights.flightDetails[0].depDisplayDateTime.displayTime
                                                                }}
                                                            </v-chip>
                                                            <br />
                                                            <span>
                                                                {{
                                                                itemAirline.flights.flightDetails[0].depCity.code
                                                                }}
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <div style="height: 13px;"></div>
                                                            <span style="position: relative;">
                                                                <strong style="font-size: .8rem">
                                                                    <span>
                                                                        {{
                                                                            formatDate(itemAirline.flights.totalTime)
                                                                        }}
                                                                    </span>
                                                                </strong>
                                                                <br />
                                                                <img class="hidden-sm-and-down"
                                                                    :src="require('@/assets/icons/betweenlineStop.svg')"
                                                                    style="position: absolute; top: 50%; left: 50%;
                                                                     transform: translate(-50%, -50%); text-align: center;"
                                                                    width="180px" alt="" />
                                                                <img class="hidden-md-and-up"
                                                                    :src="require('@/assets/icons/betweenlineStop.svg')"
                                                                    style="position: absolute; top: 50%; left: 50%;
                                                                     transform: translate(-50%, -50%); text-align: center;"
                                                                    width="110px" alt="" />
                                                                <span style="font-size: .7rem"
                                                                    v-if="itemAirline.flights.flightDetails.length === 1">
                                                                    Direct
                                                                </span>
                                                                <span style="font-size: .7rem" v-else>
                                                                    {{
                                                                    itemAirline.flights.flightDetails.length
                                                                    -
                                                                    1
                                                                    }} Stops</span>
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-chip label color="#ccecf8" small class="text-profile">
                                                                {{
                                                                itemAirline.flights.flightDetails[itemAirline.flights.flightDetails.length
                                                                - 1].arrDisplayDateTime.displayTime
                                                                }}
                                                            </v-chip>
                                                            <br />
                                                            <span>
                                                                {{
                                                                itemAirline.flights.flightDetails[itemAirline.flights.flightDetails.length
                                                                - 1].arrCity.code
                                                                }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="6" sm="2" md="2" xs="6">
                                                    <span class="text-profile" id="pricing-airline">
                                                        {{ itemAirline.fare.currencyCode }}
                                                        {{ selectflight == 1 ? new
                                                        Intl.NumberFormat().format(itemAirline.fare.total) : '+0' }}
                                                        <br />
                                                        <span
                                                            @click="dialogFareRule.isActive = true, dialogFareRule.fareRule = itemAirline.fareRule"
                                                            style="cursor: pointer; text-decoration: none; color: #66c6e9; font-size: 0.7rem">
                                                            &#60; Fare Conditions
                                                        </span>
                                                    </span>
                                                </v-col>
                                                <v-col cols="6" sm="2" md="2" xs="6">
                                                    <span style="float: right">
                                                        <v-btn color="primary" style="color:white;"
                                                            @click="selectedflight(itemAirline.flights.bookingCode,
                                                            itemAirline.id, selectflight, itemAirline.agentService, itemAirline.mainAirline.code, itemAirline.flightBreak)">
                                                            Select
                                                        </v-btn>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <br />
                                            <hr />
                                        </v-card-text>
                                        <v-card-actions @click="ShowDetails(itemAirline.flights)"
                                            style="cursor: pointer;">
                                            <template v-if="itemAirline.flights.bShow != false">
                                                <v-row>
                                                    <v-col>
                                                        <v-chip class="ml-2">
                                                            <v-img :src="require('@/assets/icons/seat.svg')" />
                                                            <span class="ml-2"
                                                                v-if="svcClass != null && svcClass === 'Y'">
                                                                {{
                                                                    itemAirline.flights.flightDetails[0].cabin === 'Y' ||
                                                                    itemAirline.flights.flightDetails[0].cabin === 'W' ||
                                                                    itemAirline.flights.flightDetails[0].cabin === 'M' ?
                                                                    'Economy' : itemAirline.flights.flightDetails[0].cabin === 'C' ?
                                                                    'Business' : 'First'
                                                                }}
                                                            </span>
                                                        </v-chip>
                                                        <v-chip class="ml-2"
                                                            v-if="itemAirline.flights.baggageDetails != null">
                                                            <v-img :src="require('@/assets/icons/bagagge.svg')" />
                                                            <span class="ml-2">
                                                                <span
                                                                    v-if="itemAirline.flights.baggageDetails.quantityCode == 'N'">
                                                                    {{
                                                                    itemAirline.flights.baggageDetails.freeAllowance
                                                                    }}
                                                                    piece
                                                                </span>
                                                                <span
                                                                    v-if="itemAirline.flights.baggageDetails.quantityCode == 'W'">
                                                                    {{
                                                                    itemAirline.flights.baggageDetails.freeAllowance
                                                                    }}
                                                                    Kg
                                                                </span>
                                                            </span>
                                                        </v-chip>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <template v-else>
                                                <v-row style="width: 100%" class="text-center">
                                                    <v-col cols="5">
                                                        <h5>
                                                            {{
                                                            itemAirline.flights.flightDetails[0].depCity.name.split(',',
                                                            2)[1]
                                                            }}
                                                            ({{
                                                            itemAirline.flights.flightDetails[0].depCity.code
                                                            }})
                                                        </h5>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <div class="hidden-sm-and-dowm"></div>
                                                        <div class="hidden-md-and-up" style="height: 15px;">
                                                        </div>
                                                        <v-img :src="require('@/assets/icons/ArrowRight.svg')"
                                                            width="20" />
                                                    </v-col>
                                                    <v-col cols="5">
                                                        <h5>
                                                            {{
                                                            itemAirline.flights.flightDetails[0].arrCity.name.split(',',
                                                            2)[1]
                                                            }}
                                                            ({{
                                                            itemAirline.flights.flightDetails[itemAirline.flights.flightDetails.length
                                                            - 1].arrCity.code
                                                            }})
                                                        </h5>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <v-spacer></v-spacer>
                                            <span class="text-profile show-details" style="cursor: pointer;">
                                                {{
                                                itemAirline.flights.bShow != false ? "Show Details" :
                                                "Hide Details"
                                                }}
                                            </span>
                                            <img :src="require(itemAirline.flights.bShow != false ? '@/assets/icons/arrowdown.svg' : '@/assets/icons/arrowup.svg')"
                                                class="ml-1" width="25" style="cursor: pointer;" alt="" />
                                        </v-card-actions>
                                        <v-card-text v-show="!itemAirline.flights.bShow">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <span class="lineplane">
                                                        <v-row
                                                            v-for="(itemflightDetails, index) in itemAirline.flights.flightDetails"
                                                            :key="index">
                                                            <v-col cols="12" xs="6" md="2" class="text-center">
                                                                <strong>
                                                                    {{
                                                                    itemflightDetails.depDisplayDateTime.displayTime
                                                                    }}
                                                                </strong>
                                                                <br />
                                                                <span>
                                                                    {{
                                                                    itemflightDetails.depDisplayDateTime.shortDate
                                                                    }}
                                                                </span>
                                                                <v-row>
                                                                    <v-col cols="12" class="hidden-md-and-up">
                                                                        <h3>
                                                                            {{
                                                                            itemflightDetails.depCity.name.split(',',
                                                                            2)[1]
                                                                            }}</h3>
                                                                        {{
                                                                        itemflightDetails.depCity.name.split(',',
                                                                        2)[0]
                                                                        }}
                                                                        ({{
                                                                        itemflightDetails.depCity.code
                                                                        }})
                                                                        <div>
                                                                            <br />
                                                                            <div
                                                                                style="border: #EBEEF4 0.5px solid; border-radius: 8px;">
                                                                                <div style="padding: 10px;">
                                                                                    <v-row>
                                                                                        <v-col cols="12">
                                                                                            <v-row>
                                                                                                <v-col cols="4" sm="2">
                                                                                                    <v-img width="50"
                                                                                                        class="text-center mr-4"
                                                                                                        alt="user"
                                                                                                        :src="require('@/assets/logoAirline/square/' +
                                                                                                        itemflightDetails.airline.code + '.png')" />
                                                                                                </v-col>
                                                                                                <v-col cols="8" sm="10">
                                                                                                    <span
                                                                                                        style="font-size: .8rem; float: right;">
                                                                                                        {{
                                                                                                        itemflightDetails.airline.name
                                                                                                        }}
                                                                                                    </span>
                                                                                                </v-col>
                                                                                                <v-col cols="4" sm="2">
                                                                                                    <span
                                                                                                        style="font-size: .8rem; float: left;">
                                                                                                        Flight
                                                                                                        No.
                                                                                                    </span>
                                                                                                </v-col>
                                                                                                <v-col cols="8" sm="10">
                                                                                                    <span
                                                                                                        style="font-size: .8rem; float: right;">{{
                                                                                                        itemflightDetails.airline.code
                                                                                                        +
                                                                                                        '-'
                                                                                                        +
                                                                                                        itemflightDetails.flightNumber
                                                                                                        }}</span>
                                                                                                </v-col>
                                                                                                <v-col cols="4" sm="2"
                                                                                                    style="font-size: .8rem; float: left;">
                                                                                                    <span
                                                                                                        style="font-size: .8rem;">Equipment
                                                                                                        Type</span>
                                                                                                </v-col>
                                                                                                <v-col cols="8" sm="10">
                                                                                                    <span
                                                                                                        style="font-size: .8rem; padding-top: 2px; float: right;">
                                                                                                        {{
                                                                                                        !itemAirline.flights.bShow
                                                                                                        ?
                                                                                                        aircraftData(itemflightDetails.equipmentType)
                                                                                                        : ''
                                                                                                        }}
                                                                                                    </span>
                                                                                                </v-col>
                                                                                                <v-col cols="4" sm="2"
                                                                                                    v-if="itemAirline.flights.baggageDetails != null">
                                                                                                    <span
                                                                                                        style="font-size: .8rem; float: left;">Baggage</span>
                                                                                                </v-col>
                                                                                                <v-col cols="8" sm="10"
                                                                                                    v-if="itemAirline.flights.baggageDetails != null">
                                                                                                    <span
                                                                                                        style="font-size: .8rem; float: right;">
                                                                                                        <span
                                                                                                            v-if="itemAirline.flights.baggageDetails.quantityCode == 'N'">
                                                                                                            {{
                                                                                                            itemAirline.flights.baggageDetails.freeAllowance
                                                                                                            }}
                                                                                                            piece
                                                                                                        </span>
                                                                                                        <span
                                                                                                            v-if="itemAirline.flights.baggageDetails.quantityCode == 'W'">
                                                                                                            {{
                                                                                                            itemAirline.flights.baggageDetails.freeAllowance
                                                                                                            }}
                                                                                                            Kg
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <br />
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                        </div>
                                                                        <div v-if="itemAirline.flights.baggageDetails == null"
                                                                            style="height: 45px;"></div>
                                                                        <h3>
                                                                            {{
                                                                            itemflightDetails.arrCity.name.split(',',
                                                                            2)[1]
                                                                            }}
                                                                        </h3>
                                                                        {{
                                                                        itemflightDetails.arrCity.name.split(',',
                                                                        2)[0]
                                                                        }}
                                                                        ({{
                                                                        itemflightDetails.arrCity.code
                                                                        }})
                                                                        <br />
                                                                    </v-col>
                                                                </v-row>
                                                                <div style="height: 223px;" class="hidden-sm-and-down">
                                                                </div>
                                                                <strong>
                                                                    {{
                                                                    itemflightDetails.arrDisplayDateTime.displayTime
                                                                    }}
                                                                </strong>
                                                                <br />
                                                                <span>
                                                                    {{
                                                                    itemflightDetails.arrDisplayDateTime.shortDate
                                                                    }}
                                                                </span>
                                                            </v-col>
                                                            <v-col class="hidden-sm-and-down" cols="12" xs="3" md="1">
                                                                <v-img class="hidden-sm-and-down"
                                                                    :src="require('@/assets/icons/LineairPlane.jpg')"
                                                                    width="18">
                                                                </v-img>
                                                            </v-col>
                                                            <v-col class="hidden-sm-and-down" cols="5"
                                                                style="position: relative;">
                                                                <h3>
                                                                    {{
                                                                    itemflightDetails.depCity.name.split(',',
                                                                    2)[1]
                                                                    }}</h3>
                                                                {{
                                                                itemflightDetails.depCity.name.split(',',
                                                                2)[0]
                                                                }} ({{
                                                                itemflightDetails.depCity.code
                                                                }})
                                                                <div>
                                                                    <br />
                                                                    <div
                                                                        style="border: #EBEEF4 0.5px solid; border-radius: 8px;">
                                                                        <div style="padding: 10px;">
                                                                            <v-row>
                                                                                <v-col cols="12">
                                                                                    <v-row>
                                                                                        <v-col cols="2">
                                                                                            <v-img width="40" alt="user"
                                                                                                :src="require('@/assets/logoAirline/square/' +
                                                                                                itemflightDetails.airline.code + '.png')" />
                                                                                        </v-col>
                                                                                        <v-col cols="6">
                                                                                            <span
                                                                                                style="font-size: .8rem;">
                                                                                                {{
                                                                                                itemflightDetails.airline.name
                                                                                                }}
                                                                                            </span>
                                                                                            <br />
                                                                                            <span
                                                                                                style="font-size: .8rem;">
                                                                                                Flight
                                                                                                No.
                                                                                            </span>
                                                                                            <br />
                                                                                            <span
                                                                                                style="font-size: .8rem;">Equipment
                                                                                                Type</span>
                                                                                        </v-col>
                                                                                        <v-col cols="4">
                                                                                            <span
                                                                                                style="font-size: .8rem; padding: 5px; float: right;"></span>
                                                                                            <br />
                                                                                            <span
                                                                                                style="font-size: .8rem; float: right;">{{
                                                                                                itemflightDetails.airline.code
                                                                                                + ' ' +
                                                                                                itemflightDetails.flightNumber
                                                                                                }}</span>
                                                                                            <br />
                                                                                            <span
                                                                                                style="font-size: .7rem; padding-top: 2px; float: right;">
                                                                                                {{!itemAirline.flights.bShow
                                                                                                ?
                                                                                                aircraftData(itemflightDetails.equipmentType)
                                                                                                : '' }}
                                                                                            </span>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-col>
                                                                                <v-col cols="2"
                                                                                    v-if="itemAirline.flights.baggageDetails != null">
                                                                                    <v-img alt="user"
                                                                                        src="../../assets/icons/bagagge.svg"
                                                                                        style="margin: 8px;"
                                                                                        width="25" />
                                                                                </v-col>
                                                                                <v-col cols="5"
                                                                                    v-if="itemAirline.flights.baggageDetails != null">
                                                                                    <div style="height: 8px;">
                                                                                    </div>
                                                                                    <span>Baggage</span>
                                                                                </v-col>
                                                                                <v-col cols="5"
                                                                                    v-if="itemAirline.flights.baggageDetails != null">
                                                                                    <span
                                                                                        style="font-size: 0.9rem; float: right; margin-top: 10px;">
                                                                                        <span
                                                                                            v-if="itemAirline.flights.baggageDetails.quantityCode == 'N'">
                                                                                            {{
                                                                                            itemAirline.flights.baggageDetails.freeAllowance
                                                                                            }}
                                                                                            piece
                                                                                        </span>
                                                                                        <span
                                                                                            v-if="itemAirline.flights.baggageDetails.quantityCode == 'W'">
                                                                                            {{
                                                                                            itemAirline.flights.baggageDetails.freeAllowance
                                                                                            }}
                                                                                            Kg
                                                                                        </span>
                                                                                    </span>
                                                                                    <br />
                                                                                </v-col>
                                                                            </v-row>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                </div>
                                                                <div v-if="itemAirline.flights.baggageDetails == null"
                                                                    style="height: 45px;"></div>
                                                                <h3>
                                                                    {{
                                                                    itemflightDetails.arrCity.name.split(',',
                                                                    2)[1]
                                                                    }}
                                                                </h3>
                                                                {{
                                                                itemflightDetails.arrCity.name.split(',',
                                                                2)[0]
                                                                }} ({{itemflightDetails.arrCity.code}})
                                                                <br />
                                                            </v-col>
                                                            <v-col cols="12"
                                                                v-if="itemAirline.flights.flightDetails.length - 1 !== index">
                                                                <h4 class="text-center">
                                                                    <span>
                                                                        Flight Break:
                                                                    </span>
                                                                    {{ timespanFlightDetail_v2(itemAirline, index) }}
                                                                </h4>
                                                            </v-col>
                                                        </v-row>
                                                    </span>
                                                </v-col>
                                                <v-col cols="9" md="6">
                                                    <br />
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!lowCostailneDataloading">
                            &nbsp;<span
                                style="padding: 5px 30px 0px 30px; background-color: #FFCB0B; color: white; border-radius: 8px 8px 0px 0px;">Nok
                                Air</span>
                            <div style="border: solid 2px #FFCB0B; padding: 20px; border-radius: 10px;">
                                Emtpy Data
                            </div>
                            <br />
                        </div>
                        <div v-else>
                            &nbsp;<span
                                style="padding: 5px 30px 0px 30px; background-color: #FFCB0B; color: white; border-radius: 8px 8px 0px 0px;">Nok
                                Air</span>
                            <div class="overflow-x-auto text-center"
                                style="height: 150px; border: solid 2px #FFCB0B; border-radius: 10px;">
                                <div style="height: 55px"></div>
                                <v-progress-circular :size="40" indeterminate color="warning"
                                    :width="7"></v-progress-circular>
                            </div>
                            <br />
                        </div>
                        <!--End Nok Air Display-->

                    </template>
                    <template v-else>
                        <div v-if="(error.Iserror != true)">
                            <div style="height: 80px"></div>
                            <div class="text-center">
                                <v-progress-circular :size="70" indeterminate color="primary"
                                    :width="7"></v-progress-circular>
                            </div>
                            <div style="height: 80px"></div>
                        </div>
                        <div v-else-if="(airlines === null)">
                            Not Found
                        </div>
                        <div v-else-if="(airlines == null)">
                            {{ error.errorMessage }}
                        </div>
                    </template>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <v-dialog v-model="errDialog" width="500">
        <v-card>
            <v-card-title>
                Error
            </v-card-title>
            <v-card-text>
                {{ error.errorMessage }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="errDialog = false">Cancel</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
    name: "airline",
    props: {
        selectflight: 0,
        flightRequest: Object,
        Tramsit: String,
        selectedCarries: Array,
        svcClass: String,
        flightTime: Object
    },
    data() {
        return {
            notFoundFlightMessage: '',
            firstRender: true,
            previousAirlines: [],
            flightHistory: [],
            errDialog: false,
            isActive: false,
            tab: null,
            dialogFareRule: {
                isActive: false,
                fareRule: [],
            },
            lowcostairlines: null,
            airlines: null,
            selectedLowcost: false,
            selectedFullcarrier: false,
            ailneDataloading: true,
            lowCostailneDataloading: true,
            error:
            {
                Iserror: false,
                errorMessage: null,
            },

            search: null,
            processingflight: false,
            selectreturnflight: [],
            selectreturnLowcostflight: [],
            sortLowCostflightAirline: {
                NokAir: [],
                AirAsia: []
            },
            sortflightAirline: [],
            airline: {
                depflight: null,
                returnflight: null,
                fare: null,
                fareRule: null,
                agentService: null
            },

            filterairlines: {
                any: [],
                non: [],
                stp1: [],
                stp2: []
            },
            sortflight: [],
            aircraft: null,
            formatYMDhour: "YYYY-MM-DD HH:mm:ss"
        }
    },
    watch: {
        svcClass(){
            if(!this.firstRender){
                this.flightRequest.newreload = true
                this.ailneDataloading = true
                this.$emit('ailneDataloading', true)
                this.callAPIAirline()
                sessionStorage.svcClass = this.svcClass
            }
        },
        selectflight() {
            this.ailneDataloading = true
            setTimeout(() => {
                if (this.flightRequest.tripType === 'O' && this.selectflight === 2) {
                    this.ailneDataloading = true
                } else if(this.selectflight === 1){
                    this.selectedLowcost = false
                    this.selectedFullcarrier = false
                    this.ailneDataloading = false
                }else{
                    this.ailneDataloading = false
                }
            }, 2000)
        },
        ailneDataloading() {

        },
        Tramsit() {
            this.ailneDataloading = true
            this.modifytramsit(this.Tramsit, this.selectedCarries)
        },
        selectedCarries() {
            this.ailneDataloading = true
            this.modifytramsit(this.Tramsit, this.selectedCarries)
        },
        'flightTime.dep'() {
            this.ailneDataloading = true
            this.modifytramsit(this.Tramsit, this.selectedCarries)

        },
        'flightTime.arr'() {
            this.ailneDataloading = true
            this.modifytramsit(this.Tramsit, this.selectedCarries)
        },
    },
    methods: {
        formatDate(val){
            let res = ''
            if(val){
                res = `${val.slice(0, 2)}h ${val.slice(2, 4)}m`
            }
            return res
        },
        showAirlineLogo(val){
            let result
            try{
               if(require(`@/assets/logoAirline/square/${val}.png`)){
                   result = val
               }
            }catch (err){
                result = 'SL'
            }
            return result
        },
        aircraftData(val) {
            let result = ""
            if (val) {
                if(this.aircraft.length > 0){
                    const newFilter = this.aircraft.filter(data => data.Code === val)
                    if(newFilter?.length > 0){
                        result = newFilter[0].name
                    }
                }
            }
            return result
        },
        timespanFlight_v2(depflight, arrflight) {
            const dep = moment(depflight).format(this.formatYMDhour)
            const arr = moment(arrflight).format(this.formatYMDhour)
            const cal = parseInt(moment(arr).diff(moment(dep), 'minutes', true))
            const result = this.toHoursAndMinutes(cal)
            return `${result.hours}h ${result.minutes}m`
        },
        timespanFlightDetail_v2(itemAirline, index) {
            let depflight = `${itemAirline.flights.flightDetails[index].arrDisplayDateTime.year}-${itemAirline.flights.flightDetails[index].arrDisplayDateTime.month}`
            let arrflight = `${itemAirline.flights.flightDetails[index + 1].depDisplayDateTime.year}-${itemAirline.flights.flightDetails[index + 1].depDisplayDateTime.month}`
            depflight += '-' + itemAirline.flights.flightDetails[index].arrDisplayDateTime.date
            depflight += ' ' + itemAirline.flights.flightDetails[index].arrDisplayDateTime.displayTime.split('(',  1)
            arrflight += '-' + itemAirline.flights.flightDetails[index + 1].depDisplayDateTime.date
            arrflight += ' ' + itemAirline.flights.flightDetails[index + 1].depDisplayDateTime.displayTime.split('(', 1)
            const dep = moment(depflight).format(this.formatYMDhour)
            const arr = moment(arrflight).format(this.formatYMDhour)
            const cal = parseInt(moment(arr).diff(moment(dep), 'minutes', true))
            const result = this.toHoursAndMinutes(cal)
            itemAirline.flightBreak = `${result.hours}:${result.minutes}`
            return `${result.hours}h ${result.minutes}m`
        },
        ShowDetails(elm) {
            elm.bShow = !elm.bShow;
        },
        async selectedflight(bookingCode, id, type, agentService, airlineName, flightBreak) {
            if (this.airline.agentService == null) {
                this.airline.agentService = agentService
            }
            if (this.flightRequest.tripType !== 'O') {
                this.tripTypeStatusY(bookingCode, id, type, airlineName, flightBreak)
            } else {
                await this.tripTypeStatusO(bookingCode, id, type, airlineName, flightBreak)
            }

            window.scrollTo(0, 0)
        },
        async tripTypeStatusO(bookingCode, id, type, airlineName, flightBreak) {
            let findairline = {}
            const ticketPricing = {
                pgSearchOID: null,
                tripType: null,
                origin: null,
                destination: null,
                adult: 0,
                child: 0,
                infant: 0,
                svc_class: "Y",
                S1: null,
                S2: null,
                S1_id: null,
                S2_id: null,
                S1_refNumber: null,
                S2_refNumber: null,
                S1_recommendation_number: null,
                S2_recommendation_number: null,
                bMultiTicket_Fare: false,
                languageCode: "en"
            }

            if(airlineName === "DD"){
                findairline = this.lowcostairlines.flights.filter(elm => elm.id === id)[0]
                this.lowcostairlines.flights.forEach(elmAirline => {
                    let dataBook = {}
                    dataBook = elmAirline.Flight_SegRef1.filter(elm => elm.bookingCode === bookingCode)[0]
                    if (dataBook !== undefined) {
                        dataBook.flightBreak = flightBreak
                        this.airline.depflight = dataBook
                    }
                });
            }else{
                findairline = this.airlines.flights.filter(elm => elm.id === id)[0]
                this.airlines.flights.forEach(elmAirline => {
                    let dataBook = {}
                    dataBook = elmAirline.Flight_SegRef1.filter(elm => elm.bookingCode === bookingCode)[0]
                    if (dataBook !== undefined) {
                        dataBook.flightBreak = flightBreak
                        this.airline.depflight = dataBook
                    }
                });
            }
            this.$emit('depflight', this.airline.depflight)
            if (this.airline.depflight != null) {
                const flightRequest = this.flightRequest
                this.airline.fare = findairline.fare
                this.airline.fareRule = findairline.fareRule
                sessionStorage.airline = JSON.stringify(this.airline)

                ticketPricing.pgSearchOID = this.airlines.pgSearchOID
                ticketPricing.origin = flightRequest.originCode
                ticketPricing.destination = flightRequest.destinationCode
                ticketPricing.adult = '1'
                ticketPricing.child = flightRequest.child
                ticketPricing.infant = flightRequest.infant
                ticketPricing.S1 = this.airline.depflight.bookingCode
                ticketPricing.S2 = null
                ticketPricing.svc_class = this.airline.depflight.flightDetails[0].cabin
                ticketPricing.S1_id = findairline.id
                ticketPricing.S2_id = null
                ticketPricing.S1_refNumber = this.airline.depflight.refNumber
                ticketPricing.S2_refNumber = null
                ticketPricing.S1_recommendation_number = this.airline.depflight.refNumber
                ticketPricing.S2_recommendation_number = null
                ticketPricing.tripType = "O"
                ticketPricing.Source = airlineName === "DD" ? "N" : null
                const req = ticketPricing
                this.$emit('selectflight', 2)
                this.ailneDataloading = true

                sessionStorage.ticketPricingReq = JSON.stringify(ticketPricing)
                this.ailneDataloading = true
                this.FlightMultiTicketPricing(req, airlineName)
            }
        },
        async tripTypeStatusY(bookingCode, id, type, airlineName, flightBreak) {
            if (type === 1) { // Trip Type == 1
                this.tripYIsOne(bookingCode, id, airlineName, flightBreak)
            } else if (type === 2) { // Trip Type == 2
                this.tripYIsTwo(bookingCode, id, airlineName, flightBreak)
            }
        },
        async tripYIsOne(bookingCode, id, airlineName, flightBreak){
            if (airlineName === 'DD') { // Nokair Trip Type 1
                const findairlineInfo = await this.sortLowCostflightAirline.NokAir.filter(elm => elm.flights.bookingCode === bookingCode)
                if (findairlineInfo.length > 0) {
                    this.airline.depflight = await findairlineInfo[0].flights
                }
                this.selectedLowcost = true
            } else { // Amadeus Trip Type 1
                let findairline = {}
                this.selectedFullcarrier = true
                findairline = this.airlines.flights.filter(elm => elm.id === id)[0]
                this.airlines.flights.forEach(elmAirline => {
                    let dataBook = {}
                    dataBook = elmAirline.Flight_SegRef1.filter(elm => elm.bookingCode === bookingCode)[0]
                    if (dataBook !== undefined) {
                        dataBook.flightBreak = flightBreak
                        this.airline.depflight = dataBook
                    }
                });
                findairline.Flight_SegRef2.sort((a, b) => {
                    return a.flightDetails[0].depDisplayDateTime.time - b.flightDetails[0].depDisplayDateTime.time
                })
                findairline.Flight_SegRef2.forEach(elm => {
                    const modifyFlight = {
                        fare: null,
                        fareRule: null,
                        id: null,
                        isMultiTicket: null,
                        mainAirline: null,
                        maxDepTime: null,
                        maxRetTime: null,
                        type: null,
                        addOnPrice: null,
                        flights: [],
                    }
                    modifyFlight.fare = findairline.fare
                    modifyFlight.fareRule = findairline.fareRule
                    modifyFlight.id = findairline.id
                    modifyFlight.isMultiTicket = findairline.isMultiTicket
                    modifyFlight.mainAirline = findairline.mainAirline
                    modifyFlight.maxDepTime = findairline.maxDepTime
                    modifyFlight.maxRetTime = findairline.maxRetTime
                    modifyFlight.type = findairline.type
                    modifyFlight.addOnPrice = findairline.addOnPrice
                    modifyFlight.flights = elm
                    this.selectreturnflight = []
                    this.selectreturnflight.push(modifyFlight)
                })
            }
            this.$emit('depflight', this.airline.depflight)
            this.$emit('selectflight', 2)
        },
        async tripYIsTwo(bookingCode, id, airlineName, flightBreak){
            let findairline = {}
            const ticketPricing = {
                pgSearchOID: null,
                tripType: null,
                origin: null,
                destination: null,
                adult: 0,
                child: 0,
                infant: 0,
                svc_class: "Y",
                S1: null,
                S2: null,
                S1_id: null,
                S2_id: null,
                S1_refNumber: null,
                S2_refNumber: null,
                S1_recommendation_number: null,
                S2_recommendation_number: null,
                bMultiTicket_Fare: false,
                languageCode: "en"
            }
            if (airlineName === "DD") { // Nokair Trip Type 2
                findairline = this.selectreturnLowcostflight.filter(elm => elm.flights.bookingCode === bookingCode)[0]
                if (findairline) {
                    this.airline.returnflight = findairline.flights
                }
            } else { // Amadeus Trip Type 2
                findairline = this.airlines.flights.filter(elm => elm.id === id)[0]
                this.airlines.flights.forEach(elmAirline => {
                    let dataBook = {}
                    dataBook = elmAirline.Flight_SegRef2.filter(elm => elm.bookingCode === bookingCode)[0]
                    if (dataBook !== undefined) {
                        console.log(dataBook);
                        dataBook.flightBreak = flightBreak
                        this.airline.returnflight = dataBook
                    }
                });
            }

            if (this.airline.depflight != null && this.airline.returnflight != null) {
                const flightRequest = this.flightRequest
                this.airline.fare = findairline.fare
                this.airline.fareRule = findairline.fareRule
                sessionStorage.airline = JSON.stringify(this.airline)
                ticketPricing.pgSearchOID = this.airlines.pgSearchOID
                ticketPricing.origin = flightRequest.originCode
                ticketPricing.destination = flightRequest.destinationCode
                ticketPricing.adult = flightRequest.adult
                ticketPricing.child = flightRequest.child
                ticketPricing.infant = flightRequest.infant
                ticketPricing.S1 = this.airline.depflight.bookingCode
                ticketPricing.S2 = this.airline.returnflight.bookingCode
                ticketPricing.S1_id = findairline.id
                ticketPricing.S2_id = findairline.id
                ticketPricing.S1_flightBreak = this.airline.depflight.flightBreak ? this.airline.depflight.flightBreak : 0
                ticketPricing.S2_flightBreak = this.airline.returnflight.flightBreak ? this.airline.returnflight.flightBreak : 0
                ticketPricing.svc_class = this.airline.depflight.flightDetails[0].cabin
                ticketPricing.S1_refNumber = this.airline.depflight.refNumber
                ticketPricing.S2_refNumber = this.airline.returnflight.refNumber
                ticketPricing.S1_recommendation_number = this.airline.depflight.refNumber
                ticketPricing.S2_recommendation_number = this.airline.returnflight.refNumber
                ticketPricing.Source = airlineName === "DD" ? "N" : null
                ticketPricing.tripType = 'R'

                sessionStorage.ticketPricingReq = JSON.stringify(ticketPricing)
                const req = ticketPricing
                this.ailneDataloading = true
                this.FlightMultiTicketPricing(req, airlineName)
                this.$emit('returnflight', this.airline.returnflight)
            }
        },
        FlightMultiTicketPricing(req, airlineName) {
            if(airlineName === "DD"){ //Nok Air
                axios.post(`${this.$store.state.API_PATH}NokAirFlightMultiTicketPricing`, req, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(async res => {
                    res.data.IsBooking = false
                    await this.ModifyMultiTicketPricingReq(res)
                }).catch(error => {
                    console.log(error)
                });
            }else{
                axios.post(`${this.$store.state.API_PATH}FlightMultiTicketPricing`, req, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(async res => {
                    if(!res.data.isError){
                        res.data.IsBooking = false
                        await this.ModifyMultiTicketPricingReq(res)
                    }else{
                        this.errDialog = true
                        this.error.errorMessage = res.data.ErrorMessage
                    }
                }).catch(error => {
                    console.log(error)
                    this.error.errorMessage = error.message
                    this.errDialog = true
                });
            }
        },
        async ModifyMultiTicketPricingReq(res){
            if (!res.data.isError) {
                res.data.depFlight[0].bookingCode = this.airline.depflight.bookingCode
                if(this.airline.returnflight){
                    res.data.retFlight[0].bookingCode = this.airline.returnflight.bookingCode
                }
                sessionStorage.ticketPricing = JSON.stringify(res.data)
                const Modifyreq = await {
                    AirFare: res.data,
                    Travelers: await this.ModifyTicketreq(JSON.parse(sessionStorage.travelers))
                }
                if (Modifyreq) {
                    axios.post(this.$store.state.API_PATH + 'ModifyMultiTicketPricing', Modifyreq, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    }).then(Modifiedres => {
                        sessionStorage.ticketPricingModified = JSON.stringify(Modifiedres.data)
                        setTimeout(() => {
                            location.href = 'tripSummary'
                        }, 1000);
                    })
                }
            } else {
                this.error.errorMessage = 'Error'
                this.errDialog = true
            }
        },
        async ModifyTicketreq(val) {
            const result = []
            await val.forEach(async data => {
                const modify = data
                data.SpecialRequest = data.DefaultSpecialRequest
                data.SpecialRequest.baggageRemark = data.Baggage

                modify.TravelerInformation = await {
                    emp_ID: data.emp_ID,
                    PNR: data.PNR,
                    fullname: data.fullname,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    title: data.title,
                    mobile: data.mobile,
                    position: data.position,
                    group: data.group,
                    orgunit: data.orgunit,
                    div: data.div,
                    dep: data.dep,
                    section: data.section,
                    status: data.status,
                    budgetHolderApprover: data.budgetHolderApprover,
                    lineApprovalD: data.lineApprovalD,
                    BTTId: data.BTTId,
                    CreateBy: data.CreateBy,
                    Type: data.Type,
                    birthdate: data.birthdate,
                    age: data.age,
                    ageNumber: data.ageNumber ? parseInt(data.ageNumber) : 0,
                    travelExpens: data.travelExpens,
                    lineApprover: data.lineApprover
                }

                if(data.MyTraveler && data.MyTraveler.length > 0){
                    await data.MyTraveler.forEach(async x => {
                        const MyTravelermodify = x
                        x.SpecialRequest = x.DefaultSpecialRequest
                        MyTravelermodify.TravelerInformation = await {
                            emp_ID: x.emp_ID,
                            PNR: x.PNR,
                            fullname: x.fullname,
                            firstname: x.firstname,
                            lastname: x.lastname,
                            email: x.email,
                            title: x.title,
                            mobile: x.mobile,
                            position: x.position,
                            group: x.group,
                            orgunit: x.orgunit,
                            div: x.div,
                            dep: x.dep,
                            section: x.section,
                            status: x.status,
                            budgetHolderApprover: x.budgetHolderApprover,
                            lineApprovalD: x.lineApprovalD,
                            BTTId: x.BTTId,
                            CreateBy: x.CreateBy,
                            Type: x.Type,
                            birthdate: x.birthdate,
                            age: x.age,
                            ageNumber: x.ageNumber ? parseInt(data.ageNumber) : 0,
                            travelExpens: x.travelExpens,
                            lineApprover: x.lineApprover
                        }

                        result.push(await MyTravelermodify)
                    })
                }

                result.push(await modify)
            })
            return result
        },
        timespanFlight(timespan) {
            let result = 0
            const itemtsp = []
            itemtsp.push(timespan);
            itemtsp.forEach(tsp => {
                tsp.forEach(time => {
                    const dep = moment(time.departureDateTime).format(this.formatYMDhour)
                    const arr = moment(time.arrivalDateTime).format(this.formatYMDhour)
                    const cal = parseInt(moment(arr).diff(moment(dep), 'minutes', true))
                    result += cal
                });
            });
            result = this.toHoursAndMinutes(result)
            return `${result.hours}h ${result.minutes}m`
        },
        toHoursAndMinutes(totalMinutes) {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return { hours, minutes };
        },
        async modifyFlight(airlines, airline) {
            this.filterairlines = {
                any: [],
                non: [],
                stp1: [],
                stp2: []
            }
            this.sortflightAirline = []
            this.processingflight = true
            await airlines.flights.forEach(async elmAirline => {
                if (elmAirline.Flight_SegRef1) {
                    let modifyFlight = {
                        fare: null,
                        fareRule: null,
                        id: null,
                        isMultiTicket: null,
                        mainAirline: null,
                        maxDepTime: null,
                        maxRetTime: null,
                        type: null,
                        addOnPrice: null,
                        flights: null,
                        agentService: null,
                    }
                    await elmAirline.Flight_SegRef1.forEach(elmFlight => {
                        modifyFlight.fare = elmAirline.fare
                        modifyFlight.fareRule = elmAirline.fareRule
                        modifyFlight.id = elmAirline.id
                        modifyFlight.isMultiTicket = elmAirline.isMultiTicket
                        modifyFlight.mainAirline = elmAirline.mainAirline
                        modifyFlight.maxDepTime = elmAirline.maxDepTime
                        modifyFlight.maxRetTime = elmAirline.maxRetTime
                        modifyFlight.type = elmAirline.type
                        modifyFlight.addOnPrice = elmAirline.addOnPrice
                        modifyFlight.agentService = elmAirline.fare.AgentsService
                        modifyFlight.flights = elmFlight
                        airline !== 'DD' ? this.sortflightAirline.push(modifyFlight) : this.sortLowCostflightAirline.NokAir.unshift(modifyFlight)
                        modifyFlight = {}
                    });

                }
                if (airline === 'DD' && elmAirline.Flight_SegRef2 !== undefined) {
                    const FlightSegRefInfo = []
                    FlightSegRefInfo.push(...elmAirline.Flight_SegRef2)
                    await FlightSegRefInfo.forEach(async elm => {
                        const modifyFlight = {
                            fare: null,
                            fareRule: null,
                            id: null,
                            isMultiTicket: null,
                            mainAirline: null,
                            maxDepTime: null,
                            maxRetTime: null,
                            type: null,
                            addOnPrice: null,
                            flights: [],
                        }
                        modifyFlight.fare = elmAirline.fare
                        modifyFlight.fareRule = elmAirline.fareRule
                        modifyFlight.id = elmAirline.id
                        modifyFlight.isMultiTicket = elmAirline.isMultiTicket
                        modifyFlight.mainAirline = elmAirline.mainAirline
                        // modifyFlight.maxDepTime = findairline.maxDepTime
                        // modifyFlight.maxRetTime = findairline.maxRetTime
                        // modifyFlight.type = findairline.type
                        // modifyFlight.addOnPrice = findairline.addOnPrice
                        // elm.flights.forEach(async flight => {
                        modifyFlight.flights = elm
                        // })
                        await this.selectreturnLowcostflight.push(modifyFlight)
                    })
                }
            })

            let dir = []
            let stp1 = []
            let stp2 = []

            function sortData(data){
                return data.sort((a, b) => a.flights.flightDetails[0].depDisplayDateTime.time - b.flights.flightDetails[0].depDisplayDateTime.time)
            }

            if (airline !== 'DD') {
                dir = await this.sortflightAirline.filter(sflight => sflight.flights.flightDetails.length === 1)
                stp1 = await this.sortflightAirline.filter(sflight => sflight.flights.flightDetails.length === 2)
                stp2 = await this.sortflightAirline.filter(sflight => sflight.flights.flightDetails.length > 2)
                sortData(dir)
                sortData(stp1)
                sortData(stp2)
                setTimeout(() => {
                    this.sortflightAirline = []
                    this.sortflightAirline.push(...dir, ...stp1, ...stp2)
                    this.filterairlines.any.push(...dir, ...stp1, ...stp2)
                    this.filterairlines.non.push(...dir)
                    this.filterairlines.stp1.push(...stp1)
                    this.filterairlines.stp2.push(...stp2)
                    this.processingflight = false
                        this.previousAirlines.push(...this.sortflightAirline.filter(a => this.flightHistory.filter(x => x.Seq === '1' &&
                        a.flights.flightDetails.filter(b => b.flightNumber === x.FlightNumber
                        && b.airline.code === x.Airline && b.airline.code === x.Airline
                        && moment(b.departureDateTime).format(this.formatYMDhour) === moment(x.DepartureDateTime).format(this.formatYMDhour)
                        && moment(b.arrivalDateTime).format(this.formatYMDhour) === moment(x.ArrivalDateTime).format(this.formatYMDhour)).length > 0).length > 0))
                     }, 3000)
            } else {
                dir = await this.sortLowCostflightAirline.NokAir.filter(sflight => sflight.flights.flightDetails.length === 1
                && sflight.mainAirline !== undefined && sflight.mainAirline.code === 'DD')
                stp1 = await this.sortLowCostflightAirline.NokAir.filter(sflight => sflight.flights.flightDetails.length === 2
                && sflight.mainAirline !== undefined && sflight.mainAirline.code === 'DD')
                stp2 = await this.sortLowCostflightAirline.NokAir.filter(sflight => sflight.flights.flightDetails.length > 2 &&
                sflight.mainAirline !== undefined && sflight.mainAirline.code === 'DD')
                sortData(dir)
                sortData(stp1)
                sortData(stp2)
                setTimeout(() => {
                    this.sortLowCostflightAirline.NokAir = []
                    this.sortLowCostflightAirline.NokAir.push(...dir, ...stp1, ...stp2)
                }, 100)
            }
        },
        async modifytramsit(ptramsit, carries) {
            if (ptramsit === null) {
                ptramsit = 'Any'
            }
            if (ptramsit === 'Any') {
                this.sortflightAirline = await this.modifyCarries(carries, this.filterairlines.any)
            } else if (ptramsit === 'Nonstop') {
                this.sortflightAirline = await this.modifyCarries(carries, this.filterairlines.non)

            } else if (ptramsit === '1stop') {
                this.sortflightAirline = await this.modifyCarries(carries, this.filterairlines.stp1)

            } else if (ptramsit === '2stop') {
                this.sortflightAirline = await this.modifyCarries(carries, this.filterairlines.stp2)
            }
            setTimeout(async () => {
                this.sortflightAirline = await this.modifyFlightTime()
            }, 1500);
        },
        async modifyFlightTime() {
            const setting = JSON.parse(sessionStorage.searchflightReq)
            let result = []
            if (!setting.newreload) {
                this.ailneDataloading = false
            }

            if (this.sortflightAirline) {
                result = await this.sortflightAirline.filter(x => moment(x.flights.flightDetails[0].departureDateTime).format('H') >= this.flightTime.dep[0] &&
                    moment(x.flights.flightDetails[0].departureDateTime).format('H') <= this.flightTime.dep[1] &&
                    moment(x.flights.flightDetails[0].arrivalDateTime).format('H') >= this.flightTime.arr[0] &&
                    moment(x.flights.flightDetails[0].arrivalDateTime).format('H') <= this.flightTime.arr[1]
                )
            }

            return result
        },
        modifyCarries(carries, airlines) {
            this.sortflightAirline = airlines
            return this.sortflightAirline.filter(x => carries.findIndex(y => y === x.mainAirline.name) > -1)
        },
        async callAPIAirline() {
            this.airlines = null
            this.lowcostairlines = null
            const setting = JSON.parse(sessionStorage.searchflightReq)
            sessionStorage.removeItem('searchflightReq')
            axios.post(this.$store.state.API_PATH + 'FlightSearch/NokAirSearchFlights', {
                pgSearchOID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                languageCode: "EN",
                tripType: this.flightRequest.tripType,
                originCode: this.flightRequest.originCode,
                destinationCode: this.flightRequest.destinationCode,
                airline: [
                    "DD"
                ],
                svcClass: this.svcClass,
                directFlight: true,
                departDate: this.flightRequest.departDate,
                returnDate: this.flightRequest.returnDate,
                adult: this.flightRequest.adult,
                child: this.flightRequest.child,
                infant: this.flightRequest.infant
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async res => {
                this.lowcostairlines = JSON.parse(res.data.Result)
                if (this.lowcostairlines) {
                    sessionStorage.lowcostairlines = JSON.stringify(this.lowcostairlines)
                    await this.modifyFlight(this.lowcostairlines, 'DD')
                }
                this.lowCostailneDataloading = false
                setTimeout(() => {
                    this.$emit('ailneDataloading', this.ailneDataloading)
                }, 500)
            }).catch(err => {
                console.log(err)
            })
            const req = JSON.parse(JSON.stringify(this.flightRequest))
            req.svcClass = this.svcClass
            axios.post(this.$store.state.API_PATH + 'FlightSearch/Search', req, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async res => {
                this.airlines = res.data
                if(this.airlines?.flights && this.airlines.flights.length > 0){
                    if (this.airlines.flights != null) {
                        this.notFoundFlightMessage = ""
                        sessionStorage.airlines = JSON.stringify(this.airlines)
                        await this.modifyFlight(this.airlines)
                        this.$emit('carries', this.airlines.filter)
                    }
                    this.ailneDataloading = false
                    setTimeout(() => {
                        this.$emit('ailneDataloading', false)
                    }, 500)
                }else{
                    if(this.svcClass === "F"){
                        req.svcClass = "C"
                    }else if(this.svcClass === "C"){
                        req.svcClass = "Y"
                    }else if(this.svcClass === "Y"){
                        this.airlines = res.data
                        this.ailneDataloading = false
                        setTimeout(() => {
                            this.$emit('ailneDataloading', false)
                        }, 500)
                    }
                    this.notFoundFlightMessage = `${funcFullClass(this.svcClass)} class not found, The system is searching in ${
                        funcFullClass(req.svcClass)} class`
                    this.$emit('svcClass', req.svcClass)
                }

            }).catch(error => {
                console.log(error)
                this.ailneDataloading = false
                setTimeout(() => {
                    this.$emit('ailneDataloading', false)
                }, 500)
                this.error.Iserror = true
                this.error.errorMessage = error.message
            });
            setting.newreload = false
            sessionStorage.searchflightReq = JSON.stringify(setting)
        },
        getFlightHistory(){
            if(sessionStorage.formReqID){
                const formReqID = JSON.parse(sessionStorage.formReqID)
                axios.post(`${this.$store.state.API_PATH}FlightSearch/CheckFlightHistory?val=${formReqID.TravelId}`, {}, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(res => {
                    this.flightHistory = res.data.Result
                })
            }
        },
        funcFullClass(c){
            let result = ""
            if(c === "F"){
                result = "First"
            }
            else if(c === "C"){
                result = "Business"
            }
            else{
                result = "Economy"
            }
            return result
        }
    },
    created() {
        setTimeout(() => {
            if (this.flightRequest.newreload) {
                this.callAPIAirline()
            } else {
                this.airlines = JSON.parse(sessionStorage.airlines)
                this.lowcostairlines = JSON.parse(sessionStorage.lowcostairlines)
                setTimeout(async () => {
                    // await this.modifyFlight(this.airlines, this.Tramsit)
                    await this.modifyFlight(this.lowcostairlines, 'DD')
                    await this.modifyFlight(this.airlines)
                    this.ailneDataloading = false
                    setTimeout(() => {
                        this.$emit('ailneDataloading', false)
                    }, 500)
                    this.$emit('carries', this.airlines.filter)
                }, 1500)
            }
            this.firstRender = false
        }, 300)
    },
    mounted() {
        this.getFlightHistory()
        axios.get(this.$store.state.API_PATH + 'FlightBooking/GetEquipmentType', {
            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        }).then(res => {
            this.aircraft = res.data
            window.scrollTo(0, 0)
        })
    }
}
</script>

<style>
.timetoflight {
    position: absolute;
    top: 40%;
}

.lineplane {
    position: relative;
}

.lineplane b,
.lineplane span {
    font-size: .75rem;
}

.hideDetails {
    display: none;
}

#pricing-airline {
    float: right;
}

.v-chip {
    font-size: .8rem !important;
}

.font-W700 {
    font-weight: 700;
}

.font-W400 {
    font-weight: 400;
}

@media screen and (max-width: 1000px) {
    #pricing-airline {
        float: left;

    }

    .show-details {
        display: none
    }
}

.text-profile {
    color: black;
    font-weight: 500;
}
</style>
