<template>
    <div>
        <v-card elevation="0">
            <v-card-text>
                <div>
                    <v-tabs v-model="tab" @click="clearTab('')">
                        <v-tab v-if="pathname !== pathTripSum && selectTraveler && selectTraveler.status !== selectTravelerStatus
                         && selectTraveler.status !== flightActionStatus.ReIssue" href="#searchflight" @click="clearTab('Online')">
                            <img :src="require('@/assets/icons/SearchFlight.svg')" alt="" />
                            <span class="ml-2">Search Flight (Online Booking)</span>
                        </v-tab>
                        <v-tab href="#Addticket" @click="clearTab('Offline')" v-if="pathname !== this.pathSearchFlight">
                            <img :src="require('@/assets/icons/offlinePlan.svg')" alt="" />
                            <span class="ml-2">Search Flight (Offline Booking)</span>
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <!--onlinebooking-->
                        <v-tab-item v-if="pathname !== pathTripSum" :value="'searchflight'">
                            <div class="h-space"></div>
                            <v-card elevation="0">
                                <v-card-text>
                                    <div class="bgcard">
                                        <h3>
                                            Booking History <v-icon color="primary">mdi-history</v-icon>
                                        </h3>
                                        <br />
                                        <v-row>
                                            <template v-if="showFlightHistory && flightHistory && flightHistory.length > 0">
                                                <v-col cols="3" v-for="(Hisitem, Hindex) in flightHistory" :key="`H${Hindex}`">
                                                    <v-card elevation="0" class="pointerCard">
                                                        <v-card-text @click="inputFlightHistory(Hisitem)">
                                                            <v-row >
                                                                <template>
                                                                    <v-col cols="2">
                                                                        <v-img :src="require(`@/assets/logoAirline/square/${
                                                                            showAirlineLogo(Hisitem.Airline)}.png`)" 
                                                                            v-if="showAirlineLogo(Hisitem.Airline) !== 'NoImg'"></v-img>
                                                                    </v-col>
                                                                    <v-col cols="4">
                                                                        <div class="text-center">
                                                                        {{Hisitem.Origin}}
                                                                        </div>
                                                                        <div class="f-text-16 text-center">
                                                                            {{formatDateHistory(Hisitem.DepartureDateTime)}}
                                                                        </div>
                                                                    </v-col>
                                                                    <v-col cols="2">
                                                                        <div class="text-center mt-2">
                                                                            <img width="10" :src="require(`@/assets/icons/tweenArrows.svg`)" alt="Tween Arrows Icon"/>
                                                                        </div>
                                                                    </v-col>
                                                                    <v-col cols="4" style="position: relative;">
                                                                        <span style="float: right; position: absolute; top: 0; right: 0" class="f-text-14 text-center"
                                                                        v-if="!Hisitem.ReturnDateTime && !Hisitem.ReturnArrivalDateTime">
                                                                            (One Way)
                                                                        </span>
                                                                        <div class="text-center">
                                                                            {{Hisitem.Destination}}
                                                                        </div>
                                                                        <div class="f-text-16 text-center" v-if="Hisitem.ReturnDateTime && Hisitem.ReturnArrivalDateTime">
                                                                            {{formatDateHistory(Hisitem.ReturnDateTime)}}
                                                                        </div>
                                                                        <div v-else class="f-text-16 text-center">
                                                                            {{formatDateHistory(Hisitem.DepartureDateTime)}}
                                                                        </div>
                                                                    </v-col>
                                                                </template>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </template>
                                            <v-col col="12" v-else>
                                                <v-card elevation="0">
                                                    <v-card-text>
                                                        No Data
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card-text>
                                <v-card-text>
                                    <div class="bgcard" id="searchFlights">
                                        <h3>
                                            Search Flight
                                        </h3>
                                        <br />
                                        <v-row>
                                            <v-col cols="12">
                                                Business Travel Type
                                                <v-select dense outlined background-color="white" :items="TravelType"
                                                    v-model="coperateData.BusinessTravelType"></v-select>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6">
                                                From <span style="color: red">*</span>
                                                <v-autocomplete background-color="white"
                                                    v-model="searchflightvalue.originCode" :rules="rule.From" ref="From"
                                                    :placeholder="pathname != createTravelRqStringPath ?
                                                    searchflightvalue.originName + ' (' + searchflightvalue.originCode + ')' : 'From'"
                                                    @input.native="getCityCode($event.srcElement.value, 'From')"
                                                    :items="citytotravelFrom" item-text="text" item-value="Code" dense
                                                    outlined></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6">
                                                To <span style="color: red">*</span>
                                                <v-autocomplete background-color="white" dense outlined :rules="rule.To"
                                                    ref="To"
                                                    :placeholder="pathname != createTravelRqStringPath ?
                                                        searchflightvalue.destinationName + ' (' + searchflightvalue.destinationCode + ')' : 'To'"
                                                    @input.native="getCityCode($event.srcElement.value, 'To')"
                                                    :items="citytotravelTo" item-text="text" item-value="Code"
                                                    v-model="searchflightvalue.destinationCode"></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6">
                                                Business Start Date <span style="color: red">*</span>
                                                <v-text-field background-color="white" dense outlined disabled readonly
                                                    :value="formatDate(coperateData.startDate)"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6">
                                                Business End Date <span style="color: red">*</span>
                                                <v-text-field background-color="white" dense disabled outlined
                                                    :value="formatDate(coperateData.endDate)"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" style="position: relative;">
                                                Departure Date <span style="color: red">*</span>
                                                <v-menu v-model="startdate.menu" :close-on-content-click="false"
                                                    :nudge-right="40" transition="scale-transition" offset-y
                                                    min-width="auto">
                                                    <template v-slot:[`activator`]="{ on, attrs }">
                                                        <v-text-field background-color="white"
                                                            :value="formatDate(searchflightvalue.departDate)"
                                                            :rules="rule.DepartureDateFrom" ref="DepartureDateFrom" outlined
                                                            dense readonly v-bind="attrs" v-on="on">
                                                            <v-icon slot="append" color="info">
                                                                mdi-calendar
                                                            </v-icon>
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="searchflightvalue.departDate"
                                                        @input="startdate.menu = false" :min="nowDate"></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" style="position: relative;">
                                                Return Date <span style="color: red" v-if="!searchflightvalue.notServiceRq">*</span>
                                                <v-checkbox label="Not Service Request" hide-details dense color="info"
                                                    style="position: absolute; right: 0px; top: 0px"
                                                    v-model="searchflightvalue.notServiceRq"></v-checkbox>
                                                <v-menu v-model="enddate.menu" :close-on-content-click="false"
                                                    :nudge-right="40" transition="scale-transition" offset-y
                                                    min-width="auto">
                                                    <template v-slot:[`activator`]="{ on, attrs }">
                                                        <v-text-field background-color="white" :value="formatDate
                                                            (searchflightvalue.returnDate)" :rules="rule.ReturnDate"
                                                            ref="ReturnDate" outlined dense :disabled="searchflightvalue.notServiceRq"
                                                            readonly v-bind="attrs" v-on="on">
                                                            <v-icon slot="append"
                                                                :color="searchflightvalue.notServiceRq ? 'default' : 'info'">
                                                                mdi-calendar
                                                            </v-icon>
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="searchflightvalue.returnDate"
                                                        @input="enddate.menu = false"
                                                        :min="searchflightvalue.departDate"></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="shortHaulCheckWhenInput">
                                            <v-col cols="12" xs="12" sm="5" md="5"
                                                v-if="pathname.toString() == createTravelRqStringPath">
                                                <v-card elevation="0">
                                                    <v-card-text>
                                                        <strong>Stand By</strong>
                                                        <br />
                                                        <br />
                                                        <v-data-table class="card" mobile-breakpoint hide-default-footer v-model="selectedtable" :single-select="true"
                                                            :items="standby" item-key="fullname"
                                                            show-select :headers="header">
                                                            <template v-slot:[`item.data-table-select`]="{ isSelected, index, select, item }">
                                                                <td @click="item.TariffLevel && !TariffLevelCanSelect ||
                                                                    TariffLevelCanSelect === item.TariffLevel.SelectFlight.code ||
                                                                    TravelersCanSelect.filter(x => x === item.emp_ID).length > 0 ? rowClicked(item) : null">
                                                                    <div style="pointer-events: none;"
                                                                    v-if="item.TariffLevel && !TariffLevelCanSelect ||
                                                                    TariffLevelCanSelect === item.TariffLevel.SelectFlight.code ||
                                                                    TravelersCanSelect.filter(x => x === item.emp_ID).length > 0">
                                                                        <v-checkbox :value="isSelected" :key="index"
                                                                            :disabled="false" :ripple="false"
                                                                            @input="select($event)"
                                                                            color="primary">
                                                                        </v-checkbox>
                                                                    </div>
                                                                    <div v-else>
                                                                        <v-checkbox :key="index"
                                                                            :disabled="true" :ripple="false"
                                                                            color="primary">
                                                                        </v-checkbox>
                                                                    </div>
                                                                </td>
                                                            </template>
                                                            <template v-slot:[`item.no`]="{ index }">
                                                                <td @click="item.TariffLevel && !TariffLevelCanSelect ||
                                                                    TariffLevelCanSelect === item.TariffLevel.SelectFlight.code ||
                                                                    TravelersCanSelect.filter(x => x === item.emp_ID).length > 0 ? rowClicked(item) : null">
                                                                    {{ index + 1 }}
                                                                </td>
                                                            </template>
                                                            <template v-slot:[`item.fullname`]="{ item }">
                                                                <td @click="item.TariffLevel && !TariffLevelCanSelect ||
                                                                    TariffLevelCanSelect === item.TariffLevel.SelectFlight.code ||
                                                                    TravelersCanSelect.filter(x => x === item.emp_ID).length > 0 ? rowClicked(item) : null">

                                                                    <br v-if="item.flights.length === 0 || item.ParentsOfChild === 1" />
                                                                    {{ item.fullname }}
                                                                    <span v-if="item.age" class="ml-1">({{ checkbirthDate(item) }})
                                                                    </span>
                                                                    <v-chip v-if="item.ParentsOfChild === 0 && item.Type === 'Internal'" x-small :color="item.TariffLevel
                                                                        && !TariffLevelCanSelect ||
                                                                    TariffLevelCanSelect === item.TariffLevel.SelectFlight.code ||
                                                                    TravelersCanSelect.filter(x => x === item.emp_ID).length > 0
                                                                    ? item.TariffLevel.SelectFlight.color : ''">
                                                                        #{{ svcClass.filter(x => x.code === item.TariffLevel.SelectFlight.code)[0].name }}
                                                                    </v-chip>
                                                                    <span v-if="item.ParentsOfChild &&
                                                                    travelers.filter(x => x.emp_ID === item.CreateBy) &&
                                                                    travelers.filter(x => x.emp_ID === item.CreateBy).length > 0" style="font-size: .3rem;" class="mt-1 ml-1">
                                                                        <br />
                                                                        Travel With: {{travelers.filter(x => x.emp_ID === item.CreateBy)[0].fullname}}
                                                                        <br />
                                                                    </span>
                                                                    <br v-else />
                                                                    <span style="font-size: .3rem;" class="mt-1 ml-1">
                                                                        <v-badge color="primary" :content="item.flights.length"
                                                                            v-if="item.flights.length > 0">
                                                                        </v-badge>
                                                                        <br v-else />
                                                                    </span>
                                                                </td>
                                                            </template>
                                                            <template v-slot:[`item.Type`]="{item}">
                                                                <td @click="item.TariffLevel && !TariffLevelCanSelect ||
                                                                    TariffLevelCanSelect === item.TariffLevel.SelectFlight.code ||
                                                                    TravelersCanSelect.filter(x => x === item.emp_ID).length > 0 ? rowClicked(item) : null">
                                                                    {{ item.Type }}
                                                                    <span class="f-text-18 text-center" v-if="item.IsBoard === 1"><br />(Board)</span>
                                                                </td>
                                                            </template>
                                                        </v-data-table>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="2" md="2" style="position: relative;"
                                                v-if="pathname.toString() == createTravelRqStringPath">
                                                <v-row class="text-cente heightcenter">
                                                    <v-col>
                                                        <v-btn class="btn-table" @click="addtraveler()">
                                                            <img class="hidden-sm-and-down"
                                                                :src="require('@/assets/icons/Arrowrightinfo.svg')"
                                                                alt="" />
                                                            <img class="hidden-sm-and-up"
                                                                :src="require('@/assets/icons/ArrowPrimaryUP.svg')"
                                                                alt="" />
                                                            <span class="ml-4" style="font-size: .8rem;">Add</span>
                                                            <span class="ml-4"></span>
                                                        </v-btn>
                                                        <div style="height: 10px;"></div>
                                                        <v-btn class="btn-table" @click="removetravel()">
                                                            <img class="hidden-sm-and-down"
                                                                :src="require('@/assets/icons/ArrowLeftInfo.svg')" alt="" />
                                                            <img class="hidden-sm-and-up"
                                                                :src="require('@/assets/icons/ArrowPrimaryDown.svg')"
                                                                alt="" />
                                                            <span class="ml-1" style="font-size: .8rem;">Remove</span>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="5"
                                                v-if="pathname.toString() == createTravelRqStringPath">
                                                <v-card elevation="0">
                                                    <v-card-text>
                                                        <strong>Selected</strong>
                                                        <br />
                                                        <br />
                                                        <v-data-table mobile-breakpoint hide-default-footer single-select
                                                            :style="alertTravele.alert ? alertTravele.style : ''"
                                                            :items="selected" class="card" item-key="fullname" @click:row="rowClickFromSelect"
                                                            v-model="standbytable" show-select :headers="header">
                                                            <template v-slot:[`item.data-table-select`]="{ isSelected, index, select }">
                                                                <div style="pointer-events: none;">
                                                                    <v-checkbox :value="isSelected" :key="index"
                                                                        :disabled="false" :ripple="false"
                                                                        @input="select($event)"
                                                                        color="primary">
                                                                    </v-checkbox>
                                                                </div>
                                                            </template>
                                                            <template v-slot:[`item.no`]="{ index }">
                                                                {{ index + 1 }}
                                                            </template>
                                                            <template v-slot:[`item.fullname`]="{ item }">
                                                                <br v-if="item.flights.length === 0" />
                                                                {{ item.fullname }}
                                                                <span v-if="item.age" class="ml-1"> ({{ checkbirthDate(item) }})
                                                                </span>
                                                                <v-chip x-small v-if="item.Type === 'Internal'" :color="item.TariffLevel && !TariffLevelCanSelect ||
                                                                TariffLevelCanSelect === item.TariffLevel.SelectFlight.code ||
                                                                TravelersCanSelect.filter(x => x === item.emp_ID).length > 0
                                                                ? item.TariffLevel.SelectFlight.color : ''">
                                                                    #{{ svcClass.filter(x => x.code === item.TariffLevel.SelectFlight.code)[0].name }}
                                                                </v-chip>
                                                                <div v-if="item.MyTraveler && item.MyTraveler.length > 0">
                                                                    <div class="f-text-20 ml-1" v-for="(Traveler, index) in item.MyTraveler" :key="index">
                                                                        <img :src="require('@/assets/icons/Frame.svg')" width="15" alt="Frame Icon"/>
                                                                        {{Traveler.fullname}}
                                                                    </div>
                                                                </div>
                                                                <br v-else />
                                                                <span style="font-size: .6rem;" class="mt-1 ml-1">
                                                                    <v-badge color="primary" :content="item.flights.length"
                                                                        v-if="item.flights.length > 0">
                                                                    </v-badge>
                                                                    <br v-else />
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.Type`]="{item}">
                                                                <td @click="item.TariffLevel && !TariffLevelCanSelect ||
                                                                    TariffLevelCanSelect === item.TariffLevel.SelectFlight.code ||
                                                                    TravelersCanSelect.filter(x => x === item.emp_ID).length > 0 ? rowClicked(item) : null">
                                                                    {{ item.Type }}
                                                                    <span class="f-text-18 text-center" v-if="item.IsBoard === 1"><br />(Board)</span>
                                                                </td>
                                                            </template>
                                                        </v-data-table>
                                                    </v-card-text>
                                                    <span style="color: red" v-if="alertTravele.alert">{{
                                                        alertTravele.message }}</span>
                                                        <!-- TravelersCanSelect: {{TravelersCanSelect}} -->
                                                        <!-- TariffLevelCanSelect: {{TariffLevelCanSelect}} -->
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn outlined color="primary" width="120" @click="close()">Cancel</v-btn>
                                    <v-btn color="primary" width="120" @click="selectSeatClassForEx" :loading="SearchFlightloading"
                                        :disabled="pathname.toString() == pathSearchFlight ? false : selected.length == 0 ? true : false">
                                        Continue</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-tab-item>
                        <!--/onlinebooking-->

                        <!--offlinebooking-->
                        <v-tab-item :value="'Addticket'">
                            <v-card>
                                <v-card-text>
                                    <div class="bgcard">
                                        <h3>
                                            Flight
                                        </h3>
                                        <br />
                                        <template v-if="searchflightvalue.RouteItem.length > 0">
                                            <v-row v-for="(item, index) in searchflightvalue.RouteItem" :key="index">
                                                <v-col>
                                                    <v-card class="card">
                                                        <v-card-title>
                                                            Route {{item.number}}
                                                            <v-spacer></v-spacer>
                                                            <v-btn class="btn-primary" @click="removeRoute(item.ID)" v-if="selectTraveler">
                                                                <v-img class="mr-2" width="15" :src="require(`@/assets/icons/CancelInfo.svg`)" /> Delete
                                                            </v-btn>
                                                        </v-card-title>
                                                        <v-card-text class="">
                                                            <v-row align="center">
                                                                <v-col cols="6">
                                                                    <v-row>
                                                                        <v-col cols="1" class="image-container">
                                                                            <div class="image-center">
                                                                                <v-img class="mt-5 image-center" v-if="item.Airline && showAirlineLogo(item.Airline) !== 'NoImg'"
                                                                                :src="require(`@/assets/logoAirline/square/${showAirlineLogo(item.Airline)}.png`)" />
                                                                                <div v-else-if="item.Airline && showAirlineLogo(item.Airline) === 'NoImg'" class="text-center mt-2">
                                                                                    No Img
                                                                                </div>
                                                                                <v-img v-else class="mt-5 image-center"
                                                                                :src="require(`@/assets/icons/Add.svg`)" />
                                                                            </div>
                                                                        </v-col>
                                                                        <v-col cols="11">
                                                                            Airline <span style="color: red">*</span>
                                                                            <v-autocomplete ref="Airlines" no-data-text="Please Type Airline"
                                                                            v-model="item.Airline" :items="Airlines" item-value="Code" item-text="Name"
                                                                            @input.native="SearchAirlineCode($event.srcElement.value)" hide-details dense outlined>
                                                                                <template v-slot:[`item`]="{item}">
                                                                                    <v-row @click="pushAirline(item)">
                                                                                        <v-col cols="12">
                                                                                            {{ item.Name }}
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </template>
                                                                            </v-autocomplete>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <span v-if="true">
                                                                        Trip Type
                                                                        <v-select outlined background-color="white" dense :items="tripTypes"
                                                                        item-text="text" item-value="code" hide-details v-model="item.TripType"></v-select>
                                                                    </span>
                                                                </v-col>
                                                                <v-col cols="3">
                                                                    Route From <span style="color: red">*</span>
                                                                    <v-autocomplete v-model="item.RouteFrom" placeholder="From" background-color="white"
                                                                        @input.native="getCityCode($event.srcElement.value, 'From')" hide-details
                                                                        ref="RouteFrom" :items="citytotravelFrom" item-text="text"
                                                                        item-value="Code" dense outlined>
                                                                        <v-row @click="pushCity(item)">
                                                                            <v-col cols="12">
                                                                                {{ item.text }}
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="3">
                                                                    Route To <span style="color: red">*</span>
                                                                    <v-autocomplete dense outlined placeholder="To" hide-details
                                                                        @input.native="getCityCode($event.srcElement.value, 'To')" background-color="white"
                                                                        :items="citytotravelTo" item-text="text" item-value="Code"
                                                                        v-model="item.RouteTo" ref="RouteTo">
                                                                        <v-row @click="pushCity(item)">
                                                                            <v-col cols="12">
                                                                                {{ item.text }}
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="3">
                                                                    Travel Date <span style="color: red">*</span>
                                                                    <v-menu v-model="item.travelDateOffMenu" :close-on-content-click="false"
                                                                        :nudge-right="40" transition="scale-transition" offset-y
                                                                        min-width="auto">
                                                                        <template v-slot:[`activator`]="{ on, attrs }">
                                                                            <v-text-field :value="formatDate(item.departDate)" ref="departDate"
                                                                             background-color="white" hide-details
                                                                             outlined dense readonly v-bind="attrs" v-on="on">
                                                                                <v-icon slot="append" color="info">
                                                                                    mdi-calendar
                                                                                </v-icon>
                                                                            </v-text-field>
                                                                        </template>
                                                                        <v-date-picker v-model="item.departDate"
                                                                            @input="item.travelDateOffMenu = false" :min="nowDate">
                                                                        </v-date-picker>
                                                                    </v-menu>
                                                                </v-col>
                                                                <v-col cols="3">
                                                                    <v-row>
                                                                        <v-col cols="6">
                                                                            Departure time
                                                                        </v-col>
                                                                        <v-col cols="6">
                                                                            <span v-if="flightAction === flightActionStatus.ReIssue">
                                                                                Arrival time
                                                                            </span>
                                                                        </v-col>
                                                                        <v-col cols="3" class="mb-6">
                                                                            <v-text-field dense outlined placeholder="HH"
                                                                                v-model="item.TravelDepHour" hide-details></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="3">
                                                                            <v-text-field dense outlined placeholder="MM"
                                                                                v-model="item.TravelDepMinute" hide-details></v-text-field>
                                                                        </v-col>
                                                                        <template v-if="flightAction === flightActionStatus.ReIssue">
                                                                            <v-col cols="3">
                                                                                <v-text-field dense outlined placeholder="HH"
                                                                                    v-model="item.TravelArrivalHour" hide-details></v-text-field>
                                                                            </v-col>
                                                                            <v-col cols="3">
                                                                                <v-text-field dense outlined placeholder="MM"
                                                                                    v-model="item.TravelArrivalMinute" hide-details></v-text-field>
                                                                            </v-col>
                                                                        </template>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col cols="3" v-if="false">
                                                                    PNR <span style="color: red">
                                                                    </span>
                                                                    <v-text-field dense outlined background-color="white" hide-details
                                                                        v-model="item.PNR" :disabled="selectTraveler &&
                                                                        (selectTraveler.status === 'Approve Final'
                                                                        || selectTraveler.status === flightActionStatus.ReIssue) ? true : false"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="3" v-if="false">
                                                                    FlightNo. <span style="color: red">
                                                                    </span>
                                                                    <v-text-field dense outlined background-color="white" hide-details
                                                                        v-model="item.FlightNumber" :disabled="selectTraveler &&
                                                                        (selectTraveler.status === 'Approve Final'
                                                                         || selectTraveler.status === flightActionStatus.ReIssue) ? true : false"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="3" v-if="false">
                                                                    Total Fare <span style="color: red">
                                                                    </span>
                                                                    <v-text-field dense outlined background-color="white" hide-details
                                                                        v-model="item.TotalFare" :disabled="selectTraveler &&
                                                                        (selectTraveler.status === 'Approve Final'
                                                                         || selectTraveler.status === flightActionStatus.ReIssue) ? true : false"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <div class="h-space"></div>
                                             <v-card elevation="0">
                                                <v-card-actions>
                                                    <v-btn class="btn-primary" @click="addRoute()" v-if="selectTraveler">
                                                        <v-icon>mdi-plus</v-icon> Add Flight
                                                    </v-btn>
                                                    <v-spacer></v-spacer>
                                                    <span>({{searchflightvalue.RouteItem.length}}/3)</span>
                                                </v-card-actions>
                                             </v-card>
                                        </template>
                                        <template v-else>
                                            <div class="h-space"></div>
                                            <div class="text-center">
                                                <v-progress-circular indeterminate color="primary" :size="80" :width="6"></v-progress-circular>
                                            </div>
                                            <div class="h-space"></div>
                                        </template>
                                        <br />
                                        <v-row v-if="selectTraveler && (selectTraveler.status !== 'Approve Final' && selectTraveler.status !== flightActionStatus.ReIssue)">
                                            <v-col cols="5" v-if="pathname.toString() == createTravelRqStringPath">
                                                <v-card elevation="0">
                                                    <v-card-text>
                                                        <strong>Stand By</strong>
                                                        <v-data-table hide-default-footer :items="standby" checkbox-color="primary"
                                                            item-key="fullname" v-model="selectedtable" show-select
                                                            :headers="header">
                                                            <template v-slot:[`item.no`]="{ index }">
                                                                {{ index + 1 }}
                                                            </template>
                                                        </v-data-table>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="2" v-if="pathname.toString() == createTravelRqStringPath"
                                                class="text-center">
                                                <v-btn class="btn-table" @click="addTravelerOffFlight()"><img
                                                        :src="require('@/assets/icons/Arrowrightinfo.svg')" alt="" />
                                                    <span class="ml-4" style="font-size: .8rem;">Add</span>
                                                    <span class="ml-4"></span>
                                                </v-btn>
                                                <div style="height: 10px;"></div>
                                                <v-btn class="btn-table" @click="removetravel()"><img
                                                        :src="require('@/assets/icons/ArrowLeftInfo.svg')" alt="" />
                                                    <span class="ml-1" style="font-size: .8rem;">Remove</span>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="5" v-if="pathname.toString() == createTravelRqStringPath">
                                                <v-card elevation="0">
                                                    <v-card-text>
                                                        <strong>Selected</strong>
                                                        <v-data-table hide-default-footer :items="selected" checkbox-color="primary"
                                                            item-key="fullname" v-model="standbytable" show-select
                                                            :headers="header">
                                                            <template v-slot:[`item.no`]="{ index }">
                                                                {{ index + 1 }}
                                                            </template>
                                                        </v-data-table>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12" v-else>
                                                <v-card>
                                                    <v-card-text>
                                                        <strong>Travelers</strong>
                                                        <br />
                                                        <v-row>
                                                             <v-col>
                                                                <v-simple-table style="width: 100%;">
                                                                    <template v-slot:default>
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="row">
                                                                                    <span
                                                                                        style="float:left;">Selected</span>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="item in traveler" :key="item.name">
                                                                                <td>{{ item.fullname }}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </template>
                                                                </v-simple-table>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn outlined color="primary" width="120" @click="close()">Cancel</v-btn>
                                    <v-btn color="primary" width="120" @click="insertFlightOffline()"
                                        :disabled="selected.length == 0 ? true : false">
                                        Save
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-tab-item>
                        <!--/offlinebooking-->
                    </v-tabs-items>
                </div>
            </v-card-text>
        </v-card>
    <v-dialog width="600" v-model="seatClassDialog" v-if="seatClassDialog">
        <v-card>
            <v-card-title>
                Select Class
            </v-card-title>
            <v-card-text>
                <div class="bgcard">
                    Please contact BTT admin for the cabin class of external traveller.
                    <div class="h-space"></div>
                    <v-select dense outlined background-color="white" v-model="searchflightvalue.svcClass" item-text="name"
                    hide-details
                    item-value="code" :items="svcClass"></v-select>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="seatClassDialog = false">Cancel</v-btn>
                <v-btn @click="searchflight" color="primary">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';

export default {
    props: {
        flightType: String,
        flightAction: String,
        airline: Object,
        travelers: Array,
        coperateData: Object,
        selectTraveler: Object
    },
    data() {
        return {
            pathSearchFlight: "/searchFlight",
            selectedItem: {airline: [], city: []},
            pathTripSum: "/tripSummary",
            flightActionStatus : {
                ReIssue: 'Re-Issue',
                Revised: 'Revised',
                Draft: 'Draft',
            },
            selectTravelerStatus : 'Approve Final',
            tripTypes: [
                {text: 'Depart', code: 'depart'},
                {text: 'Return', code: 'return'}
            ],
            number: 0,
            showFlightHistory: false,
            flightHistory: [],
            shortHaulFlight: false,
            shortHaulCheckWhenInput: false,
            seatClassDialog: false,
            SearchFlightloading: false,
            offlineNo: 0,
            TravelersCanSelect: [],
            TariffLevelCanSelect: null,
            Airlines: [],
            createTravelRqStringPath: '/createTravelRq',
            useTab: "Online",
            index: 0,
            specialMealItem: [],
            reuseItem: [
                { text: "Yes", val: 1 },
                { text: "No", val: 0 },
            ],
            returnDateOffMenu: false,
            nowDate: new Date().toISOString().substr(0, 10),
            topSearch: [],
            headerCity: "Top Search",
            citytotravelFrom: [],
            citytotravelTo: [],
            pLanguage: this.$store.state.Language,
            startdate: { date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), menu: false },
            enddate: { date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), menu: false },
            modal: false,
            tab: null,
            pathname: "",
            header: [{ text: 'Name', value: 'fullname', }, { text: 'Type', value: 'Type', }],
            standby: [],
            selected: [],
            traveler: null,
            svcClass: [
                { code: 'Y', name: 'Economy', color: '#8AFFFF'},
                { code: 'C', name: 'Business', color: '#FF8FBE' },
                { code: 'F', name: 'First', color: '#FDFF8E' },
            ],
            selectedtable: [],
            standbytable: [],
            codetotravel: [],
            TravelType: [
                'Domestic',
                'Overseas'
            ],
            citytotravel: [],
            flightDate: [],
            formatYMD: "YYYY-MM-DD",
            alertTravele: {
                alert: false,
                style: 'border: solid red 1.5px; border-radius: 5px',
                message: 'Select at least an internal or an adult external'
            },
            searchflightvalue: {
                tripType: "R",
                originCode: "",
                destinationCode: "",
                airline: null,
                svcClass: "Y",
                directFlight: false,
                departDate: null,
                returnDate: null,
                adult: 0,
                child: 0,
                infant: 0,
                languageCode: "en",
                originName: "",
                notServiceRq: false,
                destinationName: "",
                newreload: false,
                RouteItem: [],
                TravelDepHour: "",
                TravelDepMinute: "",
                TravelArrivalHour: "",
                TravelArrivalMinute: "",
                ReturnDepHour: "",
                ReturnDepMinute: "",
                ReturnArrivalHour: "",
                ReturnArrivalMinute: "",
                FlightNumber: "",
                seatNo: "",
                Reuse: "",
                specialMeal: "",
            },
            valRuleRequire: "This field is required",
            rule: {
                From: [val => (val || '').length > 0 || this.valRuleRequire],
                To: [val => (val || '').length > 0 || this.valRuleRequire],
                DepartureDateFrom: [val => (val || '').length > 0 || this.valRuleRequire],
                ReturnDate: [val => (val || '').length > 0 || this.valRuleRequire],
            },
        }
    },
    watch: {
        'searchflightvalue.RouteItem'(){
            setTimeout(() => {
                if(this.searchflightvalue.RouteItem.length === 0){
                    this.addRoute()
                }
            }, 300);
        },
        'searchflightvalue.departDate'() {
            if (this.index !== 0 && this.searchflightvalue.departDate > this.searchflightvalue.returnDate) {
                this.searchflightvalue.returnDate = moment(this.searchflightvalue.departDate).format(this.formatYMD)
            }
            this.index += 1

        },
        'searchflightvalue.originCode'() {
            this.flightCheck()
        },
        'searchflightvalue.destinationCode'(){
            this.flightCheck()
        }
    },
    methods: {
        showAirlineLogo(val){
            let result
            try{
                if(require(`@/assets/logoAirline/square/${val}.png`)){
                    result = val
                }
            }catch (err){
                result = 'NoImg'
            }
            return result
        },
        pushCity(val){
            this.selectedItem.city.push(val);
        },
        pushAirline(val){
            this.selectedItem.airline.push(val);
        },
        inputFlightHistory(val){
            this.searchflightvalue.originCode = val.Origin
            this.searchflightvalue.destinationCode = val.Destination
            this.searchflightvalue.departDate = moment(val.DepartureDateTime).format(this.formatYMD)
            if(val.ReturnDateTime && val.ReturnArrivalDateTime){
                this.searchflightvalue.notServiceRq = false
                this.searchflightvalue.returnDate = moment(val.ReturnDateTime).format(this.formatYMD)
            }else{
                this.searchflightvalue.returnDate = moment(val.DepartureDateTime).format(this.formatYMD)
                this.searchflightvalue.notServiceRq = true
            }

            location.href = '#searchFlights'
        },
        flightCheck(){
            if(this.searchflightvalue.originCode && this.searchflightvalue.destinationCode && this.pathname !== this.pathSearchFlight){
                const findOriginName = this.citytotravelFrom.filter(e => e.Code === this.searchflightvalue.originCode)
                const fingDestinationName = this.citytotravelTo.filter(e => e.Code === this.searchflightvalue.destinationCode)
                this.ShortHaulCheck(findOriginName, fingDestinationName).then(async res => {
                    if(res.data.Status){
                        this.shortHaulCheckWhenInput = false
                        this.selected = []
                        await this.travelerJson()
                        this.TariffLevelCanSelect = null
                        if(res.data.Result && res.data.Result.OriginCountry === 'TH' && res.data.Result.DestinationCountry === 'TH'){
                            for (const item of this.standby) {
                                await new Promise((resolve) => {
                                    console.log(item.TariffLevel);
                                    item.TariffLevel.SelectFlight = {
                                        code: 'Y',
                                        color:'#8AFFFF'
                                    }
                                    resolve()
                                })
                                this.shortHaulFlight = false
                            }
                        }else{
                            if(res.data.Result){
                                console.log(res.data.Result);
                                for (const item of this.standby) {
                                    await new Promise((resolve) => {
                                        if(item.TariffLevel.Level === 1 && item.ParentsOfChild === 0 && item.TariffLevel.CanFlight.length > 0 &&
                                         item.TariffLevel.CanFlight.filter(x => x === 'Y').length > 0){
                                            item.TariffLevel.SelectFlight = {
                                                code: item.TariffLevel.CanFlight.filter(x => x === 'Y')[0],
                                                color: '#8AFFFF'
                                            }
                                        }else{
                                            if(item.ParentsOfChild === 1){
                                                const findIn = this.standby.filter(x => x.emp_ID === item.CreateBy)
                                                item.TariffLevel.SelectFlight = {
                                                    code: findIn[0].TariffLevel.Level === 1 ? 'Y' : findIn[0].TariffLevel.CanFlight[0],
                                                    color: null
                                                }
                                            }else{
                                                item.TariffLevel.SelectFlight = {
                                                    code: item.TariffLevel.CanFlight[0],
                                                    color: item.TariffLevel.Color
                                                }
                                            }
                                        }
                                        resolve()
                                    })
                                }
                                this.shortHaulFlight = true
                            }else{
                                for (const item of this.standby) {
                                    await new Promise((resolve) => {
                                        if(item.ParentsOfChild === 1){
                                            const findIn = this.standby.filter(x => x.emp_ID === item.CreateBy)
                                            item.TariffLevel.SelectFlight = {
                                                code: findIn[0]?.TariffLevel?.CanFlight[0],
                                                color: this.svcClass.filter(x => x.code === findIn[0]?.TariffLevel?.CanFlight[0])[0].color
                                            }
                                        }else{
                                            item.TariffLevel.SelectFlight = {
                                                code: item?.TariffLevel?.CanFlight[0],
                                                color: this.svcClass.filter(x => x.code === item?.TariffLevel?.CanFlight[0])[0].color
                                            }
                                        }
                                        resolve()
                                    })
                                }
                                this.shortHaulFlight = false
                            }
                        }
                    }
                    this.shortHaulCheckWhenInput = true
                })
            }else{
                this.shortHaulCheckWhenInput = false
            }
        },
        selectSeatClassForEx(){
            console.log(this.selected);
            if (!this.RequiredBoolking()) {
                if(this.selected.filter(x => x.Type === 'External').length > 0 && this.selected.filter(x => x.Type === 'External').length === this.selected.length){
                    if(this.selected.filter(x => x.Type === 'External' && x.IsBoard === 1).length > 0){
                        if(this.svcClass.filter(x=>x.code === 'F').length === 0){
                            this.svcClass.push({ code: 'F', name: 'First', color: '#FDFF8E' },)
                        }
                    }else{
                        this.svcClass = this.svcClass.filter(x => x.code !== 'F')
                    }
                    this.seatClassDialog = true
                }else{
                    this.searchflight()
                }
            }
        },
        async rowClicked(item){
            if (await this.selectedtable.includes(item)) {
                this.selectedtable.splice(this.selectedtable.findIndex(e => e === item), 1)
            }
            else if(item.ParentsOfChild === 1 || this.selectedtable.length === 0 ||
             this.TariffLevelCanSelect === item.TariffLevel.SelectFlight.code ||
            this.TravelersCanSelect.filter(x => x === item.emp_ID).length > 0){
                this.selectedtable.push(item)
            }

            if(this.selectedtable && this.selectedtable.length > 0 && this.selected.length === 0){
                this.TariffLevelCanSelect = this.selectedtable[0].TariffLevel.SelectFlight.code
            }else if(this.selected.length === 0){
                this.TariffLevelCanSelect = null
            }
        },
        async rowClickFromSelect(item){
            if (await this.standbytable.includes(item)) {
                this.standbytable.splice(this.standbytable.findIndex(e => e === item), 1)
            }
            else {
                this.standbytable.push(item)
            }
        },
        SearchAirlineCode(val){
            const req = {
                Code: val,
                Language: 'en',
                Name: val
            }
            axios.post(this.$store.state.API_PATH + 'FlightSearch/SearchAirlineCode', req, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async res => {
                this.Airlines = await res.data
                this.Airlines.push(...this.selectedItem.airline)
            })
        },
        checkbirthDate(val){
            let type = ''
            //  && this.searchflightvalue.returnDate
            if(this.searchflightvalue.departDate){
                if(parseInt(moment(this.searchflightvalue.departDate).diff(moment(val.birthdate), 'years')) >= 12) {
                    type = 'Adult'
                }else if(parseInt(moment(this.searchflightvalue.departDate).diff(moment(val.birthdate), 'years')) < 12 &&
                 parseInt(moment(this.searchflightvalue.departDate).diff(moment(val.birthdate), 'years')) >= 2){
                    type = 'Child'
                }else{
                    type = 'Infant'
                }
                // val.age = type
            }

            if(this.searchflightvalue.returnDate){
                if(parseInt(moment(this.searchflightvalue.returnDate).diff(moment(val.birthdate), 'years')) >= 12) {
                   type = 'Adult'
                }else if(parseInt(moment(this.searchflightvalue.returnDate).diff(moment(val.birthdate), 'years')) < 12 &&
                 parseInt(moment(this.searchflightvalue.returnDate).diff(moment(val.birthdate), 'years')) >= 2){
                   type = 'Child'
                }else {
                    type = 'Infant'
                }
            }
            val.age = type
            return type
        },
        formatDateHistory(date) {
            return date ? moment(date).format('DD-MMM-YYYY \n (HH:mm)') : ""
        },
        formatDate(date) {
            return date ? moment(date).format('DD-MMM-YYYY') : ""
        },
        RequiredBoolking() {
            let checkvalid = false
            if (!this.$refs.From.validate()) {
                this.$refs.From.focus()
                this.$refs.From.validate(true)
                checkvalid = true
            }
            if (!this.$refs.To.validate()) {
                this.$refs.To.focus()
                this.$refs.To.validate(true)
                checkvalid = true
            }
            if (!this.$refs.DepartureDateFrom.validate()) {
                this.$refs.DepartureDateFrom.focus()
                this.$refs.DepartureDateFrom.validate(true)
                checkvalid = true
            }
            if (!this.$refs.ReturnDate.validate()) {
                this.$refs.ReturnDate.focus()
                this.$refs.ReturnDate.validate(true)
                checkvalid = true
            }
            return checkvalid
        },
        async searchflight() {
                this.SearchFlightloading = true
                const findOriginName = this.citytotravelFrom.filter(e => e.Code === this.searchflightvalue.originCode)
                const fingDestinationName = this.citytotravelTo.filter(e => e.Code === this.searchflightvalue.destinationCode)
                this.searchflightvalue.originName = findOriginName[0].Name
                this.searchflightvalue.destinationName = fingDestinationName[0].Name
                const countingAdultinFlight = await this.selected.filter(item => !item.CreateBy || this.checkbirthDate(item) === 'Adult').length
                await this.modifySearchflight(countingAdultinFlight, findOriginName, fingDestinationName)
        },
        ShortHaulCheck(Origin, Destination){
            return  axios.post(`${this.$store.state.API_PATH}CityCode/ShortHaulCheck?val=`+
            `${Origin[0].CountryCode}|${Origin[0].Code}|${Destination[0].CountryCode}|${Destination[0].Code}`, {},
            {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
        },
        async modifySearchflight(countingAdultinFlight, Origin, Destination) {
            if (countingAdultinFlight > 0 || this.pathname === this.pathSearchFlight) {
                await this.CheckingLocationPath()
                // this.searchflightvalue.travelers = this.selected
                this.searchflightvalue.newreload = true
                const req = this.citytotravelFrom.filter(e => e.Code === this.searchflightvalue.originCode)[0]
                axios.post(this.$store.state.API_PATH + 'SearchManagement/SaveDataSearch', req, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                }).then(() => {
                        if(this.selectTraveler.Type === 'Internal'){
                            this.searchflightvalue.svcClass = this.selected[0].TariffLevel.SelectFlight.code
                        }
                    if (this.searchflightvalue.notServiceRq) {
                        this.searchflightvalue.tripType = "O"
                        this.searchflightvalue.notServiceRq = true
                    }else{
                        this.searchflightvalue.tripType = "R"
                        this.searchflightvalue.notServiceRq = false
                    }
                    setTimeout(() => {
                        sessionStorage.searchflightReq = JSON.stringify(this.searchflightvalue)
                        location.href = "/searchFlight"
                        this.SearchFlightloading = false
                    }, 200);
                })
            } else {
                this.seatClassDialog = false
                this.SearchFlightloading = false
                this.alertTravele.alert = true
            }
        },
        async CheckingLocationPath(){
            if (this.pathname === this.createTravelRqStringPath) {
                await this.setTraveler()
                sessionStorage.removeItem('travelers')
                if (sessionStorage.travelers !== undefined) {
                    sessionStorage.travelers = JSON.stringify(sestraveler)
                } else {
                    setTimeout(async () => {
                        sessionStorage.travelers = JSON.stringify(await this.selected)
                    }, 1);
                }
            } else {
                const sestraveler = JSON.parse(sessionStorage.travelers)
                this.selected = sestraveler
                this.selected.forEach(elmselect => {
                    let status = []
                    status = sestraveler.filter(filtraveler => filtraveler.fullname === elmselect.fullname)
                    if (status.length === 0) {
                        sestraveler.push(elmselect)
                    }
                })
            }
        },
        async setTraveler(){
            await this.selected.forEach(async item => {
                if (await item.Type === 'Internal' || item.age === null || item.age === '' || await item.age === 'Adult') {
                    this.searchflightvalue.adult += 1
                }else if (await item.age === 'Child') {
                    this.searchflightvalue.child += 1
                    item.TravelWith = await this.selected.filter(findadtTr => findadtTr.emp_ID === item.CreateBy).length > 0 ? item.CreateBy : null
                } else {
                    this.searchflightvalue.infant += 1
                    item.TravelWith = await this.selected.filter(findadtTr => findadtTr.emp_ID === item.CreateBy).length > 0 ? item.CreateBy : null
                }

                if(await item.MyTraveler && item.MyTraveler.length > 0){
                    this.searchflightvalue.adult += item.MyTraveler.filter(x => x.age === 'Adult').length
                }
                if(await item.MyTraveler && item.MyTraveler.length > 0){
                    this.searchflightvalue.child += item.MyTraveler.filter(x => x.age === 'Child').length
                }
                if(await item.MyTraveler && item.MyTraveler.length > 0){
                    this.searchflightvalue.infant += item.MyTraveler.filter(x => x.age === 'Infant').length
                }
            })
        },
        addTravelerOffFlight(){
            this.selectedtable.forEach(element => {
                this.standby = this.standby.filter(data => element.fullname !== data.fullname)
                element.PNR = ''
                this.selected.push(element)
            });
            this.alertTravele.alert = false
            this.resetCgheck();
        },
        async addtraveler() {
             for (const element of this.selectedtable) {
                await new Promise(async (resolve) => {
                    if(element.TariffLevel.SelectFlight.code === this.TariffLevelCanSelect){
                        if(element.ParentsOfChild === 1){
                            let findParents = await this.standby.filter(x => x.emp_ID === element.CreateBy)[0]
                            if(findParents){
                                if(findParents.MyTraveler.filter(x => x.emp_ID === element.emp_ID).length === 0){
                                    findParents.MyTraveler.push(element)
                                }
                                this.standby = await this.standby.filter(data => element.emp_ID !== data.emp_ID)
                                this.standby = await this.standby.filter(data => findParents.emp_ID !== data.emp_ID)
                                this.selected.push(await findParents)
                            }else{
                                findParents = await this.selected.filter(x => x.emp_ID === element.CreateBy)[0]
                                if(findParents){
                                     if(findParents.MyTraveler.filter(x => x.emp_ID === element.emp_ID).length === 0){
                                        findParents.MyTraveler.push(element)
                                    }
                                    this.standby = await this.standby.filter(data => element.emp_ID !== data.emp_ID)
                                }
                            }
                        }else {
                            this.standby = await this.standby.filter(data => element.emp_ID !== data.emp_ID)
                            element.PNR = ''
                            if(await this.selected.filter(x => x.emp_ID === element.emp_ID).length === 0){
                                this.selected.push(element)
                            }
                        }
                    }
                    resolve()
                })
            }
            this.alertTravele.alert = false
            this.resetCgheck();
        },
        removetravel() {
            this.standbytable.forEach(element => {
                this.selected = this.selected.filter(data => element.emp_ID !== data.emp_ID)
                if(element.MyTraveler){
                    this.standby.push(...element.MyTraveler)
                    element.MyTraveler = []
                    this.standby.push(element)
                }else{
                    this.standby.push(element)
                }
            });
            if(this.selected.length === 0){
                this.TravelersCanSelect = []
                this.TariffLevelCanSelect = null
            }
            this.alertTravele.alert = false
            this.resetCgheck();
        },
        resetCgheck() {
            this.standbytable = [];
            this.selectedtable = [];
        },
        close() {
            this.$emit('closeflightbook', false)
            this.clearTab("")
        },
        getCityCode(input, type) {
            if (input.length >= 3) {
                const checkCityCodeItem = []
                if (type === "From") {
                    if (this.citytotravelFrom[0] === { header: this.headerCity }) {
                        this.citytotravelFrom.filter(el => el.Name.toLowerCase().substr(0, input.length) === input.toLowerCase())
                    }
                }
                else {
                    this.citytotravelTo.filter(el => el.Name.toLowerCase().substr(0, input.length) === input.toLowerCase())
                }
                if (checkCityCodeItem.length === 0) {
                    this.GetCityCodeLine2(input, type)
                }
            }
        },
        GetCityCodeLine2(input, type) {
            axios.post(`${this.$store.state.API_PATH}CityCode/GetCityCodesAndAirportCodeBySearch?Name=${input}&pLanguage=${this.pLanguage}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(async res => {
                if (res.data.length > 0) {
                    if (type === "From") {
                        await res.data.forEach(elm => {
                            this.citytotravelFrom.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                        })
                        await this.citytotravelFrom.push(...this.selectedItem.city)
                    } else if(type === "To") {
                        await res.data.forEach(elm => {
                            this.citytotravelTo.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                        })
                        await this.citytotravelTo.push(...this.selectedItem.city)
                    } else {
                        this.selectedItem.city.push(...res.data)
                        this.selectedItem.city.forEach(elm => {
                            this.citytotravelFrom.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                        })
                        this.selectedItem.city.forEach(elm => {
                            this.citytotravelTo.push({ ...elm, text: `${elm.Name} (${elm.Code})` })
                        })
                    }
                }
            }).catch(err => err)
        },
        addRoute() {
            if (!this.funcSeachflightvalue()) {
                this.offlineNo += 1
                this.searchflightvalue.RouteItem.push({
                    number: this.number + 1,
                    ID: this.offlineNo,
                    RouteFrom: "",
                    TripType: "",
                    RouteTo: "",
                    Airline: null,
                    departDate: null,
                    arrivalDate: null,
                    TravelDepHour: "",
                    TravelDepMinute: "",
                    TravelArrivalHour: "",
                    TotalFare: null,
                    TravelArrivalMinute: "",
                    ReturnDepHour: "",
                    ReturnDepMinute: "",
                    ReturnArrivalHour: "",
                    ReturnArrivalMinute: "",
                    FlightNumber: "",
                    seatNo: "",
                    Reuse: "",
                    specialMeal: "",
                    travelDateOffMenu: false,
                })
            }
        },
        removeRoute(ID) {
            this.searchflightvalue.RouteItem = this.searchflightvalue.RouteItem.filter(x => x.ID !== ID)
            this.number -= 1
        },
        clearTab(tab) {
            this.searchflightvalue = {
                tripType: "R",
                originCode: "",
                destinationCode: "",
                airline: null,
                svcClass: "Y",
                directFlight: false,
                departDate: moment(this.coperateData.startDate).subtract(1, 'days').format(this.formatYMD),
                returnDate: moment(this.coperateData.endDate).add(1, 'days').format(this.formatYMD),
                adult: 0,
                child: 0,
                infant: 0,
                languageCode: "en",
                originName: "",
                destinationName: "",
                newreload: false,
                RouteItem: [],
                TravelDepHour: "",
                TravelDepMinute: "",
                TravelArrivalHour: "",
                TravelArrivalMinute: "",
                ReturnDepHour: "",
                ReturnDepMinute: "",
                ReturnArrivalHour: "",
                ReturnArrivalMinute: "",
                FlightNumber: "",
                seatNo: "",
                Reuse: "",
                specialMeal: "",
            }
            this.selected = []
            this.TariffLevelCanSelect = null
            this.standby = [...this.travelers]
        },
        getSpecialMeal() {
            axios.post(this.$store.state.API_PATH + "SpecialMeal/GetSpecialMeal", {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then((res) => {
                if (res.data.Status) {
                    this.specialMealItem = res.data.Result;
                    this.specialMealItem.unshift({ Description: "No Preferred", MealCode: "" })
                }
            })
                .catch((err) => err);
        },
        async insertFlightOffline() {
            if (!this.funcSeachflightvalue()) {
                this.$store.state.LOADING = true
                const newTraveler = []
                for (const item of this.selected) {
                    await new Promise((resolve, reject) => {
                        const modifytraveler = {
                            EMPLOYEE_ID: item.emp_ID,
                            Status: this.flightAction === this.flightActionStatus.ReIssue ? this.flightActionStatus.ReIssue : item.status,
                            BookingOID: null,
                            TravelWith: this.flightAction === this.flightActionStatus.ReIssue &&
                            item.TravelWith !== 0 ? item.TravelWith : null, // Re-Issue Check By TravelWith Propertie
                            BookingOfflineOID: null,
                            TravelRequestOID: item.TravelRequestOID,
                            TravelCardOID: item.TravelCard.TravelCardOID,
                            SpecialMeal: item.SpecialMeal
                        }
                        if(this.selectTraveler && (this.selectTraveler.status === this.selectTravelerStatus || this.selectTraveler.status === this.flightActionStatus.ReIssue)){
                            if(this.airline.isFlightOffline){
                                modifytraveler.BookingOfflineOID = this.airline.OriginalBookingOID ? this.airline.OriginalBookingOID : this.airline.BookingOfflineOID
                            }else{
                                modifytraveler.BookingOID = this.airline.BookingOID
                            }
                        }
                        newTraveler.push(modifytraveler)
                        this.updateStatusTraveler(modifytraveler)
                        resolve()
                        // reject(err)
                    })
                }
                const RequestApi = {
                    TravelRequestOID: null,
                    IsReIssue: 0,
                    ReIssueSelected: 0,
                    Traveler: [],
                    RouteItem: [],
                    TITLE: null,
                    PNR: null,
                    FIRSTNAME: null,
                    LASTNAME: null,
                    EMAIL_ID: null,
                    MOBILE_NO: null,
                    FlightNumber: null,
                    TravelDate: null,
                    ReturnDate: null,
                    PreferredSeat: null,
                    SpecialMeal: null,
                    Svc_class: null,
                    NotServiceRq: this.NotServiceRq,
                    Status: null
                }
                if(this.selectTraveler && (this.selectTraveler.status === 'Submitted')){
                    RequestApi.Status = this.flightActionStatus.Revised
                }else if(this.selectTraveler && this.selectTraveler.status === this.selectTravelerStatus){
                    RequestApi.Status = this.flightActionStatus.ReIssue
                }else if(this.selectTraveler && this.selectTraveler.status){
                    RequestApi.Status = this.selectTraveler.status
                }else{
                    RequestApi.Status = this.flightActionStatus.Draft
                }
                for (const item of this.searchflightvalue.RouteItem){
                    await new Promise((resolve) => {
                        let TravelDepTimeStart = "", TravelArrTimeEnd = ""
                        if (item.departDate) {
                            TravelDepTimeStart = `${item.departDate} ${item.TravelDepHour ? item.TravelDepHour : '00'}:${item.TravelDepMinute ? item.TravelDepMinute : '00'}:00.000`
                            console.log(item.departDate, TravelDepTimeStart);
                        }
                        if (item.arrivalDate) {
                            TravelArrTimeEnd = `${item.arrivalDate} ${item.TravelArrivalHour ? item.TravelArrivalHour : '00'}:${item.TravelArrivalMinute ?
                            item.TravelArrivalHour : '00'}:00.000`
                        }
                        if (sessionStorage.formReqID) {
                            const formReqID = JSON.parse(sessionStorage.formReqID)
                            const PROFILE = JSON.parse(localStorage.PROFILE)
                            item.departDate = `${TravelDepTimeStart}`
                            item.arrivalDate = `${!TravelArrTimeEnd ? TravelDepTimeStart : TravelArrTimeEnd}`
                            item.PNR = item.PNR ? item.PNR : null
                            item.TotalFare = item.TotalFare ? item.TotalFare : 0
                            RequestApi.number = item.number
                            RequestApi.TravelRequestOID = formReqID.TravelId
                            RequestApi.Traveler = newTraveler
                            RequestApi.TITLE = PROFILE.TITLE
                            RequestApi.FIRSTNAME = PROFILE.FIRSTNAME
                            RequestApi.LASTNAME = PROFILE.LASTNAME
                            RequestApi.EMAIL_ID = PROFILE.EMAIL_ID
                            RequestApi.MOBILE_NO = PROFILE.MOBILE_NO
                            RequestApi.NotServiceRq = true
                            RequestApi.PNR = item.PNR
                            RequestApi.IsReIssue = (RequestApi.Status === this.selectTravelerStatus ||  RequestApi.Status === this.flightActionStatus.ReIssue) ? 1 : 0
                            RequestApi.ReIssueSelected = RequestApi.IsReIssue === 1 ? 1 : 0
                            RequestApi.FlightNumber = item.FlightNumber
                            console.log(RequestApi);
                            RequestApi.RouteItem.push(item)
                        }
                        resolve()
                    })
                }
                this.insertFlightOfflineAPI(RequestApi)
            }
        },
        updateStatusTraveler(val){
            axios.post(`${this.$store.state.API_PATH}Traveler/UpdateStatusTraveler`, {
                EMPLOYEE_ID: val.EMPLOYEE_ID,
                TravelRequestOID: val.TravelRequestOID,
                Status: val.Status
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
        },
        insertFlightOfflineAPI(RequestApi) {
            axios.post(this.$store.state.API_PATH + 'Traveler/InsertTravelerOffline', RequestApi, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
                .then(res => {
                    if(this.pathname !== this.pathTripSum){
                        this.$store.state.LOADING = false
                        if (res.data.Status) {
                            window.location.href = ""
                        }
                    }else{
                        location.href = '/createTravelRq'
                    }
                }, err => {
                    this.$store.state.LOADING = false
                    console.log(err.message + " : Traveler/InsertTravelerOffline")
                })
        },
        funcSeachflightvalue() {
            let checkvalid = false
            const iAirline = this.searchflightvalue.RouteItem.findIndex(e => e.Airline === null)
            if (iAirline !== -1) {
                this.$refs.Airlines[iAirline].focus()
                checkvalid = true
            }
            const iFrom = this.searchflightvalue.RouteItem.findIndex(e => e.RouteFrom === "")
            if (iFrom !== -1) {
                this.$refs.RouteFrom[iFrom].focus()
                checkvalid = true
            }
            const iTo = this.searchflightvalue.RouteItem.findIndex(e => e.RouteTo === "")
            if (iTo !== -1) {
                this.$refs.RouteTo[iTo].focus()
                checkvalid = true
            }
            const iDepartDate = this.searchflightvalue.RouteItem.findIndex(e => e.departDate === null)
            if (iDepartDate !== -1) {
                this.$refs.departDate[iDepartDate].focus()
                checkvalid = true
            }
            return checkvalid
        },
        async totalAge(val) {
            const nowdate = moment().format(this.formatYMD)
            console.log(nowdate);
            let result = null
            if (val) {
                result = await parseInt(moment(nowdate).diff(moment(val), 'years'))
            }
            return result
        },
        async travelerJson() {
            this.standby = []
            const findIn = this.travelers.filter(x => x.Type === 'Internal')
            await findIn.forEach(x => x.MyTraveler = [])
            const findChd = this.travelers.filter(x => x.ParentsOfChild === 1)
            const findWithoutPrnt = this.travelers.filter(x => x.ParentsOfChild === 0 && x.Type === 'External')
            if(this.selectTraveler){
                if(this.selectTraveler.Type === 'Internal'){
                    this.standby.push(...findIn)
                    this.standby.push(...findChd)
                }else if(this.selectTraveler.Type === 'External'){
                    if(this.selectTraveler.ParentsOfChild === 1){
                        this.standby.push(...findIn)
                        this.standby.push(...findChd)
                    }else{
                        this.standby.push(...findWithoutPrnt)
                    }
                }
            }
        },
        async checkTravelerType(elm){
            elm.flights = elm.flights !== null && elm.flights !== undefined && elm.flights.length > 0 ? elm.flights.length : 0
            elm.Type = elm.CreateBy !== null ? 'External' : 'Internal'
            elm.ageNumber = elm.Type === 'External' ? await this.totalAge(elm.birthdate) : 0
        },
        async SetFlightOffFromTripSumPage(){
            let item = {}
            this.searchflightvalue.RouteItem = []
            for (const x of this.airline.depFlight) {
                await new Promise(async (resolve) => {
                    this.offlineNo += 1
                    item = {
                        ID: this.offlineNo,
                        number: this.number + 1,
                        RouteFrom: x.depCity.code,
                        TripType: 'depart',
                        BookingOID: this.airline.BookingOID,
                        BookingOfflineOID: this.airline.BookingOfflineOID,
                        RouteTo: x.arrCity.code,
                        Airline: x.airline.code,
                        TotalFare: (this.airline.adtFare ? this.airline.adtFare.net : 0) + (this.airline.chdFare ?
                         this.airline.chdFare.net : 0) + (this.airline.infFare ? this.airline.infFare.net : 0),
                        svcClass: this.pathname !== '/TripSumary' ? this.airline.Svc_class : "C",
                        departDate: moment(x.departureDateTime).format(this.formatYMD),
                        TravelDepHour: moment(x.departureDateTime).format('HH'),
                        arrivalDate: moment(x.departureDateTime).format(this.formatYMD),
                        TravelDepMinute: moment(x.departureDateTime).format('mm'),
                        TravelArrivalMinute: moment(x.arrivalDateTime).format('mm'),
                        TravelArrivalHour: moment(x.arrivalDateTime).format('HH'),
                        ReturnDepHour: "",
                        ReturnDepMinute: "",
                        ReturnArrivalMinute: "",
                        ReturnArrivalHour: "",
                        PNR: this.airline.PNR,
                        FlightNumber: x.flightNumber,
                        seatNo: "",
                        Reuse: "",
                        specialMeal: "",
                        travelDateOffMenu: false,
                    }
                    const city = this.funcArrCity(x)

                    this.Airlines.push({
                        Code: x.airline.code,
                        Language: "en",
                        Name: x.airline.name
                    })
                    this.searchflightvalue.RouteItem.push(item)
                    setTimeout(async () => {
                        this.citytotravelFrom.push(...city)
                        this.citytotravelTo.push(...city)
                    }, 500)
                    resolve()
                })
            }
            for (const x of this.airline.retFlight) {
                await new Promise(async (resolve) => {
                    this.offlineNo += 1
                    item = await {
                        ID: this.offlineNo,
                        number: this.number + 1,
                        TripType: 'return',
                        RouteFrom: x.depCity.code,
                        RouteTo: x.arrCity.code,
                        Airline: x.airline.code,
                        svcClass: this.pathname !== '/TripSumary' ? this.airline.Svc_class : "C",
                        departDate: moment(x.departureDateTime).format(this.formatYMD),
                        arrivalDate: moment(x.arrivalDateTime).format(this.formatYMD),
                        TravelDepHour: moment(x.departureDateTime).format('HH'),
                        TravelDepMinute: moment(x.departureDateTime).format('mm'),
                        TravelArrivalHour: moment(x.arrivalDateTime).format('HH'),
                        TravelArrivalMinute: moment(x.arrivalDateTime).format('mm'),
                        ReturnDepHour: "",
                        ReturnDepMinute: "",
                        ReturnArrivalHour: "",
                        ReturnArrivalMinute: "",
                        PNR: this.airline.PNR,
                        FlightNumber: x.flightNumber,
                        seatNo: "",
                        Reuse: "",
                        specialMeal: "",
                        travelDateOffMenu: false,
                    }
                    const city = this.funcArrCity(x)
                    this.Airlines.push({
                        Code: x.airline.code,
                        Language: "en",
                        Name: x.airline.name
                    })
                    this.searchflightvalue.RouteItem.push(await item)
                    setTimeout(async () => {
                        this.citytotravelFrom.push(...city)
                        this.citytotravelTo.push(...city)
                    }, 500)
                    resolve()
                })
            }
        },
        getFlightHistory(){
            const formReqID = JSON.parse(sessionStorage.formReqID)
            axios.post(`${this.$store.state.API_PATH}FlightSearch/GetFlightHistory?val=${formReqID.TravelId}`, {}, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then(res => {
                if(res.data.Result && res.data.Result.length > 0){
                    this.flightHistory = res.data.Result.filter(x => x.Seq === '1')
                    let index = 0
                    this.flightHistory.forEach(x => {
                        this.GetCityCodeLine2(x.Origin, "From")
                        this.GetCityCodeLine2(x.Destination, "To")
                        index += 1
                        if(index === this.flightHistory.length){
                            this.showFlightHistory = true
                        }
                    })
                }
            })
        },
        funcArrCity(x){
            return [{
                Code: x.depCity.code,
                Name: x.depCity.name.split(',')[0],
                Language: "en",
                CountryCode: "",
                text: `${x.depCity.name.split(',')[0]} (${x.depCity.code})`
            },
            {
                Code: x.arrCity.code,
                Name: x.arrCity.name.split(',')[0],
                Language: "en",
                CountryCode: "",
                text: `${x.arrCity.name.split(',')[0]} (${x.arrCity.code})`
            }]
        }
    },
    created() {
        axios.post(this.$store.state.API_PATH + 'SearchManagement/GetPoppularSearch', {}, {
            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        }).then(res => {
            this.topSearch = res.data
            if (this.topSearch != null && this.topSearch.length > 0) {
                if (this.pathname === this.createTravelRqStringPath) {
                    this.citytotravelFrom.push(...this.topSearch)
                    this.citytotravelTo.push(...this.topSearch)
                } else {
                    this.GetCityCodeLine2(this.searchflightvalue.destinationCode, "First")
                    this.GetCityCodeLine2(this.searchflightvalue.originCode, "First")
                }
            }
        })
    },
    async mounted() {
        this.pathname = window.location.pathname
        this.traveler = this.travelers
        this.getFlightHistory()
        if(this.pathname === this.pathTripSum){
            this.selected.push(...this.travelers)
            const findMyTravelers = await this.travelers.filter(x => x.MyTraveler && x.MyTraveler.length > 0)
            if(findMyTravelers && findMyTravelers.length > 0){
                findMyTravelers.forEach(x => {
                    this.selected.push(...x.MyTraveler)
                })
                this.traveler = this.selected
            }
            this.svcClass = [{ code: 'C', name: 'Business' }]
            this.SetFlightOffFromTripSumPage()
        }else if(this.flightAction === this.flightActionStatus.ReIssue){
            if(!this.airline.isFlightOffline){
                if(this.airline.adtPaxs && this.airline.adtPaxs.length > 0){
                    const findAdt = this.travelers.filter(x => this.airline.adtPaxs.filter(adt => adt.emp_id === x.emp_ID).length > 0)
                    this.selected.push(...findAdt)
                }
                if(this.airline.chdPaxs && this.airline.chdPaxs.length > 0){
                    const findChd = this.travelers.filter(x => this.airline.chdPaxs.filter(chd => chd.emp_id === x.emp_ID).length > 0)
                    this.selected.push(...findChd)
                }
                if(this.airline.infPaxs && this.airline.infPaxs.length > 0){
                    const findInf = this.travelers.filter(x => this.airline.infPaxs.filter(Inf => Inf.emp_id === x.emp_ID).length > 0)
                    this.selected.push(...findInf)
                }
            }else{
                let res = []
                if(this.selectTraveler.Type === 'External'){
                    res = this.traveler.filter(x => x.emp_ID === this.selectTraveler.TravelWith)
                    this.selected.push(this.selectTraveler)
                    this.selected.push(...res)
                }else{
                    res = this.traveler.filter(x => x.TravelWith === this.selectTraveler.emp_ID)
                    this.selected.push(...res)
                    this.selected.push(this.selectTraveler)
                }
            }
            this.SetFlightOffFromTripSumPage()
        }
        this.citytotravelFrom = [{ header: this.headerCity }]
        this.searchflightvalue.departDate = moment(this.coperateData.startDate).subtract(1, 'days').format(this.formatYMD)
        this.searchflightvalue.returnDate = moment(this.coperateData.endDate).add(1, 'days').format(this.formatYMD)
        this.getSpecialMeal()
        this.flightDate.push(this.searchflightvalue.departDate, this.searchflightvalue.returnDate)
        if (this.travelers.length > 0) {
            this.travelerJson()
        }
        if (this.pathname !== this.createTravelRqStringPath && this.pathname !== this.pathTripSum) {
            this.searchflightvalue = JSON.parse(sessionStorage.searchflightReq)
        }
    }
}
</script>
<style scoped>
* {
    font-size: .8rem;
}
.btn-table {
    background: rgba(0, 174, 239, 0.1) !important;
}
</style>
