<template>
  <div>
    <v-card elevation="0" class="card" v-if="airline != null || setSession">
      <v-card-title> Flight Details </v-card-title>
      <v-card-text>
        <div class="card-border" style="background-color: white;">
          <strong class="text-profile">
            {{ airline.depFlight[0].depCity.name.split(",", 2)[1] }}
            ({{ airline.depFlight[0].depCity.code }})
            <template v-if="airline.retFlight !== null && airline.retFlight.length > 0">
              <img :src="require('@/assets/icons/tweenArrows.svg')" alt="" />
              {{ airline.retFlight[0].depCity.name.split(",", 2)[1] }}
              ({{ airline.retFlight[0].depCity.code }})
            </template>
          </strong>
          <br />
          <v-card elevation="0" class="card" style="background-color: #f8f9fa">
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="6" md="2">
                  <h3>Departure</h3>
                </v-col>
                <v-col cols="12" xs="6" md="5" class="hidden-sm-and-down">
                  <span class="">
                    {{
                      airline.depFlight[0].depDisplayDateTime.shortDate +
                        " " +
                        airline.depFlight[0].depDisplayDateTime.year
                    }}
                    ,
                    {{
                      airline.depFlight[0].depDisplayDateTime.displayTime +
                        " - " +
                        airline.depFlight[airline.depFlight.length - 1]
                        .arrDisplayDateTime.displayTime
                    }}
                  </span>
                </v-col>
                <v-col cols="12" xs="12" md="5" class="hidden-sm-and-down">
                  <span class="">
                    <strong class="text-profile" style="float: right">
                      {{ airline.depFlight[0].depCity.name.split(",", 2)[1] }}
                      ({{ airline.depFlight[0].depCity.code }})
                      <template v-if="airline.retFlight !== null && airline.retFlight.length > 0">
                        <img :src="require('@/assets/icons/tweenArrows.svg')" alt="" />
                        {{ airline.retFlight[0].depCity.name.split(",", 2)[1] }}
                        ({{ airline.retFlight[0].depCity.code }})
                      </template>
                    </strong>
                  </span>
                </v-col>
                <v-col cols="12" xs="6" md="5" class="hidden-md-and-up">
                  <strong class="text-profile">
                    {{ airline.depFlight[0].depCity.name.split(",", 2)[1] }}
                    ({{ airline.depFlight[0].depCity.code }})
                    <template v-if="airline.retFlight !== null && airline.retFlight.length > 0">
                      <img :src="require('@/assets/icons/tweenArrows.svg')" alt="" />
                      {{ airline.retFlight[0].depCity.name.split(",", 2)[1] }}
                      ({{ airline.retFlight[0].depCity.code }})
                    </template>
                  </strong>
                  <br />
                  <span class="">
                    {{
                      airline.depFlight[0].depDisplayDateTime.shortDate +
                        " " +
                        airline.depFlight[0].depDisplayDateTime.year
                    }}
                    ,
                    {{
                      airline.depFlight[0].depDisplayDateTime.displayTime +
                        " - " +
                        airline.depFlight[airline.depFlight.length - 1]
                          .arrDisplayDateTime.displayTime
                    }}
                  </span>
                </v-col>
                <v-col cols="3" md="1" sm="2">
                  <span class="text-profile">
                    <v-avatar size="50">
                      <v-img :src="
                        require('@/assets/logoAirline/square/' +
                          airline.depFlight[0].airline.code +
                          '.png')
                      " />
                    </v-avatar>
                  </span>
                </v-col>
                <v-col cols="9" md="4" sm="3">
                  <v-list dense style="background-color: #f8f9fa; margin-top: -8px">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h3>
                            {{ airline.depFlight[0].airline.name }}
                          </h3>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span style="font-size: 0.8rem">
                            <!-- Bussiness -->
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="12" md="7" xs="12" class="text-center">
                  <v-row>
                    <v-col cols="4">
                      <v-chip label color="#ccecf8" class="text-profile">
                        {{
                          airline.depFlight[0].depDisplayDateTime.displayTime
                        }}
                      </v-chip>
                      <br />
                      <span>
                        {{ airline.depFlight[0].depCity.code }}
                      </span>
                    </v-col>
                    <v-col cols="4">
                      <span>
                        <span>
                          {{ totalTimeFormat(airline.depFlight[0].flightTime, 'depart') }}
                        </span>
                        <v-img :src="require('@/assets/icons/betweenlineStop.svg')" />
                      </span>
                    </v-col>
                    <v-col cols="4">
                      <v-chip label color="#ccecf8" class="text-profile">
                        {{
                          airline.depFlight[airline.depFlight.length - 1]
                            .arrDisplayDateTime.displayTime
                        }}
                      </v-chip>
                      <br />
                      <span>
                        {{
                          airline.depFlight[airline.depFlight.length - 1]
                            .arrCity.code
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <br />
          <v-card elevation="0" class="card" v-if="airline.retFlight != null && airline.retFlight.length > 0" style="background-color: #f8f9fa">
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="2">
                  <h3>Return</h3>
                </v-col>
                <v-col cols="5" md="5" class="hidden-sm-and-down">
                  <span class="">
                    {{
                      airline.retFlight[0].depDisplayDateTime.shortDate +
                        " " +
                        airline.retFlight[0].depDisplayDateTime.year
                    }}
                    ,
                    {{
                      airline.retFlight[0].depDisplayDateTime.displayTime +
                        " - " +
                        airline.retFlight[airline.retFlight.length - 1]
                          .arrDisplayDateTime.displayTime
                    }}
                    <!-- Sun, 20 Feb 2025, 22:55 - 09:55 (+1d) -->
                  </span>
                </v-col>
                <v-col cols="5" md="5" class="hidden-sm-and-down">
                  <span class="">
                    <strong class="text-profile" style="float: right">
                      {{ airline.retFlight[0].depCity.name.split(",", 2)[1] }}
                      ({{ airline.retFlight[0].depCity.code }})
                      <img :src="require('@/assets/icons/tweenArrows.svg')" alt="" />
                      {{
                        airline.retFlight[
                          airline.retFlight.length - 1
                        ].arrCity.name.split(",", 2)[1]
                      }}
                      ({{
                        airline.retFlight[airline.retFlight.length - 1].arrCity
                          .code
                      }})
                    </strong>
                  </span>
                </v-col>
                <v-col cols="12" xs="6" md="5" class="hidden-md-and-up">
                  <strong class="text-profile">
                    {{ airline.retFlight[0].depCity.name.split(",", 2)[1] }}
                    ({{ airline.retFlight[0].depCity.code }})
                    ({{ airline.retFlight[0].depCity.code }})
                    <img :src="require('@/assets/icons/tweenArrows.svg')" alt="" />
                    {{
                      airline.retFlight[
                        airline.retFlight.length - 1
                      ].arrCity.name.split(",", 2)[1]
                    }}
                    ({{
                      airline.retFlight[airline.retFlight.length - 1].arrCity
                        .code
                    }})
                  </strong>
                  <br />
                  <span class="">
                    {{
                      airline.retFlight[0].depDisplayDateTime.shortDate +
                        " " +
                        airline.retFlight[0].depDisplayDateTime.year
                    }}
                    ,
                    {{
                      airline.retFlight[0].depDisplayDateTime.displayTime +
                        " - " +
                        airline.retFlight[airline.retFlight.length - 1]
                          .arrDisplayDateTime.displayTime
                    }}
                    <!-- Sun, 20 Feb 2025, 22:55 - 09:55 (+1d) -->
                  </span>
                </v-col>
                <v-col cols="3" md="1" sm="2">
                  <span class="text-profile">
                    <v-avatar size="50">
                      <v-img :src="
                        require('@/assets/logoAirline/square/' +
                          airline.retFlight[0].airline.code +
                          '.png')
                      " />
                    </v-avatar>
                  </span>
                </v-col>
                <v-col cols="9" md="4" sm="3">
                  <v-list dense style="background-color: #f8f9fa; margin-top: -8px">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h3>
                            {{ airline.retFlight[0].airline.name }}
                          </h3>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span style="font-size: 0.8rem"> </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="12" md="7" xs="12" class="text-center">
                  <v-row>
                    <v-col cols="4">
                      <v-chip label color="#ccecf8" class="text-profile">
                        {{
                          airline.retFlight[0].depDisplayDateTime.displayTime
                        }}
                      </v-chip>
                      <br />
                      <span>
                        {{ airline.retFlight[0].depCity.code }}
                      </span>
                    </v-col>
                    <v-col cols="4">
                      <div style="height: 13px"></div>
                      <span style="position: relative">
                        <span>
                          {{ totalTimeFormat(airline.retFlight[0].flightTime, 'return') }}
                        </span>
                        <v-img :src="require('@/assets/icons/betweenlineStop.svg')" />
                      </span>
                    </v-col>
                    <v-col cols="4">
                      <v-chip label color="#ccecf8" class="text-profile">
                        {{
                          airline.retFlight[airline.retFlight.length - 1]
                            .arrDisplayDateTime.displayTime
                        }}
                      </v-chip>
                      <br />
                      <span>
                        {{
                          airline.retFlight[airline.retFlight.length - 1]
                            .arrCity.code
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
      <template>
        <v-card-title> Contact Details </v-card-title>
        <v-card-text>
          <div class="card-border">
            <v-row>
              <v-col cols="6" xs="6" md="3">
                Name
                <br />
                <strong style="font-size: 0.8rem">
                  {{ profile.TITLE }}
                  {{ profile.FIRSTNAME + " " + profile.LASTNAME }}
                </strong>
              </v-col>
              <v-col cols="6" xs="6" md="3">
                Email
                <br />
                <strong style="font-size: 0.8rem">
                  {{ profile.EMAIL_ID }}
                </strong>
              </v-col>
              <v-col cols="6" xs="6" md="3">
                Office Phone No.
                <br />
                <strong style="font-size: 0.8rem"> - </strong>
              </v-col>
              <v-col cols="6" xs="6" md="3">
                Mobile Phone No.
                <br />
                <strong style="font-size: 0.8rem">
                  {{ profile.MOBILE_NO }}
                </strong>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-title> Traveler Information </v-card-title>
        <v-card-text
          v-if="pathname !== this.pathCancelFlights && ticketPricingModified && ticketPricingModified != undefined && ticketPricingModified.TicketPricing.length > 0">
          <!-- Booking Flight -->
          <div v-for="(itemTraveler, adtindex) in ticketPricingModified.TicketPricing" :key="adtindex">
            <div class="" v-if="itemTraveler.data != null && itemTraveler.data != undefined">
              <div class="card-border" style="background-color: white;">
                    <div class="f-card-header font-W700 pb-3">Traveler {{ adtindex += 1 }} ({{ itemTraveler.TravelerType }}) <span>{{ itemTraveler.age }}</span></div>
                  <div class="card-border">
                    <h3>Personal Data</h3>
                    <br />
                    <v-row>
                      <v-col cols="6" xs="6" md="3">
                        <span> Title </span>
                        <br />
                        <span class="text-profile">
                          {{ itemTraveler.data.contactInfo.title.toUpperCase() }}
                        </span>
                      </v-col>
                      <v-col cols="6" xs="6" md="3">
                        <span> Firstname </span>
                        <br />
                        <span class="text-profile">
                          {{ itemTraveler.data.contactInfo.firstname }}
                        </span>
                      </v-col>
                      <v-col cols="6" xs="6" md="3">
                        <span> Midname </span>
                        <br />
                        <span class="text-profile">
                          {{
                            itemTraveler.data.contactInfo.middlename != null
                              ? itemTraveler.data.contactInfo.middlename
                              : "-"
                          }}
                        </span>
                      </v-col>
                      <v-col cols="6" xs="6" md="3">
                        <span> Lastname </span>
                        <br />
                        <span class="text-profile">
                          {{ itemTraveler.data.contactInfo.lastname }}
                        </span>
                      </v-col>
                      <v-col cols="6">
                        <span> Email </span>
                        <br />
                        <span class="text-profile">
                          <v-text-field dense outlined background-color="white" @change="
                              setData(
                                itemTraveler.data.contactInfo.email,
                                itemTraveler,
                                'Adt',
                                itemTraveler.data.contactInfo.emp_id,
                              )"
                          v-model="itemTraveler.data.contactInfo.email" v-if="itemTraveler.TravelerType === 'External'"></v-text-field>
                          <span v-else>
                            {{ itemTraveler.data.contactInfo.email }}
                          </span>
                        </span>
                      </v-col>
                      <v-col cols="6">
                        <span> Company </span>
                        <br />
                        <span class="text-profile">
                          {{ coperateData.CompanyName }}
                        </span>
                      </v-col>
                      <v-col cols="12" md="6" xs=12>
                        <span> Office Phone No. </span>
                        <br />
                        <span class="text-profile">
                          <v-text-field dense outlined background-color="white"></v-text-field>
                        </span>
                      </v-col>
                      <v-col cols="12" md="6" xs=12>
                        <span> Mobile Phone No. <span style="color: red">*</span></span>
                        <br />
                        <v-row>
                                <v-col cols="5">
                                    <v-autocomplete :items="countryCode" v-model="MOBILE_CODE" dense outlined
                                        background-color="white" item-text="PhoneCode"
                                        item-value="PhoneCode">
                                    </v-autocomplete>
                                </v-col>
                                <v-col>
                                    <v-text-field dense outlined type="text" @change="
                                      setData(
                                        itemTraveler.data.contactInfo.telNo,
                                        itemTraveler,
                                        'Adt',
                                        itemTraveler.data.contactInfo.emp_id,
                                      )"
                                      @input="(itemTraveler.data.contactInfo.telNo = validateNumberText(itemTraveler.data.contactInfo.telNo))"
                                      v-model="itemTraveler.data.contactInfo.telNo" />
                                </v-col>
                          </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <br />
                  <div class="card-border">
                    <h3>Special Request</h3>
                    <br />
                    <v-row v-if="itemTraveler.DefaultSpecialRequest">
                      <v-col cols="12" md="6">
                        <span> Preferred Seat </span>
                        <br />
                        <span class="text-profile">
                          <v-select background-color="white" v-model="itemTraveler.DefaultSpecialRequest.PreferredSeat"
                            item-value="Code"
                            @change="setData(specialRequestCheck(itemTraveler.DefaultSpecialRequest.PreferredSeat, 'seat'), itemTraveler, 'Adt')"
                            item-text="Description" :items="preferredSeat" dense outlined></v-select>
                        </span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <span> Special Meal </span>
                        <br />
                        <span class="text-profile">
                          <v-select background-color="white" v-model="itemTraveler.DefaultSpecialRequest.SpecialMeal"
                            @change="
                            setData(specialRequestCheck(itemTraveler.DefaultSpecialRequest.SpecialMeal, 'meal'), itemTraveler, 'Adt')"
                            :items="specialMeal" item-value="MealCode" item-text="Description" dense outlined></v-select>
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                  <br />
                  <div class="card-border">
                    <h3>Request more baggage</h3>
                    <br />
                    <v-row>
                      <v-col cols="12" md="6">
                        <span> Additional Baggage </span>
                        <br />
                        <span class="text-profile">
                          <v-select background-color="white" v-model="itemTraveler.DefaultSpecialRequest.baggageRemark"
                            item-value="Code"
                            @change="setData(specialRequestCheck(itemTraveler.DefaultSpecialRequest.baggageRemark, 'baggage'), itemTraveler, 'Adt')"
                            item-text="Description" :items="Baggage" dense outlined></v-select>
                        </span>
                      </v-col>
                      <v-col cols="12" md="6">
                      </v-col>
                    </v-row>
                  </div>
                  <br />
                  <div class="card-border">
                    <br />
                    <h3>
                      Frequent Flyer
                    </h3>
                    <br />
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <span> Earn Miles </span>
                        <br />
                        <span class="text-profile">
                          <v-select background-color="white" :items="itemTraveler.ManagementEarnMiles.EarnMiles"
                            item-text="Name" @change="selectEarnmile(itemTraveler.ManagementEarnMiles.SelectEarnMile)"
                            item-value="EarnMileOID" v-model="itemTraveler.ManagementEarnMiles.SelectEarnMile.EarnMileOID"
                            dense outlined>
                          </v-select>
                        </span>
                      </v-col>
                      <v-col cols="12" xs="12" md="8">
                        <span> Member ID </span>
                        <br />
                        <span class="text-profile">
                          <v-text-field background-color="white" dense outlined
                            v-model="itemTraveler.ManagementEarnMiles.SelectEarnMile.MemberID"></v-text-field>
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                <br />
              </div>
            </div>
            <br />
          </div>
          <div v-if="adtTicketPricing && adtTicketPricing.length > 0 && TicketSettings">
            <div class="card-border" style="background-color: white;">
              <div v-for="(itemadtPax, index) in adtTicketPricing" :key="index">
                <div class="f-card-header font-W700 pb-3">Traveler {{ (ticketPricingModified.TicketPricing.length + (index + 1)) }} <span>(External) Adult</span></div>
                <div class="card-border">
                  <h3>Personal Data</h3>
                  <br />
                  <v-row>
                    <v-col cols="6" xs="6" md="3">
                      <span> Title </span>
                      <br />
                      <span class="text-profile">
                        {{ itemadtPax.title.toUpperCase() }}
                      </span>
                    </v-col>
                    <v-col cols="6" xs="6" md="3">
                      <span> Firstname </span>
                      <br />
                      <span class="text-profile">
                        {{ itemadtPax.firstname }}
                      </span>
                    </v-col>
                    <v-col cols="6" xs="6" md="3">
                      <span> Midname </span>
                      <br />
                      <span class="text-profile">
                        {{
                          itemadtPax.middlename != null
                            ? itemadtPax.middlename
                            : "-"
                        }}
                      </span>
                    </v-col>
                    <v-col cols="6" xs="6" md="3">
                      <span> Lastname </span>
                      <br />
                      <span class="text-profile">
                        {{ itemadtPax.lastname }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <span> Email </span>
                      <br />
                      <span class="text-profile">
                        {{ itemadtPax.email }}
                      </span>
                    </v-col>
                    <v-col cols="12" md="6" xs=12>
                    </v-col>
                    <v-col cols="12" md="6" xs=12>
                      <span> Travel With</span>
                      <br />
                      <v-select outlined dense background-color="white" @change="withAdultTraveler(itemadtPax)"
                        v-model="itemadtPax.travelwithEmpID" item-text="fullname" item-value="emp_ID"
                        :items="travelWith"></v-select>
                    </v-col>
                    <v-col cols="12" md="6" xs=12>
                    </v-col>
                  </v-row>
                </div>
                <br />
                <div class="card-border">
                  <h3>Special Request</h3>
                  <br />
                  <v-row>
                    <v-col cols="12" md="6">
                      <span> Preferred Seat </span>
                      <br />
                      <span class="text-profile">
                        <v-select background-color="white" v-model="itemadtPax.seatRequest" item-value="Code" @change="setData(specialRequestCheck(
                          itemadtPax.seatRequest,
                          'seat'
                        ),
                          itemadtPax,
                          'Chd',
                          itemadtPax.emp_id
                        )" item-text="Description" :items="preferredSeat" dense outlined></v-select>
                      </span>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span> Special Meal </span>
                      <br />
                      <span class="text-profile">
                        <v-select background-color="white" v-model="itemadtPax.mealRequest" @change="
                          setData(
                            specialRequestCheck(
                              itemadtPax.mealRequest,
                              'meal'
                            ),
                            itemadtPax,
                            'Chd',
                            itemadtPax.emp_id
                          )
                        " :items="specialMeal" item-value="MealCode" item-text="Description" dense
                          outlined></v-select>
                      </span>
                    </v-col>
                  </v-row>
                </div>
                <br />
              </div>
            </div>
          </div>
          <br />
          <div v-if="chdTicketPricing && chdTicketPricing.length > 0 && TicketSettings">
            <div class="card-border" style="background-color: white;">
              <div v-for="(itemchdPax, index) in chdTicketPricing" :key="index">
                <div class="f-card-header font-W700 pb-3">Traveler {{ (ticketPricingModified.TicketPricing.length + (index + 1)) }} <span>(External) Child</span></div>
                <div class="card-border">
                  <h3>Personal Data</h3>
                  <br />
                  <v-row>
                    <v-col cols="6" xs="6" md="3">
                      <span> Title </span>
                      <br />
                      <span class="text-profile">
                        {{ itemchdPax.title.toUpperCase() }}
                      </span>
                    </v-col>
                    <v-col cols="6" xs="6" md="3">
                      <span> Firstname </span>
                      <br />
                      <span class="text-profile">
                        {{ itemchdPax.firstname }}
                      </span>
                    </v-col>
                    <v-col cols="6" xs="6" md="3">
                      <span> Midname </span>
                      <br />
                      <span class="text-profile">
                        {{
                          itemchdPax.middlename != null
                            ? itemchdPax.middlename
                            : "-"
                        }}
                      </span>
                    </v-col>
                    <v-col cols="6" xs="6" md="3">
                      <span> Lastname </span>
                      <br />
                      <span class="text-profile">
                        {{ itemchdPax.lastname }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                    </v-col>
                    <v-col cols="12" md="6" xs=12>
                    </v-col>
                    <v-col cols="12" md="6" xs=12>
                      <span> Travel With</span>
                      <br />
                      <v-select outlined dense background-color="white" @change="withAdultTraveler(itemchdPax)"
                        v-model="itemchdPax.travelwithEmpID" item-text="fullname" item-value="emp_ID"
                        :items="travelWith"></v-select>
                    </v-col>
                    <v-col cols="12" md="6" xs=12>
                    </v-col>
                  </v-row>
                </div>
                <br />
                <div class="card-border">
                  <h3>Special Request</h3>
                  <br />
                  <v-row>
                    <v-col cols="12" md="6">
                      <span> Preferred Seat </span>
                      <br />
                      <span class="text-profile">
                        <v-select background-color="white" v-model="itemchdPax.seatRequest" item-value="Code" @change="setData(specialRequestCheck(
                          itemchdPax.seatRequest,
                          'seat'
                        ),
                          itemchdPax,
                          'Chd',
                          itemchdPax.emp_id
                        )" item-text="Description" :items="preferredSeat" dense outlined></v-select>
                      </span>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span> Special Meal </span>
                      <br />
                      <span class="text-profile">
                        <v-select background-color="white" v-model="itemchdPax.mealRequest" @change="
                          setData(
                            specialRequestCheck(
                              itemchdPax.mealRequest,
                              'meal'
                            ),
                            itemchdPax,
                            'Chd',
                            itemchdPax.emp_id
                          )
                        " :items="specialMeal" item-value="MealCode" item-text="Description" dense
                          outlined></v-select>
                      </span>
                    </v-col>
                  </v-row>
                </div>
                <br />
                 <div class="card-border" v-if="false">
                    <h3>Request more baggage</h3>
                    <br />
                    <v-row>
                      <v-col cols="12" md="6">
                        <span> Additional Baggage </span>
                        <br />
                        <span class="text-profile">
                          <v-select background-color="white" v-model="itemchdPax.Baggage"
                            item-value="Code"
                            @change="setData(specialRequestCheck(itemchdPax.Baggage, 'baggage'), itemchdPax, 'Chd')"
                            item-text="Description" :items="Baggage" dense outlined></v-select>
                        </span>
                      </v-col>
                      <v-col cols="12" md="6">
                      </v-col>
                    </v-row>
                  </div>
              </div>
            </div>
          </div>
          <br />
          <div v-if="infTicketPricing && infTicketPricing.length > 0 && TicketSettings">
            <div class="card-border" style="background-color: white;">
              <div v-for="(iteminfPax, index) in infTicketPricing" :key="index">
                <div class="f-card-header font-W700 pb-3">Traveler {{ (ticketPricingModified.TicketPricing.length + (index + 1)) }} <span>(External) Infant</span></div>
                <div class="card-border">
                  <h3>Personal Data</h3>
                  <br />
                  <v-row>
                    <v-col cols="6" xs="6" md="3">
                      <span> Title </span>
                      <br />
                      <span class="text-profile">
                        {{ iteminfPax.title.toUpperCase() }}
                      </span>
                    </v-col>
                    <v-col cols="6" xs="6" md="3">
                      <span> Firstname </span>
                      <br />
                      <span class="text-profile">
                        {{ iteminfPax.firstname }}
                      </span>
                    </v-col>
                    <v-col cols="6" xs="6" md="3">
                      <span> Midname </span>
                      <br />
                      <span class="text-profile">
                        {{
                          iteminfPax.middlename != null
                            ? iteminfPax.middlename
                            : "-"
                        }}
                      </span>
                    </v-col>
                    <v-col cols="6" xs="6" md="3">
                      <span> Lastname </span>
                      <br />
                      <span class="text-profile">
                        {{ iteminfPax.lastname }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                    </v-col>
                    <v-col cols="12" md="6" xs=12>
                    </v-col>
                    <v-col cols="12" md="6" xs=12>
                      <span> Travel With</span>
                      <br />
                      <v-select outlined dense background-color="white" @change="withAdultTraveler(iteminfPax)"
                        v-model="iteminfPax.travelwithEmpID" item-text="fullname" item-value="emp_ID"
                        :items="travelWith"></v-select>
                    </v-col>
                    <v-col cols="12" md="6" xs=12>
                    </v-col>
                  </v-row>
                </div>
                <br />
                <div class="card-border">
                  <h3>Special Request</h3>
                  <br />
                  <v-row>
                    <v-col cols="12" md="6">
                      <span> Preferred Seat </span>
                      <br />
                      <span class="text-profile">
                        <v-select background-color="white" v-model="iteminfPax.seatRequest" item-value="Code" @change="setData(specialRequestCheck(
                          iteminfPax.seatRequest,
                          'seat'
                        ),
                          iteminfPax,
                          'Inf',
                          iteminfPax.emp_id
                        )" item-text="Description" :items="preferredSeat" dense outlined></v-select>
                      </span>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span> Special Meal </span>
                      <br />
                      <span class="text-profile">
                        <v-select background-color="white" v-model="iteminfPax.mealRequest" @change="
                          setData(
                            specialRequestCheck(
                              iteminfPax.mealRequest,
                              'meal'
                            ),
                            iteminfPax,
                            'Inf',
                            iteminfPax.emp_id
                          )
                        " :items="specialMeal" item-value="MealCode" item-text="Description" dense
                          outlined></v-select>
                      </span>
                    </v-col>
                  </v-row>
                </div>
                <br />
                 <div class="card-border" v-if="false">
                    <h3>Request more baggage</h3>
                    <br />
                    <v-row>
                      <v-col cols="12" md="6">
                        <span> Additional Baggage </span>
                        <br />
                        <span class="text-profile">
                          <v-select background-color="white" v-model="iteminfPax.Baggage"
                            item-value="Code"
                            @change="setData(specialRequestCheck(iteminfPax.Baggage, 'baggage'), iteminfPax, 'Inf')"
                            item-text="Description" :items="Baggage" dense outlined></v-select>
                        </span>
                      </v-col>
                      <v-col cols="12" md="6">
                      </v-col>
                    </v-row>
                  </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <!--Cancel Flight-->
        <v-card-text v-else-if="pathname === this.pathCancelFlights">
          <span v-if="travelers != null">
            <div v-for="(itemTraveler, adtindex) in travelers" :key="adtindex">
              <div class="card-border" style="background-color: white;">
                  <div class="f-card-header font-W700 pb-3">
                    Traveler {{ adtindex += 1 }} ({{ itemTraveler.CreateBy ?
                    'External' : 'Internal' }}) <span>{{ itemTraveler.age }}</span>
                  </div>
                  <div class="card-border">
                    <h3>Personal Data</h3>
                    <br />
                    <v-row>
                      <v-col cols="6" xs="6" md="3">
                        <span> Title </span>
                        <br />
                        <span class="text-profile">
                          {{ itemTraveler.title ? itemTraveler.title.toUpperCase() : null }}
                        </span>
                      </v-col>
                      <v-col cols="6" xs="6" md="3">
                        <span> Firstname </span>
                        <br />
                        <span class="text-profile">
                          {{ itemTraveler.firstname }}
                        </span>
                      </v-col>
                      <v-col cols="6" xs="6" md="3">
                        <span> Midname </span>
                        <br />
                        <span class="text-profile">
                          {{
                            itemTraveler.middlename != null
                              ? itemTraveler.middlename
                              : "-"
                          }}
                        </span>
                      </v-col>
                      <v-col cols="6" xs="6" md="3">
                        <span> Lastname </span>
                        <br />
                        <span class="text-profile">
                          {{ itemTraveler.lastname }}
                        </span>
                      </v-col>
                      <v-col cols="6">
                        <span> Email </span>
                        <br />
                        <span class="text-profile">
                          {{ itemTraveler.email }}
                        </span>
                      </v-col>
                      <v-col cols="6">
                        <span> Company </span>
                        <br />
                        <span class="text-profile">
                          {{ coperateData.CompanyName }}
                        </span>
                      </v-col>
                      <v-col cols="12" md="6" xs=12>
                        <span> Office Phone No. </span>
                        <br />
                        <span class="text-profile">
                          -
                        </span>
                      </v-col>
                      <v-col cols="12" md="6" xs=12>
                        <span> Mobile Phone No. </span>
                        <br />
                        <span class="text-profile">
                          {{ itemTraveler.mobile ? itemTraveler.mobile : '' }}
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                  <br />
                  <div class="card-border">
                    <h3>Special Request</h3>
                    <br />
                    <v-row v-if="itemTraveler.DefaultSpecialRequest">
                      <v-col cols="12" md="6">
                        <span> Preferred Seat </span>
                        <br />
                        <span class="text-profile">
                          {{specialRequestCheck(itemTraveler.DefaultSpecialRequest.PreferredSeat, 'seat')}}
                        </span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <span> Special Meal </span>
                        <br />
                        <span class="text-profile">
                          {{ specialRequestCheck(itemTraveler.DefaultSpecialRequest.SpecialMeal, 'meal') }}
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                  <br />

                  <div class="card-border" v-if="itemTraveler.ManagementEarnMiles">
                    <br />
                    <h3>
                      Frequent Flyer
                    </h3>
                    <br />
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <span> Earn Miles </span>
                        <br />
                        <span class="text-profile">
                          <v-select background-color="white" :items="itemTraveler.ManagementEarnMiles.EarnMiles"
                            item-text="Airline" @change="selectEarnmile(itemTraveler.ManagementEarnMiles.SelectEarnMile)"
                            item-value="EarnMileOID" v-model="itemTraveler.ManagementEarnMiles.SelectEarnMile.EarnMileOID"
                            dense outlined>
                          </v-select>
                        </span>
                      </v-col>
                      <v-col cols="12" xs="12" md="8">
                        <span> Member ID </span>
                        <br />
                        <span class="text-profile">
                          <v-text-field background-color="white" dense outlined
                            v-model="itemTraveler.ManagementEarnMiles.SelectEarnMile.MemberID"></v-text-field>
                        </span>
                      </v-col>
                    </v-row>
                  </div>
              </div>
              <br />
            </div>
          </span>
        </v-card-text>
        <v-card-text v-else>
          <div class="text-center">
            <div style="height: 100px"></div>
            <v-progress-circular indeterminate color="primary" :size="80" :width="6"></v-progress-circular>
            <div style="height: 100px"></div>
          </div>
        </v-card-text>
        <v-card-text>
          <v-card-title> Fare Conditions </v-card-title>
          <div class="card-border" style="background-color: white;">
            <div class="card-border">
              <strong>Web will use conditions sane as airlines</strong> Anyway, it the
              fare conditions of Departure flight are different from return,
              will use the conditions of the lowest fare to issue tickets.
              <br />
              <div v-for="(itemFareRule, fareRulesIndex) in airline.fareRules" :key="fareRulesIndex">
                <h3>
                  {{
                    itemFareRule.origin.name +
                      " - " +
                      itemFareRule.destination.name
                  }}
                </h3>
                <br />
                <v-expansion-panels hover>
                  <v-expansion-panel v-for="(itemRule, itemFareRuleIndex) in itemFareRule.rules" :key="itemFareRuleIndex">
                    <v-expansion-panel-header>
                      <v-img :src="require('@/assets/icons/ExpandAdd.svg')" max-height="30" max-width="30" />
                      <span class="ms-3" style="color: #009fda">{{
                        itemRule.fareRuleText[0]
                      }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span v-for="(itemdes, fareRuleTextIndex) in itemRule.fareRuleText" :key="fareRuleTextIndex">
                        <span v-if="fareRuleTextIndex != 0">
                          {{ itemdes }}
                          <br />
                        </span>
                      </span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <br />
              </div>
              <template v-if="airline.fareRules && airline.fareRules.length === 1">
                <div v-for="(itemFareRule, i) in airline.fareRules" :key="'A'+i">
                  <h3>
                    {{
                        itemFareRule.destination.name +
                        " - " +
                        itemFareRule.origin.name
                    }}
                  </h3>
                  <br />
                  <v-expansion-panels hover>
                    <v-expansion-panel v-for="(itemRule, indexRule2) in itemFareRule.rules" :key="indexRule2">
                      <v-expansion-panel-header>
                        <v-img :src="require('@/assets/icons/ExpandAdd.svg')" max-height="30" max-width="30" />
                        <span class="ms-3" style="color: #009fda">{{
                          itemRule.fareRuleText[0]
                        }}</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <span v-for="(itemdes, indexdes2) in itemRule.fareRuleText" :key="indexdes2">
                          <span v-if="indexdes2 != 0">
                            {{ itemdes }}
                            <br />
                          </span>
                        </span>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <br />
                </div>
              </template>
            </div>
          </div>
        </v-card-text>
        <v-card-text v-if="this.pathname !== pathCancelFlights" class="hidden-sm-and-down">
          <v-checkbox @click="checkboxDes()" v-model="checkboxDescription.chk1"
            label="Agree to all of the above conditions. I have read all the terms carefully." hide-details>
          </v-checkbox>
          <v-checkbox @click="checkboxDes()" v-model="checkboxDescription.chk2"
            label="Confirm All review of traveler details are correct." hide-details></v-checkbox>
        </v-card-text>
        <v-card-text v-if="this.pathname !== pathCancelFlights" class="hidden-md-and-up">
          <v-checkbox @click="checkboxDesMobile()" v-model="checkboxDescription.chk1"
            label="Agree to all of the above conditions. I have read all the terms carefully." hide-details>
          </v-checkbox>
          <v-checkbox @click="checkboxDesMobile()" v-model="checkboxDescription.chk2"
            label="Confirm All review of traveler details are correct." hide-details></v-checkbox>
        </v-card-text>
      </template>
    </v-card>
    <v-dialog persistent v-model="flightdialog.flightdialogOpen" :width="flightdialog.dialogalert ? '500' : ''" max-width>
      <v-card v-if="flightdialog.dialogalert">
        <v-card-title class="">
          Upgrade Class
        </v-card-title>
        <v-card-text>
          You can upgrade this flights to business class by policy. <br />
          If you would like to upgrade please click <strong>‘Confirm’</strong> button,<br />
          your request will be booked as offline flight.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text small @click="flightdialog.flightdialogOpen = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="upgradeConfirm()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <FlightBooking :travelers="travelers" :key="reloading" @closeflightbook="flightdialog.dialogalert = !$event" :coperateData="coperateData" :airline="airline">
        </FlightBooking>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogalert" width="500" transition="dialog-top-transition">
      <v-card>
        <v-card-title class="">
          <h4>Save Data</h4>
        </v-card-title>

        <v-card-text>
          <strong> "{{ datafocus.value }}" </strong>
          would you like to save data to your Traveler Profile
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="buttonloading" text small
            @click="saveData(datafocus.travel, ''), (dialogalert = false)">
            No
          </v-btn>
          <v-btn color="primary" :loading="buttonloading" small @click="saveData(datafocus.travel, 'save')">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import FlightBooking from '@/components/searchFlight/FlightBooking.vue';
export default {
  name: "FlightDetail",
  props: {
    formContinue: false,
    airline: Object,
    remove: Boolean,
    travelers: Array,
    setSession: Boolean
  },
  watch: {
    async setSession() {
      await this.TravelersWithAdtfilter()
    }
  },
  components:{
    FlightBooking
  },
  data() {
    return {
      MOBILE_CODE: "",
      countryCode: [],
      businessTravel1: "On the job training (OJT) / On the job aassignment (OJA)",
      businessTravel2: "Scholarship",
      coperateData: {},
      pathCancelFlights: "/CancelFlights",
      ticketPricingReq: null,
      noPreference: "No Preference",
      reloading: 0,
      renderlevel: 0,
      flightdialog: {
        flightdialogOpen: false,
        dialogalert: false,
        flightBookingDialog: false,
        value: []
      },
      TicketSettings: false,
      chdTicketPricing: [],
      infTicketPricing: [],
      travelWith: [],
      rule: {
        telNo: [
          val => (val || '').length > 0 || 'This Business Travel Type is required'
        ],
      },
      modifyPassport: false,
      checkboxDescription: {
        chk1: false,
        chk2: false,
      },
      searchFlight: null,
      isActive: false,
      tab: null,
      i: 1,
      profile: {},
      EarnMiles: [],
      datafocus: {
        value: "",
        travel: {},
        age: '',
        emp_ID: ''
      },
      phoneNumber: 0,
      countInternal: 0,
      dialogalert: false,
      ticketPricingModified: null,
      buttonloading: false,
      specialMeal: [],
      Baggage: [],
      preferredSeat: [],
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "num",
        },
        {
          text: "Name",
          value: "fullname",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Mobile No.",
          value: "mobile",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "ETR",
          value: "ert",
        },
        {
          text: "View",
          value: "view",
        },
      ],
      pathname: null,
      ticketPricing: null,
      phoneNumberRules: [(v) => !!v || "Telephone Number is required"],
    };
  },
  computed: {
  },
  methods: {
    GetMobileCode(){
           this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}CountryCode/GetPhoneCode`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                   this.countryCode = res.data.Result
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
    totalTimeFormat(val, type){
      let res = ''
      if(val){
        console.log(this.ticketPricingReq, val);
          res = `${val.slice(0, 2)}h ${val.slice(2, 4)}m`
          let diffFlightBreakS1 = 0
          let diffFlightBreakS2 = 0
          const hours = val.slice(0, 2)
          if(type === 'depart' && this.ticketPricingReq.S1_flightBreak && this.ticketPricingReq.S1_flightBreak !== 0){
            diffFlightBreakS1 = parseInt(val.slice(0, 2)) - parseInt(this.ticketPricingReq.S1_flightBreak.slice(0, 2))
          }
          if(type === 'return' && this.ticketPricingReq.S2_flightBreak && this.ticketPricingReq?.S2_flightBreak !== 0){
            diffFlightBreakS2 = parseInt(val.slice(0, 2)) - parseInt(this.ticketPricingReq.S2_flightBreak.slice(0, 2))
          }
          if(diffFlightBreakS1 > 0 || diffFlightBreakS2 > 0){
            this.shortHaulHaveBreakCheck(diffFlightBreakS1, diffFlightBreakS2)
          }else{
            this.shortHaulCheck(hours)
          }
      }
      return res
    },
    shortHaulCheck(hours){
      if(this.pathname !== this.pathCancelFlights &&
        this.travelers[0].TariffLevel.SelectFlight.code === 'Y' &&
        this.coperateData.BusinsessTravelType !== 'Domestic' &&
        this.coperateData.PurId !== this.businessTravel2 &&
        this.coperateData.PurId !== this.businessTravel1 &&
        this.renderlevel === 0 && this.travelers.length > 0 &&
        this.travelers[0].TariffLevel.ShortHaulCheck &&
        hours >= this.travelers[0].TariffLevel.Hours){
          this.flightdialog.dialogalert = true
          this.flightdialog.flightdialogOpen = true
          this.renderlevel += 1
      }
    },
    shortHaulHaveBreakCheck(diffFlightBreakS1, diffFlightBreakS2){
      if(this.pathname !== this.pathCancelFlights &&
        this.travelers[0].TariffLevel.SelectFlight.code === 'Y' &&
        this.coperateData.BusinsessTravelType !== 'Domestic' &&
        this.coperateData.PurId !== this.businessTravel2 &&
        this.coperateData.PurId !== this.businessTravel1 &&
        this.renderlevel === 0 && this.travelers.length > 0 &&
        this.travelers[0].TariffLevel.ShortHaulCheck &&
        (diffFlightBreakS1 >= this.travelers[0].TariffLevel.Hours || diffFlightBreakS2 >= this.travelers[0].TariffLevel.Hours)){
          this.flightdialog.dialogalert = true
          this.flightdialog.flightdialogOpen = true
          this.renderlevel += 1
      }
    },
    upgradeConfirm(){
      this.flightdialog.dialogalert = false
    },
    pagereloading() {
      this.reloading += 1
    },
    closeUpgradeClass() {
      this.flightdialog.flightdialogOpen = false
    },
    validateNumberText(val) {
      return val.replace(/\D/g, '');
    },
    async TravelersWithAdtfilter(){
      if (await this.setSession && await sessionStorage.ticketPricingModified && await sessionStorage.ticketPricingModified !== undefined) {
        this.adtTicketPricing = []
        this.chdTicketPricing = []
        this.infTicketPricing = []
        this.ticketPricingModified = await JSON.parse(sessionStorage.ticketPricingModified)
        this.ticketPricingModified.TicketPricing.forEach(elm => {
          this.travelWith.push({ fullname: `${elm.data.contactInfo.firstname} ${elm.data.contactInfo.lastname}`, emp_ID: elm.data.adtPaxs[0].emp_id })
        })
        await this.ticketPricingModified.TicketPricing.filter(x => x.data && x.data !== undefined &&
          x.data.adtPaxs && x.data.adtPaxs.length > 0 &&
          x.data.adtPaxs.filter(xl => xl.travelwithEmpID).length > 0).forEach(async elm => {
          if(elm.data.adtPaxs && elm.data.adtPaxs.length > 0){
            await elm.data.adtPaxs.forEach(async adtPax => {
              if(adtPax.travelwithEmpID){
                this.adtTicketPricing.push(await adtPax)
              }
            })
          }
        })
        await this.ticketPricingModified.TicketPricing.filter(x => x.data && x.data !== undefined && x.data.chdPaxs && x.data.chdPaxs.length > 0).forEach(async elm => {
          if(elm.data.chdPaxs && elm.data.chdPaxs.length > 0){
            await elm.data.chdPaxs.forEach(async chdPax => {
              this.chdTicketPricing.push(await chdPax)
            })
          }
        })
        await this.ticketPricingModified.TicketPricing.filter(x => x.data && x.data !== undefined && x.data.infPaxs && x.data.infPaxs.length > 0).forEach(async elm => {
          if(elm.data.infPaxs && elm.data.infPaxs.length > 0){
            await elm.data.infPaxs.forEach(async infPax => {
              this.infTicketPricing.push(await infPax)
            })
          }
        })
        this.chdTicketPricing = await this
        .chdTicketPricing.sort((a, b) => {return a.id - b.id})
        this.infTicketPricing = await this
        .infTicketPricing.sort((a, b) => {return a.id - b.id})
        this.TicketSettings = true
      }
    },
    async withAdultTraveler(val) {
      this.$store.state.LOADING = true
      await this.ticketPricingModified.TicketPricing.forEach(async element => {
        if(await element.data.chdPaxs){
          element.data.chdPaxs.forEach(async x => {
            if(x.emp_id === val.emp_id){
              x.travelwithEmpID = val.travelwithEmpID
            }
          })
        }
      });
      setTimeout(() => {
        axios.post(this.$store.state.API_PATH + "RecheckMultiTicketPricing", this.ticketPricingModified.TicketPricing, {
          headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        }).then(async res => {
          if (res.data.Status) {
            this.ticketPricingModified.TicketPricing = res.data.Result
            sessionStorage.ticketPricingModified = JSON.stringify(this.ticketPricingModified)
            await this.TravelersWithAdtfilter()
            this.$store.state.LOADING = false
          }
        }).catch(err => {
          console.log(err)
          this.$store.state.LOADING = false
        })
      }, 100);
    },
    selectEarnmile(selectEarnmile) {
      if (selectEarnmile.EarnMileOID == null) {
        selectEarnmile = this.EarnMiles.filter(x => x.Airline === selectEarnmile.Airline)[0]
      } else {
        this.ticketPricingModified.TicketPricing.forEach(async data => {
          if (await data.ManagementEarnMiles.SelectEarnMile.EMPLOYEE_ID === selectEarnmile.EMPLOYEE_ID) {
            const findEarnMile = data.ManagementEarnMiles.EarnMiles.filter(x => x.EarnMileOID === selectEarnmile.EarnMileOID)[0]
            const modifyEarnMile = {
              EarnMileOID: findEarnMile.EarnMileOID,
              EMPLOYEE_ID: findEarnMile.EMPLOYEE_ID,
              Name: findEarnMile.Name,
              MemberID: findEarnMile.MemberID,
              MemberName: findEarnMile.MemberName,
              IsActive: findEarnMile.IsActive,
              IsDelete: findEarnMile.IsDelete
          }
            data.ManagementEarnMiles.SelectEarnMile = {...modifyEarnMile}
          }
        })
      }
    },
    checkboxDes() {
      this.$emit('checkboxDescription', this.checkboxDescription)
      this.$emit('checkboxDesMobile', false)
    },
    checkboxDesMobile() {
      this.$emit('checkboxDescription', this.checkboxDescription)
      this.$emit('checkboxDesMobile', true)
    },
    specialRequestCheck(val, type) {
      let result = null
      if (type === "seat") {
        if(this.preferredSeat && this.preferredSeat.length > 0){
          if(this.preferredSeat.filter((data) => data.Code === val)[0].Description !== null){
            result = this.preferredSeat.filter((data) => data.Code === val)[0].Description
          }
        }
      }
      else if(type === "meal"){
        if(this.specialMeal && this.specialMeal.length > 0){
          if(this.specialMeal.filter((data) => data.MealCode === val)[0].Description !== null){
            result = this.specialMeal.filter((data) => data.MealCode === val)[0].Description
          }
        }
      }else if(type === "baggage"){
        if(this.Baggage){
          if(this.Baggage.filter((data) => data.Code === val)[0].Description !== null){
            result = this.Baggage.filter((data) => data.Code === val)[0].Description
          }
        }
      }
      return result
    },
    getSpecialMeal() {
      axios
        .post(this.$store.state.API_PATH + "SpecialMeal/GetSpecialMeal", {}, {
          headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        })
        .then((res) => {
          if (res.data.Status) {
            this.specialMeal = res.data.Result;
            this.specialMeal.unshift({ Description: this.noPreference, MealCode: "" })
          }
        })
        .catch((err) => err);
    },
    setData(value, traveler, age, empId) {
      this.datafocus.value = value;
      this.datafocus.travel = traveler;
      this.datafocus.age = age;
      this.datafocus.emp_ID = empId;
      this.dialogalert = true;
    },
    async saveData(traveler, word) {
      this.buttonloading = true;
      if (word === "save") {
        const req = {
          EMPLOYEE_ID: null,
          MOBILE_NO: null,
          MOBILE_CODE: null,
          PREFERRED_SEAT: null,
          SPECIAL_MEAL: null,
          EMAIL_ID: null
        }

        if (!this.datafocus.age || this.datafocus.age === 'Adt') {
          req.EMPLOYEE_ID = this.datafocus.emp_ID ? this.datafocus.emp_ID : traveler.data.adtPaxs[0].emp_id
          req.MOBILE_NO = traveler.data.contactInfo.telNo
          req.MOBILE_CODE = this.MOBILE_CODE
          req.EMAIL_ID = traveler.data.contactInfo.email
          req.BAGGAGE = traveler.DefaultSpecialRequest.baggageRemark
          req.PREFERRED_SEAT = traveler.DefaultSpecialRequest.PreferredSeat
          req.SPECIAL_MEAL = traveler.DefaultSpecialRequest.SpecialMeal
        } else if (this.datafocus.age === 'Chd' || this.datafocus.age === 'Inf') {
            req.EMPLOYEE_ID = traveler.emp_id
            req.PREFERRED_SEAT = traveler.seatRequest
            req.SPECIAL_MEAL = traveler.mealRequest
        }
        axios.post(this.$store.state.API_PATH + "UserProfile/updateDatauserProfile", req, {
          headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
        })
          .then(async (res) => {
            this.dialogalert = false;
            if (res.status === 200) {
              if (!this.datafocus.age || this.datafocus.age === 'Adt'){
                this.modifyProfile(traveler)
              }
              await this.ticketPricingModify(traveler)
            }
          })
      } else {
        await this.ticketPricingModify(traveler)
      }
      this.buttonloading = false;
    },
    modifyProfile(traveler) {
      const modifyProfile = JSON.parse(localStorage.PROFILE);
      if (modifyProfile?.EMPLOYEE_ID === traveler.data.adtPaxs[0].emp_ID) {
        modifyProfile.MOBILE_NO = traveler.data.contactInfo.telNo;
        modifyProfile.EMAIL_ID = traveler.data.contactInfo.email;
        modifyProfile.PREFERRED_SEAT = traveler.DefaultSpecialRequest.PreferredSeat;
        modifyProfile.SPECIAL_MEAL = traveler.DefaultSpecialRequest.SpecialMeal;
        modifyProfile.BAGGAGE = traveler.DefaultSpecialRequest.baggageRemark;
        localStorage.PROFILE = JSON.stringify(modifyProfile);
      }
    },
    async ticketPricingModify(traveler) {
      const modifyTravelers = sessionStorage.travelers ? JSON.parse(sessionStorage.travelers) : [];
      sessionStorage.removeItem('travelers')
      await this.ticketPricingModified.TicketPricing.forEach(async (elm) => {
        if (this.datafocus.age === 'Adt' && elm.data.adtPaxs[0].emp_id === traveler.data.adtPaxs[0].emp_id) {
          await this.forEachAdt(modifyTravelers, traveler, elm)
        } else if (this.datafocus.age === 'Chd' && elm.data.chdPaxs && await elm.data.chdPaxs.filter(data => data.emp_id === this.datafocus.emp_ID).length > 0) {
          await this.forEachChd(modifyTravelers, elm)
        } else {
          await this.forEachInf(modifyTravelers, elm)
        }
        sessionStorage.removeItem('ticketPricingModified')
        sessionStorage.ticketPricingModified = JSON.stringify(
          this.ticketPricingModified
        )
      });
    },
    async forEachAdt(modifyTravelers, traveler, elm){
      await modifyTravelers.forEach((elmtravel) => {
        if (elmtravel.emp_ID === traveler.data.adtPaxs[0].emp_id) {
          elmtravel.mobile = traveler.data.contactInfo.telNo;
          elmtravel.email = traveler.data.contactInfo.email;
          elmtravel.DefaultSpecialRequest = traveler.DefaultSpecialRequest;
        }
      });
      elm.data.contactInfo.telNo = traveler.data.contactInfo.telNo;
      elm.data.adtPaxs.forEach((elmadt) => {
        elmadt.telNo = traveler.data.contactInfo.telNo;
        elmadt.email = traveler.data.contactInfo.email;
        elmadt.mealRequest = traveler.DefaultSpecialRequest.SpecialMeal;
        elmadt.seatRequest = traveler.DefaultSpecialRequest.PreferredSeat;
      });
      sessionStorage.travelers = JSON.stringify(modifyTravelers);
    },
    async forEachChd(modifyTravelers, elm){
      await modifyTravelers.forEach(async (elmchd) => {
        if (elmchd.emp_ID === this.datafocus.emp_ID) {
          const findId = await elm.data.chdPaxs ? elm.data.chdPaxs.filter(data => data.emp_id === elmchd.emp_ID)[0] : null
          elmchd.DefaultSpecialRequest.PreferredSeat = findId.seatRequest
          elmchd.DefaultSpecialRequest.SpecialMeal = findId.mealRequest
        }
      });
      sessionStorage.travelers = JSON.stringify(modifyTravelers);
    },
    async forEachInf(modifyTravelers, elm){
      if (this.datafocus.age === 'Inf' && elm.data.infPaxs && await elm.data.infPaxs.filter(data => data.emp_id === this.datafocus.emp_ID).length > 0) {
        await modifyTravelers.forEach(async (elminf) => {
          if (elminf.emp_ID === this.datafocus.emp_ID) {
            const findId = await elm.data.infPaxs ? elm.data.infPaxs.filter(data => data.emp_id === elminf.emp_ID)[0] : null
            elminf.DefaultSpecialRequest.PreferredSeat = findId.seatRequest
            elminf.DefaultSpecialRequest.SpecialMeal = findId.mealRequest
          }
        });
        sessionStorage.travelers = JSON.stringify(modifyTravelers);
      }
    },
    timespanFlight_v2(depflight, arrflight) {
      let result = 0;
      const formatDate = "YYYY-MM-DD HH:mm:ss"
      const dep = moment(depflight).format(formatDate);
      const arr = moment(arrflight).format(formatDate);
      const cal = parseInt(moment(arr).diff(moment(dep), "minutes", true));
      result += cal;

      result = this.toHoursAndMinutes(result);
      return `${result.hours}h ${result.minutes}m`
    },
    timespanFlight(timespan) {
      let result = 0;
      const formatDate = "YYYY-MM-DD HH:mm:ss"
      const itemtsp = [];
      itemtsp.push(timespan);

      // console.log(itemtsp.length)
      itemtsp.forEach((tsp) => {
        //    .getTime() + element.arrDisplayDateTime.displayTime.getTime()
        tsp.forEach((time) => {
          const dep = moment(time.departureDateTime).format(
            formatDate
          );
          const arr = moment(time.arrivalDateTime).format(formatDate);
          const cal = parseInt(moment(arr).diff(moment(dep), "minutes", true));
          result += cal;
        });
      });

      result = this.toHoursAndMinutes(result);
      return `${result.hours}h ${result.minutes}m`
    },
    toHoursAndMinutes(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60
      if(this.pathname !== this.pathCancelFlights &&
        this.travelers[0].TariffLevel.SelectFlight.code === 'Y' &&
        this.coperateData.PurId !== this.businessTravel2 &&
        this.coperateData.PurId !== this.businessTravel1 &&
        this.renderlevel === 0 && this.travelers.length > 0 &&
        this.travelers[0].TariffLevel.ShortHaulCheck &&
        hours >= this.travelers[0].TariffLevel.Hours){
          console.log('called');
          this.flightdialog.dialogalert = true
          this.flightdialog.flightdialogOpen = true
          this.renderlevel += 1
      }
      return { hours, minutes };
    },
    ShowDetails() {
      this.isActive = !this.isActive;
    },
  },
  mounted() {
    this.GetMobileCode();
    if(sessionStorage.coperateData){
      this.coperateData = JSON.parse(sessionStorage.coperateData)
    }
    if(sessionStorage.ticketPricingReq){
      this.ticketPricingReq = JSON.parse(sessionStorage.ticketPricingReq)
    }
    this.Baggage = [
      { Code: null, Description: this.noPreference },
      { Code: "10", Description: "10 KG" },
      { Code: "20", Description: "20 KG" }
    ]
    this.preferredSeat = [
      { Code: "", Description: this.noPreference },
      { Code: "A", Description: "Aisle seat" },
      { Code: "W", Description: "Window seat" },
    ]
    this.pathname = location.pathname.toString()
    if(localStorage.PROFILE){
      this.profile = JSON.parse(localStorage.PROFILE)
      this.getSpecialMeal();
      this.searchFlight = sessionStorage.searchflightReq ? JSON.parse(sessionStorage.searchflightReq) : null;
      this.ticketPricing = sessionStorage.searchflightReq ? JSON.parse(sessionStorage.ticketPricing) : null;
      setTimeout(async () => {
        if (this.airline != null) {
          this.EarnMiles.push(this.airline.depFlight[0].depCity.name.split(",", 2)[0])
        }
      }, 100);
    }
  },
};
</script>

<style>
.card-border {
  border: solid 0.6px #D4D4D4;
  padding: 15px;
  border-radius: 10px;
  background-color: #f8f9fa;

}

.text-profile {
  color: black;
  font-weight: 500;
}
</style>
