<template>
  <v-app>
    <v-container fluid id="width">
      <mainMenu v-if="$store.state.SHOW_MENU" style="z-index: 10;"></mainMenu>
      <v-main>
        <router-view></router-view>
      </v-main>
      <loading :active.sync="$store.state.LOADING" background-color="#a8a8a8" color="#00AEEF" :is-full-page="true">
      </loading>
    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu'
import mainFooter from '@/components/mainFooter'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  name: 'App',
  components: {
    mainMenu,
    mainFooter,
    Loading
  },
  data() {
    return {
    }
  },
  mounted() {
    if(window.location.pathname !== '/searchFlight'){
      sessionStorage.removeItem('svcClass');
    }
    if (window.location.pathname === "/login" || window.location.pathname === "/login/" || window.location.pathname.substring(0,4) === "/doc") {
      this.$store.state.SHOW_MENU = false
    }
    else {
      this.$store.state.SHOW_MENU = true
    }
  }
}
</script>

<style>

.phone-number::first-line {
  display: inline-block;
}

.phone-number::after {
  content: '';
  display: inline-block;
  width: 1ch; /* Adjust the width as needed */
  margin-right: 1ch;
}

.phone-number::after::after {
  margin-right: 2ch; /* After the fifth digit */
}

* {
  scroll-behavior: smooth;
  font-family: 'DBHelvethaicaX', sans-serif;
  font-size: 14px !important;
}

.input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.input-group-addon {
  position: absolute;
  left: 0;
  padding: 10px;
}

.form-control {
  padding-left: 40px !important; /* Adjust this value based on the icon width */
}

.my-text-field {
  /* Input styles */
  width: 100%;
  font-size: 1rem;
  line-height: 1.1876em;
  padding: 0.75rem;

  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  background-color: #ffffff;
  transition: all 0.3s;

  /* Outlined style */
  outline: none;
}

.my-text-field label {
  /* Label floating effect */
  transform: translateY(-50%) scale(0.75);
  transform-origin: top left;
  pointer-events: none;
}

.my-text-field input[type="text"],
.my-text-field input[type="password"] {
  /* Remove default input styles */
  appearance: none;
  -webkit-appearance: none;
}

.my-text-field input[type="text"]:focus,
.my-text-field input[type="password"]:focus {
  /* Input focus styles */
  outline: none;
}

.image-container {
  position: relative;
}

.image-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-container .overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center;
  color: #fff;
}

.image-container:hover {
  opacity: 10;
  transition: opacity 0.3s ease;
}

.image-container:hover .overlay{
  opacity: 1;
}

.image-container img {
  display: block;
  width: 100%;
  height: auto;
}

.image-container .overlay p {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.alway-show {
  display: block !important;
}

html {
  scroll-behavior: smooth;
}

#app {
  background: url('@/assets/background.svg') no-repeat top center fixed;
  /* background-size: cover; */
  background-color: #E5E5E5;
  /* z-index: -1; */
  /* font-family: 'DBHelvethaicaX', sans-serif; */
  /* font-weight: 400; */
}

#width {
  width: 90%;
}

@media screen and (max-width: 1500px) {
  #width {
    width: 100%;
  }
}

/* @media screen and (max-width: 1800px) {
    #width {
      width: 90%;
    }
  } */

/* @font-face {
  font-family: 'DBHelvethaicaX';
    src: local('DBHelvethaicaX'), url(./assets/fonts/DBHelvethaicaXv3.2.ttf) format("truetype");
} */

.DBHelvethaicaX {
  font-family: 'DBHelvethaicaX', sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #C8D6ED;
}

::-webkit-scrollbar-thumb {
  background: #00AEEF;
  border-radius: 5px;
}

.v-btn {
  text-transform: none !important;
}

.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  font-size: 22px !important;
  font-weight: 500;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>th {
  font-size: 22px !important;
  font-weight: 500 !important;
  font-style: italic !important;
  line-height: 26.37px !important;
}

span {
  text-transform: none !important;
}

.pagination-custom .v-pagination__navigation {
  height: 26px !important;
  width: 26px !important;
}

.pagination-custom, .v-pagination__navigation, .v-icon,
.mdi-paperclip {
  font-size: 20px !important;
}

.pagination-custom .v-pagination__item {
  height: 26px !important;
  min-width: 26px !important;
  font-size: 0.85rem !important;
}

.select-data-table {
  width: 80px !important;
  font-size: 0.85rem;
}

.v-data-table {
  white-space: nowrap;
}

.scroll-custom::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.btn-primary {
  background: rgba(185, 220, 255, 0.3) !important;
  box-shadow: 0px 4px 10px rgba(0, 128, 255, 0.12) !important;
  border-radius: 5px !important;
}

.scroll-custom::-webkit-scrollbar-track {
  background: #C8D6ED;
  border-radius: 5px;
}

.scroll-custom::-webkit-scrollbar-thumb {
  background: #00AEEF;
  border-radius: 5px;
}

.font-W400 {
  font-weight: 400 !important;
}

.font-W700 {
  font-weight: 700 !important;
}

.font-W500 {
  font-weight: 500 !important;
}

.bgcard {
  border: #EBEEF4 0.5px solid !important;
  background-color: #F8F9FA !important;
  border-radius: 10px !important;
  padding: 12px 15px 20px !important;
}

.card {
  border: #EBEEF4 0.5px solid !important;
  box-shadow: 0px 2px 20px rgba(4, 49, 128, 0.1) !important;
  border-radius: 10px !important;
}

.card-radius5px {
  border: #EBEEF4 0.5px solid !important;
  box-shadow: 0px 2px 20px rgba(4, 49, 128, 0.1) !important;
  border-radius: 5px !important;
}

.row-card {
  background: #F8F9FA;
  border: 1px solid #EBEEF4;
  border-radius: 10px;
}

.f-page {
  font-size: 22px;
}

.f-main-header {
  font-size: 20px;
}

.f-card-header {
  font-size: 18px;
}

.f-text {
  font-size: 16px;
}

.f-text-detail {
  font-size: 12px;
}

/* ตัวอักษร */
.f-text-32 {
  /* font-size: 32px !important; */
  font-size: 26px !important;
}

.f-text-30 {
  /* font-size: 30px !important; */
  font-size: 24px !important;
}

.f-text-30-b {
  /* font-size: 30px !important; */
  font-size: 24px !important;
  color: #000000 !important;
}

.f-text-28 {
  /* font-size: 28px !important; */
  font-size: 22px !important;
}

.f-text-26 {
  /* font-size: 26px !important; */
  font-size: 20px !important;
}

.f-text-24 {
  /* font-size: 24px !important; */
  font-size: 18px !important;
}

.f-text-24-b {
  /* font-size: 24px !important; */
  font-size: 18px !important;
  color: #000000 !important;
}

.f-text-22 {
  font-size: 16px !important;
}

.f-text-22-b {
  /* font-size: 22px !important; */
  font-size: 16px !important;
  color: #000000 !important;
}

.f-text-20 {
  /* font-size: 20px !important; */
  font-size: 14px !important;
}

.f-text-20-b {
  /* font-size: 20px !important; */
  font-size: 14px !important;
  color: #000000 !important;
}

.f-text-18 {
  /* font-size: 18px !important; */
  font-size: 12px !important;
}

.f-text-16 {
  /* font-size: 16px !important; */
  font-size: 10px !important;
}
.f-text-14 {
  /* font-size: 16px !important; */
  font-size: 8px !important;
}

.f-weight {
  font-weight: 500 !important;
  color: black !important;
}

.h-space {
  margin: 20px 0px 20px 0px;
}

.btn-padding {
  padding: 50px;
}
.success-text-color {
  color: #A6CE39 !important;
}
.error-text-color {
  color: red !important;
}
.btn-success {
  background-color: #A6CE39 !important;
  color: white !important;
}

.pointerLink {
  cursor: pointer;
}
.pointerCard {
  cursor: pointer;
}
.pointerCard:hover {
  border: solid #00AEEF 1px;
}
.pointerLink:hover {
  text-decoration: underline;
}

.heightcenter {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
