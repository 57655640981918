<template>
    <v-container fluid fill-height v-if="!this.$store.state.LOADING">
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-row class="ma-2">
                        <v-col>
                            <v-row>
                                <v-col>
                                    <v-img :src="require('@/assets/image/logo_pttep.png')" style="width: 110px;" />
                                </v-col>
                                <v-col class="text-right">
                                    <v-img :src="require('@/assets/image/NewRS_PTTEPNAME.png')" style="width: 80%;" class="ml-auto" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8" lg="2" md="2" sm="3" style="display: flex; align-items: center;">
                                    <span class="mr-2" style="white-space: nowrap;">No PTTEP</span>
                                    <v-text-field outlined dense hide-details v-model="docNo"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    {{ $store.state.dateNowEN }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea outlined dense hide-details v-model="$store.state.docVisa.addressTo"></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <span>Dear Sir,</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea outlined dense hide-details v-model="$store.state.docVisa.body"></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <span><u>Name</u></span><br>
                                    <span>{{ $store.state.docVisa.nameEN }}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <span>Please be assured that PTTEP will provide full financial support during his/her stay in {{ $store.state.docVisa.countryEN }}
                                        Should you need any further information, please let us know.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <span>Yours sincerely,</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-10">
                                <v-col cols="12" lg="6" md="6" sm="12" class="d-flex justify-center">
                                    <v-text-field outlined dense hide-details v-model="$store.state.docVisa.nameBudget"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2 mb-10">
                                <v-col cols="12" lg="6" md="6" sm="12" class="d-flex justify-center">
                                    <v-text-field outlined dense hide-details v-model="$store.state.docVisa.posiBudget"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col cols="12">
                                    <span>Coordinator: {{ $store.state.docVisa.nameBTT }}</span><br>
                                    <span>{{ $store.state.docVisa.posiBTT }}</span><br>
                                    <span>TR No.{{ $store.state.docVisa.TRBudget }}</span><br>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-img :src="require('@/assets/image/NewWeb_footerPTTEP.png')" style="width: 70%;" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn color="primary" elevation="0" @click="printPDF()">Print To PDF</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import pdfFonts from '@/assets/custom-font.js'
export default {
    data(){
        return{
            docNo: "",
            TRNo: "",
            EMP_ID: "",
            Type: "",
        }
    },
    methods:{
        async printPDF(){
            pdfMake.vfs = pdfFonts.pdfMake.vfs
            pdfMake.fonts = {
                CordiaNew: {
                    normal: "CordiaNew.ttf"
                }
            }
            const doc = {
                content:[
                    {
                        columns: this.$store.state.docVisa.headerVisa
                    },
                    {
                        text: `No PTTEP ${this.docNo}`,
                        style: "font16",
                        margin: [0, 10, 0, 0]
                    },
                    {
                        text: this.$store.state.dateNowEN,
                        style: "font16",
                        margin: [0, 25, 0, 0]
                    },
                    {
                        text: this.$store.state.docVisa.addressTo,
                        style: "font16",
                        margin: [0, 25, 0, 0]
                    },
                    {
                        text: "Dear Sir,",
                        style: "font16",
                        margin: [0, 25, 0, 0]
                    },
                    {
                        text: this.$store.state.docVisa.body,
                        style: "font16",
                        margin: [0, 25, 0, 0]
                    },
                    {
                        decoration: 'underline',
                        text: "Name",
                        style: "font16",
                        margin: [0, 25, 0, 0]
                    },
                    {
                        text: this.$store.state.docVisa.nameEN,
                        style: "font16",
                    },
                    {
                        text: `Please be assured that PTTEP will provide full financial support during his/her stay in 
                        ${this.$store.state.docVisa.countryEN}. Should you need any further information, please let us know.`,
                        style: "font16",
                        margin: [0, 25, 0, 0]
                    },
                    this.$store.state.docVisa.footerVisa
                ],
                footer: {
                    margin: [20, -10, 20, 10],
                    image: this.$store.state.NewWeb_footerPTTEP,
                    width: 555
                },
                pageSize: "A4",
                styles:{
                    font16: {
                        alignment: 'left',
                        fontSize: 16
                    },
                },
                defaultStyle: {
                    font: 'CordiaNew'
                }
            }
            pdfMake.createPdf(doc).download(`RptSponLet_${this.$store.state.docVisa.nameEN}.pdf`)
        },
        getRptSponLet(TRNo, EMP_ID, Type){
            this.$store.commit("docVisa/getDocVisa", {
                TRNo: TRNo,
                EMP_ID: EMP_ID,
                Type: Type,
            })
        }
    },
    mounted(){
        if(this.$route.query.TRNo && this.$route.query.EMP_ID && this.$route.query.Type){
            const q = this.$route.query
            this.TRNo = q.TRNo
            this.EMP_ID = q.EMP_ID
            this.Type = q.Type
            this.$store.state.docPassport.enclosure = "1. คำร้องขอออกหนังสือเดินทางและหนังสือนำวีซ่า"
            this.getRptSponLet(q.TRNo, q.EMP_ID, q.Type)
        }
    }
}
</script>
