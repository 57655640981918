import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import environment from '@/environment'

Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: environment.googleMap,
    libraries: 'places', // If you need additional Google Maps libraries, add them here
  },
});
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')

