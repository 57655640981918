<template>
    <div v-if="!$store.state.LOADING">
        <v-row class="mb-3" no-gutters>
            <v-col>
                <span>
                    <span class="font-W700" style="font-size: 1.2em;">Questionare Travel Request</span>
                </span>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col class="d-flex flex-column">
                                Travel Request Number
                                <span class="font-W500" color="black">{{ TRNo }}</span>
                            </v-col>
                            <v-col class="d-flex flex-column">
                                Business Travel Type
                                <span class="font-W500" color="black">{{ BusinessTravelType }}</span>
                            </v-col>
                            <v-col class="d-flex flex-column">
                                Travel Request Status
                                <span class="font-W500" color="black">{{ TravelRequestStatus }}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <v-row class="mb-3" no-gutters>
                            <v-col>
                                <span>
                                    <span class="font-W700" style="font-size: 1.1em;">Travel information</span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <v-card class="card-detail" elevation="0">
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <span class="font-W700" color="black">
                                                    Coporate Budget
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="mt-2">
                                            <v-col lg="6" sm="6" cols="12">
                                                Budget Holder
                                                <span style="color: #FF0000;">*</span>
                                                <v-text-field outlined dense
                                                v-model="BudgetHolder" disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col lg="6" sm="6" cols="12">
                                                Project
                                                <span style="color: #FF0000;">*</span>
                                                <v-text-field outlined dense
                                                v-model="Project" disabled></v-text-field>
                                            </v-col>
                                            <v-col lg="6" sm="6" cols="12" class="pl-lg-2 pl-sm-2">
                                                Company
                                                <span style="color: #FF0000;">*</span>
                                                <v-text-field outlined dense
                                                v-model="Company" disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col lg="6" sm="6" cols="12">
                                                Country
                                                <span style="color: #FF0000;">*</span>
                                                <v-text-field outlined dense
                                                v-model="Country" disabled></v-text-field>
                                            </v-col>
                                            <v-col lg="6" sm="6" cols="12" class="pl-lg-2 pl-sm-2">
                                                City or Province
                                                <span style="color: #FF0000;">*</span>
                                                <v-text-field outlined dense
                                                v-model="CityorProvince" disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col lg="6" sm="6" cols="12">
                                                Purpose of Travel
                                                <span style="color: #FF0000;">*</span>
                                                <v-text-field outlined dense
                                                v-model="PurposeofTravel" disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                Description for purpose of Travel
                                                <v-textarea outlined dense disabled></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-3" no-gutters>
            <v-col>
                <v-card class="card" elevation="0">
                    <v-card-text>
                        <template v-if="Flights.length > 0">
                            <v-row no-gutters class="mb-2">
                                <v-col>
                                    <span class="font-W700">Flights</span>
                                </v-col>
                            </v-row>
                            <v-card class="card-note" elevation="0">
                                <v-card-text>
                                    <v-row no-gutters>
                                        <v-col>
                                            <v-row no-gutters v-for="(item, index) in Flights" :key="index" :align="index <= Flights.length ? 'center mb-2' : 'center'">
                                                <v-col cols="8" class="pr-1">
                                                    {{ item.DepCity + " - " + item.ArrCity + " ("+ item.Airline + item.FlightNumber +")" }}<br>
                                                    {{ item.NDepartureDateTime + " - " + item.NArrivalDateTime }}
                                                </v-col>
                                                <v-col cols="4" class="pl-1">
                                                    <v-radio-group v-model="item.isUse" row hide-details>
                                                        <v-radio label="Use" value="1"></v-radio>
                                                        <v-radio label="Unuse" value="0"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </template>
                        <br v-if="Hotels.length > 0">
                        <template v-if="Hotels.length > 0">
                            <v-row no-gutters class="mb-2">
                                <v-col>
                                    <span class="font-W700">Hotels</span>
                                </v-col>
                            </v-row>
                            <v-card class="card-note" elevation="0">
                                <v-card-text>
                                    <v-row no-gutters>
                                        <v-col>
                                            <v-row no-gutters v-for="(item, index) in Hotels" :key="index" :align="index <= Hotels.length ? 'center mb-2' : 'center'">
                                                <v-col cols="8" class="pr-1">
                                                    <span>{{ item.HotelName }}</span><br>
                                                    <span>{{ item.DisplayDateStart + " - " + item.DisplayDateEnd }}</span>
                                                </v-col>
                                                <v-col cols="4" class="pl-1">
                                                    <v-radio-group v-model="item.isUse" row hide-details>
                                                        <v-radio label="Use" value="1"></v-radio>
                                                        <v-radio label="Unuse" value="0"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </template><br>
                        <v-card class="card-note" elevation="0">
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col class="text-right mr-1">
                                        <v-btn color="primary" @click="saveQuestionnaire()">Submit</v-btn>
                                    </v-col>
                                    <v-col class="ml-1">
                                        <v-btn outlined color="primary" @click="cancelQuestionnaire()">Cancel</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
export default {
    data(){
        return{
            TravelRequestOID: "",
            Flights: [],
            Hotels: [],
            EMPLOYEE_ID: "",
            TRNo: "",
            BusinessTravelType: "",
            TravelRequestStatus: "",
            BudgetHolder: "",
            Project: "",
            Company: "",
            Country: "",
            CityorProvince: "",
            PurposeofTravel: "",
        }
    },
    methods:{
        async saveQuestionnaire(){
            let f = 1, h = 1
            for(const e of this.Flights){
                await new Promise(resolve=>{
                    e.Seq = f
                    e.isUse = Number(e.isUse)
                    f++
                    resolve()
                })
            }
            for(const e of this.Hotels){
                await new Promise(resolve=>{
                    e.Seq = h
                    e.isUse = Number(e.isUse)
                    h++
                    resolve()
                })
            }
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}TravelRequest/InsertTravelRequestQuestionnaire`, {
                TravelRequestOID: this.TravelRequestOID,
                TRNo: this.TRNo,
                EMPLOYEE_ID: this.EMPLOYEE_ID,
                Flights: this.Flights,
                Hotels: this.Hotels,
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then((res) => {
                this.$store.state.LOADING = false
                if(res.data.Status){
                    Swal.fire({
                        icon: 'success',
                        text: 'Success',
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                        window.location.href = "/"
                    },()=>{})
                }
                else{
                    Swal.fire({
                        icon: 'error',
                        text: res.data.ErrorMessage,
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                        window.location.href = "/"
                    },()=>{})
                }
            },()=>{
                this.$store.state.LOADING = false
            })
        },
        cancelQuestionnaire(){
            this.Flights.forEach(e=>{
                e.isUse = 0
            })
            this.Hotels.forEach(e=>{
                e.isUse = 0
            })
        },
        getTravelRequestQuestionnaire(TravelRequestOID, Traveller, TypeTraveller){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}TravelRequest/GetTravelRequestQuestionnaire`, {
                TravelRequestOID: TravelRequestOID,
                EMPLOYEE_ID: Traveller,
                TypeTraveller: TypeTraveller
            }, {
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            }).then((res) => {
                if(res.data.Status){
                   const item = res.data.Result
                   this.TRNo = item.TRNo
                   this.EMPLOYEE_ID = item.EMPLOYEE_ID
                   this.BusinessTravelType = item.BusinessTravelType
                   this.TravelRequestStatus = item.Status
                   this.BudgetHolder = item.BudgetHolder
                   this.Project = item.Project
                   this.Company = item.Company
                   this.Country = item.Country
                   this.CityorProvince = item.CityorProvince
                   this.PurposeofTravel = item.PurposeofTravel
                   this.DesofTravel = item.DesofTravel
                   this.Flights = item.Flights
                   this.Hotels = item.Hotels
                   setTimeout(()=>{
                    this.$store.state.LOADING = false
                   },1000)
                }
                else{
                    this.$store.state.LOADING = false
                    Swal.fire({
                        icon: 'error',
                        text: res.data.ErrorMessage,
                        confirmButtonColor: '#00AEEF'
                    }).then(()=>{
                        window.location.href = "/"
                    },()=>{})
                }
            },()=>{
                this.$store.state.LOADING = false
            })
        }
    },
    mounted(){
        if(this.$route.query.TravelRequestOID && this.$route.query.Traveller && this.$route.query.TypeTraveller){
            const q = this.$route.query
            this.TravelRequestOID = q.TravelRequestOID
            this.getTravelRequestQuestionnaire(q.TravelRequestOID, q.Traveller, q.TypeTraveller)
        }
    }
}
</script>
<style scoped>
.v-input--selection-controls{
    margin: 0;
}
.font-W700{
    font-weight: 700;
}
.font-W500{
    font-weight: 500;
}
.card-detail{
    background: #F8F9FA;
    border: 1px solid #EBEEF4;
    border-radius: 10px;
}
.card-white{
    background: #F8F9FA;
}
.card-in-detail{
    background: #FFFFFF;
    /* Main bolder */

    border: 1px solid #EBEEF4;
    border-radius: 5px;
    vertical-align: top;
}
.row-card{
    background: #F8F9FA;
    border: 1px solid #EBEEF4;
    border-radius: 10px;
}
#customers td, #customers th {
  padding: 8px;
  white-space: nowrap;
}
.myTicket::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.myTicket::-webkit-scrollbar-track {
    background: #C8D6ED;
    border-radius: 5px;
}

.myTicket::-webkit-scrollbar-thumb {
    background: #00AEEF;
    border-radius: 5px;
}
.card-note{
    background: #FFFFFF;
    border: 1px solid #E6EBF4;
    border-radius: 5px;
}
#Note th {
    text-align: left;
    background: #F8F9FA;
    color: #747678;
    font-weight: 500;
    padding: 8px;
    white-space: nowrap;
}
#Note td {
  padding: 8px;
  color: #000000;
  white-space: nowrap;
}
/* #Verify tr {
    border-bottom: 1px solid #EBEEF4;
} */
#Verify th {
    text-align: left;
    background: #F8F9FA;
    background-color: white;
    color: #747678;
    font-weight: 500;
    padding: 8px;
    white-space: nowrap;
}
#Verify td {
  padding: 8px;
  color: #000000;
  white-space: nowrap;
  background-color: white;
}
</style>
