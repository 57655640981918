<template>
        <div>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <span>
                        <span class="font-W700" style="font-size: 1em;">Province</span>
                    </span>
                </v-col>
            </v-row>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row gutters align="center" justify="center">
                                <v-col sm="3" lg="3" cols="8">
                                    Code
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Code" @keypress.enter="getProvinceBySearch()"></v-text-field>
                                </v-col>
                                <v-col sm="3" lg="3" cols="8">
                                    Name_th
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Name_th" @keypress.enter="getProvinceBySearch()"></v-text-field>
                                </v-col>
                                <v-col sm="3" lg="3" cols="8">
                                    Name_en
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Name_en" @keypress.enter="getProvinceBySearch()"></v-text-field>
                                </v-col>
                                 <v-col sm="3" lg="3" cols="8">
                                    Country
                                    <v-text-field outlined dense background-color="white"
                                    v-model="Country" @keypress.enter="getProvinceBySearch()"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters align="center" justify="center">
                                <v-col md="auto" sm="auto" >
                                    <v-btn color="primary"
                                      @click="getProvinceBySearch()" :loading="searchbtnLoading"
                                      elevation="0"
                                      class="mb-lg-0 mb-md-0 mb-sm-0 mb-6">Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mb-3" no-gutters>
                <v-col>
                    <v-card elevation="0" title>
                        <v-card-text>
                            <v-row no-gutters align="center">
                                <v-col class="text-lg-right text-sm-right text-center">
                                    <v-btn class="btn-table mr-2" elevation="0" @click="deleteProvince()" v-if="$store.state.buttonPermit.menuConfig.Delete">
                                        <v-img :src="require('@/assets/icons/rubbish_bin.svg')" class="mr-1"></v-img>
                                        Delete
                                    </v-btn>
                                    <v-btn class="btn-table mr-2" elevation="0" @click="resetInfo()">
                                        <v-img :src="require('@/assets/icons/Reloading_Solid_30.svg')" class="mr-1"></v-img>
                                        Reset
                                    </v-btn>
                                    <v-btn class="btn-table" elevation="0" @click="actionProvince('Add')" v-if="$store.state.buttonPermit.menuConfig.Add">
                                        <v-img :src="require('@/assets/icons/Add_trans.svg')" class="mr-1"></v-img>
                                        Add
                                    </v-btn>
                                    <v-dialog v-if="dialogAddOrEditProvince" v-model="dialogAddOrEditProvince" persistent
                                        width="600px">
                                        <v-card elevation="0" title color="primary" class="pt-1 card">
                                            <v-card>
                                                <v-card-text>
                                                    <v-card class="card-detail" title elevation="0">
                                                        <v-card-title>
                                                            <v-row no-gutters>
                                                                <v-col class="text-center">
                                                                    {{ AddOrEdit }} Province
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Code
                                                                    <v-text-field  :disabled="AddOrEdit === 'Add' ? false : true"
                                                                     outlined dense background-color="white" ref="Code" :rules="rules.Code"
                                                                     v-model="Code"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Name_th
                                                                    <v-text-field :disabled="AddOrEdit === 'Add' ? false : true"
                                                                     outlined dense background-color="white" ref="Name_th" :rules="rules.Name_th"
                                                                     v-model="Name_th"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Name_en
                                                                     <v-text-field outlined dense background-color="white" ref="Name_en" :rules="rules.Name_en"
                                                                    v-model="Name_en"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    Country
                                                                     <v-text-field outlined dense background-color="white" ref="Country" :rules="rules.Country"
                                                                    v-model="Country"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col class="text-center">
                                                            <v-btn outlined color="primary" class="mr-1"
                                                            @click="closeAddOrEditProvince()">Close</v-btn>
                                                            <v-btn color="primary" class="ml-1" v-if="AddOrEdit === 'Add'"
                                                            @click="addProvinceData()">Save</v-btn>
                                                            <v-btn color="primary" class="ml-1" v-else
                                                            @click="saveAddOrEditProvince()">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <br>
                            <v-card elevation="0">
                                <v-data-table :headers="headerProvince" :items="itemProvinceData" class="elevation-1" item-key="no"
                                @click:row="rowClicked" show-select v-model="selectedItemProvince" checkbox-color="primary"
                                mobile-breakpoint style="white-space: nowrap" hide-default-footer height="45vh"
                                @page-count="pageCountProvince = $event" :page.sync="page" :items-per-page="itemPage" @load="onPagination($event)">
                                </v-data-table>
                            </v-card>
                            <v-row class="mt-3 hidden-xs-only" no-gutters>
                                <v-col class="d-flex" cols="12">
                                    <span class="mr-3 mt-3">Show</span>
                                    <v-select outlined dense :items="itemsPerPageProvince" item-text="text" item-value="value"
                                        v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                    <span class="ml-3 mt-3">items per page</span>
                                    <v-pagination v-model="page" :length="pageCountProvince" :total-visible="5" class="pagination-custom"
                                        style="width: 60%;"></v-pagination>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3 hidden-sm-and-up" align="center" no-gutters>
                                <v-col class="d-flex" cols="12">
                                    <span class="mr-3 mt-3">Show</span>
                                    <v-select outlined dense :items="itemsPerPageProvince" item-text="text" item-value="value"
                                        v-model="itemPage" background-color="white" class="select-data-table shrink" style="min-width: 100px;"></v-select>
                                    <span class="ml-3 mt-3">items per page</span>
                                </v-col>
                                <v-col>
                                    <v-pagination v-model="page" :length="pageCountProvince" :total-visible="5" class="pagination-custom">
                                    </v-pagination>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="DialogRemove" width="500">
            <v-card>
                <v-card-title>
                    Remove
                </v-card-title>
                <v-card-text>
                    <template v-if="FileFocus">
                        would you like to remove '{{ FileFocus.FilePath.split('\\')[FileFocus.FilePath.split('\\').length - 1] }}' ?
                    </template>
                    <template v-else>
                        would you like to remove your passport account?
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small text @click="DialogRemove = false">Cancel</v-btn>
                    <v-btn small color="error" @click="remove(typeFocus, 'remove', null)">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </div>
</template>
<script>
import Swal from 'sweetalert2'
import axios from 'axios';
export default {
    data(){
        return{
            disable: false,
            searchbtnLoading: false,
            FileFocus: null,
            typeFocus: null,
            DialogRemove: false,
            files: [],
            Code: "",
            Language: "",
            Name_th: "",
            Name_en: "",
            Country:"",
            AddOrEdit: "",
            dialogAddOrEditProvince: false,
            itemsPerPageProvince: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "50", value: 50 },
                { text: "100", value: 100 },
                { text: "ALL", value: 200 },
            ],
            TEXT_SEARCH: "",
            itemPage: 10,
            page: 1,
            pageCountProvince: 2,
            headerProvince: [
                { text: 'No.', value: 'no', sortable: false },
                { text: 'Code', value: 'Code', sortable: false },
                { text: 'Name_th', value: 'Name_th', sortable: false },
                { text: 'Name_en', value: 'Name_en', sortable: false },
                { text: 'Country', value: 'Country', sortable: false },
            ],
            itemProvinceData: [],
            selectedItemProvince: [],
            requireField: "This field is required",
            rules:{
                Code: [(val) => (val || "").length > 0 || this.requireField],
                Name_th: [(val) => (val || "").length > 0 || this.requireField],
                Name_en: [(val) => (val || "").length > 0 || this.requireField],
                Country: [(val) => (val || "").length > 0 || this.requireField],
            }
        }
    },
    methods:{
        remove(type, processing, val) {
            this.typeFocus = type
            if (type === 'BedTypeFile') {
                if (processing === 'alert') {
                    this.FileFocus = val
                }
                if (processing === 'remove') {
                    this.removeFiles(this.FileFocus, 'Saved')
                    this.DialogRemove = false
                }
            }
        },
        checkvalidate(){
            let result = false
            if (!this.$refs.Code.validate()) {
                this.$refs.Code.validate(true)
                this.$refs.Code.focus()
                result = true
            }
            if (!this.$refs.Name_th.validate()) {
                this.$refs.Name_th.validate(true)
                this.$refs.Name_th.focus()
                result = true
            }
            if (!this.$refs.Name_en.validate()) {
                this.$refs.Name_en.validate(true)
                this.$refs.Name_en.focus()
                result = true
            }
            if (!this.$refs.Country.validate()) {
                this.$refs.Country.validate(true)
                this.$refs.Country.focus()
                result = true
            }
            return result
        },
        saveAddOrEditProvince(){
            if(!this.checkvalidate()){
                this.dialogAddOrEditProvince = false
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}Province/UpdateProvince`, {
                    Code: this.Code,
                    Name_th: this.Name_th,
                    Name_en: this.Name_en,
                    Country: this.Country,
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.SwalAlert(res)
                })
            }
        },
        SwalAlert(res){
            if(res.data.Status){
                Swal.fire({
                    icon: 'success',
                    text: 'Success',
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.closeAddOrEditProvince()
                    this.getProvinceData()
                }, () => {})
            }
            else{
                Swal.fire({
                    icon: 'error',
                    text: res.data.ErrorMessage,
                    confirmButtonColor: '#00AEEF'
                }).then(() => {
                    this.dialogAddExternal = true
                    this.$store.state.LOADING = false
                }, () => { })
            }
        },
        closeAddOrEditProvince(){
            this.dialogAddOrEditProvince = false
            this.Code = ""
            this.Name_th = ""
            this.Name_en = ""
            this.Country = ""
            this.AddOrEdit = ""
            this.disable = false
        },
        addProvinceData(){
            if(!this.checkvalidate()){
                this.dialogAddOrEditProvince = false
                this.$store.state.LOADING = true
                axios.post(`${this.$store.state.API_PATH}Province/InsertProvince`, {
                    Code: this.Code,
                    Name_th: this.Name_th,
                    Name_en: this.Name_en,
                    Country: this.Country
                }, {
                    headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                })
                .then((res) => {
                    this.SwalAlert(res)
                }).catch(() => {
                    this.$store.state.LOADING = false
                })
            }
        },
        actionProvince(AddOrEdit){
            this.AddOrEdit = AddOrEdit
            this.dialogAddOrEditProvince = true
        },
        resetInfo(){
            this.selectedItemProvince = []
            this.TEXT_SEARCH = ""
            this.getProvinceData()
        },
        deleteProvince(){
            if(this.selectedItemProvince.length > 0){
                Swal.fire({
                    icon: 'warning',
                    text: 'would you like to to delete?',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    confirmButtonColor: '#00AEEF'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.state.LOADING = true
                        axios.post(this.$store.state.API_PATH + 'Province/DeleteProvince', {
                            selectedDelItem: this.selectedItemProvince
                        }, {
                            headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                        })
                        .then(res => {
                            this.$store.state.LOADING = false
                            if (res.data.Status) {
                                Swal.fire({
                                    icon: 'success',
                                    text: 'Success',
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                    this.selectedItemProvince = []
                                    this.getProvinceData()
                                },()=>{})
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    text: res.data.ErrorMessage,
                                    confirmButtonColor: '#00AEEF'
                                }).then(() => {
                                },()=>{})
                            }
                        })
                        .catch(() => {
                            this.$store.state.LOADING = false
                        })
                    }
                })
            }
        },
        getProvinceData(){
            this.$store.state.LOADING = true
            axios.post(`${this.$store.state.API_PATH}Province/GetProvince`,{},{
                headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
            })
            .then(async (res) => {
                this.$store.state.LOADING = false
                if (res.data.Status) {
                    this.itemProvinceData = res.data.Result
                    }
            }).catch(() => {
                this.$store.state.LOADING = false
            })
        },
        getProvinceBySearch(){
                this.searchbtnLoading = true
                setTimeout(() => {
                    axios.post(this.$store.state.API_PATH + 'Province/GetProvinceBySearch', {
                        Code: this.Code,
                        Name_th: this.Name_th,
                        Name_en: this.Name_en,
                        Country: this.Country,
                    }, {
                        headers: { 'Authorization': `Basic ${this.$store.state.authenApi}` }
                    }).then(res => {
                        this.$store.state.LOADING = false
                    if (res.data.Status) {
                        console.log(res.data.Result);
                        this.itemProvinceData = res.data.Result
                        this.searchbtnLoading = false
                    } else {
                        this.searchbtnLoading = false
                    }
                    }).catch(() => {
                        this.$store.state.LOADING = false
                        this.searchbtnLoading = false
                    })
                }, 1000);
            },
        rowClicked(item){
            if(this.$store.state.buttonPermit.menuConfig.Update){
                this.AddOrEdit = "Edit"
                this.Code = item.Code
                this.Name_th = item.Name_th
                this.Name_en = item.Name_en
                this.Country = item.Country
                this.dialogAddOrEditProvince = true
            }
        },
    },
    mounted(){
        this.getProvinceData()
    }
}
</script>
<style scoped>
.font-W700{
    font-weight: 700;
}
.font-W400{
    font-weight: 400;
}
.btn-table{
    background: rgba(0, 174, 239, 0.1) !important;
}
</style>
